export const LOOKUP_UNCLAIMED_DEALERSHIP = [
  {
    stateCode: 'IA',
    state: 'Iowa',
    count: 44190
  },
  {
    stateCode: 'TX',
    state: 'Texas',
    count: 329261
  },
  {
    stateCode: 'ND',
    state: 'North Dakota',
    count: 14647
  },
  {
    stateCode: 'LA',
    state: 'Louisana',
    count: 43678
  },
  {
    stateCode: 'MO',
    state: 'Missouri',
    count: 61178
  },
  {
    stateCode: 'VI',
    state: 'Virgin Islands',
    count: 19
  },
  {
    stateCode: 'AL',
    state: 'Alabama',
    count: 38597
  },
  {
    stateCode: 'WA',
    state: 'Washington',
    count: 52809
  },
  {
    stateCode: 'MT',
    state: 'Montana',
    count: 11131
  },
  {
    stateCode: 'KY',
    state: 'Kentucky',
    count: 53116
  },
  {
    stateCode: 'CO',
    state: 'Colorado',
    count: 57213
  },
  {
    stateCode: 'NY',
    state: 'New York',
    count: 196938
  },
  {
    stateCode: 'AE',
    state: 'Armed Forces Africa',
    count: 522
  },
  {
    stateCode: 'MP',
    state: 'Northern Mariana Islands',
    count: 1
  },
  {
    stateCode: 'MS',
    state: 'Mississippi',
    count: 39287
  },
  {
    stateCode: 'GA',
    state: 'Georgia',
    count: 71913
  },
  {
    stateCode: 'UT',
    state: 'Utah',
    count: 21914
  },
  {
    stateCode: 'NV',
    state: 'Nevada',
    count: 23738
  },
  {
    stateCode: 'NM',
    state: 'New Mexico',
    count: 18228
  },
  {
    stateCode: 'ID',
    state: 'Idaho',
    count: 11772
  },
  {
    stateCode: 'NH',
    state: 'New Hampshire',
    count: 16070
  },
  {
    stateCode: 'CT',
    state: 'Connecuit',
    count: 32947
  },
  {
    stateCode: 'ME',
    state: 'Maine',
    count: 17682
  },
  {
    stateCode: 'VT',
    state: 'Vermont',
    count: 5957
  },
  {
    stateCode: 'PA',
    state: 'Pennsylvania',
    count: 130876
  },
  {
    stateCode: 'DC',
    state: 'District of Columbia',
    count: 2866
  },
  {
    stateCode: 'RI',
    state: 'Rohade Island',
    count: 8427
  },
  {
    stateCode: 'PR',
    state: 'Pureto Rico',
    count: 1348
  },
  {
    stateCode: 'NJ',
    state: 'New Jersey',
    count: 105321
  },
  {
    stateCode: 'MI',
    state: 'Michigan',
    count: 138192
  },
  {
    stateCode: 'AP',
    state: 'Armed Forces Pacific',
    count: 214
  },
  {
    stateCode: 'MB',
    state: 'Manitoba',
    count: 1
  },
  {
    stateCode: 'MA',
    state: 'Massachusetts',
    count: 54879
  },
  {
    stateCode: 'FL',
    state: 'Florida',
    count: 315419
  },
  {
    stateCode: 'MN',
    state: 'Minnesota',
    count: 88418
  },
  {
    stateCode: 'VA',
    state: 'Virginia',
    count: 113199
  },
  {
    stateCode: 'IN',
    state: 'Indiana',
    count: 50520
  },
  {
    stateCode: 'OH',
    state: 'Ohio',
    count: 169479
  },
  {
    stateCode: 'OR',
    state: 'Oregon',
    count: 21986
  },
  {
    stateCode: 'NE',
    state: 'Nebraska',
    count: 19798
  },
  {
    stateCode: 'CA',
    state: 'California',
    count: 246864
  },
  {
    stateCode: 'WI',
    state: 'Wiscanson',
    count: 70036
  },
  {
    stateCode: 'WV',
    state: 'West Virgiania',
    count: 20970
  },
  {
    stateCode: 'OK',
    state: 'Oklahama',
    count: 40479
  },
  {
    stateCode: 'AZ',
    state: 'Arizona',
    count: 29433
  },
  {
    stateCode: 'MD',
    state: 'Maryland',
    count: 87023
  },
  {
    stateCode: 'IL',
    state: 'Illinois',
    count: 118715
  },
  {
    stateCode: 'AA',
    state: 'Armed Forces Americas',
    count: 16
  },
  {
    stateCode: 'AR',
    state: 'Arkansas',
    count: 2068
  },
  {
    stateCode: 'TN',
    state: 'Tennessee',
    count: 75195
  },
  {
    stateCode: 'HI',
    state: 'Hawaii',
    count: 2064
  },
  {
    stateCode: 'SD',
    state: 'South Dakota',
    count: 8288
  },
  {
    stateCode: 'AZ',
    state: 'Arizona',
    count: 47797
  },
  {
    stateCode: 'KS',
    state: 'Kansas',
    count: 24880
  },
  {
    stateCode: 'NC',
    state: 'North Carolina',
    count: 111132
  },
  {
    stateCode: 'SC',
    state: 'South Carolina',
    count: 43487
  },
  {
    stateCode: 'DE',
    state: 'Deleware',
    count: 9427
  },
  {
    stateCode: 'WY',
    state: 'Wyoming',
    count: 7733
  },
  {
    stateCode: 'GU',
    state: 'Guam',
    count: 46
  },
  {
    stateCode: 'AS',
    state: 'American Samoa',
    count: 0
  },
  {
    stateCode: 'AK',
    state: 'Alaska',
    count: 0
  },
  {
    stateCode: 'FM',
    state: 'Federated States Of Micronesia',
    count: 0
  },
  {
    stateCode: 'MH',
    state: 'Marshall Islands',
    count: 0
  },
  {
    stateCode: 'PW',
    state: 'Palau',
    count: 0
  }
]
