import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Badge, Button, Modal, Popover, Upload, notification } from 'antd'
import ImgCrop from 'antd-img-crop'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { BsTrash } from 'react-icons/bs'
import * as DealerServices from 'src/services/dealer.service'
import { beforeUpload, getBase64 } from 'src/utils/file'
import './style.scss'

function UploadingDealershipLogoComponent({
  triggerUploadDealershipLogo,
  setTriggerUploadDealershipLogo,
  initialImageUrl,
  dealerId
}) {
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState(initialImageUrl)
  const [file, setFile] = useState()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false)
        setImageUrl(url)
      })
    }
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8
        }}
      >
        Upload
      </div>
    </div>
  )

  const dummyRequest = ({ file, onSuccess }) => {
    setFile(file)
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  useEffect(() => {
    if (triggerUploadDealershipLogo && file && dealerId) {
      setTriggerUploadDealershipLogo(false)
      DealerServices.updateDealershipLogo(file, dealerId).catch((error) => {
        let errorMessage = 'Failed to upload the dealership logo'
        if (error?.response?.data?.meta?.errors?.dealer_logo[0]) {
          errorMessage = error.response.data.meta.errors.dealer_logo[0]
        }
        notification.error({
          message: 'Error!',
          description: errorMessage
        })
      })
    }
  }, [triggerUploadDealershipLogo, file, dealerId])

  const deleteBadgeClass = imageUrl ? 'cursor-pointer hover:text-red-500' : 'hidden'

  const handleConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal)
  }

  const handleConfirmationSubmit = () => {
    if (dealerId) {
      setShowConfirmationModal(false)
      DealerServices.deleteDealershipLogo(dealerId)
        .then(() => {
          setFile('')
          setImageUrl('')
          notification.success({
            message: 'Success',
            description: 'Logo successfully removed'
          })
        })
        .catch((error) => {
          let errorMessage = 'Failed to delete the logo'
          if (error?.response?.data?.meta?.errors?.dealer_logo[0]) {
            errorMessage = error.response.data.meta.errors.dealer_logo[0]
          }
          notification.error({
            message: 'Error!',
            description: errorMessage
          })
        })
    } else {
      setShowConfirmationModal(false)
      setFile('')
      setImageUrl('')
      notification.success({
        message: 'Success',
        description: 'Logo successfully removed'
      })
    }
  }

  const handleConfirmationCancel = () => {
    setShowConfirmationModal(false)
  }

  return (
    <>
      <div className="mt-1">
        <Badge
          count={
            <Popover content="Remove Logo">
              <BsTrash className={deleteBadgeClass} onClick={() => setShowConfirmationModal(true)} />
            </Popover>
          }
        >
          <ImgCrop showReset zoomSlider aspectSlider>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={dummyRequest}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              accept="image/jpeg, image/png ,image/jpg"
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="avatar"
                  style={{
                    width: '100%'
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </ImgCrop>
        </Badge>
      </div>
      <Modal
        centered
        title=""
        visible={showConfirmationModal}
        onCancel={handleConfirmationModal}
        closable={false}
        okText="Close"
        className=""
        width={350}
        destroyOnClose
        footer={[
          <div className="flex items-center justify-center">
            <Button
              type="button"
              className="bg-primary hover:bg-activePrimary hover:text-white font-semibold rounded-lg outline-none h-full text-white justify-center uppercase"
              onClick={handleConfirmationSubmit}
            >
              CONFIRM
            </Button>
            <Button
              type="button"
              className="hover:bg-activePrimary font-semibold rounded-lg outline-none h-full text-black hover:text-white justify-center uppercase"
              onClick={handleConfirmationCancel}
            >
              CANCEL
            </Button>
          </div>
        ]}
      >
        <div className="pb-2 flex gap-3 lg:gap-5 justify-center rounded-3xl">
          <div className="font-semibold text-md text-center">Are you sure you want to remove the logo?</div>
        </div>
      </Modal>
    </>
  )
}

UploadingDealershipLogoComponent.propTypes = {
  setTriggerUploadDealershipLogo: PropTypes.func,
  triggerUploadDealershipLogo: PropTypes.bool,
  initialImageUrl: PropTypes.string,
  dealerId: PropTypes.number
}

export default UploadingDealershipLogoComponent
