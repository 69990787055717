import { Button, Form, Input, notification } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { BiChevronDown, BiChevronLeft, BiChevronUp } from 'react-icons/bi'
import { IoMdShareAlt } from 'react-icons/io'
import * as ClaimServices from 'src/services/claim.service'
import LicenseAgreement from './components/LicenseAgreement'
import MSAContent from './components/MSAContent'
import { VALID_EMAIL } from '../../../constants/constants'

function TermsAndConditions({ backStep, onSubmit, getClaimBusinessPayloadMailAdapter, dealershipDetail, paymentInfo }) {
  const [formMSA] = Form.useForm()
  const [formLicense] = Form.useForm()
  const [showMSAContent, setShowMSAContent] = useState(true)
  const [showLicenseContent, setShowLicenseContent] = useState(true)
  // const [showTermContent, setShowTermContent] = useState(true)

  const { representativeName } = dealershipDetail

  useEffect(() => {
    if (dealershipDetail?.email) {
      formMSA.setFieldsValue({ ...formMSA.getFieldValue(), email: dealershipDetail.email })
      formLicense.setFieldsValue({ ...formLicense.getFieldValue(), email: dealershipDetail.email })
    }
  }, [dealershipDetail])

  const { selectedPrice } = paymentInfo

  const openNotification = ({ message, description, type = 'success' }) => {
    const action = type === 'success' ? notification.success : notification.error
    action({
      message,
      description
    })
  }

  const handleSendMail = (type, email) => {
    const payload = getClaimBusinessPayloadMailAdapter()
    return ClaimServices.employeeSendMailAgreement({ ...payload, email }, type)
  }

  const handleSendMSAEmail = async () => {
    await formMSA
      .validateFields()
      .then(() => {
        handleSendMail('msa', formMSA.getFieldValue(['email']))
          .then(() => {
            openNotification({ message: 'Send MSA successful' })
          })
          .catch((error) => {
            console.error(error)
            openNotification({ message: 'Send MSA failed', type: 'error' })
          })
      })
      .catch(() => {})
  }

  const handleSendLicenseEmail = async () => {
    await formLicense
      .validateFields()
      .then(() => {
        handleSendMail('license', formMSA.getFieldValue(['email']))
          .then(() => {
            openNotification({ message: 'Send license argreement successful' })
          })
          .catch((error) => {
            console.error(error)
            openNotification({ message: 'Send license argreement failed', type: 'error' })
          })
      })
      .catch(() => {})
  }

  // const handleSendTermEmail = async () => {
  //   await formTerm
  //     .validateFields()
  //     .then(() => {
  //       handleSendMail('term', formMSA.getFieldValue(['email']))
  //         .then(() => {
  //           openNotification({ message: 'Send terms and conditions successful' })
  //         })
  //         .catch((error) => {
  //           console.error(error)
  //           openNotification({ message: 'Send terms and conditions failed', type: 'error' })
  //         })
  //     })
  //     .catch(() => {})
  // }

  const emailFormRender = (form, handleSubmit, title) => {
    return (
      <Form
        form={form}
        layout="vertical"
        name="payment-method-ref-terms"
        className="w-full custom-form-email"
        spellCheck={false}
      >
        <div className="flex flex-col xl:flex-row gap-3 xl:gap-5 pt-7 xl:items-center justify-end">
          <div className="text-secondary-color text-base font-semibold">{`Send ${title} to my email address:`}</div>
          <div className="flex gap-4 w-full xl:w-auto xl:items-center">
            <Form.Item
              name="email"
              className="mb-0 sm-max:flex-1"
              rules={[
                { required: true, message: '' },
                {
                  pattern: VALID_EMAIL,
                  message: 'Invalid email'
                }
              ]}
            >
              <Input
                name="email"
                placeholder="Email address"
                className="w-full p-3 box-border rounded-xl border-gray-300 border"
                type="text"
                style={{ minWidth: '240px' }}
                defaultValue={dealershipDetail?.email ? dealershipDetail.email : ''}
              />
            </Form.Item>
            <Form.Item shouldUpdate className="submit mb-0">
              {() => (
                <Button
                  className="hidden sm:flex px-6 py-5 h-12 rounded-lg justify-center items-center gap-2 text-base font-semibold text-white bg-blue-700"
                  onClick={handleSubmit}
                  disabled={
                    !form.isFieldsTouched(true) ||
                    form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                  }
                >
                  Send <IoMdShareAlt className="text-xl" />
                </Button>
              )}
            </Form.Item>
            <Form.Item shouldUpdate className="submit mb-0">
              {() => (
                <Button
                  className="hidden sm-max:flex px-6 py-5 h-12 rounded-lg justify-center items-center gap-2 text-base font-semibold text-white bg-blue-700"
                  onClick={handleSubmit}
                  disabled={
                    !form.isFieldsTouched(true) ||
                    form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                  }
                >
                  <IoMdShareAlt className="text-3xl" />
                </Button>
              )}
            </Form.Item>
          </div>
        </div>
      </Form>
    )
  }

  return (
    <div className="mt-5 ml-0 xl:ml-10">
      <div className="ml-0 xl:ml-56 py-7 border-b flex flex-col gap-0 xl:gap-5">
        <div className="w-full font-bold text-base flex justify-between items-center">
          <div>MSA:</div>
          <BiChevronUp
            className={`hover:bg-blue-700 hover:text-white text-3xl ${showMSAContent ? '' : 'hidden'}`}
            onClick={() => setShowMSAContent(false)}
          />
          <BiChevronDown
            className={`hover:bg-blue-700 hover:text-white text-3xl ${showMSAContent ? 'hidden' : ''}`}
            onClick={() => setShowMSAContent(true)}
          />
        </div>
        <div className={`${showMSAContent ? '' : 'hidden'}`}>
          <MSAContent dealerName={representativeName} selectedPrice={selectedPrice} />
          {emailFormRender(formMSA, handleSendMSAEmail, 'MSA')}
        </div>
      </div>
      <div className="ml-0 xl:ml-56 py-7 border-b flex flex-col gap-0 xl:gap-5">
        <div className="w-full font-bold text-base flex justify-between items-center">
          <div>License agreement:</div>
          <BiChevronUp
            className={`hover:bg-blue-700 hover:text-white text-3xl ${showLicenseContent ? '' : 'hidden'}`}
            onClick={() => setShowLicenseContent(false)}
          />
          <BiChevronDown
            className={`hover:bg-blue-700 hover:text-white text-3xl ${showLicenseContent ? 'hidden' : ''}`}
            onClick={() => setShowLicenseContent(true)}
          />
        </div>
        <div className={`${showLicenseContent ? '' : 'hidden'}`}>
          <LicenseAgreement />
          {emailFormRender(formLicense, handleSendLicenseEmail, 'licence agreement')}
        </div>
      </div>
      {/* <div className="ml-0 xl:ml-56 py-7 border-b flex flex-col gap-0 xl:gap-5">
        <div className="w-full font-bold text-base flex justify-between items-center">
          <div>Terms and Conditions:</div>
          <BiChevronUp
            className={`hover:bg-blue-700 hover:text-white text-3xl ${showTermContent ? '' : 'hidden'}`}
            onClick={() => setShowTermContent(false)}
          />
          <BiChevronDown
            className={`hover:bg-blue-700 hover:text-white text-3xl ${showTermContent ? 'hidden' : ''}`}
            onClick={() => setShowTermContent(true)}
          />
        </div>
        <div className={`${showTermContent ? '' : 'hidden'}`}>
          <div>{termContent}</div>
          {emailFormRender(formTerm, handleSendTermEmail, ' terms and conditions')}
        </div>
      </div> */}
      <div className="flex xl:justify-end gap-5 justify-between py-7">
        <Button
          className="px-6 py-5 h-12 border-0  flex justify-center items-center gap-2 text-base font-semibold text-blue-700"
          onClick={backStep}
        >
          <BiChevronLeft className="text-xl" /> Back
        </Button>
        <Button
          className="w-48 px-6 py-5 h-12 rounded-lg flex justify-center items-center gap-2 text-base font-semibold text-white bg-blue-700"
          onClick={onSubmit}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

TermsAndConditions.propTypes = {
  backStep: PropTypes.func,
  onSubmit: PropTypes.func,
  getClaimBusinessPayloadMailAdapter: PropTypes.func,
  dealershipDetail: PropTypes.object,
  paymentInfo: PropTypes.object
}

export default TermsAndConditions
