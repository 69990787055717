import { LoadingOutlined } from '@ant-design/icons'
import { Button, Form, Input, Spin } from 'antd'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from '@reach/combobox'
import '@reach/combobox/styles.css'
import { BiChevronLeft } from 'react-icons/bi'
import Select from 'react-select'
import PhoneNumberInput from 'src/components/InputComponent/PhoneNumberInput'
import { STATES, VALID_EMAIL } from 'src/constants/constants'
import * as ClaimServices from 'src/services/claim.service'
import './style.scss'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

function LoadingIcon() {
  return <Spin indicator={antIcon} />
}

function AddYourDealershipDetail({ nextStep, setDealershipDetail, dealershipDetail, backStep, handleValidAccRequest }) {
  const [form] = Form.useForm()
  const [zipCodeResolved, setZipCodeResolved] = useState(false)
  const [foundLocation, setFoundLocation] = useState(false)

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_PLATFORM_KEY ?? '',
    libraries: ['places']
  })

  // New York is default
  const [latitude, setLatitude] = useState('-73.935242')
  const [longitude, setLongitude] = useState('40.730610')

  const googleMapsCenter = useMemo(() => ({ lat: latitude, lng: longitude }), [])
  const [googleMapsCenterSelected, setGoogleMapsCenterSelected] = useState(googleMapsCenter)

  useEffect(() => {
    fetch('https://geolocation-db.com/json/')
      .then((response) => response.json())
      .then((data) => {
        setLatitude(data.latitude)
        setLongitude(data.longitude)
        setGoogleMapsCenterSelected({ lat: data.latitude, lng: data.longitude })
        setFoundLocation(true)
      })
      .catch((error) => console.log(error))
  }, [foundLocation])



  // eslint-disable-next-line react/prop-types,react/no-unstable-nested-components
  function PlacesAutocomplete({ setGoogleMapsCenterSelected }) {
    const {
      ready,
      value,
      setValue,
      suggestions: { status, data },
      clearSuggestions
    } = usePlacesAutocomplete()

    const handleSelect = async (address) => {
      setValue(address, false)
      clearSuggestions()

      const results = await getGeocode({ address })
      form.setFieldsValue({ physicalAddress: results[0].formatted_address })
      results[0].address_components.forEach((obj) => {
        if (obj.types[0] === 'postal_code') {
          form.setFieldsValue({ zip: obj.long_name })
        }
        if (obj.types[0] === 'locality') {
          form.setFieldsValue({ city: obj.long_name })
        }
        if (obj.types[0] === 'administrative_area_level_1') {
          const stateValue = STATES.find((e) => e.name === obj.long_name)
          if (stateValue)
            form.setFieldsValue({
              state: {
                label: stateValue.name,
                value: stateValue.abbreviation
              }
            })
        }
      })
      const { lat, lng } = await getLatLng(results[0])
      setGoogleMapsCenterSelected({ lat, lng })
    }

    return (
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          className="w-full p-3"
          placeholder="Search an address"
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === 'OK' &&
              // eslint-disable-next-line camelcase
              data.map(({ place_id, description }) => (
                // eslint-disable-next-line camelcase
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    )
  }

  const onSelectState = (value) => {
    form.setFieldsValue({ ...form.getFieldsValue(), state: value })
  }

  const dealershipFormFields = [
    {
      name: 'representativeName',
      rules: [
        { required: true, message: 'Dealership name is required' },
        { max: 32, message: 'Max allowed characters is 32' }
      ],
      label: 'Dealership Name',
      placeholder: 'Add dealership name',
      className: 'md:col-span-8 col-span-12'
    },
    {
      name: 'website',
      rules: [
        { required: true, message: 'Dealership Website Address is required' },
        {
          pattern:
            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\\+\\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\\+\\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\\+~%\\/.\w-_]*)?\??(?:[-\\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
          message: 'Address is incorrect format'
        }
      ],
      label: 'Dealership Website Address',
      placeholder: 'Add website',
      className: 'md:col-span-4 col-span-12'
    },
    {
      name: 'physicalAddress',
      rules: [
        { required: true, message: 'Address is required' },
        { max: 100, message: 'Max allowed characters is 100' }
      ],
      label: 'Dealership Address',
      placeholder: 'Add address',
      className: 'md:col-span-5 col-span-12'
    },
    {
      name: 'city',
      rules: [
        { required: true, message: 'City is required' },
        { max: 20, message: 'Max allowed characters is 20' }
      ],
      label: 'Dealership City',
      placeholder: 'Add City',
      className: 'md:col-span-3 col-span-12'
    },
    {
      name: 'state',
      rules: [{ required: true, message: 'State is required' }],
      label: 'Dealership State',
      placeholder: 'Add state',
      className: 'md:col-span-4 col-span-7',
      type: 'select',
      options: STATES.map((e) => ({ value: e.abbreviation, label: e.name })),
      onSelectField: onSelectState
    },
    {
      name: 'zip',
      rules: [
        { required: true, message: 'Zip is required' },
        { min: 4, max: 5 },
        { pattern: /[0-9]/g, message: 'Only number is accepted!' }
      ],
      label: 'Dealership Zip',
      placeholder: 'Add Zip',
      className: 'md:col-span-2 col-span-5'
    },
    {
      name: 'phone',
      rules: [
        { required: true, message: 'Phone is required' },
        { pattern: /[0-9,-]{12}/g, message: 'Phone number is invalid' }
      ],
      label: 'Dealership Phone',
      placeholder: 'Add phone number',
      className: 'md:col-span-4 md:col-start-6 col-span-12',
      type: 'phone'
    }
  ]

  const personalFormFields = [
    {
      name: 'firstName',
      rules: [
        { required: true, message: 'First name is required' },
        { max: 32, message: 'Max allowed characters is 32' },
        { pattern: /^[A-Za-z]([A-Za-z ])*$/g, message: 'First name can only consist of letters' }
      ],
      label: 'Your first name',
      placeholder: 'Add first name',
      className: 'md:col-span-5 col-span-12'
    },
    {
      name: 'lastName',
      rules: [
        { required: true, message: 'Last name is required' },
        { max: 32, message: 'Max allowed characters is 32' },
        { pattern: /^[A-Za-z]([A-Za-z ])*$/g, message: 'Last name can only consist of letters' }
      ],
      label: 'Your last name',
      placeholder: 'Add last name',
      className: 'md:col-span-5 col-span-12'
    },
    {
      name: 'email',
      rules: [
        { required: true, message: 'Email is required' },
        {
          pattern: VALID_EMAIL,
          message: 'Email is invalid'
        }
      ],
      label: 'Your email',
      placeholder: 'Add email',
      className: 'md:col-span-5 md:col-start-1 col-span-12'
    },
    {
      name: 'mobile',
      rules: [
        { required: true, message: 'Mobile number is required' },
        { pattern: /[0-9,-]{12}/g, message: 'Phone number is invalid' }
      ],
      label: 'Your Mobile Phone',
      placeholder: 'Add mobile phone',
      className: 'md:col-span-5 md:col-start-6 col-span-12',
      type: 'phone'
    }
  ]

  const onSubmitForm = useCallback(async () => {
    const values = form.getFieldsValue()

    values.role = 'Dealer'

    await form
      .validateFields()
      .then(() => {
        setDealershipDetail(values)
        handleValidAccRequest(nextStep, values.email)
      })
      .catch(() => {})
  }, [form])

  const onFormChange = (values) => {
    form.setFieldsValue({ ...form.getFieldsValue(), [values.target.name]: values.target.value })
  }

  const fieldRenderer = useCallback((field) => {
    switch (field.type) {
      case 'select':
        return (
          <Select
            placeholder={field.placeholder}
            name={field.name}
            onChange={field.onSelectField}
            options={field.options.filter((item) => !item.hidden)}
            components={{
              IndicatorSeparator: () => null
            }}
            styles={{
              control: (provided) => ({
                ...provided,
                width: '100%',
                borderRadius: '10px',
                height: '50px'
              }),
              placeholder: (provided) => ({
                ...provided,
                fontStyle: 'italic'
              }),
              menu: (provided) => ({
                ...provided
              })
            }}
          />
        )
      case 'phone':
        return (
          <PhoneNumberInput
            placeholder={field.placeholder}
            name={field.name}
            className="w-full p-3 box-border rounded-lg border-gray-300 border focus:outline-none"
          />
        )
      default:
        return (
          <Input
            placeholder={field.placeholder}
            name={field.name}
            className="w-full p-3 box-border rounded-lg border-gray-300 border focus:outline-none"
            type={field.type || 'text'}
          />
        )
    }
  }, [])

  useEffect(() => {
    if (dealershipDetail && Object.keys(dealershipDetail).length > 0) {
      form.setFieldsValue(dealershipDetail)
    }
  }, [dealershipDetail])

  const onResolvingZipCode = async () => {
    const zipCode = form.getFieldValue('zip')
    if (zipCode) {
      setZipCodeResolved(true)
      try {
        const location = await ClaimServices.getLocationFromZipCode(zipCode)

        if (location) {
          const { city, region } = location.data
          if (city && city !== '-') form.setFieldsValue({ city })
          const state = STATES.find((e) => e.name === region)
          if (state) {
            form.setFieldsValue({ state: { value: state.abbreviation, label: state.name } })
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        setZipCodeResolved(false)
      }
    }
  }

  return (
    <div className="grid grid-cols-4 mt-24 add-your-dealership-detail">
      <Form
        form={form}
        className="md:col-start-2 md:col-span-3 col-span-12"
        name="add-your-dealership-form-ref"
        onChange={onFormChange}
        layout="vertical"
      >
        <div className="dealer-info-container grid grid-cols-12 gap-x-5">
          {dealershipFormFields?.map((field, key) => {
            return (
              <div className={field.className} key={`dealership-form-field-${key}`}>
                <p className="pb-2 text-gray-500 text-base">{field.label}:</p>
                <Form.Item name={field.name} className="flex flex-col" rules={field.rules} validateTrigger="onChange">
                  {field.name === 'zip' ? (
                    <Input
                      suffix={zipCodeResolved ? <LoadingIcon /> : undefined}
                      name={field.name}
                      onPressEnter={onResolvingZipCode}
                      placeholder={field.placeholder}
                      className="w-full p-3 box-border rounded-xl border-gray-300 border"
                      type="number"
                    />
                  ) : (
                    fieldRenderer(field)
                  )}
                </Form.Item>
              </div>
            )
          })}
        </div>

        {isLoaded && foundLocation && (
          <div className="dealer-info-container mt-8">
            <div className="places-container">
              <PlacesAutocomplete setGoogleMapsCenterSelected={setGoogleMapsCenterSelected} />
            </div>
            <GoogleMap zoom={10} center={googleMapsCenterSelected} mapContainerClassName="google-map-container">
              {googleMapsCenterSelected && <Marker position={googleMapsCenterSelected} />}
            </GoogleMap>
          </div>
        )}

        <hr className="my-8" />
        <div className="personal-info-container grid grid-cols-12 gap-x-5">
          {personalFormFields?.map((field, key) => {
            return (
              <div key={`personal-form-field-${key}`} className={field.className}>
                <p className="pb-2 text-gray-500 text-base">{field.label}:</p>
                <Form.Item
                  name={field.name}
                  className="flex flex-col"
                  rules={field.rules}
                  key={`dealership-form-field-${key}`}
                >
                  {fieldRenderer(field)}
                </Form.Item>
              </div>
            )
          })}
        </div>
      </Form>
      <div className="flex md:col-start-4 col-span-12 text-right sm-max:justify-between mt-5">
        <Button
          className="px-6 py-5 h-12 border-0  flex justify-center items-center gap-2 text-base font-semibold text-blue-700"
          onClick={backStep}
        >
          <BiChevronLeft className="text-xl" /> Back
        </Button>
        <Button
          type="primary"
          onClick={() => onSubmitForm()}
          className="bg-blue-600 h-12 w-60 font-bold rounded-xl px-10"
        >
          Next
        </Button>
      </div>
    </div>
  )
}

AddYourDealershipDetail.propTypes = {
  nextStep: PropTypes.func,
  backStep: PropTypes.func,
  setDealershipDetail: PropTypes.func,
  dealershipDetail: PropTypes.object,
  handleValidAccRequest: PropTypes.func
}

export default AddYourDealershipDetail
