import { Button, Modal } from 'antd'
import * as PropTypes from 'prop-types'
import React from 'react'

function CashDownPopup({
  cashDown,
  showCashDownPopupConfirm,
  setShowCashDownPopupConfirm,
  handleCashDownUpdate,
  closable,
  maskClosable
}) {
  const handleClose = () => {
    setShowCashDownPopupConfirm(false)
  }

  const cashDownLabel = () => {
    if (cashDown !== undefined) {
      return cashDown.toLocaleString()
    }
    return '0'
  }

  return (
    <Modal
      centered
      title="Update cash down"
      visible={showCashDownPopupConfirm}
      closable={closable}
      maskClosable={maskClosable}
      onCancel={() => setShowCashDownPopupConfirm(false)}
      className="calendar-modal"
      width={450}
      footer={[
        <div className="flex justify-between gap-4">
          <Button block onClick={handleCashDownUpdate}>
            Yes
          </Button>
          <Button block onClick={handleClose}>
            No
          </Button>
        </div>
      ]}
    >
      <div className="w-full text-center">
        <p>Would you like to save ${cashDownLabel()} as you default down payment</p>
      </div>
    </Modal>
  )
}

CashDownPopup.propTypes = {
  cashDown: PropTypes.any,
  showCashDownPopupConfirm: PropTypes.bool,
  setShowCashDownPopupConfirm: PropTypes.func,
  handleCashDownUpdate: PropTypes.func,
  closable: PropTypes.bool,
  maskClosable: PropTypes.bool
}

export default CashDownPopup
