import { InputNumber, Radio, Space } from 'antd'
import React, { useState } from 'react'
import { STORAGE_KEYS } from 'src/constants/constants'
import { load, save } from 'src/lib/storage'
import kelleyImage from '../../../assets/kbb-logo.png'
import './style.scss'

function DownPayment() {
  const previousValue = load(STORAGE_KEYS.EZ_DEALZ_BUDGET) || {}
  const selectedTypeIsPercentage = previousValue.percentage !== undefined
  const [type, setType] = useState(selectedTypeIsPercentage ? 'percentage' : 'cash')
  const [value, setValue] = useState({
    percentage: previousValue.percentage || 0,
    cash: previousValue.cash || 0
  })

  const handleNumberOnlyInput = (event) => {
    return event.keyCode === 69 || event.keyCode === 189 || event.keyCode === 187 ? event.preventDefault() : true
  }

  const handleValueChange = (changeValue) => {
    delete previousValue.cash
    delete previousValue.percentage
    save(STORAGE_KEYS.EZ_DEALZ_BUDGET, JSON.stringify({ ...previousValue, [type]: changeValue }))
    if (type === 'percentage') {
      setValue({
        ...value,
        percentage: changeValue
      })
    } else {
      setValue({
        ...value,
        cash: changeValue
      })
    }
  }

  return (
    <div className="p-6 px-8 w-full bg-white text-black">
      <p className="font-bold text-2xl">Down Payment</p>
      <p className="font-extrabold text-orange-500 text-3xl">
        {type === 'percentage'
          ? `${value.percentage !== null ? value.percentage : 0}%`
          : `$${value.cash !== null ? parseFloat(value.cash).toLocaleString() : 0}`}
      </p>
      <p className="text-base text-justify my-4">
        Your Down Payment lowers your monthly payments and overall amount financed, helping you pay off the vehicle
        quicker and giving you more equity in your purchase. Banks typically look for a 20% down payment of the purchase
        price of the vehicle. Here you can use that or put in your own dollar amount or percentage to fit your budget.
      </p>
      <div className=" border-b-2 pb-12">
        <p className="text-base">Enter your payment below.</p>
        <div className="flex w-full mt-4">
          <Radio.Group onChange={(e) => setType(e.target.value)} value={type}>
            <Space direction="vertical">
              <Radio value="percentage" className=" mb-2">
                Percentage of Price
              </Radio>
              <Radio value="cash">Cash Down</Radio>
            </Space>
          </Radio.Group>
          <div className="ml-12 w-1/2">
            <p className="text-lg mb-2 text-black-600 font-semibold">Down Payment</p>
            {type !== 'percentage' && (
              <InputNumber
                min={0}
                formatter={(val) => `$ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(val) => val.replace(/\$\s?|(,*)/g, '')}
                defaultValue={value.cash}
                className="w-3/4"
                onChange={handleValueChange}
                onKeyDown={handleNumberOnlyInput}
              />
            )}
            {type === 'percentage' && (
              <InputNumber
                min={0}
                max={100}
                formatter={(val) => `${val}%`}
                parser={(val) => val.replace('%', '')}
                defaultValue={value.percentage}
                className="w-3/4"
                onChange={handleValueChange}
                onKeyDown={handleNumberOnlyInput}
              />
            )}
          </div>
        </div>
      </div>
      <div className="-mx-8 bg-lightgray mt-10 p-6 pt-0">
        <div className="bg-blue-100 p-6 flex">
          <img src={kelleyImage} alt="" width="120" />
          <p className="ml-6 text-justify text-sky-400">
            Kelley Blue Book® experts suggest puting 20% or more down to avoid being &quot;upside-down&quot; on your car
            loan.
          </p>
        </div>
      </div>
    </div>
  )
}

DownPayment.propTypes = {}

export default DownPayment
