import { Button, Form, Input, Modal } from 'antd'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from '@reach/combobox'
import '@reach/combobox/styles.css'
import { BiChevronLeft } from 'react-icons/bi'
import Select from 'react-select'
import PhoneNumberInput from 'src/components/InputComponent/PhoneNumberInput'
import { STATES, VALID_EMAIL } from 'src/constants/constants'
import './style.scss'

function validateEmailWithDomain(email, domain) {
  if (email && email.includes('@powersportsx.com')) return true
  if (domain) {
    const [, domainTail] = domain.includes('://www.') ? domain.split('://www.') : domain.split('://')
    const [, emailTail] = email.split('@')
    return domainTail?.replace('/', '') === emailTail
  }
  return false
}

function VerifyYourAccountOwnership({
  nextStep,
  selectedDealership,
  backStep,
  dealershipDetail,
  setDealershipDetail,
  handleValidAccRequest
}) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_PLATFORM_KEY ?? '',
    libraries: ['places']
  })

  // New York is default
  const dealershipLat = dealershipDetail.lat ?? '-73.935242'
  const dealershipLng = dealershipDetail.lng ?? '40.730610'

  const googleMapsCenter = useMemo(() => ({ lat: dealershipLat, lng: dealershipLng }), [])
  const [googleMapsCenterSelected, setGoogleMapsCenterSelected] = useState(googleMapsCenter)

  const [form] = Form.useForm()
  const { powersportsDealerUrl, email, dealerName } = selectedDealership

  const [emailErrorMessage, setEmailErrorMessage] = useState('')

  const [modal, setModal] = useState(false)

  const [isEnabledEditAddress, setIsEnabledEditAddress] = useState(true)

  const dealershipFormFields = [
    {
      name: 'firstName',
      rules: [
        { required: true, message: 'First name is required' },
        { max: 32, message: 'Max allowed characters is 32' },
        { pattern: /^[A-Za-z]([A-Za-z ])*$/g, message: 'First name can only consist of letters' }
      ],
      label: 'Your first name',
      placeholder: 'Add first name',
      className: 'md:col-span-5 col-span-12'
    },
    {
      name: 'lastName',
      rules: [
        { required: true, message: 'Last name is required' },
        { max: 32, message: 'Max allowed characters is 32' },
        { pattern: /^[A-Za-z]([A-Za-z ])*$/g, message: 'Last name can only consist of letters' }
      ],
      label: 'Your last name',
      placeholder: 'Add last name',
      className: 'md:col-span-5 col-span-12'
    },
    {
      name: 'email',
      rules: [
        { required: true, message: 'Email is required' },
        {
          pattern: VALID_EMAIL,
          message: 'Email is invalid'
        }
      ],
      label: 'Email Address',
      placeholder: 'Add email',
      className: 'md:col-span-5 md:col-start-1 col-span-12'
    },
    {
      name: 'phone',
      rules: [{ pattern: /(___-___-____)|([0-9,-,\\),\\(,\s])/g, message: 'Phone number is invalid' }],
      label: 'Dealership Phone',
      placeholder: 'Add mobile phone',
      className: 'md:col-span-5 md:col-start-6 col-span-12',
      type: 'phone'
    },
    {
      name: 'hasEmailAddress',
      placeholder: 'Add state',
      className: 'col-span-12 col-start-1',
      type: 'checkbox',
      label: "I don't have a dealership email address"
    },
    {
      name: 'mobile',
      rules: [
        { required: true, message: 'Mobile number is required' },
        { pattern: /[0-9,-]{12}/g, message: 'Phone number is invalid' }
      ],
      label: 'Mobile Phone',
      placeholder: 'Add mobile phone',
      className: 'md:col-span-5 md:col-start-1 col-span-12',
      type: 'phone'
    }
  ]

  const onEditAddress = async () => {
    if (isEnabledEditAddress) {
      form
        .validateFields(['physicalAddress', 'city', 'state', 'zip'])
        .then(() => {
          setIsEnabledEditAddress(false)
        })
        .catch(() => {})
    } else {
      setIsEnabledEditAddress(true)
    }
  }

  const onFormChange = (values) => {
    if (values.target.value === '___-___-____' && values.target.name === 'phone') {
      form.setFieldsValue({ ...form.getFieldsValue(), phone: '' })
    } else if (values.target.name === 'hasEmailAddress') {
      setEmailErrorMessage('')
      form.setFieldsValue({ ...form.getFieldsValue(), [values.target.name]: values.target.checked })
    } else {
      if (values.target.name === 'email') setEmailErrorMessage('')
      form.setFieldsValue({ ...form.getFieldsValue(), [values.target.name]: values.target.value })
    }
  }

  const fieldRenderer = useCallback((field) => {
    switch (field.type) {
      case 'select':
        return (
          <Select
            components={{
              IndicatorSeparator: () => null
            }}
            placeholder={field.placeholder}
            className="w-full"
            onChange={field.onSelectField}
            options={field.options.filter((item) => !item.hidden)}
            styles={{
              control: (provided) => ({
                ...provided,
                width: '100%',
                borderRadius: '10px',
                height: '50px'
              }),
              placeholder: (provided) => ({
                ...provided,
                fontStyle: 'italic'
              }),
              menu: (provided) => ({
                ...provided
              })
            }}
          />
        )
      case 'phone':
        return (
          <PhoneNumberInput
            placeholder={field.placeholder}
            className="w-full p-3 box-border rounded-lg border-gray-300 border focus:outline-none"
            value={form.getFieldValue(field.name)}
          />
        )
      default:
        return (
          <Input
            name={field.name}
            placeholder={field.placeholder}
            className="w-full p-3 box-border rounded-lg border-gray-300 border focus:outline-none"
            type={field.type || 'text'}
          />
        )
    }
  }, [])

  const onSubmitForm = async () => {
    const values = form.getFieldsValue()

    values.role = 'Dealer'

    const isEmailValid = validateEmailWithDomain(form.getFieldValue('email') || '', powersportsDealerUrl || email)
    await form
      .validateFields(['email'])
      .then(() => {
        if (!form.getFieldValue('hasEmailAddress') && !isEmailValid) {
          setEmailErrorMessage('Different email and domain, please insert a valid email')
        }
      })
      .catch((error) => {
        console.error(error)
      })
    await form
      .validateFields()
      .then(() => {
        setDealershipDetail({ ...dealershipDetail, ...values })
        if (form.getFieldValue('hasEmailAddress')) {
          handleValidAccRequest(() => setModal(true), values.email)
        } else if (isEmailValid) {
          handleValidAccRequest(nextStep, values.email)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    if (dealershipDetail && Object.keys(dealershipDetail).length > 0) {
      form.setFieldsValue(dealershipDetail)
      form.setFieldsValue({ zip: String(dealershipDetail.zip) })
      const stateValue = STATES.find((e) => e.name === dealershipDetail.state)
      if (stateValue) form.setFieldsValue({ state: { label: stateValue.name, value: stateValue.abbreviation } })
    }
  }, [dealershipDetail])

  // eslint-disable-next-line react/prop-types,react/no-unstable-nested-components
  function PlacesAutocomplete({ setGoogleMapsCenterSelected }) {
    const {
      ready,
      value,
      setValue,
      suggestions: { status, data },
      clearSuggestions
    } = usePlacesAutocomplete()

    const handleSelect = async (address) => {
      setValue(address, false)
      clearSuggestions()

      const results = await getGeocode({ address })
      form.setFieldsValue({ physicalAddress: results[0].formatted_address })
      results[0].address_components.forEach((obj) => {
        if (obj.types[0] === 'postal_code') {
          form.setFieldsValue({ zip: obj.long_name })
        }
        if (obj.types[0] === 'locality') {
          form.setFieldsValue({ city: obj.long_name })
        }
        if (obj.types[0] === 'administrative_area_level_1') {
          const stateValue = STATES.find((e) => e.name === obj.long_name)
          if (stateValue)
            form.setFieldsValue({
              state: {
                label: stateValue.name,
                value: stateValue.abbreviation
              }
            })
        }
      })
      const { lat, lng } = await getLatLng(results[0])
      setGoogleMapsCenterSelected({ lat, lng })
    }

    return (
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          className={classNames({ 'address-input-border': isEnabledEditAddress }, 'text-base w-full')}
          placeholder="Search an address"
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === 'OK' &&
              // eslint-disable-next-line camelcase
              data.map(({ place_id, description }) => (
                // eslint-disable-next-line camelcase
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    )
  }

  return (
    <div className="grid grid-cols-4 mt-24 verify-your-account-container">
      <Form
        form={form}
        className="md:col-start-2 md:col-span-3 col-span-12"
        name="add-your-dealership-form-ref"
        onChange={onFormChange}
        layout="vertical"
      >
        <div className="dealer-info-container grid grid-cols-12 gap-x-5">
          {dealershipFormFields?.map((field, key) => {
            return (
              <div className={field.className} key={`dealer-info-form-${field.label}`}>
                {field.label && field.type !== 'checkbox' && (
                  <p className="pb-2 text-gray-500 text-base">{field.label}:</p>
                )}
                {field.type === 'checkbox' ? (
                  <div className="flex items-center mb-4">
                    <Form.Item
                      name={field.name}
                      className="flex flex-col mb-0 mt-1"
                      rules={field.rules}
                      validateTrigger="onChange"
                      key={`dealership-form-field-${key}`}
                    >
                      <Input
                        name={field.name}
                        placeholder={field.placeholder}
                        className="w-full p-3 box-border rounded-lg border-gray-300 border focus:outline-none mb-0"
                        type="checkbox"
                      />
                    </Form.Item>
                    <p className="ml-2">{field.label}</p>
                  </div>
                ) : (
                  <Form.Item
                    name={field.name}
                    className="flex flex-col"
                    rules={field.rules}
                    validateTrigger="onChange"
                    key={`dealership-form-field-${key}`}
                  >
                    {fieldRenderer(field)}
                  </Form.Item>
                )}
                {!!emailErrorMessage && field.name === 'email' && (
                  <p className="md:col-start-2 col-span-4 text-red-500">{emailErrorMessage}</p>
                )}
              </div>
            )
          })}
        </div>
        {/* Edit mode */}
        <div className={classNames('grid grid-cols-12', { hidden: !isEnabledEditAddress })}>
          <div className="md:col-start-1 md:col-span-6 text-md mt-4 mb-4 md:mb-4 col-span-12 text-center md:text-left">
            <p className="mb-6 text-base">Dealership address</p>
            <div className="font-bold text-base mb-2">{dealerName}</div>

            <p className="pt-2 text-gray-500 text-base text-left">Dealer Address:</p>
            <Form.Item
              name="physicalAddress"
              rules={[
                { required: true, message: 'Address is required' },
                { max: 100, message: 'Max allowed characters is 100' }
              ]}
              validateTrigger="onChange"
            >
              <input
                className={classNames({ 'address-input-border': isEnabledEditAddress }, 'text-base w-full')}
                bordered={false}
                name="physicalAddress"
                disabled={!isEnabledEditAddress}
                required
              />
            </Form.Item>
            <p className="pt-2 text-gray-500 text-base text-left">Dealer City:</p>
            <Form.Item
              name="city"
              rules={[
                { required: true, message: 'City is required' },
                { max: 20, message: 'Max allowed characters is 20' },
                { pattern: /^[^0-9]+$/g, message: 'City can only consist of letters' }
              ]}
              validateTrigger="onChange"
            >
              <input
                bordered={false}
                className={classNames({ 'address-input-border': isEnabledEditAddress }, 'text-base w-full')}
                name="city"
                disabled={!isEnabledEditAddress}
                required
              />
            </Form.Item>
            <p className="pt-2 text-gray-500 text-base text-left">Dealer Zip:</p>
            <Form.Item
              name="zip"
              rules={[
                { required: isEnabledEditAddress, message: 'Zip is required' },
                { min: 4, max: 5 },
                { pattern: /[0-9]/g, message: 'Only number is accepted!' }
              ]}
              validateTrigger="onChange"
            >
              <input
                className={classNames({ 'address-input-border': isEnabledEditAddress }, 'text-base w-full')}
                bordered={false}
                name="zip"
                disabled={!isEnabledEditAddress}
              />
            </Form.Item>
            <p className="pt-2 text-gray-500 text-base text-left">Dealer State:</p>
            <Form.Item
              name="state"
              className="flex flex-col mr-2 address-input-border"
              rules={[{ required: true, message: 'State is required' }]}
              validateTrigger="onChange"
            >
              <Select
                options={STATES.map((e) => ({ value: e.abbreviation, label: e.name }))}
                name="state"
                required
                className="text-base w-full"
                isDisabled={!isEnabledEditAddress}
                components={{
                  IndicatorSeparator: () => null
                }}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                    border: 'none',
                    height: '10px',
                    padding: 0
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: 0
                  })
                }}
              />
            </Form.Item>
          </div>
        </div>
        {/* Text mode */}
        {!isEnabledEditAddress && (
          <div>
            <div className="md:col-start-2 md:col-span-3 text-md mt-4 mb-0 md:mb-4 col-span-12 text-center md:text-left">
              <div className="mb-4">Dealership address</div>
              <div className="font-bold">{dealerName}</div>
              {form.getFieldValue('physicalAddress') === '' ? setIsEnabledEditAddress(true) : null}
              <div>{form.getFieldValue('physicalAddress')}</div>
              <div>{`${form.getFieldValue('city')}`}</div>
              <div>{`${form.getFieldValue('state')?.value} ${form.getFieldValue('zip')}`}</div>
            </div>
          </div>
        )}
        <div className="text-center md:text-left">
          <Button className="hidden w-20 font-bold rounded-xl px-5" onClick={onEditAddress}>
            {isEnabledEditAddress ? 'Save' : 'Edit'}
          </Button>
        </div>
        {isLoaded && (
          <div className="text-center md:text-left mb-8">
            <div className="places-container">
              <PlacesAutocomplete setGoogleMapsCenterSelected={setGoogleMapsCenterSelected} />
            </div>
            <GoogleMap zoom={10} center={googleMapsCenterSelected} mapContainerClassName="google-map-container">
              {googleMapsCenterSelected && <Marker position={googleMapsCenterSelected} />}
            </GoogleMap>
          </div>
        )}
      </Form>

      <div className="md:col-start-4 col-span-12 flex sm-max:justify-between justify-end">
        <Button
          className="px-6 py-5 h-12 border-0 flex justify-center items-center gap-2 text-base font-semibold text-blue-700"
          onClick={backStep}
        >
          <BiChevronLeft className="text-xl" /> Back
        </Button>
        <Button type="primary" onClick={() => onSubmitForm()} className="h-12 w-60 font-bold rounded-xl px-10">
          Next
        </Button>
      </div>
      <Modal
        centered
        visible={modal}
        onCancel={() => setModal(false)}
        className="verify-account-modal-alert"
        width={650}
        footer={null}
      >
        <div className="md:m-14 m-8 flex flex-col justify-center items-center">
          <p className="text-lg text-center">
            MotorMarketUSA Support will need to verify with your dealership your account ownership
          </p>
          <div className="mt-5 w-36">
            <Button
              className="w-full h-10 font-bold"
              type="primary"
              onClick={() => {
                setModal(false)
                nextStep()
              }}
            >
              Ok
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

VerifyYourAccountOwnership.propTypes = {
  nextStep: PropTypes.func,
  backStep: PropTypes.func,
  selectedDealership: PropTypes.object.isRequired,
  setDealershipDetail: PropTypes.func,
  dealershipDetail: PropTypes.object,
  handleValidAccRequest: PropTypes.func
}

export default VerifyYourAccountOwnership
