import React from 'react'
import PropTypes from 'prop-types'
import MetaDecorator from './MetaDecorator'

const hostname = process.env.REACT_APP_API_FRONTEND

function SinglePageMetaDecorator({ title, description, imageUrl, imageAlt }) {
  return (
    <MetaDecorator title={title} description={description} imageUrl={`${hostname}${imageUrl}`} imageAlt={imageAlt} />
  )
}

SinglePageMetaDecorator.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired
}

export default SinglePageMetaDecorator
