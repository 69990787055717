import React, { useEffect, useState } from 'react'
import { Form, Button, notification } from 'antd'
import * as PropTypes from 'prop-types'
import * as DealerService from '../../services/dealer.service'
import TableRows from './tableRows'
import './style.scss'

function AddDeleteTableRows({ dealerId, canManagePaymentSettings }) {
  const [rowsData, setRowsData] = useState([])
  const [form] = Form.useForm()

  useEffect(() => {
    DealerService.getDealerFees(dealerId)
      .then((response) => {
        setRowsData(response.data)
      })
      .catch((error) => {
        setRowsData([])
        console.error(error)
      })
  }, [])

  const addTableRows = () => {
    const rowsInput = {
      name: '',
      value: 0
    }
    setRowsData([...rowsData, rowsInput])
  }

  const deleteTableRows = (index) => {
    const rows = [...rowsData]
    rows.splice(index, 1)
    setRowsData(rows)
  }

  const handleChange = (index, event) => {
    const { name, value } = event.target
    const rowsInput = [...rowsData]
    rowsInput[index][name] = value
    setRowsData(rowsInput)
  }

  const handleFormSubmission = async () => {
    const parameters = {}
    parameters.fees = rowsData
    parameters.dealer_id = dealerId

    DealerService.dealerFeesUpdate(parameters)
      .then((response) => {
        setRowsData(response.data)
        notification.success({
          message: 'Success!',
          description: 'Updated dealer fees successful.'
        })
      })
      .catch((error) => {
        notification.error({
          message: 'Error!',
          description: 'Updated dealer fees failed.'
        })
        console.error(error)
      })
  }

  return (
    <Form form={form} onFinish={handleFormSubmission}>
      <div className="ant-table-wrapper payment-settings-table">
        <div className="ant-spin-nested-loading">
          <div className="ant-spin-container">
            <div className="ant-table ant-table-scroll-horizontal">
              <div className="ant-table-container">
                <div className="ant-table-container ant-table-container-hidden">
                  <table className="table-ant-table-content">
                    <thead className="ant-table-thead">
                      <tr>
                        <th className="ant-table-cell">Name</th>
                        <th className="ant-table-cell">Value</th>
                        {canManagePaymentSettings && (
                          <th className="ant-table-cell text-right">
                            <Button type="primary" className="rounded text-black" onClick={addTableRows}>
                              Add New Fee
                            </Button>
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody className="ant-table-tbody">
                      <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} canManagePaymentSettings={canManagePaymentSettings} />
                    </tbody>
                  </table>
                </div>
                {canManagePaymentSettings && (
                  <div className="ant-table-content">
                    <Form.Item className="flex items-end w-full mt-8 submit" shouldUpdate>
                      {() => (
                        <button
                          type="submit"
                          className="bg-primary hover:bg-activePrimary font-semibold rounded-lg px-8 py-2 outline-none text-lg w-fit text-white flex justify-center"
                        >
                          Save
                        </button>
                      )}
                    </Form.Item>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  )
}

AddDeleteTableRows.propTypes = {
  dealerId: PropTypes.number,
  canManagePaymentSettings: PropTypes.bool
}

export default AddDeleteTableRows
