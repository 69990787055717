/* eslint-disable no-unreachable */
import { Button, Input, Modal, notification, Radio, Space } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import ReactInputMask from 'react-input-mask'
import { STORAGE_KEYS, VALID_EMAIL } from 'src/constants/constants'
import { useAuthorizationContext } from '../../AuthorizationProvider'
import * as VDPDealerForm from '../../services/vdp-dealerForm.service'
import LoginFormModal from '../Vehicle/BoxButtonGroup/LoginFormModal'
import LoginPopup from '../Vehicle/BoxButtonGroup/LoginPopup'

const { TextArea } = Input
const emailValidation = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,4})+$/
const phoneValidation = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/

function ContactDealerForm({ info }) {
  const { customerAccount } = useAuthorizationContext()
  const customerId = customerAccount !== undefined ? customerAccount.id : ''
  const [dealerInfo, setDelarInfo] = useState({ interest: "I'm interested in this" })
  const [show, setShow] = useState(false)
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [hasComment, setHasComment] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [showPromptModal, setShowPromptModal] = useState(false)
  const [submitAnotherInquiry, setSubmitAnotherInquiry] = useState(false)
  const [radioValue, setRadioValue] = useState('default')
  const [reason, setReason] = useState('')
  const [currentLeadId, setCurrentLeadId] = useState()
  const { vehicle } = info || {}
  const { _id: vehicleId } = vehicle || {}
  const [contactDealerFormSents, setContactDealerFormSents] = useState(
    JSON.parse(localStorage.getItem(STORAGE_KEYS.CONTACT_DEALER_FORM_SENT) || '[]')
  )

  const handleShowLoginForm = () => {
    setShowLoginForm(true)
    setShow(false)
  }

  const callbackForForm = () => {
    setShowLoginForm(false)
  }

  useEffect(() => {
    if (customerAccount !== undefined) {
      const email = customerAccount.email
      const phone = customerAccount.phone
      const firstName = customerAccount.first_name
      const lastName = customerAccount.last_name
      const zipcode = customerAccount.zip
      setDelarInfo({ ...dealerInfo, email, phone, firstName, lastName, zipcode })
    }
  }, [JSON.stringify(customerAccount)])

  const handleInterested = (event) => {
    const interest = event.target.value
    setDelarInfo({ ...dealerInfo, interest })
  }

  const openNotification = (type, errMessage) => {
    const errorMessage = errMessage === undefined ? 'Incomplete form field' : errMessage
    const action = type === 'success' ? notification.success : notification.error
    const message = type === 'success' ? 'Information is sent.' : errorMessage
    action({
      message,
      placement: 'topRight'
    })
  }

  const handleChange = (e) => {
    const label = e.target.name
    const value = e.target.value
    switch (label) {

      case 'zipcode':
        if (value.length <= 4) {
          setDelarInfo({ ...dealerInfo, [label]: value })
        }
        break
      default:
        setDelarInfo({ ...dealerInfo, [label]: value })
        break
    }
  }

  const handleComment = () => {
    setHasComment(!hasComment)
  }

  const handleSendEmail = () => {
    setIsLoading(true)
    const dealerId = info?.dealer_id
    const vehicleName = `${vehicle?.year} ${vehicle?.make} ${vehicle?.model}`
    const pageURL = window.location.href
    VDPDealerForm.storeLeadSendEmail({
      ...dealerInfo,
      vehicleName,
      dealerId,
      pageURL,
      vehicleId: info._id,
      customerId,
      reason: radioValue === 'default' ? 'Did not receive a response from the dealership' : reason,
      leadId: currentLeadId
    })
      .then((response) => {
        const message = response?.message || null
        if (message === 'email_exists_ask_customer_to_login') {
          setShow(true)
        } else if (message === 'created_another_inquiry_for_this_vehicle') {
          setShowPromptModal(true)
        } else {
          openNotification('success')
        }
        setDelarInfo({ interest: "I'm interested in this" })
        document.getElementById('cdf-form').reset()
        const newContactDealerFormSents = [...contactDealerFormSents, vehicleId]
        setContactDealerFormSents(newContactDealerFormSents)
        localStorage.setItem(STORAGE_KEYS.CONTACT_DEALER_FORM_SENT, JSON.stringify(newContactDealerFormSents))
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message || null
        if (errorMessage === 'email_exists_invalid_role') {
          openNotification('error', 'This email address cannot be used')
        } else {
          openNotification('error')
        }
      })
      .finally(() => setIsLoading(false))
  }

  const handleCheckExistLead = () => {
    setIsLoading(true)
    VDPDealerForm.checkExistLead({
      vehicleId: info._id,
      customerId,
      interest: dealerInfo.interest,
      email: dealerInfo.email
    })
      .then((response) => {
        const { isExist, lead } = response.data
        if (isExist) {
          setCurrentLeadId(lead._id)
          setShowPromptModal(true)
          setIsLoading(false)
        } else {
          handleSendEmail()
        }
      })
      .catch((error) => {
        console.error(error)
        setShowPromptModal(true)
        setIsLoading(false)
      })
  }

  const handleSubmitForm = (e) => {
    e.preventDefault()

    if (
      Object.keys(dealerInfo).length >= 6 &&
      (emailValidation.test(dealerInfo.email) || phoneValidation.test(dealerInfo.phone)) &&
      !isLoading
    ) {
      handleCheckExistLead()
    }
  }

  return (
    <div className="my-4 px-4 py-5 bg-gray-100 rounded-xl w-full flex items-center flex-col">
      <form id="cdf-form" onSubmit={(e) => handleSubmitForm(e)} className="w-full">
        <p className="text-xl font-semibold text-left w-full">Contact dealership</p>
        <p className="mt-5 text-base w-full">
          Hello, my name is
          <input
            name="firstName"
            className="mx-2 px-2 w-1/2 lg:w-1/4 md:1/4"
            placeholder="First name"
            type="text"
            required
            value={dealerInfo.firstName || ''}
            onChange={handleChange}
          />
          <input
            name="lastName"
            className="mx-2 px-2 w-1/2 lg:w-1/4 md:1/4 mt-2 lg:mt-0"
            placeholder="Last name"
            type="text"
            required
            value={dealerInfo.lastName || ''}
            onChange={handleChange}
          />
          and
          <select
            name="interest"
            placeholder="Intersted"
            onChange={handleInterested}
            className="mt-2 w-full"
            value={dealerInfo?.interest}
            aria-label="interest"
          >
            <option value="I'm interested in this">{`I'm interested in this`}</option>
            <option value="I'd like to test drive this">{`I'd like to test drive this`}</option>
            <option value="I'd like a history report for this">{`I'd like a history report for this`}</option>
            <option value="I'd like to know your best price for this">{`I'd like to know your best price for this`}</option>
          </select>
          <span className="font-semibold">{`${vehicle?.year || ''} ${vehicle?.make || ''} ${
            vehicle?.model || ''
          }`}</span>
          . I&apos;m in the
          <input
            name="zipcode"
            className="m-2 px-2 w-1/2 lg:w-3/12"
            placeholder="ZIP"
            type="number"
            required
            value={dealerInfo.zipcode || ''}
            maxLength={5}
            onChange={handleChange}
          />
          area. You can reach me by email at{' '}
          <input
            name="email"
            type="email"
            required
            placeholder="Email Address"
            pattern={new RegExp(VALID_EMAIL).toString()}
            title="Invalid email address pattern. e.g. xxx@xxx.xxx"
            style={{ width: 200 }}
            value={dealerInfo.email || ''}
            onChange={handleChange}
            className="px-2 mt-2"
          />{' '}
          or by phone at{' '}
          <ReactInputMask
            mask="999-999-9999"
            className="px-2.5 w-44 mt-2"
            name="phone"
            required={!dealerInfo.email}
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            placeholder="123-456-7890"
            title="Invalid phone number pattern. e.g. 123-456-7890"
            value={dealerInfo.phone || ''}
            onChange={handleChange}
            type="text"
          />
          {/* <div className="relative rounded mt-2" style={{ width: 175 }}>
            <ReactInputMask
              mask="999-999-9999"
              className="px-2.5 w-full"
              name="phone"
              required={!dealerInfo.email}
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              placeholder="123-456-7890"
              title="Invalid phone number pattern. e.g. 123-456-7890"
              value={dealerInfo.phone}
              onChange={handleChange}
              type="text"
            />
          </div> */}
          {/* </div> */}
          {hasComment && (
            <>
              <br />
              <TextArea
                className="mt-4"
                showCount
                required
                name="comments"
                value={dealerInfo.comments}
                placeholder="Write your comment here..."
                maxLength={100}
                style={{ height: 120 }}
                onChange={handleChange}
              />
            </>
          )}
          <br />
          Thank you!
        </p>
        <div className="flex flex-col items-center">
          <Button type="text" className="text-blue-700 text-base text-right place-self-end m-2" onClick={handleComment}>
            {hasComment ? '- Remove' : '+ Add'} comments
          </Button>
          {/* {isMessageSent ? (
            <Popover
              title="Notification!"
              content="You have sent a message to this dealer already!"
              trigger="hover"
              placement="topLeft"
              className="cursor-pointer"
            >
              <button
                type="button"
                className="cursor-not-allowed gap-3 flex justify-center items-center w-10/12 border border-primary hover:bg-primary hover:text-white rounded-xl px-2 py-3 outline-none text-base text-primary my-2"
              >
                <BsCheckLg className="text-xl" />
                Send message
              </button>
            </Popover>
          ) : ( */}
          <button
            type="submit"
            className="flex justify-center items-center w-10/12 border border-primary hover:bg-primary hover:text-white rounded-xl px-2 py-3 outline-none text-base text-primary my-2"
          >
            {isLoading ? <AiOutlineLoading3Quarters className="text-xl animate-spin" /> : 'Send message'}
          </button>
          {/* )} */}
        </div>
      </form>
      <LoginPopup show={show} setShow={setShow} handleShowLoginForm={handleShowLoginForm} />
      <LoginFormModal
        showModal={showLoginForm}
        setShowModal={setShowLoginForm}
        callback={callbackForForm}
        setShowCashDownPopupConfirm={setShowLoginForm}
      />
      <Modal
        centered
        visible={showPromptModal}
        title={
          submitAnotherInquiry
            ? 'Why do you want to submit another inquiry?'
            : 'Do you want to submit another inquiry for this vehicle?'
        }
        okText="Yes"
        cancelText="No"
        onOk={() => {
          if (submitAnotherInquiry) {
            setShowPromptModal(false)
            handleSendEmail()
          } else {
            setSubmitAnotherInquiry(true)
          }
        }}
        onCancel={() => setShowPromptModal(false)}
        className="calendar-modal"
        destroyOnClose
        maskClosable={false}
        afterClose={() => {
          setReason('')
          setRadioValue('default')
          setSubmitAnotherInquiry(false)
        }}
      >
        {submitAnotherInquiry ? (
          <Radio.Group onChange={(e) => setRadioValue(e.target.value)} value={radioValue}>
            <Space direction="vertical">
              <Radio value="default">Did not receive a response from the dealership</Radio>
              <div className="flex flex-col gap-2">
                <Radio value="need_more_info">Need more information</Radio>
                {radioValue === 'need_more_info' ? (
                  <Input value={reason} onChange={(e) => setReason(e.target.value)} className="w-full" />
                ) : null}
              </div>
            </Space>
          </Radio.Group>
        ) : null}
      </Modal>
    </div>
  )
}

ContactDealerForm.propTypes = {
  info: PropTypes.object.isRequired
}

export default ContactDealerForm
