import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import Cookies from 'universal-cookie'
import Logo from '../../assets/new-logo.png'
import './style.scss'

function Footer() {
  const [hasCookies, setCookies] = useState(false)
  const cookies = new Cookies()
  const { width: screenWidth } = useWindowSize()
  const isMobile = screenWidth <= 1024

  const checkCookies = () => {
    setCookies(cookies.get('MotorMarketUSA'))
  }

  const acceptCookies = () => {
    cookies.set('MotorMarketUSA', 'cookiesAccepted', { path: '/' })
    checkCookies()
  }

  useEffect(() => {
    checkCookies()
  }, [])

  const hideOnProduction = process.env.REACT_APP_HIDE_ON_PRODUCTION === 'true'

  return (
    <>
      <div className="bg-gray-500 main-footer pt-12">
        <div className="container mx-auto">
          <div className={`flex items-baseline justify-center ${isMobile ? 'pb-3' : 'pb-10'}`}>
            <div className="mt-0 w-4/12 md:w-auto pl-4 pr-5 xl:px-7">
              <p className="capitalize text-white font-medium text-base">Hotline</p>
              <ul className="mt-2">
                <li className="py-1 px-1 -mx-1">
                  <Link to="/contact" className="text-gray-300 hover:underline">
                    Contact Support
                  </Link>
                </li>
              </ul>

              <div className="">
                <img className={`footer-logo ${isMobile ? 'mobile' : ''} py-2`} src={Logo} alt="Logo" />
              </div>
            </div>
            <div className="mt-0 w-4/12 md:w-auto pl-4 pr-5 xl:px-7">
              <p className="capitalize text-white font-medium text-base">Services</p>
              <ul className="mt-2">
                <li className="py-1 px-1 -mx-1">
                  <Link to="/buy" className="text-gray-300 hover:underline">
                    Buy a Car
                  </Link>
                </li>
                {!hideOnProduction && (
                  <>
                    <li className="py-1 px-1 -mx-1">
                      <Link to="/sell" className="text-gray-300 hover:underline">
                        Sell Your Car
                      </Link>
                    </li>
                    <li className="py-1 px-1 -mx-1">
                      <Link to="/financing" className="text-gray-300 hover:underline">
                        Financing
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div className="mt-0 w-4/12 md:w-auto pl-4 pr-5 xl:px-7">
              <p className="capitalize text-white font-medium text-base">About Us</p>
              <ul className="mt-2">
                <li className="py-1 px-1 -mx-1">
                  <Link to="/" className="text-gray-300 hover:underline">
                    MotorMarketUSA
                  </Link>
                </li>
              </ul>
            </div>
            <div className={`w-3/12 text-gray-300 text-xs ${isMobile ? 'hidden' : 'block'}`}>
              This site or product includes IP2Location LITE data available from
              <a href="http://www.ip2location.com." className="text-gray-300 hover:underline">
                &nbsp;http://www.ip2location.com.
              </a>
            </div>
          </div>

          <div className={`text-gray-300 text-xs text-center pb-10 ${isMobile ? 'block' : 'hidden'}`}>
            This site or product includes IP2Location LITE data available from
            <a href="http://www.ip2location.com." className="text-gray-300 hover:underline">
              &nbsp;http://www.ip2location.com.
            </a>
          </div>
        </div>
      </div>
      <div className={`${hasCookies && 'hidden'} px-4 fixed-footer flex flex-col md:flex-row items-center`}>
        <div className="m-5 text-justify">
          We use cookies and browser activity to improve your experience, personalize content and ads, and analyze how
          our sites are used. For more information on how we collect and use this information, please review our{' '}
          <Link to="/terms-privacy" className="ml-1 text-primary cursor-pointer">
            Privacy Policy
          </Link>
          . California consumers may exercise their CCPA rights{' '}
          <Link to="/terms-privacy" className="ml-1 text-primary cursor-pointer">
            here
          </Link>
        </div>
        <div className="m-auto align-middle">
          <button
            type="button"
            onClick={acceptCookies}
            className="bg-primary hover:bg-activePrimary text-white flex justify-center h-8 w-40 items-center py-2 h-min mr-20 rounded-lg mb-10 md:mb-0"
          >
            I accept
          </button>
        </div>
      </div>
    </>
  )
}

export default Footer
