import queryString from 'query-string'
import HttpClientService from './httpClient.service'

const getCreditApplicationParams = (options) => {
  const params = {
    vehicle_id: options?.vehicleId,
    applicant_section: {
      first_name: options?.appSection?.firstName,
      last_name: options?.appSection?.lastName,
      email: options?.appSection?.email,
      date_of_birth: options?.appSection?.dateOfBirth,
      social_security_number: options?.appSection?.socialSecurityNumber.replace(/-/g, ''),
      mobile_number: options?.appSection?.mobileNumber ? options?.appSection?.mobileNumber.replace(/-/g, '') : null,
      citizenship_status: options?.appSection?.citizenshipStatus
    },
    address_section: {
      street_address: options?.addressSection?.streetAddress,
      zip_code: options?.addressSection?.zipCode,
      city: options?.addressSection?.city,
      state: options?.addressSection?.state,
      county: options?.addressSection?.county,
      years_at_address: options?.addressSection?.yearsAtAddress,
      months_at_address: options?.addressSection?.monthsAtAddress,
      residential_status: options?.selectedValues?.selectedResident,
      mortgage_lender_name: options?.addressSection?.mortgageLenderName,
      monthly_mortgage: options?.addressSection?.monthlyMortgage,
      landlord_name: options?.addressSection?.landlordName,
      monthly_rent: options?.addressSection?.monthlyRent
    },
    job_section: {
      employer_name: options?.jobSection?.employerName,
      job_status: options?.jobSection?.jobStatus,
      annual_gross_income: options?.jobSection?.annualGrossIncome,
      annual_net_income: options?.jobSection?.annualNetIncome,
      work_phone: options?.jobSection?.workPhone?.replace(/-/g, ''),
      job_title: options?.jobSection?.jobTitle,
      street_address: options?.jobSection?.streetAddress,
      zip_code: options?.jobSection?.zipCode,
      city: options?.jobSection?.city,
      state: options?.jobSection?.state,
      county: options?.jobSection?.county,
      years_at_job: options?.jobSection?.yearsAtJob,
      months_at_job: options?.jobSection?.monthsAtJob,
      job_type: options?.selectedValues?.selectedJobType
    }
  }

  if (
    options?.jobSection?.yearsAtJob !== undefined &&
    options?.jobSection?.yearsAtJob < 2 &&
    options?.previousJobInformation?.employerName
  ) {
    params.previous_job_section = {
      employer_name: options?.previousJobInformation?.employerName,
      job_status: options?.previousJobInformation?.jobStatus,
      annual_gross_income: options?.previousJobInformation?.annualGrossIncome,
      annual_net_income: options?.previousJobInformation?.annualNetIncome,
      work_phone: options?.previousJobInformation?.workPhone?.replace(/-/g, ''),
      job_title: options?.previousJobInformation?.jobTitle,
      street_address: options?.previousJobInformation?.streetAddress,
      zip_code: options?.previousJobInformation?.zipCode,
      city: options?.previousJobInformation?.city,
      state: options?.previousJobInformation?.state,
      county: options?.previousJobInformation?.county,
      years_at_job: options?.previousJobInformation?.yearsAtJob,
      months_at_job: options?.previousJobInformation?.monthsAtJob,
      job_type: options?.selectedValues?.preJobType
    }
  }

  if (
    options?.addressSection?.yearsAtAddress !== undefined &&
    options?.addressSection?.yearsAtAddress < 2 &&
    options?.previousHomeAddress?.streetAddress
  ) {
    params.previous_address_section = {
      street_address: options?.previousHomeAddress?.streetAddress,
      zip_code: options?.previousHomeAddress?.zipCode,
      city: options?.previousHomeAddress?.city,
      state: options?.previousHomeAddress?.state,
      county: options?.previousHomeAddress?.county,
      years_at_address: options?.previousHomeAddress?.yearsAtAddress,
      months_at_address: options?.previousHomeAddress?.monthsAtAddress,
      residential_status: options?.selectedValues?.preSelectedResidentialStatus
    }
  }

  if (options.linkCode) {
    params.link_code = options.linkCode
  }

  if (options?.appSection?.middleName) {
    params.applicant_section = {
      ...params.applicant_section,
      middle_name: options.appSection.middleName
    }
  }

  if (
    options?.appSection?.maritalStatus !== 'Select Marital Status' &&
    options?.appSection?.maritalStatus !== undefined
  ) {
    params.applicant_section = {
      ...params.applicant_section,
      marital_status: options.appSection.maritalStatus
    }
  }

  if (options.selectedValues.selectedResident === 'Mortgage') {
    params.address_section = {
      ...params.address_section,
      mortgage_lender_name: options?.addressSection?.mortgageLenderName,
      monthly_mortgage: options?.addressSection?.monthlyMortgage
    }
  }

  if (options.selectedValues.selectedResident === 'Rent') {
    params.address_section = {
      ...params.address_section,
      landlord_name: options?.addressSection?.landlordName,
      monthly_mortgage: options?.addressSection?.monthlyRent
    }
  }

  if (options?.licenseSection?.licenseNumber) {
    params.license_section = {
      ...params.license_section,
      license_number: options?.licenseSection?.licenseNumber
    }
  }

  if (
    options?.licenseSection?.licenseState !== 'Select license state' &&
    options?.licenseSection?.licenseState !== undefined
  ) {
    params.license_section = {
      ...params.license_section,
      license_state: options?.licenseSection?.licenseState
    }
  }

  if (options?.licenseSection?.licenseIssuedDate !== '' && options?.licenseSection?.licenseIssuedDate !== undefined) {
    params.license_section = {
      ...params.license_section,
      license_issued_date: options?.licenseSection?.licenseIssuedDate
    }
  }

  if (options?.licenseSection?.licenseExpiryDate) {
    params.license_section = {
      ...params.license_section,
      license_expiry_date: options?.licenseSection?.licenseExpiryDate
    }
  }

  if (options?.incomeSection?.additionalAnnualIncome) {
    params.income_section = {
      ...params.income_section,
      additional_annual_income: options?.incomeSection?.additionalAnnualIncome
    }
  }

  if (options?.incomeSection?.additionalIncomeSource) {
    params.income_section = {
      ...params.income_section,
      additional_income_source: options?.incomeSection?.additionalIncomeSource
    }
  }
  return params
}

export function setCreditApplication(options) {
  const params = getCreditApplicationParams(options)
  // TODO: REFERENCES

  let action = options.linkCode ? 'credit-applications/save' : 'credit-application-form'
  action = options?.step ? 'credit-application-form-save-by-step' : action

  if (options?.step) {
    params.step = options?.step
  }

  return HttpClientService.post(action, params).then((result) => {
    const { data } = result
    return data
  })
}

export function getCreditApplicationFormById(id) {
  return HttpClientService.get(`credit-application-form/${id}`).then((result) => {
    const { data } = result
    return data
  })
}

export function getCreditApplicationFormByVehicleId(vehicleId, params) {
  const queryParams = queryString.stringify(params)
  return HttpClientService.get(`credit-application-form/get-by-vehicle/${vehicleId}?${queryParams}`).then((result) => {
    const { data } = result
    return data
  })
}

export function updateCreditApplication(options) {
  const creditAppId = options?._id
  const params = options

  return HttpClientService.put(`update-credit-application-form/${creditAppId}`, params).then((result) => {
    const { data } = result
    return data
  })
}

export function useExistingCreditAppForVehicleAndCustomer(params) {
  return HttpClientService.post('credit-application-form-save-by-step', params).then((result) => {
    const { data } = result
    return data
  })
}
