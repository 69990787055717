import { NOTIFICATION_TYPES } from '../constants/constants'
import HttpService from './http.service'
import httpClientService from './httpClient.service'

export const historyNotificationsSms = (recordId, recordType) => {
  const params = {
    type: NOTIFICATION_TYPES.SMS,
    record_id: recordId,
    record_type: recordType
  }
  return HttpService.post('notifications-history', params).then((result) => {
    const { data } = result
    return data
  })
}

export const historyNotificationsEmail = (recordId, recordType) => {
  const params = {
    type: NOTIFICATION_TYPES.EMAIL,
    record_id: recordId,
    record_type: recordType
  }
  return HttpService.post('notifications-history', params).then((result) => {
    const { data } = result
    return data
  })
}

export const historyVisitsSms = (recordId, recordType) => {
  const params = {
    type: NOTIFICATION_TYPES.SMS,
    record_id: recordId,
    record_type: recordType
  }
  return HttpService.post('visits-history', params).then((result) => {
    const { data } = result
    return data
  })
}

export const historyVisitsEmail = (recordId, recordType) => {
  const params = {
    type: NOTIFICATION_TYPES.EMAIL,
    record_id: recordId,
    record_type: recordType
  }
  return HttpService.post('visits-history', params).then((result) => {
    const { data } = result
    return data
  })
}

export const markReadedMessages = (offer) => {
  const params = {
    offers: [offer]
  }
  return httpClientService.put('dealership/offers/read-message', params).then((result) => {
    const { data } = result
    return data
  })
}