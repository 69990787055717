export const partialUpdateVehicleAdapter = (data, options) => ({
  active: typeof data.active === 'string' ? data.active === 'true' : undefined,
  data1: {
    engine: {
      fuel: data.fuel || undefined
    }
  },
  vehicle: {
    body_type: data.body_type || undefined,
    miles: data.miles || undefined,
    engine_description: data.engine_description || undefined,
    transmission_description: data.transmission_description || undefined,
    exterior_color: data.exterior_color || undefined,
    vin: data.vin || undefined,
    stock_number: data.stock_number || undefined,
    price: {
      current: data.price_current || undefined
    },
    options,
    new_used_cert_flag: data.new_used_cert_flag
  }
})

export const newPartialUpdateVehicleAdapter = (data, options) => ({
  fuel: data.fuel,
  year: data.year,
  make: data.make,
  model: data.model,
  body: data.body,
  style: data.style,
  miles: data.miles,
  engine_name: data.engine_name,
  transmission_type: data.transmission_type,
  exterior_color: data.exterior_color,
  exterior_color_type: data.exterior_color_type,
  interior_color: data.interior_color,
  vin: data.vin,
  stock_number: data.stock_number,
  price: data.price,
  new_used_cert_flag: data.new_used_cert_flag,
  options
})

export const newStatusUpdateVehicleAdapter = (status) => ({
  active: status
})

