import { STORAGE_KEYS } from '../constants/constants'

const PREFIX = 'dealermall-'

export function save(key, value, prefix = PREFIX) {
  try {
    window.localStorage.setItem(`${prefix}${key}`, value)
    return value
  } catch (e) {
    window.console.error('Error in storage.save', e)
    return null
  }
}

export function unSave(key) {
  localStorage.removeItem(key)
}

export function load(key, parse = true, prefix = PREFIX) {
  try {
    const value = window.localStorage.getItem(`${prefix}${key}`)
    return value && parse ? JSON.parse(value) : value
  } catch (e) {
    window.console.error('Error in storage.load', e)
    return null
  }
}

export function clear(key, prefix = PREFIX) {
  try {
    if (Array.isArray(key)) {
      key.forEach((k) => {
        window.localStorage.removeItem(`${prefix}${k}`)
      })
    } else {
      window.localStorage.removeItem(`${prefix}${key}`)
    }
    return
  } catch (e) {
    window.console.error('Error in storage.clear', e)
  }
}

export const handleClearStorageUserInfo = () => {
  // we clear the shared dealership session
  sessionStorage.removeItem(STORAGE_KEYS.IS_SHARED_BY_DEALERSHIP)
  sessionStorage.removeItem(STORAGE_KEYS.SHARED_BY_DEALERSHIP_ID)

  return clear([
    STORAGE_KEYS.FAVOURITE_CARS,
    STORAGE_KEYS.ACCESS_TOKEN,
    STORAGE_KEYS.TOKEN_TYPE,
    STORAGE_KEYS.CUSTOMER_LOGIN,
    STORAGE_KEYS.DEALER_LOGIN,
    STORAGE_KEYS.ADMIN_LOGIN,
    STORAGE_KEYS.USER_LOGIN,
    STORAGE_KEYS.USER_SETTINGS
  ])
}
