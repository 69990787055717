/* eslint-disable no-nested-ternary */
import { Button, Pagination, Popover, Spin, Tag } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import { INITIAL_PRICE, STORAGE_KEYS } from 'src/constants/constants'
import { clear, load, save } from 'src/lib/storage'
import { generateSearchURL } from 'src/utils'
import metaPageImage from '../../assets/new-logo.png'
import VehicleCard from '../../components/Buy/vehicleCard'
import FilterBuyCar from '../../components/FilterBuyCar/FilterBuyCar'
import SearchCar from '../../components/SearchCar/searchCar'
import metaPages from '../../data/metaPages.json'
import SinglePageMetaDecorator from '../../decorators/SinglePageMetaDecorator'
import * as BuyService from '../../services/buy.service'
import * as VehicleService from '../../services/vehicle.service'
import './style.scss'

const initYear = {
  min: 2009,
  max: 2023
}
const initMileage = {
  min: 0,
  max: 200000
}
const getFilterBody = ({
  zipCode,
  zipCodeMileRange,
  price,
  year,
  mileage,
  make,
  model,
  trim,
  transmission,
  color,
  certifiedVehicles,
  similarDeal,
  sortKey,
  sortType,
  keyword
}) => {
  const filterBody = {}
  filterBody.zipCode =
    zipCode !== undefined && zipCode !== '' ? zipCode : sessionStorage.getItem(STORAGE_KEYS.INITIAL_FILTER_ZIP_CODE)
  if (zipCodeMileRange !== undefined && zipCodeMileRange < 1) {
    filterBody.zipCode = ''
  }
  filterBody.zipRadius =
    zipCodeMileRange !== undefined && zipCodeMileRange !== '' && zipCode !== undefined && zipCode !== ''
      ? zipCodeMileRange
      : 100
  if (zipCodeMileRange !== undefined && zipCodeMileRange < 1) {
    filterBody.zipRadius = -1
  }
  if (price && (price.min !== INITIAL_PRICE.min || price.max !== INITIAL_PRICE.max)) {
    filterBody.price = { min: Number(price.min), max: Number(price.max) }
  }
  if (year && (year.min !== initYear.min || year.max !== initYear.max)) filterBody.year = year
  if (mileage && (mileage.min !== initMileage.min || mileage.max !== initMileage.max)) {
    filterBody.mileage = { min: Number(mileage.min), max: Number(mileage.max) }
  }
  if (make && make.length > 0) filterBody.make = make
  if (model && model.length > 0) filterBody.model = model
  if (trim && trim.length > 0) filterBody.trim = trim
  if (color && color.length > 0) filterBody.color = color
  if (transmission && transmission.length > 0) filterBody.transmission = transmission
  if (color && color.length > 0) filterBody.color = color
  filterBody.certifiedVehicles = certifiedVehicles
  if (sortKey && sortKey.length > 0) filterBody.sortBy = sortKey
  if (sortType && sortType.length > 0) filterBody.orderBy = sortType
  if (keyword !== '') filterBody.keyword = keyword
  if (similarDeal && similarDeal.length > 0) filterBody.similarDeal = similarDeal

  return filterBody
}

const transmissionNames = [
  { name: 'A', displayName: 'Automatic' },
  { name: 'M', displayName: 'Manual' },
  { name: 'Unspecified', displayName: 'Unspecified' },
  { name: 'CVT', displayName: 'CVT' }
]

function findTransmissionName(transmission) {
  let name = ''
  transmissionNames.forEach((item) => {
    if (item.name === transmission) {
      name = item.displayName
    }
  })
  return name
}

const similarDealFilter = [
  { key: 'AM', name: 'Above Market', count: 0 },
  { key: 'FD', name: 'Fair Deal', count: 0 },
  { key: 'GD', name: 'Good Deal', count: 0 },
  { key: 'BM', name: 'Below Market', count: 0 }
]

function findSimilarDealTypeName(dealType) {
  let name = ''
  similarDealFilter.forEach((item) => {
    if (item.key === dealType) {
      name = item.key
    }
  })
  return name
}

function Buy() {
  let initialFilterOptions = load(STORAGE_KEYS.INITIAL_FILTER_OPTIONS) || undefined
  const [isCheckingZipCode, setIsCheckingZipCode] = useState(true)
  const zipCodeChanged = load(STORAGE_KEYS.ZIP_CODE_CHANGED)
  const {
    year: yearInitial,
    make: makeInitial,
    model: modelInitial,
    mileage: mileageInitial,
    price: priceInitial,
    zipCode: zipCodeInitial,
    zipRadius: zipCodeMileRangeInitial,
    trim: trimInitial
  } = initialFilterOptions || {}

  const { search } = useLocation()
  const queryString = new URLSearchParams(search)
  let query = queryString.get('query')
  const searchID = queryString.get('searchID') || ''
  if (search !== '') {
    query += `&searchID=${searchID}`
  }

  const [sortKey, setSortKey] = useState('all')
  const [keyword, setKeyword] = useState(query?.split('&searchID=')[0])
  const [sortType, setSortType] = useState('asc')
  const [buyCars, setBuyCars] = useState([])
  const oldBuyCarRef = useRef([])

  const [zipCodeResolved, setZipCodeResolved] = useState(false)
  const [zipCode, setZipCode] = useState(zipCodeInitial ?? sessionStorage.getItem(STORAGE_KEYS.INITIAL_FILTER_ZIP_CODE))
  const [zipCodeDefault, setZipCodeDefault] = useState(!!zipCodeInitial)
  const [certified, setCertified] = useState([])
  const [zipCodeMileRange, setZipCodeMileRange] = useState(zipCodeMileRangeInitial ?? 100)
  const [price, setPrice] = useState(priceInitial || INITIAL_PRICE)
  const [priceDefault, setPriceDefault] = useState(!!priceInitial)
  const [year, setYear] = useState(yearInitial || initYear)
  const [yearDefault, setYearDefault] = useState(!!yearInitial)
  const [mileage, setMileage] = useState(mileageInitial || initMileage)
  const [mileageDefault, setMileageDefault] = useState(!!mileageInitial)
  const [make, setMake] = useState(makeInitial || [])
  const [model, setModel] = useState(modelInitial || [])
  const [trim, setTrim] = useState(trimInitial || [])
  const [transmission, setTransmission] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const initialRef = useRef(true)
  const favouriteCarsLocal = load(STORAGE_KEYS.FAVOURITE_CARS) || []
  const [favouriteCars, setFavouriteCars] = useState(favouriteCarsLocal)
  const [showOnlyFavoriteVehicles, setShowOnlyFavoriteVehicles] = useState(false)
  const [vehicleType, setVehicleType] = useState([])
  const [marketPrice, setMarketPrice] = useState([])
  const isMoreFilterRef = useRef(false)
  const [shouldCallFilter, setShouldCallFilter] = useState()
  const [color, setColor] = useState([])
  const [showZipCodeNotiBox, setShowZipCodeNotiBox] = useState(false)
  const [reloadFilters, setReloadFilters] = useState(false)
  const shouldChangeUrlRef = useRef(false)
  const [showKeywordTag, setShowKeywordTag] = useState(false)
  const navigate = useNavigate()

  const shouldCallSearchVehicle = sessionStorage.getItem(STORAGE_KEYS.SHOULD_CALL_SEARCH_VEHICLE) === 'true'
  const disableCallApiGetZipCode = sessionStorage.getItem(STORAGE_KEYS.DISABLE_CALL_API_GET_ZIP_CODE) === 'true'
  const [similarDeal, setSimilarDeal] = useState([])

  const removeMake = (e, item) => {
    e.preventDefault()
    const tags = make.filter((tag) => tag !== item.item)
    setMake(tags)
    setReloadFilters(true)
  }

  const removeModel = (e, item) => {
    e.preventDefault()
    const tags = model.filter((tag) => tag !== item.item)
    setModel(tags)
    setReloadFilters(true)
  }

  const removeTrim = (e, item) => {
    e.preventDefault()
    const tags = trim.filter((tag) => tag !== item.item)
    setTrim(tags)
    setReloadFilters(true)
  }

  const removeTransmission = (e, item) => {
    e.preventDefault()
    const tags = transmission.filter((tag) => tag !== item.item)
    setTransmission(tags)
    setReloadFilters(true)
  }

  const removeZipCode = (e) => {
    e.preventDefault()
    setZipCodeDefault(false)
    setZipCode('')
    setZipCodeMileRange(-1)
    setReloadFilters(true)
  }

  const removeCertified = (e) => {
    e.preventDefault()
    setCertified([])
    setReloadFilters(true)
  }

  const removePrice = (e) => {
    e.preventDefault()
    setPrice(INITIAL_PRICE)
    setPriceDefault(false)
    setReloadFilters(true)
  }

  const removeYear = (e) => {
    e.preventDefault()
    setYear(initYear)
    setYearDefault(false)
    setReloadFilters(true)
  }

  const removeMileage = (e) => {
    e.preventDefault()
    setMileage(initMileage)
    setMileageDefault(false)
    setReloadFilters(true)
  }

  const removeKeywordTag = (e) => {
    e.preventDefault()
    setShowKeywordTag(false)
    setKeyword('')
    shouldChangeUrlRef.current = false
    const newSearchURL = generateSearchURL({
      make,
      model,
      year
    })
    navigate(newSearchURL)
  }

  const removeColor = (e, item) => {
    e.preventDefault()
    const tags = color.filter((tag) => tag !== item.item)
    setColor(tags)
    setReloadFilters(true)
  }

  const removeVehicleType = (e, item) => {
    e.preventDefault()
    const tags = vehicleType.filter((tag) => tag !== item.item)
    setVehicleType(tags)
    setReloadFilters(true)
  }

  const removeSimilarDeal = (e, item) => {
    e.preventDefault()
    const tags = similarDeal.filter((tag) => tag !== item.item)
    setSimilarDeal(tags)
    setReloadFilters(true)
  }

  const setPriceRange = (values) => {
    setPrice(values)
    if (values.min !== INITIAL_PRICE.min || values.max !== INITIAL_PRICE.max) {
      setPriceDefault(true)
    } else {
      setPriceDefault(false)
    }
  }

  const setYearRange = (values) => {
    setYear(values)
    if (values.min !== initYear.min || values.max !== initYear.max) {
      setYearDefault(true)
    } else {
      setYearDefault(false)
    }
  }

  const handleSortChange = useCallback(
    (value, type, currentPage, currentPerPage) => {
      isMoreFilterRef.current = false
      // setShowOnlyFavoriteVehicles(false)
      setLoading(true)
      setSortKey(value)
      setSortType(type)
      const filterBody = getFilterBody({
        zipCode,
        certified,
        zipCodeMileRange,
        price,
        year,
        mileage,
        make,
        model,
        trim,
        transmission,
        color,
        vehicleType,
        similarDeal,
        certifiedVehicles: certified,
        sortKey: value !== 'all' ? value : '',
        sortType: value !== 'all' ? type : '',
        keyword
      })
      BuyService.getByFeature({
        rowsPerPage: currentPerPage,
        page: currentPage,
        showFavorites: showOnlyFavoriteVehicles,
        ...filterBody
      })
        .then((response) => {
          if (undefined !== response.data.data) {
            setBuyCars(response.data.data)
            setTotal(response.data.total)
          } else {
            setBuyCars([])
          }
        })
        .finally(() => {
          setLoading(false)
          initialRef.current = false
        })
        .catch((e) => {
          setBuyCars([])
          setPage(1)
          setTotal(0)
          console.log(e)
        })
    },
    [
      zipCode,
      certified,
      zipCodeMileRange,
      JSON.stringify(price),
      JSON.stringify(year),
      JSON.stringify(mileage),
      JSON.stringify(make),
      JSON.stringify(model),
      JSON.stringify(trim),
      JSON.stringify(transmission),
      JSON.stringify(color),
      JSON.stringify(vehicleType),
      sortKey,
      sortType,
      keyword,
      JSON.stringify(similarDeal),
      getFilterBody
    ]
  )

  const handleSetPrice = () => {
    let newPrice = price
    if (queryString.get('initial_from_price') && queryString.get('initial_to_price')) {
      setPriceDefault(true)
    }
    if (queryString.get('initial_from_price')) {
      newPrice = {
        ...newPrice,
        min: Number(queryString.get('initial_from_price'))
      }
    }
    if (queryString.get('initial_to_price')) {
      newPrice = {
        ...newPrice,
        max: Number(queryString.get('initial_to_price'))
      }
    }
    setPrice(newPrice)

    return newPrice
  }

  const handleSetMileage = () => {
    let newMileage = mileage
    if (queryString.get('initial_from_mileage') && queryString.get('initial_to_mileage')) {
      setMileageDefault(true)
    }
    if (queryString.get('initial_from_mileage')) {
      newMileage = {
        ...newMileage,
        min: Number(queryString.get('initial_from_mileage'))
      }
    }
    if (queryString.get('initial_to_mileage')) {
      newMileage = {
        ...newMileage,
        max: Number(queryString.get('initial_to_mileage'))
      }
    }
    setMileage(newMileage)

    return newMileage
  }

  const handleSetTrim = () => {
    let newTrim = trim
    if (queryString.get('initial_trim')) {
      newTrim = [queryString.get('initial_trim')]
    }
    setTrim(newTrim)

    return newTrim
  }

  useEffect(() => {
    const newPrice = handleSetPrice()
    const newMileage = handleSetMileage()
    const newTrim = handleSetTrim()

    initialFilterOptions = {
      ...initialFilterOptions,
      trim: newTrim,
      price: newPrice,
      mileage: newMileage
    }
  }, [search])

  useEffect(() => {
    const newPrice = handleSetPrice()
    const newMileage = handleSetMileage()

    initialFilterOptions = {
      ...initialFilterOptions,
      price: newPrice,
      mileage: newMileage
    }

    if (!shouldCallSearchVehicle && initialRef.current && !isCheckingZipCode) {
      let action = BuyService.getVehiclesSanitized
      let params = page

      if (initialFilterOptions && Object.keys(initialFilterOptions).length > 0) {
        const { make, model, price, mileage, certifiedVehicles, zipCode, zipRadius, trim } = initialFilterOptions
        const filterBody = getFilterBody({
          price,
          mileage,
          make,
          model,
          certifiedVehicles,
          keyword,
          zipCode,
          zipCodeMileRange: zipRadius,
          trim
        })

        action = BuyService.getByFeature
        params = { rowsPerPage, page, showFavorites: showOnlyFavoriteVehicles, ...filterBody }
      }

      action(params)
        .then((response) => {
          if (undefined !== response.data.data) {
            setBuyCars(response.data.data)
            setTotal(response.data.total)
          } else {
            setBuyCars([])
          }
        })
        .catch((e) => {
          setBuyCars([])
          setPage(1)
          setTotal(0)
          console.log(e)
        })
        .finally(() => {
          initialRef.current = false
          setLoading(false)
        })
    }
  }, [page, isCheckingZipCode, showOnlyFavoriteVehicles])

  useEffect(() => {
    if (query && !initialRef.current) {
      setLoading(true)
      // setShowOnlyFavoriteVehicles(false)
      setKeyword(query)

      const filterBody = getFilterBody({
        zipCode,
        certified,
        zipCodeMileRange,
        price,
        year,
        mileage,
        make,
        model,
        trim,
        transmission,
        color,
        vehicleType,
        similarDeal,
        certifiedVehicles: certified,
        sortKey: sortKey !== 'all' ? sortKey : '',
        sortType: sortKey !== 'all' ? sortType : '',
        keyword: query
      })
      save(STORAGE_KEYS.INITIAL_FILTER_OPTIONS, JSON.stringify(filterBody))
      BuyService.getByFeature({ showFavorites: showOnlyFavoriteVehicles, ...filterBody })
        .then((response) => {
          if (undefined !== response.data.data) {
            setBuyCars(response.data.data)
            setTotal(response.data.total)
          } else {
            setBuyCars([])
          }
        })
        .catch((e) => {
          setBuyCars([])
          setPage(1)
          setTotal(0)
          setMake([])
          setModel([])
          console.log(e)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [query, keyword])

  const handleGetByFeature = useCallback(
    ({ rowsPerPage, page }) => {
      const filterBody = getFilterBody({
        zipCode,
        certified,
        zipCodeMileRange,
        price,
        year,
        mileage,
        make,
        model,
        trim,
        transmission,
        color,
        vehicleType,
        similarDeal,
        certifiedVehicles: certified,
        sortKey: sortKey !== 'all' ? sortKey : '',
        sortType: sortKey !== 'all' ? sortType : '',
        keyword
      })
      if (Object.keys(filterBody).length > 0) {
        save(STORAGE_KEYS.INITIAL_FILTER_OPTIONS, JSON.stringify(filterBody))
        BuyService.getByFeature({ rowsPerPage, page, showFavorites: showOnlyFavoriteVehicles, ...filterBody })
          .then((response) => {
            if (undefined !== response.data.data) {
              setBuyCars(response.data.data)
              setTotal(response.data.total)
            } else {
              setBuyCars([])
            }
          })
          .catch((e) => {
            setBuyCars([])
            setPage(1)
            setTotal(0)
            setMake([])
            setModel([])
            console.log(e)
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        handleSortChange(sortKey, sortType, page, rowsPerPage)
      }
    },
    [
      zipCode,
      certified,
      zipCodeMileRange,
      JSON.stringify(price),
      JSON.stringify(year),
      JSON.stringify(mileage),
      JSON.stringify(make),
      JSON.stringify(model),
      JSON.stringify(trim),
      JSON.stringify(transmission),
      JSON.stringify(color),
      JSON.stringify(vehicleType),
      JSON.stringify(similarDeal),
      sortKey,
      sortType,
      keyword,
      getFilterBody,
      handleSortChange,
      showOnlyFavoriteVehicles
    ]
  )

  useEffect(() => {
    if (!initialRef.current && isMoreFilterRef.current) {
      setLoading(true)
      // setShowOnlyFavoriteVehicles(false)
      handleGetByFeature({ rowsPerPage, page })
    }
  }, [rowsPerPage, page, shouldCallFilter, handleGetByFeature, showOnlyFavoriteVehicles])

  useEffect(() => {
    if (!initialRef.current && !isMoreFilterRef.current) {
      setLoading(true)
      isMoreFilterRef.current = true
      const filterBody = getFilterBody({
        zipCode,
        certified,
        zipCodeMileRange,
        price,
        year,
        mileage,
        make,
        model,
        trim,
        transmission,
        color,
        similarDeal,
        certifiedVehicles: certified
      })
      if (Object.keys(filterBody).length > 0) {
        setShouldCallFilter(Math.random())
      }
    }
  }, [
    zipCode,
    zipCodeMileRange,
    certified,
    price,
    year,
    mileage,
    make,
    model,
    trim,
    transmission,
    vehicleType,
    marketPrice,
    color,
    vehicleType,
    similarDeal,
    zipCodeResolved,
    showOnlyFavoriteVehicles
  ])

  useEffect(() => {
    if (shouldCallSearchVehicle && initialRef.current && !isCheckingZipCode) {
      const { year, make, model, mileage, price, zipCode, trim } = initialFilterOptions || {}
      sessionStorage.setItem(STORAGE_KEYS.SHOULD_CALL_SEARCH_VEHICLE, 'false')
      const filterBody = getFilterBody({
        zipCode,
        zipCodeMileRange,
        price,
        year,
        make,
        mileage,
        model,
        trim
      })
      if (Object.keys(filterBody).length > 0) {
        save(STORAGE_KEYS.INITIAL_FILTER_OPTIONS, JSON.stringify(filterBody))
        BuyService.getByFeature({ rowsPerPage, page, showFavorites: showOnlyFavoriteVehicles, ...filterBody })
          .then((response) => {
            if (undefined !== response.data.data) {
              setBuyCars(response.data.data)
              setTotal(response.data.total)
            } else {
              setBuyCars([])
            }
          })
          .catch((e) => {
            console.log(e)
          })
          .finally(() => {
            setLoading(false)
            initialRef.current = false
          })
      }
    }
  }, [isCheckingZipCode])

  useEffect(() => {
    if (!initialFilterOptions?.zipCode && !disableCallApiGetZipCode && !zipCodeChanged) {
      VehicleService.getZipCodeBaseIpAddress()
        .then((response) => {
          const { zipcode } = response.data
          setZipCode(zipcode)
          setZipCodeMileRange(100)
          const filterBody = getFilterBody(initialFilterOptions || {})
          save(
            STORAGE_KEYS.INITIAL_FILTER_OPTIONS,
            JSON.stringify({ ...filterBody, zipCode: zipcode, zipCodeMileRange: 100 })
          )
          setZipCodeDefault(true)
          setZipCodeResolved(true)
        })
        .catch((error) => {
          setShowZipCodeNotiBox(true)
          console.error(error)
        })
        .finally(() => setIsCheckingZipCode(false))
    } else {
      setIsCheckingZipCode(false)
    }
  }, [])

  const handleChangePage = (page, pageSize) => {
    setPage(page)
    if (!isMoreFilterRef.current) {
      handleSortChange(sortKey, sortType, page, pageSize)
    }
  }

  const handleChangePageSize = (page, pageSize) => {
    setRowsPerPage(pageSize)
  }

  const setZipCodeRange = (value) => {
    setZipCode(value)
    setZipCodeDefault(true)
  }

  const setMileageRange = (values) => {
    setMileage(values)
    if (values.min !== initMileage.min || values.max !== initMileage.max) {
      setMileageDefault(true)
    } else {
      setMileageDefault(false)
    }
  }

  const handleRemoveZipCode = (zipCodeMileRange, zipCode) => {
    if (zipCodeMileRange < 1) {
      return 'Within Unrestricted miles radius'
    }
    return `Within ${zipCodeMileRange} miles of ZIP CODE: ${zipCode}`
  }

  const clearFilter = () => {
    clear(STORAGE_KEYS.INITIAL_FILTER_OPTIONS)
    clear(STORAGE_KEYS.INITIAL_FILTER_COUNT)
    clear(STORAGE_KEYS.ZIP_CODE_CHANGED)
    setPrice(INITIAL_PRICE)
    setCertified([])
    setMake([])
    setModel([])
    setYear(initYear)
    setMileage(initMileage)
    setTrim([])
    setTransmission([])
    setZipCodeDefault(false)
    setZipCodeMileRange(-1)
    setZipCode('')
    setPriceDefault(false)
    setYearDefault(false)
    setMileageDefault(false)
    setShowOnlyFavoriteVehicles(false)
    setVehicleType([])
    setMarketPrice([])
    setColor([])
    setSimilarDeal([])
    setReloadFilters(true)
    setKeyword('')
    navigate('/buy')
  }

  const mobileFilterBar = (
    <FilterBuyCar
      loading={loading}
      zipCode={zipCode}
      setZipCode={setZipCodeRange}
      certified={certified}
      setCertified={setCertified}
      zipRange={zipCodeMileRange}
      setZipRange={setZipCodeMileRange}
      price={price}
      setPrice={setPriceRange}
      year={year}
      setYear={setYearRange}
      mileage={mileage}
      setMileage={setMileageRange}
      make={make}
      setMake={setMake}
      model={model}
      setModel={setModel}
      trim={trim}
      setTrim={setTrim}
      transmission={transmission}
      setTransmission={setTransmission}
      clearFilter={clearFilter}
      className="sm:hidden"
      isInitial={initialRef.current}
      showOnlyFavoriteVehicles={showOnlyFavoriteVehicles}
      setShowOnlyFavoriteVehicles={setShowOnlyFavoriteVehicles}
      favouriteCars={favouriteCars}
      oldBuyCarRef={oldBuyCarRef}
      buyCars={buyCars}
      setBuyCars={setBuyCars}
      vehicleType={vehicleType}
      setVehicleType={setVehicleType}
      marketPrice={marketPrice}
      setMarketPrice={setMarketPrice}
      color={color}
      setColor={setColor}
      showZipCodeNotiBox={showZipCodeNotiBox}
      keyword={keyword}
      setKeyword={setKeyword}
      reloadFilters={reloadFilters}
      setReloadFilters={setReloadFilters}
      query={query}
      similarDeal={similarDeal}
      setSimilarDeal={setSimilarDeal}
    />
  )

  useEffect(() => {
    if (shouldChangeUrlRef.current) {
      let newSearchURL = generateSearchURL({
        keyword: keyword?.split('&searchID')[0],
        make,
        model,
        year
      })
      if (keyword) {
        newSearchURL += `?query=${keyword}`
      }
      navigate(newSearchURL)
    } else {
      setTimeout(() => {
        shouldChangeUrlRef.current = true
      }, 500)
    }
  }, [keyword, make, model, year])

  return (
    <div className="pt-12 md:pt-20">
      <SinglePageMetaDecorator
        title={metaPages.buy.title}
        description={metaPages.buy.description}
        imageUrl={metaPageImage}
        imageAlt={metaPages.buy.imageAlt}
      />
      <div className="container">
        <div className="sticky-nav ">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6">
            <FilterBuyCar
              loading={loading}
              zipCode={zipCode}
              setZipCode={setZipCodeRange}
              certified={certified}
              setCertified={setCertified}
              zipRange={zipCodeMileRange}
              setZipRange={setZipCodeMileRange}
              price={price}
              setPrice={setPriceRange}
              year={year}
              setYear={setYearRange}
              mileage={mileage}
              setMileage={setMileageRange}
              make={make}
              setMake={setMake}
              model={model}
              setModel={setModel}
              trim={trim}
              setTrim={setTrim}
              transmission={transmission}
              setTransmission={setTransmission}
              clearFilter={clearFilter}
              className="hidden sm:block"
              isInitial={initialRef.current}
              showOnlyFavoriteVehicles={showOnlyFavoriteVehicles}
              setShowOnlyFavoriteVehicles={setShowOnlyFavoriteVehicles}
              favouriteCars={favouriteCars}
              oldBuyCarRef={oldBuyCarRef}
              buyCars={buyCars}
              setBuyCars={setBuyCars}
              vehicleType={vehicleType}
              setVehicleType={setVehicleType}
              marketPrice={marketPrice}
              setMarketPrice={setMarketPrice}
              color={color}
              setColor={setColor}
              showZipCodeNotiBox={showZipCodeNotiBox}
              keyword={keyword}
              setKeyword={setKeyword}
              reloadFilters={reloadFilters}
              setReloadFilters={setReloadFilters}
              query={query}
              similarDeal={similarDeal}
              setSimilarDeal={setSimilarDeal}
            />
            <div className="md:col-span-2 lg:col-span-4">
              <div className="flex bg-gray-100 px-2 py-2 sm:p-0 items-center mb-5 rounded relative z-30">
                <Popover
                  placement="bottomLeft"
                  content={mobileFilterBar}
                  trigger="click"
                  style={{ width: 400 }}
                  openClassName="mobile-filter-car"
                >
                  <svg
                    viewBox="0 0 24 24"
                    className="h-8 w-auto cursor-pointer sm:hidden mr-3"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path color="#444445" d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
                  </svg>
                </Popover>
                <SearchCar
                  make={make}
                  setMake={setMake}
                  model={model}
                  setModel={setModel}
                  year={year}
                  setYear={setYear}
                  setYearDefault={setYearDefault}
                  buy
                  bgColor={false}
                  heading={false}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  shouldChangeUrlRef={shouldChangeUrlRef}
                  setShowKeywordTag={setShowKeywordTag}
                />
              </div>
              <div className="flex flex-wrap mb-5">
                {zipCodeDefault && !zipCodeMileRange && (
                  <Tag closable onClose={removeZipCode}>
                    {`ZIP CODE: ${zipCode}`}
                  </Tag>
                )}
                {certified.length > 0 && (
                  <Tag closable onClose={removeCertified}>
                    {`CERTIFIED: ${certified}`}
                  </Tag>
                )}
                {zipCodeDefault && zipCodeMileRange > 0 && (
                  <Tag closable onClose={removeZipCode}>
                    {handleRemoveZipCode(zipCodeMileRange, zipCode)}
                  </Tag>
                )}
                {priceDefault && (
                  <Tag closable onClose={removePrice}>
                    {`PRICE: $${price.min.toLocaleString('en-US')} TO $${price.max.toLocaleString('en-US')}`}
                  </Tag>
                )}
                {make.map((item, index) => (
                  <Tag closable key={`make-tag-${index}`} onClose={(e) => removeMake(e, { item })}>
                    {item}
                  </Tag>
                ))}
                {model.map((item, index) => (
                  <Tag closable key={`model-tag-${index}`} onClose={(e) => removeModel(e, { item })}>
                    {item}
                  </Tag>
                ))}
                {yearDefault && (
                  <Tag closable onClose={removeYear}>
                    {year.min !== year.max ? `YEAR: ${year.min} TO ${year.max}` : `YEAR: ${year.min}`}
                  </Tag>
                )}
                {mileageDefault && (
                  <Tag closable onClose={removeMileage}>
                    {`MILEAGE: ${mileage.min} TO ${mileage.max}`}
                  </Tag>
                )}
                {showKeywordTag && keyword && (
                  <Tag closable onClose={removeKeywordTag}>
                    {keyword?.split('&searchID')[0]}
                  </Tag>
                )}
                {trim.map((item, index) => (
                  <Tag closable key={`trim-tag-${index}`} onClose={(e) => removeTrim(e, { item })}>
                    {item}
                  </Tag>
                ))}
                {transmission.map((item, index) => (
                  <Tag closable key={`transmission-tag-${index}`} onClose={(e) => removeTransmission(e, { item })}>
                    {findTransmissionName(item)}
                  </Tag>
                ))}
                {color.map((item, index) => (
                  <Tag closable key={`color-tag-${index}`} onClose={(e) => removeColor(e, { item })}>
                    {item}
                  </Tag>
                ))}
                {vehicleType.map((item, index) => (
                  <Tag closable key={`vehicle-type-tag-${index}`} onClose={(e) => removeVehicleType(e, { item })}>
                    {item}
                  </Tag>
                ))}
                {similarDeal.map((item, index) => (
                  <Tag closable key={`similar-deal-tag-${index}`} onClose={(e) => removeSimilarDeal(e, { item })}>
                    {findSimilarDealTypeName(item)}
                  </Tag>
                ))}
              </div>
              <div className="flex justify-between flex-col lg:flex-row gap-3">
                <Button onClick={clearFilter} className="rounded text-red-700" danger>
                  Reset
                </Button>
                <div className="flex justify-end items-center mb-5">
                  <p className="mr-3" style={{ minWidth: 'max-content' }}>
                    Sort by:
                  </p>
                  <div className="flex w-80 md:w-96 bg-gray-300 rounded-2xl">
                    <div
                      className={`flex-1 flex justify-center cursor-pointer py-1 ${
                        sortKey === 'all' ? 'bg-white border border-primary rounded-2xl' : ''
                      }`}
                      onClick={() => handleSortChange('all', 'asc', page, rowsPerPage)}
                      role="presentation"
                    >
                      All
                    </div>
                    <div
                      className={`flex-1 flex justify-center items-center cursor-pointer py-1 ${
                        sortKey === 'year' ? 'bg-white border border-primary rounded-2xl' : ''
                      }`}
                      onClick={
                        sortKey === 'year' && sortType !== 'asc'
                          ? () => handleSortChange('year', 'asc', page, rowsPerPage)
                          : sortKey === 'year' && sortType === 'asc'
                          ? () => handleSortChange('year', 'desc', page, rowsPerPage)
                          : () => handleSortChange('year', 'asc', page, rowsPerPage)
                      }
                      role="presentation"
                    >
                      <div className="mr-2 md:mr-4">Year</div>
                      {sortKey === 'year' && sortType !== 'asc' ? (
                        <FaChevronDown />
                      ) : sortKey === 'year' && sortType === 'asc' ? (
                        <FaChevronUp />
                      ) : (
                        <FaChevronUp />
                      )}
                    </div>
                    <div
                      className={`flex-1 flex justify-center items-center cursor-pointer py-1 ${
                        sortKey === 'brand' ? 'bg-white border border-primary rounded-2xl' : ''
                      }`}
                      onClick={
                        sortKey === 'brand' && sortType !== 'asc'
                          ? () => handleSortChange('brand', 'asc', page, rowsPerPage)
                          : sortKey === 'brand' && sortType === 'asc'
                          ? () => handleSortChange('brand', 'desc', page, rowsPerPage)
                          : () => handleSortChange('brand', 'asc', page, rowsPerPage)
                      }
                      role="presentation"
                    >
                      <div className="mr-2 md:mr-4">Brand</div>
                      {sortKey === 'brand' && sortType !== 'asc' ? (
                        <FaChevronDown />
                      ) : sortKey === 'brand' && sortType === 'asc' ? (
                        <FaChevronUp />
                      ) : (
                        <FaChevronUp />
                      )}
                    </div>
                    <div
                      className={`flex-1 flex justify-center items-center cursor-pointer py-1 ${
                        sortKey === 'price' ? 'bg-white border border-primary rounded-2xl' : ''
                      }`}
                      onClick={
                        sortKey === 'price' && sortType !== 'asc'
                          ? () => handleSortChange('price', 'asc', page, rowsPerPage)
                          : sortKey === 'price' && sortType === 'asc'
                          ? () => handleSortChange('price', 'desc', page, rowsPerPage)
                          : () => handleSortChange('price', 'asc', page, rowsPerPage)
                      }
                      role="presentation"
                    >
                      <div className="mr-2 md:mr-4">Price</div>
                      {sortKey === 'price' && sortType !== 'asc' ? (
                        <FaChevronDown />
                      ) : sortKey === 'price' && sortType === 'asc' ? (
                        <FaChevronUp />
                      ) : (
                        <FaChevronUp />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {loading ? (
                <div className="spiner">
                  <Spin size="large" tip="Loading..." />
                </div>
              ) : (
                <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-6 mb-12">
                  {buyCars?.map((car) => (
                    <VehicleCard
                      car={car}
                      key={car._id}
                      favouriteCars={favouriteCars}
                      setFavouriteCars={setFavouriteCars}
                    />
                  ))}
                </div>
              )}
              <div className={`xl:w-11/12 lg:w-11/12 flex justify-center items-center mb-8 ${loading && 'hidden'}`}>
                <Pagination
                  className="flex"
                  responsive
                  current={page}
                  total={buyCars.length > 0 ? total : 0}
                  pageSize={rowsPerPage}
                  onChange={handleChangePage}
                  onShowSizeChange={handleChangePageSize}
                  pageSizeOptions={[10, 20, 50, 100]}
                  showSizeChanger={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Buy
