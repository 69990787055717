import { Button, Form, notification, Select } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { CPO_PROVIDERS, STORAGE_KEYS, VALID_EMAIL } from 'src/constants/constants'
import { load, unSave } from 'src/lib/storage'
import * as AuthServices from 'src/services/auth.service'
import LoginLogo from '../../assets/new-login-logo.png'
import './style.scss'

const { Option } = Select

const isHideOnProduction = process.env.REACT_APP_HIDE_ON_PRODUCTION

const userSetup = [
  {
    label: 'Email',
    name: 'username',
    required: true,
    type: 'text',
    disabled: true
  },
  {
    label: 'Password',
    name: 'password',
    required: true,
    type: 'password'
  },
  {
    label: 'Confirm Password',
    name: 'confirmPassword',
    required: true,
    dependencies: ['password'],
    type: 'password'
  }
]

if (!isHideOnProduction) {
  userSetup.push({
    label: 'Choose CPO Provider',
    name: 'cpoProvider',
    required: true,
    type: 'select',
    options: CPO_PROVIDERS,
    placeholder: 'Choose CPO Provider'
  })
}

function UserSetup({ isClaim }) {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [, forceUpdate] = useState({})
  const data = load(STORAGE_KEYS.VERIFICATION_DEALER_ACCOUNT) || {}
  const { email } = data
  const isPasswordCreate = data.verification_type === 'forgot-password'
  const isPasswordGenerate = data.verification_type === 'generate-password'

  const openNotification = ({ message, description, type = 'success' }) => {
    const action = type === 'success' ? notification.success : notification.error
    action({
      message,
      description
    })
  }

  useEffect(() => {
    forceUpdate({})
    unSave(STORAGE_KEYS.VERIFICATION_DEALER_ACCOUNT)
  }, [])

  const handleFormSubmission = (value) => {
    const username = isPasswordGenerate || isPasswordCreate ? data.email : value.username
    const cpoProvider = isHideOnProduction ? 'Gulf States Finance' : value.cpoProvider

    let params = {
      ...value,
      repeatPassword: value.confirmPassword,
      cpoProvider,
      employeeId: data.user_id,
      passwordToken: data.password_token
    }
    let action = AuthServices.dealershipLoginCreatePassword
    if (!isClaim) {
      action = AuthServices.loginCreatePassword
      params = {
        ...value,
        repeatPassword: value.confirmPassword,
        username,
        cpoProvider,
        userId: data.user_id,
        userRole: data.user_role,
        passwordToken: data.password_token
      }
    }
    action(params)
      .then((response) => {
        const { data } = response
        if (data) {
          openNotification({ message: response.message })
          navigate('/login')
        }
      })
      .catch((error) => {
        if (error.response.data.message !== 'undefined') {
          openNotification({ type: 'error', message: error.response.data.message })
        }
      })
  }

  const validation = (form) => {
    const patternMessage = {
      pattern: /^[a-zA-Z0-9\s]+$/g,
      message: 'Alphanumeric string accepted'
    }
    switch (form.name) {
      case 'username':
        patternMessage.pattern = VALID_EMAIL
        patternMessage.message = 'Invalid email format'
        return patternMessage
      case 'confirmPassword':
        return ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('The two passwords that you entered do not match!'))
          }
        })
      case 'password':
        patternMessage.required = form.required
        patternMessage.pattern = /^(?=.*[\d])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,16}$/
        patternMessage.message =
          'Must Contain min 8 Characters, a Uppercase, a Lowercase, a Number and a Special Character from (!, ?, @, #, $, %, ^, &, *)'
        return patternMessage
      default:
        return patternMessage
    }
  }

  const handleChangeShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleChangeShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const onFormChange = (e) => {
    form.setFieldsValue({ ...form.getFieldValue(), [e.target.name]: e.target.value })
  }

  return (
    <div className="claim-page">
      <div className="auth-main-block">
        <div className="form-user-setup">
          <div className="cursor-pointer text-center" onClick={() => navigate('/')}>
            <img src={LoginLogo} alt="claim logo" className="object-contain" />
          </div>
          {!isPasswordGenerate && !isPasswordCreate && (
            <div className="bg-green-100 p-2 my-6 rounded-2xl">
              <p className="text-center font-semibold text-green-600">YOUR EMAIL HAS BEEN VERIFIED</p>
            </div>
          )}
          <Form
            form={form}
            layout="vertical"
            name="vehicle-ref"
            className="mx-4 lg:px-8 items-center justify-center"
            onFinish={handleFormSubmission}
            onChange={onFormChange}
            initialValues={{ username: email || '' }}
          >
            {!isPasswordGenerate && !isPasswordCreate && (
              <p className="text-black text-center my-4 text-xl font-bold">Setup your login</p>
            )}
            {isPasswordGenerate && <p className="text-black text-center my-4 text-xl font-bold">Generate password</p>}
            {isPasswordCreate && <p className="text-black text-center my-4 text-xl font-bold">Create password</p>}
            {userSetup.map((item, index) => {
              return (
                <Form.Item
                  name={item.name}
                  hasFeedback
                  dependencies={item?.dependencies}
                  rules={[
                    {
                      required: item.required && !isPasswordGenerate && !isPasswordCreate && item.name !== 'username',
                      message: `${item.label} is required`
                    },
                    validation(item)
                  ]}
                  className="mt-5"
                  key={item.name}
                >
                  {item.type === 'password' && (
                    <div className="relative border rounded-xl border-gray">
                      <input
                        placeholder={item.label}
                        name={item.name}
                        autoFocus={index === 0}
                        className={`w-full p-4 box-border rounded-xl border-gray-300 border focus:outline-none ${
                          item.type === 'password' && 'pr-8'
                        }`}
                        type={(item.name === 'password' ? showPassword : showConfirmPassword) ? 'text' : 'password'}
                      />
                      {item.name === 'password' &&
                        (showPassword ? (
                          <AiFillEyeInvisible
                            onClick={handleChangeShowPassword}
                            className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                          />
                        ) : (
                          <AiFillEye
                            onClick={handleChangeShowPassword}
                            className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                          />
                        ))}
                      {item.name === 'confirmPassword' &&
                        (showConfirmPassword ? (
                          <AiFillEyeInvisible
                            onClick={handleChangeShowConfirmPassword}
                            className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                          />
                        ) : (
                          <AiFillEye
                            onClick={handleChangeShowConfirmPassword}
                            className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                          />
                        ))}
                    </div>
                  )}
                  {item.type === 'text' && (
                    <input
                      defaultValue={email || ''}
                      disabled={item.name === 'username'}
                      placeholder={item.label}
                      name={item.name}
                      autoFocus={index === 0}
                      className="w-full p-4 box-border rounded-xl border-gray-300 border focus:outline-none"
                      type={item.type}
                    />
                  )}
                  {item.type === 'select' && (
                    <Select
                      placeholder={item.placeholder || `Select ${item.name}`}
                      allowClear
                      showSearch
                      className="w-full mt-3"
                    >
                      {item.options.map((option) => (
                        <Option value={option.name}>{option.name}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              )
            })}

            <Form.Item className="mt-8 items-center" shouldUpdate>
              {() => (
                <Button type="primary" htmlType="submit" className="w-full h-12 rounded-xl	">
                  {isPasswordGenerate || isPasswordCreate ? 'SAVE' : 'CREATE MY ACCOUNT'}
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

UserSetup.propTypes = {
  isClaim: PropTypes.bool.isRequired
}

export default UserSetup
