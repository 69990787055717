import { Button, Modal, notification } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useState } from 'react'
import TableOfDealers from 'src/pages/AccountDashboard/CreditApplicationDetail/TableOfDealers'
import * as CustomerService from 'src/services/customer.service'
import SharedLinks from '../../SharedLinks'

export function TradeDetailModal({ trade, showModal, handleCloseModal }) {
  const [showModalSharedLinks, setShowModalSharedLinks] = useState(false)
  const [showModalListOfDealers, setShowModalListOfDealers] = useState(false)
  const { vehicle, customer, comment, _id: tradeId } = trade
  const {
    color,
    condition,
    engine,
    make,
    mileage,
    model,
    ownership,
    transmission,
    trim,
    vin,
    year,
    zipcode,
    estimatedvalue
  } = vehicle || {}
  const { email, firstname, lastname, phone } = customer || {}
  const fields = {
    year,
    make,
    model,
    engine,
    mileage,
    ownership,
    transmission,
    trim,
    vin,
    zipcode,
    color,
    condition,
    email,
    'first name': firstname,
    'last name': lastname,
    phone,
    comment,
    'Trade-in value': estimatedvalue ? `$${estimatedvalue.toLocaleString()}` : 0
  }

  // const handleChangeSharedlinks = () => setShowModalSharedLinks(!showModalSharedLinks)

  const handleShareLinkToDealers = (values) => {
    CustomerService.handleShareTradeInVehicleLInk(values)
      .then(() => {
        notification.success({
          message: 'Success!',
          description: 'Shared trade-in vehicle link successfully'
        })
      })
      .catch((error) => {
        notification.error({
          message: 'Error!',
          description: error?.response?.data?.message ?? 'Shared trade-in vehicle link failed'
        })
      })
  }

  const handleChangeShowModalListOfDealers = () => {
    setShowModalListOfDealers(!showModalListOfDealers)
  }

  // const hideOnProduction = process.env.REACT_APP_HIDE_ON_PRODUCTION === 'true'

  return (
    <>
      <Modal
        centered
        visible={showModal}
        title="Trade detail"
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        okText="Close"
        className="calendar-modal"
        destroyOnClose
        width={650}
        footer={[
          <div className="flex justify-end">
            {/* <Button type="primary" onClick={handleChangeShowModalListOfDealers}>
              Share to dealer users
            </Button>
            <Button type="primary" onClick={handleChangeSharedlinks}>
              Shared links
            </Button> */}
            <Button type="primary" onClick={handleCloseModal}>
              Close
            </Button>
          </div>
        ]}
      >
        <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2">
          {Object.keys(fields).map((key) => (
            <div key={key} className="flex gap-2 flex-row">
              <div className="capitalize font-semibold">{key}:</div>
              <div>{fields[key]}</div>
            </div>
          ))}
        </div>
      </Modal>
      {showModalListOfDealers && (
        <TableOfDealers
          objectId={tradeId}
          handleShareLinkToDealers={handleShareLinkToDealers}
          showModal={showModalListOfDealers}
          handleCloseModal={handleChangeShowModalListOfDealers}
        />
      )}
      {showModalSharedLinks && (
        <SharedLinks
          id={tradeId}
          showModalSharedLinks={showModalSharedLinks}
          setShowModalSharedLinks={setShowModalSharedLinks}
        />
      )}
    </>
  )
}

TradeDetailModal.propTypes = {
  trade: PropTypes.object,
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func
}
