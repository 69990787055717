import { Button, Form, Input, Modal, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import ReactInputMask from 'react-input-mask'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import { useEzDealzWalletContext } from 'src/EzDealzWalletProvider'
import EzDealzImg from 'src/assets/icon/Ez-Dealz.png'
import Budget from 'src/components/MyWallet/Budget'
import CreditTerm from 'src/components/MyWallet/CreditTerm'
import DownPayment from 'src/components/MyWallet/DownPayment'
import EstimatedValue from 'src/components/MyWallet/EstimatedValue'
import TradeIn from 'src/components/MyWallet/TradeIn'
import { STORAGE_KEYS, VALID_EMAIL } from 'src/constants/constants'
import { clear, load, save } from 'src/lib/storage'
import * as AuthService from 'src/services/auth.service'
import LoginLogo from '../../assets/new-logo.png'
import MyWallet from '../MyWallet'

const userSetup = [
  {
    label: 'Full Name',
    name: 'fullName',
    required: true,
    type: 'text'
  },
  {
    label: 'Email',
    name: 'email',
    required: true,
    type: 'email'
  },
  {
    label: 'Phone Number',
    name: 'phone',
    required: false,
    type: 'text'
  },
  {
    label: 'Password',
    name: 'password',
    required: true,
    type: 'password'
  },
  {
    label: 'Confirm Password',
    name: 'confirmPassword',
    required: true,
    type: 'password',
    dependencies: ['password']
  }
]

const userLogin = [
  {
    label: 'Email',
    name: 'email',
    required: true,
    type: 'email'
  },
  {
    label: 'Password',
    name: 'password',
    required: true,
    type: 'password'
  }
]

function EzDealzModalWallet() {
  const budgetCalculator = load(STORAGE_KEYS.EZ_DEALZ_BUDGET) || {}
  const { showWalletModal, setShowWalletModal } = useEzDealzWalletContext()
  const [form] = Form.useForm()
  const [showNoHastag, setShowNoHastag] = useState(false)
  const [isLogin, setIsLogin] = useState(false)
  const [myWalletView, setMyWalletView] = useState(-1)
  const [stack, setStack] = useState([])
  const [, forceUpdate] = useState({})
  const { handleCustomerAccountLogin, handleAccountSettingsLogin } = useAuthorizationContext()

  useEffect(() => {
    forceUpdate({})
  }, [])

  const openNotification = (type, msg) => {
    const action = type === 'success' ? notification.success : notification.error
    const message = type === 'success' ? 'Logged in successfully' : 'Incorrect user/email or password'
    action({
      message: msg || message,
      placement: 'topRight'
    })
  }

  const handleModal = (hastag, isLogin) => {
    setShowNoHastag(hastag)
    setShowWalletModal(false)
    setIsLogin(isLogin)
    setMyWalletView(-1)
    setStack([])
  }

  const handleSignIn = () => {
    const { email, password } = form.getFieldsValue()
    AuthService.loginWithEmail({ email, password })
      .then((response) => {
        const data = response.data
        const { access_token: accessToken, token_type: tokenType } = data
        const userSettings = JSON.stringify(data)
        save(STORAGE_KEYS.ACCESS_TOKEN, accessToken)
        save(STORAGE_KEYS.TOKEN_TYPE, tokenType)
        save(STORAGE_KEYS.CUSTOMER_LOGIN, 'true')
        save(STORAGE_KEYS.USER_SETTINGS, userSettings)
        clear(STORAGE_KEYS.FAVOURITE_CARS)
        handleCustomerAccountLogin(true)
        handleAccountSettingsLogin(userSettings)
        openNotification('success')
        setShowNoHastag(false)
        setShowWalletModal(true)
        setIsLogin(true)
        setMyWalletView(-1)
        setStack([])
      })
      .catch((error) => {
        openNotification('error')
        console.error(error)
      })
  }

  const handleFormSubmission = async () => {
    if (!isLogin) {
      let data = form.getFieldsValue()

      const name = data.fullName.split(' ')
      if (name.length > 1) {
        data = {
          ...data,
          firstName: name[0],
          lastName: name[1],
          customerBudgetCalculator: budgetCalculator
        }
      } else {
        data = {
          ...data,
          firstName: data.fullName,
          lastName: data.fullName,
          customerBudgetCalculator: budgetCalculator
        }
      }

      await AuthService.customerSignup(data)
        .then((response) => {
          const { isSuccessful, data } = response
          if (isSuccessful === 'Yes') {
            handleSignIn()
          } else {
            openNotification('error', data.message)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    } else {
      handleSignIn()
    }
  }

  const returnTo = () => {
    if (stack.length === 0) {
      setMyWalletView(-1)
    } else {
      setMyWalletView(stack[0])
      stack.pop()
    }
  }

  const renderMyWallet = () => {
    switch (myWalletView) {
      case 1:
        return <CreditTerm />
      case 2:
        return (
          <Budget
            selectedCard={(index) => {
              setMyWalletView(index)
              stack.push(6)
            }}
          />
        )
      case 3:
        return <DownPayment />
      case 4:
        return (
          <TradeIn
            selectedCard={(index) => {
              setMyWalletView(index)
              stack.push(4)
            }}
            setMyWalletView={setMyWalletView}
            setStack={setStack}
          />
        )
      case 5:
        return <EstimatedValue setMyWalletView={setMyWalletView} />
      case 6:
        return (
          <Budget
            selectedCard={(index) => {
              setMyWalletView(index)
              stack.push(6)
            }}
          />
        )
      default:
        return (
          <MyWallet
            selectedCard={(index) => setMyWalletView(index)}
            handleModal={(hastag, isLogin) => handleModal(hastag, isLogin)}
          />
        )
    }
  }

  const validatePasswordConfirmation = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('The two passwords that you entered do not match!'))
    }
  })

  const validation = (form, antdForm) => {
    const paternMessage = {
      pattern: /^[a-zA-Z\s]+$/g,
      message: 'Only string accepted'
    }
    switch (form.name) {
      case 'password':
        paternMessage.pattern = /^(?=.*[\d])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,16}$/
        paternMessage.message =
          'Must Contain min 8 Characters, a Uppercase, a Lowercase, a Number and a Special Character from (!, ?, @, #, $, %, ^, &, *)'
        return paternMessage
      case 'confirmPassword':
        return validatePasswordConfirmation(antdForm)
      case 'email':
        paternMessage.pattern = VALID_EMAIL
        paternMessage.message = 'Invalid email format'
        return paternMessage
      case 'phone':
        paternMessage.pattern = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/
        paternMessage.message = 'Invalid phone number format'
        return paternMessage
      default:
        return paternMessage
    }
  }

  const renderForm = (form, index) => {
    if (form.type === 'password') {
      return (
        <Input.Password
          name={form.name}
          placeholder={form.label}
          className="py-3 box-border rounded-xl border-gray-300 border focus:outline-none"
        />
      )
    }
    if (form.name === 'phone') {
      return (
        <ReactInputMask
          name="phone"
          mask="999-999-9999"
          placeholder="NXX-NXX-XXXX"
          className="py-3 px-2.5 box-border rounded-xl border-gray-300 border focus:outline-none w-full"
          type="text"
          hasFeedback
        />
      )
    }
    return (
      <Input
        placeholder={form.label}
        name={form.name}
        autoFocus={index === 0}
        className="py-3 box-border rounded-xl border-gray-300 border focus:outline-none"
        type={form.type}
      />
    )
  }

  return (
    <>
      <Modal
        visible={showNoHastag}
        footer={null}
        closable
        onCancel={() => handleModal(false)}
        bodyStyle={{ padding: 0, maxHeight: 715, overflow: 'auto' }}
        style={{ top: 20 }}
        title={
          <div className="flex items-center justify-items-start justify-between">
            <p>
              NO HASSLE, NO HAGGLE,
              <br />
              NO PROBLEM… IN NO TIME!
            </p>
            <div className="flex flex-row items-center">
              <img src={LoginLogo} alt="" className="h-10 pr-5" />
            </div>
          </div>
        }
      >
        <div className="flex flex-col justify-center items-center bg-gray-200 w-full p-6 ">
          <Form form={form} className="mt-4 w-full px-5" onFinish={handleFormSubmission}>
            <span className={`${!isLogin && 'grid'} lg:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-6`}>
              {(isLogin ? userLogin : userSetup).map((formModel, index) => (
                <div key={index}>
                  <p className="pb-2 text-base font-semibold">{formModel.label}</p>
                  <Form.Item
                    name={formModel.name}
                    hasFeedback
                    dependencies={formModel?.dependencies}
                    rules={[
                      {
                        required: formModel.required,
                        message: `${formModel.label} is required`
                      },
                      validation(formModel, form)
                    ]}
                    className="w-full"
                  >
                    {renderForm(formModel, index)}
                  </Form.Item>
                </div>
              ))}
            </span>
            <div className="flex w-full mt-8 justify-center">
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="primary"
                    className="h-12 rounded-lg font-bold"
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form.getFieldsError().filter(({ errors }) => errors.length).length
                    }
                    onClick={handleFormSubmission}
                  >
                    {isLogin ? 'Login To' : 'Create My'} Ez-Dealz
                  </Button>
                )}
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal
        style={{ top: 20 }}
        title={
          <div className="flex flex-col sm:flex-row items-center justify-items-start justify-between">
            <div className="flex items-center">
              <img alt="Ez-Dealz log" src={EzDealzImg} className="w-8 h-8" onClick={() => setShowWalletModal(true)} />
              <p className="text-lg ml-1 text-left font-bold">
                Ez-Dealz <br />
                Budget Calculator
              </p>
            </div>
            <div className="flex flex-row items-center">
              <img src={LoginLogo} alt="" className="mr-2" style={{ height: '2.7rem' }} />
              <MdClose className="ml-4 text-white" size={27} onClick={() => setShowWalletModal(false)} />
            </div>
          </div>
        }
        centered
        onCancel={() => setShowWalletModal(false)}
        visible={showWalletModal}
        footer={null}
        bodyStyle={{ padding: 0, maxHeight: 715, overflow: 'auto' }}
      >
        <div className="flex flex-col text-white items-center bg-darkPrimary">
          {myWalletView !== -1 && (
            <div className="bg-gray-200 text-blue-400 w-full px-6 py-3">
              <p className="cursor-pointer font-semibold" onClick={returnTo}>
                Back to {stack.length === 0 ? 'My Ez-Deal' : 'previous'}
              </p>
            </div>
          )}
          {renderMyWallet()}
        </div>
      </Modal>
    </>
  )
}

export default EzDealzModalWallet
