export const claimBusinessPayloadAdapter = (
  selectedDealership,
  dealershipDetail,
  dealershipInfo,
  paymentInfo,
  dealershipDetailStep5,
  userAgent,
  step
) => ({
  on_step: step || 0,
  user_agent: userAgent || window.navigator.userAgent,
  dealer_id: selectedDealership?.dealerId,
  first_name: dealershipDetail.firstName,
  last_name: dealershipDetail.lastName,
  role: dealershipDetail?.role?.value || dealershipDetail?.role,
  term: paymentInfo?.selectedPrice,
  price_amount: paymentInfo.totalMonthly,

  bank_name: paymentInfo.paymentDetails?.bankName,
  account_nr: paymentInfo.paymentDetails?.bankAccountNumber,
  routing_nr: paymentInfo.paymentDetails?.bankRoutingNumber,

  is_not_dealership_email: dealershipDetail.hasEmailAddress,

  cc_nr: paymentInfo.paymentDetails?.cardNumber,
  cc_nh: paymentInfo.paymentDetails?.cardHolder,
  cc_exp: paymentInfo.paymentDetails?.expiration,
  cc_cvv: paymentInfo.paymentDetails?.cvv,
  cc_zip: paymentInfo.paymentDetails?.billingZipCode,

  special_terms: paymentInfo?.paymentDetails?.specialterms,
  bill_my_dealership_email: paymentInfo?.paymentDetails?.bill_my_dealership_email || '',
  bill_my_dealership_phone: paymentInfo?.paymentDetails?.bill_my_dealership_phone || '',
  bill_my_dealership_contact_name: paymentInfo?.paymentDetails?.bill_my_dealership_contact_name || '',

  dealer_name: dealershipDetail.representativeName,
  contacts: {
    address: dealershipDetail.physicalAddress,
    city: dealershipDetail.city,
    state: dealershipDetail.state.value || selectedDealership.state,
    zip: dealershipDetail.zip,
    phone: dealershipDetail.phone,
    email: dealershipDetail.email,
    mobile: dealershipDetail.mobile || ''
  },
  website: dealershipDetail.website,
  inventory: dealershipDetailStep5?.inventory || '',
  inventory_contact_name: dealershipDetailStep5?.formInventory?.inventory_contact_name || '',
  inventory_email: dealershipDetailStep5?.formInventory?.inventory_email || '',
  inventory_phone: dealershipDetailStep5?.formInventory?.inventory_phone || '',
  dealership_get_leads_email: dealershipDetailStep5?.formGetLeads?.get_leads_email || '',
  dealership_get_leads_method: dealershipDetailStep5?.getLeads || 'adf_xml',
  crm: dealershipDetailStep5?.selectedCrm ?? null,
  is_niada_member: dealershipInfo?.isNiadaMember === 'yes',
  average_vehicles_stock: dealershipInfo?.vehicleQuantity || ''
})

export const claimEmailPayloadAdapter = ({ dealershipDetail, paymentInfo, userAgent, step }) => ({
  on_step: step || 0,
  user_agent: userAgent || window.navigator.userAgent,
  first_name: dealershipDetail.firstName,
  last_name: dealershipDetail.lastName,
  // role: dealershipDetail.role,
  term: paymentInfo?.selectedPrice,
  price_amount: paymentInfo.totalMonthly,

  bank_name: paymentInfo.paymentDetails?.bankName,
  account_nr: paymentInfo.paymentDetails?.bankAccountNumber,
  routing_nr: paymentInfo.paymentDetails?.bankRoutingNumber,

  cc_nr: paymentInfo.paymentDetails?.cardNumber,
  cc_ch: paymentInfo.paymentDetails?.cardHolder,
  cc_exp: paymentInfo.paymentDetails?.expiration,
  cc_cvv: paymentInfo.paymentDetails?.cvv,
  cc_zip: paymentInfo.paymentDetails?.billingZipCode,

  special_terms: paymentInfo?.paymentDetails?.specialterms,
  bill_my_dealership_email: paymentInfo?.paymentDetails?.bill_my_dealership_email || '',
  bill_my_dealership_phone: paymentInfo?.paymentDetails?.bill_my_dealership_phone || '',
  bill_my_dealership_contact_name: paymentInfo?.paymentDetails?.bill_my_dealership_contact_name || '',

  dealer_name: dealershipDetail.representativeName,
  contacts: {
    address: dealershipDetail.physicalAddress,
    city: dealershipDetail.city,
    state: dealershipDetail.state?.value || dealershipDetail.state,
    zip: dealershipDetail.zip,
    phone: dealershipDetail.phone,
    email: dealershipDetail.email
  },
  website: dealershipDetail.website
})
