import * as PropTypes from 'prop-types'
import React from 'react'
import '../style.scss'

function MSAContent({ dealerName, selectedPrice }) {
  const month = selectedPrice === 'month to Month' ? 'monthly basis' : `${selectedPrice} months`
  return (
    <div className="overflow-auto h-80 bg-gray-100 p-5 msa-content">
      <div className="font-bold text-2xl mb-2 text-center">MOTOix Master Services Agreement</div>
      <div className="italic mb-4">
        <span className="text-red-500">*</span>A MOTOix company
      </div>
      <div className="font-bold text-xl mb-4">{`Dealership(s): ${dealerName || ''}`}</div>
      <div>
        <ol>
          <li>
            <p className="mb-4">
              <span className="mr-5">1.</span> TERM. MOTOix provides a co-termination Platform License, which sets
              things up so that the termination dates of ALL of your MOTOix Platform Licenses and add-on services are
              adjusted to fall on a single date for all rooftops. Licensing co-termination is a huge convenience,
              because it makes possible for all rooftops in your MOTOix eco-system to terminate on the same date, and
              there is one renewal date for all stores which makes keeping track of automatic renewals spread over
              several rooftops easier to keep track of.
            </p>
            <ol>
              <li className="mb-4 sub-paragraph">
                <span className="mr-5">1.1</span>
                <span className="underline">Initial Agreement.</span>
                {` This Initial Agreement as well as monthly recurring billing shall commence (the
                "Initial Effective Date") and shall continue in effect for ${month} ("Initial Agreement Term"). On the
                ${month} Initial Agreement Term anniversary this Initial Agreement will automatically renew for an
                additional ${month} ("Renewal Term(s)"), if no written notice of an intent to terminate the agreement
                has been received by the Provider in writing 60 days prior to the end of the Initial Agreement Term or
                any subsequent Renewal Terms. This cycle of renewal and review will continue throughout the agreement
                relationship between Provider and Client, unless the Client adds an additional rooftop or MOTOix
                service. In the foregoing case, the Effective Date of this Agreement will be the website and/or CRM
                installation date in the new rooftop or 90-days after the contract endorsement date (whichever occurs
                first) and shall continue in effect for ${month} thereafter (e.g. "First Adjusted Agreement Term ",
                "Second Adjusted Agreement Term", etc.). The Agreement will automatically renew for ${month}
                thereafter on the ${month} of the Adjusted Agreement Term, unless Client notifies Provider in writing
                60 days prior to the ${month} of the Adjusted Agreement Term of an intent not to renew the Agreement.`}
              </li>
              <li className="mb-4 sub-paragraph">
                <span className="mr-5">1.2</span>
                <span className="underline">Effect of Termination Notice. </span>
                The rights, duties and obligations of the parties shall continue in full force during or following the
                period of the termination notice until termination, including the ordering and billing of advertising in
                media whose closing dates follow then such period.
              </li>
              <li className="mb-4 sub-paragraph">
                <span className="mr-5">1.3</span>
                <span className="underline">Rights Upon Termination. </span>
                {`Upon termination of the Agreement, Provider shall transfer, assign and make
                available to Client all property and materials in Provider's possession or subject to Provider's control
                that are the property of Client, subject to payment in full of amounts due pursuant to this Agreement.
                Contingent upon the foregoing, Provider further agrees to provide reasonable cooperation in arranging
                for the transfer or approval of third party's interest in all contracts, agreements and other
                arrangements with advertising media, suppliers, talent and others not then utilized, and all rights and
                claims thereto and therein, following appropriate release from the obligations therein.`}
              </li>
            </ol>
          </li>
          <li>
            <p className="mb-4">
              <span className="mr-5">2.</span> BILLING
            </p>
            <ol>
              <li className="mb-4 sub-paragraph">
                <span className="mr-5">2.1</span>
                {`The Provider will bill the Client’s account monthly for the base MOTOix Platform License. The Provider then will bill the Client for any additional services agreed to on a monthly or project basis as dictated by the service. `}
              </li>
              <li className="mb-4 sub-paragraph">
                <span className="mr-5">2.2</span>
                Client agrees to place recurring charges for additional MOTOix Platform services and related MOTOix
                products and services on ACH through the company’s bank information. Invoices shall be submitted in an
                itemized format and shall be charged to Client’s ACH account within ten (10) days of the invoice date.
              </li>
              <li className="mb-4 sub-paragraph">
                <span className="mr-5">2.3</span>
                {`Charges for any production or materials and professional services mutually agreed to and outlined in a SOW (Statement of Work) shall be billed by the Provider upon completion of the production job or, if cash discounts are available, upon receipt of the supplier's invoice.`}
              </li>
              <li className="mb-4 sub-paragraph">
                <span className="mr-5">2.4</span>
                {`On all outside purchases including media, the Provider shall attach to the invoice proof of the supplier's charges.`}
              </li>
              <li className="mb-4 sub-paragraph">
                <span className="mr-5">2.5</span>
                All cash discounts on the Provider’s purchases including, but not limited to, media, art, printing and
                mechanical work, shall be available to the Client, provided that Client meets Provider’s requisite
                billing terms and there is no outstanding indebtedness of Client to Provider at the time of the payment
                to the supplier.
              </li>
              <li className="mb-4 sub-paragraph">
                <span className="mr-5">2.6</span>
                Rate or billing adjustments shall be credited or charged to the Client on the next following regular
                invoice date or as soon as otherwise practical.
              </li>
            </ol>
          </li>
          <li>
            <p className="mb-4">
              <span className="mr-5">3.</span> CLIENT’S WAIVER AND RELEASE OF LIABILITY
            </p>
            <ol>
              <li className="mb-4 sub-paragraph">
                <span className="mr-5">3.1</span>
                Waiver: Client does hereby waive any claim against the Provider, it’s employees, agents, servants, or
                representatives and does agree to hold them harmless for any actual, consequential, indirect, special,
                incidental, reliance, exemplary, or punitive damages, or loss, expense, or other injury arising from
                their efforts to advertise on behalf of Client’s business whether such damages are foreseeable or not,
                and whether such claims are based on the alleged breach of any express or implied warranty, breach of
                contract, misrepresentation, negligence, or strict liability (including damages for loss of data,
                goodwill, reputation, business, money, or opportunity), even if the Provider has been advised of the
                possibility of such damages except that the provider shall retain the responsibility and liability for
                providing marketing products and services.
              </li>
              <li className="mb-4 sub-paragraph">
                <span className="mr-5">3.2</span>
                Disclaimer of Warranty: Except as otherwise expressly agreed to herein, the Provider makes no warranty
                of any kind, either express or implied, regarding the quality, accuracy, reliability, or validity of the
                applications, software, data, or information related to the Provider’s network, systems, or marketing
                services. The Provider delivers its products and services “as is” and specifically disclaims all
                warranties of merchantability and fitness for a particular purpose. Client understands, acknowledges,
                and agrees that it will use the Provider’s products and services, and all aspects thereof, at Client’s
                sole risk.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div className="italic text-xs">
        I understand that this authorization will remain in effect until I cancel it in writing, and I agree to notify
        MOTOix in writing of any changes in my account information or termination of this authorization at least
        15 days prior to the next billing date. If the above noted payment dates fall on a weekend or holiday, I
        understand that the payments may be executed on the next business day. For ACH debits to my checking/savings
        account, I understand that because these are electronic transactions, these funds may be withdrawn from my
        account as soon as the above noted periodic transactions dates. In this case of an ACH Transaction being
        rejected for Non-Sufficient Funds (NSF) I understand that MOTOix may at its discretion attempt to process
        the charge again within 30 days and agree to an additional $25.00 charge for each attempt returned NSF which
        will be initiated as a separate transaction from the authorized recurring payment. I acknowledge that the
        origination of ACH transactions to my account must comply with the provisions of U.S. law. I certify that I am
        an authorized user of this bank account and will not dispute these scheduled transactions with my bank; so long
        as the transactions correspond to the terms indicated in this authorization form.
      </div>
    </div>
  )
}

MSAContent.propTypes = {
  dealerName: PropTypes.string,
  selectedPrice: PropTypes.string
}

export default MSAContent
