import { Button, Form, Modal, notification } from 'antd'
import { cloneDeep } from 'lodash'
import * as PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AiFillSave, AiOutlineClose } from 'react-icons/ai'
import { /* BsArrowLeftRight, BsFillShareFill, */ BsFillPencilFill, BsTrash } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import * as VehicleService from 'src/services/vehicle.service'
import { useAuthorizationContext } from '../../../../AuthorizationProvider'
import * as adminService from '../../../../services/admin.service'
import * as OptionService from '../../../../services/option.service'
import * as vehicleService from '../../../../services/vehicle.service'
import { newPartialUpdateVehicleAdapter, newStatusUpdateVehicleAdapter } from './adapters'
import './style.scss'

const STATUSES = [
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'Not active' }
]

const newUsedCertFlagOptions = [
  { value: '', label: 'None' },
  { value: 'N', label: 'CPO' },
  { value: 'U', label: 'VIP' }
]

const bodies = [
  { value: 'Cab Over Engine', label: 'Cab Over Engine' },
  { value: 'Chassis', label: 'Chassis' },
  { value: 'Commercial', label: 'Commercial' },
  { value: 'Conventional', label: 'Conventional' },
  { value: 'Convertible', label: 'Convertible' },
  { value: 'Coupe', label: 'Coupe' },
  { value: 'Full-Size', label: 'Full-Size' },
  { value: 'Hatchback', label: 'Hatchback' },
  { value: 'Mini-Van', label: 'Mini-Van' },
  { value: 'Pickup', label: 'Pickup' },
  { value: 'Sedan', label: 'Sedan' },
  { value: 'Wagon', label: 'Wagon' },
  { value: 'SUV', label: 'SUV' },
  { value: 'Unspecified', label: 'Unspecified' }
]

const colors = [
  { value: 'White', label: 'White' },
  { value: 'Black', label: 'Black' },
  { value: 'Gray', label: 'Gray' },
  { value: 'Silver', label: 'Silver' },
  { value: 'Red', label: 'Red' },
  { value: 'Blue', label: 'Blue' },
  { value: 'Brown', label: 'Brown' },
  { value: 'Green', label: 'Green' },
  { value: 'Beige', label: 'Beige' },
  { value: 'Orange', label: 'Orange' },
  { value: 'Gold', label: 'Gold' },
  { value: 'Yellow', label: 'Yellow' },
  { value: 'Purple', label: 'Purple' }
]

const transmissions = [
  { value: 'A', label: 'Automatic' },
  { value: 'M', label: 'Manual' },
  { value: 'CVT', label: 'CVT' },
  { value: 'Unspecified', label: 'Unspecified' }
]

const yearOptions = () => {
  const options = []
  for (let i = 2024; i >= 1990; --i) {
    options.push({
      label: i,
      value: i
    })
  }
  return options
}

function VehicleDetail({ info, loading, id, setCarDetail, similarVehicles, createNewVehicle, carOptions }) {
  const { dealershipAccount } = useAuthorizationContext()
  const navigate = useNavigate()
  const canEditInventory = undefined !== dealershipAccount ? dealershipAccount?.permissions?.edit_inventory : true

  const [makes, setMakes] = useState([])
  const [models, setModels] = useState([])
  const [selectedVin, setSelectedVin] = useState()
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(loading)
  const [vehicleDetail, setVehicleDetail] = useState({})
  const {
    active,
    year,
    make,
    model,
    price,
    miles,
    fuel,
    vin,
    body,
    style,
    equipment,
    options: arrOptionIds,
    engine_name: engineDescription,
    transmission_type: transmissionType,
    exterior_color_type: exteriorColor,
    exterior_color: exteriorColorLabel,
    interior_color: interiorColor,
    stock_number: stockNumber,
    new_used_cert_flag: newUsedCertFlag
  } = vehicleDetail || {}
  const [selectedYear, setSelectedYear] = useState(year)
  const [selectedMake, setSelectedMake] = useState(make)
  const [selectedModel, setSelectedModel] = useState(model)
  const [selectedActive, setSelectedActive] = useState(active)
  const [selectedBody, setSelectedBody] = useState(body)
  const [selectedTransmissionType, setSelectedTransmissionType] = useState(transmissionType)
  const [selectedColor, setSelectedColor] = useState(exteriorColor)
  const [selectedNewUsedCertFlag, setSelectedNewUsedCertFlag] = useState(newUsedCertFlag)
  const [options, setOptions] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([])
  const [editVehicle, setEditVehicle] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [infoModal, setInfoModal] = useState(false)
  const [deleteVehicleDetail, setDeleteVehicleDetail] = useState({
    year: '',
    make: '',
    model: ''
  })

  const initialRef = useRef(true)

  const handleVin = (event) => {
    setSelectedVin(event.target.value)
  }

  useEffect(() => {
    vehicleService
      .getYearsMakesModels()
      .then((response) => {
        const data = response.data
        setMakes(data.makes)
        setModels(data.models)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    setSelectedYear(year)
    setSelectedMake(make)
    setSelectedModel(model)
    setSelectedActive(active)
    setSelectedBody(body)
    setSelectedTransmissionType(transmissionType)
    setSelectedColor(exteriorColor)
    setSelectedNewUsedCertFlag(newUsedCertFlag)
  }, [newUsedCertFlag, year, make, model, active, body, transmissionType, exteriorColor])

  useEffect(() => {
    if (info) {
      if (createNewVehicle) {
        info.vin = ''
      }
      setVehicleDetail(info)
      setIsLoading(false)
    }
  }, [info])

  const openNotification = (type, errorMessage) => {
    let notificationErrorMessage = ''
    if (type === 'error') {
      notificationErrorMessage = errorMessage !== undefined ? errorMessage : 'Error'
    }
    const action = type === 'success' ? notification.success : notification.error
    const message = type === 'success' ? 'Updated successfully' : notificationErrorMessage
    action({
      message,
      placement: 'topRight'
    })
  }

  const handleAddOption = useCallback(
    (value) => {
      const selectedOptionsClone = cloneDeep(selectedOptions)
      selectedOptionsClone.push(value)
      setSelectedOptions(selectedOptionsClone)
      const newOptionsList = options.filter((item) => selectedOptionsClone.every((sub) => item.value !== sub.value))
      setOptions(newOptionsList)
    },
    [selectedOptions, options]
  )

  const handleRemoveOption = useCallback(
    (value) => {
      const optionsClone = cloneDeep(options)
      optionsClone.push(value)
      setOptions(optionsClone)
      const newSelectedOptions = selectedOptions.filter((item) => optionsClone.every((sub) => item.value !== sub.value))
      setSelectedOptions(newSelectedOptions)
    },
    [selectedOptions, options]
  )

  const [originalData, setOriginalData] = useState([])

  useEffect(() => {
    if (arrOptionIds?.length > 0 && options?.length > 0 && initialRef.current) {
      const newOptions = options.filter((item) => !arrOptionIds.includes(item.value))
      setOptions(newOptions)
      initialRef.current = false
    }
  }, [arrOptionIds, options])

  useEffect(() => {
    if (arrOptionIds?.length > 0 && originalData?.length > 0 && initialRef.current) {
      const newSelectedOptions = originalData.filter((item) => arrOptionIds.includes(item.value))
      setSelectedOptions(newSelectedOptions)
      initialRef.current = false
    }
  }, [arrOptionIds, originalData])

  useEffect(() => {
    if (carOptions?.length > 0 && options?.length > 0 && initialRef.current) {
      const carOptionsIds = carOptions.map((element) => element._id)
      const newOptions = options.filter((item) => !carOptionsIds.includes(item.value))
      setOptions(newOptions)
      const newSelectedOptions = options.filter((item) => carOptionsIds.includes(item.value))
      setSelectedOptions(newSelectedOptions)
      initialRef.current = false
    }
  }, [options, carOptions, selectedOptions])

  const handleInitialFormValue = useCallback(() => {
    form.setFieldsValue({
      year,
      make,
      model,
      body,
      style,
      miles,
      price,
      engine_description: engineDescription,
      transmission_type: transmissionType,
      exterior_color_type: exteriorColor,
      exterior_color: exteriorColorLabel,
      interior_color: interiorColor,
      vin,
      stock_number: stockNumber,
      fuel,
      new_used_cert_flag: newUsedCertFlag
    })
  }, [vehicleDetail])
  useEffect(() => {
    if (vehicleDetail) {
      handleInitialFormValue()
    }
  }, [vehicleDetail])

  const handleEditVehicleButton = () => {
    setEditVehicle(true)
  }

  useEffect(() => {
    OptionService.getOptions().then((result) => {
      setOptions(result.data)
      setOriginalData(result.data)
    })
    if (equipment) {
      setSelectedOptions(equipment)
    }
  }, [])

  const getIsActive = () => {
    if (active) {
      return 'Active'
    }
    return 'Not active'
  }

  const getIsCertified = () => {
    if (newUsedCertFlag === 'U') {
      return 'VIP'
    }
    if (newUsedCertFlag === 'N') {
      return 'CPO'
    }
    return 'None'
  }

  const getPrice = () => {
    const tmpPrice = price?.toLocaleString()
    if (undefined !== tmpPrice) {
      return `$${tmpPrice.toString()}`
    }
    return ''
  }

  const getTransmissionType = () => {
    const currentTransimissonType = transmissions.find((item) => item.value === transmissionType)
    return currentTransimissonType?.label || 'Unspecified'
  }

  const onStatusChange = (e) => {
    setSelectedActive(e.target.value === 'true')
  }

  const onYearChange = (e) => {
    setSelectedYear(e.target.value)
  }

  const onMakeChange = (e) => {
    setSelectedMake(e.target.value)
    setSelectedModel(models[e.target.value][0].value)
  }

  const onModelChange = (e) => {
    setSelectedModel(e.target.value)
  }

  const onBodyTypeChange = (e) => {
    setSelectedBody(e.target.value)
  }

  const onTransmissionChange = (e) => {
    setSelectedTransmissionType(e.target.value)
  }

  const onColorChange = (e) => {
    setSelectedColor(e.target.value)
  }

  const onBadgeChange = (e) => {
    setSelectedNewUsedCertFlag(e.target.value)
  }

  const handleDiscardChanges = () => {
    setEditVehicle(false)
    handleInitialFormValue()
  }

  const [toggleEdtiStatus, setToggleEditStatus] = useState(false)
  const handleToggleStatus = (state) => {
    setToggleEditStatus(state)
  }

  const handleSaveStatus = () => {
    setIsLoading(true)

    vehicleService
      .partialUpdateVehicle(id, newStatusUpdateVehicleAdapter(selectedActive))
      .then((response) => {
        setVehicleDetail(response.data)
        setCarDetail({
          vehicleDetail: response.data,
          similarVehicles
        })
        setToggleEditStatus(false)
        openNotification('success')
        navigate(`/dealer-vehicles/${id}`)
      })
      .catch((e) => {
        let errorMessage = e.message
        if (e.response) {
          errorMessage = e.response.data.message
        }
        openNotification('error', errorMessage)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleSaveEvent = (formData) => {
    setIsLoading(true)
    const options = selectedOptions?.map((item) => item.value)
    formData.year = selectedYear
    formData.make = selectedMake
    formData.model = selectedModel
    formData.fuel = formData.fuel !== undefined ? formData.fuel : vehicleDetail.fuel
    formData.body = selectedBody
    formData.exterior_color_type = selectedColor
    formData.exterior_color =
      formData.exterior_color !== undefined ? formData.exterior_color : vehicleDetail.exterior_color
    formData.style = formData.style !== undefined ? formData.style : vehicleDetail.style
    formData.miles = formData.miles !== undefined ? formData.miles : vehicleDetail.miles
    formData.engine_name =
      formData.engine_description !== undefined ? formData.engine_description : vehicleDetail.engine_name
    formData.interior_color =
      formData.interior_color !== undefined ? formData.interior_color : vehicleDetail.interior_color
    formData.vin = formData.vin !== undefined ? formData.vin : vehicleDetail.vin
    formData.stock_number = formData.stock_number !== undefined ? formData.stock_number : vehicleDetail.stock_number
    formData.transmission_type = selectedTransmissionType
    formData.new_used_cert_flag = selectedNewUsedCertFlag

    vehicleService
      .partialUpdateVehicle(id, newPartialUpdateVehicleAdapter(formData, options))
      .then((response) => {
        setVehicleDetail(response.data)
        setCarDetail({
          vehicleDetail: response.data,
          similarVehicles
        })
        setEditVehicle(false)
        openNotification('success')
        navigate(`/dealer-vehicles/${id}`)
      })
      .catch((e) => {
        let errorMessage = e.message
        if (e.response) {
          errorMessage = e.response.data.message
        }
        openNotification('error', errorMessage)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleDecode = (e, vin) => {
    setIsLoading(true)
    VehicleService.getVehicleVinInfo(vin)
      .then((response) => {
        if (response.isSuccessful === 'No') {
          // handle error
        } else {
          const { year, make, model, style, miles, body } = response.data
          const vehicle = {
            make,
            model,
            style,
            miles,
            body,
            year,
            vin: selectedVin,
            exterior_color_type: response.data.colors.exterior_colors[0].type,
            fuel: response.data.engines[0].fuel,
            engine_name: response.data.engines[0].name,
            displayName: `${year} ${make} ${model}`,
            engine_description: response.data.engines[0].name,
            exterior_color: response.data.exterior_color,
            interior_color: response.data.interior_color,
            transmission_type: response.data.transmission_name
          }
          setVehicleDetail(vehicle)
        }
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => setIsLoading(false))
  }

  const handleDeleteBtn = () => {
    setShowModal(true)
  }

  const handleChangeShowModal = () => {
    setShowModal(!showModal)
  }

  const handleCloseInfoModal = () => {
    setInfoModal(!infoModal)
    navigate('/dealer-dashboard')
  }

  const handleConfirmDelete = () => {
    adminService
      .deleteMyInventoryVehicle(info._id)
      .then((response) => {
        if (response.isSuccessful === 'Yes') {
          setShowModal(false)
          setDeleteVehicleDetail({
            year: response.data.year,
            make: response.data.make,
            model: response.data.model
          })
          setInfoModal(true)
        }
      })
      .catch((e) => {
        openNotification({ message: e.message, description: 'The vehicle is not deleted', type: 'error' })
        setShowModal(false)
      })
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center">
        <div className="loader" />
      </div>
    )
  }
  return (
    <div className="relative pb-4 mb-6">
      <div className="flex flex-col gap-4">
        <div className="flex justify-between border-b mb-4">
          <Form form={form} className="w-full" autoComplete="off" onFinish={handleSaveEvent}>
            <div className="flex gap-5">
              <p className="text-2xl font-bold">{isLoading ? 'Loading' : `${year} ${make} ${model}`}</p>
            </div>
            <div className="flex justify-between border-b mb-4">
              <p>
                {vehicleDetail.dealer_name}
                <br />
                {vehicleDetail.dealer_city}, {vehicleDetail.dealer_state}, {vehicleDetail.dealer_address}
              </p>
              <div className="flex items-center gap-x-2">
                {canEditInventory && !toggleEdtiStatus && (
                  <BsFillPencilFill
                    className="w-4 h-4 cursor-pointer hover:bg-primary hover:text-white text-primary mr-2"
                    onClick={() => handleToggleStatus(true)}
                  />
                )}
                {toggleEdtiStatus && (
                  <div className="flex gap-x-2">
                    <Button
                      icon={<AiOutlineClose />}
                      className="flex items-center justify-center border-none shadow-none  cursor-pointer hover:bg-primary hover:text-white text-primary"
                      type="primary"
                      onClick={() => handleToggleStatus(false)}
                    />
                    <Button
                      icon={<AiFillSave />}
                      className="flex items-center justify-center border-none shadow-none cursor-pointer hover:bg-primary hover:text-white text-primary"
                      type="primary"
                      htmlType="submit"
                      onClick={() => handleSaveStatus()}
                    />
                  </div>
                )}
                <div className="flex flex-col justify-center text-sm mt-1">
                  <div className="col-span-2 text-gray-400">STATUS</div>
                  <div className="col-span-2 ">
                    {!toggleEdtiStatus ? (
                      getIsActive()
                    ) : (
                      <Form.Item name="active" className="custom-antd-label">
                        <select
                          value={selectedActive}
                          name="active"
                          onChange={onStatusChange}
                          className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                        >
                          {STATUSES.map((item) => (
                            <option value={item.value} key={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </Form.Item>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-3 justify-between items-center">
              <div className="flex items-center gap-3 flex-wrap">
                <div className="flex gap-2 text-xl items-end justify-end">
                  {/* <BsArrowLeftRight className="cursor-pointer hover:bg-primary hover:text-white text-primary" /> */}
                  {editVehicle && (
                    <>
                      <Form.Item shouldUpdate className="mb-0">
                        {() => (
                          <Button
                            icon={<AiFillSave />}
                            className="flex gap-2 font-semibold text-lg items-center rounded-md"
                            type="primary"
                            htmlType="submit"
                          >
                            Save
                          </Button>
                        )}
                      </Form.Item>
                      <Button
                        icon={<AiOutlineClose />}
                        className="flex gap-2 font-semibold text-lg items-center rounded-md"
                        type="primary"
                        onClick={handleDiscardChanges}
                      >
                        Discard
                      </Button>
                    </>
                  )}
                  {canEditInventory && !editVehicle && (
                    <BsFillPencilFill
                      className="cursor-pointer hover:bg-primary hover:text-white text-primary"
                      onClick={handleEditVehicleButton}
                    />
                  )}
                  {canEditInventory && (
                    <BsTrash
                      onClick={handleDeleteBtn}
                      className="cursor-pointer hover:bg-primary hover:text-white text-primary"
                    />
                  )}
                </div>

                {/* <Button */}
                {/* type="primary" */}
                {/* size="large" */}
                {/* className="flex gap-3 items-center font-semibold" */}
                {/* icon={<BsFillShareFill />} */}
                {/* > */}
                {/* Share */}
                {/* </Button> */}

              </div>
            </div>
            <div className="grid grid-cols-4 text-sm mt-1">
              <div className="col-span-2 text-gray-400"> 
              {editVehicle ? (<span className="text-red-500"> * </span>): ('') } VIN 
              </div>
              <div className="col-span-2">
                {!editVehicle ? (
                  vin
                ) : (
                  <div className="flex flex-col gap-2 mt-4 items-end">
                    <Form.Item
                      name="vin"
                      className="custom-antd-label mb-0 w-full"
                      rules={[
                        {
                          required: true,
                          message: `VIN is required`
                        }
                      ]}
                    >
                      <input
                        className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                        type="text"
                        onChange={handleVin}
                        value={selectedVin || vin}
                      />
                    </Form.Item>
                    <Button
                      className="rounded-md font-semibold w-1/2"
                      onClick={(event) => handleDecode(event, selectedVin)}
                      type="primary"
                      style={{ height: '37px' }}
                      danger
                    >
                      Decode
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="border-b pb-5 mb-2 mt-4">
              <div className="grid grid-cols-4 text-sm mt-1">
                <div className="col-span-2 text-gray-400">Year</div>
                <div className="col-span-2">
                  {!editVehicle ? (
                    year
                  ) : (
                    <Form.Item name="year" className="custom-antd-label">
                      <select
                        value={selectedYear}
                        name="year"
                        onChange={onYearChange}
                        className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                      >
                        {yearOptions().map((item) => (
                          <option value={item.value} key={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </Form.Item>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-4 text-sm mt-1">
                <div className="col-span-2 text-gray-400">MAKE</div>
                <div className="col-span-2">
                  {!editVehicle ? (
                    make
                  ) : (
                    <Form.Item name="make" className="custom-antd-label">
                      <select
                        value={selectedMake}
                        name="make"
                        onChange={onMakeChange}
                        className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                      >
                        {makes.map((item) => (
                          <option value={item.value} key={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </Form.Item>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-4 text-sm mt-1">
                <div className="col-span-2 text-gray-400">MODEL</div>
                <div className="col-span-2">
                  {!editVehicle ? (
                    model
                  ) : (
                    <Form.Item name="model" className="custom-antd-label">
                      <select
                        value={selectedModel}
                        name="model"
                        onChange={onModelChange}
                        className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                      >
                        {models[selectedMake]?.map((item) => (
                          <option value={item.value} key={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </Form.Item>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-4 text-sm mt-1">
                <div className="col-span-2 text-gray-400">PRICE</div>
                <div className="col-span-2">
                  {!editVehicle ? (
                    getPrice()
                  ) : (
                    <Form.Item
                      name="price"
                      className="custom-antd-label"
                      rules={[
                        {
                          required: true,
                          message: `PRICE is required`
                        }
                      ]}
                    >
                      <input
                        className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                        type="number"
                        name="price"
                        defaultValue={price}
                      />
                    </Form.Item>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-4 text-sm mt-1">
                <div className="col-span-2 text-gray-400">CERTIFIED</div>
                <div className="col-span-2">
                  {!editVehicle ? (
                    getIsCertified()
                  ) : (
                    <Form.Item name="new_used_cert_flag" className="custom-antd-label">
                      <select
                        value={newUsedCertFlag}
                        name="new_used_cert_flag"
                        onChange={onBadgeChange}
                        className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                      >
                        {newUsedCertFlagOptions.map((item) => (
                          <option value={item.value} key={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </Form.Item>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-4 text-sm mt-1">
                <div className="col-span-2 text-gray-400">BODY</div>
                <div className="col-span-2">
                  {!editVehicle ? (
                    body
                  ) : (
                    <Form.Item name="body" className="custom-antd-label">
                      <select
                        value={body}
                        name="body"
                        onChange={onBodyTypeChange}
                        className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                      >
                        {bodies.map((item) => (
                          <option value={item.value} key={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </Form.Item>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-4 text-sm mt-1">
                <div className="col-span-2 text-gray-400">
                  {editVehicle ? (<span className="text-red-500"> * </span>): ('') }STYLE
                </div>
                <div className="col-span-2">
                  {!editVehicle ? (
                    style
                  ) : (
                    <Form.Item
                      name="style"
                      className="custom-antd-label"
                      rules={[
                        {
                          required: true,
                          message: `STYLE is required`
                        }
                      ]}
                    >
                      <input
                        value={style}
                        name="style"
                        className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                        type="text"
                      />
                    </Form.Item>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-4 text-sm mt-1">
                <div className="col-span-2 text-gray-400">MILEAGE</div>
                <div className="col-span-2">
                  {!editVehicle ? (
                    miles
                  ) : (
                    <Form.Item
                      name="miles"
                      className="custom-antd-label"
                      rules={[
                        {
                          required: true,
                          message: `MILEAGE is required`
                        }
                      ]}
                    >
                      <input
                        value={miles}
                        name="miles"
                        className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                        type="number"
                      />
                    </Form.Item>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-4 text-sm mt-1">
                <div className="col-span-2 text-gray-400">
                  {editVehicle ? (<span className="text-red-500"> * </span>): ('') }ENGINE
                </div>
                <div className="col-span-2">
                  {!editVehicle ? (
                    engineDescription
                  ) : (
                    <Form.Item
                      name="engine_description"
                      className="custom-antd-label"
                      rules={[
                        {
                          required: true,
                          message: `ENGINE is required`
                        }
                      ]}
                    >
                      <input
                        name="engine_description"
                        className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                        type="text"
                      />
                    </Form.Item>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-4 text-sm mt-1">
                <div className="col-span-2 text-gray-400">TRANSMISSION</div>
                <div className="col-span-2">
                  {!editVehicle ? (
                    getTransmissionType()
                  ) : (
                    <Form.Item name="transmission_type" className="custom-antd-label">
                      <select
                        value={transmissionType}
                        name="transmission_type"
                        onChange={onTransmissionChange}
                        className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                      >
                        {transmissions.map((item) => (
                          <option value={item.value} key={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </Form.Item>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-4 text-sm mt-1">
                <div className="col-span-2 text-gray-400">EXTERNAL COLOR</div>
                <div className="col-span-2">
                  {!editVehicle ? (
                    exteriorColor
                  ) : (
                    <Form.Item name="exterior_color_type" className="custom-antd-label">
                      <select
                        value={exteriorColor}
                        name="exterior_color_type"
                        onChange={onColorChange}
                        className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                      >
                        {colors.map((item) => (
                          <option value={item.value} key={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </Form.Item>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-4 text-sm mt-1">
                <div className="col-span-2 text-gray-400">EXTERNAL COLOR LABEL</div>
                <div className="col-span-2">
                  {!editVehicle ? (
                    exteriorColorLabel
                  ) : (
                    <Form.Item
                      name="exterior_color"
                      className="custom-antd-label"
                      rules={[
                        {
                          required: true,
                          message: `EXTERNAL COLOR LABEL is required`
                        }
                      ]}
                    >
                      <input
                        className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                        type="text"
                      />
                    </Form.Item>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-4 text-sm mt-1">
                <div className="col-span-2 text-gray-400">
                  {editVehicle ? (<span className="text-red-500"> * </span>): ('') }INTERIOR COLOR
                </div>
                <div className="col-span-2">
                  {!editVehicle ? (
                    interiorColor
                  ) : (
                    <Form.Item
                      name="interior_color"
                      className="custom-antd-label"
                      rules={[
                        {
                          required: true,
                          message: `INTERIOR COLOR is required`
                        }
                      ]}
                    >
                      <input
                        className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                        type="text"
                      />
                    </Form.Item>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-4 text-sm mt-1">
                <div className="col-span-2 text-gray-400">STOCK#</div>
                <div className="col-span-2">
                  {!editVehicle ? (
                    stockNumber
                  ) : (
                    <Form.Item
                      name="stock_number"
                      className="custom-antd-label"
                      rules={[
                        {
                          required: false,
                          message: `STOCK# is required`
                        }
                      ]}
                    >
                      <input
                        className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                        type="text"
                      />
                    </Form.Item>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-4 text-sm mt-1">
                <div className="col-span-2 text-gray-400">
                  {editVehicle ? (<span className="text-red-500"> * </span>): ('') }FUEL TYPE
                </div>
                <div className="col-span-2">
                  {!editVehicle ? (
                    fuel
                  ) : (
                    <Form.Item
                      name="fuel"
                      className="custom-antd-label"
                      rules={[
                        {
                          required: true,
                          message: `FUEL TYPE is required`
                        }
                      ]}
                    >
                      <input
                        className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                        type="text"
                      />
                    </Form.Item>
                  )}
                </div>
              </div>
            </div>
            <div className="pb-5 mb-2 mt-4">
              <p className="text-base text-gray-400 uppercase">Optional equipments</p>
              {editVehicle ? (
                <Select
                  name="option"
                  placeholder="Options"
                  onChange={handleAddOption}
                  options={options}
                  className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 border bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                />
              ) : null}
              {!editVehicle &&
                selectedOptions.length > 0 &&
                selectedOptions.map((opt, idx) => {
                  return (
                    <div className="grid grid-cols-12 text-sm mt-1" key={idx}>
                      <div className="col-span-12">{opt.label}</div>
                    </div>
                  )
                })}
              {editVehicle &&
                selectedOptions.map((opt) => {
                  return (
                    <div className="grid grid-cols-12 text-sm mt-1">
                      <div className="col-span-1">
                        <Button
                          icon={<AiOutlineClose />}
                          className="flex gap-2 font-semibold text-lg items-center rounded-md text-gray-400"
                          size="small"
                          type="button"
                          onClick={() => handleRemoveOption(opt)}
                        />
                      </div>
                      <div className="col-span-11 text-gray-600">{opt.label}</div>
                    </div>
                  )
                })}
            </div>
            {editVehicle ? (
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    icon={<AiFillSave />}
                    className="flex gap-2 font-semibold text-lg items-center rounded-md"
                    size="large"
                    type="primary"
                    htmlType="submit"
                  >
                    SAVE CHANGES
                  </Button>
                )}
              </Form.Item>
            ) : null}
          </Form>
        </div>
      </div>
      <Modal
        centered
        title="Delete vehicle"
        visible={showModal}
        onCancel={handleChangeShowModal}
        closable={false}
        okText="Close"
        className="w-full"
        destroyOnClose
        footer={[
          <div className="flex justify-center">
            <Button
              type="button"
              className="bg-primary hover:bg-activePrimary hover:text-white font-semibold rounded-lg outline-none text-lg w-40 h-full text-white justify-center uppercase"
              onClick={handleConfirmDelete}
            >
              CONFIRM
            </Button>
            <Button
              type="button"
              className="hover:bg-activePrimary font-semibold rounded-lg outline-none text-lg w-40 h-full text-black hover:text-white justify-center uppercase"
              onClick={handleChangeShowModal}
            >
              CANCEL
            </Button>
          </div>
        ]}
      >
        <div className="px-5 lg:px-10 pb-2 flex gap-3 lg:gap-5 justify-center rounded-3xl">
          <div className="font-semibold text-lg text-center">
            Are you sure you want to delete this vehicle unit from your inventory?
          </div>
        </div>
      </Modal>
      <Modal
        centered
        visible={infoModal}
        onCancel={handleCloseInfoModal}
        closable={false}
        okText="Close"
        className="w-full"
        destroyOnClose
        footer={[
          <div className="flex justify-center">
            <Button
              type="button"
              className="hover:bg-activePrimary font-semibold rounded-lg outline-none text-lg w-40 h-full text-black hover:text-white justify-center uppercase"
              onClick={handleCloseInfoModal}
            >
              CLOSE
            </Button>
          </div>
        ]}
      >
        <div className="px-5 lg:px-10 pt-5 pb-3 flex gap-3 lg:gap-5 justify-center rounded-3xl">
          <div className="font-semibold text-xl text-center">
            {deleteVehicleDetail.year} {deleteVehicleDetail.make} {deleteVehicleDetail.model} deleted successfully!
          </div>
        </div>
      </Modal>
    </div>
  )
}

VehicleDetail.propTypes = {
  info: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  id: PropTypes.string,
  setCarDetail: PropTypes.func,
  similarVehicles: PropTypes.array,
  createNewVehicle: PropTypes.bool,
  carOptions: PropTypes.array
}

export default VehicleDetail
