export const pricingTableKeys = {
  average_vehicles_stock: 'average_vehicles_stock',
  member_setUp: 'member_setUp',
  member_2_month: 'member_2_month',
  member_12_month: 'member_12_month',
  member_24_month: 'member_24_month',
  member_36_month: 'member_36_month',
  none_member_setUp: 'none_member_setUp',
  none_member_2_month: 'none_member_2_month',
  none_member_12_month: 'none_member_12_month',
  none_member_24_month: 'none_member_24_month',
  none_member_36_month: 'none_member_36_month',
  // advanced table column keys
  member_app_month_to_month: 'member_app_month_to_month',
  member_per_month_to_month: 'member_per_month_to_month',
  none_member_app_month_to_month: 'none_member_app_month_to_month',
  none_member_per_month_to_month: 'none_member_per_month_to_month'
}

export const TABLE_TYPES = {
  LARGE_TABLE: 'LARGE_TABLE',
  SMALL_TABLE: 'SMALL_TABLE',
  ADVANCED_TABLE: 'ADVANED_TABLE'
}

export const TAB_KEYS = {
  BASIC_INVENTORY_LISTING_TABLE: {
    id: 'BASIC_INVENTORY_LISTING_TABLE',
    name: 'Basic Inventory Listing',
    tableType: TABLE_TYPES.LARGE_TABLE,
    pricingTable: 'basic'
  },
  ILM_PRO_TABLE: {
    id: 'ILM_PRO_TABLE',
    name: 'ILM Pro Table',
    tableType: TABLE_TYPES.LARGE_TABLE,
    pricingTable: 'ILM'
  },
  THIRD_PARTY_LEAD_INTEGRATION: {
    id: 'THIRD_PARTY_LEAD_INTEGRATION',
    name: '3rd Party Lead Integration',
    tableType: TABLE_TYPES.SMALL_TABLE,
    pricingTable: '3rd Party Lead Integration'
  },
  SMS_PACKAGE: {
    id: 'SMS_PACKAGE',
    name: 'SMS Package',
    tableType: TABLE_TYPES.SMALL_TABLE,
    pricingTable: 'SMS Package'
  },
  CALL_PACKAGE: {
    id: 'CALL_PACKAGE',
    name: 'Call Package',
    tableType: TABLE_TYPES.SMALL_TABLE,
    pricingTable: 'Call Package'
  },
  MARKETING_AUTOMATION: {
    id: 'MARKETING_AUTOMATION',
    name: 'Marketing Automation',
    tableType: TABLE_TYPES.LARGE_TABLE,
    pricingTable: 'Marketing Automation'
  },
  MMUSA_CUSTOM_STORE: {
    id: 'MMUSA_CUSTOM_STORE',
    name: 'MMUSA Custom Store',
    tableType: TABLE_TYPES.SMALL_TABLE,
    pricingTable: 'MMUSA Custom Store'
  },
  INTEGRATED_CUSTOM_WEB_SITE: {
    id: 'INTEGRATED_CUSTOM_WEB_SITE',
    name: 'Integrated Custom Web Site',
    tableType: TABLE_TYPES.SMALL_TABLE,
    pricingTable: 'Integrated Custom Web Site'
  },
  FIMYRIDE: {
    id: 'FIMYRIDE',
    name: 'FiMyRide',
    tableType: TABLE_TYPES.ADVANCED_TABLE,
    pricingTable: 'FiMyRide'
  }
}

export const largeTableColumns = (title = 'Basic Inventory Listing') => [
  {
    title,
    dataIndex: 'average_vehicles_stock',
    key: pricingTableKeys.average_vehicles_stock,
    width: 150
  },
  {
    title: 'NIADA Member Set Up',
    dataIndex: ['pricing_table', 'member', 'setUp'],
    key: pricingTableKeys.member_setUp,
    width: 150,
    render: (value) => (value && typeof value === 'string' ? value : `$${value}`)
  },
  {
    title: 'NIADA Member month 2 month',
    dataIndex: ['pricing_table', 'member', 'month to Month'],
    key: pricingTableKeys.member_2_month,
    width: 150,
    render: (value) => (value && typeof value === 'string' ? value : `$${value}`)
  },
  {
    title: 'NIADA Member 12 month',
    dataIndex: ['pricing_table', 'member', '12'],
    key: pricingTableKeys.member_12_month,
    width: 150,
    render: (value) => (value && typeof value === 'string' ? value : `$${value}`)
  },
  {
    title: 'NIADA Member 24 month',
    dataIndex: ['pricing_table', 'member', '24'],
    key: pricingTableKeys.member_24_month,
    width: 150,
    render: (value) => (value && typeof value === 'string' ? value : `$${value}`)
  },
  {
    title: 'NIADA Member 36 month',
    dataIndex: ['pricing_table', 'member', '36'],
    key: pricingTableKeys.member_36_month,
    width: 150,
    render: (value) => (value && typeof value === 'string' ? value : `$${value}`)
  },
  {
    title: 'NON NIADA Member Set Up',
    dataIndex: ['pricing_table', 'none', 'setUp'],
    key: pricingTableKeys.none_member_setUp,
    render: (value) => (value && typeof value === 'string' ? value : `$${value}`)
  },
  {
    title: 'NON NIADA Member month 2 month',
    dataIndex: ['pricing_table', 'none', 'month to Month'],
    key: pricingTableKeys.none_member_2_month,
    width: 150,
    render: (value) => (value && typeof value === 'string' ? value : `$${value}`)
  },
  {
    title: 'NON NIADA Member 12 month',
    dataIndex: ['pricing_table', 'none', '12'],
    key: pricingTableKeys.none_member_12_month,
    width: 150,
    render: (value) => (value && typeof value === 'string' ? value : `$${value}`)
  },
  {
    title: 'NON NIADA Member 24 month',
    dataIndex: ['pricing_table', 'none', '24'],
    key: pricingTableKeys.none_member_24_month,
    width: 150,
    render: (value) => (value && typeof value === 'string' ? value : `$${value}`)
  },
  {
    title: 'NON NIADA Member 36 month',
    dataIndex: ['pricing_table', 'none', '36'],
    key: pricingTableKeys.none_member_36_month,
    width: 150,
    render: (value) => (value && typeof value === 'string' ? value : `$${value}`)
  }
]

export const smallTableColumns = (title = '3rd Party Lead Integration') => [
  {
    title,
    dataIndex: 'average_vehicles_stock',
    key: pricingTableKeys.average_vehicles_stock
  },
  // {
  //   title: 'NIADA Member Set Up',
  //   dataIndex: ['pricing_table', 'member', 'setUp'],
  //   key: pricingTableKeys.member_setUp,
  //   width: 150
  // },
  {
    title: 'NIADA Member',
    dataIndex: ['pricing_table', 'member', 'month to Month'],
    key: pricingTableKeys.member_2_month,
    render: (value) => (value && typeof value === 'string' ? value : `$${value}`)
  },
  // {
  //   title: 'Non NIADA Member Set Up',
  //   dataIndex: ['pricing_table', 'none', 'setUp'],
  //   key: pricingTableKeys.none_member_setUp,
  //   width: 150
  // },
  {
    title: 'Non NIADA Member',
    dataIndex: ['pricing_table', 'none', 'month to Month'],
    key: pricingTableKeys.none_member_2_month,
    render: (value) => (value && typeof value === 'string' ? value : `$${value}`)
  }
]

export const advancedTableColumns = [
  {
    title: 'FiMyRide',
    dataIndex: 'average_vehicles_stock',
    key: pricingTableKeys.average_vehicles_stock,
    width: '20%'
  },
  // {
  //   title: 'NIADA Member Set Up',
  //   dataIndex: ['pricing_table', 'member', 'setUp'],
  //   key: pricingTableKeys.member_setUp,
  //   width: 150
  // },
  {
    title: 'NIADA Member App & Management',
    dataIndex: ['pricing_table', 'member', 'App month to Month'],
    key: pricingTableKeys.member_app_month_to_month,
    width: '20%',
    render: (value) => (value && typeof value === 'string' ? value : `$${value}`)
  },
  {
    title: 'NIADA Member Per Chosen Integrated Lender',
    dataIndex: ['pricing_table', 'member', 'Per month to Month'],
    key: pricingTableKeys.member_per_month_to_month,
    width: '20%',
    render: (value) => (value && typeof value === 'string' ? value : `$${value}`)
  },
  // {
  //   title: 'Non NIADA Member Set Up',
  //   dataIndex: ['pricing_table', 'none', 'setUp'],
  //   key: pricingTableKeys.none_member_setUp,
  //   width: 150
  // },
  {
    title: 'Non NIADA Member App & Management',
    dataIndex: ['pricing_table', 'none', 'App month to Month'],
    key: pricingTableKeys.none_member_app_month_to_month,
    width: '20%',
    render: (value) => (value && typeof value === 'string' ? value : `$${value}`)
  },
  {
    title: 'Non NIADA Member Per Chosen Integrated Lender',
    dataIndex: ['pricing_table', 'none', 'Per month to Month'],
    key: pricingTableKeys.none_member_per_month_to_month,
    width: '20%',
    render: (value) => (value && typeof value === 'string' ? value : `$${value}`)
  }
]

export const pricingFields = ({ tableType = TABLE_TYPES.LARGE_TABLE, averageLabel = 'Basic Inventory Listing' }) => {
  const largeTableVisible = tableType === TABLE_TYPES.LARGE_TABLE
  const smallTableVisible = tableType === TABLE_TYPES.SMALL_TABLE
  const advancedTableVisible = tableType === TABLE_TYPES.ADVANCED_TABLE
  return [
    {
      label: averageLabel,
      name: pricingTableKeys.average_vehicles_stock,
      required: true,
      type: 'text',
      disabled: true,
      visible: true
    },
    {
      label: 'NIADA Member Set Up',
      name: pricingTableKeys.member_setUp,
      required: true,
      type: 'number',
      visible: largeTableVisible
    },
    {
      label: 'NIADA Member month 2 month',
      name: pricingTableKeys.member_2_month,
      required: true,
      type: 'number',
      visible: largeTableVisible || smallTableVisible
    },
    {
      label: 'NIADA Member 12 month',
      name: pricingTableKeys.member_12_month,
      required: true,
      type: 'number',
      visible: largeTableVisible
    },
    {
      label: 'NIADA Member 24 month',
      name: pricingTableKeys.member_24_month,
      required: true,
      type: 'number',
      visible: largeTableVisible
    },
    {
      label: 'NIADA Member 36 month',
      name: pricingTableKeys.member_36_month,
      required: true,
      type: 'number',
      visible: largeTableVisible
    },
    {
      label: 'NON NIADA Member Set Up',
      name: pricingTableKeys.none_member_setUp,
      required: true,
      type: 'number',
      visible: largeTableVisible
    },
    {
      label: 'NON NIADA Member month 2 month',
      name: pricingTableKeys.none_member_2_month,
      required: true,
      type: 'number',
      visible: largeTableVisible || smallTableVisible
    },
    {
      label: 'NON NIADA Member 12 month',
      name: pricingTableKeys.none_member_12_month,
      required: true,
      type: 'number',
      visible: largeTableVisible
    },
    {
      label: 'NON NIADA Member 24 month',
      name: pricingTableKeys.none_member_24_month,
      required: true,
      type: 'number',
      visible: largeTableVisible
    },
    {
      label: 'NON NIADA Member 36 month',
      name: pricingTableKeys.none_member_36_month,
      required: true,
      type: 'number',
      visible: largeTableVisible
    },
    // advanced table fields
    {
      label: 'NIADA Member App & Management',
      name: pricingTableKeys.member_app_month_to_month,
      required: true,
      type: 'number',
      visible: advancedTableVisible
    },
    {
      label: 'NIADA Member Per Chosen Integrated Lender',
      name: pricingTableKeys.member_per_month_to_month,
      required: true,
      type: 'number',
      visible: advancedTableVisible
    },
    {
      label: 'Non NIADA Member App & Management',
      name: pricingTableKeys.none_member_app_month_to_month,
      required: true,
      type: 'number',
      visible: advancedTableVisible
    },
    {
      label: 'Non NIADA Member Per Chosen Integrated Lender',
      name: pricingTableKeys.none_member_per_month_to_month,
      required: true,
      type: 'number',
      visible: advancedTableVisible
    }
  ]
}
