import { Button, Modal, notification } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
import { BsFillPencilFill, BsTrash } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import StockComponent from 'src/components/StockComponent'
import { STORAGE_KEYS } from 'src/constants/constants'
import { save } from 'src/lib/storage'
import carImage from '../../../assets/placeholder_car.jpg'
import * as adminService from '../../../services/admin.service'
import * as vehicleService from '../../../services/vehicle.service'
import ExteriorColorFoundComponent from '../../ExteriorColorFoundComponent'
import { partialUpdateVehicleAdapter } from './adapters'
import './style.scss'

function InventoryVehicleCard({
  vehicle,
  favouriteCars,
  setFavouriteCars,
  canEditInventory,
  page,
  perPage,
  handleRefetchVehicle,
  ...rest
}) {
  const notAvailable = 'Not available'
  const navigate = useNavigate()
  const [car, setCar] = useState(vehicle)
  const [edit, setEdit] = useState(false)
  const [editId, setEditId] = useState()
  const [image, setImage] = useState(carImage)
  const [showModal, setShowModal] = useState(false)
  const [infoModal, setInfoModal] = useState(false)

  useEffect(() => {
    if (car.photos?.length > 0 && car.photos[0]) {
      setImage(car.photos[0])
    }
  }, [car.photos])

  const handleEditButton = (carId) => {
    if (!carId) {
      return false
    }

    setEdit(true)
    setEditId(carId)
  }

  const handleFavoriteButton = (carId) => {
    const isFavouriteCar = favouriteCars.filter((item) => item === carId)

    if (isFavouriteCar.length > 0) {
      const newFavouriteVehicles = favouriteCars.filter((item) => item !== carId)
      setFavouriteCars(newFavouriteVehicles)
      save(STORAGE_KEYS.FAVOURITE_CARS, JSON.stringify(newFavouriteVehicles))
    } else {
      setFavouriteCars([carId, ...favouriteCars])
      save(STORAGE_KEYS.FAVOURITE_CARS, JSON.stringify([carId, ...favouriteCars]))
    }
  }

  const handleSaveEvent = async (e) => {
    e.preventDefault()

    const formData = new FormData(e.target)

    await vehicleService
      .partialUpdateVehicle(editId, partialUpdateVehicleAdapter(formData))
      .then((response) => {
        setEdit(false)
        setCar(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleCancelButton = () => {
    setEdit(false)
    setEditId(false)
  }

  const handleChangeShowModal = () => {
    setShowModal(!showModal)
  }

  const handleCloseInfoModal = () => {
    setInfoModal(!infoModal)
    handleRefetchVehicle()
  }

  const [deleteCarId, setDeleteCarId] = useState({ id: '' })

  const handleDeleteBtn = (id) => {
    setShowModal(true)
    setDeleteCarId({ id })
  }

  const [deleteVehicleDetail, setDeleteVehicleDetail] = useState({
    year: '',
    make: '',
    model: ''
  })

  const openNotification = ({ message, description, type = 'success' }) => {
    const action = type === 'success' ? notification.success : notification.error
    action({
      message,
      description
    })
  }

  const handleConfirmDelete = (id) => {
    adminService
      .deleteMyInventoryVehicle(id)
      .then((response) => {
        if (response.isSuccessful === 'Yes') {
          setShowModal(false)
          setDeleteVehicleDetail({
            year: response.data.year,
            make: response.data.make,
            model: response.data.model
          })
          setInfoModal(true)
        }
      })
      .catch((e) => {
        openNotification({ message: e.message, description: 'The vehicle is not deleted', type: 'error' })
        setShowModal(false)
      })
  }

  const handleRedirectVehicleDetail = () => {
    save(STORAGE_KEYS.INVENTORY_PAGINATION, JSON.stringify({ page, perPage }))
    navigate(`/dealer-vehicles/${car._id}`)
  }

  const isHideOnProduction = process.env.REACT_APP_HIDE_ON_PRODUCTION === 'true'

  return (
    <div>
      <div className="border border-gray-200 rounded relative flex flex-col justify-between h-full" {...rest}>
        <div className="p-3 vehicle-card-entry">
          <StockComponent vehicle={car} />
          <ExteriorColorFoundComponent vehicle={car} />
          <div className="vehicle-card-thumbnail">
            <div className="hover:underline" onClick={handleRedirectVehicleDetail}>
              <img src={image} alt={car?.make} className="w-full" />
            </div>
          </div>
          <form autoComplete="off" onSubmit={handleSaveEvent}>
            <div className="vehicle-card-body">
              <div
                onClick={handleRedirectVehicleDetail}
                className="text-gray-800 text-xl font-semibold mb-2 hover:underline cursor-pointer hover:text-primary"
              >
                {`${car.year} ${car.make} ${car.model}`}
              </div>
              <div className={`grid grid-cols-7 ${edit ? 'mt-2' : ''}`}>
                <p className="text-gray-600 col-span-3">Body</p>
                <p className="text-gray-800 col-span-4">
                  {!edit ? (
                    car.body || notAvailable
                  ) : (
                    <input
                      className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                      type="text"
                      name="body_type"
                      defaultValue={car.body || notAvailable}
                    />
                  )}
                </p>
              </div>
              <div className={`grid grid-cols-7 ${edit ? 'mt-2' : ''}`}>
                <p className="text-gray-600 col-span-3">Mileage</p>
                <p className="text-gray-800 col-span-4">
                  {!edit ? (
                    car.miles || 0
                  ) : (
                    <input
                      className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                      type="text"
                      name="miles"
                      defaultValue={car.miles || notAvailable}
                    />
                  )}
                </p>
              </div>
              <div className={`grid grid-cols-7 ${edit ? 'mt-2' : ''}`}>
                <p className="text-gray-600 col-span-3">Engine</p>
                <p className="text-gray-800 col-span-4">
                  {!edit ? (
                    car.engine_name || notAvailable
                  ) : (
                    <input
                      className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                      type="text"
                      name="engine_description"
                      defaultValue={car.engine_name || notAvailable}
                    />
                  )}
                </p>
              </div>
              <div className={`grid grid-cols-7 mb-4 ${edit ? 'mt-2' : ''}`}>
                <p className="text-gray-600 col-span-3">Transmission</p>
                <p className="text-gray-800 col-span-4">
                  {!edit ? (
                    car.transmission_name || notAvailable
                  ) : (
                    <input
                      className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                      type="text"
                      name="transmission_description"
                      defaultValue={car.transmission_name || notAvailable}
                    />
                  )}
                </p>
              </div>
            </div>
            <div className={`border-t pt-2 mb-2 ${edit ? 'mt-2 flex flex-col gap-2' : 'grid grid-cols-7'}`}>
              <div className={edit ? 'w-full' : 'col-span-3'}>
                <p>{edit ? '$ ' : ''}Save price</p>
                {edit ? (
                  <input
                    className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                    type="text"
                    name="price_current"
                    defaultValue={car.price || 0}
                  />
                ) : (
                  <p className="text-lg font-semibold">${car.price?.toLocaleString() || 0}</p>
                )}
              </div>
              <div className={edit ? '' : 'col-span-4'}>
                <p>{edit ? '$ ' : ''}Discount price</p>
                <div className="flex gap-2 items-center">
                  {edit ? (
                    <input
                      className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                      type="text"
                      name="price_current"
                      defaultValue={car.price || 0}
                    />
                  ) : (
                    <p className="text-lg font-semibold discount-price-text">${car.price?.toLocaleString() || 0}</p>
                  )}
                  {/* <p className="text-sm discount-price-text">(Saving: $5,500)</p> */}
                </div>
              </div>
            </div>
            <div className={`${edit ? 'flex flex-col gap-2 mt-2' : 'grid grid-cols-7'}`}>
              <p className="col-span-3">{edit ? '$ ' : ''}Availability</p>
              <div className={edit ? 'flex flex-col gap-2' : 'w-full col-span-3'}>
                {!edit ? (
                  <p>{car.disclaimer || notAvailable}</p>
                ) : (
                  <input
                    className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-30"
                    type="text"
                    name="disclaimer"
                    defaultValue={car.disclaimer || notAvailable}
                  />
                )}
                <p>{edit ? '$ ' : ''}Additional fees</p>
                {!edit ? (
                  <p className="text-sm font-medium w-full">{car.disclaimer2 || 'Additional fees apply*'}</p>
                ) : (
                  <input
                    className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-30"
                    type="text"
                    name="disclaimer2"
                    defaultValue={car.disclaimer2 || 'Additional fees apply*'}
                  />
                )}
              </div>
            </div>
            <div className="flex gap-2 text-xl items-end justify-end mt-3">
              {!edit ? (
                <>
                  {favouriteCars.filter((item) => item === car._id).length > 0
                    ? !isHideOnProduction && (
                        <AiFillHeart
                          className="cursor-pointer hover:bg-primary hover:text-white text-primary"
                          onClick={() => handleFavoriteButton(car._id)}
                        />
                      )
                    : !isHideOnProduction && (
                        <AiOutlineHeart
                          className="cursor-pointer hover:bg-primary hover:text-white text-primary"
                          onClick={() => handleFavoriteButton(car._id)}
                        />
                      )}
                  {canEditInventory && (
                    <BsFillPencilFill
                      className="cursor-pointer hover:bg-primary hover:text-white text-primary"
                      onClick={() => handleEditButton(car._id)}
                    />
                  )}
                  {canEditInventory && process.env.REACT_APP_ENV !== 'production' && (
                    <BsTrash
                      className="cursor-pointer hover:bg-primary hover:text-white text-primary"
                      onClick={() => handleDeleteBtn(car._id)}
                    />
                  )}
                </>
              ) : null}
              {edit ? (
                <>
                  <button
                    type="button"
                    className="hover:bg-activePrimary font-semibold rounded-lg outline-none text-lg w-40 h-full text-black hover:text-white justify-center uppercase"
                    onClick={handleCancelButton}
                  >
                    CANCEL
                  </button>
                  <button
                    type="submit"
                    className="bg-primary hover:bg-activePrimary font-semibold rounded-lg outline-none text-lg w-40 h-full text-white justify-center uppercase"
                  >
                    SAVE
                  </button>
                </>
              ) : null}
            </div>
          </form>
        </div>
      </div>

      <Modal
        centered
        title="Delete vehicle"
        visible={showModal}
        onCancel={handleChangeShowModal}
        closable={false}
        okText="Close"
        className="w-full"
        destroyOnClose
        footer={[
          <div className="flex justify-center">
            <Button
              type="button"
              className="bg-primary hover:bg-activePrimary hover:text-white font-semibold rounded-lg outline-none text-lg w-40 h-full text-white justify-center uppercase"
              onClick={() => handleConfirmDelete(deleteCarId.id)}
            >
              CONFIRM
            </Button>
            <Button
              type="button"
              className="hover:bg-activePrimary font-semibold rounded-lg outline-none text-lg w-40 h-full text-black hover:text-white justify-center uppercase"
              onClick={handleChangeShowModal}
            >
              CANCEL
            </Button>
          </div>
        ]}
      >
        <div className="px-5 lg:px-10 pb-2 flex gap-3 lg:gap-5 justify-center rounded-3xl">
          <div className="font-semibold text-lg text-center">
            Are you sure you want to delete this vehicle unit from your inventory?
          </div>
        </div>
      </Modal>

      <Modal
        centered
        visible={infoModal}
        onCancel={handleCloseInfoModal}
        closable={false}
        okText="Close"
        className="w-full"
        destroyOnClose
        footer={[
          <div className="flex justify-center">
            <Button
              type="button"
              className="hover:bg-activePrimary font-semibold rounded-lg outline-none text-lg w-40 h-full text-black hover:text-white justify-center uppercase"
              onClick={handleCloseInfoModal}
            >
              CLOSE
            </Button>
          </div>
        ]}
      >
        <div className="px-5 lg:px-10 pt-5 pb-3 flex gap-3 lg:gap-5 justify-center rounded-3xl">
          <div className="font-semibold text-xl text-center">
            {deleteVehicleDetail.year} {deleteVehicleDetail.make} {deleteVehicleDetail.model} deleted successfully!
          </div>
        </div>
      </Modal>
    </div>
  )
}

InventoryVehicleCard.propTypes = {
  favouriteCars: PropTypes.shape([PropTypes.string]).isRequired,
  setFavouriteCars: PropTypes.func.isRequired,
  canEditInventory: PropTypes.bool.isRequired,
  page: PropTypes.number,
  perPage: PropTypes.number,
  handleRefetchVehicle: PropTypes.func,
  vehicle: PropTypes.shape({
    _id: PropTypes.string,
    dealer_id: PropTypes.number,
    vehicle: PropTypes.shape({
      new_used_cert_flag: PropTypes.string,
      vin: PropTypes.string,
      stock_number: PropTypes.string,
      year: PropTypes.string,
      make: PropTypes.string,
      model: PropTypes.string,
      trim_description: PropTypes.string,
      body_type: PropTypes.string,
      exterior_color: PropTypes.string,
      interior_color: PropTypes.string,
      engine_description: PropTypes.string,
      transmission_description: PropTypes.string,
      transmission_type: PropTypes.string,
      miles: PropTypes.string,
      doors: PropTypes.string,
      style: PropTypes.string,
      photos: PropTypes.arrayOf(PropTypes.string),
      price: PropTypes.shape({
        current: PropTypes.string,
        changes: PropTypes.string,
        highest: PropTypes.string,
        lowest: PropTypes.string
      }),
      disclaimer: PropTypes.string,
      disclaimer2: PropTypes.string
    })
  }).isRequired
}

export default InventoryVehicleCard
