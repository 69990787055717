import * as PropTypes from 'prop-types'
import React, { createContext, useContext, useMemo, useState } from 'react'
import { STORAGE_KEYS } from './constants/constants'
import { load } from './lib/storage'

const AuthorizationContext = createContext({})

export function AuthorizationProvider({ children }) {
  const [favouriteVehicles, setFavouriteVehicles] = useState([])
  const [favouriteDealers, setFavouriteDealers] = useState([])
  const [userLocation, setUserLocation] = useState({})

  const [customerAccount, setCustomerAccount] = useState({})
  const [dealershipAccount, setDealershipAccount] = useState({})
  const [adminAccount, setAdminAccount] = useState({})
  const [userAccount, setUserAccount] = useState({})
  const [accountSettings, setAccountSettings] = useState({})

  const [loginObject, setLoginObject] = useState({
    customerLogin: load(STORAGE_KEYS.CUSTOMER_LOGIN, false) === 'true',
    dealerLogin: load(STORAGE_KEYS.DEALER_LOGIN, false) === 'true',
    adminLogin: load(STORAGE_KEYS.ADMIN_LOGIN, false) === 'true',
    userLogin: load(STORAGE_KEYS.USER_LOGIN, false) === 'true'
  })
  const settings = load(STORAGE_KEYS.USER_SETTINGS)

  const { customerLogin, dealerLogin, adminLogin, userLogin } = loginObject

  const handleCustomerAccountLogin = (value) => {
    setLoginObject({
      ...loginObject,
      customerLogin: value
    })
  }

  const handleDealerAccountLogin = (value) => {
    setLoginObject({
      ...loginObject,
      dealerLogin: value
    })
  }

  const handleAdminAccountLogin = (value) => {
    setLoginObject({
      ...loginObject,
      adminLogin: value
    })
  }

  const handleUserAccountLogin = (value) => {
    setLoginObject({
      ...loginObject,
      userLogin: value
    })
  }

  const handleAccountSettingsLogin = (value) => {
    setAccountSettings({
      ...settings,
      settings: value
    })
  }

  const authorizationState = useMemo(
    () => ({
      userLocation,
      setUserLocation,
      favouriteVehicles,
      setFavouriteVehicles,
      favouriteDealers,
      setFavouriteDealers,
      customerLogin,
      dealerLogin,
      adminLogin,
      userLogin,
      settings,
      handleCustomerAccountLogin,
      handleDealerAccountLogin,
      handleAdminAccountLogin,
      handleUserAccountLogin,
      handleAccountSettingsLogin,
      customerAccount,
      setCustomerAccount,
      dealershipAccount,
      setDealershipAccount,
      adminAccount,
      setAdminAccount,
      userAccount,
      setUserAccount,
      accountSettings,
      setAccountSettings
    }),
    [
      userLocation,
      setUserLocation,
      favouriteVehicles,
      setFavouriteVehicles,
      favouriteDealers,
      setFavouriteDealers,
      customerLogin,
      dealerLogin,
      adminLogin,
      userLogin,
      settings,
      handleCustomerAccountLogin,
      handleDealerAccountLogin,
      handleAdminAccountLogin,
      handleUserAccountLogin,
      handleAccountSettingsLogin,
      customerAccount,
      setCustomerAccount,
      dealershipAccount,
      setDealershipAccount,
      adminAccount,
      setAdminAccount,
      userAccount,
      setUserAccount,
      accountSettings,
      setAccountSettings
    ]
  )
  return <AuthorizationContext.Provider value={authorizationState}>{children}</AuthorizationContext.Provider>
}

AuthorizationProvider.propTypes = {
  children: PropTypes.node
}

export function useAuthorizationContext() {
  return useContext(AuthorizationContext)
}
