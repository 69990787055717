import { notification } from 'antd'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import * as CustomerServices from 'src/services/customer.service'
import * as Yup from 'yup'

function FinishDealStepFinal({ resetStep, offerId, vehicleId, setStep, setFinishYourDealStepStatus }) {
  const { customerAccount, customerLogin } = useAuthorizationContext()
  const [customerDetail, setCustomerDetail] = useState({})

  useEffect(() => {
    if (customerLogin && customerAccount.id) {
      setCustomerDetail({
        firstName: customerAccount?.first_name,
        lastName: customerAccount?.last_name,
        email: customerAccount?.email,
        phone: customerAccount?.phone
      })
    }
  }, [customerLogin, customerAccount])

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Please enter a valid first name.'),
    lastName: Yup.string().required('Please enter a valid last name.'),
    email: Yup.string().email('Please enter a valid email address').required('Please enter a email address')
  })

  const handleSubmit = (values) => {
    CustomerServices.updateOffer(offerId, {
      finish_your_deal_comments: values?.comments || '',
      finish_your_deal_step: 2,
      vehicle_id: vehicleId
    })
      .then((response) => {
        notification.success({
          message: 'Success!',
          description: 'Successfully submitted final deal.'
        })
        setStep(-1)
        setFinishYourDealStepStatus(response?.data?.finish_your_deal_step_status)
      })
      .catch((error) => {
        notification.error({
          message: 'Error!',
          description: 'Failed to submit final deal'
        })
        console.error(error)
      })
  }

  return (
    <div className="rounded-xl p-6 bg-white shadow">
      <p className="text-2xl text-black font-bold">You&apos;ve Reached the Finish Line</p>
      <p className="text-gray-400 text-sm mb-3">
        Your deal information will be sent to the dealer to finalize your purchase in-store.
      </p>
      <Formik
        enableReinitialize
        initialValues={customerDetail}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, handleBlur, handleChange, errors, touched, values, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-5 mb-5">
              <div>
                <p className="text-black text-sm mb-2">First Name</p>
                <Field
                  name="firstName"
                  type="text"
                  className={`w-full border border-black rounded outline-none py-2 px-3 ${
                    errors.firstName && touched.firstName ? 'border-danger focus:border-danger' : ''
                  }`}
                  placeholder="e.g. John"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly
                />
                {errors.firstName && touched.firstName && (
                  <p className="text-danger text-sm">
                    <span className="text-xl font-semibold leading-3">×</span> {errors.firstName}
                  </p>
                )}
              </div>
              <div>
                <p className="text-black text-sm mb-2">Last Name</p>
                <Field
                  name="lastName"
                  type="text"
                  className={`w-full border border-black rounded outline-none py-2 px-3 ${
                    errors.lastName && touched.lastName ? 'border-danger focus:border-danger' : ''
                  }`}
                  placeholder="e.g. Smith"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly
                />
                {errors.lastName && touched.lastName && (
                  <p className="text-danger text-sm">
                    <span className="text-xl font-semibold leading-3">×</span> {errors.lastName}
                  </p>
                )}
              </div>
              <div>
                <p className="text-black text-sm mb-2">Email</p>
                <Field
                  name="email"
                  type="email"
                  className={`w-full border border-black rounded outline-none py-2 px-3 ${
                    errors.email && touched.email ? 'border-danger focus:border-danger' : ''
                  }`}
                  placeholder="e.g. JSmith@gmail.com"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly
                />
                {errors.email && touched.email && (
                  <p className="text-danger text-sm">
                    <span className="text-xl font-semibold leading-3">×</span> {errors.email}
                  </p>
                )}
              </div>
              <div>
                <p className="text-black text-sm mb-2">
                  Phone <span className="text-lightGray">(Optional)</span>
                </p>
                <Field
                  name="phone"
                  type="text"
                  className="w-full border border-black rounded outline-none py-2 px-3"
                  placeholder="e.g. 456-789-0123"
                  readOnly
                />
              </div>
              <div className="col-span-2">
                <p className="text-black text-sm mb-2">
                  Comments <span className="text-lightGray">(Optional)</span>
                </p>
                <textarea
                  className="border border-black rounded outline-none p-3 w-full resize-none"
                  rows="4"
                  onChange={(e) => setFieldValue('comments', e.target.value)}
                  value={values.comments}
                  maxLength={500}
                />
                <div className="flex justify-end">
                  <p className="text-xs text-darkGray">
                    {500 - values.comments ? values.comments.length : 0} characters remaining
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center mt-8">
              <button type="submit" className="bg-primary rounded text-white px-16 py-2 hover:bg-activePrimary">
                Submit
              </button>
              <button
                type="button"
                className="text-primary hover:underline text-base cursor-pointer mt-4"
                onClick={resetStep}
              >
                Back To My Deal
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

FinishDealStepFinal.propTypes = {
  resetStep: PropTypes.func.isRequired,
  offerId: PropTypes.string.isRequired,
  vehicleId: PropTypes.string.isRequired,
  setStep: PropTypes.func.isRequired,
  setFinishYourDealStepStatus: PropTypes.func.isRequired
}

export default FinishDealStepFinal
