/* eslint-disable jsx-a11y/no-static-element-interactions */
import { SearchOutlined } from '@ant-design/icons'
import { Button, Tabs } from 'antd'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AiOutlineLoading } from 'react-icons/ai'
import kelleyImage from '../../../assets/kbb-logo.png'
import * as KBBVehicleServices from '../../../services/kbb-automobile.service'
import * as KBBPowersportServices from '../../../services/kbb-powersports.service'
import * as VehicleService from '../../../services/vehicle.service'
import './style.scss'

const { TabPane } = Tabs

const TAB_LIST = {
  VALUE_BY_MAKE_MODEL: 'VALUE_BY_MAKE_MODEL',
  VALUE_MY_CAR: 'VALUE_MY_CAR'
}

function VehicleFinanceStep1({
  vehicleType,
  nextStep,
  setYearId,
  setMakeObject,
  setModelObject,
  setCallInitialApi,
  setTrimId
}) {
  const [searchedCars, setSearchedCars] = useState([])
  const [query, setQuery] = useState(undefined)
  const [vehicleSection, setVehicleSection] = useState(1)
  const [queryVIN, setQueryVIN] = useState(undefined)
  const ref = useRef(null)
  const [showSearch, setShowSearch] = useState(false)
  const [tab, setTab] = useState(TAB_LIST.VALUE_BY_MAKE_MODEL)
  const [loading, setLoading] = useState(false)

  const searchQuery = (query) => {
    KBBPowersportServices.getVehicles({ yearId: query })
      .then((response) => {
        setSearchedCars(response.items)
        setShowSearch(true)
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const debounceQueryChange = useMemo(() => {
    return debounce(searchQuery, 500)
  }, [])

  const searchKBBQuery = (keyword) => {
    setLoading(true)
    VehicleService.getSearchSuggestionKBB({ keyword, vehicleType })
      .then((response) => {
        setSearchedCars(response.data)
        setShowSearch(true)
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => {
        setLoading(false)
        document.getElementById('search-vehicle-input').focus()
      })
  }

  const debounceQueryKBBChange = useMemo(() => {
    return debounce(searchKBBQuery, 1000)
  }, [vehicleType])

  useEffect(() => {
    return () => {
      debounceQueryChange.cancel()
      debounceQueryKBBChange.cancel()
    }
  }, [])

  const changeMakeAndModel = (e) => {
    const value = e.target.value
    setQuery(value)

    if (value) {
      debounceQueryKBBChange(value)
    } else {
      setSearchedCars([])
    }
  }

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setSearchedCars([])
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [ref])

  const selectValue = (vehicle) => {
    const { yearId, makeId, makeName, modelId, modelName, displayName, trimId } = vehicle
    setShowSearch(false)
    setQuery(displayName)
    setYearId(yearId)
    setTrimId(trimId)
    setMakeObject({ makeId, make: makeName })
    setModelObject({ modelId, model: modelName })
    setCallInitialApi(true)
    nextStep()
  }

  const handleDecode = (vin) => {
    setLoading(true)
    KBBVehicleServices.getVehiclesByVin(vin)
      .then((response) => {
        const searchedVehicles = response.data.map((item) => ({
          ...item,
          displayName: item.vehicleName
        }))
        setSearchedCars(searchedVehicles)
        setShowSearch(true)
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => setLoading(false))
  }

  const debounceQueryChangeVin = useMemo(() => {
    return debounce(handleDecode, 700)
  }, [])

  const changeVIN = (e) => {
    const value = e.target.value
    setQueryVIN(value)

    if (value) {
      debounceQueryChangeVin(value)
    } else {
      setSearchedCars([])
    }
  }

  const onKeyPressMakeAndModel = (e) => {
    if (e.charCode === 13) {
      searchKBBQuery(e.target.value)
    }
  }

  const onKeyPressVin = (e) => {
    if (e.charCode === 13) {
      handleDecode(e.target.value)
    }
  }

  const hideOnProduction = process.env.REACT_APP_HIDE_ON_PRODUCTION === 'true'

  return (
    <div className="mt-8 step-1">
      <p className="text-2xl text-black font-bold">Value Your Trade-In</p>
      <p className="text-gray-400 text-base mb-6 sm:mb-3">
        Four simple steps to discover your car&apos;s Kelley Blue Book® value.
      </p>
      <div>
        <p className="text-gray-400 text-base mb-6 sm:mb-3">
          Do you have your vehicles
          <span
            className="text-blue-500 ml-1 cursor-pointer"
            onClick={() => {
              setTab(TAB_LIST.VALUE_MY_CAR)
              setVehicleSection(1)
            }}
          >
            VIN Number
          </span>
          {!hideOnProduction && (
            <>
              <span className="ml-1 mr-1">or your</span>
              <span
                className="text-blue-500 cursor-pointer ml-1"
                onClick={() => {
                  setTab(TAB_LIST.VALUE_MY_CAR)
                  setVehicleSection(2)
                }}
              >
                License Plate Number
              </span>
              <span className="ml-1">handy</span>
            </>
          )}
          ?
        </p>
      </div>
      <Tabs
        className="finance-cash-tabs m-1 sm:m-10"
        type="card"
        size="large"
        activeKey={tab}
        onTabClick={(value) => {
          setTab(value)
          setVehicleSection(1)
          setQuery('')
          setQueryVIN('')
        }}
      >
        <TabPane tab="Value By Make & Model" key={TAB_LIST.VALUE_BY_MAKE_MODEL}>
          <div className="pb-3">
            <p className="text-gray-400 text-base pr-24">
              Enter your car&apos;s, make, and model to get an estimated Kelley Blue Book® trade-in value.
            </p>
            <img src={kelleyImage} alt="" width="90" className="absolute right-3 top-0" />
            <p className="text-black mb-1">Year, Make & Model</p>
            <div className="relative" ref={ref}>
              <input
                type="text"
                id="search-vehicle-input"
                placeholder="e.g.2015 Honda Pilot"
                className="w-full border border-black rounded bg-inputBgColor text-inputColor py-2 px-3"
                value={query}
                onChange={changeMakeAndModel}
                onKeyPress={onKeyPressMakeAndModel}
                // disabled={loading}
              />
              {loading ? (
                <AiOutlineLoading className="absolute right-3 top-1/3 -translate-y-2/4 animate-spin" />
              ) : (
                <SearchOutlined className="absolute right-3 top-1/3 -translate-y-2/4" />
              )}
              {showSearch && query && searchedCars?.length > 0 && (
                <div className="h-full">
                  <ul className="absolute overflow-y-auto bg-white w-full right-0 left-0 border border-gray-300 rounded-md h-64">
                    {searchedCars?.map((vehicle, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          selectValue(vehicle)
                        }}
                        className="hover:bg-gray-50 list-none text-sm px-2 py-3 cursor-pointer"
                        role="presentation"
                      >
                        {vehicle.displayName}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </TabPane>
        <TabPane tab="Value By VIN" key={TAB_LIST.VALUE_MY_CAR}>
          {vehicleSection === 1 ? (
            <div className="pb-3">
              <p className="text-gray-400 text-base pr-24">
                Use your VIN to look up your car and get a more accurate Kelley Blue Book® trade-in value.
              </p>
              <img src={kelleyImage} alt="" width="90" className="absolute right-3 top-0" />
              <p className="text-black mb-1">VIN</p>
              <div className="relative" ref={ref}>
                <input
                  type="text"
                  placeholder="e.g. RTG567908THG"
                  className="w-full border border-black rounded bg-inputBgColor text-inputColor py-2 px-3"
                  value={queryVIN}
                  onChange={changeVIN}
                  onKeyPress={onKeyPressVin}
                />

                {loading ? (
                  <AiOutlineLoading className="absolute right-3 top-1/3 -translate-y-2/4 animate-spin" />
                ) : (
                  <SearchOutlined className="absolute right-3 top-1/3 -translate-y-2/4" />
                )}
                {showSearch && queryVIN && searchedCars?.length > 0 && (
                  <div className="h-full">
                    <ul className="absolute overflow-y-auto bg-white w-full right-0 left-0 border border-gray-300 rounded-md h-64">
                      {searchedCars?.map((vehicle, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            selectValue(vehicle)
                          }}
                          className="hover:bg-gray-50 list-none text-sm px-2 py-3 cursor-pointer"
                          role="presentation"
                        >
                          {vehicle.displayName}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div className="flex items-center">
                <Button className="flex items-center bg-white text-blue-400 mr-4">ABC-123</Button>
                <p>Don&apos;t know your vehicle identification number (VIN)?</p>
              </div>

              <p className="text-black my-4">License Plate Number</p>
              <div className="relative" ref={ref}>
                <input
                  type="text"
                  className="w-full border border-black rounded bg-inputBgColor text-inputColor py-2 px-3"
                  value={queryVIN}
                  onChange={changeVIN}
                />
              </div>
            </div>
          )}
        </TabPane>
      </Tabs>
      <div className="flex justify-center mt-12">
        <button
          type="button"
          className="bg-primary rounded text-white px-16 py-2 hover:bg-activePrimary"
          onClick={nextStep}
        >
          Next
        </button>
      </div>
    </div>
  )
}

VehicleFinanceStep1.propTypes = {
  nextStep: PropTypes.func.isRequired,
  setYearId: PropTypes.func,
  setMakeObject: PropTypes.func,
  setModelObject: PropTypes.func,
  setCallInitialApi: PropTypes.func,
  vehicleType: PropTypes.string.isRequired,
  setTrimId: PropTypes.func
}

export default VehicleFinanceStep1
