/* eslint-disable no-nested-ternary */
import { Tabs, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ReactStars from 'react-stars'
import DealerReview from 'src/components/Dealership/DealerReview'
import DealershipHeader from 'src/components/Dealership/DealershipHeader'
import DealershipInventory from 'src/components/Dealership/DealershipInventory'
import NearbyDealership from 'src/components/Dealership/NearbyDealership'
import { STORAGE_KEYS } from 'src/constants/constants'
import * as DealerService from '../../services/dealer.service'
import './style.scss'

const { TabPane } = Tabs

const TAB_KEYS = {
  INVENTORY: 'INVENTORY',
  REVIEW: 'REVIEW'
}

function Dealership() {
  const { id } = useParams()
  const dealerId = Number(id)
  const [currentTab, setCurrentTab] = useState(TAB_KEYS.INVENTORY)
  const [dealer, setDealer] = useState({})
  const sharedByDealership = sessionStorage.getItem(STORAGE_KEYS.IS_SHARED_BY_DEALERSHIP) === 'true'
  const [dealerNumReviews, setNumReviews] = useState(0)
  const [dealerStarRatings, setStarRatings] = useState(0)

  const handleChangeTab = (key) => {
    setCurrentTab(key)
  }

  useEffect(() => {
    if (dealerId > 0) {
      DealerService.getDealerDetail(dealerId)
        .then((response) => {
          setDealer(response.data[0])
        })
        .catch((error) => {
          console.error(error)
        })

      DealerService.getDealerRatings(dealerId)
        .then((response) => {
          setNumReviews(response?.data?.num_of_reviews ?? 0)
          setStarRatings(response?.data?.stars_ratings ?? 0)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [])

  const saleReviewsTabTitle = (
    <div className="flex gap-1 items-center h-11 text-base">
      <span className="text-gray-700">
        Sales Reviews
        <Tooltip className="c-pop-tooltip ml-1 mr-1" title="Total Number of Reviews" placement="bottomLeft">
          ({dealerNumReviews})
        </Tooltip>
      </span>
      <Tooltip className="c-pop-tooltip ml-1 mr-1" title="Star Ratings" placement="bottomLeft">
        <span>
          <ReactStars value={dealerStarRatings} size={24} edit={false} color1="#d4d4d4" color2="#fadb14" />
        </span>
      </Tooltip>
    </div>
  )

  const inventoryTabTitle = <div className="flex items-center text-gray-700 h-11 text-base">Inventory</div>

  const hideOnProduction = process.env.REACT_APP_HIDE_ON_PRODUCTION === 'true'

  return (
    <div className="pt-5">
      <div className="container mx-auto">
        <DealershipHeader dealerInfo={dealer} />
        <div className="my-5">
          <Tabs onChange={handleChangeTab} activeKey={currentTab}>
            <TabPane tab={inventoryTabTitle} key={TAB_KEYS.INVENTORY} />
            {!hideOnProduction && <TabPane tab={saleReviewsTabTitle} key={TAB_KEYS.REVIEW} />}
          </Tabs>
        </div>
        <div className={currentTab === TAB_KEYS.INVENTORY ? '' : 'hidden'}>
          <DealershipInventory dealerId={dealerId} />
        </div>
        <div className={currentTab === TAB_KEYS.REVIEW ? '' : 'hidden'}>
          <DealerReview />
        </div>
        {!sharedByDealership && <NearbyDealership />}
      </div>
    </div>
  )
}

export default Dealership
