import React, { useEffect, useState, useCallback } from 'react'
import { Form, Input, InputNumber, Table, notification } from 'antd'
import * as PropTypes from 'prop-types'
import * as DealerService from '../../../services/dealer.service'
import './style.scss'

const getParams = (params) => ({
  results: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params
})

function CreditScoreVehicleYearModelTermInterestRate({ dealerId, canManagePaymentSettings }) {
  const [loading, setLoading] = useState(true)
  const [dataRates, setDataRates] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 6,
    pageSizeOptions: [6, 12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72, 78, 84, 90, 96, 102, 108, 114, 120]
  })
  const [form] = Form.useForm()

  const handleChangeRates = (index, name, event) => {
    const value = event.target.value
    const rowsInput = [...dataRates]
    rowsInput[index][name].value = value
    rowsInput[index][name].label = value
    setDataRates(rowsInput)
  }

  const getInputProperties = (name) => {
    let label = name
    let prefix = ''
    if (name === 'range_from') {
      label = 'Credit Score From'
    } else if (name === 'range_to') {
      label = 'Credit Score To'
    } else if (name === 'year') {
      label = 'Vehicle Year'
      prefix = '-'
    } else if (name === 'term_from') {
      label = 'Term (Months) From'
    } else if (name === 'term_to') {
      label = 'Term (Months) To'
    } else if (name === 'interest_rate') {
      label = 'Interest Rate'
    }
    return {
      label,
      prefix
    }
  }

  const renderInputRate = (index, column, name) => {
    const inputProperties = getInputProperties(name)
    const input =
      name === 'year' || name === 'interest_rate' ? (
        <InputNumber
          type="number"
          min={0}
          max={100}
          name={`${name}_${index}`}
          placeholder={column.label}
          prefix={inputProperties.prefix}
        />
      ) : (
        <Input
          type="number"
          name={`${name}_${index}`}
          placeholder={column.label}
          prefix={inputProperties.prefix}
        />
      )
    return (
      <Form.Item
        onChange={(event) => handleChangeRates(index, name, event)}
        name={`${column.name}`}
        key={`${column.name}`}
        initialValue={column.label}
        rules={[{ required: true, message: `${inputProperties.label} is required` }]}
      >
        {input}
      </Form.Item>
    )
  }

  const renderLabelRate = (column) => {
    return <div className="text-center">{`${column.label}`}</div>
  }

  const columnsRates = [
    {
      title: 'Credit Score',
      children: [
        {
          width: 80,
          title: 'From',
          dataIndex: 'range_from',
          key: 'range_from',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputRate(index, column, 'range_from')
            }
            if (!canManagePaymentSettings) {
              return renderLabelRate(column)
            }
          }
        },
        {
          width: 80,
          title: 'To',
          dataIndex: 'range_to',
          key: 'range_to',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputRate(index, column, 'range_to')
            }
            if (!canManagePaymentSettings) {
              return renderLabelRate(column)
            }
          }
        }
      ]
    },
    {
      width: 80,
      title: 'Vehicle Year',
      dataIndex: 'year',
      key: 'year',
      render: (column, data, index) => {
        if (canManagePaymentSettings) {
          return renderInputRate(index, column, 'year')
        }
        if (!canManagePaymentSettings) {
          return renderLabelRate(column)
        }
      }
    },
    {
      title: 'Term (Months)',
      children: [
        {
          width: 80,
          title: 'From',
          dataIndex: 'term_from',
          key: 'term_from',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputRate(index, column, 'term_from')
            }
            if (!canManagePaymentSettings) {
              return renderLabelRate(column)
            }
          }
        },
        {
          width: 80,
          title: 'To',
          dataIndex: 'term_to',
          key: 'term_to',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputRate(index, column, 'term_to')
            }
            if (!canManagePaymentSettings) {
              return renderLabelRate(column)
            }
          }
        }
      ]
    },
    {
      width: 80,
      title: 'Interest Rate',
      dataIndex: 'interest_rate',
      key: 'interest_rate',
      render: (column, data, index) => {
        if (canManagePaymentSettings) {
          return renderInputRate(index, column, 'interest_rate')
        }
        if (!canManagePaymentSettings) {
          return renderLabelRate(column)
        }
      }
    }
  ]

  const fetchData = useCallback(
    (params = {}) => {
      setLoading(true)
      const allParams = {
        ...params,
        dealer_id: dealerId
      }

      DealerService.getCreditScoreVehicleYearTermInterestRates(getParams(allParams))
        .then((response) => {
          const data = response.data.data
          setDataRates(data)
          setPagination({
            ...allParams.pagination,
            total: response.data.total
          })
        })
        .catch((error) => {
          setDataRates([])
          setPagination({
            current: 1,
            pageSize: 6,
            pageSizeOptions: [6, 12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72, 78, 84, 90, 96, 102, 108, 114, 120]
          })
          console.error(error)
        })
        .finally(() => setLoading(false))
    },
    [dataRates]
  )

  const handleTableChange = (newPagination, filters, sorter) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: newPagination,
      ...filters
    })
  }

  useEffect(() => {
    fetchData({
      pagination
    })
  }, [])

  const handleFormSubmission = async () => {
    setLoading(true)

    const params = {
      dealer_id: dealerId,
      rates: dataRates,
      results: pagination?.pageSize,
      page: pagination?.current
    }

    DealerService.creditScoreVehicleYearTermInterestRates(params)
      .then((response) => {
        setDataRates(response.data.data)
        notification.success({
          message: 'Success!',
          description: 'Updated successful.'
        })
      })
      .catch((error) => {
        notification.error({
          message: 'Error!',
          description: 'Updated failed.'
        })
        console.error(error)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Form form={form} onFinish={handleFormSubmission}>
      <div className="ant-table-content">
        <Form.Item className="flex items-end w-full mt-0 submit" shouldUpdate>
          <div>
            {dataRates.length > 0 ? (
              <Table
                loading={loading}
                columns={columnsRates}
                className="payment-settings-table-credit-score-vehicle-year-term-interest-rates"
                dataSource={dataRates}
                scroll={{ x: 'max-content' }}
                pagination={pagination}
                onChange={handleTableChange}
              />
            ) : null}
          </div>
          {canManagePaymentSettings && (
            <div className="mt-0">
              <button
                type="submit"
                className="bg-primary hover:bg-activePrimary font-semibold rounded-lg px-8 py-2 outline-none text-lg w-fit text-white flex justify-center"
              >
                Save
              </button>
            </div>
          )}
        </Form.Item>
      </div>
    </Form>
  )
}

CreditScoreVehicleYearModelTermInterestRate.propTypes = {
  dealerId: PropTypes.number,
  canManagePaymentSettings: PropTypes.bool
}

export default CreditScoreVehicleYearModelTermInterestRate
