import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import AuthPopup from 'src/components/Vehicle/BoxButtonGroup/AuthPopup'
import LoginFormModal from 'src/components/Vehicle/BoxButtonGroup/LoginFormModal'
import SignUpFormModal from 'src/components/Vehicle/BoxButtonGroup/SignUpFormModal'
import VehicleFinanceStep1 from 'src/components/Vehicle/VehicleFinanceSteps/step1'
import VehicleFinanceStep2 from 'src/components/Vehicle/VehicleFinanceSteps/step2'
import VehicleFinanceStep3 from 'src/components/Vehicle/VehicleFinanceSteps/step3'
import VehicleFinanceStep4 from 'src/components/Vehicle/VehicleFinanceSteps/step4'
import VehicleFinanceStep5 from 'src/components/Vehicle/VehicleFinanceSteps/step5'
import VehicleFinanceStepFinal from 'src/components/Vehicle/VehicleFinanceSteps/stepFinal'
import TradeInValue from 'src/components/Vehicle/VehicleFinanceSteps/tradeInValue'
import { KBB_VEHICLE_TYPE, STORAGE_KEYS } from 'src/constants/constants'
import * as CustomerServices from 'src/services/customer.service'

function ValueYourTradeIn() {
  const navigate = useNavigate()
  const { customerAccount, customerLogin } = useAuthorizationContext()
  const [showPopupConfirm, setShowPopupConfirm] = useState(false)
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [showSignUpForm, setShowSignUpForm] = useState(false)
  const [searchParams] = useSearchParams()
  const dealershipId = searchParams.get('dealer_id')
  const linkCode = searchParams.get('code')
  const [step, setStep] = useState(0)
  const vehicleType = KBB_VEHICLE_TYPE.AUTOMOTIVE
  const [yearId, setYearId] = useState('')
  const [makeObject, setMakeObject] = useState({
    makeId: '',
    make: ''
  })
  const [modelObject, setModelObject] = useState({
    modelId: '',
    model: ''
  })
  const [trimId, setTrimId] = useState('')
  const [ownership, setOwnership] = useState('')
  const [vin, setVin] = useState('')
  const [mileage, setMiliage] = useState()
  const [zipCode, setZipCode] = useState()
  const [active, setActive] = useState('no')
  const [debit, setDebit] = useState()
  const [selectedCondition, setSelectedCondition] = useState()
  const [engine, setEngine] = useState()
  const [transmission, setTransmission] = useState()
  const [color, setColor] = useState()
  const [userObject, setUserObject] = useState({})
  const [vehicles, setVehicles] = useState({})
  const [tradeVehicleInfo, setTradeVehicleInfo] = useState({ tradeIn: 0 })
  const [selectedOtherColor, setSelectedOtherColor] = useState(false)
  const [callInitialApi, setCallInitialApi] = useState(false)
  const [, setLoginRequired] = useState(false)

  const [listYears, setListYears] = useState([])
  const [makeList, setMakeList] = useState([])
  const [modelList, setModelList] = useState([])
  const [trimList, setTrimList] = useState([])

  const [finalConfiguration, setFinalConfiguration] = useState({})
  const [tradeId, setTradeId] = useState()
  const [tradeInValue, setTradeInValue] = useState('')

  const [verifying, setVerifying] = useState(true)
  const [, setShowCashDownPopupConfirm] = useState(false)
  const [tradeToVehicleId, setTradeToVehicleId] = useState('')

  useEffect(() => {
    sessionStorage.setItem(STORAGE_KEYS.IS_SHARED_BY_DEALERSHIP, 'true')
    sessionStorage.setItem(STORAGE_KEYS.SHARED_BY_DEALERSHIP_ID, dealershipId)
    if (linkCode) {
      CustomerServices.checkValidLink(linkCode)
        .catch(() => {
          navigate('/link-expired')
        })
        .finally(() => setVerifying(false))
    }
  }, [dealershipId, linkCode])

  const resetStep = () => {
    setStep(0)
  }

  const nextStep = () => {
    setStep(step + 1)
  }

  const backStep = () => {
    setStep(step - 1)
  }

  const handleSubmitTrade = (usrObj) => {
    if (vehicles && vehicles[0]) {
      const { trimName } = vehicles[0]

      let estimatedvalue = tradeVehicleInfo.tradeIn || 0
      if (usrObj !== undefined) {
        if (estimatedvalue < 1 && usrObj.tradeIn !== undefined && usrObj.tradeIn > 0) {
          estimatedvalue = usrObj.tradeIn
        }
      }

      CustomerServices.handleSaveTrade({
        vin,
        make: makeObject.make,
        makeId: makeObject.makeId,
        model: modelObject.model,
        modelId: modelObject.modelId,
        year: yearId,
        trim: trimName,
        trimId,
        ownership,
        mileage,
        zipcode: zipCode,
        owePrice: undefined,
        condition: selectedCondition,
        engine,
        transmission,
        color,
        firstName: userObject.firstName || usrObj.firstName,
        lastName: userObject.lastName || usrObj.lastName,
        email: userObject.email || usrObj.email,
        phone: userObject.phone || usrObj.phone,
        comment: userObject.comments || usrObj.comments,
        estimatedvalue,
        notLoggedIn: !customerAccount.id,
        linkCode,
        stepWizard: 5,
        tradeToVehicleId
      })
        .then((response) => {
          setTradeId(response.data._id)
        })
        .finally(() => {
          if (!customerLogin) {
            setShowPopupConfirm(true)
          }
        })
    }
  }

  const sendOffer = () => {
    CustomerServices.saveOffer({ vehicleId: tradeToVehicleId, tradeInId: tradeId })
      .then()
      .catch((err) => console.error(err))
  }

  const handleShowLoginForm = () => {
    setShowPopupConfirm(false)
    setShowLoginForm(true)
  }

  const handleShowSignUpForm = () => {
    setShowPopupConfirm(false)
    setShowSignUpForm(true)
  }

  const callbackForForm = () => {
    if (tradeId) {
      CustomerServices.saveTradeinToLoginCustomer(tradeId)
        .then(() => {
          sendOffer()
        })
        .catch((error) => error)
    }
    navigate('/customer-dashboard')
  }

  if (verifying) {
    return (
      <div style={{ fontSize: '32px' }} className="verification-expired-table">
        Verifying...
      </div>
    )
  }

  return (
    <div className="container md:w-full lg:w-3/4 xl:w-1/2 px-5 py-10">
      {step === 0 && (
        <VehicleFinanceStep1
          nextStep={nextStep}
          setYearId={setYearId}
          setMakeObject={setMakeObject}
          setModelObject={setModelObject}
          setTrimId={setTrimId}
          setCallInitialApi={setCallInitialApi}
          vehicleType={vehicleType}
        />
      )}
      {step === 1 && (
        <VehicleFinanceStep2
          nextStep={nextStep}
          backStep={backStep}
          vehicleType={vehicleType}
          yearId={yearId}
          setYearId={setYearId}
          setMakeObject={setMakeObject}
          makeObject={makeObject}
          setModelObject={setModelObject}
          modelObject={modelObject}
          setTrimId={setTrimId}
          trimId={trimId}
          ownership={ownership}
          setOwnership={setOwnership}
          vin={vin}
          setVin={setVin}
          mileage={mileage}
          setMiliage={setMiliage}
          zipCode={zipCode}
          setZipCode={setZipCode}
          active={active}
          setActive={setActive}
          debit={debit}
          setDebit={setDebit}
          callInitialApi={callInitialApi}
          setCallInitialApi={setCallInitialApi}
          vehicles={vehicles}
          setVehicles={setVehicles}
          setTradeVehicleInfo={setTradeVehicleInfo}
          listYears={listYears}
          setListYears={setListYears}
          makeList={makeList}
          setMakeList={setMakeList}
          modelList={modelList}
          setModelList={setModelList}
          trimList={trimList}
          setTrimList={setTrimList}
        />
      )}
      {step === 2 && (
        <VehicleFinanceStep3
          nextStep={nextStep}
          backStep={backStep}
          selectedCondition={selectedCondition}
          setSelectedCondition={setSelectedCondition}
          tradeVehicleInfo={tradeVehicleInfo}
        />
      )}
      {step === 3 && (
        <VehicleFinanceStep4
          nextStep={nextStep}
          backStep={backStep}
          engine={engine}
          setEngine={setEngine}
          transmission={transmission}
          setTransmission={setTransmission}
          color={color}
          setColor={setColor}
          tradeVehicleInfo={tradeVehicleInfo}
          setSelectedOtherColor={setSelectedOtherColor}
          selectedOtherColor={selectedOtherColor}
        />
      )}
      {step === 4 && (
        <VehicleFinanceStep5
          nextStep={nextStep}
          backStep={backStep}
          tradeVehicleInfo={tradeVehicleInfo}
          vehicleType={vehicleType}
          setFinalConfiguration={setFinalConfiguration}
          color={color}
          selectedOtherColor={selectedOtherColor}
        />
      )}

      {step === 5 && (
        <VehicleFinanceStepFinal
          nextStep={nextStep}
          backStep={backStep}
          resetStep={resetStep}
          userObject={userObject}
          setUserObject={setUserObject}
          handleSubmitTrade={handleSubmitTrade}
          finalConfiguration={finalConfiguration}
          mileage={mileage}
          zipCode={zipCode}
          setTradeInValue={setTradeInValue}
          selectedCondition={selectedCondition}
          isSinglePage
          linkCode={linkCode}
          setTradeToVehicleId={setTradeToVehicleId}
        />
      )}
      {step === 6 && (
        <TradeInValue
          tradeVehicleInfo={tradeVehicleInfo}
          tradeId={tradeId}
          backStep={backStep}
          tradeInValue={tradeInValue}
        />
      )}

      <AuthPopup
        show={showPopupConfirm}
        setShow={setShowPopupConfirm}
        handleShowLoginForm={handleShowLoginForm}
        handleShowSignUpForm={handleShowSignUpForm}
        closable={false}
        maskClosable={false}
        setLoginRequired={setLoginRequired}
      />
      <LoginFormModal
        showModal={showLoginForm}
        setShowModal={setShowLoginForm}
        callback={callbackForForm}
        setShowCashDownPopupConfirm={setShowCashDownPopupConfirm}
      />
      <SignUpFormModal
        showModal={showSignUpForm}
        setShowModal={setShowSignUpForm}
        callback={callbackForForm}
        setShowCashDownPopupConfirm={setShowCashDownPopupConfirm}
      />
    </div>
  )
}

export default ValueYourTradeIn
