import React, { useState } from 'react'
import { CustomDeals } from 'src/components/AdminDashboard/CustomDeals'
import PricingTables from 'src/components/AdminDashboard/PricingTables'
import { STORAGE_KEYS } from 'src/constants/constants'
import { useAuthorizationContext } from '../../AuthorizationProvider'
import { SearchDealerDashboard } from '../../components/DealerDashboard/SearchDealerDashboard'
import { load } from '../../lib/storage'
import './style.scss'

function AdminDealers() {
  const [searchText, setSearchText] = useState('')
  const [loading, setLoading] = useState(false)
  const [reloadDealers, setReloadDealers] = useState(false)
  const dealerId = load(STORAGE_KEYS.DEALER_ID) || 0
  const { dealershipAccount, adminAccount } = useAuthorizationContext()
  const isAdmin = !!adminAccount.id

  const searchableData = (searchData) => {
    setSearchText(searchData)
  }

  return (
    <div className="container">
      {dealerId < 1 && (
        <div className="mx-auto my-10 px-4 rounded-xl border-2">
          <PricingTables />
        </div>
      )}
      {!isAdmin && (
        <div className="my-5 lg:mx-16 mx-3">
          <SearchDealerDashboard
            filterParentData={searchableData}
            dealershipAccount={dealershipAccount}
            isHidden
            isAdmin={false}
            dealerId={dealerId}
            setReloadDealers={setReloadDealers}
          />
        </div>
      )}
      <div className="mx-auto mt-2 mb-12 pt-6 px-4 rounded-xl border-2">
        <CustomDeals
          searchText={searchText}
          filterParentData={searchableData}
          showSearch
          dealerId={dealerId}
          isAdmin={isAdmin}
          loading={loading}
          setLoading={setLoading}
          reloadDealers={reloadDealers}
          setReloadDealers={setReloadDealers}
        />
      </div>
    </div>
  )
}

export default AdminDealers
