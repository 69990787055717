import { Modal, Table } from 'antd'
import * as PropTypes from 'prop-types'
import React from 'react'

function ExistingTradeInsModal({ showModal, setSelectedTradeIn, closeModal, tradeIns }) {
  const columns = [
    {
      title: 'Maker',
      dataIndex: ['vehicle', 'make']
    },
    {
      title: 'Model',
      dataIndex: ['vehicle', 'model']
    },
    {
      title: 'Year',
      dataIndex: ['vehicle', 'year']
    },
    {
      title: 'Source',
      dataIndex: 'source'
    }
  ]

  return (
    <Modal
      centered
      visible={showModal}
      title="My Trade Ins"
      onCancel={closeModal}
      className="calendar-modal"
      destroyOnClose
      width="75%"
      footer={[]}
    >
      <Table
        columns={columns}
        dataSource={tradeIns}
        onRow={(row) => ({
          onClick: () => setSelectedTradeIn(row)
        })}
      />
    </Modal>
  )
}

ExistingTradeInsModal.propTypes = {
  showModal: PropTypes.bool,
  setSelectedTradeIn: PropTypes.func,
  closeModal: PropTypes.func,
  tradeIns: PropTypes.array
}

export default ExistingTradeInsModal
