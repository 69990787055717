import { Form, Input, notification } from 'antd'
import React, { useEffect } from 'react'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import * as DealerServices from 'src/services/dealer.service'

function AdfEmailAddress() {
  const [form] = Form.useForm()
  const { dealershipAccount, setDealershipAccount } = useAuthorizationContext()
  const { email_adf_xml: adfEmail } = dealershipAccount

  useEffect(() => {
    if (adfEmail) {
      form.setFieldsValue({ adf_xml: adfEmail })
    }
  }, [adfEmail])

  const handleStoreCredit = (values) => {
    DealerServices.updateADFEmail(values)
      .then(() => {
        notification.success({
          message: 'Success!',
          description: 'Updated ADF email successful!'
        })
        setDealershipAccount({ ...dealershipAccount, adf_xml: values.adf_xml })
      })
      .catch((error) => {
        console.error(error)
        notification.error({
          message: 'Error!',
          description: 'Cannot complete this action. Please try again!'
        })
      })
  }

  return (
    <div className="container w-full rounded-2xl border-2 p-6 bg-white flex flex-col justify-center items-center gap-5">
      <div className="text-xl">Outbound ADF Email Setup</div>
      <div className="w-full lg:w-96">
        <Form layout="vertical" form={form} name="vehicle-ref" onFinish={handleStoreCredit}>
          <div className="lg:px-12">
            <Form.Item
              name="adf_xml"
              rules={[
                { required: true, message: 'Email is required' }
                // ,
                // {
                //   pattern:
                //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                //   message: 'Has to be in email format'
                // }
              ]}
              className="custom-antd-label"
            >
              <Input
                name="adf_xml"
                placeholder="ADF Email"
                className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border"
                type="text"
              />
            </Form.Item>
            <div className="mt-6 flex justify-center">
              <button
                type="submit"
                className="bg-primary hover:bg-activePrimary font-semibold rounded-lg px-8 py-2 outline-none text-lg w-fit text-white flex justify-center"
              >
                Save
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default AdfEmailAddress
