import { Button, Form, Input, notification, Popover } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { AiOutlineCheck, AiOutlineClose, AiOutlineMail, AiOutlineSend, AiOutlineShareAlt } from 'react-icons/ai'
import { BsPhone } from 'react-icons/bs'
import { IoIosArrowBack } from 'react-icons/io'
import * as VehicleService from 'src/services/vehicle.service'
import { VALID_EMAIL } from '../../constants/constants'
import './style.scss'

function Share({ vehicle }) {
  const [form] = Form.useForm()
  const [, forceUpdate] = useState({})
  const [shareType, setShareType] = useState(-1)
  const [shareVisible, setShareVisible] = useState(false)

  useEffect(() => {
    forceUpdate({})
  }, [])

  const handleDisabled = (form) => {
    return (
      !form.isFieldTouched('first_name') ||
      !form.isFieldTouched(shareType === 0 ? 'phoneNumber' : 'email') ||
      !form.isFieldTouched(shareType === 0 ? 'recipient_phoneNumber' : 'recipient_email') ||
      !!form.getFieldsError().filter(({ errors }) => errors.length).length
    )
  }

  const validation = () => {
    const paternMessage = {
      pattern: /^[a-zA-Z0-9\s]+$/g,
      message: 'Alphanumeric string accepted'
    }
    switch (shareType) {
      case 0:
        paternMessage.pattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
        paternMessage.message = 'Invalid phone number'
        return paternMessage
      case 1:
        paternMessage.pattern = VALID_EMAIL
        paternMessage.message = 'Invalid email format'
        return paternMessage
      default:
        return paternMessage
    }
  }

  const handleShare = (value) => {
    if (shareType === 0) {
      VehicleService.shareVehicleLinkPhone({
        vehicleId: vehicle._id,
        phone: value.phoneNumber,
        recipientPhone: value.recipient_phoneNumber,
        firstName: value.first_name,
        lastName: value.last_name
      })
        .then(() => {
          notification.success({
            message: 'Success!',
            description: 'Vehicle link sent!'
          })

          setShareType(2)
          form.resetFields()
        })
        .catch((err) => {
          let errorMessage = 'Cannot complete this action. Please try again!'
          if (err.response.data.message !== undefined && err.response.data.message === 'AlreadyShared') {
            errorMessage = 'Vehicle link was already shared.'
          }
          notification.error({
            message: 'Error!',
            description: errorMessage
          })
        })
    }

    if (shareType === 1) {
      VehicleService.shareVehicleLinkEmail({
        vehicleId: vehicle._id,
        email: value.email,
        recipientEmail: value.recipient_email,
        firstName: value.first_name,
        lastName: value.last_name
      })
        .then(() => {
          notification.success({
            message: 'Success!',
            description: 'Vehicle link sent!'
          })

          setShareType(2)
          form.resetFields()
        })
        .catch((err) => {
          let errorMessage = 'Cannot complete this action. Please try again!'
          if (err.response.data.message !== undefined && err.response.data.message === 'AlreadyShared') {
            errorMessage = 'Vehicle link was already shared.'
          }
          notification.error({
            message: 'Error!',
            description: errorMessage
          })
        })
    }
  }

  const renderShareType = () => {
    switch (shareType) {
      case -1:
        return (
          <div>
            <Button
              type="text"
              onClick={() => setShareType(0)}
              className="flex w-full items-center"
              icon={<BsPhone size={25} className="mr-2" />}
            >
              Send to phone
            </Button>
            <hr className="my-4" />
            <Button
              type="text"
              onClick={() => setShareType(1)}
              className="flex w-full items-center mb-4"
              icon={<AiOutlineMail size={25} className="mr-2" />}
            >
              Email
            </Button>
          </div>
        )
      case 2:
        return (
          <div className="w-full gap-5 my-3 grid lg:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 grid-cols-1">
            <div className="col-span-2 alert-success">
              <AiOutlineCheck className="inline-block" /> Your Email has been sent. Thank you for sharing this ride on
              MotorMarketUSA!
            </div>
          </div>
        )

      default:
        return (
          <Form form={form} onFinish={handleShare} preserve={false}>
            <div className="w-full gap-5 my-3 grid lg:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 grid-cols-1">
              <Form.Item
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: `Your first name is required`
                  },
                  {
                    pattern: /^[a-zA-Z\s]+$/g,
                    message: 'First name can only consist of letters'
                  }
                ]}
                hasFeedback
              >
                <Input placeholder="Your First Name" size="large" autoFocus onChange={() => {}} />
              </Form.Item>

              <Form.Item
                name="last_name"
                rules={[
                  {
                    pattern: /^[a-zA-Z\s]+$/g,
                    message: 'Last name can only consist of letters'
                  }
                ]}
                hasFeedback
              >
                <Input placeholder="Your Last Name (Optional)" size="large" onChange={() => {}} />
              </Form.Item>

              <Form.Item
                name={`${shareType === 0 ? 'phoneNumber' : 'email'}`}
                rules={[
                  {
                    required: true,
                    message: `${shareType === 0 ? 'Phone Number' : 'Your Email'} is required`
                  },
                  validation()
                ]}
                hasFeedback
              >
                <Input
                  placeholder={`${shareType === 0 ? 'Your phone number' : 'Your Email'}`}
                  size="large"
                  onChange={() => {}}
                />
              </Form.Item>

              <Form.Item
                name={`${shareType === 0 ? 'recipient_phoneNumber' : 'recipient_email'}`}
                rules={[
                  {
                    required: true,
                    message: `${shareType === 0 ? "Recipient's Phone Number" : "Recipient's Email"} is required`
                  },
                  validation()
                ]}
                hasFeedback
              >
                <Input
                  placeholder={`${shareType === 0 ? "Recipient's Phone Number" : "Recipient's Email"}`}
                  size="large"
                  onChange={() => {}}
                />
              </Form.Item>

              <Form.Item className="flex items-center" shouldUpdate>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="flex text-base items-center justify-center w-full h-11 rounded-xl"
                    disabled={handleDisabled(form)}
                    icon={<AiOutlineSend size={25} className="mr-2" />}
                  >
                    Send
                  </Button>
                )}
              </Form.Item>
            </div>
          </Form>
        )
    }
  }

  const handleVisibility = () => {
    setShareVisible(!shareVisible)
    setShareType(-1)
  }

  const handleBack = () => {
    setShareType(-1)
  }

  const renderShareTitle = () => {
    switch (shareType) {
      case -1:
        return (
          <p className="flex items-center justify-between my-4">
            Share this Ride <AiOutlineClose size={20} onClick={handleVisibility} className="cursor-pointer" />
          </p>
        )
      default:
        return (
          <p className="flex items-center my-4">
            <IoIosArrowBack size={25} className="cursor-pointer mr-4" onClick={handleBack} />
            Share this Ride by {shareType === 0 ? 'phone number' : 'email'}
          </p>
        )
    }
  }

  return (
    <Popover
      content={renderShareType}
      title={renderShareTitle}
      visible={shareVisible}
      trigger="click"
      placement="bottomRight"
      className="cursor-pointer"
      onVisibleChange={handleVisibility}
    >
      <Button type="default" className="flex w-fit float-right" icon={<AiOutlineShareAlt size={25} className="mr-2" />}>
        Share
      </Button>
    </Popover>
  )
}

Share.propTypes = {
  vehicle: PropTypes.object.isRequired
}

export default Share
