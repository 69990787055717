import { Button, notification } from 'antd'
import * as PropTypes from 'prop-types'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import React, { useState, useEffect } from 'react'
import * as DealerService from '../../../services/dealer.service'
import './style.scss'

function SftpInventoryCredentials({ dealer }) {
  const [settings, setSettings] = useState({})
  const [enabled, setEnabled] = useState(false)
  const [loading, setLoading] = useState(true)
  const [email, setEmail] = useState(dealer.email)
  const [showPassword, setShowPassword] = useState(false)

  const handleChangeShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const carsForSaleTemplateLink = DealerService.getCarsForSaleTemplateLink()

  const openNotification = (type, userAction) => {
    const action = type === 'success' ? notification.success : notification.error
    const message = type === 'success' ? `Successfully ${userAction}` : `Action Failed: ${userAction}`
    action({
      message,
      placement: 'topRight'
    })
  }

  useEffect(() => {
    DealerService.getDealerCarsForSaleSettings(dealer.dealer_id).then((response) => {
      setSettings(response.data)
      setEnabled(response.data.is_enabled)
      setLoading(false)
    })
  }, [loading])

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleEnable = () => {
    DealerService.setDealerCarsForSaleSettings({ dealerId: dealer.dealer_id, action: 'enable' }).then((response) => {
      setSettings(response.data)
      setEnabled(response.data.is_enabled)
      setLoading(false)
      openNotification('success', 'Enabled')
    })
  }

  const handleDisable = () => {
    DealerService.setDealerCarsForSaleSettings({ dealerId: dealer.dealer_id, action: 'disable' }).then((response) => {
      setSettings(response.data)
      setEnabled(response.data.is_enabled)
      setLoading(false)
      openNotification('success', 'Disabled')
    })
  }

  const handleSendCredentials = () => {
    DealerService.sendDealerCarsForSaleSettingsEmail({ dealerId: dealer.dealer_id, email }).then(() => {
      openNotification('success', 'Sent Credentials')
    })
  }

  return (
    <div className="pb-6 bg-white text-black">
      {!loading ? (
        <div className="mt-5">
          <div className="row">
            <Button type="primary" disabled={enabled} className="mr-2" onClick={handleEnable}>
              Enable
            </Button>
            <Button type="danger" disabled={!enabled} className="mr-2" onClick={handleDisable}>
              Disable
            </Button>
          </div>
          <div className="row mt-3">
            <span className="font-bold">SFTP Host</span>
            <input
              readOnly={!enabled}
              defaultValue={settings.host}
              className="rounded-md border-gray-300 border mt-3 mb-3 p-2 w-full"
              placeholder="SFTP Host: sftp.motoix.com"
            />
          </div>
          <div className="row hidden">
            <span className="font-bold">PORT</span>
            <input
              readOnly={!enabled}
              defaultValue={settings.port}
              className="rounded-md border-gray-300 border mt-3 mb-3 p-2 w-full"
              placeholder="PORT: 22"
            />
          </div>
          <div className="row">
            <span className="font-bold">USERNAME</span>
            <input
              readOnly={!enabled}
              defaultValue={settings.username}
              className="rounded-md border-gray-300 border mt-3 mb-3 p-2 w-full"
              placeholder={`USERNAME: ${dealer.name.replaceAll(' ', '').toLowerCase()}_${dealer.dealer_id}`}
            />
          </div>
          <div className="relative border rounded-xl border-gray hidden">
            <input
              readOnly={!enabled}
              type={showPassword ? 'text' : 'password'}
              defaultValue={settings.password}
              className="w-full box-border rounded-md border-gray-300 border focus:outline-none pr-8 p-2"
              placeholder="PASSWORD: *****"
            />
            {showPassword ? (
              <AiFillEyeInvisible
                onClick={handleChangeShowPassword}
                className={`w-4 h-full cursor-pointer font-semibold absolute top-0 right-2${!enabled ? ' hidden' : ''}`}
              />
            ) : (
              <AiFillEye
                onClick={handleChangeShowPassword}
                className={`w-4 h-full cursor-pointer font-semibold absolute top-0 right-2${!enabled ? ' hidden' : ''}`}
              />
            )}
          </div>
          <div className="row mt-2">
            <Button disabled={!enabled} type="primary" onClick={handleSendCredentials}>
              Send Credentials
            </Button>
            <input
              disabled={!enabled}
              onChange={handleEmailChange}
              defaultValue={dealer.email}
              className="rounded-md border-gray-300 border mt-3 mb-3 p-2 w-full"
              placeholder="Enter Recipient Email Address"
            />
          </div>
        </div>
      ) : (
        <div className="loader mx-auto my-44" />
      )}

      <div className="flex items-end mt-5">
        <a href={carsForSaleTemplateLink} className="cursor-pointer text-primary" download>
          Download template
        </a>
      </div>
    </div>
  )
}

SftpInventoryCredentials.propTypes = {
  dealer: PropTypes.object
}

export default SftpInventoryCredentials
