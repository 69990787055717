import React from 'react'
import { SavedOffers } from 'src/components/AccountDashboard/SavedOffers'

function CustomerSavedOffers() {
  return (
    <div className="container mx-auto mb-12 mt-10 pt-6 rounded-xl border-2">
      <SavedOffers searchText="" isSavedOffersPage />
    </div>
  )
}

export default CustomerSavedOffers
