import { Button, Form, Modal, Table, Tabs } from 'antd'
import 'antd/dist/antd.css'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TiPencil } from 'react-icons/ti'
import * as ClaimServices from 'src/services/claim.service'
import {
  advancedTableColumns,
  largeTableColumns,
  pricingFields,
  smallTableColumns,
  TABLE_TYPES,
  TAB_KEYS
} from './constants'
import './style.scss'

const { TabPane } = Tabs

function isNumeric(str) {
  if (typeof str !== 'string') return false
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str))
}

const generatedPricingRows = (data, tabKey) => {
  const currentTab = TAB_KEYS[tabKey]
  const { pricingTable } = currentTab
  const firstRow = data.find((item) => item.average_vehicles_stock === '<30' && item.type === pricingTable)
  const secondRow = data.find((item) => item.average_vehicles_stock === '31-100' && item.type === pricingTable)
  const thirdRow = data.find((item) => item.average_vehicles_stock === '101-300' && item.type === pricingTable)
  const fourthRow = data.find((item) => item.average_vehicles_stock === '300+' && item.type === pricingTable)
  return [firstRow, secondRow, thirdRow, fourthRow]
}

function PricingTables() {
  const [tableType, setTableType] = useState(TABLE_TYPES.LARGE_TABLE)
  const [tabKey, setTabKey] = useState(TAB_KEYS.BASIC_INVENTORY_LISTING_TABLE.id)
  const [averageLabel, setAverageLabel] = useState('Basic Inventory Listing')
  const [form] = Form.useForm()
  const [pricingTableData, setPricingTableData] = useState([])
  const [tableData, setTableData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})

  const handleShowModal = (record) => {
    const {
      pricing_table: { member, none }
    } = record
    const selectedRow = {
      id: record._id,
      average_vehicles_stock: record.average_vehicles_stock,
      member_setUp: member.setUp || '',
      member_2_month: member['month to Month'] || '',
      member_12_month: member['12'] || '',
      member_24_month: member['24'] || '',
      member_36_month: member['36'] || '',
      member_app_month_to_month: member['App month to Month'] || '',
      member_per_month_to_month: member['Per month to Month'] || '',
      none_member_setUp: none.setUp || '',
      none_member_2_month: none['month to Month'] || '',
      none_member_12_month: none['12'] || '',
      none_member_24_month: none['24'] || '',
      none_member_36_month: none['36'] || '',
      none_member_app_month_to_month: member['App month to Month'] || '',
      none_member_per_month_to_month: member['Per month to Month'] || ''
    }
    form.setFieldsValue(selectedRow)
    setSelectedRow(selectedRow)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    render: (text, record) => (
      <Button
        key={record?.id}
        onClick={() => handleShowModal(record)}
        icon={<TiPencil className="mx-auto" />}
        size="large"
        type="text"
        className="hover:bg-blue-500 hover:text-white flex items-center justify-center rounded-xl"
      />
    )
  }

  const getPricingTableData = useCallback(() => {
    ClaimServices.getPricingTable()
      .then((response) => {
        const data = generatedPricingRows(response.data, tabKey)
        setTableData(data)
        setPricingTableData(response.data)
      })
      .catch((error) => console.error(error))
  }, [tabKey])

  useEffect(() => {
    ClaimServices.getPricingTable()
      .then((response) => {
        const data = generatedPricingRows(response.data, TAB_KEYS.BASIC_INVENTORY_LISTING_TABLE.id)
        setTableData(data)
        setPricingTableData(response.data)
      })
      .catch((error) => console.error(error))
  }, [])

  const handleFormSubmission = (values) => {
    let payload
    if (tableType === TABLE_TYPES.ADVANCED_TABLE) {
      payload = {
        none: {
          setUp: !isNumeric(values.none_member_setUp)
            ? values.none_member_setUp
            : parseInt(values.none_member_setUp, 10),
          'App month to Month': !isNumeric(values.none_member_app_month_to_month)
            ? values.none_member_app_month_to_month
            : parseInt(values.none_member_app_month_to_month, 10),
          'Per month to Month': !isNumeric(values.none_member_per_month_to_month)
            ? values.none_member_per_month_to_month
            : parseInt(values.none_member_per_month_to_month, 10)
        },
        member: {
          setUp: !isNumeric(values.member_setUp) ? values.member_setUp : parseInt(values.member_setUp, 10),
          'App month to Month': !isNumeric(values.member_app_month_to_month)
            ? values.member_app_month_to_month
            : parseInt(values.member_app_month_to_month, 10),
          'Per month to Month': !isNumeric(values.member_per_month_to_month)
            ? values.member_per_month_to_month
            : parseInt(values.member_per_month_to_month, 10)
        }
      }
    } else if (tableType === TABLE_TYPES.SMALL_TABLE) {
      payload = {
        none: {
          setUp: !isNumeric(values.none_member_setUp)
            ? values.none_member_setUp
            : parseInt(values.none_member_setUp, 10),
          'month to Month': !isNumeric(values.none_member_2_month)
            ? values.none_member_2_month
            : parseInt(values.none_member_2_month, 10)
        },
        member: {
          setUp: !isNumeric(values.member_setUp) ? values.member_setUp : parseInt(values.member_setUp, 10),
          'month to Month': !isNumeric(values.member_2_month)
            ? values.member_2_month
            : parseInt(values.member_2_month, 10)
        }
      }
    } else {
      payload = {
        none: {
          setUp: !isNumeric(values.none_member_setUp)
            ? values.none_member_setUp
            : parseInt(values.none_member_setUp, 10),
          'month to Month': !isNumeric(values.none_member_2_month)
            ? values.none_member_2_month
            : parseInt(values.none_member_2_month, 10),
          12: !isNumeric(values.none_member_12_month)
            ? values.none_member_12_month
            : parseInt(values.none_member_12_month, 10),
          24: !isNumeric(values.none_member_24_month)
            ? values.none_member_24_month
            : parseInt(values.none_member_24_month, 10),
          36: !isNumeric(values.none_member_36_month)
            ? values.none_member_36_month
            : parseInt(values.none_member_36_month, 10)
        },
        member: {
          setUp: !isNumeric(values.member_setUp) ? values.member_setUp : parseInt(values.member_setUp, 10),
          'month to Month': !isNumeric(values.member_2_month)
            ? values.member_2_month
            : parseInt(values.member_2_month, 10),
          12: !isNumeric(values.member_12_month) ? values.member_12_month : parseInt(values.member_12_month, 10),
          24: !isNumeric(values.member_24_month) ? values.member_24_month : parseInt(values.member_24_month, 10),
          36: !isNumeric(values.member_36_month) ? values.member_36_month : parseInt(values.member_36_month, 10)
        }
      }
    }
    ClaimServices.updatePricing(selectedRow.id, payload)
      .then(() => {
        getPricingTableData()
        handleCloseModal()
      })
      .catch((error) => {
        console.error(error)
        handleCloseModal()
      })
  }

  const onFormChange = (e) => {
    form.setFieldsValue({ ...form.getFieldValue(), [e.target.name]: e.target.value })
  }

  const onChangeTabKey = (key) => {
    const currentTab = TAB_KEYS[key]
    const { tableType, name } = currentTab
    const tableData = generatedPricingRows(pricingTableData, key)
    setTableData(tableData)
    setTableType(tableType)
    setTabKey(key)
    setAverageLabel(name)
  }

  const formFields = useMemo(() => {
    return pricingFields({ tableType, averageLabel }).filter((item) => item.visible)
  }, [tableType, averageLabel])

  const tableColumns = useMemo(() => {
    switch (tableType) {
      case TABLE_TYPES.SMALL_TABLE:
        return smallTableColumns(averageLabel)
      case TABLE_TYPES.LARGE_TABLE:
        return largeTableColumns(averageLabel)
      default:
        return advancedTableColumns
    }
  }, [tableType, averageLabel])

  return (
    <>
      <Tabs className="pricing-tables" defaultActiveKey={tabKey} activeKey={tabKey} tabPosition="top" onChange={onChangeTabKey} renderTabBar={(props, TabNavList) => (
        <TabNavList {...props} mobile />
      )}>
        {Object.keys(TAB_KEYS).map((key) => (
          <TabPane tab={TAB_KEYS[key].name} key={TAB_KEYS[key].id}>
            <Table
              columns={[...tableColumns, actionColumn]}
              className="mt-5"
              dataSource={tableData}
              scroll={{ x: 'max-content' }}
            />
          </TabPane>
        ))}
      </Tabs>
      <Modal
        visible={showModal}
        title="Edit Pricing"
        footer={null}
        style={{ top: 20 }}
        onCancel={handleCloseModal}
        className="w-full"
        dataSource={selectedRow}
      >
        <Form form={form} layout="vertical" name="vehicle-ref" onFinish={handleFormSubmission} onChange={onFormChange}>
          {formFields.map((item, index) => {
            return (
              <div key={item.name}>
                <p className="text-base font-semibold">{item.label}</p>
                <Form.Item
                  name={item.name}
                  hasFeedback
                  rules={[
                    {
                      required: item.required,
                      message: `${item.label} is required`
                    }
                  ]}
                  className="custom-antd-label flex"
                >
                  <input
                    // defaultValue={selectedRow[item.name]}
                    placeholder={item.label}
                    name={item.name}
                    autoFocus={index === 1}
                    className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
                    // type={item.type}
                    disabled={item.disabled}
                  />
                </Form.Item>
              </div>
            )
          })}

          <Form.Item className="flex items-end w-full mt-5 submit" shouldUpdate>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                className="w-48 px-6 py-5 h-12 rounded-lg flex justify-center items-center gap-2 text-base font-semibold text-white bg-blue-700"
              >
                Update
              </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default PricingTables
