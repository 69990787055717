import { Button } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { BsPrinterFill } from 'react-icons/bs'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import * as CreditApplicationForm from 'src/services/creditApplicationForm.service'
import * as CustomerServices from 'src/services/customer.service'

function CreditApplicationDetailSharedByCustomer() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const creidtApplicationId = searchParams.get('credit_application_id')
  const linkCode = searchParams.get('code')
  const [verifying, setVerifying] = useState(true)
  const [creditApplicationData, setCreditApplicationData] = useState({})

  const creditApplicationRef = useRef()
  const handlePrintCrediApplication = useReactToPrint({
    content: () => creditApplicationRef.current
  })

  useEffect(() => {
    if (linkCode) {
      CustomerServices.checkValidLink(linkCode)
        .catch(() => {
          navigate('/link-expired')
        })
        .finally(() => setVerifying(false))
    }
  }, [linkCode])

  useEffect(() => {
    if (!verifying && creidtApplicationId) {
      CreditApplicationForm.getCreditApplicationFormById(creidtApplicationId)
        .then((response) => {
          setCreditApplicationData(response.data)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [creidtApplicationId, verifying])

  if (verifying) {
    return (
      <div style={{ fontSize: '32px' }} className="verification-expired-table">
        Verifying...
      </div>
    )
  }

  return (
    <div className="container md:w-full lg:w-3/4 xl:w-1/2 px-5 py-32 flex gap-10 flex-col">
      <div ref={creditApplicationRef} className="px-10 py-10">
        <div className="text-2xl text-center">Credit application detail</div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {creditApplicationData && creditApplicationData?.applicant_section ? (
            <div className="">
              <div className="group  mb-2">
                <h2 className="text-xl">Applicant Information</h2>
                <div className="">
                  <div className="flex gap-x-1">
                    <b>First Name:</b>
                    <p>{creditApplicationData?.applicant_section?.first_name}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Middle Name:</b>
                    <p>{creditApplicationData?.applicant_section?.middle_name}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Last Name:</b>
                    <p>{creditApplicationData?.applicant_section?.last_name}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Email:</b>
                    <p>{creditApplicationData?.applicant_section?.email}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Date of Birth:</b>
                    <p>{creditApplicationData?.applicant_section?.date_of_birth}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Social Security Number:</b>
                    <p>{creditApplicationData?.applicant_section?.social_security_number}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Mobile Number:</b>
                    <p>{creditApplicationData?.applicant_section?.mobile_number}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Marital Status:</b>
                    <p>{creditApplicationData?.applicant_section?.marital_status}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Citizenship Status:</b>
                    <p>{creditApplicationData?.applicant_section?.citizenship_status}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {creditApplicationData && creditApplicationData?.address_section ? (
            <div className="">
              <div className="group  mb-2">
                <h2 className="text-xl">Address Information</h2>
                <div className="">
                  <div className="flex gap-x-1">
                    <b>Street Address:</b>
                    <p>{creditApplicationData?.address_section?.street_address}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Zip Code:</b>
                    <p>{creditApplicationData?.address_section?.zip_code}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>City:</b>
                    <p>{creditApplicationData?.address_section?.city}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>State:</b>
                    <p>{creditApplicationData?.address_section?.state}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>County:</b>
                    <p>{creditApplicationData?.address_section?.county}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Years At Address:</b>
                    <p>{creditApplicationData?.address_section?.years_at_address}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Months At Address:</b>
                    <p>{creditApplicationData?.address_section?.months_at_address}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Residential Status:</b>
                    <p>{creditApplicationData?.address_section?.residential_status}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Mortgage Lender Name:</b>
                    <p>{creditApplicationData?.address_section?.mortgage_lender_name}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Monthly Mortgage:</b>
                    <p>{creditApplicationData?.address_section?.monthly_mortgage}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Landlord Name:</b>
                    <p>{creditApplicationData?.address_section?.landlord_name}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Monthly Rent:</b>
                    <p>{creditApplicationData?.address_section?.monthly_rent}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {creditApplicationData && creditApplicationData?.job_section ? (
            <div className="">
              <div className="group  mb-2">
                <h2 className="text-xl">Job Information</h2>
                <div className="">
                  <div className="flex gap-x-1">
                    <b>Employer Name:</b>
                    <p>{creditApplicationData?.job_section?.employer_name}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Job Status:</b>
                    <p>{creditApplicationData?.job_section?.job_status}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Annual Gross Income:</b>
                    <p>{creditApplicationData?.job_section?.annual_gross_income}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Annual Net Income:</b>
                    <p>{creditApplicationData?.job_section?.annual_net_income}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Work Phone:</b>
                    <p>{creditApplicationData?.job_section?.work_phone}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Job Title:</b>
                    <p>{creditApplicationData?.job_section?.job_title}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Street Address:</b>
                    <p>{creditApplicationData?.job_section?.street_address}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Zip Code:</b>
                    <p>{creditApplicationData?.job_section?.zip_code}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>City:</b>
                    <p>{creditApplicationData?.job_section?.city}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>State:</b>
                    <p>{creditApplicationData?.job_section?.state}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>County:</b>
                    <p>{creditApplicationData?.job_section?.county}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Years At Job:</b>
                    <p>{creditApplicationData?.job_section?.years_at_job}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Months At Job:</b>
                    <p>{creditApplicationData?.job_section?.months_at_job}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Previous Home Address:</b>
                    <p>{creditApplicationData?.job_section?.previous_home_address}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Previous Job:</b>
                    <p>{creditApplicationData?.job_section?.previous_job}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Job Type:</b>
                    <p>{creditApplicationData?.job_section?.job_type}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {creditApplicationData && creditApplicationData?.license_section ? (
            <div className="">
              <div className="group  mb-2">
                <h2 className="text-xl">License Information</h2>
                <div className="">
                  <div className="flex gap-x-1">
                    <b>License Number:</b>
                    <p>{creditApplicationData?.license_section?.license_number}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>License State:</b>
                    <p>{creditApplicationData?.license_section?.license_state}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>License Issued Date:</b>
                    <p>{creditApplicationData?.license_section?.license_issued_date}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>License Expiry Date:</b>
                    <p>{creditApplicationData?.license_section?.license_expiry_date}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {creditApplicationData && creditApplicationData?.income_section ? (
            <div className="">
              <div className="group  mb-2">
                <h2 className="text-xl">Income Information</h2>
                <div className="">
                  <div className="flex gap-x-1">
                    <b>Additional Annual Income:</b>
                    <p>{creditApplicationData?.income_section?.additional_annual_income}</p>
                  </div>
                  <div className="flex gap-x-1">
                    <b>Additional Income Source:</b>
                    <p>{creditApplicationData?.income_section?.additional_income_source}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="px-10 flex justify-end">
        <Button
          className="flex gap-2 justify-center items-center font-semibold text-md rounded-lg p-4"
          icon={<BsPrinterFill className="text-md" />}
          type="primary"
          onClick={handlePrintCrediApplication}
        >
          Print
        </Button>
      </div>
    </div>
  )
}

export default CreditApplicationDetailSharedByCustomer
