import { Select, Table } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import * as CustomerServices from 'src/services/customer.service'
import { SearchDashboard } from '../AccountDashboard/SearchDashboard'
import { MyTradeIn } from './MyTradeIn'
import './style.scss'

const columns = [
  {
    title: 'DATE',
    dataIndex: 'date'
  },
  {
    title: 'DEALERSHIP',
    dataIndex: 'dealership'
  },
  {
    title: 'YEAR',
    dataIndex: 'year'
  },
  {
    title: 'MAKE',
    dataIndex: 'make'
  },
  {
    title: 'MODEL',
    dataIndex: 'model'
  },
  {
    title: 'BODY TYPE',
    dataIndex: 'body_type'
  },
  {
    title: 'SALE PRICE',
    dataIndex: 'price',
    render: (price) => (price ? `$${price.toLocaleString()}` : '_')
  },
  {
    title: 'FINANCING',
    dataIndex: 'financing'
  }
]

const options = [
  {
    label: 'Date',
    value: 'date_of_offer'
  },
  {
    label: 'Dealership',
    value: 'dealership'
  },
  {
    label: 'Year',
    value: 'year'
  },
  {
    label: 'Make',
    value: 'make'
  },
  {
    label: 'Model',
    value: 'model'
  },
  {
    label: 'Body Type',
    value: 'body_type'
  },
  {
    label: 'Sale price',
    value: 'price'
  },
  {
    label: 'Financing',
    value: 'financing'
  }
]
const getCustomStyles = () => ({
  option: (provided, state) => ({
    ...provided,
    border: '1px solid #f2f0f0',
    borderRadius: '5%',
    color: state.isSelected ? 'white' : 'blue'
  }),
  control: (provided, state) => ({
    ...provided,
    border: '1px solid rgb(140, 139, 139)',
    borderRadius: '5%',
    backgroundColor: state.isDisabled ? '#dfdfdf' : '#ffffff'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms'
  })
})

function SavedOffersTable({
  setRowData,
  startStep,
  selectedTrade,
  setSelectedTrade,
  vehicleId,
  selectedOffer,
  setValueYourVehicleStepStatus
}) {
  const { customerLogin } = useAuthorizationContext()
  const customStyles = getCustomStyles()
  const [initialData, setInitialData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [sortBy, setSortBy] = useState(options[4].value)
  const previousOfferRef = useRef([])
  const navigate = useNavigate()

  const handleChangeSortBy = (value) => {
    setSortBy(value)
  }

  useEffect(() => {
    if (searchText !== '') {
      const filteredData = previousOfferRef.current.filter(
        (o) =>
          o.dealership?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          o.financing?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          o.vehicleName?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          o.body_type?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          o.make?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          o.model?.toLowerCase()?.includes(searchText.toLowerCase())
      )
      if (filteredData) {
        setInitialData(filteredData)
      } else {
        setInitialData([])
      }
    } else {
      setInitialData(previousOfferRef.current)
    }
  }, [searchText])

  useEffect(() => {
    if (customerLogin) {
      CustomerServices.getCustomerOffers({ sort: sortBy })
        .then((response) => {
          const data = response.data
            ?.filter((e) => e.vehicle)
            ?.map((item) => {
              const { vehicle, finance, date, dealerName } = item
              const { year, make, model, price } = vehicle || { year: '', make: '', model: '', price: 0 }
              const { frequency } = finance
              return {
                year,
                make,
                model,
                date,
                price,
                dealership: dealerName,
                financing: frequency ? 'Yes' : 'No',
                body_type: vehicle?.body
              }
            })
          setInitialData(data)
          previousOfferRef.current = data
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [customerLogin, sortBy])

  const handleNavigateSavedOffers = () => {
    navigate('/saved-offers')
  }
  return (
    <div className="lg:flex m-4 w-full">
      <div className="mr-8">
        <SearchDashboard onSearch={(value) => setSearchText(value)} />
        <div className="flex lg:flex-row flex-col">
          <div className="font-semibold text-xl p-5">Saved offers</div>
          <div className="flex lg:flex-row lg:items-center flex-col items-start gap-4 xl:pr-7 md:pr-5 sm:pr-4">
            <div className="lg:ml-5 flex items-center gap-3 col-span-2 justify-end">
              <span className="select-sortby-dashboard text-xl">Sort by: </span>
              <Select
                name="sortBy"
                placeholder="Sort by"
                value={sortBy}
                onChange={handleChangeSortBy}
                styles={customStyles}
                options={options}
                className="h-full w-32"
              />
            </div>
            <div className="text-primary cursor-pointer font-bold" onClick={handleNavigateSavedOffers}>
              Saved offers &nbsp; &#62;
            </div>
          </div>
        </div>
        <Table
          scroll={{ x: 875 }}
          columns={columns}
          dataSource={initialData}
          onRow={(record) => {
            return {
              onClick: () => {
                setRowData(record)
              }
            }
          }}
          className="cursor-pointer"
        />
      </div>
      <MyTradeIn
        handleShowValueYourVehicle={startStep}
        selectedTrade={selectedTrade}
        setSelectedTrade={setSelectedTrade}
        vehicleId={vehicleId}
        selectedOffer={selectedOffer}
        setValueYourVehicleStepStatus={setValueYourVehicleStepStatus}
      />
    </div>
  )
}

SavedOffersTable.propTypes = {
  setRowData: PropTypes.func,
  startStep: PropTypes.func,
  selectedTrade: PropTypes.object,
  setSelectedTrade: PropTypes.func,
  vehicleId: PropTypes.string,
  selectedOffer: PropTypes.object,
  setValueYourVehicleStepStatus: PropTypes.func
}

export default SavedOffersTable
