import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import React from 'react'
import { Line } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      display: true
    },
    title: {
      display: true,
      position: 'left',
      text: 'Engagement this month'
    }
  }
}

const labels = () => {
  const result = []
  let start = 1
  while (start <= 30) {
    result.push(start.toString())
    start += 1
  }
  return result
}

export const data = {
  labels: labels(),
  datasets: [
    {
      label: 'ClickByRide',
      data: [],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
      tension: 0.2
    },
    {
      label: 'Total',
      data: [],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
      tension: 0.2
    }
  ]
}

export function EngagementStatistic() {
  return (
    <div className="rounded-xl border-2 p-4 flex flex-col justify-between overflow-x-auto">
      <div className="grid grid-cols-1 xl:flex lg:flex justify-between mb-5 md:grid-cols-2 sm:grid-cols-1 gap-0 xl:gap-6 lg:gap-5 md:gap-4 sm:gap-0">
        <div className="text-xl font-semibold">Engagements</div>
        <div className="flex">
          <div className="border-r px-5">
            <p className="font-semibold">0</p>
            <p>TOTAL</p>
          </div>
          <div className="px-5 text-center">
            <p className="font-semibold">0</p>
            <p>Call</p>
          </div>
          <div className="pr-5 text-center">
            <p className="font-semibold">0</p>
            <p>Chat</p>
          </div>
          <div className="pr-5 text-center">
            <p className="font-semibold">0</p>
            <p>Form</p>
          </div>
          <div className="pr-5 text-center">
            <p className="font-semibold">0</p>
            <p>Credit App</p>
          </div>
          <div className="pr-5 text-center">
            <p className="font-semibold">0</p>
            <p>Trade</p>
          </div>
        </div>
      </div>
      <Line options={options} data={data} />
    </div>
  )
}
