import { CloseOutlined } from '@ant-design/icons'
import { Button, Modal, Tabs, notification } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthPopup from 'src/components/Vehicle/BoxButtonGroup/AuthPopup'
import LoginFormModal from 'src/components/Vehicle/BoxButtonGroup/LoginFormModal'
import SignUpFormModal from 'src/components/Vehicle/BoxButtonGroup/SignUpFormModal'
import * as CustomerServices from 'src/services/customer.service'
import { useAuthorizationContext } from '../../../AuthorizationProvider'
import { useVehicleInformationContext } from '../../../VehicleProvider'
import { getCreditScoreRanges } from '../../../services/credit.service'
import CashBox from '../CashBox'
import FinanceBox from '../FinanceBox'
import VehicleFinanceMobileModal from '../VehicleFinanceMobileModal'
import VehicleFinanceModal from '../VehicleFinanceModal'
import './style.scss'

const { TabPane } = Tabs

function FinanceCashBox({ info, hasSetting700 }) {
  const { customerLogin, accountSettings } = useAuthorizationContext()
  const { setFinanceDetail } = useVehicleInformationContext()
  const { vehicle } = info || {}
  const vehiclePrice = vehicle?.price || 0
  const isMobile = window.innerWidth <= 1024
  const [openBreakdown, setOpenBreakdown] = useState(false)
  const [activeKey, setActiveKey] = useState('finance')

  const hideOnProduction = process.env.REACT_APP_HIDE_ON_PRODUCTION === 'true'

  const [creditScorePositionRanges, setCreditScorePositionRanges] = useState([])
  const [loans, setLoans] = useState(36)
  const [apr, setApr] = useState(25)
  const [initStep, setInitStep] = useState(-1)

  const [isCalculateTaxesModalVisible, setIsCalculateTaxesModalVisible] = useState(false)
  const [finance] = useState(331)

  const [calculation, setCalculation] = useState({})

  const getCalculation = (data) => {
    setCalculation(data)
  }

  const getCreditScorePosition = () => {
    let creditScorePositionValue = 5
    if (customerLogin && accountSettings.credit_score_position !== undefined) {
      creditScorePositionValue = accountSettings.credit_score_position
    }
    return creditScorePositionValue
  }
  const [creditScorePosition, setCreditScorePosition] = useState(getCreditScorePosition)

  const getCashDown = () => {
    let cashDownValue = Math.round(vehiclePrice * 0.2)
    if (customerLogin && accountSettings.cash_down !== undefined && accountSettings.cash_down > 0) {
      cashDownValue = accountSettings.cash_down
    }
    return cashDownValue
  }
  const [cashDown, setCashDown] = useState(getCashDown)

  const handleCancel = () => {
    setIsCalculateTaxesModalVisible(false)
  }

  useEffect(() => {
    const dealerId = vehicle?.dealer_id
    getCreditScoreRanges(dealerId).then((result) => {
      setCreditScorePositionRanges(result.data)
      result.data.forEach((item) => {
        if (item.position === creditScorePosition) {
          const rangeName = `rate_${loans}`
          const tempApr = item[rangeName]
          setApr(tempApr)
        }
      })
    })

    if (isCalculateTaxesModalVisible) {
      setFinanceDetail({
        fee: 1500,
        payment: finance,
        terms: loans,
        apr
      })
    }
  }, [isCalculateTaxesModalVisible, finance, loans, apr])

  const vehicleFinanceModalHeader = (
    <>
      <div className="lg:flex lg:items-end">
        <p className="text-black font-bold text-2xl mr-1">Speed Up Your Purchase.</p>
        <p className="text-xl font-medium">Car-buying made fast, easy, transparent.</p>
      </div>
      <Button
        shape="circle"
        icon={<CloseOutlined />}
        className="absolute right-4 top-3 -translate-y-2/4 bg-whiteTransparent2 z-30"
        onClick={handleCancel}
      />
      <div className="absolute bottom-0 right-12 px-4 py-0 sm:mt-10 bg-darkWhite border-t border-l border-r how-border">
        <p className="text-xs text-primary">How it works</p>
      </div>
    </>
  )

  const navigate = useNavigate()
  const [addedOffer, setAddedOffer] = useState(false)
  const [showPopupConfirm, setShowPopupConfirm] = useState(false)
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [showSignUpForm, setShowSignUpForm] = useState(false)
  const [, setLoginRequired] = useState(false)
  const [, setShowCashDownPopupConfirm] = useState(false)

  const getCustomerVehicleOffer = useCallback(() => {
    CustomerServices.getCustomerOfferByVehicleId(info._id)
      .then(() => {
        setAddedOffer(true)
      })
      .catch((error) => {
        setAddedOffer(false)
        console.error(error)
      })
  })

  useEffect(() => {
    if (customerLogin) {
      getCustomerVehicleOffer()
    }
  }, [isCalculateTaxesModalVisible, finance, loans, apr])

  const openNotification = (type, msg) => {
    const action = type === 'success' ? notification.success : notification.error
    action({
      message: msg,
      placement: 'topRight'
    })
  }

  const handleShowLoginForm = () => {
    setShowPopupConfirm(false)
    setShowLoginForm(true)
    setLoginRequired(false)
  }

  const handleShowSignUpForm = () => {
    setShowPopupConfirm(false)
    setShowSignUpForm(true)
    setLoginRequired(false)
  }

  const callbackForForm = () => {
    getCustomerVehicleOffer()
  }

  const goToCustomerDashboard = () => {
    navigate('/customer-dashboard')
  }

  const handleNotAddedOffer = () => {
    if (customerLogin) {
      openNotification('error', 'You do not have an Ez-Deal for this vehicle.')
    } else {
      setShowPopupConfirm(true)
    }
  }

  return (
    <>
      {!hideOnProduction && (
        <div className="bg-light rounded-lg p-3 mb-6">
          <p className="font-bold text-center mb-1">Already Submitted a Deal?</p>
          <p className="text-center text-gray mb-1">Load your progress on any device.</p>
          <p
            className="text-primary text-center fontSize11 font-semibold cursor-pointer"
            onClick={addedOffer ? goToCustomerDashboard : handleNotAddedOffer}
          >
            Lookup My Deal
          </p>
        </div>
      )}
      <Tabs className="finance-cash-tabs" type="card" onChange={(key) => setActiveKey(key)} size="large">
        <TabPane
          tab={
            <>
              <p className="leading-2 font-semibold text-center">Finance</p>
              {/* <p className="text-primary text-center text-base font-semibold leading-2">${finance}/mo*</p> */}
            </>
          }
          key="finance"
        >
          <FinanceBox
            info={info}
            setIsCalculateTaxesModalVisible={setIsCalculateTaxesModalVisible}
            isCalculateTaxesModalVisible={isCalculateTaxesModalVisible}
            openBreakdown={openBreakdown}
            setOpenBreakdown={setOpenBreakdown}
            creditScorePositionRanges={creditScorePositionRanges}
            creditScorePosition={creditScorePosition}
            setCreditScorePosition={setCreditScorePosition}
            cashDown={cashDown}
            setCashDown={setCashDown}
            loans={loans}
            setLoans={setLoans}
            setInitStep={setInitStep}
            apr={apr}
            setApr={setApr}
            hasSetting700={hasSetting700}
            getCalculation={getCalculation}
          />
        </TabPane>
        <TabPane
          tab={
            <>
              <p className="leading-2 text-center">Cash</p>
              <p className="text-primary text-center text-base font-semibold leading-2">or Use My Bank</p>
            </>
          }
          key="cash"
        >
          <CashBox
            setIsCalculateTaxesModalVisible={setIsCalculateTaxesModalVisible}
            isCalculateTaxesModalVisible={isCalculateTaxesModalVisible}
            info={vehicle}
            openBreakdown={openBreakdown}
            setOpenBreakdown={setOpenBreakdown}
            setInitStep={setInitStep}
            loans={loans}
            getCalculation={getCalculation}
          />
        </TabPane>
      </Tabs>
      <Modal
        title={vehicleFinanceModalHeader}
        visible={isCalculateTaxesModalVisible}
        footer={null}
        style={{ top: 40 }}
        onCancel={handleCancel}
        width="95%"
        closable={false}
        destroyOnClose
        wrapClassName="vehicle-finance-modal"
      >
        <div className={!isMobile ? 'hidden' : ''}>
          <VehicleFinanceMobileModal
            info={vehicle}
            initStep={initStep}
            setInitStep={setInitStep}
            type={activeKey}
            cashDown={cashDown}
            calculation={calculation}
            handleCancel={handleCancel}
          />
        </div>
        <div className={isMobile ? 'hidden' : ''}>
          <VehicleFinanceModal
            initStep={initStep}
            setInitStep={setInitStep}
            type={activeKey}
            info={vehicle}
            cashDown={cashDown}
            calculation={calculation}
            handleCancel={handleCancel}
          />
        </div>
      </Modal>
      <AuthPopup
        show={showPopupConfirm}
        setShow={setShowPopupConfirm}
        handleShowLoginForm={handleShowLoginForm}
        handleShowSignUpForm={handleShowSignUpForm}
        setLoginRequired={setLoginRequired}
      />
      <LoginFormModal
        showModal={showLoginForm}
        setShowModal={setShowLoginForm}
        callback={callbackForForm}
        setShowCashDownPopupConfirm={setShowCashDownPopupConfirm}
      />
      <SignUpFormModal
        showModal={showSignUpForm}
        setShowModal={setShowSignUpForm}
        callback={callbackForForm}
        setShowCashDownPopupConfirm={setShowCashDownPopupConfirm}
      />
    </>
  )
}

FinanceCashBox.propTypes = {
  info: PropTypes.object.isRequired,
  hasSetting700: PropTypes.bool
}

export default FinanceCashBox
