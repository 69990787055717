import { Button, Table } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { AiFillCar } from 'react-icons/ai'
import * as DealerService from '../../../services/dealer.service'
import './style.scss'

export function ExpandedRow({ record, isDealer, handleNavigateVehicle }) {
  const [leads, setLeads] = useState([])
  const [expandedId, setExpandedId] = useState(0)

  useEffect(() => {
    let id = record._id
    let action = DealerService.getDealerLeads
    if (isDealer) {
      id = record.employee_id ? record.employee_id : record.id
      action = DealerService.getDealerUserLeads
    }

    if (expandedId !== id) {
      setExpandedId(id)
      action(id).then((response) => {
        setLeads(response.data.rows)
      })
    }
  })

  const columns = [
    { title: 'LEADS', dataIndex: 'purchasedLeads', key: 'purchasedLeads' },
    { title: 'CUSTOMER NAME', dataIndex: 'name', key: 'name' },
    { title: 'CUSTOMER EMAIL', dataIndex: 'email', key: 'email' },
    { title: 'CUSTOMER PHONE', dataIndex: 'phone', key: 'phone' },
    {
      title: 'VEHICLE PRICE',
      dataIndex: 'price',
      key: 'price',
      render: (price) => (price ? `$${price.toLocaleString()}` : '-')
    },
    { title: 'ZIP CODE', dataIndex: 'zipcode', key: 'zipcode', render: (zipcode) => (zipcode ? `${zipcode}` : '-') },
    { title: 'LEAD TYPE', dataIndex: 'leadType', key: 'leadType' },
    { title: 'DATE & TIME', dataIndex: 'dateTime', key: 'dateTime' },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Button
          onClick={() => handleNavigateVehicle(record)}
          icon={<AiFillCar />}
          size="large"
          type="text"
          className="hover:bg-primary hover:text-white flex items-center justify-center rounded-xl"
        />
      )
    }
  ]

  return (
    <div>
      <Table className="py-8" id="details" columns={columns} dataSource={leads} pagination={false} />
    </div>
  )
}

ExpandedRow.propTypes = {
  record: PropTypes.object,
  isDealer: PropTypes.bool,
  handleNavigateVehicle: PropTypes.func
}
