import { Button, Form, Input, InputNumber, Modal, notification, Popconfirm, Table, Typography } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import * as AdminService from '../../services/admin.service'
import AddDeleteTableRows from '../../components/AddDeleteTableRows'
import RateAdjustmentForScoreAndAgeOfVehicle from '../../components/DealerDashboard/RateAdjustmentForScoreAndAgeOfVehicle'
import CreditScoreVehicleYearModelTermInterestRate from '../../components/DealerDashboard/CreditScoreVehicleYearModelTermInterestRate'
import './style.scss'

const tableYearsColumns = [
  {
    title: 'Rate 36',
    dataIndex: 'rate_year_36',
    key: 'rate_year_36',
    render: (value) => <div className="text-center">{`-${value}`}</div>
  },
  {
    title: 'Rate 48',
    dataIndex: 'rate_year_48',
    key: 'rate_year_48',
    render: (value) => <div className="text-center">{`-${value}`}</div>
  },
  {
    title: 'Rate 60',
    dataIndex: 'rate_year_60',
    key: 'rate_year_60',
    render: (value) => <div className="text-center">{`-${value}`}</div>
  },
  {
    title: 'Rate 72',
    dataIndex: 'rate_year_72',
    key: 'rate_year_72',
    render: (value) => <div className="text-center">{`-${value}`}</div>
  }
]

const formYearsFields = [
  {
    label: 'Rate 36',
    name: 'rate_year_36',
    required: true,
    type: 'number',
    prefix: '-'
  },
  {
    label: 'Rate 48',
    name: 'rate_year_48',
    required: true,
    type: 'number',
    prefix: '-'
  },
  {
    label: 'Rate 60',
    name: 'rate_year_60',
    required: true,
    type: 'number',
    prefix: '-'
  },
  {
    label: 'Rate 72',
    name: 'rate_year_72',
    required: true,
    type: 'number',
    prefix: '-'
  }
]

const tableColumns = ({ handleFormSubmission, cancel, edit, editingKey }) => [
  {
    title: 'Score Range',
    dataIndex: 'scoreRange2',
    key: 'scoreRange2',
    colSpan: 2,
    width: 150,
    render: (value) => (value > 0 ? value : 'Below')
  },
  {
    title: 'Score Range',
    dataIndex: 'scoreRange1',
    key: 'scoreRange1',
    colSpan: 0,
    width: 150
  },
  {
    title: 'Estimated Rate 36',
    dataIndex: 'estimatedRate36',
    key: 'estimatedRate36',
    inputType: 'number',
    render: (value) => <div className="text-center">{`${value.toFixed(2)}%`}</div>
  },
  {
    title: 'Estimated Rate 48',
    dataIndex: 'estimatedRate48',
    key: 'estimatedRate48',
    inputType: 'number',
    render: (value) => <div className="text-center">{`${value.toFixed(2)}%`}</div>
  },
  {
    title: 'Estimated Rate 60',
    dataIndex: 'estimatedRate60',
    key: 'estimatedRate60',
    inputType: 'number',
    render: (value) => <div className="text-center">{`${value.toFixed(2)}%`}</div>
  },
  {
    title: 'Estimated Rate 72',
    dataIndex: 'estimatedRate72',
    key: 'estimatedRate72',
    inputType: 'number',
    render: (value) => <div className="text-center">{`${value.toFixed(2)}%`}</div>
  },
  {
    title: '',
    dataIndex: 'operation',
    disabled: true,
    width: 140,
    render: (_, record) => {
      const isEditing = (record) => record._id === editingKey
      const editable = isEditing(record)
      return editable ? (
        <div className="flex gap-2">
          <Typography.Link
            onClick={handleFormSubmission}
            style={{
              marginRight: 8
            }}
          >
            Save
          </Typography.Link>
          <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
            <div>Cancel</div>
          </Popconfirm>
        </div>
      ) : (
        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
          Edit
        </Typography.Link>
      )
    }
  }
]

function CustomErrorRule({ title, dataIndex, form }) {
  return (
    <div className="flex flex-col gap-1">
      <div>Are you sure you want to</div>
      <div>{`set ${title} months greater than 30%?`}</div>
      <div className="flex justify-center gap-2">
        <Button
          type="primary"
          onClick={() => {
            form.setFields([
              {
                name: dataIndex,
                value: form.getFieldValue(dataIndex),
                errors: []
              }
            ])
          }}
        >
          Yes
        </Button>
        <Button
          type="default"
          onClick={() => {
            form.setFieldsValue({ ...form.getFieldValue(), [dataIndex]: null })
            form.validateFields([dataIndex], { force: true })
          }}
        >
          No
        </Button>
      </div>
    </div>
  )
}

CustomErrorRule.propTypes = {
  title: PropTypes.string,
  dataIndex: PropTypes.string,
  form: PropTypes.object
}

function EditableCell({ editing, dataIndex, title, inputType, record, children, form, ...restProps }) {
  const inputNode = inputType === 'number' ? <InputNumber min={0} /> : <Input />
  const validation = () => {
    const paternMessage = {}
    switch (inputType) {
      case 'number':
        return () => ({
          validator(_, value) {
            if (value && value > 30) {
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject(<CustomErrorRule title={title} dataIndex={dataIndex} form={form} />)
            }
            return Promise.resolve()
          }
        })
      default:
        return paternMessage
    }
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ marginTop: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            },
            validation()
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

EditableCell.propTypes = {
  editing: PropTypes.bool,
  dataIndex: PropTypes.string,
  title: PropTypes.string,
  inputType: PropTypes.string,
  record: PropTypes.object,
  children: PropTypes.array,
  form: PropTypes.object
}

function AdminPaymentSettings() {
  const [rowPosition, setRowPosition] = useState(0)
  const [tableYearsData, setTableYearsData] = useState([])
  const [tableData, setTableData] = useState([])
  const [showYearsModal, setShowYearsModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [formYears] = Form.useForm()
  const [form] = Form.useForm()
  const [editingKey, setEditingKey] = useState('')

  const handleChangeShowYearsModal = () => setShowYearsModal(!showYearsModal)
  const handleChangeShowModal = () => setShowModal(!showModal)

  const handleFormYearsSubmission = () => {
    const parameters = formYears.getFieldsValue()
    parameters.dealer_id = 0
    AdminService.updateCreditScoreRateYearsRecord(parameters)
      .then((response) => {
        setTableYearsData(response.data)
        notification.success({
          message: 'Success!',
          description: 'Updated rate years successful.'
        })
      })
      .catch((error) => {
        notification.error({
          message: 'Error!',
          description: 'Updated rate years failed.'
        })
        console.error(error)
      })
    handleChangeShowYearsModal()
  }

  const handleFormSubmission = () => {
    const hasError = !!form.getFieldsError().filter(({ errors }) => errors.length).length
    if (!hasError) {
      const parameters = form.getFieldsValue()
      parameters.position = rowPosition
      AdminService.updateCreditScoreRangeRatesRecord(parameters)
        .then((response) => {
          const data = Object.values(response.data)
          const sortedRangeAndRates = data.sort((a, b) => a.position - b.position)
          setTableData(sortedRangeAndRates)
          setEditingKey('')
          notification.success({
            message: 'Success!',
            description: 'Updated range and rate successful.'
          })
        })
        .catch((error) => {
          notification.error({
            message: 'Error!',
            description: 'Updated range and rate failed.'
          })
          console.error(error)
        })
      handleChangeShowModal()
    }
  }

  const onFormYearsChange = (e) => {
    if (e.charCode) formYears.setFieldsValue({ ...formYears.getFieldValue(), [e.target.name]: e.target.value })
  }

  const onSelectYearsRow = (record) => {
    formYears.setFieldsValue(record)
  }

  useEffect(() => {
    AdminService.getCreditScoreRateYears()
      .then((response) => {
        setTableYearsData(response.data)
      })
      .catch((error) => {
        setTableYearsData([])
        console.error(error)
      })
  }, [])

  useEffect(() => {
    AdminService.getCreditScoreRangeRates()
      .then((response) => {
        const sortedRangeAndRates = response.data.sort((a, b) => a.position - b.position)
        setTableData(sortedRangeAndRates)
      })
      .catch((error) => {
        setTableData([])
        console.error(error)
      })
  }, [])

  const edit = (record) => {
    form.setFieldsValue({
      estimatedRate36: '',
      estimatedRate48: '',
      estimatedRate60: '',
      estimatedRate72: '',
      scoreRange1: '',
      scoreRange2: '',
      ...record
    })
    setRowPosition(record.position)
    setEditingKey(record._id)
  }

  const cancel = () => {
    setEditingKey('')
  }

  const mergedColumns = useMemo(() => {
    const isEditing = (record) => record._id === editingKey
    return tableColumns({ handleFormSubmission, cancel, edit, editingKey, form }).map((col) => {
      if (col.disabled) {
        return col
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
          inputType: col.inputType,
          form
        })
      }
    })
  }, [editingKey, form])

  return (
    <div className="container">
      <div className="mx-auto my-10 px-4 rounded-xl border-2 py-4">
        <div>Credit Score Rate Years</div>
        <Table
          columns={tableYearsColumns}
          className="mt-5 payment-settings-table"
          dataSource={tableYearsData}
          scroll={{ x: 'max-content' }}
          pagination={{ position: ['none', 'none'] }}
          onRow={(record) => {
            return {
              onClick: () => {
                onSelectYearsRow(record)
                handleChangeShowYearsModal()
              }
            }
          }}
        />
      </div>
      <Modal
        visible={showYearsModal}
        title="Edit Credit Score Rate Years"
        footer={null}
        style={{ top: 20 }}
        onCancel={handleChangeShowYearsModal}
        className="w-full"
      >
        <Form
          form={formYears}
          layout="vertical"
          name="vehicle-ref-ranges"
          onFinish={handleFormYearsSubmission}
          onChange={onFormYearsChange}
        >
          {formYearsFields.map((item, index) => {
            return (
              <div key={item.name}>
                <p className="text-base font-semibold">{item.label}</p>
                <Form.Item
                  name={item.name}
                  rules={[
                    {
                      required: item.required,
                      message: `${item.label} is required`
                    }
                  ]}
                  className="custom-antd-label flex"
                >
                  <Input
                    placeholder={item.label}
                    name={item.name}
                    autoFocus={index === 1}
                    className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
                    prefix={item.prefix}
                  />
                </Form.Item>
              </div>
            )
          })}
          <Form.Item className="flex items-end w-full mt-5 submit" shouldUpdate>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                className="w-48 px-6 py-5 h-12 rounded-lg flex justify-center items-center gap-2 text-base font-semibold text-white bg-blue-700"
              >
                Save
              </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>
      <div className="mx-auto my-10 px-4 rounded-xl border-2 py-4">
        <div>Max Term For Vehicle Years</div>
        <Form form={form} component={false} onFinish={handleFormSubmission}>
          <Table
            columns={mergedColumns}
            components={{
              body: {
                cell: EditableCell
              }
            }}
            className="mt-5 payment-settings-table"
            dataSource={tableData}
            scroll={{ x: 'max-content' }}
            pagination={{ position: ['none', 'none'] }}
          />
        </Form>
      </div>

      <div className="mx-auto my-10 px-4 rounded-xl border-2 py-4">
        <div>Dealer Fees</div>
        <div className="ant-table-wrapper mt-5 payment-settings-table">
          <AddDeleteTableRows dealerId={0} canManagePaymentSettings />
        </div>
      </div>

      <div className="mx-auto my-10 px-4 rounded-xl border-2 py-4">
        <div>Rate Adjustment For Score And Age Of Vehicle</div>
        <div className="ant-table-wrapper mt-5 payment-settings-table">
          <RateAdjustmentForScoreAndAgeOfVehicle dealerId={0} canManagePaymentSettings />
        </div>
      </div>

      <div className="mx-auto my-10 px-4 rounded-xl border-2 py-4">
        <div>Credit Score Vehicle Year Model Term Interest Rate</div>
        <div className="ant-table-wrapper mt-5 payment-settings-table">
          <CreditScoreVehicleYearModelTermInterestRate dealerId={0} canManagePaymentSettings />
        </div>
      </div>

    </div>
  )
}

export default AdminPaymentSettings
