import PropTypes from 'prop-types'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import carImage from '../../../assets/placeholder_car.jpg'

function VehicleImage({ images }) {
  return (
    <Carousel
      autoPlay
      infiniteLoop
      showArrows
      useKeyboardArrows
      showIndicators={false}
      showStatus={false}
      dynamicHeight={false}
    >
      {images?.length && images[0] ? (
        images?.map((image) => (
          <img
            src={image}
            onError={(e) => {
              e.target.onError = null
              e.target.src = carImage
            }}
            alt=""
            key={`vehicle-image-${image}`}
          />
        ))
      ) : (
        <img src={carImage} alt="" key="vehicle-image-default" />
      )}
    </Carousel>
  )
}

VehicleImage.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string)
}

export default VehicleImage
