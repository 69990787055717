import { Form } from 'antd'
import { Field, Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import ReactInputMask from 'react-input-mask'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import * as CustomerServices from 'src/services/customer.service'
import * as Yup from 'yup'
import * as KBBPowersAutomobileServices from '../../../services/kbb-automobile.service'
import './style.scss'

function PhoneComponent(props) {
  return (
    <ReactInputMask
      {...props}
      mask="999-999-9999"
      placeholder="e.g. 456-789-0123"
      className="w-full border border-black rounded outline-none py-2 px-3"
      type="text"
    />
  )
}

function VehicleFinanceStepFinal({
  resetStep,
  userObject,
  setUserObject,
  nextStep,
  backStep,
  handleSubmitTrade,
  finalConfiguration,
  mileage,
  zipCode,
  setTradeInValue,
  selectedCondition,
  isSinglePage,
  linkCode,
  setTradeToVehicleId
}) {
  const { customerAccount, setCustomerAccount } = useAuthorizationContext()
  const loggedIn = customerAccount?.id

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Please enter a valid first name.'),
    lastName: Yup.string().required('Please enter a valid last name.'),
    email: Yup.string().email('Please enter a valid email address').required('Please enter a email address'),
    phone: Yup.string().matches(/(\d{3}[-]?\d{3}[-]?\d{4})|(___-___-____)/, 'Phone number is not valid')
  })

  const handleNextStep = (values) => {
    KBBPowersAutomobileServices.getFinalVehicleValue(finalConfiguration, mileage, zipCode)
      .then((response) => {
        const priceObj = response.data.prices.find((item) => item.condition?.toLowerCase() === selectedCondition) || {}
        let price = 0
        if (priceObj.configuredValue !== undefined) {
          price = priceObj.configuredValue
        }
        values.tradeIn = price
        setTradeInValue(price)
      })
      .catch((error) => {
        setTradeInValue(0)
        console.error(error)
      })
      .finally(() => {
        if (loggedIn) {
          customerAccount.first_name = values.firstName
          customerAccount.last_name = values.lastName
          customerAccount.full_name = `${values.firstName} ${values.lastName}`
          customerAccount.phone = values.phone
          setCustomerAccount(customerAccount)
        }
        setUserObject(values)
        handleSubmitTrade(values)
        nextStep()
      })
  }

  useEffect(() => {
    if (customerAccount?.id && userObject && Object.keys(userObject).length < 1) {
      setUserObject({
        firstName: customerAccount?.first_name,
        lastName: customerAccount?.last_name,
        email: customerAccount?.email,
        phone: customerAccount?.phone
      })
    }
  }, [customerAccount])

  useEffect(() => {
    if (linkCode) {
      CustomerServices.getSharingLink(linkCode)
        .then((response) => {
          const customerData = response?.data?.to_user

          customerAccount.first_name = customerData?.first_name || ''
          customerAccount.last_name = customerData?.last_name || ''
          customerAccount.full_name = customerData?.full_name || ''
          customerAccount.phone = customerData?.phone || ''
          setCustomerAccount(customerAccount)

          setUserObject({
            firstName: customerData?.first_name || '',
            lastName: customerData?.last_name || '',
            email: customerData?.email || '',
            phone: customerData?.phone || ''
          })

          setTradeToVehicleId(response?.data?.object_id)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [linkCode])

  return (
    <div className="mt-4 rounded-xl p-6 step-final bg-white shadow">
      <p className="text-2xl text-black font-bold">Almost to Your Trade-In Value</p>
      <p className="text-gray-400 text-base mb-3">
        Your trade-in value is on the next page, Let&apos;s submit your deal status to unlock.
      </p>
      <Formik
        enableReinitialize
        initialValues={userObject}
        validationSchema={validationSchema}
        onSubmit={handleNextStep}
      >
        {({ handleSubmit, handleBlur, handleChange, errors, touched, values, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-5 mb-5">
              <div>
                <p className="text-black text-sm mb-2">First Name</p>
                <Field
                  name="firstName"
                  type="text"
                  className={`w-full border border-black rounded outline-none py-2 px-3 ${
                    errors.firstName && touched.firstName ? 'border-danger focus:border-danger' : ''
                  }`}
                  placeholder="e.g. John"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.firstName && touched.firstName && (
                  <p className="text-danger text-sm">
                    <span className="text-xl font-semibold leading-3">×</span> {errors.firstName}
                  </p>
                )}
              </div>
              <div>
                <p className="text-black text-sm mb-2">Last Name</p>
                <Field
                  name="lastName"
                  type="text"
                  className={`w-full border border-black rounded outline-none py-2 px-3 ${
                    errors.lastName && touched.lastName ? 'border-danger focus:border-danger' : ''
                  }`}
                  placeholder="e.g. Smith"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.lastName && touched.lastName && (
                  <p className="text-danger text-sm">
                    <span className="text-xl font-semibold leading-3">×</span> {errors.lastName}
                  </p>
                )}
              </div>
              <div>
                <p className="text-black text-sm mb-2">Email</p>
                <Field
                  disabled={loggedIn}
                  name="email"
                  type="email"
                  className={`w-full border border-black rounded outline-none py-2 px-3 ${
                    errors.email && touched.email ? 'border-danger focus:border-danger' : ''
                  }`}
                  placeholder="e.g. JSmith@gmail.com"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email && (
                  <p className="text-danger text-sm">
                    <span className="text-xl font-semibold leading-3">×</span> {errors.email}
                  </p>
                )}
              </div>
              <div>
                <p className="text-black text-sm mb-2">
                  Phone <span className="text-lightGray">(Optional)</span>
                </p>
                <Field
                  name="phone"
                  type="text"
                  className="w-full border border-black rounded outline-none py-2 px-3"
                  placeholder="e.g. 456-789-0123"
                  as={PhoneComponent}
                />
                {errors.phone && touched.phone && (
                  <p className="text-danger text-sm">
                    <span className="text-xl font-semibold leading-3">×</span> {errors.phone}
                  </p>
                )}
              </div>
              <div className="col-span-2">
                <p className="text-black text-sm mb-2">
                  Comments <span className="text-lightGray">(Optional)</span>
                </p>
                <textarea
                  className="border border-black rounded outline-none p-3 w-full resize-none"
                  rows="4"
                  onChange={(e) => setFieldValue('comments', e.target.value)}
                  value={values.comments}
                  maxLength={500}
                />
                <div className="flex justify-end">
                  <p className="text-xs text-darkGray">{500 - (values.comments?.length || 0)} characters remaining</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center mt-8">
              <button type="button" className="bg-gray-500 rounded text-white px-20 py-2 mb-3" onClick={backStep}>
                Back
              </button>
              <button
                type="button"
                onClick={handleSubmit}
                className="bg-primary rounded text-white px-16 py-2 hover:bg-activePrimary"
              >
                Continue
              </button>
              {!isSinglePage && (
                <button
                  type="button"
                  className="text-primary hover:underline text-base cursor-pointer mt-4"
                  onClick={resetStep}
                >
                  Back To My Deal
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

VehicleFinanceStepFinal.propTypes = {
  resetStep: PropTypes.func.isRequired,
  backStep: PropTypes.func.isRequired,
  userObject: PropTypes.object,
  setUserObject: PropTypes.func,
  nextStep: PropTypes.func,
  handleSubmitTrade: PropTypes.func,
  finalConfiguration: PropTypes.object,
  mileage: PropTypes.any,
  zipCode: PropTypes.any,
  setTradeInValue: PropTypes.func,
  selectedCondition: PropTypes.string,
  isSinglePage: PropTypes.bool,
  linkCode: PropTypes.string,
  setTradeToVehicleId: PropTypes.func
}

export default VehicleFinanceStepFinal
