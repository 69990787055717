import { Button, Form, InputNumber, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { STORAGE_KEYS, VALID_EMAIL } from 'src/constants/constants'
import { unSave } from 'src/lib/storage'
import { getHomeUrl } from 'src/utils'
import LoginLogo from '../../assets/new-logo.png'
import * as AuthService from '../../services/auth.service'
import '../ClaimYourBusiness/style.scss'
import SinglePageMetaDecorator from '../../decorators/SinglePageMetaDecorator'
import metaPages from '../../data/metaPages.json'
import metaPageImage from '../../assets/background-image-login.png'

const validPhoneNumber = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/

const userSetup = [
  {
    label: 'First Name',
    name: 'firstName',
    required: true,
    type: 'text'
  },
  {
    label: 'Last Name',
    name: 'lastName',
    required: true,
    type: 'text'
  },
  {
    label: 'Email',
    name: 'email',
    required: true,
    type: 'text'
  },
  {
    label: 'Create a password',
    name: 'password',
    required: true,
    type: 'password'
  },
  {
    label: 'Confirm password',
    name: 'confirmPassword',
    required: true,
    dependencies: ['password'],
    type: 'password'
  },
  {
    label: 'Phone Number',
    name: 'phone',
    required: false,
    type: 'number'
  },
  {
    label: 'Zip Code',
    name: 'zipCode',
    required: true,
    type: 'number'
  }
]

function SignUp() {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [, forceUpdate] = useState({})

  const openNotification = (type, errorMessage) => {
    const action = type === 'success' ? notification.success : notification.error
    const message = type === 'success' ? 'Sign up success' : errorMessage
    action({
      message,
      placement: 'topRight'
    })
  }

  useEffect(() => {
    forceUpdate({})
    unSave(STORAGE_KEYS.VERIFICATION_DEALER_ACCOUNT)
  }, [])

  const handleFormSubmission = (value) => {
    AuthService.customerSignup(value)
      .then((response) => {
        const { isSuccessful, data } = response
        if (isSuccessful === 'Yes') {
          openNotification('success')
          navigate('/login')
        } else {
          openNotification('error', data.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleLogin = () => {
    navigate('/login')
  }

  const validation = (form) => {
    const paternMessage = {
      pattern: /^[a-zA-Z0-9\s]+$/g,
      message: 'Alphanumeric string accepted'
    }
    switch (form.name) {
      case 'email':
        paternMessage.pattern = VALID_EMAIL
        paternMessage.message = 'Invalid message format'
        return paternMessage
      case 'confirmPassword':
        return ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('The two passwords that you entered do not match!'))
          }
        })
      case 'password':
        paternMessage.required = form.required
        paternMessage.pattern = /^(?=.*[\d])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,16}$/
        paternMessage.message =
          'Must Contain min 8 Characters, a Uppercase, a Lowercase, a Number and a Special Character from (!, ?, @, #, $, %, ^, &, *)'
        return paternMessage
      case 'phone':
        return () => ({
          validator(_, value) {
            if (!value || validPhoneNumber.test(value)) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('Invalid phone number!'))
          }
        })
      case 'zipCode':
        paternMessage.pattern = /^[0-9]{4,5}\s*$/
        paternMessage.message = 'Invalid zip code'
        return paternMessage
      default:
        return paternMessage
    }
  }

  const handleChangeShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleChangeShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }
  const sharedByDealership = sessionStorage.getItem(STORAGE_KEYS.IS_SHARED_BY_DEALERSHIP) === 'true'
  const dealershipId = sessionStorage.getItem(STORAGE_KEYS.SHARED_BY_DEALERSHIP_ID)
  const handleRedirectToHome = () => {
    navigate(getHomeUrl({ sharedByDealership, dealershipId }))
  }

  const renderInputItems = (item, index) => {
    switch (item.type) {
      case 'password':
        return (
          <div className="relative border rounded-xl border-gray">
            <input
              placeholder={item.label}
              name={item.name}
              className="w-full p-4 box-border rounded-xl border-gray-300 border focus:outline-none pr-8"
              type={(item.name === 'password' ? showPassword : showConfirmPassword) ? 'text' : 'password'}
            />
            {item.name === 'password' &&
              (showPassword ? (
                <AiFillEyeInvisible
                  onClick={handleChangeShowPassword}
                  className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                />
              ) : (
                <AiFillEye
                  onClick={handleChangeShowPassword}
                  className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                />
              ))}
            {item.name === 'confirmPassword' &&
              (showConfirmPassword ? (
                <AiFillEyeInvisible
                  onClick={handleChangeShowConfirmPassword}
                  className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                />
              ) : (
                <AiFillEye
                  onClick={handleChangeShowConfirmPassword}
                  className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                />
              ))}
          </div>
        )
      case 'number':
        return (
          <InputNumber
            className="w-full py-2 box-border rounded-xl border-gray-300 border focus:outline-none"
            min={0}
            name={item.name}
            placeholder={item.label}
          />
        )
      default:
        return (
          <input
            placeholder={item.label}
            name={item.name}
            autoFocus={index === 0}
            className="w-full p-4 box-border rounded-xl border-gray-300 border focus:outline-none"
            type={item.type}
          />
        )
    }
  }

  return (
    <div className="claim-page">
      <SinglePageMetaDecorator
        title={metaPages.signUp.title}
        description={metaPages.signUp.description}
        imageUrl={metaPageImage}
        imageAlt={metaPages.signUp.imageAlt}
      />
      <div className="auth-main-block">
        <div className="form-claim">
          <img
            className="cursor-pointer"
            onClick={handleRedirectToHome}
            width="100%"
            height="100%"
            src={LoginLogo}
            alt="login logo"
          />
          <Form
            form={form}
            layout="vertical"
            name="vehicle-ref"
            className="mx-4 lg:px-8 items-center justify-center"
            onFinish={handleFormSubmission}
          >
            <p className="text-black text-center my-4 text-xl font-bold">Sign Up</p>
            {userSetup.map((item, index) => {
              return (
                <Form.Item
                  name={item.name}
                  dependencies={item?.dependencies}
                  rules={[
                    {
                      required: item.required && !item.type === 'password',
                      message: `${item.label} is required`
                    },
                    validation(item)
                  ]}
                  className="mt-5"
                  key={item.name}
                >
                  {renderInputItems(item, index)}
                </Form.Item>
              )
            })}

            <Form.Item className="mt-8 items-center" shouldUpdate>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="w-full h-12 rounded-xl	"
                  disabled={
                    !form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                >
                  SIGN UP
                </Button>
              )}
            </Form.Item>
          </Form>
          <p className="text-center p-4 pb-0">
            I have already an account{' '}
            <button onClick={handleLogin} type="button" className="text-blue-500">
              Login
            </button>
          </p>
        </div>
      </div>
    </div>
  )
}

export default SignUp
