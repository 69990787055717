import { List, Tooltip, Typography } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { AiFillBank, AiFillQuestionCircle, AiOutlineCar, AiOutlineCheckCircle, AiOutlineMail } from 'react-icons/ai'
import { BsFolder } from 'react-icons/bs'
import { FaCheck, FaHandHoldingUsd } from 'react-icons/fa'
import { EZ_DEALZ_STATUS_OPTIONS, SMARTBOARD_DEACTIVED_MSG } from "../../../constants/constants"
import * as dealerService from '../../../services/dealer.service'
import './style.scss'

const { Text } = Typography

// Show only markers on production
// const hideOnProduction = process.env.REACT_APP_HIDE_ON_PRODUCTION === 'true'
const hideOnProduction = true

const engagementReports = (totalLeads) => [
  {
    key: 1,
    icons: <BsFolder color="lightgray" size={35} />,
    label: 'TOTAL',
    value: 0,
    disable: hideOnProduction
  },
  {
    key: 7,
    icons: <AiOutlineCar color="lightgray" size={35} />,
    label: 'Total Leads',
    value: totalLeads
  },
  {
    key: 2,
    icons: <AiOutlineCar color="lightgray" size={35} />,
    label: 'Trades',
    value: 0,
    percentage: 0,
    disable: hideOnProduction
  },
  {
    key: 3,
    icons: <AiFillBank color="lightgray" size={35} />,
    label: 'Credit Apps',
    value: 0,
    percentage: 0,
    disable: hideOnProduction
  },
  {
    key: 4,
    icons: <AiOutlineMail color="lightgray" size={35} />,
    label: 'Offers Sent',
    value: 0,
    percentage: 0,
    disable: hideOnProduction
  },
  {
    key: 5,
    icons: <AiOutlineCheckCircle color="lightgray" size={35} />,
    label: 'Offer Accepted',
    value: 0,
    percentage: 0,
    disable: hideOnProduction
  },
  {
    key: 6,
    icons: <FaHandHoldingUsd color="lightgray" size={35} />,
    label: 'Offer Delivered',
    value: 0,
    percentage: 0,
    disable: hideOnProduction
  },
  {
    key: 8,
    icons: <FaCheck size={22} />,
    label: `SmartBoard Activated`,
    suffixIcon: <AiFillQuestionCircle size={16} />
  }
]

export function Engagements({ dealershipAccount }) {
  const leadsCount = 0
  const [totalLeads, setTotalLeads] = useState(leadsCount)
  const isSmartBoardActivated = dealershipAccount?.smartboard ?? true

  useEffect(() => {
    if (leadsCount < 1) {
      dealerService.dealershipOffersCount().then((result) => {
        setTotalLeads(result.data)
      })
    }
  }, [totalLeads])

  return (
    <div className="grid lg:grid-cols-7 grid-cols-3 gap-5 bg-white p-5 rounded-xl">
      <p className="font-bold text-base">
        Ez-Dealz
        <br />
        Engagements
      </p>
      {engagementReports(totalLeads).map((engagement) => {
        return (
          <div key={engagement.key} className={`items-center ${engagement.disable ? 'hidden' : 'lg:flex'} ${isSmartBoardActivated ? 'text-lightGreen' : 'text-lightGray'}`}>
            {engagement.icons}
            <div className="pl-2">
              <p className="font-normal text-black text-lg">
                {engagement.value}
                <span
                  className={`font-bold text-blue-500 ${!engagement.percentage && 'hidden'}`}
                >{` | ${engagement.percentage}%`}</span>
              </p>
              <p className={`font-semibold text-base text-gray-400 ${engagement.label === 'SmartBoard Activated' ? 'w-1/2' : ''}`}>
                {engagement.label}
                <span className='inline-block ml-1'>
                  <Tooltip
                    placement="right"
                    title={
                      <div className='text-black'>
                        {isSmartBoardActivated ? (
                          <List
                            size="small"
                            bordered={false}
                            dataSource={EZ_DEALZ_STATUS_OPTIONS}
                            renderItem={(status) =>
                              <List.Item style={{ color: `${status.color}`, border: 'none' }}>
                                <span 
                                  className={ status.name === EZ_DEALZ_STATUS_OPTIONS[2].name? "new-inbound-lead-status": "smartboard-status"} 
                                  style= {{ borderColor: `${status.color}`}}
                                />
                                {status.text}
                              </List.Item>
                            }
                          />
                        ) : (
                          <Text>{SMARTBOARD_DEACTIVED_MSG}</Text>
                        )}
                      </div>
                    }
                    color="white"
                    overlayInnerStyle={{ width: '490px', padding: '15px 10px' }}
                  >
                    <span className={`${isSmartBoardActivated ? 'text-info' : 'text-lightGray'}`}>
                    {engagement.suffixIcon}
                    </span>
                    
                  </Tooltip>
                </span>
              </p>

            </div>
          </div>
        )
      })}
    </div>
  )
}

Engagements.propTypes = {
  dealershipAccount: PropTypes.object
}
