import PropTypes from 'prop-types'
import React from 'react'
import ConditionCard from '../ConditionCard'
import './style.scss'

const conditions = [
  {
    title: 'Excellent',
    detail: 'Looks new and is in excellent mechanical condition',
    type: 'excellent',
    selected: false
  },
  {
    title: 'Very Good',
    detail: 'Minor cosmetic defects and in excellent mechanical condition',
    type: 'very good',
    selected: false
  },
  {
    title: 'Good',
    detail: 'Repairable cosmetic defect and free of major mechanical problems',
    type: 'good',
    selected: false
  },
  {
    title: 'Fair',
    detail: 'Some mechanical or cosmetic defects and needs servicing',
    type: 'fair',
    selected: false
  }
]

function VehicleFinanceStep3({ tradeVehicleInfo, backStep, nextStep, selectedCondition, setSelectedCondition }) {
  const { year, make, model, yearId, makeName, modelName } = tradeVehicleInfo
  const handleSelect = (value) => {
    setSelectedCondition(value)
  }
  const yearDisplay = year || yearId || ''
  const makeDisplay = make || makeName || ''
  const modelDisplay = model || modelName || ''

  return (
    <div className="mt-8 step-3">
      <p className="text-2xl text-black font-bold">Tell Us About Its Condition</p>
      <p className="text-gray-400 text-base mb-3">
        {`Please select your condition carefully, as it will affect the trade-in value of your ${yearDisplay} ${makeDisplay} ${modelDisplay}.`}
      </p>
      <div className="grid grid-cols-2 gap-1 px-3">
        {conditions.map((condition) => (
          <ConditionCard
            info={condition}
            selectedCondition={selectedCondition}
            handleSelect={handleSelect}
            key={`condition-card-${condition.type}`}
          />
        ))}
      </div>
      <div className="flex justify-around mt-12 gap-2">
        <button type="button" className="bg-gray-500 rounded text-white px-16 py-2" onClick={backStep}>
          Back
        </button>
        <button
          type="button"
          className={`bg-primary rounded text-white px-16 py-2 hover:bg-activePrimary ${!selectedCondition ? 'cursor-not-allowed opacity-50 pointer-events-none' : ''
            }`}
          onClick={nextStep}
        >
          Next
        </button>
      </div>
    </div>
  )
}

VehicleFinanceStep3.propTypes = {
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  selectedCondition: PropTypes.string,
  setSelectedCondition: PropTypes.func,
  tradeVehicleInfo: PropTypes.object
}

export default VehicleFinanceStep3
