import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

function SummaryCarCard({ car, ...rest }) {
  return (
    <div className="border border-gray-200 rounded p-5 flex flex-col items-center" {...rest}>
      <div className="w-7/12 m-auto">
        <img className="img-logos" src={car.image} alt="icon" />
      </div>
      <p className="text-2xl font-bold mt-4 mb-3">{car.title}</p>
      <p className="text-gray-800 text-lg text-center h-16">{car.detail}</p>
      <Link to={car.link}>
        <button
          type="button"
          className="mt-6 rounded-md border-2 border-primary px-8 py-1 text-primary font-semibold text-base w-full hover:bg-primary hover:text-white"
        >
          {car.linkDetail}
        </button>
      </Link>
    </div>
  )
}

SummaryCarCard.propTypes = {
  car: PropTypes.shape({
    title: PropTypes.string,
    detail: PropTypes.string,
    image: PropTypes.string,
    link: PropTypes.string,
    linkDetail: PropTypes.string
  }).isRequired
}

export default SummaryCarCard
