import { Button } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { BsArrowLeftRight, BsFillPencilFill, BsTrash } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import carImage from 'src/assets/placeholder_car.jpg'
import StockComponent from 'src/components/StockComponent'
import ExteriorColorFoundComponent from '../../../ExteriorColorFoundComponent'
import { useAuthorizationContext } from '../../../../AuthorizationProvider'
import './style.scss'

function CardSimilarVehicleInventory({ car, ...rest }) {
  const navigate = useNavigate()
  const notAvailable = 'Not available'
  const [image, setImage] = useState(carImage)
  useEffect(() => {
    if (car.photos?.length > 0) {
      setImage(car.photos[0])
    }
  }, [car.photos])

  const { dealershipAccount } = useAuthorizationContext()
  const canEditInventory = undefined !== dealershipAccount ? dealershipAccount?.permissions?.edit_inventory : true
  const isHideOnProduction = process.env.REACT_APP_HIDE_ON_PRODUCTION === 'true'

  const handleRedirectToDealerVehicle = () => {
    navigate(`/dealer-vehicles/${car._id}`)
  }

  return (
    <div className="border border-gray-200 rounded-2xl relative flex flex-col justify-between h-full" {...rest}>
      <div className="p-3 vehicle-card-entry">
        <StockComponent vehicle={car} />
        <ExteriorColorFoundComponent vehicle={car} />
        <div className="vehicle-card-thumbnail">
          <Link to={`/dealer-vehicles/${car._id}`}>
            <img src={image} alt={car.make} className="w-full" />
          </Link>
        </div>
        <div className="vehicle-card-body">
          <Link
            to={`/dealer-vehicles/${car._id}`}
            className="text-gray-800 text-xl font-semibold mb-2 hover:underline cursor-pointer hover:text-primary"
          >
            {`${car.year} ${car.make} ${car.model}`}
          </Link>
          <div className="grid grid-cols-6">
            <p className="text-gray-600 col-span-2">Body</p>
            <p className="text-gray-800 col-span-4">{car.body || notAvailable}</p>
          </div>
          <div className="grid grid-cols-6">
            <p className="text-gray-600 col-span-2">Mileage</p>
            <p className="text-gray-800 col-span-4">{car.miles || 0}</p>
          </div>
          <div className="grid grid-cols-6">
            <p className="text-gray-600 col-span-2">Engine</p>
            <p className="text-gray-800 col-span-4">{car.engine_name || notAvailable}</p>
          </div>
          <div className="grid grid-cols-6 mb-4">
            <p className="text-gray-600 col-span-2">Transmission</p>
            <p className="text-gray-800 col-span-4">{car.transmission_name || notAvailable}</p>
          </div>
        </div>
        <div className="flex gap-3 mt-3">
          <div className="w-40">
            <Button type="primary" className="font-semibold flex items-center" onClick={handleRedirectToDealerVehicle}>
              Details &nbsp; &#62;
            </Button>
          </div>
          <div className="flex gap-2 text-xl items-end justify-end">
            {!isHideOnProduction && (
              <BsArrowLeftRight className="cursor-pointer hover:bg-primary hover:text-white text-primary" />
            )}
            {!isHideOnProduction && canEditInventory && (
              <BsFillPencilFill className="cursor-pointer hover:bg-primary hover:text-white text-primary" />
            )}
            {!isHideOnProduction && (
              <BsTrash className="cursor-pointer hover:bg-primary hover:text-white text-primary" />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

CardSimilarVehicleInventory.propTypes = {
  car: PropTypes.object
}

export default CardSimilarVehicleInventory
