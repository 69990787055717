import { install } from 'ga-gtag'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import ClaimYourBusinessV3 from './pages/ClaimYourBusinessV3'
import ClaimNiada from './pages/ClaimNiada'
import { DealerVerification } from './pages/DealerVerification'
import DealerVerificationByToken from './pages/DealerVerificationByToken'
import EzDealzModalWallet from './pages/EzDealzModalWallet'
import Login from './pages/Login'
import Main from './pages/Main'
import SignUp from './pages/SignUp'
import UserSetup from './pages/UserSetup'

function App() {
  const baseUrl = window.location.origin
  if (baseUrl === 'https://dev.motormarketusa.com') {
    window.location.replace('https://motormarketusa.com')
  }
  if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'production') {
    install(process.env.REACT_APP_GTAG)
  }
  return (
    <>
      <Routes>
        <Route path="/*" element={<Main />} />
        <Route path="/login" element={<Login isLogin isLoginAdmin={false} isForgotPassword={false} />} />
        <Route path="/login-admin" element={<Login isLogin={false} isLoginAdmin isForgotPassword={false} />} />
        <Route path="/forgot-password" element={<Login isLogin={false} isLoginAdmin={false} isForgotPassword />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/claim" element={<ClaimYourBusinessV3 />} />
        <Route path="/claim1" element={<ClaimYourBusinessV3 />} />
        <Route path="/claim2" element={<ClaimYourBusinessV3 />} />
        <Route path="/claim3" element={<ClaimYourBusinessV3 />} />
        <Route path="/claim/:employeeId" element={<UserSetup isClaim />} />
        <Route path="/NIADA-Claim" element={<ClaimNiada />} />
        <Route
          path="/verification/:verificationToken"
          element={<DealerVerification verificationType="verification" />}
        />
        <Route
          path="/generate-password/:verificationToken"
          element={<DealerVerification verificationType="generate-password" />}
        />
        <Route
          path="/forgot-password/:verificationToken/role/:userRole/id/:userId"
          element={<DealerVerification verificationType="forgot-password" />}
        />
        <Route path="/create-password/:id" element={<UserSetup isClaim={false} />} />

        <Route path="/verification-by-token/:verificationToken" element={<DealerVerificationByToken />} />
      </Routes>
      <EzDealzModalWallet />
    </>
  )
}

export default App
