/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { SearchOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AiOutlineLoading3Quarters, AiOutlinePlus } from 'react-icons/ai'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import { CustomDeals } from 'src/components/AdminDashboard/CustomDeals'
import AdfEmailAddress from 'src/components/DealerDashboard/AdfEmailAddress'
import CreditCredentials from 'src/components/DealerDashboard/CreditCredentials'
import CreditRatesAndTermsTable from 'src/components/DealerDashboard/CreditRatesAndTermsTable'
import { DealerCustomDeals } from 'src/components/DealerDashboard/DealerCustomDeals'
import DealershipInformation from 'src/components/DealerDashboard/DealershipInformation'
import { Engagements } from 'src/components/DealerDashboard/Engagements'
import { EzDealzSent } from 'src/components/DealerDashboard/EzDealzSent'
import FeeTable from 'src/components/DealerDashboard/FeeTable'
import { MyInventory } from 'src/components/DealerDashboard/MyInventory'
import { SearchDealerDashboard } from 'src/components/DealerDashboard/SearchDealerDashboard'
import { EngagementStatistic } from 'src/components/DealerDashboard/Statistic/EngagementStatistic'
import { RecentLeadStatistic } from 'src/components/DealerDashboard/Statistic/RecentLeads'
import { VisitorStatistic } from 'src/components/DealerDashboard/Statistic/VisitorStatistic'
import CreditScoreRateYearTable from '../../components/DealerDashboard/CreditScoreRateYearTable'
import CreditScoreVehicleYearModelTermInterestRate from '../../components/DealerDashboard/CreditScoreVehicleYearModelTermInterestRate'
import RateAdjustmentForScoreAndAgeOfVehicle from '../../components/DealerDashboard/RateAdjustmentForScoreAndAgeOfVehicle'
import UploadInventoryModal from '../../components/UploadInventoryModal'
import metaPages from '../../data/metaPages.json'
import SinglePageMetaDecorator from '../../decorators/SinglePageMetaDecorator'
import metaPageImageDealershipDashboard from '../../assets/background-image-login.png'
import * as vehicleService from '../../services/vehicle.service'
import './style.scss'

function DealerDashboard({ dealerHeaderScrollRef, activeKey, setActiveKey }) {
  const { dealershipAccount } = useAuthorizationContext()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const dashboard = useRef({})
  const myInventory = useRef({})
  const searchRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [reloadDealers, setReloadDealers] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [keyword, setKeyword] = useState('')
  const dealerId = undefined !== dealershipAccount ? dealershipAccount?.dealer_id : 0
  const employeeId = undefined !== dealershipAccount ? dealershipAccount?.employee_id : ''
  const canEditInventory = undefined !== dealershipAccount ? dealershipAccount?.permissions?.edit_inventory : true
  const canManageDealers = undefined !== dealershipAccount ? dealershipAccount?.permissions?.manage_dealers : true
  const canManagePaymentSettings =
    undefined !== dealershipAccount ? dealershipAccount?.permissions?.manage_payment_settings : true

  const [showUploadInventoryModal, setShowUploadInventoryModal] = useState(false)
  const handleChangeShowUploadInventoryModal = () => setShowUploadInventoryModal(!showUploadInventoryModal)

  const [refetchInventory, setRefetchInventory] = useState(false)

  const handleRefetchVehicle = () => {
    setRefetchInventory((prevState) => !prevState)
  }

  const handleScrollTo = (index) => {
    switch (index) {
      case 1:
        if (myInventory.current) {
          myInventory.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        break
      case 6:
        setActiveKey('2')
        if (dashboard.current) {
          dashboard.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
        }
        break
      default:
        setActiveKey('1')
        if (dashboard.current) {
          dashboard.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
        }
        break
    }
  }

  useEffect(() => {
    if (dealerHeaderScrollRef.current) {
      dealerHeaderScrollRef.current.handleScrollTo = handleScrollTo
    }
  }, [handleScrollTo])

  useEffect(() => {
    if (searchParams.get('scrollTo')) {
      const scrollTo = searchParams.get('scrollTo')
      if (scrollTo === 'my-inventory' && myInventory.current) {
        setTimeout(() => {
          const inventoryElement = document.getElementById('my-inventory')
          inventoryElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        }, 1500)
      }
    }
  }, [])

  const searchableData = (searchData) => {
    setSearchText(searchData)
  }

  const handleSearchInventory = useCallback(() => {
    const value = searchRef.current.value
    setKeyword(value)
  }, [keyword])

  const pressEnterKey = (event) => {
    if (event.charCode === 13 && !loading) {
      handleSearchInventory(event)
    }
  }

  const hideOnProduction = process.env.REACT_APP_HIDE_ON_PRODUCTION === 'true'

  const handleNavigateAddVehicle = () => {
    vehicleService
      .addVehicle(dealerId)
      .then((response) => {
        const vehicleId = response.data._id
        navigate(`/add-vehicle/${vehicleId}`)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const renderComponent = useMemo(() => {
    if (activeKey === '1') {
      return (
        <EzDealzSent
          searchText={searchText}
          dealershipAccount={dealershipAccount}
          refetchInventory={refetchInventory}
        />
      )
    }
    if (activeKey === '2') {
      return (
        <div className="my-6 gap-5">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 mb-5 gap-0 xl:gap-6 lg:gap-5 md:gap-4 sm:gap-0">
            <VisitorStatistic />
            <div className="col-span-2 mt-5">
              <EngagementStatistic />
            </div>
          </div>
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 gap-0 xl:gap-6 lg:gap-5 md:gap-4 sm:gap-0">
            {!hideOnProduction && <RecentLeadStatistic />}
            <div className={`rounded-xl border-2 p-4 mb-5 ${hideOnProduction ? 'col-span-3' : 'col-span-2'}`}>
              <DealerCustomDeals dealershipAccount={dealershipAccount} />
            </div>
          </div>
        </div>
      )
    }
    if (activeKey === '4') {
      return (
        <div className="flex gap-10 flex-col">
          <DealershipInformation />
          <div className="flex gap-10">
            <CreditCredentials />
            <AdfEmailAddress />
          </div>
        </div>
      )
    }
    if (activeKey === '5' && dealerId > 0 && canManageDealers) {
      return (
        <CustomDeals
          searchText={searchText}
          filterParentData={searchableData}
          showSearch
          dealerId={dealerId}
          employeeId={employeeId}
          loading={loading}
          setLoading={setLoading}
          reloadDealers={reloadDealers}
          setReloadDealers={setReloadDealers}
        />
      )
    }
    return (
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 ">
        <CreditScoreRateYearTable dealerId={dealerId} />
        <CreditRatesAndTermsTable dealerId={dealerId} />
        <FeeTable dealerId={dealerId} />
        <div className="container">
          <div className="mx-auto my-10 px-4 rounded-xl border-2 py-4">
            <div>Rate Adjustment For Score And Age Of Vehicle</div>
            <div className="ant-table-wrapper mt-5 payment-settings-table">
              <RateAdjustmentForScoreAndAgeOfVehicle
                dealerId={dealerId}
                canManagePaymentSettings={canManagePaymentSettings}
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mx-auto my-10 px-4 rounded-xl border-2 py-4">
            <div>Credit Score Vehicle Year Model Term Interest Rate</div>
            <div className="ant-table-wrapper mt-5 payment-settings-table">
              <CreditScoreVehicleYearModelTermInterestRate
                dealerId={dealerId}
                canManagePaymentSettings={canManagePaymentSettings}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }, [activeKey, dealershipAccount, reloadDealers, refetchInventory])

  return (
    <div ref={dashboard} className="bg-gray-100">
      <SinglePageMetaDecorator
        title={metaPages.dealershipDashboard.title}
        description={metaPages.dealershipDashboard.description}
        imageUrl={metaPageImageDealershipDashboard}
        imageAlt={metaPages.dealershipDashboard.imageAlt}
      />
      <div className="my-5 lg:mx-16 mx-3">
        <SearchDealerDashboard
          filterParentData={searchableData}
          dealershipAccount={dealershipAccount}
          isHidden
          isAdmin={false}
          dealerId={dealerId}
          setReloadDealers={setReloadDealers}
        />
      </div>
      <div className="lg:mx-16 mx-4">
        <Engagements dealershipAccount={dealershipAccount} />
      </div>
      <div className=" lg:mx-16 mx-3 mt-10 card-container">
        <div id="statistic-col" className="w-full rounded-t-3xl p-6 bg-white">
          <div className="w-full lg:flex mb-4">
            <div className="flex flex-wrap lg:flex-nowrap font-bold justify-around w-full lg:w-3/4 xl:w-1/2">
              <p
                className={`cursor-pointer border-b-4 pb-8 w-1/2 text-center hover:text-blue-400 ${
                  activeKey === '1' && 'text-blue-400 border-blue-400'
                }`}
                onClick={() => setActiveKey('1')}
              >
                Ez-Dealz Sent
              </p>

              <p
                className={`cursor-pointer border-b-4 pb-8 w-1/2 text-center hover:text-blue-400 ${
                  activeKey === '2' && 'text-blue-400  border-blue-400'
                }`}
                onClick={() => setActiveKey('2')}
              >
                Analytics
              </p>

              <p
                className={`cursor-pointer border-b-4 pb-8 w-1/2 text-center hover:text-blue-400 ${
                  activeKey === '3' && 'text-blue-400  border-blue-400'
                }`}
                onClick={() => setActiveKey('3')}
              >
                Settings
              </p>
              <p
                className={`cursor-pointer border-b-4 pb-8 w-1/2 text-center hover:text-blue-400 ${
                  activeKey === '4' && 'text-blue-400  border-blue-400'
                }`}
                onClick={() => setActiveKey('4')}
              >
                Dealership Information
              </p>
              {canManageDealers && (
                <p
                  className={`cursor-pointer border-b-4 pb-8 w-1/2 text-center hover:text-blue-400 ${
                    activeKey === '5' && 'text-blue-400  border-blue-400'
                  }`}
                  onClick={() => setActiveKey('5')}
                >
                  Dealer users
                </p>
              )}
            </div>
          </div>
          {renderComponent}
        </div>
      </div>

      <div ref={myInventory} id="my-inventory" className=" lg:mx-16 mx-3 my-12 bg-white p-6 rounded-xl border-2">
        <div className="w-full flex items-center justify-center mb-5">
          <div className="relative w-10/12 lg:w-3/12 xl:w-3/12">
            <input
              ref={searchRef}
              className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
              type="text"
              placeholder="Search by Year, Make, Model, VIN, Stock Number"
              onKeyPress={pressEnterKey}
            />
            {loading ? (
              <AiOutlineLoading3Quarters className="text-blue-500 absolute right-3 top-1/3 -translate-y-2/4 animate-spin" />
            ) : (
              <SearchOutlined
                onClick={handleSearchInventory}
                className="text-blue-500 absolute right-3 top-1/3 -translate-y-2/4"
              />
            )}
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="text-xl font-bold mb-4">My Inventory</div>
          {canEditInventory && (
            <div className="text-xl font-bold mb-4 ml-5 flex items-center gap-3">
              <Button
                onClick={() => setShowUploadInventoryModal(true)}
                icon={<AiOutlinePlus />}
                type="primary"
                className="flex gap-2 items-center h-10 font-semibold"
              >
                Upload Inventory
              </Button>
              <UploadInventoryModal
                dealerId={dealerId}
                showModal={showUploadInventoryModal}
                handleCloseModal={handleChangeShowUploadInventoryModal}
                handleRefetchVehicle={handleRefetchVehicle}
              />
              <Button
                onClick={handleNavigateAddVehicle}
                icon={<AiOutlinePlus />}
                type="primary"
                className="flex gap-2 items-center h-10 font-semibold"
              >
                Add Vehicle
              </Button>
            </div>
          )}
        </div>
        <MyInventory
          dealershipAccount={dealershipAccount}
          searchKeyword={keyword}
          refetchInventory={refetchInventory}
          handleRefetchVehicle={handleRefetchVehicle}
        />
      </div>
    </div>
  )
}

DealerDashboard.propTypes = {
  dealerHeaderScrollRef: PropTypes.object,
  activeKey: PropTypes.string,
  setActiveKey: PropTypes.func
}

export default DealerDashboard
