import { notification } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import AuthPopup from 'src/components/Vehicle/BoxButtonGroup/AuthPopup'
import LoginFormModal from 'src/components/Vehicle/BoxButtonGroup/LoginFormModal'
import SignUpFormModal from 'src/components/Vehicle/BoxButtonGroup/SignUpFormModal'
import AdditionalIncome from 'src/components/Vehicle/CreditFinance/AdditionalIncome'
import ApplicationSection from 'src/components/Vehicle/CreditFinance/ApplicationSection'
import DriverLicense from 'src/components/Vehicle/CreditFinance/DriverLicense'
import HomeAddress from 'src/components/Vehicle/CreditFinance/HomeAddress'
import JobInformation from 'src/components/Vehicle/CreditFinance/JobInformation'
import References from 'src/components/Vehicle/CreditFinance/References'
import Summary from 'src/components/Vehicle/CreditFinance/Summary'
import { STORAGE_KEYS } from 'src/constants/constants'
import { load } from 'src/lib/storage'
import { setCreditApplication, updateCreditApplication } from 'src/services/creditApplicationForm.service'
import * as CustomerServices from 'src/services/customer.service'

const residentialStatusOptions = [
  { id: 'own', name: 'Own' },
  { id: 'mortgage', name: 'Mortgage' },
  { id: 'rent', name: 'Rent' }
]

const jobTypeOptions = [
  { id: 'fulltime', name: 'Full time' },
  { id: 'parttime', name: 'Part time' }
]

function BodyStepper({ currentStep, setCurrentStep, linkCode }) {
  const navigate = useNavigate()
  const isMobile = window.innerWidth <= 500
  const { customerLogin } = useAuthorizationContext()
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [showSignUpForm, setShowSignUpForm] = useState(false)
  const [showPopupConfirm, setShowPopupConfirm] = useState(false)
  const [, setLoginRequired] = useState(false)
  const [, setShowCashDownPopupConfirm] = useState(false)

  const [selectedResidentialStatus, setSelectedResidentialStatus] = useState(residentialStatusOptions[1].id)
  const handleChangeResidentialStatus = (event) => {
    const { status } = event.target.dataset
    setSelectedResidentialStatus(status)
  }

  const [preSelectedResidentialStatus, setPreSelectedResidentialStatus] = useState(residentialStatusOptions[1].id)
  const handleChangePreResidentialStatus = (event) => {
    const { status } = event.target.dataset
    setPreSelectedResidentialStatus(status)
  }

  const [jobType, setJobType] = useState(jobTypeOptions[0].id)
  const handleChangeJobType = (event) => {
    const { type } = event.target.dataset
    setJobType(type)
  }

  const [preJobType, setPreJobType] = useState(jobTypeOptions[0].id)
  const handleChangePreJobType = (event) => {
    const { type } = event.target.dataset
    setPreJobType(type)
  }

  const [creditAppToVehichelId, setCreditAppToVehichelId] = useState('')

  const openNotification = (type) => {
    const action = type === 'success' ? notification.success : notification.error
    const message =
      type === 'success'
        ? 'Credit Application Form has been added'
        : 'There was error while saving the form. Please try again.'
    action({
      message,
      placement: 'topRight'
    })
  }

  const applicationFormRef = useRef()
  const homeAddressFormRef = useRef()
  const previousHomeAddressRef = useRef()
  const driverLicenseFormRef = useRef()
  const jobInformationFormRef = useRef()
  const previousInformationFormRef = useRef()
  const additionalIncomeFormRef = useRef()
  const referencesFormRef = useRef()
  const summaryPageRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => summaryPageRef.current
  })

  const disabledPrevious = currentStep === 1
  const isFinalStep = currentStep === 7

  const nextStep = async () => {
    let formRef = applicationFormRef.current
    switch (currentStep) {
      case 1:
        formRef = applicationFormRef.current
        break
      case 2:
        formRef = homeAddressFormRef.current
        break
      case 3:
        formRef = driverLicenseFormRef.current
        break
      case 4:
        formRef = jobInformationFormRef.current
        break
      case 5:
        formRef = additionalIncomeFormRef.current
        break
      case 6:
        formRef = referencesFormRef.current
        break
      default:
        formRef = applicationFormRef.current
    }

    const selectedValues = {
      selectedResident: selectedResidentialStatus,
      selectedJobType: jobType,
      preSelectedResidentialStatus,
      preJobType
    }
    const data = {
      linkCode,
      selectedValues,
      appSection: applicationFormRef.current?.getFieldsValue(),
      addressSection: homeAddressFormRef.current?.getFieldsValue(),
      licenseSection: driverLicenseFormRef.current?.getFieldsValue(),
      jobSection: jobInformationFormRef.current?.getFieldsValue(),
      incomeSection: additionalIncomeFormRef.current?.getFieldsValue(),
      previousHomeAddress: previousHomeAddressRef.current?.getFieldsValue(),
      previousJobInformation: previousInformationFormRef.current?.getFieldsValue(),
      references: referencesFormRef.current?.getFieldsValue()?.referenceList,
      vehicleId: creditAppToVehichelId
    }

    if (isFinalStep) {
      setCreditApplication(data)
        .then((response) => {
          sessionStorage.setItem(STORAGE_KEYS.CUSTOMER_CREDIT_APPS, response?.data[0]?._id)
          openNotification('success')
        })
        .catch(() => {
          openNotification()
        })
        .finally(() => {
          if (!customerLogin) {
            setShowPopupConfirm(true)
          } else {
            navigate('/customer-dashboard')
          }
        })
    }

    const values = await formRef
      .validateFields()
      .then((response) => {
        return response
      })
      .catch((error) => error)
    if (currentStep === 2 && formRef.getFieldValue('yearsAtAddress') < 2) {
      const values = await previousHomeAddressRef.current
        .validateFields()
        .then((response) => response)
        .catch((error) => error)
      if (values.errorFields && values.errorFields.length > 0) return
    }
    if (currentStep === 4 && formRef.getFieldValue('yearsAtJob') < 2) {
      const values = await previousInformationFormRef.current
        .validateFields()
        .then((response) => response)
        .catch((error) => error)
      if (values.errorFields && values.errorFields.length > 0) return
    }
    if (values.errorFields && values.errorFields.length > 0) return

    if (currentStep < 7) {
      setCurrentStep(currentStep + 1)
    }
  }

  const userLogin = load(STORAGE_KEYS.CUSTOMER_LOGIN)
  const userSettings = load(STORAGE_KEYS.USER_SETTINGS)

  useEffect(() => {
    if (userLogin && userSettings.role === 'customer') {
      if (applicationFormRef && applicationFormRef.current) {
        applicationFormRef.current.setFieldsValue({
          firstName: userSettings.first_name,
          lastName: userSettings.last_name,
          // middleName: userSettings.middle_name,
          email: userSettings.email
          // mobileNumber: phoneFormat(userSettings.phone)
        })
      }
    }
  }, [userLogin, userSettings, applicationFormRef])

  useEffect(() => {
    if (linkCode) {
      CustomerServices.getSharingLink(linkCode)
        .then((response) => {
          const customerData = response?.data?.to_user

          if (applicationFormRef && applicationFormRef.current) {
            applicationFormRef.current.setFieldsValue({
              firstName: customerData?.first_name,
              lastName: customerData?.last_name,
              email: customerData?.email
            })
          }

          setCreditAppToVehichelId(response?.data?.object_id)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [linkCode])

  const backStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const handleShowLoginForm = () => {
    setShowPopupConfirm(false)
    setShowLoginForm(true)
  }

  const handleShowSignUpForm = () => {
    setShowPopupConfirm(false)
    setShowSignUpForm(true)
  }

  const callbackForForm = () => {
    const creditAppsInSession = sessionStorage.getItem(STORAGE_KEYS.CUSTOMER_CREDIT_APPS)
    if (creditAppsInSession) {
      const params = {
        _id: creditAppsInSession,
        link_code: linkCode
      }
      updateCreditApplication(params)
        .then(() => {
          sessionStorage.removeItem(STORAGE_KEYS.CUSTOMER_CREDIT_APPS)
        })
        .catch((error) => error)
    }
    navigate('/customer-dashboard')
  }

  return (
    <div className="currentStep-wrapper">
      <div className="flex justify-center">
        <div className="w-full">
          <div className={currentStep === 1 ? 'block' : 'hidden'}>
            <ApplicationSection applicationFormRef={applicationFormRef} />
          </div>
          <div className={currentStep === 2 ? 'block' : 'hidden'}>
            <HomeAddress
              homeAddressFormRef={homeAddressFormRef}
              previousHomeAddressRef={previousHomeAddressRef}
              selectedResidentialStatus={selectedResidentialStatus}
              handleChangeResidentialStatus={handleChangeResidentialStatus}
              preSelectedResidentialStatus={preSelectedResidentialStatus}
              handleChangePreResidentialStatus={handleChangePreResidentialStatus}
            />
          </div>
          <div className={currentStep === 3 ? 'block' : 'hidden'}>
            <DriverLicense driverLicenseFormRef={driverLicenseFormRef} />
          </div>
          <div className={currentStep === 4 ? 'block' : 'hidden'}>
            <JobInformation
              jobInformationFormRef={jobInformationFormRef}
              previousInformationFormRef={previousInformationFormRef}
              jobType={jobType}
              handleChangeJobType={handleChangeJobType}
              preJobType={preJobType}
              handleChangePreJobType={handleChangePreJobType}
            />
          </div>
          <div className={currentStep === 5 ? 'block' : 'hidden'}>
            <AdditionalIncome additionalIncomeFormRef={additionalIncomeFormRef} />
          </div>
          <div className={currentStep === 6 ? 'block' : 'hidden'}>
            <References referencesFormRef={referencesFormRef} />
          </div>
          <div className={currentStep === 7 ? 'block' : 'hidden'}>
            <Summary
              ref={summaryPageRef}
              formData={{
                application: applicationFormRef.current?.getFieldsValue(),
                homeAddress: homeAddressFormRef.current?.getFieldsValue(),
                driverLicense: driverLicenseFormRef.current?.getFieldsValue(),
                jobInformation: jobInformationFormRef.current?.getFieldsValue(),
                additionalIncome: additionalIncomeFormRef.current?.getFieldsValue(),
                references: referencesFormRef.current?.getFieldsValue()?.referenceList
              }}
            />
          </div>
          <div className="flex justify-between gap-3 mt-12">
            <button
              type="button"
              className="bg-primary rounded text-white px-16 py-2 hover:bg-activePrimary"
              onClick={backStep}
              disabled={disabledPrevious}
            >
              Back
            </button>
            {currentStep === 7 && (
              <button
                type="button"
                className="bg-primary rounded text-white px-8 py-2 hover:bg-activePrimary"
                onClick={() => handlePrint()}
              >
                Print Application
              </button>
            )}
            <div>
              {currentStep === 6 && !isMobile && (
                <button
                  type="button"
                  className="bg-primary rounded text-white px-16 py-2 hover:bg-activePrimary mr-2"
                  onClick={nextStep}
                >
                  View Full
                </button>
              )}

              {currentStep < 6 && (
                <button
                  type="button"
                  className="bg-primary rounded text-white px-10 py-2 hover:bg-activePrimary"
                  onClick={nextStep}
                >
                  Next
                </button>
              )}

              {currentStep === 7 && (
                <button
                  type="button"
                  className="bg-primary rounded text-white px-10 py-2 hover:bg-activePrimary"
                  onClick={nextStep}
                >
                  Save and Send
                </button>
              )}
            </div>
          </div>
          <div className="mt-5 text-center">
            {currentStep === 6 && isMobile && (
              <button
                type="button"
                className="bg-primary rounded text-white px-16 py-2 hover:bg-activePrimary mr-2"
                onClick={nextStep}
              >
                View Full
              </button>
            )}
          </div>
        </div>
      </div>

      <AuthPopup
        show={showPopupConfirm}
        setShow={setShowPopupConfirm}
        handleShowLoginForm={handleShowLoginForm}
        handleShowSignUpForm={handleShowSignUpForm}
        closable={false}
        maskClosable={false}
        setLoginRequired={setLoginRequired}
      />

      <LoginFormModal
        showModal={showLoginForm}
        setShowModal={setShowLoginForm}
        callback={callbackForForm}
        setShowCashDownPopupConfirm={setShowCashDownPopupConfirm}
      />
      <SignUpFormModal
        showModal={showSignUpForm}
        setShowModal={setShowSignUpForm}
        callback={callbackForForm}
        setShowCashDownPopupConfirm={setShowCashDownPopupConfirm}
      />
    </div>
  )
}

BodyStepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  linkCode: PropTypes.string
}

export default BodyStepper
