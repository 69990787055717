import { Modal } from 'antd'
import * as PropTypes from 'prop-types'
import React from 'react'

function VehicleValueNotFoundPopup({ showPopup, setShowPopup }) {
  return (
    <Modal
      centered
      title="Vehicle value not found"
      visible={showPopup}
      closable
      onCancel={() => setShowPopup(false)}
      className="calendar-modal"
      width={450}
      footer={false}
    >
      <div className="w-full text-center">
        <p>We are sorry, an estimated value for your trade-in vehicle could not be calculated.</p>
      </div>
    </Modal>
  )
}

VehicleValueNotFoundPopup.propTypes = {
  showPopup: PropTypes.bool,
  setShowPopup: PropTypes.func
}

export default VehicleValueNotFoundPopup
