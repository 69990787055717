export const createDealershipAdapter = (data) => ({
  dealer_name: data.dealerName,
  first_name: data.firstName,
  last_name: data.lastName,
  contacts: {
    address: data.address,
    city: data.city,
    state: data.state,
    zip: data.zip,
    email: data.email,
    phone: data.phone === '(___) ___-____' ? '' : data.phone,
    urls: {
      powersports_dealer: data.website
    }
  },
  type: 'dealership',
  isUser: data.isUser
})

export const createDealerAdapter = (data) => ({
  dealer_id: data.dealerId,
  first_name: data.firstName,
  last_name: data.lastName,
  contacts: {
    email: data.email,
    phone: data.phone === '(___) ___-____' ? '' : data.phone
  },
  permissions: data.permissions,
  type: 'dealer',
  isUser: data.isUser
})
