import { Button, Modal } from 'antd'
import * as PropTypes from 'prop-types'
import React from 'react'

function BankRoutingNumberPopup({ show, setShow, setResetNumber, closable, maskClosable }) {
  const onCancel = () => {
    setShow(false)
  }

  const onClickNo = () => {
    setShow(false)
    setResetNumber('')
  }

  return (
    <Modal
      centered
      title="Bank Routing Number check"
      visible={show}
      closable={closable}
      maskClosable={maskClosable}
      onCancel={() => onCancel()}
      className="calendar-modal"
      width={450}
      footer={[
        <div className="flex justify-between gap-4">
          <Button block onClick={() => onCancel()}>
            Yes
          </Button>
          <Button block onClick={() => onClickNo()}>
            No
          </Button>
        </div>
      ]}
    >
      <div className="w-full text-center">
        <p>Number is Invalid, do you want to proceed with this number?</p>
      </div>
    </Modal>
  )
}

BankRoutingNumberPopup.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  setResetNumber: PropTypes.func,
  closable: PropTypes.bool,
  maskClosable: PropTypes.bool
}

export default BankRoutingNumberPopup
