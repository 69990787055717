import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons'
import { Pagination } from 'antd'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { STORAGE_KEYS } from 'src/constants/constants'
import { load, save } from 'src/lib/storage'
import * as BuyService from '../../../services/buy.service'
import InventoryVehicleCard from './InventoryVehicleCard'

function MyInventory({ dealershipAccount, searchKeyword, refetchInventory, handleRefetchVehicle }) {
  const favouriteCarsLocal = load(STORAGE_KEYS.FAVOURITE_CARS) || []
  const pagination = load(STORAGE_KEYS.INVENTORY_PAGINATION) || {}
  const [favouriteCars, setFavouriteCars] = useState(favouriteCarsLocal)
  const [vehicles, setVehicles] = useState([])
  const [page, setPage] = useState(pagination?.page || 1)
  const [perPage, setPerPage] = useState(pagination?.perPage || 10)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)
  const [canEditInventory, setCanEditInventory] = useState(false)

  useEffect(() => {
    let mounted = true

    setCanEditInventory(dealershipAccount?.permissions?.edit_inventory)
    const params = queryString.stringify({
      page,
      per_page: perPage,
      keyword: searchKeyword
    })
    BuyService.dealershipVehicles(params)
      .then((response) => {
        if (mounted) {
          setVehicles(response.data.data)
          setPage(response.data.current_page)
          setTotal(response.data.total)
        }
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => {
        setLoading(false)
        mounted = false
      })
  }, [page, perPage, dealershipAccount, searchKeyword, refetchInventory])

  const handleScrollFirstRow = () => {
    const inventoryElement = document.getElementById('my-inventory-col')
    save(STORAGE_KEYS.INVENTORY_PAGINATION, JSON.stringify({}))
    inventoryElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
  }

  useEffect(() => {
    const inventoryElement = document.getElementById('my-inventory-col')
    if (pagination.page && inventoryElement) {
      handleScrollFirstRow()
    }
  }, [pagination])

  const handleChangePage = (value) => {
    setPage(value)
    handleScrollFirstRow()
  }

  const handleChangePageSize = (current, size) => {
    setPerPage(size)
  }

  const goToFirstPage = () => {
    if (page !== 1) setPage(1)
  }
  const goToLastPage = () => {
    if (page !== total) setPage(total)
  }

  if (loading) {
    return (
      <div className="mb-6">
        <div className="loader mx-auto my-44" />
      </div>
    )
  }

  return (
    <>
      <div className="grid grid-cols-1 xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 gap-6 mb-6" id="my-inventory-col">
        {vehicles.map((deal) => {
          return (
            <InventoryVehicleCard
              vehicle={deal}
              key={deal._id}
              favouriteCars={favouriteCars}
              setFavouriteCars={setFavouriteCars}
              canEditInventory={canEditInventory}
              page={page}
              perPage={perPage}
              handleRefetchVehicle={handleRefetchVehicle}
            />
          )
        })}
      </div>
      <div className="flex justify-end mb-8">
        <div className="flex items-center">
          <button
            type="button"
            className={`custom-pagination custom-first ${page === 1 ? 'disabled' : ''}`}
            onClick={goToFirstPage}
          >
            <DoubleLeftOutlined />
          </button>
          <Pagination
            className="flex"
            current={page}
            responsive
            total={vehicles.length > 0 ? total : 0}
            pageSize={perPage}
            onChange={handleChangePage}
            onShowSizeChange={handleChangePageSize}
            pageSizeOptions={[10, 20, 50]}
            showSizeChanger
          />
          <button
            type="button"
            className={`custom-pagination custom-last ${
              page === Math.ceil((vehicles.length > 0 ? total : 0) / perPage) ? 'disabled' : ''
            }`}
            onClick={goToLastPage}
          >
            <DoubleRightOutlined />
          </button>
        </div>
      </div>
    </>
  )
}

MyInventory.propTypes = {
  dealershipAccount: PropTypes.object,
  searchKeyword: PropTypes.string,
  refetchInventory: PropTypes.bool,
  handleRefetchVehicle: PropTypes.func
}

export { MyInventory }
