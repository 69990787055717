import { notification, Progress } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import Footer from 'src/components/Footer'
import { STORAGE_KEYS } from 'src/constants/constants'
import { clear, load } from 'src/lib/storage'
import * as AuthServices from '../../services/auth.service'
import { getPublicIp } from '../../utils'
import { claimBusinessPayloadAdapter, claimEmailPayloadAdapter } from './adapters'
import AddYourDealershipDetail from './AddYourDealershipDetail'
import BasicInventoryListingEnrollment from './BasicInventoryListingEnrollment'
import DealershipDetails from './DealershipDetails'
import DealershipInfo from './DealershipInfo'
import FindYourDealership from './FindYourDealership'
import Header from './Header'
import PSXProcess from './PSXProcess'
import './style.scss'
import TermsAndConditions from './TermsAndConditions'
import VerifyYourAccountOwnership from './VerifyYourAccountOwnership'
import SinglePageMetaDecorator from '../../decorators/SinglePageMetaDecorator'
import metaPages from '../../data/metaPages.json'
import metaPageImage from '../../assets/jpg/claimDealership.jpg'

function Title({ title, step }) {
  return (
    <div className="flex flex-row justify-between items-center flex-wrap gap-3">
      <div className="flex flex-row gap-2 items-center">
        <div className="step-number">{step}</div>
        <div className="font-bold text-xl capitalize">{title}</div>
      </div>
      <div className="xl:w-60 lg:w-60 w-full flex flex-row gap-3">
        <Progress className="text-secondary-color" showInfo={false} percent={(step / 7) * 100} />
        <div className="flex flex-row font-semibold gap-1 text-lg w-20">
          <div style={{ color: '#2168ba' }}>{`${step} `}</div>
          <div className="text-secondary-color">of 7</div>
        </div>
      </div>
    </div>
  )
}

Title.propTypes = {
  title: PropTypes.string,
  step: PropTypes.number
}

function ClaimYourBusinessV3() {
  const [step, setStep] = useState(1)
  const [description, setDescription] = useState()
  const [isManualInputDealerShip, setIsManualInputDealership] = useState(false)
  const [selectedDealership, setSelectedDealership] = useState({})
  const [triggerUploadDealershipLogo, setTriggerUploadDealershipLogo] = useState()
  const [dealerId, setDealerId] = useState()
  const userAgent = window.navigator.userAgent

  // Step 2 data
  const [dealershipDetail, setDealershipDetail] = useState({})

  // Step 5 data
  const [dealershipDetailStep5, setDealershipDetailStep5] = useState(undefined)

  // Step 3 data
  const [dealershipInfo, setDealershipInfo] = useState({})

  // Step 4 data
  const [paymentInfo, setPaymentInfo] = useState({})

  const [signUpData, setSignUpData] = useState({ dealership: {}, employee: {} })

  const backStep = useCallback(() => {
    setStep(step - 1)
  }, [step, setStep])

  const nextStep = useCallback(() => {
    setStep(step + 1)
  }, [step, setStep])

  const openNotification = ({ message, description, type = 'success' }) => {
    const action = type === 'success' ? notification.success : notification.error
    action({
      message,
      description
    })
  }

  const getClaimBusinessPayloadMailAdapter = (values) => {
    return claimEmailPayloadAdapter({
      dealershipDetail,
      paymentInfo: values || paymentInfo,
      isManualInputDealerShip,
      userAgent,
      step
    })
  }

  const getClaimBusinessPayloadAdapter = () => {
    return claimBusinessPayloadAdapter(
      selectedDealership,
      dealershipDetail,
      dealershipInfo,
      paymentInfo,
      dealershipDetailStep5,
      userAgent,
      step
    )
  }

  useEffect(() => {
    const startStep = load(STORAGE_KEYS.START_STEP_CLAIM_YOUR_DEALERSHIP, false)
      ? parseInt(load(STORAGE_KEYS.START_STEP_CLAIM_YOUR_DEALERSHIP, false), 10)
      : 1
    if (startStep === 2) {
      const startDealership = load(STORAGE_KEYS.START_DEALERSHIP_CLAIM_YOUR_DEALERSHIP) || {}
      setStep(2)
      setSelectedDealership(startDealership)
      setDealershipDetail({
        ...startDealership,
        website: startDealership.powersportsDealerUrl,
        physicalAddress: startDealership.address,
        representativeName: startDealership.dealerName
      })
      clear([STORAGE_KEYS.START_STEP_CLAIM_YOUR_DEALERSHIP, STORAGE_KEYS.START_DEALERSHIP_CLAIM_YOUR_DEALERSHIP])
    }
  }, [])

  const onSubmitDealer = async () => {
    try {
      const payload = getClaimBusinessPayloadAdapter()
      payload.ip = await getPublicIp()
      setTriggerUploadDealershipLogo(true)
      await AuthServices.dealershipSignup(payload).then((response) => {
        setSignUpData(response.data)
        setDealerId(response.data.dealer_id)
        setTriggerUploadDealershipLogo(true)
        openNotification({
          message: 'Dealership sign up successful',
          description: 'Click link in email to verify your business'
        })
        nextStep(7)
      })
    } catch (error) {
      openNotification({ message: error.message, type: 'error' })
      console.error(error)
    }
  }

  const onChangeStepSave = async () => {
    const payload = getClaimBusinessPayloadAdapter()
    payload.ip = await getPublicIp()
    await AuthServices.dealershipSaveClaimProcessStep(payload)
  }

  useEffect(() => {
    let title = 'Add your dealership details'
    switch (step) {
      case 2:
        if (isManualInputDealerShip) {
          title = 'Add your dealership details'
        } else {
          title = 'Verify Your Account Ownership'
        }
        break
      case 3:
        title = 'Dealership info'
        break
      case 4:
        title = 'Basic Inventory Listing Enrollment'
        break
      case 5:
        title = 'Dealership details'
        break
      case 6:
        title = 'MSA and License Agreement with Terms and Conditions'
        break
      default:
        title = 'Done'
    }
    setDescription(title)
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
    if (step > 1 && step < 8) {
      onChangeStepSave()
    }
  }, [step])

  const handleValidAccRequest = (callback, email) => {
    AuthServices.checkValidAccount(email)
      .then((response) => {
        const { data } = response
        if (data) {
          callback()
        } else {
          openNotification({ message: 'Email already exists!', type: 'error' })
        }
      })
      .catch(() => openNotification({ message: 'Email already exists!', type: 'error' }))
  }

  return (
    <>
      <SinglePageMetaDecorator
        title={metaPages.claim.title}
        description={metaPages.claim.description}
        imageUrl={metaPageImage}
        imageAlt={metaPages.claim.imageAlt}
      />
      {step === 1 && (
        <div>
          <div className="w-screen relative find-your-dealership">
            <Header />
            <FindYourDealership
              nextStep={nextStep}
              selectedDealership={selectedDealership}
              setSelectedDealership={setSelectedDealership}
              setDealershipDetail={setDealershipDetail}
              setIsManualInputDealership={setIsManualInputDealership}
            />
            <Footer />
          </div>
        </div>
      )}

      {step > 1 && (
        <>
          <Header />
          <div className="bg-gray-50 claim-your-business">
            <div className="!px-0 lg:px-4 container">
              <div className="flex justify-center">
                <div className="lg:w-10/12 w-full">
                  {step > 1 && (
                    <div className="shadow-xl rounded-2xl my-10 p-5 lg:p-8 bg-white">
                      <Title step={step} title={description} />
                      {/* step 2A */}
                      <div className={`${step === 2 && isManualInputDealerShip ? '' : 'hidden'}`}>
                        <AddYourDealershipDetail
                          nextStep={nextStep}
                          backStep={backStep}
                          setDealershipDetail={setDealershipDetail}
                          dealershipDetail={dealershipDetail}
                          handleValidAccRequest={handleValidAccRequest}
                        />
                      </div>

                      {/* step 2B */}
                      <div className={`${step === 2 && !isManualInputDealerShip ? '' : 'hidden'}`}>
                        <VerifyYourAccountOwnership
                          backStep={backStep}
                          nextStep={nextStep}
                          selectedDealership={selectedDealership}
                          dealershipDetail={dealershipDetail}
                          setDealershipDetail={setDealershipDetail}
                          handleValidAccRequest={handleValidAccRequest}
                        />
                      </div>
                      {/* step 3 */}
                      <div className={`${step === 3 ? '' : 'hidden'}`}>
                        <DealershipInfo
                          dealershipDetail={dealershipDetail}
                          backStep={backStep}
                          nextStep={nextStep}
                          setDealershipInfo={setDealershipInfo}
                          setTriggerUploadDealershipLogo={setTriggerUploadDealershipLogo}
                          triggerUploadDealershipLogo={triggerUploadDealershipLogo}
                          dealerId={dealerId}
                        />
                      </div>

                      {/* step 4 */}
                      <div className={`${step === 4 ? '' : 'hidden'}`}>
                        <BasicInventoryListingEnrollment
                          dealershipDetail={dealershipDetail}
                          dealershipInfo={dealershipInfo}
                          backStep={backStep}
                          nextStep={nextStep}
                          setPaymentInfo={setPaymentInfo}
                          getClaimBusinessPayloadMailAdapter={getClaimBusinessPayloadMailAdapter}
                          step={step}
                        />
                      </div>

                      {/* step 5 */}
                      <div className={`${step === 5 ? '' : 'hidden'}`}>
                        <DealershipDetails
                          dealershipDetail={dealershipDetail}
                          setDealershipDetail={setDealershipDetailStep5}
                          backStep={backStep}
                          nextStep={nextStep}
                        />
                      </div>

                      {/* Step 6 */}
                      <div className={`${step === 6 ? '' : 'hidden'}`}>
                        <TermsAndConditions
                          backStep={backStep}
                          onSubmit={onSubmitDealer}
                          getClaimBusinessPayloadMailAdapter={getClaimBusinessPayloadMailAdapter}
                          dealershipDetail={dealershipDetail}
                          paymentInfo={paymentInfo}
                        />
                      </div>

                      {/* step 7 */}
                      <div className={`${step === 7 ? '' : 'hidden'}`}>
                        <PSXProcess
                          signUpData={signUpData}
                          getClaimBusinessPayloadMailAdapter={getClaimBusinessPayloadMailAdapter}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  )
}

export default ClaimYourBusinessV3
