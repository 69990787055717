import { Collapse } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { AiOutlineMinus } from 'react-icons/ai'
import { GrAdd } from 'react-icons/gr'
import './style.scss'

const { Panel } = Collapse

function OptionalEquipment({ options }) {
  const expandIcon = (panelProps) => {
    return panelProps.isActive ? <AiOutlineMinus /> : <GrAdd />
  }
  return (
    <div className="my-4">
      <p className="text-2xl font-medium text-primary mb-3">OPTIONAL EQUIPMENTS</p>
      <div className="border border-lightGray rounded-xl p-4">
        <div className="flex flex-col item-center">
          {options &&
            Object.keys(options).map((key) => {
              const subCategory = options[key]
              return (
                <Collapse ghost expandIcon={expandIcon}>
                  <Panel header={key} key={key}>
                    {subCategory &&
                      Object.keys(subCategory).map((subTitle) => (
                        <Collapse ghost expandIcon={expandIcon}>
                          <Panel header={subTitle} key={subTitle}>
                            {subCategory[subTitle].map((description, idx) => (
                              <p key={idx}>●&nbsp; {description?.option_description}</p>
                            ))}
                          </Panel>
                        </Collapse>
                      ))}
                  </Panel>
                </Collapse>
              )
            })}
        </div>
      </div>
    </div>
  )
}

OptionalEquipment.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default OptionalEquipment
