import { message } from 'antd'

export const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}
export const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!')
  }
  const isLt2M = file.size / 1024 / 1024 <= 2
  if (!isLt2M) {
    message.error('Image must smaller than or equal 2MB!')
  }
  return isJpgOrPng && isLt2M
}
