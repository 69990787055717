import { isNaN } from 'lodash'
import * as PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

const invalidChars = [
  "-",
  "+",
  "e"
]

function NumberInput({ onChange, ...rest }) {
  const inputRef = useRef()

  const handleChange = (event) => {
    const value = event.target.value
    if (!isNaN(value) && value >= 0) {
      onChange(event)
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener("keydown", (e) => {
        if (invalidChars.includes(e.key)) {
          e.preventDefault()
        }
      })
    }
  }, [])

  return <input
    {...rest}
    onChange={handleChange}
    type="number"
    ref={inputRef}
  />
}

NumberInput.propTypes = {
  onChange: PropTypes.func
}

export default NumberInput
