import { DoubleLeftOutlined, DoubleRightOutlined, MinusOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Modal, Pagination, Table, notification } from 'antd'
import parse from 'html-react-parser'
import * as PropTypes from 'prop-types'
import queryString from 'query-string'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { USER_ROLES } from 'src/constants/constants'
import * as BuyService from 'src/services/buy.service'
import * as dealerService from '../../../services/dealer.service'
import * as notificationService from '../../../services/notification.service'
import './style.scss'

const vehicleColumns = (action, vehicleIds) => [
  {
    title: 'Stock',
    dataIndex: 'stock_number'
  },
  {
    title: 'Year',
    dataIndex: 'year'
  },
  {
    title: 'Make',
    dataIndex: 'make'
  },
  {
    title: 'Model',
    dataIndex: 'model'
  },
  {
    title: 'Add',
    dataIndex: '_id',
    render: (vehicleId, record) =>
      vehicleIds.includes(vehicleId) ? (
        <Button type="secondary" icon={<MinusOutlined />} onClick={() => action(record)} />
      ) : (
        <Button type="primary" icon={<PlusOutlined />} onClick={() => action(record)} />
      )
  }
]

function MessageHistory({ notifications, offer, fetchData, refresh, showMore, setShowMore }) {
  const [chatHistory, setChatHistory] = useState([])
  const callReadMessageRef = useRef(true)

  useEffect(() => {
    if (refresh) {
      callReadMessageRef.current = false
      notificationService
        .markReadedMessages({ type: offer?.record_type, id: offer?.record_id })
        .then(() => {
          fetchData()
        })
        .catch((error) => console.error(error))
    }

    if (!refresh) {
      callReadMessageRef.current = true
    }
  }, [refresh, offer])

  const handleShowMoreMsg = () => {
    setShowMore(!showMore)
  }

  useEffect(() => {
    if (notifications.length > 0) {
      const initialData = notifications
        .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
        .filter((_, idx) => idx < 2)
      setChatHistory(initialData)
    } else {
      setChatHistory([])
    }
  }, [notifications])

  useEffect(() => {
    if (showMore) {
      setChatHistory(notifications)
    } else {
      const filteredData = notifications.filter((_, idx) => idx < 2)
      setChatHistory(filteredData)
    }
  }, [showMore])

  const renderMessage = useCallback((email) => {
    const { message, shared_vehicles: sharedLinks } = email
    if (sharedLinks?.length > 0) {
      const elementSharedLinks = sharedLinks?.map((item) => (
        <a href={item.url} className="text-blue-500">
          <span>{item.name};</span>
        </a>
      ))
      return (
        <>
          <div className="message message-comments">{message}</div>
          <div className="flex gap-2 flex-wrap close-line-height">
            <span>Shared vehicles:</span>
            {elementSharedLinks}
          </div>
        </>
      )
    }
    return (
      <div key="" className="message message-comments">
        {parse(message)}
      </div>
    )
  }, [])

  return (
    <>
      <div className="chatWindow mb-3 border">
        <ul className="chat" id="chatList">
          <div className="history-message flex justify-between mb-3">
            <p className="history-text font-bold ml-2">History</p>
          </div>
          {chatHistory?.length === 0 && <p className="text-center">There is no email history for this customer</p>}
          {chatHistory?.length > 0 &&
            chatHistory?.map((n, index) => (
              <li key={`email-history-${index}`} className={`${n.class_name} w-full}`}>
                <div className="msg">
                  <div className="message-name-container flex justify-between">
                    {n.name ? (
                      <div className="message message-name text-base font-bold">{n.name}:</div>
                    ) : (
                      <div className="message message-name text-base text-blue-500 font-bold">{offer?.customer}:</div>
                    )}
                    <div className="message message-created-at text-xs">{n.created_at}</div>
                  </div>
                  <div className="message font-semibold text-sm">{n.subject}</div>
                  {renderMessage(n)}
                </div>
              </li>
            ))}
        </ul>
      </div>
      {chatHistory.length > 1 && (
        <div className="w-full bg-gray-400 text-white flex justify-center p-1 text-md mb-3" onClick={handleShowMoreMsg}>
          {showMore ? (
            <div className="flex content-center justify-center flex-wrap cursor-pointer">
              <div className="flex content-center justify-center flex-wrap">
                <BiMinus className="text-lg" />
              </div>
              <p className="font-bold">View Less</p>
            </div>
          ) : (
            <div className="flex content-center justify-center flex-wrap cursor-pointer">
              <div className="flex content-center justify-center flex-wrap">
                <BiPlus className="text-lg" />
              </div>
              <p className="font-bold">View More</p>
            </div>
          )}
        </div>
      )}
    </>
  )
}

MessageHistory.propTypes = {
  notifications: PropTypes.array,
  offer: PropTypes.object,
  fetchData: PropTypes.func,
  refresh: PropTypes.bool,
  showMore: PropTypes.bool,
  setShowMore: PropTypes.func
}

export function SendEmailModal({
  offer,
  notifications,
  showModal,
  dealershipAccount,
  handleCloseModal,
  handleCallback,
  fetchData,
  handleHistoryNotifications,
  cPopId,
  useFor
}) {
  /*   const canAssignLeads = undefined !== dealershipAccount ? dealershipAccount?.permissions?.assign_leads : true
  const canTrackEngagements = undefined !== dealershipAccount ? dealershipAccount?.engagement_tracking : true */

  const role = undefined !== dealershipAccount ? dealershipAccount?.role : USER_ROLES.DEALERSHIP_ADMIN
  const fromUserId = undefined !== dealershipAccount ? dealershipAccount?.employee_id : null

  const [noReplyNeeded, setNoReplyNeeded] = useState(false)
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [vehicles, setVehicles] = useState([])
  const [selectedVehicles, setSelectedVehicles] = useState([])

  const [showSearchVehicle, setShowSearchVehicle] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [searchKeyword, setSearchKeyword] = useState('')
  const [total, setTotal] = useState(0)

  const [loading] = useState(true)
  const searchRef = useRef()
  const [searchLoading, setSearchLoading] = useState(false)
  const [vehicleIds, setVehicleIds] = useState([])

  const [cPop, setCPop] = useState(false)
  const [credit, setCredit] = useState(false)
  const [appraisal, setAppraisal] = useState(false)
  const [inventory, setInventory] = useState(false)

  const [disableSendBtn, setDisableSendBtn] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [showMore, setShowMore] = useState(false)

  const renderSelectedVehicle = useMemo(() => {
    const result = selectedVehicles.map((item) => `${item.year} ${item.make} ${item.model}`).join(', ')
    return result
  }, [selectedVehicles])

  const openNotification = ({ message, description, type = 'success' }) => {
    const action = type === 'success' ? notification.success : notification.error
    action({
      message,
      description
    })
  }

  const addVehicleToMail = (vehicle) => {
    const { _id: vehicleId } = vehicle
    if (vehicleIds.includes(vehicleId)) {
      const newSelectedVehicles = selectedVehicles.filter((item) => item._id !== vehicleId)
      const newSelectedVehicleIds = newSelectedVehicles.map((item) => item._id)
      setVehicleIds(newSelectedVehicleIds)
      setSelectedVehicles(newSelectedVehicles)
    } else {
      setVehicleIds([...vehicleIds, vehicleId])
      setSelectedVehicles([...selectedVehicles, vehicle])
    }
  }

  useEffect(() => {
    setDisableSendBtn(vehicleIds.length <= 0 ? !subject || !message : false)
  }, [vehicleIds, subject, message])

  useEffect(() => {
    if (showSearchVehicle) {
      const params = queryString.stringify({
        page,
        per_page: perPage,
        keyword: searchKeyword
      })
      setSearchLoading(true)
      BuyService.dealershipVehicles(params)
        .then((response) => {
          setVehicles(response.data.data)
          setPage(response.data.current_page)
          setTotal(response.data.total)
        })
        .catch((e) => {
          console.error(e)
        })
        .finally(() => setSearchLoading(false))
    }
  }, [page, perPage, searchKeyword, showSearchVehicle])

  const handleNoReplyNeedChange = () => {
    setNoReplyNeeded(!noReplyNeeded)
    const { record_id: recordId, record_type: recordType } = offer

    const params = {
      record_id: recordId,
      record_type: recordType,
      no_reply_needed: noReplyNeeded ? 'off' : 'on'
    }

    const successMsg = noReplyNeeded ? 'Successfully updated' : 'Successfully updated No reply needed'

    dealerService
      .updateNoReplyNeeded(params)
      .then(() => {
        openNotification({ message: successMsg })
      })
      .catch((e) => {
        openNotification({ message: e.message, type: 'error' })
      })
  }

  const handleSearchInventory = useCallback(() => {
    const value = searchRef.current.value
    setSearchKeyword(value)
  }, [])

  const pressEnterKey = (event) => {
    if (event.charCode === 13 && !loading) {
      handleSearchInventory(event)
    }
  }

  const handleSearchInputChange = (event) => {
    if (event.target.value.trim().length <= 0 && !loading) {
      handleSearchInventory(event)
    }
  }

  const handleEmailHistory = () => {
    const { record_id: recordId, record_type: recordType, no_reply_needed: noReplyNeeded } = offer || {}

    setNoReplyNeeded(noReplyNeeded)
    handleHistoryNotifications(recordId, recordType)
  }

  const handleSendCPop = () => {
    setCPop(!cPop)
  }

  useEffect(() => {
    setCredit(false)
    setAppraisal(false)

    if (useFor === 'credit-app') {
      setCredit(true)
      setSubject(`${offer?.dealerName} - Credit Application Request`)
      setMessage('Please use the tool below to submit a credit application.')
    }

    if (useFor === 'trade') {
      setAppraisal(true)
      setSubject(`${offer?.dealerName} - Value Your Trade Request`)
      setMessage('Please use the tool below to value your trade.')
    }
  }, [useFor])

  const handleSendCredit = () => {
    setCredit(!credit)
  }

  const handleSendAppraisal = () => {
    setAppraisal(!appraisal)
  }

  const handleSendInventory = () => {
    setInventory((inventory) => {
      if (inventory) {
        setSelectedVehicles([])
        setVehicleIds([])
      }
      return !inventory
    })
    setShowSearchVehicle(!showSearchVehicle)
  }

  const goToFirstPage = () => {
    if (page !== 1) setPage(1)
  }
  const goToLastPage = () => {
    if (page !== total) setPage(total)
  }

  const handleChangePage = (value) => {
    setPage(value)
  }

  const handleChangePageSize = (current, size) => {
    setPerPage(size)
  }

  const handleSendEmail = () => {
    const types = []
    let tradeForVehicle = ''
    let creditAppForVehicle = ''

    if (inventory) types.push('inventory')

    if (appraisal) {
      types.push('trade-in')
      tradeForVehicle = offer?.vehicle_id
    }

    if (credit) {
      types.push('credit-application')
      creditAppForVehicle = offer?.vehicle_id
    }

    const params = {
      subject,
      message,
      to: offer?.email,
      name: 'test',
      recordId: offer?.record_id,
      recordType: offer?.record_type,
      role,
      fromUserId,
      toUserId: offer?.customer_id,
      vehicleIds,
      no_reply_needed: noReplyNeeded ? 'off' : 'on',
      types,
      has_c_pop_id: cPop ? cPopId : '',
      tradeForVehicle,
      creditAppForVehicle
    }

    if (!subject && !message && vehicleIds.length <= 0) {
      return openNotification({
        message: 'Please fill in the input fields or select the appropriate button',
        type: 'error'
      })
    }

    dealerService
      .sendEmail(params)
      .then(() => {
        openNotification({ message: 'Email sent successfully' })
        handleEmailHistory()
        setSearchKeyword('')
        setShowSearchVehicle(false)
        setVehicleIds([])
        setSelectedVehicles([])
        setCPop(false)
        setCredit(false)
        setAppraisal(false)
        setInventory(false)
        setRefresh(true)
        setSubject('')
        setMessage('')
        setShowMore(false)
      })
      .catch((e) => {
        openNotification({ message: e.message, description: e.response.data.message, type: 'error' })
      })
  }

  return (
    <Modal
      centered
      visible={showModal}
      title="Send email to customer"
      onOk={handleCloseModal}
      onCancel={() => {
        handleCloseModal()
        handleCallback()
        setShowMore(false)
      }}
      okText="Close"
      className="calendar-modal send-email-modal rounded-3xl"
      destroyOnClose
      width={600}
      footer={[]}
    >
      <MessageHistory
        notifications={notifications}
        offer={offer}
        fetchData={fetchData}
        refresh={refresh}
        setRefresh={setRefresh}
        setShowMore={setShowMore}
        showMore={showMore}
        className="w-1/2"
      />

      <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-12 gap-1">
        <div className="col-span-2 mr-4 text-stone-700 mb-3 font-bold">Reply</div>
        <div className="col-span-9 text-right">No reply needed</div>
        <div className="col-span-1 text-right">
          <input
            name="no_reply_needed"
            className="border focus:outline-none"
            type="checkbox"
            onChange={() => handleNoReplyNeedChange()}
            checked={noReplyNeeded}
          />
        </div>
      </div>

      <div className="flex">
        <p className="col-span-2 mr-4 text-base text-slate-400">To:</p>
        <div className="col-span-10">
          <p className="font-bold text-base replay-name">{offer?.customer}</p>
        </div>
      </div>
      <div className="my-4 grid grid-cols-1 md:grid-cols-5 lg:grid-cols-12 gap-1">
        <p className="col-span-2 text-base text-slate-400">Subject:</p>
        <div className="col-span-10">
          <input
            placeholder="Write a subject"
            name="subject"
            className="p-4 py-3 w-full box-border rounded-xl border-gray-300 border focus:outline-none"
            type="text"
            onChange={(e) => setSubject(e.target.value)}
            value={subject}
          />
        </div>
      </div>

      {useFor === 'all' && (
        <div className="my-4 grid grid-cols-1 md:grid-cols-5 lg:grid-cols-12 gap-1">
          <p className="col-span-2 text-base text-slate-400">Vehicles :</p>
          <div className="col-span-10">{renderSelectedVehicle}</div>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-12 gap-1">
        <p className="col-span-2 text-base text-slate-400">Message :</p>
        <div className="col-span-10">
          <textarea
            placeholder="Your message"
            name="message"
            rows="5"
            className="p-4 py-3 w-full box-border rounded-xl border-gray-300 border focus:outline-none"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
        </div>
      </div>
      {showSearchVehicle && (
        <div className="mt-4">
          <div className="w-full flex items-center justify-start mb-5">
            <div className="relative w-3/4">
              <input
                ref={searchRef}
                className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
                type="text"
                placeholder="Search by Year, Make, Model, VIN, Stock Number"
                onChange={handleSearchInputChange}
                onKeyPress={pressEnterKey}
              />
              {searchLoading ? (
                <AiOutlineLoading3Quarters className="text-blue-500 absolute right-3 top-1/3 -translate-y-2/4 animate-spin" />
              ) : (
                <SearchOutlined
                  onClick={handleSearchInventory}
                  className="text-blue-500 absolute right-3 top-1/3 -translate-y-2/4"
                />
              )}
            </div>
          </div>
          <div className="mb-6" id="my-inventory-col">
            <Table
              dataSource={vehicles}
              className="border"
              columns={vehicleColumns(addVehicleToMail, vehicleIds)}
              pagination={{ position: ['none', 'none'] }}
              loading={searchLoading}
            />
          </div>
          <div className="flex justify-end mb-8">
            <div className="flex items-center">
              <button
                type="button"
                className={`custom-pagination custom-first ${page === 1 ? 'disabled' : ''}`}
                onClick={goToFirstPage}
              >
                <DoubleLeftOutlined />
              </button>
              <Pagination
                className="flex"
                current={page}
                responsive
                total={vehicles.length > 0 ? total : 0}
                pageSize={perPage}
                onChange={handleChangePage}
                onShowSizeChange={handleChangePageSize}
                pageSizeOptions={[10, 20, 50]}
                showSizeChanger
              />
              <button
                type="button"
                className={`custom-pagination custom-last ${
                  page === Math.ceil((vehicles.length > 0 ? total : 0) / perPage) ? 'disabled' : ''
                }`}
                onClick={goToLastPage}
              >
                <DoubleRightOutlined />
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="buttons flex justify-end mb-4">
        {useFor === 'all' && cPopId.length > 0 && (
          <Button size="medium" type="primary" className={`mt-5 mr-2 ${cPop ? 'active' : ''}`} onClick={handleSendCPop}>
            C-POP
          </Button>
        )}
        {['all', 'credit-app'].includes(useFor) && (
          <Button
            size="medium"
            type="primary"
            className={`mt-5 mr-2 ${credit ? 'active' : ''}`}
            onClick={handleSendCredit}
          >
            Credit App
          </Button>
        )}

        {['all', 'trade'].includes(useFor) && (
          <Button
            size="medium"
            type="primary"
            className={`mt-5 mr-2 ${appraisal ? 'active' : ''}`}
            onClick={handleSendAppraisal}
          >
            Trade Appraisal
          </Button>
        )}

        {useFor === 'all' && (
          <Button
            size="medium"
            type="primary"
            className={`mt-5 mr-2 ${inventory ? 'active' : ''}`}
            onClick={handleSendInventory}
          >
            Inventory
          </Button>
        )}
      </div>
      <div className="flex justify-end email-send-btn">
        {disableSendBtn ? (
          <Button size="medium" type="disabled" className="mt-5 mr-2" onClick={handleSendEmail} disabled>
            Send
          </Button>
        ) : (
          <Button size="medium" type="primary" className="mt-5 mr-2" onClick={handleSendEmail}>
            Send
          </Button>
        )}
        <Button size="medium" type="primary" className="mt-5" onClick={handleCloseModal}>
          Close
        </Button>
      </div>
    </Modal>
  )
}

SendEmailModal.propTypes = {
  offer: PropTypes.object,
  notifications: PropTypes.array,
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  dealershipAccount: PropTypes.object,
  handleCallback: PropTypes.func,
  fetchData: PropTypes.func,
  handleHistoryNotifications: PropTypes.func,
  cPopId: PropTypes.string,
  useFor: PropTypes.string
}
