import HttpService from './http.service'
import HttpClientService from './httpClient.service'

export function confirmClaimToAdmin(employeeId, dealerId, isCustomPricing = 1) {
  const params = {
    employee_id: employeeId,
    dealer_id: dealerId,
    is_custom_pricing: isCustomPricing
  }
  return HttpService.post('employees/confirm-claimed-to-admin', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getPricingTable() {
  return HttpService.get('get-pricing-tables').then((result) => {
    const { data } = result
    return data
  })
}

export function updatePricing(id, payload) {
  return HttpClientService.put(`pricing/${id}`, payload).then((result) => {
    const { data } = result
    return data
  })
}

export function employeeSendMailAgreement(payload, type) {
  return HttpService.post(`employees/send-mail-agreement/${type}`, payload).then((result) => {
    const { data } = result
    return data
  })
}

export function getLocationFromZipCode(zipcode) {
  return HttpService.get(`zip/get-location/${zipcode}`).then((result) => {
    const { data } = result
    return data
  })
}
