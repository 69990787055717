import { Popover } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import * as VehicleService from '../../../services/vehicle.service'
import './style.scss'

function PriceComparison({ info }) {

  const { year, make, model } = info
  const initialZipRadius = 300

  const [lowestPricedVehicle, setLowestPricedVehicle] = useState(0)
  const [middlePricedVehicle, setMiddlePricedVehicle] = useState(0)
  const [highestPricedVehicle, setHighestPricedVehicle] = useState(0)
  const [lowestMiles, setlowestMiles] = useState(0)
  const [highestMiles, setHighestMiles] = useState(0)
  const [middleMiles, setMiddleMiles] = useState(0)
  const [vehicles, setVehicles] = useState([])
  const [percentage, setPercentage] = useState(0)

  const getPriceComparison = () => {
    const sortedVehicles = vehicles.sort((a, b) => a.price - b.price || b.miles - a.miles)
    const tenPercent = Math.abs(Math.round(sortedVehicles.length * 0.1))
    sortedVehicles.splice(0, tenPercent)
    sortedVehicles.splice(sortedVehicles.length - tenPercent, sortedVehicles.length)

    const lowestPricedVehicle = sortedVehicles[0]
    setLowestPricedVehicle(lowestPricedVehicle.price)
    setlowestMiles(lowestPricedVehicle.miles)

    const mid = Math.abs(Math.floor(sortedVehicles.length / 2))
    const middlePricedVehicle = sortedVehicles[mid]
    setMiddlePricedVehicle(middlePricedVehicle.price)
    setMiddleMiles(middlePricedVehicle.miles)

    const highestPricedVehicle = sortedVehicles[sortedVehicles.length -1]
    setHighestPricedVehicle(highestPricedVehicle.price)
    setHighestMiles(highestPricedVehicle.miles)
  }

  const getVehicles = async () => {
    const params = {
      dealerId : info.dealer_id,
      zip : info.dealer_zip,
      zipRadius: initialZipRadius,
      year,
      make,
      model
    }

    const result = await VehicleService.getVehiclesBy(params)
    setVehicles(result.data)
  }

  const getSliderPercentage = () => {

    const min = 0
    const max = 70

    const percentage = ((info.price - lowestPricedVehicle)/(highestPricedVehicle-lowestPricedVehicle))*100

    if (percentage < 0) {
      setPercentage(min)
    } else if (percentage > 100) {
      setPercentage(max)
    }else {  
      setPercentage(percentage)
    }
  }

  useEffect(() => {
    if (info && vehicles.length === 0) {
      getVehicles()
    }
  }, [info, vehicles])

  useEffect(() => {
    if (vehicles.length > 0) {
      getPriceComparison()
    }
  }, [vehicles])

  useEffect(() => {
    getSliderPercentage()
  }, [lowestPricedVehicle, middlePricedVehicle, highestPricedVehicle])

  return (
    <div>
      <div className="toggle-market text-lg">
        <p className='text-primary text-2xl font-bold mb-5'>${info?.price?.toLocaleString()}</p> 
        <div className='btn-compare cursor-pointer text-base float-left rounded'>
          Price Comparison
        </div>
        <Popover
          content={
            <>
              <p>We compare our sale price against other similar vehicles in the market.</p>
              <p>Our price comparison is based on price and mileage.</p>
              <p>It does not include standard equipment and aftermarket installation.</p>
            </>
          }
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            className="ml-2 text-gray-500 cursor-pointer d-inline-block mt-1 fontSize14 float-right"
            height="0.8em"
            width="0.8em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" />
          </svg>
        </Popover>
        <div className="show w-full mt-5">
          <div className='market-rate m-detail rounded'>
            <div className="relative cont mx-3">
              <span className="custom-c-price absolute price-vehicle" style={{ left: `${percentage === 0 ? '-5%' : `calc(${percentage}% - 20px)`}` }}>
                ${info?.price?.toLocaleString()}
              </span>
              <div className="custom-arrow-price absolute arrow-price" style={{ left: `${percentage === 0 ? 0 : `calc(${percentage}% - 5px)`}` }} />
              <span className="custom-c-border absolute z-20 border-price" style={{ left: `${percentage === 0 ? 0 : `calc(${percentage}% - 20px)`}` }}/>
              <div className="line mb-1 relative" > 
                <span className="slider-line-left block absolute" />
                <span className="slider-line-middle block absolute"/>
                <span className="slider-line-right block absolute" />
              </div>
              <div className="grid grid-flow-col justify-between low-color fontSize13 overflow-hidden">
                <span className='block text-sm'>Low</span>
                <span className='block text-sm'>High</span>
              </div>
              <div className="grid grid-flow-col justify-between low-color">
                <div>
                  <span className="block text-sm text-black">${lowestPricedVehicle}</span>
                  <span className='block text-sm'>{lowestMiles} miles</span>
                </div>
                <div className="self-end text-center">
                  <span className="block text-sm text-black">${middlePricedVehicle}</span>
                  <span className='block text-sm'>{middleMiles} miles</span>
                </div>
                <div className="text-right">
                  <span className="block text-sm text-black">${highestPricedVehicle}</span>
                  <span className='block text-sm'>{highestMiles} miles</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

PriceComparison.propTypes = {
  info: PropTypes.object
}

export default PriceComparison
