import { Button, Modal } from 'antd'
import * as PropTypes from 'prop-types'
import React from 'react'

function LoginPopup({ show, setShow, handleShowLoginForm, closable, maskClosable }) {
  const onCancel = () => {
    setShow(false)
  }

  return (
    <Modal
      centered
      title="Email exists in system"
      visible={show}
      closable={closable}
      maskClosable={maskClosable}
      onCancel={() => onCancel()}
      className="calendar-modal"
      width={450}
      footer={[
        <div className="flex justify-between gap-4">
          <Button block onClick={handleShowLoginForm}>
            Yes
          </Button>
          <Button block onClick={onCancel}>
            No
          </Button>
        </div>
      ]}
    >
      <div className="w-full text-center">
        <p>This email address belongs to a customer in our system, would you like to login?</p>
      </div>
    </Modal>
  )
}

LoginPopup.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  handleShowLoginForm: PropTypes.func,
  closable: PropTypes.bool,
  maskClosable: PropTypes.bool
}

export default LoginPopup
