import { notification } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import { STORAGE_KEYS } from 'src/constants/constants'
import { save } from 'src/lib/storage'
import * as CustomerServices from 'src/services/customer.service'
import { getSingleVehicleUrl } from 'src/utils'
import carImage from '../../assets/placeholder_car.jpg'
import StockComponent from '../StockComponent'
import './style.scss'

function VehicleCard({ car, favouriteCars, setFavouriteCars, ...rest }) {
  const { frontend_url: vehicleURL } = car
  const { customerAccount, favouriteVehicles, setFavouriteVehicles, accountSettings } = useAuthorizationContext()
  const notAvailable = 'Not available'

  const isFavouriteCar =
    favouriteCars?.filter((el) => el)?.find((item) => item._id === car?._id) ||
    favouriteVehicles?.find((item) => item?._id === car?._id)
  const [image, setImage] = useState(carImage)

  useEffect(() => {
    if (car?.photo_url) {
      setImage(car?.photo_url)
    } else {
      setImage(car?.photos[0] || carImage)
    }
  }, [car])

  const handleSetFavoriteVehicles = (vehicleId, vehicles) => {
    CustomerServices.setFavouriteVehicles(vehicleId)
      .then(() => {
        setFavouriteVehicles(vehicles)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleFavouriteCar = () => {
    if (customerAccount?.id) {
      if (isFavouriteCar) {
        const newFavouriteVehicles = favouriteVehicles.filter((item) => item._id !== car._id)
        handleSetFavoriteVehicles(car._id, newFavouriteVehicles)
      } else {
        handleSetFavoriteVehicles(car._id, [...favouriteVehicles, { _id: car._id, vehicle: car }])
      }
    } else {
      let newFavouriteCars = JSON.parse(JSON.stringify(favouriteCars))
      if (isFavouriteCar) {
        newFavouriteCars = newFavouriteCars.filter((item) => item._id !== car._id)
      } else if (newFavouriteCars?.length < 10) {
        newFavouriteCars.push(car)
      } else {
        notification.warning({
          message: 'Favorite limit',
          description: "You can't add more than 10 favorite cars."
        })
      }
      setFavouriteCars(newFavouriteCars)
      save(STORAGE_KEYS.FAVOURITE_CARS, JSON.stringify(newFavouriteCars))
    }
  }

  return (
    <div className="border border-gray-200 rounded relative flex flex-col justify-between h-full" {...rest}>
      <div className="p-3 vehicle-card-entry">
        <StockComponent className="stock-image absolute stock z-10" vehicle={car} />
        <div className="vehicle-card-thumbnail">
          <Link to={getSingleVehicleUrl(vehicleURL)}>
            <img
              src={image}
              onError={(e) => {
                e.target.onError = null
                e.target.src = carImage
              }}
              alt={car?.make}
              className="w-full"
            />
          </Link>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="cursor-pointer mb-6"
          onClick={handleFavouriteCar}
        >
          {isFavouriteCar ? (
            <path
              className="opacity-100"
              fill="#2676d9"
              d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
            />
          ) : (
            <>
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                className="hover:hidden"
                fill="#757575"
                d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z"
              />
              <path
                className="opacity-0 hover:opacity-100"
                fill="#2676d9"
                d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
              />
            </>
          )}
        </svg>
        <div className="vehicle-card-body">
          <Link
            to={getSingleVehicleUrl(vehicleURL)}
            className="text-gray-800 text-xl font-semibold mb-2 hover:underline cursor-pointer hover:text-primary"
          >
            {`${car.year || ''} ${car.make || ''} ${car.model || ''}`}
          </Link>
          <div className="flex">
            <p className="text-gray-600 w-2/5 mr-5">Body</p>
            <p className="text-gray-800 w-3/5">{car.body || notAvailable}</p>
          </div>
          <div className="flex">
            <p className="text-gray-600 w-2/5 mr-5">Style</p>
            <p className="text-gray-800 w-3/5">{car.style || notAvailable}</p>
          </div>
          <div className="flex">
            <p className="text-gray-600 w-2/5 mr-5">Mileage</p>
            <p className="text-gray-800 w-3/5">{car.miles || notAvailable}</p>
          </div>
          <div className="flex">
            <p className="text-gray-600 w-2/5 mr-5">Engine</p>
            <p className="text-gray-800 w-3/5">{car.engine_name || notAvailable}</p>
          </div>
          <div className={`flex ${!car.dealer_id && 'mb-4'}`}>
            <p className="text-gray-600 w-2/5 mr-5">Transmission</p>
            <p className="text-gray-800 w-3/5">{car.transmission_name || notAvailable}</p>
          </div>

          {Object.keys(accountSettings).length > 0 && accountSettings.role !== 'customer' && (
            <div className={`flex ${!car.dealer_id && 'hidden'}`}>
              <p className="text-gray-600 w-2/5 mr-5">Dealer ID</p>
              <p className="text-gray-800 w-3/5">{car?.dealer_id || notAvailable}</p>
            </div>
          )}

          <div className={`flex mb-4 ${!car.dealer_name && 'hidden'}`}>
            <p className="text-gray-600 w-2/5 mr-5">Dealership</p>
            <p className="text-gray-800 w-3/5">{car?.dealer_name || notAvailable}</p>
          </div>
        </div>
      </div>
      <p className="text-black text-2xl font-bold text-center pb-2">${car.priceMask}</p>
    </div>
  )
}

VehicleCard.propTypes = {
  favouriteCars: PropTypes.array,
  setFavouriteCars: PropTypes.func.isRequired,
  car: PropTypes.shape({
    _id: PropTypes.string,
    dealer_id: PropTypes.number,
    dealer_name: PropTypes.string,
    dealer_city: PropTypes.string,
    dealer_state: PropTypes.string,
    frontend_url: PropTypes.string,
    new_used_cert_flag: PropTypes.string,
    vin: PropTypes.string,
    stock_number: PropTypes.string,
    year: PropTypes.number,
    make: PropTypes.string,
    model: PropTypes.string,
    trim_description: PropTypes.string,
    body: PropTypes.string,
    exterior_color: PropTypes.string,
    interior_color: PropTypes.string,
    engine_name: PropTypes.string,
    transmission_name: PropTypes.string,
    transmission_type: PropTypes.string,
    miles: PropTypes.number,
    doors: PropTypes.number,
    style: PropTypes.string,
    photos: PropTypes.arrayOf(PropTypes.string),
    price: PropTypes.number,
    priceMask: PropTypes.string,
    photo_url: PropTypes.string
  }).isRequired
}

export default VehicleCard
