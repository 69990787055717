import { Button, Form, Modal, Radio, Select, notification } from 'antd'
import React, { useState } from 'react'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import PhoneNumberInput from 'src/components/InputComponent/PhoneNumberInput'
import UploadingDealershipLogoComponent from 'src/components/UploadingDealershipLogoComponent'
import { STATES, USER_ROLES, VALID_EMAIL } from 'src/constants/constants'
import { VEHICLES_STOCK } from 'src/pages/ClaimYourBusinessV3/constants'
import * as DealerService from 'src/services/dealer.service'

const { Option } = Select

const useCrmOptions = [
  'No',
  'PSXDigital',
  'CRM Suite',
  'Hubspot',
  'Trailer Central',
  'Dealer Stack',
  'DXI',
  'Hyper Drive Pro',
  'e-lead',
  'ARI',
  'Microsoft Dynamics',
  'Powersports Tech',
  'Lightspeed EVO',
  'DP 360',
  'Response',
  'Nizex',
  'Higher Gear',
  'TLP - Traffic Log Pro',
  'Auto Loop',
  'VIN Solutions',
  'DealerSocket',
  'IDS',
  'Victory',
  'Vcept',
  'Other'
]

const inventoryRadio = [
  {
    label: 'Inventory feed from DMS',
    value: 'dms'
  },
  {
    label: 'Inventory management system',
    value: 'management'
  },
  {
    label: 'FTP drop',
    value: 'ftp'
  },
  {
    label: 'Manual Entry',
    value: 'manual'
  }
]

function DealershipInformation() {
  const [form] = Form.useForm()
  const { dealershipAccount } = useAuthorizationContext()
  const [dealershipData, setDealershipData] = useState(dealershipAccount)
  const [showModal, setShowModal] = useState(false)
  const [errorMsg] = useState('')
  const [triggerUploadDealershipLogo, setTriggerUploadDealershipLogo] = useState()

  const customerForm = [
    {
      label: 'Dealership name',
      name: 'dealership_name',
      required: true,
      placeholder: 'Dealership name',
      type: 'text',
      defaultValue: dealershipData?.dealer_name
    },
    {
      label: 'Dealership phone',
      name: 'dealership_phone',
      required: true,
      placeholder: 'Dealership phone',
      type: 'phone',
      defaultValue: dealershipData?.contacts?.phone
    },
    {
      label: 'Website',
      name: 'dealership_website',
      required: true,
      placeholder: 'Website',
      type: 'text',
      defaultValue: dealershipData?.contacts?.urls?.powersports_dealer
    },
    {
      label: 'Dealership email',
      name: 'dealership_email',
      required: true,
      placeholder: 'Dealership email',
      type: 'email',
      defaultValue: dealershipData?.contacts?.email
    },
    {
      label: 'Dealership Address',
      name: 'dealership_address',
      required: true,
      placeholder: 'Dealership address',
      type: 'text',
      defaultValue: dealershipData?.contacts?.address
    },
    {
      label: 'City',
      name: 'dealership_city',
      required: true,
      placeholder: 'City',
      type: 'text',
      defaultValue: dealershipData?.contacts?.city
    },
    {
      label: 'State',
      name: 'dealership_state',
      required: true,
      placeholder: 'State',
      type: 'select',
      options: STATES.map((e) => ({ value: e.abbreviation, label: e.name })),
      defaultValue: dealershipData?.contacts?.state
    },
    {
      label: 'Zip',
      name: 'dealership_zip',
      required: true,
      placeholder: 'Zip',
      type: 'number',
      defaultValue: dealershipData?.contacts?.zip
    },
    {
      label: 'First Name',
      name: 'first_name',
      required: true,
      placeholder: 'Add first name',
      type: 'text',
      defaultValue: dealershipData?.first_name
    },
    {
      label: 'Last Name',
      name: 'last_name',
      required: true,
      placeholder: 'Add last name',
      type: 'text',
      defaultValue: dealershipData?.last_name
    },
    {
      label: 'Email address',
      name: 'email',
      required: true,
      placeholder: 'Email address',
      type: 'email',
      defaultValue: dealershipData?.email
    },
    {
      label: 'Mobile phone',
      name: 'mobile_phone',
      required: true,
      placeholder: 'Mobile phone',
      type: 'phone',
      defaultValue: dealershipData?.contacts?.mobile ? dealershipData?.contacts?.mobile : dealershipData?.phone
    },
    {
      label: 'NIADA member',
      name: 'niada_member',
      required: true,
      placeholder: 'NIADA member',
      type: 'radio',
      options: [
        { value: 'no', label: 'No' },
        { value: 'yes', label: 'Yes' }
      ],
      defaultValue: dealershipData?.is_niada_member ? 'yes' : 'no'
    },
    {
      label: 'Average in stock',
      name: 'average_vehicles_stock',
      required: true,
      placeholder: 'Average in stock',
      type: 'select',
      options: VEHICLES_STOCK.map((e) => ({ value: e.id, label: e.name })),
      defaultValue: dealershipData?.average_vehicles_stock
    },
    {
      label: 'CRM',
      name: 'crm',
      placeholder: 'CRM',
      type: 'select',
      options: useCrmOptions.map((e) => ({ label: e, value: e === 'No' ? null : e })),
      defaultValue: dealershipData?.crm
    },
    {
      label: 'Sync inventory',
      name: 'sync_inventory',
      placeholder: 'Sync inventory',
      type: 'radio',
      options: inventoryRadio,
      defaultValue: dealershipData?.sync_inventory
    },
    {
      label: 'How dealership gets leads today?',
      name: 'get_leads_email',
      placeholder: 'How dealership gets leads today?',
      type: 'email',
      defaultValue: dealershipData?.get_leads_email
    }
  ]

  const onFormChange = (values) => {
    form.setFieldsValue({ ...form.getFieldValue(), [values.target.name]: values.target.value })
  }

  const handleFormSubmission = async () => {
    const values = form.getFieldsValue()

    setTriggerUploadDealershipLogo(true)

    await DealerService.updateDealershipInformation(values)
      .then((result) => {
        setDealershipData(result.data)
        notification.success({
          message: 'Success',
          description: 'Dealership updated'
        })
      })
      .finally(() => setShowModal(false))
  }

  const renderForm = (element, index) => {
    form.setFieldsValue({ ...form.getFieldValue(), [element.name]: element.defaultValue })

    switch (element.type) {
      case 'select':
        return (
          <Select
            placeholder={element.placeholder || `Select ${element.name}`}
            size="large"
            name={element.name}
            mode={element.mode}
            onChange={(value) => {
              form.setFieldsValue({ ...form.getFieldValue(), [element.name]: value })
            }}
            defaultValue={element.defaultValue}
          >
            {element.options.map((option) => (
              <Option value={option.value} key={option.value} className="p-2">
                {option.label}
              </Option>
            ))}
          </Select>
        )
      case 'radio':
        return (
          <Radio.Group name={element.name} size="large" value={element.defaultValue}>
            {element.options.map((option) => (
              <div className="flex justify-between mb-1.5" key={option.value}>
                <Radio value={option.value} className="flex items-center flex-1">
                  {option.label}
                </Radio>
              </div>
            ))}
          </Radio.Group>
        )
      case 'phone':
        return (
          <PhoneNumberInput
            placeholder={element.label}
            name={element.name}
            mask="(999) 999-9999"
            className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
            defaultValue={element.defaultValue}
          />
        )
      default:
        return (
          <input
            placeholder={element.label}
            name={element.name}
            autoFocus={index === 0}
            className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
            type={element.type}
            defaultValue={element.defaultValue}
          />
        )
    }
  }

  const validation = (form) => {
    const patternMessage = {}
    switch (form.name) {
      case 'email':
        patternMessage.pattern = VALID_EMAIL
        patternMessage.message = 'Invalid email format'
        return patternMessage
      case 'phone':
        patternMessage.pattern =
          /(^[0-9]{3}-[0-9]{3}-[0-9]{4}$)|(^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$)|(^\([_]{3}\)\s[_]{3}-[_]{4}$)/
        patternMessage.message = 'Invalid phone number format'
        return patternMessage
      default:
        return patternMessage
    }
  }

  const getAverageVehiclesStockLabel = (dealershipData) => {
    let label = dealershipData?.average_vehicles_stock
    if (label === '<30') {
      label = '30 or less'
    }
    return label
  }

  const getCrmLabel = (dealershipData) => {
    let label = dealershipData?.crm
    if (label === '') {
      label = 'No'
    }
    return label
  }

  return (
    <div className="rounded-2xl border-2 p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
      <div className="flex md:col-span-2 lg:col-span-3 xl:col-span-4">
        {dealershipAccount?.role === USER_ROLES.DEALERSHIP_ADMIN ? (
          <Button
            type="primary"
            onClick={() => setShowModal(true)}
            htmlType="button"
            className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
          >
            Edit Information
          </Button>
        ) : null}
      </div>
      <div className="flex gap-2">
        <p className="font-bold">Dealership:</p>
        <p>{dealershipData?.dealer_name}</p>
      </div>
      <div className="flex gap-2">
        <p className="font-bold">Dealership phone:</p>
        <p>{dealershipData?.contacts?.phone}</p>
      </div>
      <div className="flex gap-2">
        <p className="font-bold">Website:</p>
        <p>{dealershipData?.contacts?.urls?.powersports_dealer}</p>
      </div>
      <div className="flex gap-2">
        <p className="font-bold">Dealership email:</p>
        <p>{dealershipData?.contacts?.email}</p>
      </div>
      <div className="flex gap-2">
        <p className="font-bold">Dealership address:</p>
        <p>{dealershipData?.contacts?.address}</p>
      </div>
      <div className="flex gap-2">
        <p className="font-bold">City:</p>
        <p>{dealershipData?.contacts?.city}</p>
      </div>
      <div className="flex gap-2">
        <p className="font-bold">State:</p>
        <p>{dealershipData?.contacts?.state}</p>
      </div>
      <div className="flex gap-2">
        <p className="font-bold">Zip:</p>
        <p>{dealershipData?.contacts?.zip}</p>
      </div>
      <div className="flex gap-2">
        <p className="font-bold">First name:</p>
        <p>{dealershipData?.first_name}</p>
      </div>
      <div className="flex gap-2">
        <p className="font-bold">Last name:</p>
        <p>{dealershipData?.last_name}</p>
      </div>
      <div className="flex gap-2">
        <p className="font-bold">Email address:</p>
        <p>{dealershipData?.email}</p>
      </div>
      <div className="flex gap-2">
        <p className="font-bold">Company email address:</p>
        <p>{dealershipData?.company_email}</p>
      </div>
      <div className="flex gap-2">
        <p className="font-bold">Mobile phone:</p>
        <p>{dealershipData?.contacts?.mobile ? dealershipData?.contacts?.mobile : dealershipData?.phone}</p>
      </div>
      <div className="flex gap-2">
        <p className="font-bold">Your role:</p>
        <p>{dealershipData?.roleLabel}</p>
      </div>
      <div className="flex gap-2">
        <p className="font-bold">NIADA member:</p>
        <p>{dealershipData?.is_niada_member ? 'Yes' : 'No'}</p>
      </div>
      <div className="flex gap-2">
        <p className="font-bold">Average in stock:</p>
        <p>{getAverageVehiclesStockLabel(dealershipData)}</p>
      </div>
      <div className="flex gap-2">
        <p className="font-bold">CRM:</p>
        <p>{getCrmLabel(dealershipData)}</p>
      </div>
      <div className="flex gap-2">
        <p className="font-bold">Total Monthly Billing:</p>
        <p>${dealershipData?.price_amount}</p>
      </div>
      <div className="flex gap-2">
        <p className="font-bold">Term:</p>
        <p>{dealershipData?.term}</p>
      </div>
      <div className="flex gap-2">
        <p className="font-bold">Sync inventory:</p>
        <p>{dealershipData?.sync_inventory}</p>
      </div>
      <div className="flex gap-2">
        <p className="font-bold">How dealership gets leads today?:</p>
        <p>{dealershipData?.get_leads_email}</p>
      </div>

      <Modal
        visible={showModal}
        style={{ top: 20 }}
        bodyStyle={{ paddingLeft: 50 }}
        onCancel={() => setShowModal(false)}
        className="w-full"
        title="Edit Dealership Information"
        footer={false}
      >
        <Form form={form} layout="vertical" name="vehicle-ref-create" autoComplete="off" onChange={onFormChange}>
          {customerForm.map((form, index) => {
            return (
              <div key={index}>
                {form.name !== 'averageInterest' && <p className="text-base font-semibold">{form.label}</p>}
                <Form.Item
                  name={form.name}
                  hasFeedback
                  dependencies={form?.dependencies}
                  rules={
                    form.rules || [
                      {
                        required: form.required,
                        message: `${form.label} is required`
                      },
                      validation(form)
                    ]
                  }
                  className="custom-antd-label flex"
                >
                  {renderForm(form, index)}
                </Form.Item>
              </div>
            )
          })}
          <div key="dealership-logo-upload">
            <p className="text-base font-semibold">Logo</p>
            <UploadingDealershipLogoComponent
              setTriggerUploadDealershipLogo={setTriggerUploadDealershipLogo}
              triggerUploadDealershipLogo={triggerUploadDealershipLogo}
              initialImageUrl={dealershipData?.dealer_logo}
              dealerId={dealershipData?.dealer_id}
            />
            <p className="w-1/2 italic text-xs">
              You can upload any standard image file <b>(JPG, PNG) for up to 2MB file size</b>.
            </p>
            <p className="w-1/2 italic text-xs">
              For optimal display of the logo, we recommend uploading a <b>square image</b>.
            </p>
          </div>

          <p className="text-red-500">{errorMsg}</p>

          <div className="mt-8">
            <Button type="primary" onClick={handleFormSubmission} htmlType="submit" className="h-12 rounded-xl">
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default DealershipInformation
