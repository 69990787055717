import { Button, Form, Input, notification, Select, Space, Tooltip } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { AiOutlineArrowRight, AiOutlineLoading } from 'react-icons/ai'
import { BsCheckLg } from 'react-icons/bs'
import { FiSearch } from 'react-icons/fi'
import { GoCheck } from 'react-icons/go'
import { IoIosArrowUp } from 'react-icons/io'
import { useWindowSize } from 'react-use'
import { STATES } from 'src/constants/constants'
import { LOOKUP_UNCLAIMED_DEALERSHIP } from 'src/constants/lookupContants'
import LookupArrows from '../../../assets/lookup-arrows.png'
import * as DealershipServices from '../../../services/dealer.service'

const { Option } = Select

function StepTitle({ step, description }) {
  return (
    <div className="flex flex-row gap-2 items-center">
      <div className="bg-gray-300 p-2 text-xl text-white font-bold step-icon text-center flex items-center justify-center">
        {step}
      </div>
      <div className="ml-3 text-2xl font-bold">{description}</div>
    </div>
  )
}

StepTitle.propTypes = {
  step: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired
}

function FindYourDealership({
  nextStep,
  selectedDealership,
  setSelectedDealership,
  setIsManualInputDealership,
  setDealershipDetail
}) {
  const [form] = Form.useForm()

  const [showResult, setShowResult] = useState(false)
  const [loading, setLoading] = useState(false)
  const [searchedBusiness, setBusiness] = useState([])
  const { width: screenWidth } = useWindowSize()
  const isMobile = screenWidth <= 1024
  const [lookupCounter, setLookupCounter] = useState('000000'.split(''))
  const [defaultState, setDefaultState] = useState('TX')
  const [lookupState, setLookupState] = useState('Texas')

  const openNotification = ({ message, description, type = 'success' }) => {
    const action = type === 'success' ? notification.success : notification.error
    action({
      message,
      description
    })
  }

  const redirectToDealershipPage = (dealershipId) => {
    const {
      location: { origin }
    } = window
    window.open(`${origin}/dealership/${dealershipId}`)
  }

  const handleSearchBusiness = () => {
    const { representativeName } = form.getFieldsValue()
    if (representativeName) {
      setLoading(true)
      DealershipServices.searchDealershipMemberNumberExactMatch(representativeName)
        .then((response) => {
          const { data } = response
          if (data?.length > 0) {
            const dealershipArr = response.data.slice(0, 50).map((item) => {
              const {
                _id: dealerId,
                name,
                phone,
                address,
                website,
                city,
                email,
                state,
                zip,
                firstName,
                lastName,
                mobile,
                memberNumber,
                lat,
                lng,
                is_claimed: isClaimed
              } = item
              return {
                dealerId,
                dealerName: name,
                address,
                phone,
                powersportsDealerUrl: website,
                city,
                email,
                state,
                zip,
                firstName,
                lastName,
                mobile,
                memberNumber,
                lat,
                lng,
                isClaimed
              }
            })
            setBusiness(dealershipArr)
            setShowResult(true)
          } else {
            setBusiness([])
            setSelectedDealership({})
            openNotification({ message: 'No Results Found', type: 'error' })
          }
        })
        .catch(() => {
          setBusiness([])
          setSelectedDealership({})
          openNotification({ message: 'No Results Found', type: 'error' })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const pressEnterKey = (event) => {
    if (event.charCode === 13 && event?.target?.value) {
      handleSearchBusiness()
    }
  }

  const onSelectDealerShip = ({
    dealerId,
    dealerName,
    address,
    phone,
    powersportsDealerUrl,
    city,
    email,
    state,
    zip,
    firstName,
    lastName,
    mobile,
    memberNumber,
    lat,
    lng
  }) => {
    setIsManualInputDealership(false)
    setSelectedDealership({
      dealerId,
      dealerName,
      address,
      phone,
      powersportsDealerUrl,
      city,
      email,
      state,
      zip,
      firstName,
      lastName,
      mobile,
      memberNumber,
      lat,
      lng
    })
    setDealershipDetail({
      dealerId,
      representativeName: dealerName,
      physicalAddress: address.trim(),
      phone,
      website: powersportsDealerUrl,
      city,
      email,
      state,
      zip,
      firstName,
      lastName,
      mobile,
      memberNumber,
      lat,
      lng
    })
    nextStep()
  }

  const getLookupCount = (stateCode) => {
    let stringCounter = '000000'
    const lookUpState = LOOKUP_UNCLAIMED_DEALERSHIP.find((o) => o.stateCode === stateCode)

    if (lookUpState) {
      const countInString = lookUpState.count.toString()
      stringCounter = lookUpState.count.toString()
      if (countInString.length < 6) {
        const lackingDigits = 6 - countInString.length
        for (let i = 1; i <= lackingDigits; i++) {
          stringCounter = `0${stringCounter}`
        }
      }

      setLookupState(lookUpState.state)
    }

    setLookupCounter(stringCounter.toString().split(''))
  }

  const handleStateOption = (value) => {
    setDefaultState('')
    getLookupCount(value)
  }

  useEffect(() => {
    if (defaultState) {
      getLookupCount(defaultState)
    }
  }, [defaultState])

  return (
    <div className="relative" style={{ overflow: 'hidden' }}>
      <div className={`max-w-xl z-10 absolute top-search-box ${isMobile ? 'hidden' : 'block'}`}>
        <div>
          <img
            src="https://smart-pixl.com/12553/00007_motormarketusa.com_SMART.GIF"
            alt=""
            style={{ display: 'none !important' }}
            httpEquiv="Content-Style-Type"
            content="image/gif"
          />
          <div className="claim-arrow-bg">
            <p className="flex items-center justify-center pt-6 pl-8 text-black font-bold text-2xl">
              Claim Your Dealership
            </p>
            <p className="pt-5 flex items-center justify-center text-black font-semibold text-lg gap-2">
              <Button type="text" size="medium" className="bg-gray-400 text-white font-semibold" shape="circle">
                1
              </Button>
              <span>Input Your Association Membership ID</span>
            </p>
          </div>
          <div className="w-full" style={{ marginTop: '-16px' }}>
            <Form
              form={form}
              layout="vertical"
              name="vehicle-ref"
              className="items-center justify-center"
              onFinish={() => {}}
              spellCheck={false}
            >
              <div className="relative">
                <Form.Item name="representativeName" className="custom-antd-label">
                  <Input
                    placeholder="Search by Membership ID"
                    className="p-3 mt-4 rounded-xl"
                    type="text"
                    onKeyPress={pressEnterKey}
                  />
                </Form.Item>
                {loading && (
                  <AiOutlineLoading size={15} className="text-blue-500 border-0 absolute right-3 top-8 animate-spin" />
                )}
                {!loading && !showResult && (
                  <FiSearch
                    onClick={() => handleSearchBusiness()}
                    size={15}
                    className="text-blue-500 border-0 absolute right-3 top-8 cursor-pointer"
                  />
                )}
                {!loading && showResult && (
                  <IoIosArrowUp
                    onClick={() => setShowResult(false)}
                    size={15}
                    className="text-blue-500 border-0 absolute right-3 top-8 cursor-pointer"
                  />
                )}
              </div>
              {!loading && showResult && searchedBusiness?.length > 0 && (
                <div className="absolute overflow-y-auto z-10 mx-auto left-0 right-0 bg-white border border-gray-300 rounded-md max-h-80 md:w-3/4 w-11/12">
                  <ul className="">
                    {searchedBusiness?.map(
                      ({
                        dealerId,
                        dealerName,
                        address,
                        phone,
                        powersportsDealerUrl,
                        city,
                        email,
                        state,
                        zip,
                        lat,
                        lng,
                        firstName,
                        lastName,
                        mobile,
                        memberNumber,
                        isClaimed
                      }) => (
                        <li
                          key={dealerId}
                          onClick={() => {
                            if (!isClaimed) {
                              onSelectDealerShip({
                                dealerId,
                                dealerName,
                                address,
                                phone,
                                powersportsDealerUrl,
                                city,
                                email,
                                state,
                                zip,
                                firstName,
                                lastName,
                                mobile,
                                memberNumber,
                                lat,
                                lng
                              })
                            }
                          }}
                          className={`border-t-2 list-none px-4 py-3 cursor-pointer ${
                            selectedDealership.dealerId === dealerId ? 'bg-gray-600 text-white' : 'hover:bg-gray-50'
                          }`}
                          role="presentation"
                        >
                          <div className="flex justify-between gap-3 items-center">
                            <div className="flex gap-4 items-center">
                              <p className="font-bold text-lg">{dealerName}</p>
                              {isClaimed && <BsCheckLg className="text-xl" />}
                            </div>
                            <Tooltip placement="topRight" title="Go to dealership landing page">
                              <AiOutlineArrowRight
                                size={25}
                                className="cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  redirectToDealershipPage(dealerId)
                                }}
                              />
                            </Tooltip>
                          </div>
                          <p>{address}</p>
                          <p
                            className="text-blue-500"
                            onClick={(e) => {
                              e.stopPropagation()
                              e.preventDefault()
                              if (
                                powersportsDealerUrl.startsWith('https://') ||
                                powersportsDealerUrl.startsWith('http://')
                              ) {
                                window.open(powersportsDealerUrl)
                              } else {
                                window.open(`https://${powersportsDealerUrl}`)
                              }
                            }}
                          >
                            {powersportsDealerUrl}
                          </p>
                          <div className="flex justify-between">
                            <p>
                              <span className="font-semibold">Sales: </span>
                              {phone}
                            </p>
                            <p>
                              <span className="ml-1 font-semibold">Service: </span>
                              {phone}
                            </p>
                          </div>
                          {memberNumber.length > 0 && (
                              <div className="flex justify-between">
                                <p>
                                  <span className="font-semibold">Membership #: </span>
                                  {memberNumber}
                                </p>
                              </div>
                          )}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>

      <div className={`banner desktop banner-background1 ${isMobile ? 'hidden' : 'block'}`}>
        <div className="container banner-alignment">
          <p className="text-black font-extrabold text-4xl">With MotorMarketUSA.com You Will Get:</p>
          <div className="flex items-baseline justify-left mt-2 gap-6">
            <div className="">
              <p className="text-xl text-black text-left pb-2">
                <GoCheck className="with-check" size={25} color="orange" />
                <span>Online marketplace with</span>
                <span className="font-bold ml-1">2 million</span>
                <span className="font-bold ml-6" style={{ display: 'block' }}>
                  independent dealership units
                </span>
              </p>

              <p className="text-xl text-black text-left pb-2">
                <GoCheck className="with-check" size={25} color="orange" />
                <span>Generating over</span>
                <span className="font-bold ml-1">7 million views per month</span>
              </p>

              <p className="text-xl text-black text-left pb-2">
                <GoCheck className="with-check" size={25} color="orange" />
                <span>Engaging with over</span>
                <span className="font-bold ml-1">3.8 million KNOWN</span>
                <span className="font-bold ml-6" style={{ display: 'block' }}>
                  visitors per month
                </span>
              </p>
            </div>
            <div className="">
              <p className="text-xl text-black text-left pb-2">
                <GoCheck className="with-check" size={25} color="orange" />
                <span>Built-in</span>
                <span className="font-bold ml-1">lead management</span>
              </p>
              <p className="text-xl text-black text-left pb-2">
                <GoCheck className="with-check" size={25} color="orange" />
                <span>Built-in</span>
                <span className="font-bold ml-1">digital retailing</span>
              </p>
              <p className="text-xl text-black text-left pb-2">
                <GoCheck className="with-check" size={25} color="orange" />
                <span>Built-in</span>
                <span className="font-bold ml-1">e-contracting</span>
                <span className="font-bold ml-6" style={{ display: 'block' }}>
                  and title management
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className={`absolute lookup-table bottom-259 container ${isMobile ? 'hidden' : 'block'}`}>
          <div className="relative w-full flex items-center mx-auto">
            <div className="w-1/2 pb-5 pl-20 ">
              <div className="w-1/2 look-up-left">
                <span className="block text-4xl turn-to-5xl text-center text-white font-bold">See how many</span>
                <span className="block text-4xl turn-to-5xl text-center text-white font-bold">were missed in</span>
                <span className="block text-4xl turn-to-5xl text-center text-white font-bold">YOUR state!</span>
                <div className="text-center pt-2">
                  <Select
                    size="large"
                    placeholder="Choose your state"
                    onChange={handleStateOption}
                    defaultValue={defaultState}
                    allowClear
                    showSearch
                    listHeight={300}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().startsWith(input.toLowerCase())}
                  >
                    {STATES.map((option, index) => (
                      <Option key={`option-${index}`} value={option.abbreviation}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="w-1/2 pr-20 pb-5 look-up-right">
              <div className="float-right">
                <span className="block text-4xl text-center text-white font-bold">
                  During {moment().format('MMMM, YYYY')}
                </span>
                {/* <img
                  className="transition-all delay-150 duration-300 ease-in-out block py-3"
                  src={NumberCounter}
                  alt="Claim Your Dealership Counter"
                  style={{
                    width: '348px',
                    marginBottom: '-12px',
                    marginTop: '-12px',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                  }}
                /> */}
                <div className="text-center lookup-counter">
                  <Space>
                    {lookupCounter.map((char, index) => (
                      <Button
                        className="hover:cursor-not-allowed hover:text-black cursor-not-allowed"
                        size="large"
                        key={`${char}-${index}`}
                      >
                        <span className="text-3xl taxt-black font-bold">{char}</span>
                      </Button>
                    ))}
                  </Space>
                </div>
                <span className="block text-4xl line-height text-center font-bold" style={{ color: '#FFD93D' }}>
                  Auto Opportunities were
                </span>
                <span className="block text-4xl line-height text-center font-bold" style={{ color: '#FFD93D' }}>
                  UNCLAIMED
                </span>
                <span className="block text-4xl line-height text-center font-bold italic text-white">
                  in JUST the state of {lookupState}!
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={`absolute lookup-arrows ${isMobile ? 'hidden' : 'block'}`}>
          <img
            className="transition-all delay-150 duration-300 ease-in-out block py-3"
            src={LookupArrows}
            alt="Claim Your Lookup"
            style={{ height: '280px' }}
          />
        </div>
      </div>

      <div className={`my-5 pb-12 find-dealership-mobile ${isMobile ? 'block' : 'hidden'}`}>
        <div>
          <div className="claim-arrow-bg">
            <p className="flex items-center justify-center pt-6 pl-8 text-black font-bold text-xl">
              Claim Your Dealership
            </p>
            <p className="pt-5 flex items-center justify-center text-black font-semibold text-lg gap-2">
              <Button type="text" size="medium" className="bg-gray-400 text-white font-semibold" shape="circle">
                1
              </Button>
              <span>Input Your Association Membership ID</span>
            </p>
          </div>
          <div className="flex items-center justify-center" style={{ marginTop: '-16px' }}>
            <Form
              form={form}
              layout="vertical"
              name="vehicle-ref"
              className="mx-4 lg:px-8 items-center justify-center"
              onFinish={() => {}}
              spellCheck={false}
            >
              <div className="relative">
                <Form.Item name="representativeName" className="custom-antd-label">
                  <Input
                    placeholder="Search by Membership ID"
                    className="p-3 mt-4 rounded-xl"
                    type="text"
                    onKeyPress={pressEnterKey}
                  />
                </Form.Item>
                {loading && (
                  <AiOutlineLoading size={15} className="text-blue-500 border-0 absolute right-3 top-8 animate-spin" />
                )}
                {!loading && !showResult && (
                  <FiSearch
                    onClick={() => handleSearchBusiness()}
                    size={15}
                    className="text-blue-500 border-0 absolute right-3 top-8 cursor-pointer"
                  />
                )}
                {!loading && showResult && (
                  <IoIosArrowUp
                    onClick={() => setShowResult(false)}
                    size={15}
                    className="text-blue-500 border-0 absolute right-3 top-8 cursor-pointer"
                  />
                )}
              </div>
              {!loading && showResult && searchedBusiness?.length > 0 && (
                <div className="absolute overflow-y-auto z-10 mx-auto left-0 right-0 bg-white border border-gray-300 rounded-md max-h-80 md:w-6/12 w-11/12">
                  <ul className="">
                    {searchedBusiness?.map(
                      ({
                        dealerId,
                        dealerName,
                        address,
                        phone,
                        powersportsDealerUrl,
                        city,
                        email,
                        state,
                        zip,
                        lat,
                        lng,
                        firstName,
                        lastName,
                        mobile,
                        memberNumber,
                        isClaimed
                      }) => (
                        <li
                          key={dealerId}
                          onClick={() => {
                            if (!isClaimed) {
                              onSelectDealerShip({
                                dealerId,
                                dealerName,
                                address,
                                phone,
                                powersportsDealerUrl,
                                city,
                                email,
                                state,
                                zip,
                                firstName,
                                lastName,
                                mobile,
                                memberNumber,
                                lat,
                                lng,
                                isClaimed
                              })
                            }
                          }}
                          className={`border-t-2 list-none px-4 py-3 cursor-pointer ${
                            selectedDealership.dealerId === dealerId ? 'bg-gray-600 text-white' : 'hover:bg-gray-50'
                          }`}
                          role="presentation"
                        >
                          <div className="flex justify-between gap-3 items-center">
                            <div className="flex gap-4 items-center">
                              <p className="font-bold text-lg">{dealerName}</p>
                              {isClaimed && <BsCheckLg className="text-xl" />}
                            </div>
                            <Tooltip placement="topRight" title="Go to dealership landing page">
                              <AiOutlineArrowRight
                                size={25}
                                className="cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  redirectToDealershipPage(dealerId)
                                }}
                              />
                            </Tooltip>
                          </div>
                          <p>{address}</p>
                          <p
                            className="text-blue-500"
                            onClick={(e) => {
                              e.stopPropagation()
                              e.preventDefault()
                              if (
                                powersportsDealerUrl.startsWith('https://') ||
                                powersportsDealerUrl.startsWith('http://')
                              ) {
                                window.open(powersportsDealerUrl)
                              } else {
                                window.open(`https://${powersportsDealerUrl}`)
                              }
                            }}
                          >
                            {powersportsDealerUrl}
                          </p>
                          <div className="flex justify-between">
                            <p>
                              <span className="font-semibold">Sales : </span>
                              {phone}
                            </p>
                            <p>
                              <span className="ml-1 font-semibold">Service : </span>
                              {phone}
                            </p>
                            {memberNumber.length > 0 && (
                                <div className="flex justify-between">
                                  <p>
                                    <span className="font-semibold">Membership #: </span>
                                    {memberNumber}
                                  </p>
                                </div>
                            )}
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>

      <div className={`banner pb-10 mobile ${isMobile ? 'block' : 'hidden'}`}>
        <div className="mx-5">
          <div className="mb-8">
            <p className="text-black font-extrabold text-3xl">With MotorMarketUSA.com You Will Get:</p>
          </div>

          <p className="text-xl text-black text-left pb-2">
            <GoCheck className="with-check" size={25} color="orange" />
            <span className="ml-2">Online marketplace with</span>
            <span className="font-bold ml-1">2 million</span>
            <span className="font-bold ml-1">independent dealership units</span>
          </p>

          <p className="text-xl text-black text-left pb-2">
            <GoCheck className="with-check" size={25} color="orange" />
            <span className="ml-2">Generating over</span>
            <span className="font-bold ml-1">7 million views per month</span>
          </p>

          <p className="text-xl text-black text-left pb-2">
            <GoCheck className="with-check" size={25} color="orange" />
            <span className="ml-2">Engaging with over</span>
            <span className="font-bold ml-1">3.8 million KNOWN</span>
            <span className="font-bold ml-1">visitors per month</span>
          </p>
          <p className="text-xl text-black text-left pb-2">
            <GoCheck className="with-check" size={25} color="orange" />
            <span className="ml-2">Built-in</span>
            <span className="font-bold ml-1">lead management</span>
          </p>
          <p className="text-xl text-black text-left pb-2">
            <GoCheck className="with-check" size={25} color="orange" />
            <span className="ml-2">Built-in</span>
            <span className="font-bold ml-1">digital retailing</span>
          </p>
          <p className="text-xl text-black text-left pb-2">
            <GoCheck className="with-check" size={25} color="orange" />
            <span className="ml-2">Built-in</span>
            <span className="font-bold ml-1">e-contracting</span>
            <span className="font-bold ml-6" style={{ display: 'block' }}>
              and title management
            </span>
          </p>
        </div>
      </div>

      <div className={`my-5 pb-12 mobile look-up ${isMobile ? 'block' : 'hidden'}`}>
        <div className="look-up-field" style={{ lineHeight: '' }}>
          <span className="block text-2xl text-center font-bold">See how many</span>
          <span className="block text-4xl text-center font-bold">were missed in</span>
          <span className="block text-4xl text-center font-bold">YOUR state!</span>
          <div className="text-center pt-2">
            <Select
              size="large"
              placeholder="Choose your state"
              onChange={handleStateOption}
              defaultValue={defaultState}
              allowClear
              showSearch
              listHeight={300}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().startsWith(input.toLowerCase())}
            >
              {STATES.map((option, index) => (
                <Option key={`option-${index}`} value={option.abbreviation}>
                  {option.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>

        <div className="look-up-value pt-8">
          <span className="block text-2xl text-center font-bold">During {moment().format('MMMM, YYYY')}</span>
          {/*           <img
            className="transition-all delay-150 duration-300 ease-in-out block py-3"
            src={NumberCounter}
            alt="Claim Your Dealership Counter"
            style={{
              width: '240px',
              marginBottom: '-12px',
              marginTop: '-10px',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          /> */}
          <div className="text-center lookup-counter">
            <Space>
              {lookupCounter.map((char, index) => (
                <Button
                  className="hover:cursor-not-allowed cursor-not-allowed hover:text-black"
                  size="large"
                  key={`${char}-${index}`}
                >
                  <span className="text-2xl taxt-black font-bold">{char}</span>
                </Button>
              ))}
            </Space>
          </div>
          <span className="block text-2xl text-center font-bold" style={{ color: '#FFD93D' }}>
            Auto Opportunities were
          </span>
          <span className="block text-2xl line-height text-center font-bold" style={{ color: '#FFD93D' }}>
            UNCLAIMED
          </span>
          <span className="block text-2xl line-height text-center font-bold italic">
            in JUST the state of {lookupState}!
          </span>
        </div>

        <div style={{ marginBottom: '-175px' }}>
          <img
            className="transition-all delay-150 duration-300 ease-in-out block py-3"
            src={LookupArrows}
            alt="Claim Your Lookup"
            style={{ height: '200px', marginLeft: 'auto', marginRight: 'auto' }}
          />
        </div>
      </div>
    </div>
  )
}

FindYourDealership.propTypes = {
  nextStep: PropTypes.func,
  selectedDealership: PropTypes.object,
  setSelectedDealership: PropTypes.func,
  setIsManualInputDealership: PropTypes.func,
  setDealershipDetail: PropTypes.func
}

export default FindYourDealership
