import { LeftOutlined } from '@ant-design/icons'
import { Button, Modal, Radio, Space, notification } from 'antd'
import moment from 'moment'
import * as PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AiOutlineLeft } from 'react-icons/ai'
import { BsPrinterFill } from 'react-icons/bs'
import { useReactToPrint } from 'react-to-print'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import SavedOffersTable from 'src/components/SavedOffersTable'
import { KBB_VEHICLE_TYPE, STORAGE_KEYS } from 'src/constants/constants'
import { load, save } from 'src/lib/storage'
import * as CreditApplicationForm from 'src/services/creditApplicationForm.service'
import * as CustomerServices from 'src/services/customer.service'
import bookMarkImage from '../../../assets/finance/bookmark.png'
import dealImage from '../../../assets/finance/deal.png'
import financingImage from '../../../assets/finance/financing.png'
import gearsImage from '../../../assets/finance/gears.png'
import paymentImage from '../../../assets/finance/payment.png'
import scheduleImage from '../../../assets/finance/schedule.png'
import vehicleImage from '../../../assets/finance/vehicle.png'
import kelleyImage from '../../../assets/kbb-logo.png'
import CreditFinance from '../CreditFinance'
import ConfirmCreditApplicationModal from '../CreditFinance/ConfirmCreditApplicationModal'
import CreditApplicationDetail from '../CreditFinance/CreditApplicationDetail'
import FinishDealStep1 from '../FinishDeal/finishDealStep1'
import FinishDealStepFinal from '../FinishDeal/finishDealStepFinal'
import ScheduleDriveStep1 from '../ScheduleDrive/scheduleDriveStep1'
import ScheduleDriveStep2 from '../ScheduleDrive/scheduleDriveStep2'
import ScheduleDriveStepFinal from '../ScheduleDrive/scheduleDriveStepFinal'
import VehicleFinalDealModal from '../VehicleFinalDealModal'
import VehicleFinanceSelectionModal from '../VehicleFinanceSelectionModal'
import VehicleFinanceStep1 from '../VehicleFinanceSteps/step1'
import VehicleFinanceStep2 from '../VehicleFinanceSteps/step2'
import VehicleFinanceStep3 from '../VehicleFinanceSteps/step3'
import VehicleFinanceStep4 from '../VehicleFinanceSteps/step4'
import VehicleFinanceStep5 from '../VehicleFinanceSteps/step5'
import VehicleFinanceStepFinal from '../VehicleFinanceSteps/stepFinal'
import TradeInValue from '../VehicleFinanceSteps/tradeInValue'
import VehicleMoreDetails from '../VehicleMoreDetails'
import VehicleProtectionCard from '../VehicleProtectionCard'
import VehicleTradeInDetailModal from '../VehicleTradeInDetailModal'
import './style.scss'

const services = [
  {
    title: ' Gulf States Platinum Pre-owend Platinum 24Mo 24L $ 100 Deductible',
    monthlyPrice: '$42/mo.',
    singlePrice: '$2,995',
    details: {}
  },
  {
    title: ' Gulf States Pre-owend Platinum 36Mo 36K $100 Deductible',
    monthlyPrice: '$49/mo.',
    singlePrice: '$3,495',
    details: {}
  },
  {
    title: ' Gulf States Platinum Pre-owend Platinum 48Mo 48K $100 Deductible',
    monthlyPrice: '$56/mo.',
    singlePrice: '$3,995',
    details: {}
  },
  {
    title: ' I choose not to protect my vechicle at this time'
  }
]

function VehicleFinanceModal({ info, initStep, setInitStep, calculation }) {
  const { customerAccount, customerLogin } = useAuthorizationContext()
  const [step, setStep] = useState(initStep ?? -1)
  const vehicleType = KBB_VEHICLE_TYPE.AUTOMOTIVE
  const [rowData, setRowData] = useState({})
  const [status, setStatus] = useState('')
  const [callInitialApi, setCallInitialApi] = useState(false)
  const [yearId, setYearId] = useState('')
  const [makeObject, setMakeObject] = useState({
    makeId: '',
    make: ''
  })
  const [modelObject, setModelObject] = useState({
    modelId: '',
    model: ''
  })
  const [trimId, setTrimId] = useState('')
  const [ownership, setOwnership] = useState('')
  const [vin, setVin] = useState('')
  const [mileage, setMiliage] = useState()
  const [zipCode, setZipCode] = useState()
  const [active, setActive] = useState('no')
  const [debit, setDebit] = useState()
  const [selectedCondition, setSelectedCondition] = useState()
  const [engine, setEngine] = useState()
  const [transmission, setTransmission] = useState()
  const [color, setColor] = useState()
  const [userObject, setUserObject] = useState({})
  const [vehicles, setVehicles] = useState({})
  const [tradeVehicleInfo, setTradeVehicleInfo] = useState({ tradeIn: 0 })
  const [time, setTime] = useState('evening')
  const [selectedData, setSelectedData] = useState(moment())
  const [scheduleTestDriveData, setScheduleTestDriveData] = useState({})
  const [selectedOffer, setSelectedOffer] = useState({})
  const [selectedOtherColor, setSelectedOtherColor] = useState(false)
  const [valueYourVehicleStepStatus, setValueYourVehicleStepStatus] = useState('start')
  const { financing_step_status: financingStepStatus } = selectedOffer
  const [listYears, setListYears] = useState([])
  const [makeList, setMakeList] = useState([])
  const [modelList, setModelList] = useState([])
  const [trimList, setTrimList] = useState([])
  const [finalConfiguration, setFinalConfiguration] = useState({})
  const [tradeId, setTradeId] = useState()
  const customerTradeIns = load(STORAGE_KEYS.CUSTOMER_TRADE_INS) || []
  const [tradeInValue, setTradeInValue] = useState('')

  const [showFinanceConfirmModal, setShowFinanceConfirmModal] = useState(false)
  const [creditApps, setCreditApps] = useState([])
  const [initialCreditAppData, setInitialCreditAppData] = useState({})
  const [creditAppDetail, setCreditAppDetail] = useState({})
  const [initialFinanceStep, setInitialFinanceStep] = useState(1)
  const [selectedTrade, setSelectedTrade] = useState({})
  const [showModal, setShowModal] = useState(false)

  const [tradeIns, setTradeIns] = useState([])
  const [showFinanceSelectionModal, setShowFinanceSelectionModal] = useState(false)
  const [showVehicleTradeDetailModal, setVehicleTradeDetailModal] = useState(false)
  const [scheduleTestDriveStepStatus, setScheduleTestDriveStepStatus] = useState('start')

  const [showUseOfExistingModal, setShowUseOfExistingModal] = useState(false)
  const [refreshTradeInStatus, setRefreshTradeInStatus] = useState(false)
  const [financeCalculation, setFinanceCalculation] = useState(calculation)
  const [existingType, setExistingType] = useState('trade-in')

  const [finishYourDealStepStatus, setFinishYourDealStepStatus] = useState('start')
  const [showVehicleFinalDealModal, setVehicleFinalDealModal] = useState(false)

  useEffect(() => {
    return () => setInitStep(-1)
  }, [])

  useEffect(() => {
    if (customerLogin) {
      CustomerServices.getCustomerDeals()
        .then((response) => {
          setCreditApps(response.data.records)
        })
        .catch((error) => {
          console.error(error)
        })

      CustomerServices.getCustomerTrades()
        .then((response) => {
          setTradeIns(response.data)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [customerLogin])

  const getCustomerOfferByVehicle = () => {
    if (info._id) {
      CustomerServices.getCustomerOfferByVehicleId(info._id)
        .then((response) => {
          setSelectedOffer(response.data)
          setFinanceCalculation(response?.data?.calculation ?? {})
          setFinishYourDealStepStatus(response?.data?.finish_your_deal_step_status)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  useEffect(() => {
    if (info._id && step < 0 && (initStep === undefined || initStep !== 15)) {
      CustomerServices.getLeadTestDriveByVehicleId(info._id)
        .then((response) => {
          const { test_time: testTime, test_date: testDate } = response.data
          setTime(testTime)
          setSelectedData(moment(testDate))
          setScheduleTestDriveData(response.data)

          const testDriveStatus = response?.data?.step_wizard
          if (testDriveStatus === 1) {
            setScheduleTestDriveStepStatus('finish_up')
          }

          if (testDriveStatus > 1) {
            setScheduleTestDriveStepStatus('completed')
          }
        })
        .catch((error) => {
          console.error(error)
        })

      getCustomerOfferByVehicle()
    }
  }, [info, step])

  useEffect(() => {
    if (info._id) {
      const params = {
        latestOnly: true,
        customerId: customerAccount?.id ?? ''
      }
      CreditApplicationForm.getCreditApplicationFormByVehicleId(info._id, params)
        .then((response) => {
          setCreditAppDetail(response.data)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [info._id])

  useEffect(() => {
    if (customerLogin && info._id && (step === -1 || (initStep !== undefined && initStep === 15))) {
      CustomerServices.checkExistingTradeIn(info._id)
        .then((response) => {
          setTradeId(response?.data?._id)
          const stepWizard = response?.data?.stepWizard

          if (stepWizard > 0 && stepWizard < 5) {
            setSelectedTrade(response.data)
            setValueYourVehicleStepStatus('finish_up')
          } else {
            setSelectedTrade(response.data)
            setValueYourVehicleStepStatus('completed')
          }
        })
        .catch((error) => {
          console.error(error)
          setValueYourVehicleStepStatus('start')
        })
    }
  }, [customerLogin, info._id, step, refreshTradeInStatus])

  useEffect(() => {
    if (selectedTrade?.vehicle && selectedTrade?.customer) {
      const { vehicle, customer } = selectedTrade
      const {
        color,
        condition,
        makeId,
        make,
        mileage,
        modelId,
        model,
        ownership,
        trimId,
        year,
        zipcode,
        engine,
        transmission,
        vehicleId,
        trim
      } = vehicle
      const { email, firstname, lastname, phone } = customer

      setTradeId(selectedTrade?._id)
      setYearId(year)
      setMakeObject({
        makeId,
        make
      })
      setModelObject({
        modelId,
        model
      })
      setTrimId(trimId)
      setOwnership(ownership)
      setMiliage(mileage)
      setZipCode(zipcode)
      setUserObject({
        email,
        phone,
        firstName: firstname,
        lastName: lastname
      })
      setColor(color)
      setSelectedCondition(condition)

      setEngine(engine)
      setTransmission(transmission)

      const tradeVehicleInfoObj = {
        year,
        make,
        model,
        yearId,
        makeName: make,
        modelName: model,
        standardEngine: engine,
        standardTransmission: transmission,
        vehicleId,
        trim
      }
      setTradeVehicleInfo(tradeVehicleInfoObj)
      setCallInitialApi(true)
    }
  }, [JSON.stringify(selectedTrade)])

  const nextStep = () => {
    setStep(step + 1)
  }

  const backStep = () => {
    setStep(step - 1)
  }

  const startStep = () => {
    setStatus('started')
    window.setTimeout(() => {
      nextStep()
    }, 3200)

    window.setTimeout(() => {
      setStatus('finished')
    }, 4000)
  }

  const resetStep = () => {
    setStatus('back')
    window.setTimeout(() => {
      setStep(-1)
    }, 2000)

    window.setTimeout(() => {
      setStatus('')
    }, 4000)
  }

  useEffect(() => {
    if (initStep === undefined || initStep !== 15) {
      setStep(-1)
      setStatus('')
    }
  }, [])

  const handleProtection = (type, nextStep) => {
    const types = ['valueYourVehicle', 'schedule', 'deal', 'financing']
    if (types.includes(type)) {
      setStatus('started')
      window.setTimeout(() => {
        setStep(nextStep)
      }, 3200)

      window.setTimeout(() => {
        setStatus('finished')
      }, 4000)
    }
  }

  const nextScheduleFormStep = () => {
    setStep(11)
  }

  const nextScheduleFinal = () => {
    setStep(12)
  }

  const nextFinishDealFormStep = () => {
    setStep(16)
  }

  const startFinishYourDeal = () => {
    setStep(15)
  }

  const startAutomotiveKBB = () => {
    setStep(0)
  }

  const handleSubmitTrade = (usrObj) => {
    if (vehicles && vehicles[0]) {
      const { trimName } = vehicles[0]

      let estimatedvalue = tradeVehicleInfo.tradeIn || 0
      if (usrObj !== undefined) {
        if (estimatedvalue < 1 && usrObj.tradeIn !== undefined && usrObj.tradeIn > 0) {
          estimatedvalue = usrObj.tradeIn
        }
      }

      const tradeFirstName = customerAccount?.first_name
      const tradeLastName = customerAccount?.last_name
      const tradeEmail = customerAccount?.email
      const tradePhone = customerAccount?.phone
      const vehicleEngine = engine ?? vehicles[0]?.standardEngine
      const vehicleTransmission = transmission ?? vehicles[0]?.standardTransmission

      CustomerServices.handleSaveTrade({
        vin,
        make: makeObject.make,
        makeId: makeObject.makeId,
        model: modelObject.model,
        modelId: modelObject.modelId,
        year: yearId,
        trim: trimName,
        trimId,
        ownership,
        mileage,
        zipcode: zipCode,
        owePrice: undefined,
        condition: selectedCondition,
        engine: vehicleEngine,
        transmission: vehicleTransmission,
        color,
        firstName: usrObj?.firstName || userObject?.firstName || tradeFirstName,
        lastName: usrObj?.lastName || userObject?.lastName || tradeLastName,
        email: usrObj?.email || userObject?.email || tradeEmail,
        phone: usrObj?.phone || userObject?.phone || tradePhone,
        comment: usrObj?.comments || userObject?.comments,
        estimatedvalue,
        notLoggedIn: !customerAccount.id,
        vehicleId: tradeVehicleInfo?.vehicleId,
        stepWizard: step,
        tradeToVehicleId: info?._id
      }).then((response) => {
        setStep(step + 1)
        setTradeId(response.data._id)
        setSelectedTrade(response.data)
        if (!customerAccount._id) {
          const tradeInsInSession = customerTradeIns
          tradeInsInSession.push(response.data._id)
          save(STORAGE_KEYS.CUSTOMER_TRADE_INS, JSON.stringify(tradeInsInSession))
        }
      })
    } else {
      setStep(step + 1)
    }
  }

  const handleUpdateTrade = (usrObj) => {
    if (((vehicles && vehicles[0]) || tradeVehicleInfo) && tradeId) {
      const trimName = vehicles[0] !== undefined ? vehicles[0].trimName : tradeVehicleInfo?.trim

      let estimatedvalue = tradeVehicleInfo.tradeIn || 0
      if (usrObj !== undefined) {
        if (estimatedvalue < 1 && usrObj.tradeIn !== undefined && usrObj.tradeIn > 0) {
          estimatedvalue = usrObj.tradeIn
        }
      }

      const tradeFirstName = selectedTrade?.customer?.firstname
      const tradeLastName = selectedTrade?.customer?.lastname
      const tradeEmail = selectedTrade?.customer?.email
      const tradePhone = selectedTrade?.customer?.phone
      const tradeComments = selectedTrade?.comment
      const vehicleEngine = engine ?? vehicles[0]?.standardEngine
      const vehicleTransmission = transmission ?? vehicles[0]?.standardTransmission

      const params = {
        vin,
        make: makeObject.make,
        makeId: makeObject.makeId,
        model: modelObject.model,
        modelId: modelObject.modelId,
        year: yearId,
        trim: trimName,
        trimId,
        ownership,
        mileage,
        zipcode: zipCode,
        owePrice: undefined,
        condition: selectedCondition,
        engine: vehicleEngine,
        transmission: vehicleTransmission,
        color,
        firstName: usrObj?.firstName || userObject?.firstName || tradeFirstName,
        lastName: usrObj?.lastName || userObject?.lastName || tradeLastName,
        email: usrObj?.email || userObject?.email || tradeEmail,
        phone: usrObj?.phone || userObject?.phone || tradePhone,
        comment: usrObj?.comments || userObject?.comments || tradeComments,
        estimatedvalue,
        vehicleId: tradeVehicleInfo?.vehicleId,
        stepWizard: step,
        tradeToVehicleId: info?._id
      }

      CustomerServices.updateTrade(tradeId, params).then((response) => {
        setStep(step + 1)
        setSelectedTrade(response.data)
      })
    }
  }

  const handleTradeInSteps = (usrObj) => {
    if (tradeId) {
      handleUpdateTrade(usrObj)
    } else {
      handleSubmitTrade(usrObj)
    }
  }

  const startCards = useMemo(() => {
    return [
      {
        title: 'Payment Terms',
        image: paymentImage,
        type: 'paymentTerms',
        status: 'completed',
        onStepClick: () => {}
      },
      {
        title: 'Value Your Vehicle',
        image: vehicleImage,
        type: 'valueYourVehicle',
        status: valueYourVehicleStepStatus,
        isRecommended: valueYourVehicleStepStatus === 'start',
        step: 0,
        confirmFunc: () => handleProtection('valueYourVehicle', 1),
        cancelFunc: () => {
          setVehicleTradeDetailModal(true)
        },
        onStepClick: () => {
          if (customerLogin && tradeIns.length > 0 && valueYourVehicleStepStatus === 'start') {
            setShowFinanceSelectionModal(true)
          } else {
            const handleProtectionStep = selectedTrade?.stepWizard ? selectedTrade?.stepWizard : 0
            handleProtection('valueYourVehicle', handleProtectionStep)
          }
        }
      },
      {
        title: 'Accessories',
        image: gearsImage,
        type: 'accessories',
        status: 'start'
      },
      {
        title: 'Apply for Financing',
        image: financingImage,
        type: 'financing',
        status: financingStepStatus,
        step: 8,
        onStepClick: () => {
          if (customerLogin && creditAppDetail?._id) {
            if (creditAppDetail?.step_wizard === 6) {
              setShowModal(true)
            } else {
              setInitialFinanceStep(creditAppDetail?.step_wizard)
              handleProtection('financing', 8)
            }
          } else if (customerLogin && creditApps.length > 0) {
            setShowFinanceConfirmModal(true)
          } else {
            handleProtection('financing', 8)
          }
        }
      },
      {
        title: 'Schedule Test Drive',
        image: scheduleImage,
        type: 'schedule',
        status: scheduleTestDriveStepStatus,
        onStepClick: () => {
          if (scheduleTestDriveStepStatus === 'finish_up') {
            handleProtection('schedule', 11)
          } else if (scheduleTestDriveStepStatus === 'completed') {
            handleProtection('schedule', 12)
          } else {
            handleProtection('schedule', 10)
          }
        },
        step: 10
      },
      {
        title: 'Finish  Your Deal',
        image: dealImage,
        type: 'deal',
        status: finishYourDealStepStatus,
        step: 15,
        onStepClick: () => {
          if (finishYourDealStepStatus === 'completed') {
            setVehicleFinalDealModal(true)
          } else {
            handleProtection('deal', 15)
          }
        }
      }
    ]
  }, [
    valueYourVehicleStepStatus,
    financingStepStatus,
    scheduleTestDriveStepStatus,
    finishYourDealStepStatus,
    customerLogin,
    setShowFinanceConfirmModal,
    creditApps,
    tradeIns
  ])

  const changeShowConfirmFinanceModal = () => {
    setShowFinanceConfirmModal(!showFinanceConfirmModal)
  }

  const creditApplicationRef = useRef()
  const handlePrintCrediApplication = useReactToPrint({
    content: () => creditApplicationRef.current
  })

  const handleChangeShowModal = () => {
    setShowModal(!showModal)
  }

  const changeShowFinanceSelectionModal = () => {
    setShowFinanceSelectionModal(!showFinanceSelectionModal)
  }

  const handleVehicleTradeDetailModal = () => {
    setVehicleTradeDetailModal(!showVehicleTradeDetailModal)
  }

  const handleUseOfExistingModal = () => {
    setShowUseOfExistingModal(!showUseOfExistingModal)
  }

  const handleUseOfExistingCancel = () => {
    setShowUseOfExistingModal(false)
  }

  const openNotification = ({ message, description, type = 'success' }) => {
    const action = type === 'success' ? notification.success : notification.error
    action({
      message,
      description
    })
  }

  const handleUseOfExistingSubmit = () => {
    if (existingType === 'trade-in') {
      CustomerServices.saveOffer({ vehicleId: info._id, tradeInId: selectedTrade?._id })
        .then((response) => {
          const { isSuccessful } = response
          if (isSuccessful === 'Yes') {
            setShowUseOfExistingModal(false)
            setRefreshTradeInStatus(true)
            openNotification({
              message: 'The trade offer was sent successfully.'
            })
          }
        })
        .catch((err) =>
          openNotification({
            message: "The trade offer can't be sent.",
            description: err.message,
            type: 'error'
          })
        )
    }

    if (existingType === 'credit-app') {
      const params = {
        vehicle_id: info?._id,
        applicant_section: initialCreditAppData?.applicant_section,
        address_section: initialCreditAppData?.address_section,
        job_section: initialCreditAppData?.job_section,
        license_section: initialCreditAppData?.license_section,
        income_section: initialCreditAppData?.income_section,
        step: 6
      }

      CreditApplicationForm.useExistingCreditAppForVehicleAndCustomer(params)
        .then((response) => {
          const { isSuccessful } = response

          setCreditAppDetail(response.data)
          getCustomerOfferByVehicle()

          if (isSuccessful === 'Yes') {
            setShowUseOfExistingModal(false)
            openNotification({
              message: 'The credit app has been save successfully.'
            })
          }
        })
        .catch((err) =>
          openNotification({
            message: "The credit app can't be saved.",
            description: err.message,
            type: 'error'
          })
        )
    }
  }

  const handleVehicleFinalDealModal = () => {
    setVehicleFinalDealModal(!showVehicleFinalDealModal)
  }

  return (
    <>
      {step === -1 && (
        <div className="py-8 px-12 border-b border-lightGray">
          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12 lg:col-span-9">
              <div className="flex gap-3 lg:justify-between justify-around items-center flex-wrap">
                {startCards.map((card, index) => (
                  <VehicleProtectionCard
                    info={card}
                    handleProtection={handleProtection}
                    key={`protection-card-${index}`}
                    financeCalculation={financeCalculation}
                  />
                ))}
              </div>
            </div>
            <div className="lg:col-span-3 col-span-12 py-3">
              <div className="lg:border-l border-darkGray flex flex-col items-center px-6 py-4 h-full justify-center">
                <img src={bookMarkImage} alt="" className="mb-2" />
                <p className="text-black font-semibold mb-2">Save Your Progress</p>
                <p className="text-gray-600 text-center text-xs mb-3">
                  Use a custom link to return to your deal anytime, anywhere
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`bg-primary flex justify-center absolute left-0 w-full z-20 bottom-0 ${
          status !== '' ? 'vehicle-finance-start-footer' : ''
        } ${status === 'back' ? 'vehicle-finance-back-footer' : ''}`}
        style={{ minHeight: '96px' }}
      >
        {step !== -1 && (
          <button
            onClick={resetStep}
            type="button"
            className="absolute left-0 top-0 bg-darkPrimary flex items-center px-3 py-2 text-white rounded-br-lg border-b border-r border-white"
          >
            <LeftOutlined className="text-2xl" />
            <div className="ml-2">
              <p className="text-xs">Back to</p>
              <p className="text-lg leading-4">My Deal</p>
            </div>
          </button>
        )}
        <div className="w-9/12 relative flex justify-between h-max">
          <VehicleMoreDetails info={{ ...info, ...rowData }} status={status} />
          <div className="flex items-center">
            <div className="w-[1px] h-[50%] bg-white mr-3" />
          </div>
        </div>
      </div>
      {step === -1 && (
        <SavedOffersTable
          selectedTrade={selectedTrade}
          setSelectedTrade={setSelectedTrade}
          vehicleId={info?._id}
          selectedOffer={selectedOffer}
          startStep={startStep}
          setRowData={(data) => setRowData(data)}
          setValueYourVehicleStepStatus={setValueYourVehicleStepStatus}
        />
      )}
      <div className="pt-28 step-wrapper">
        <div className="flex justify-center">
          {step !== -1 && (
            <div className="w-11/12 xl:w-7/12 lg:w-7/12 md:w-9/12 sm:w-10/12">
              {step < 5 && (
                <>
                  <div className="flex mb-3">
                    <div className="flex-1 step-title-wrapper">
                      <p className="text-base text-primary font-bold">Search</p>
                    </div>
                    <div className="flex-1 step-title-wrapper">
                      <p className="text-base text-primary font-bold">Details</p>
                    </div>
                    <div className="flex-1 step-title-wrapper">
                      <p className="text-base text-primary font-bold">Conditions</p>
                    </div>
                    <div className="flex-1 step-title-wrapper">
                      <p className="text-base text-primary font-bold">Vehicle</p>
                    </div>
                    <div className="flex-1 step-title-wrapper">
                      <p className="text-base text-primary font-bold">Equipment</p>
                    </div>
                  </div>
                  <div className="bg-gray-200 h-1 w-full">
                    <div
                      className="h-full bg-info rounded transition-all duration-300"
                      style={{ width: `${((step + 1) * 100) / 5}%` }}
                    />
                  </div>
                </>
              )}

              {step === 0 && (
                <VehicleFinanceStep1
                  nextStep={handleTradeInSteps}
                  setYearId={setYearId}
                  setMakeObject={setMakeObject}
                  setModelObject={setModelObject}
                  setTrimId={setTrimId}
                  setCallInitialApi={setCallInitialApi}
                  vehicleType={vehicleType}
                />
              )}
              {step === 1 && (
                <VehicleFinanceStep2
                  nextStep={handleTradeInSteps}
                  backStep={backStep}
                  vehicleType={vehicleType}
                  yearId={yearId}
                  setYearId={setYearId}
                  setMakeObject={setMakeObject}
                  makeObject={makeObject}
                  setModelObject={setModelObject}
                  modelObject={modelObject}
                  setTrimId={setTrimId}
                  trimId={trimId}
                  ownership={ownership}
                  setOwnership={setOwnership}
                  vin={vin}
                  setVin={setVin}
                  mileage={mileage}
                  setMiliage={setMiliage}
                  zipCode={zipCode}
                  setZipCode={setZipCode}
                  active={active}
                  setActive={setActive}
                  debit={debit}
                  setDebit={setDebit}
                  callInitialApi={callInitialApi}
                  setCallInitialApi={setCallInitialApi}
                  vehicles={vehicles}
                  setVehicles={setVehicles}
                  setTradeVehicleInfo={setTradeVehicleInfo}
                  listYears={listYears}
                  setListYears={setListYears}
                  makeList={makeList}
                  setMakeList={setMakeList}
                  modelList={modelList}
                  setModelList={setModelList}
                  trimList={trimList}
                  setTrimList={setTrimList}
                />
              )}
              {step === 2 && (
                <VehicleFinanceStep3
                  nextStep={handleTradeInSteps}
                  backStep={backStep}
                  selectedCondition={selectedCondition}
                  setSelectedCondition={setSelectedCondition}
                  tradeVehicleInfo={tradeVehicleInfo}
                />
              )}
              {step === 3 && (
                <VehicleFinanceStep4
                  nextStep={handleTradeInSteps}
                  backStep={backStep}
                  engine={engine}
                  setEngine={setEngine}
                  transmission={transmission}
                  setTransmission={setTransmission}
                  color={color}
                  setColor={setColor}
                  tradeVehicleInfo={tradeVehicleInfo}
                  setSelectedOtherColor={setSelectedOtherColor}
                  selectedOtherColor={selectedOtherColor}
                />
              )}
              {step === 4 && (
                <VehicleFinanceStep5
                  nextStep={handleTradeInSteps}
                  backStep={backStep}
                  tradeVehicleInfo={tradeVehicleInfo}
                  vehicleType={vehicleType}
                  setFinalConfiguration={setFinalConfiguration}
                  color={color}
                  selectedOtherColor={selectedOtherColor}
                />
              )}

              {step === 5 && (
                <VehicleFinanceStepFinal
                  nextStep={nextStep}
                  backStep={backStep}
                  resetStep={resetStep}
                  userObject={userObject}
                  setUserObject={setUserObject}
                  handleSubmitTrade={handleTradeInSteps}
                  finalConfiguration={finalConfiguration}
                  mileage={mileage}
                  zipCode={zipCode}
                  setTradeInValue={setTradeInValue}
                  selectedCondition={selectedCondition}
                />
              )}
              {step === 6 && (
                <TradeInValue
                  tradeVehicleInfo={tradeVehicleInfo}
                  tradeId={tradeId}
                  info={info}
                  backStep={backStep}
                  tradeInValue={tradeInValue}
                />
              )}

              {step === 8 && (
                <CreditFinance
                  vehicleId={info?._id}
                  initialStep={initialFinanceStep}
                  creditAppDetail={creditAppDetail}
                />
              )}

              {step === 10 && (
                <ScheduleDriveStep1
                  nextScheduleStep={nextScheduleFormStep}
                  time={time}
                  setTime={setTime}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  vehicleId={info?._id}
                />
              )}

              {step === 11 && (
                <ScheduleDriveStep2
                  selectedData={selectedData}
                  resetStep={() => setStep(10)}
                  nextScheduleStep={nextScheduleFinal}
                  time={time}
                  scheduleTestDriveData={scheduleTestDriveData}
                  vehicleId={info?._id}
                  setScheduleTestDriveData={setScheduleTestDriveData}
                />
              )}

              {step === 12 && (
                <ScheduleDriveStepFinal
                  startFinishYourDeal={startFinishYourDeal}
                  startAutomotiveKBB={startAutomotiveKBB}
                  time={time}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  vehicleInfo={info}
                  leadId={scheduleTestDriveData?._id}
                />
              )}

              {step === 15 && (
                <FinishDealStep1
                  selectedTrade={selectedTrade}
                  nextFinishDealStep={nextFinishDealFormStep}
                  calculation={calculation}
                />
              )}

              {step === 16 && (
                <FinishDealStepFinal
                  resetStep={resetStep}
                  offerId={selectedOffer?._id}
                  vehicleId={info?._id}
                  setStep={setStep}
                  setFinishYourDealStepStatus={setFinishYourDealStepStatus}
                />
              )}
              {step === 17 && (
                <div className="w-3/4 left-50 items-center border-2 border-gray-200 mx-5 rounded-lg p-5">
                  <p className="font-bold text-2xl">Extended Service Coverage</p>
                  <p>Choose your extended service coverage.</p>
                  <Radio.Group onChange={() => {}} value={1}>
                    <Space direction="vertical">
                      {services.map((service, index) => {
                        return (
                          <div key={index} className="p-2">
                            <Radio value={index}>
                              <div
                                className={`border-2 border-gray-200 px-5 py-3 ${!service.monthlyPrice && 'hidden'}`}
                              >
                                <div className="flex flex-row	absolute -top-4 right-5">
                                  <p className="bg-blue-900 font-bold px-3 rounded-l-lg text-white">
                                    {service.monthlyPrice}
                                  </p>
                                  <p className="bg-blue-600 px-3 font-bold rounded-r-lg text-white">
                                    {service.singlePrice}
                                  </p>
                                </div>
                                <p className="font-bold text-xl">{service.title}</p>
                                <p className={`text-blue-400 pt-2 ${!service.details && 'hidden'}`}>Details</p>
                              </div>
                            </Radio>
                          </div>
                        )
                      })}
                    </Space>
                  </Radio.Group>
                  <div className="w-full flex items-center ml-64">
                    <Button className="bg-white rounded-4xl h-8 w-8 items-center mt-4" icon={<AiOutlineLeft />} />
                    <Button className="bg-white rounded-lg w-64 h-12 ml-4 mt-4">Next</Button>
                  </div>
                </div>
              )}

              {step < 5 && (
                <>
                  <div className="mt-8">
                    <p className="italic text-xs text-red-500">
                      * Please use Back button to refetch data from Kelley Blue Book®.
                    </p>
                  </div>
                  <div className="mt-2 mb-12 flex">
                    <img src={kelleyImage} alt="" width="90" style={{ height: '56px' }} />
                    <div className="ml-3">
                      <p className="text-gray-700 text-sm font-medium">About Kelley Blue Book® Values</p>
                      <p className="text-gray-400 text-xs font-light">
                        For over 90 years, Kelley Blue Book® has been The Trusted Resource for used car values, helping
                        car owners understand what their used car is worth. Kelley Blue Book® Values are the results of
                        massive amounts of data, including actual sales transactions and auction prices, which are then
                        analyzed and adjusted to account for seasonality and market trends. Kelley Blue Book® Values
                        reflect local conditions in over 100 different geographic regions and are undated weekly to give
                        consumers the most up-to-date used car pricing information.
                      </p>
                    </div>
                  </div>
                  <p className="text-black text-xs font-semibold text-center">MotorMarketUSA Autos</p>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <ConfirmCreditApplicationModal
        showModal={showFinanceConfirmModal}
        closeModal={changeShowConfirmFinanceModal}
        creditApps={creditApps}
        createNewCreditApp={() => {
          setShowFinanceConfirmModal(false)
          setInitialFinanceStep(1)
          setCreditAppDetail({})
          handleProtection('financing', 8)
        }}
        setInitialCreditAppData={(data) => {
          setInitialCreditAppData(data)
          setShowFinanceConfirmModal(false)
          setExistingType('credit-app')
          setShowUseOfExistingModal(true)
          setInitialFinanceStep(6)
        }}
      />
      <Modal
        centered
        visible={showModal}
        onOk={handleChangeShowModal}
        onCancel={handleChangeShowModal}
        okText="Close"
        className="dealer-custom-deals-modal"
        destroyOnClose
        width="70%"
        footer={[
          <div className="flex justify-end">
            <Button
              className="flex gap-2 justify-center items-center font-semibold text-md rounded-lg p-4"
              icon={<BsPrinterFill className="text-md" />}
              type="primary"
              onClick={handlePrintCrediApplication}
            >
              Print
            </Button>
          </div>
        ]}
      >
        <CreditApplicationDetail creditApplicationData={creditAppDetail} creditApplicationRef={creditApplicationRef} />
      </Modal>
      <VehicleFinanceSelectionModal
        showModal={showFinanceSelectionModal}
        closeModal={changeShowFinanceSelectionModal}
        tradeIns={tradeIns}
        createNewTradeIn={() => {
          setShowFinanceSelectionModal(false)
          setSelectedTrade({})
          handleProtection('valueYourVehicle', 0)
        }}
        setInitialTradeInData={(data) => {
          setExistingType('trade-in')
          setShowUseOfExistingModal(true)
          setShowFinanceSelectionModal(false)
          setSelectedTrade(data)
        }}
      />
      <VehicleTradeInDetailModal
        showModal={showVehicleTradeDetailModal}
        handleCloseModal={handleVehicleTradeDetailModal}
        trade={selectedTrade}
      />
      <Modal
        centered
        title=""
        visible={showUseOfExistingModal}
        onCancel={handleUseOfExistingModal}
        closable={false}
        okText="Close"
        className=""
        width={350}
        destroyOnClose
        footer={[
          <div className="flex justify-end">
            <Button
              type="button"
              className="bg-primary hover:bg-activePrimary hover:text-white font-semibold rounded-lg outline-none h-full text-white justify-center uppercase"
              onClick={() => handleUseOfExistingSubmit()}
            >
              CONFIRM
            </Button>
            <Button
              type="button"
              className="hover:bg-activePrimary font-semibold rounded-lg outline-none w-36 h-full text-black hover:text-white justify-center uppercase"
              onClick={handleUseOfExistingCancel}
            >
              CANCEL
            </Button>
          </div>
        ]}
      >
        {existingType === 'trade-in' && (
          <>
            <div className="pb-2 flex gap-3 lg:gap-5 justify-center rounded-3xl">
              <div className="font-semibold text-md text-center">Please confirm the use of this existing Trade In:</div>
            </div>
            <p>
              <strong>Maker:</strong> {selectedTrade?.vehicle?.make}
            </p>
            <p>
              <strong>Model:</strong> {selectedTrade?.vehicle?.model}
            </p>
            <p>
              <strong>Year:</strong> {selectedTrade?.vehicle?.year}
            </p>
            <p>
              <strong>Source:</strong> {selectedTrade.source}
            </p>
          </>
        )}
        {existingType === 'credit-app' && (
          <div className="pb-2 flex gap-3 lg:gap-5 justify-center rounded-3xl">
            <div className="font-semibold text-md text-center">
              Please confirm the use of this existing Credit Application
            </div>
          </div>
        )}
      </Modal>
      <VehicleFinalDealModal
        showModal={showVehicleFinalDealModal}
        handleCloseModal={handleVehicleFinalDealModal}
        calculation={calculation}
        offer={selectedOffer}
        selectedTrade={selectedTrade}
      />
    </>
  )
}

VehicleFinanceModal.propTypes = {
  info: PropTypes.object,
  initStep: PropTypes.number,
  setInitStep: PropTypes.func,
  calculation: PropTypes.object
  /* handleCancel: PropTypes.func */
}

export default VehicleFinanceModal
