import React from 'react'

function SharedLinkHasExpired() {
  return (
    <div className="verification-expired-table">
      <div style={{ padding: '0 35px;' }}>
        <h1 className="verification-expired-title">{`We're sorry...`}</h1>
        <span className="verification-expired-line" />
        <p className="verification-expired-description">BUT THIS LINK HAS EXPIRED.</p>
      </div>
    </div>
  )
}

export default SharedLinkHasExpired
