import * as PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import Logo from 'src/assets/new-logo.png'

const Summary = React.forwardRef(({ formData }, ref) => {
  const { application, homeAddress, driverLicense, jobInformation, additionalIncome, references } = formData

  const applicationFormValues = useMemo(
    () => [
      {
        label: 'First Name',
        value: application?.firstName
      },
      {
        label: 'Middle Name',
        value: application?.middleName
      },
      {
        label: 'Last Name',
        value: application?.lastName
      },
      {
        label: 'Email',
        value: application?.email
      },
      {
        label: 'Date of Birth',
        value: application?.dateOfBirth
      },
      {
        label: 'Social Security Number',
        value: application?.socialSecurityNumber
      },
      {
        label: 'Mobile Number',
        value: application?.mobileNumber
      },
      {
        label: 'Marital Status',
        value: application?.maritalStatus
      },
      {
        label: 'Citizenship Status',
        value: application?.citizenshipStatus
      }
    ],
    [application]
  )

  const homeAddressFormValues = useMemo(
    () => [
      {
        label: 'Street Address',
        value: homeAddress?.streetAddress
      },
      {
        label: 'Zip Code',
        value: homeAddress?.zipCode
      },
      {
        label: 'City',
        value: homeAddress?.city
      },
      {
        label: 'State',
        value: homeAddress?.state
      },
      {
        label: 'County',
        value: homeAddress?.county
      },
      {
        label: 'Years at Address',
        value: homeAddress?.yearsAtAddress
      },
      {
        label: 'Months at Address',
        value: homeAddress?.monthsAtAddress
      },
      {
        label: 'Residential Status',
        value: homeAddress?.residentialStatus
      },
      {
        label: 'State',
        value: homeAddress?.state
      }
    ],
    [homeAddress]
  )

  const driverLicenseFormValues = useMemo(
    () => [
      {
        label: 'License Number',
        value: driverLicense?.licenseNumber
      },
      {
        label: 'License State',
        value: driverLicense?.licenseState
      },
      {
        label: 'License Issued',
        value: driverLicense?.licenseIssuedDate
      },
      {
        label: 'License Expiry',
        value: driverLicense?.licenseExpiryDate
      }
    ],
    [driverLicense]
  )

  const jobInformationFormValues = useMemo(
    () => [
      {
        label: 'Employer Name',
        value: jobInformation?.employerName
      },
      {
        label: 'Job Status',
        value: jobInformation?.jobStatus
      },
      {
        label: 'Annual Gross Income',
        value: jobInformation?.annualGrossIncome
      },
      {
        label: 'Annual Net Income',
        value: jobInformation?.annualNetIncome
      },
      {
        label: 'Work Phone',
        value: jobInformation?.workPhone
      },
      {
        label: 'Job Title',
        value: jobInformation?.jobTitle
      },
      {
        label: 'Street Address',
        value: jobInformation?.streetAddress
      },
      {
        label: 'Zip Code',
        value: jobInformation?.zipCode
      },
      {
        label: 'City',
        value: jobInformation?.city
      },
      {
        label: 'State',
        value: jobInformation?.state
      },
      {
        label: 'County',
        value: jobInformation?.county
      },
      {
        label: 'Years At Job',
        value: jobInformation?.yearsAtJob
      },
      {
        label: 'Months At Job',
        value: jobInformation?.monthsAtJob
      },
      {
        label: 'Previous Home Address',
        value: jobInformation?.previousHomeAddress
      },
      {
        label: 'Previous Job',
        value: jobInformation?.previousJob
      },
      {
        label: 'Job Type',
        value: jobInformation?.jobType
      }
    ],
    [jobInformation]
  )

  const additionalIncomeFormValues = useMemo(
    () => [
      {
        label: 'Additional Annual Income',
        value: additionalIncome?.additionalAnnualIncome
      },
      {
        label: 'Additional Annual Source',
        value: additionalIncome?.additionalIncomeSource
      }
    ],
    [additionalIncome]
  )

  const referencesFormValues = useMemo(() => {
    return references?.map((e) => [
      {
        label: 'First Name',
        value: e.firstName
      },
      {
        label: 'Last Name',
        value: e.lastName
      },
      {
        label: 'Phone',
        value: e.phone
      },
      {
        label: 'Street Addess',
        value: e.streetAddress
      },
      {
        label: 'Zip Code',
        value: e.zipCode
      },
      {
        label: 'City',
        value: e.city
      },
      {
        label: 'State',
        value: e.state
      },
      {
        label: 'County',
        value: e.county
      },
      {
        label: 'Relationship',
        value: e.relationship
      }
    ])
  }, [references])

  const dataRenderer = useMemo(() => {
    const allForm = [
      { name: 'Applicant', data: applicationFormValues },
      { name: 'Home Address', data: homeAddressFormValues },
      { name: 'Driver License', data: driverLicenseFormValues },
      { name: 'Additional Income', data: additionalIncomeFormValues },
      { name: 'Job Information', data: jobInformationFormValues }
    ]

    if (referencesFormValues?.length > 0) {
      allForm.push({
        name: `References 1`,
        data: referencesFormValues[0]
      })
    }
    return allForm
  }, [
    applicationFormValues,
    homeAddressFormValues,
    driverLicenseFormValues,
    additionalIncomeFormValues,
    jobInformationFormValues,
    referencesFormValues
  ])

  const additionalDataRender = useMemo(() => {
    if (referencesFormValues?.length < 2) return []
    return referencesFormValues
      ?.slice(1)
      .map((e, idx) => ({
        name: `References ${idx + 2}`,
        data: e
      }))
      .reduce((all, one, i) => {
        const ch = Math.floor(i / 6)
        all[ch] = [].concat(all[ch] || [], one)
        return all
      }, [])
  }, [referencesFormValues])

  return (
    <div ref={ref}>
      <img className="logo py-2 print-only" height="20" width="auto" src={Logo} alt="Logo" />
      <div className="pt-8 pl-4 text-2xl font-bold">Credit Application</div>
      <div className="grid grid-cols-2 pt-4 pl-4">
        {dataRenderer?.map((step, key) => {
          return (
            <div className="m-2" key={`data-block-${key}`}>
              <div className="mb-2 font-bold text-xl">{step.name}</div>
              {step.data?.map((e, idx) => {
                if (!e.value) return null
                return (
                  <div className="my-1" key={`data-${key}-section-${idx}`}>
                    <span className="text-md">{e.label}: </span>
                    <span className="text-md">{e.value || ''}</span>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>

      <div className="print-page-container" />
      {additionalDataRender?.map((page, key) => {
        return (
          <div className="grid grid-cols-2 pt-4 pl-4 print-page-container" key={`page-${key}-additional-data`}>
            {page?.map((step, key) => {
              return (
                <div className="m-2 " key={`data-block-${key}`}>
                  <div className="mb-2 font-bold text-xl">{step.name}</div>
                  {step.data?.map((e, idx) => {
                    if (!e.value) return null
                    return (
                      <div className="my-1" key={`data-${key}-section-${idx}`}>
                        <span className="text-md">{e.label}: </span>
                        <span className="text-md">{e.value || ''}</span>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
})

Summary.propTypes = {
  formData: PropTypes.object
}

export default Summary
