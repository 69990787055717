import { Button } from 'antd'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import Logo from '../../../assets/new-logo.png'
import './style.scss'

function Header() {
  const navigate = useNavigate()
  const { width: screenWidth } = useWindowSize()
  const isMobile = screenWidth <= 1024

  const redirectHomePage = () => {
    navigate('/')
  }

  return (
    <div className="md:sticky left-0 w-full z-50 md:shadow-lg claim-header" style={{ top: '-2px' }}>
      <div className="bg-gray-50 header-nav">
        <div className="container ml-container mx-auto flex justify-end gap-4 py-1">
          <div
            className={`flex items-center justify-end cursor-pointer hover:text-primary pt-1 login-menu  ${
              isMobile ? 'pb-5' : 'pr-4'
            }`}
            onClick={redirectHomePage}
          >
            <Button className="ml-5" type="primary" onClick={redirectHomePage}>
              Search Inventory
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-between bg-gray-50">
        <div className="container ml-container mx-auto">
          <div className="w-full flex items-center justify-center">
            <Link to="/">
              <img
                className={`logo ${isMobile ? 'mobile' : ''} transition-all delay-150 duration-300 ease-in-out pb-3`}
                src={Logo}
                alt="Logo"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
