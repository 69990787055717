import { Form, Input, InputNumber, notification, Popconfirm, Table, Typography } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import * as DealerService from 'src/services/dealer.service'
import { useAuthorizationContext } from '../../../AuthorizationProvider'

const currentYear = new Date().getFullYear()

const tableColumns = ({ handleFormSubmission, cancel, edit, editingKey }) => [
  {
    title: 'Score Range',
    dataIndex: 'scoreRange1',
    key: 'scoreRange1',
    colSpan: 2,
    width: 150,
    render: (value) => (value > 0 ? value : 'Below')
  },
  {
    title: 'Score Range',
    dataIndex: 'scoreRange2',
    key: 'scoreRange2',
    colSpan: 0,
    width: 150
  },
  {
    title: () => (
      <div className="flex flex-col">
        <div>{currentYear - 10}</div>
        <div>Estimated Rate 36</div>
      </div>
    ),
    dataIndex: 'estimatedRate36',
    key: 'estimatedRate36',
    render: (value) => <div className="text-center">{`${value.toFixed(2)}%`}</div>
  },
  {
    title: () => (
      <div className="flex flex-col">
        <div>{currentYear - 8}</div>
        <div>Estimated Rate 48</div>
      </div>
    ),
    dataIndex: 'estimatedRate48',
    key: 'estimatedRate48',
    render: (value) => <div className="text-center">{`${value.toFixed(2)}%`}</div>
  },
  {
    title: () => (
      <div className="flex flex-col">
        <div>{currentYear - 6}</div>
        <div>Estimated Rate 60</div>
      </div>
    ),
    dataIndex: 'estimatedRate60',
    key: 'estimatedRate60',
    render: (value) => <div className="text-center">{`${value.toFixed(2)}%`}</div>
  },
  {
    title: () => (
      <div className="flex flex-col">
        <div>{currentYear - 3}</div>
        <div>Estimated Rate 72</div>
      </div>
    ),
    dataIndex: 'estimatedRate72',
    key: 'estimatedRate72',
    render: (value) => <div className="text-center">{`${value.toFixed(2)}%`}</div>
  },
  {
    title: '',
    dataIndex: 'operation',
    disabled: true,
    width: 140,
    render: (_, record) => {
      const isEditing = (record) => record._id === editingKey
      const editable = isEditing(record)
      return editable ? (
        <div className="flex gap-2">
          <Typography.Link
            onClick={handleFormSubmission}
            style={{
              marginRight: 8
            }}
          >
            Save
          </Typography.Link>
          <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
            <div>Cancel</div>
          </Popconfirm>
        </div>
      ) : (
        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
          Edit
        </Typography.Link>
      )
    }
  }
]

function EditableCell({ editing, dataIndex, title, inputType, record, children, ...restProps }) {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

EditableCell.propTypes = {
  editing: PropTypes.bool,
  dataIndex: PropTypes.string,
  title: PropTypes.string,
  inputType: PropTypes.string,
  record: PropTypes.object,
  children: PropTypes.object
}

function CreditRatesAndTermsTable({ dealerId }) {
  const [rowPosition, setRowPosition] = useState(0)
  const [tableData, setTableData] = useState([])
  const [form] = Form.useForm()
  const [editingKey, setEditingKey] = useState('')
  const { dealershipAccount } = useAuthorizationContext()
  const canManagePaymentSettings = undefined !== dealershipAccount ? dealershipAccount?.permissions?.manage_payment_settings : true

  const handleFormSubmission = () => {
    const parameters = form.getFieldsValue()
    parameters.dealer_id = dealerId
    parameters.position = rowPosition
    DealerService.updateCreditScoreRangeRatesRecord(parameters)
      .then((response) => {
        const sortedRangeAndRates = response.data.sort((a, b) => a.position - b.position)
        setTableData(sortedRangeAndRates)
        setEditingKey('')
        notification.success({
          message: 'Success!',
          description: 'Updated fee successful.'
        })
      })
      .catch((error) => {
        console.error(error)
        notification.error({
          message: 'Error!',
          description: 'Cannot complete this action. Please try again!'
        })
      })
  }

  useEffect(() => {
    DealerService.getCreditScoreRangeRates(dealerId)
      .then((response) => {
        setTableData(response.data)
      })
      .catch((error) => {
        console.error(error)
        setTableData([])
      })
  }, [])

  const edit = (record) => {
    form.setFieldsValue({
      estimatedRate36: '',
      estimatedRate48: '',
      estimatedRate60: '',
      estimatedRate72: '',
      scoreRange1: '',
      scoreRange2: '',
      ...record
    })
    setRowPosition(record.position)
    setEditingKey(record._id)
  }

  const cancel = () => {
    setEditingKey('')
  }

  const renderTableColumns = tableColumns({ handleFormSubmission, cancel, edit, editingKey })
  if (!canManagePaymentSettings) {
    renderTableColumns.splice(6, 1)
  }

  const mergedColumns = useMemo(() => {
    const isEditing = (record) => record._id === editingKey
    return renderTableColumns.map((col) => {
      if (col.disabled) {
        return col
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record)
        })
      }
    })
  }, [editingKey])

  return (
    <div className="container">
      <div className="mx-auto my-10 px-4 rounded-xl border-2 py-4">
        <div>Credit Score Ranges & Rates</div>
        <Form form={form} component={false}>
          <Table
            columns={mergedColumns}
            components={{
              body: {
                cell: EditableCell
              }
            }}
            className="mt-5 payment-settings-table"
            dataSource={tableData}
            scroll={{ x: 'max-content' }}
            pagination={{ position: ['none', 'none'] }}
          />
        </Form>
      </div>
    </div>
  )
}

CreditRatesAndTermsTable.propTypes = {
  dealerId: PropTypes.number
}

export default CreditRatesAndTermsTable
