import React from 'react'

function Disclaimer() {
  return (
    <>
      <p className="text-2xl font-medium text-primary mb-3">VEHICLE DISCLAIMER</p>
      <div className="border border-lightGray rounded-xl p-4 mb-8">
        <p className="text-lg text-black mb-4">
          MotorMarketUSA.com works with the dealer body to make every effort to ensure each vehicle description and
          specifications are accurate on our site. All information contained on MotorMarketUSA along with all vehicles
          are presented “As Is” with no warranty at all either expressed or implied.
        </p>
        <p className="text-lg text-black mb-4">
          All vehicles are subject to prior sale. All vehicle listed prices do not include applicable tax, title,
          license, processing and /or dealer documentation fees. MotorMarketUSA does take great care to certify the
          accuracy of vehicle and other information on this site, errors can occur. Please be sure and verify all
          information with a certified dealership representative.
        </p>
        <p className="text-lg text-black">
          **All terms with approved credit. Your terms may vary. Monthly payments are estimates only unless otherwise
          specified by you final purchase with the dealership of your choice.
        </p>
      </div>
    </>
  )
}

export default Disclaimer
