import { getPublicIp } from '../utils'
import HttpService from './http.service'
import HttpClientService from './httpClient.service'

export function getCreditScoreRanges(dealerId) {
  return HttpService.get(`get-credit-score-ranges/${dealerId}`).then((result) => {
    const { data } = result
    return data
  })
}

export async function calculateLoan({
  userId,
  vehicleId,
  tradeIn,
  cashDown,
  loanTerm,
  loanApr,
  creditScore,
  isCash,
  zipCodeValue,
  iKnowMyCreditScore
}) {
  const ip = await getPublicIp()
  const header = new Headers({ 'Access-Control-Allow-Origin': '*' })
  const params = {
    user_id: userId,
    ip_address: ip,
    vehicle_id: vehicleId,
    trade_in: tradeIn,
    cash_down: cashDown,
    loan_term: loanTerm,
    loan_apr: loanApr,
    credit_score: creditScore,
    is_cash: isCash,
    zip_code: zipCodeValue,
    i_know_my_credit_score: iKnowMyCreditScore
  }
  return HttpClientService.post('user-customer/calculate-loan', params, { header }, { mode: 'no-cors' }).then(
    (result) => {
      const { data } = result
      return data
    }
  )
}
