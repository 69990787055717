import { LoadingOutlined } from '@ant-design/icons'
import { Button, Checkbox, DatePicker, Form, Input, Radio, Spin, notification } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Cards from 'react-credit-cards-2'
import 'react-credit-cards-2/dist/es/styles-compiled.css'
import { IoMdShareAlt } from 'react-icons/io'
import * as ClaimServices from 'src/services/claim.service'
import * as DealerService from 'src/services/dealer.service'
import moment from 'moment/moment'
import BankRoutingNumberPopup from '../../../../components/Vehicle/BoxButtonGroup/BankRoutingNumberPopup'
import './style.scss'
import { VALID_EMAIL } from '../../../../constants/constants'
import PhoneNumberInput from '../../../../components/InputComponent/PhoneNumberInput'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

function LoadingIcon() {
  return <Spin indicator={antIcon} />
}

const achDisclaimer =
  'I understand that this authorization will remain in effect until I cancel it in writing, and I agree to notify MOTOix (creators of MotorMarketUSA) in writing of any changes in my account information or termination of this authorization at least 15 days prior to the next billing date. If the above noted payment dates fall on a weekend or holiday, I understand that the payments may be executed on the next business day. For ACH debits to my checking/savings account, I understand that because these are electronic transactions, these funds may be withdrawn from my account as soon as the above noted periodic transactions dates. In this case of an ACH Transaction being rejected for Non-Sufficient Funds (NSF) I understand that MOTOix (creators of MotorMarketUSA) may at its discretion attempt to process the charge again within 30 days and agree to an additional $25.00 charge for each attempt returned NSF which will be initiated as a separate transaction from the authorized recurring payment. I acknowledge that the origination of ACH transactions to my account must comply with the provisions of U.S. law. I certify that I am an authorized user of this bank account and will not dispute these scheduled transactions with my bank; so long as the transactions correspond to the terms indicated in this authorization form.'

const creditCardDisclaimer = (
  <div>
    <div>
      You authorize regularly scheduled charges to your Credit Card account. You will be charged the amount indicated
      below each billing period. A separate convenience fee of 3% will be added to your invoice amount when you choose
      to pay via a credit card instead of the primary payment method. A receipt for each payment will be provided to you
      and the charge will appear on your credit card statement. You agree that no prior notification will be provided
      unless the date or amount changes, in which case you will receive notice from us at least 10-days prior to the
      payment being collected.
    </div>
    <br />
    <div>
      I understand that this authorization will remain in effect until I cancel it in writing, and I agree to notify
      MOTOix (creators of MotorMarketUSA) in writing of any changes in my account information or termination of this
      authorization at least 15 days prior to the next billing date. If the above noted payment dates fall on a weekend
      or holiday, I understand that the payments may be executed on the next business day. I certify that I am an
      authorized user of this credit card account and will not dispute these scheduled transactions; so long as the
      transactions correspond to the terms indicated in this authorization form.
    </div>
  </div>
)

function PaymentDetails({
  form,
  paymentMethod,
  setPaymentMethod,
  setPaymentDetails,
  getClaimBusinessPayloadMailAdapter,
  selectedPrice,
  totalMonthly
}) {
  const [emailForm] = Form.useForm()
  const [bankResolving, setBankResolving] = useState(false)
  const [showBankRoutingNumberPopup, setShowBankRoutingNumberPopup] = useState(false)
  const [bankRoutingNumber, setBankRoutingNumber] = useState('')
  const [cardNumber, setSetCardNumber] = useState('')
  const [cardHolder, setCardHolder] = useState('')
  const [expiration, setExpiration] = useState('')
  const [cvv, setCvv] = useState('')
  const [cardFocus, setCardFocus] = useState('')
  const [checkedSpecialTerms, setCheckedSpecialTerms] = useState(false)
  const [specialTerms, setSpecialTerms] = useState('')
  const [checkedBillMyDealership, setCheckedBillMyDealership] = useState(false)
  const [billMyDealershipEmail, setBillMyDealershipEmail] = useState('')
  const [billMyDealershipPhone, setBillMyDealershipPhone] = useState('')
  const [billMyDealershipContactName, setBillMyDealershipContactName] = useState('')

  const previousValues = getClaimBusinessPayloadMailAdapter() || undefined
  const contactsEmail = previousValues?.contacts?.email || ''

  const handleCheckboxBillMyDealership = () => {
    setCheckedBillMyDealership(!checkedBillMyDealership)
  }

  const handleBillMyDealershipEmail = (e) => {
    setBillMyDealershipEmail(e.target.value)
  }

  const handleBillMyDealershipPhone = (e) => {
    setBillMyDealershipPhone(e.target.value)
  }

  const handleBillMyDealershipContactName = (e) => {
    setBillMyDealershipContactName(e.target.value)
  }

  const handleChangePaymentMethod = (e) => {
    setPaymentMethod(e.target.value)
  }

  const openNotification = ({ message, description, type = 'success' }) => {
    const action = type === 'success' ? notification.success : notification.error
    action({
      message,
      description
    })
  }

  const handleSendMail = (type, email) => {
    const values = form.getFieldsValue()
    const payload = getClaimBusinessPayloadMailAdapter({ paymentDetails: values, selectedPrice, totalMonthly })
    return ClaimServices.employeeSendMailAgreement({ ...payload, email }, type)
  }

  const handleSendEmail = async () => {
    await form
      .validateFields()
      .then(async () => {
        await emailForm.validateFields(['email']).then(() => {
          const emailValue = emailForm.getFieldValue(['email'])
          if (emailValue) {
            handleSendMail('disclaimer', emailValue)
              .then(() => {
                openNotification({ message: 'Send disclaimer successful' })
              })
              .catch((error) => {
                console.error(error)
                openNotification({ message: 'Send disclaimer failed', type: 'error' })
              })
          }
        })
      })
      .catch(() => {})
  }

  const handleSendEmailInvoiceMe = async () => {
    await form
      .validateFields()
      .then(async () => {
        await form
          .validateFields(['bill_my_dealership_email', 'bill_my_dealership_phone', 'bill_my_dealership_contact_name'])
          .then(() => {
            const emailValue = form.getFieldValue(['bill_my_dealership_email'])
            const phoneValue = form.getFieldValue(['bill_my_dealership_phone'])
            const contactNameValue = form.getFieldValue(['bill_my_dealership_contact_name'])
            if (emailValue && phoneValue && contactNameValue) {
              handleSendMail('invoiceme', contactsEmail)
                .then(() => {
                  openNotification({ message: 'Send Invoice Me successful' })
                })
                .catch((error) => {
                  console.error(error)
                  openNotification({ message: 'Send Invoice Me failed', type: 'error' })
                })
            }
          })
      })
      .catch(() => {})
  }

  useEffect(() => {
    const values = form.getFieldsValue()
    setPaymentDetails({ ...values, term: selectedPrice })
    if (contactsEmail.length > 0) {
      emailForm.setFieldsValue({ ...emailForm.getFieldValue(), email: contactsEmail })
    }
  }, [selectedPrice, contactsEmail])

  const onFormChange = (values) => {
    setPaymentDetails({ ...form.getFieldsValue(), [values.target.name]: values.target.value })
  }

  const onChangeResolvingBank = async (value) => {
    setBankRoutingNumber(value)
    if (value.length > 8 && value.length < 10) {
      setBankResolving(true)
      setShowBankRoutingNumberPopup(false)
      try {
        const bankName = await DealerService.bankRoutingNumberResolve(value)
        if (bankName) form.setFieldsValue({ bankName })
      } catch (e) {
        setShowBankRoutingNumberPopup(true)
        setBankResolving(false)
        console.error(e)
      } finally {
        setBankResolving(false)
      }
    }
  }

  useEffect(() => {
    if (bankRoutingNumber === '') {
      form.setFieldsValue({ bankRoutingNumber: '' })
    }
  }, [bankRoutingNumber])

  const handleCheckboxSpecialTerms = () => {
    setCheckedSpecialTerms(!checkedSpecialTerms)
  }

  const handleSpecialTermsChange = (value) => {
    setSpecialTerms(value)
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onChange={onFormChange}
        name="payment-method-ref"
        className="w-full form-not-show-required"
        spellCheck={false}
      >
        <div className="pt-7 flex flex-col lg:flex-row gap-5">
          <div className="field-label w-full lg:w-60 text-center lg:text-left">Payment details:</div>
          <div className="text-left w-full pl-4">
            <Radio.Group
              className="text-xl flex justify-center lg:justify-start gap-12 mb-6"
              onChange={handleChangePaymentMethod}
              value={paymentMethod}
            >
              <Radio value="ACH">ACH</Radio>
              <Radio value="Credit card">Credit card</Radio>
            </Radio.Group>
            {paymentMethod === 'ACH' ? (
              <>
                <div className="flex gap-5">
                  <Form.Item
                    className="flex-1 bank-routing-search"
                    name="bankRoutingNumber"
                    label="Bank Routing Number:"
                    rules={[
                      { required: true, message: 'Bank Routing Number is required' },
                      { pattern: /[0-9]{9}/g, message: 'Bank Routing Number is invalid' },
                      { max: 9, message: 'Max allowed digits is 9' }
                    ]}
                  >
                    <Input
                      suffix={bankResolving ? <LoadingIcon /> : undefined}
                      name="bankRoutingNumber"
                      placeholder="Please fill"
                      className="w-full p-3 box-border rounded-xl border-gray-300 border"
                      type="number"
                      onChange={(e) => onChangeResolvingBank(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    className="flex-1"
                    name="bankAccountNumber"
                    label="Bank Account Number:"
                    rules={[{ required: true, message: 'Bank Account Number is required' }]}
                  >
                    <Input
                      name="bankAccountNumber"
                      placeholder="Please fill"
                      className="w-full p-3 box-border rounded-xl border-gray-300 border"
                      type="number"
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  name="bankName"
                  label="Bank Name:"
                  rules={[{ required: true, message: 'Bank Name is required' }]}
                >
                  <Input
                    name="bankName"
                    placeholder="Please fill"
                    className="w-full p-3 box-border rounded-xl border-gray-300 border"
                    type="text"
                  />
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  name="cardNumber"
                  label="Card Number:"
                  rules={[
                    { required: true, message: 'Card Number is required' },
                    { pattern: /[0-9]{15,19}/g, message: 'Card Number is invalid' },
                    { min: 15, message: 'Minimum digits 15' },
                    { max: 19, message: 'Maximum digits 19' }
                  ]}
                >
                  <Input
                    name="cardNumber"
                    placeholder="Please fill"
                    className="w-full p-3 box-border rounded-xl border-gray-300 border"
                    type="number"
                    value={cardNumber}
                    onChange={(e) => setSetCardNumber(e.target.value)}
                    onFocus={() => setCardFocus('number')}
                  />
                </Form.Item>
                <Form.Item
                  name="cardHolder"
                  label="Card Holder Name:"
                  rules={[{ required: true, message: 'Card Holder Name is required' }]}
                >
                  <Input
                    name="cardHolder"
                    placeholder="Please fill"
                    className="w-full p-3 box-border rounded-xl border-gray-300 border"
                    type="text"
                    value={cardHolder}
                    onChange={(e) => setCardHolder(e.target.value)}
                    onFocus={() => setCardFocus('name')}
                  />
                </Form.Item>
                <div className="grid grid-cols-11 gap-4">
                  <Form.Item
                    className="col-span-4"
                    name="expiration"
                    label="Expiration CN:"
                    rules={[{ required: true, message: 'Expiration is required' }]}
                  >
                    <DatePicker
                      disabledDate={(current) => {
                        const customDate = moment().format('YYYY-MM-DD')
                        return current && current < moment(customDate, 'YYYY-MM-DD')
                      }}
                      minDate={new Date()}
                      format="MM/YYYY"
                      picker="month"
                      placeholder="MM | YY"
                      className="w-full p-3 box-border rounded-xl border-gray-300 border"
                      value={expiration}
                      onChange={(date) => {
                        const dateString = new Date(date).toLocaleString(undefined, {
                          month: '2-digit',
                          year: '2-digit'
                        })
                        setExpiration(dateString)
                      }}
                      onFocus={() => setCardFocus('expiry')}
                    />
                  </Form.Item>
                  <Form.Item
                    className="flex-1 col-span-3"
                    name="cvv"
                    label="CVV:"
                    rules={[
                      { required: true, message: 'CVV is required' },
                      { pattern: /[0-9]{3,4}/g, message: 'CVV is invalid' },
                      { min: 3, message: 'Minimum digits 3' },
                      { max: 4, message: 'Maximum digits 4' }
                    ]}
                  >
                    <Input
                      name="cvv"
                      placeholder="123"
                      className="w-full p-3 box-border rounded-xl border-gray-300 border"
                      type="text"
                      value={cvv}
                      onChange={(e) => setCvv(e.target.value)}
                      onFocus={() => setCardFocus('cvc')}
                    />
                  </Form.Item>
                  <Form.Item
                    className="col-span-4"
                    name="billingZipCode"
                    label="Billing zip code:"
                    rules={[
                      { required: true, message: 'Billing zip code is required' },
                      { min: 4, max: 5 },
                      { pattern: /[0-9]/g, message: 'Only number is accepted!' }
                    ]}
                  >
                    <Input
                      name="billingZipCode"
                      placeholder="Please fill"
                      className="w-full p-3 box-border rounded-xl border-gray-300 border"
                      type="number"
                    />
                  </Form.Item>
                </div>
                <div className="mt-5">
                  <Cards number={cardNumber} name={cardHolder} expiry={expiration} cvc={cvv} focused={cardFocus} />
                </div>
              </>
            )}
            <Checkbox className="mr-2 mt-2" onChange={handleCheckboxBillMyDealership} value={checkedBillMyDealership} />
            Invoice Me
            <div className="flex gap-5">
              <Form.Item
                hidden={!checkedBillMyDealership}
                label="Contact Name:"
                name="bill_my_dealership_contact_name"
                className="flex-1"
                rules={[
                  {
                    required: checkedBillMyDealership,
                    message: 'Contact Name is required'
                  }
                ]}
              >
                <Input
                  defaultValue={billMyDealershipContactName}
                  onChnage={handleBillMyDealershipContactName}
                  hidden={!checkedBillMyDealership}
                  name="bill_my_dealership_contact_name"
                  placeholder="Please fill"
                  className="w-full p-3 box-border rounded-xl border-gray-300 border"
                  type="text"
                />
              </Form.Item>
              <Form.Item
                className="flex-1"
                hidden={!checkedBillMyDealership}
                name="bill_my_dealership_phone"
                label="Phone Number:"
                rules={[
                  { required: checkedBillMyDealership, message: 'Phone Number is required' },
                  {
                    pattern: /(___-___-____)|([0-9,-,\\),\\(,\s])/g,
                    message: 'Phone Number is invalid'
                  }
                ]}
              >
                <PhoneNumberInput
                  name="bill_my_dealership_phone"
                  defaultValue={billMyDealershipPhone}
                  onChnage={handleBillMyDealershipPhone}
                  hidden={!checkedBillMyDealership}
                  placeholder="Please fill"
                  className="w-full p-3 box-border rounded-xl border-gray-300 border"
                />
              </Form.Item>
            </div>
            <Form.Item
              hidden={!checkedBillMyDealership}
              label="Email Address:"
              name="bill_my_dealership_email"
              className="mb-0 sm-max:flex-1 mt-2"
              rules={[
                {
                  required: checkedBillMyDealership,
                  message: 'Email Address is required'
                },
                {
                  pattern: VALID_EMAIL,
                  message: 'Invalid email'
                }
              ]}
            >
              <Input
                defaultValue={billMyDealershipEmail}
                onChnage={handleBillMyDealershipEmail}
                hidden={!checkedBillMyDealership}
                name="bill_my_dealership_email"
                placeholder="Please fill"
                className="w-full p-3 box-border rounded-xl border-gray-300 border"
                type="text"
                style={{ minWidth: '240px' }}
              />
            </Form.Item>
            <Button
              hidden={!checkedBillMyDealership}
              className="hidden sm:flex px-6 py-5 h-12 rounded-lg justify-center items-center gap-2 mt-5 text-base font-semibold text-white bg-blue-700"
              onClick={handleSendEmailInvoiceMe}
            >
              Send <IoMdShareAlt className="text-xl" />
            </Button>
            <Button
              hidden={!checkedBillMyDealership}
              className="hidden sm-max:flex px-6 py-5 h-12 rounded-lg justify-center items-center gap-2 mt-5 text-base font-semibold text-white bg-blue-700"
              onClick={handleSendEmailInvoiceMe}
            >
              <IoMdShareAlt className="text-3xl" />
            </Button>
          </div>
        </div>
        <div className="pt-7 flex flex-col lg:flex-row gap-10">
          <div className="field-label w-full lg:w-60 text-center lg:text-left">
            <Checkbox className="mr-2" onChange={handleCheckboxSpecialTerms} value={checkedSpecialTerms} />
            Special Terms:
          </div>

          <div className={`flex gap-5 w-full h-auto ${!checkedSpecialTerms ? 'hidden' : ''}`}>
            <textarea
              rows={7}
              name="specialterms"
              defaultValue={specialTerms}
              onChange={(event) => handleSpecialTermsChange(event.target.value)}
              className="w-full p-3 box-border rounded-xl border-gray-300 border"
            />
          </div>
        </div>
        <div className="py-7 flex flex-col gap-5">
          <div className="w-full font-bold text-base">Disclaimer:</div>
          <div className="text-[15px]">{paymentMethod === 'ACH' ? achDisclaimer : creditCardDisclaimer}</div>
        </div>
      </Form>
      <Form
        form={emailForm}
        layout="vertical"
        onChange={onFormChange}
        name="email-form-ref"
        className="w-full custom-form-email"
        spellCheck={false}
      >
        <div className="flex flex-col lg:flex-row gap-3 lg:gap-5 border-b pb-7 lg:items-center">
          <div className="text-secondary-color text-base font-semibold">Send disclaimer to my email:</div>
          <div className="flex gap-4 w-full lg:w-auto lg:items-center">
            <Form.Item
              name="email"
              className="mb-0 sm-max:flex-1"
              rules={[
                {
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Invalid email'
                }
              ]}
            >
              <Input
                name="email"
                placeholder="Email address"
                className="w-full p-3 box-border rounded-xl border-gray-300 border"
                type="text"
                style={{ minWidth: '240px' }}
              />
            </Form.Item>
            <Button
              className="hidden sm:flex px-6 py-5 h-12 rounded-lg justify-center items-center gap-2 text-base font-semibold text-white bg-blue-700"
              onClick={handleSendEmail}
            >
              Send <IoMdShareAlt className="text-xl" />
            </Button>
            <Button
              className="hidden sm-max:flex px-6 py-5 h-12 rounded-lg justify-center items-center gap-2 text-base font-semibold text-white bg-blue-700"
              onClick={handleSendEmail}
            >
              <IoMdShareAlt className="text-3xl" />
            </Button>
          </div>
        </div>
      </Form>
      <BankRoutingNumberPopup
        show={showBankRoutingNumberPopup}
        setShow={setShowBankRoutingNumberPopup}
        setResetNumber={setBankRoutingNumber}
        closable={false}
        maskClosable={false}
      />
    </>
  )
}

PaymentDetails.propTypes = {
  paymentMethod: PropTypes.string,
  setPaymentMethod: PropTypes.func,
  setPaymentDetails: PropTypes.func,
  getClaimBusinessPayloadMailAdapter: PropTypes.func,
  selectedPrice: PropTypes.any,
  form: PropTypes.object,
  totalMonthly: PropTypes.any
}

export default PaymentDetails
