import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import React, { useState } from 'react'
import { Doughnut } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'right',
      display: true,
      fullWidth: false
    },
    title: {
      display: false,
      fullWidth: false,
      text: 'Engagement Statistic Chart'
    }
  }
}

const data = {
  labels: ['Website', 'Email campaign', 'Call', 'SMS', 'Paid ads', 'Social media', 'Other'],
  datasets: [
    {
      label: '# of Votes',
      data: [98, 62, 41, 28, 26, 15, 21],
      backgroundColor: ['#b31410', '#2775d9', '#a9c5ec', '#ccddf1', '#63c927', '#b9bbd4', '#012861'],
      borderColor: ['#b31410', '#2775d9', '#a9c5ec', '#ccddf1', '#63c927', '#b9bbd4', '#012861'],
      borderWidth: 2
    }
  ]
}

const monthOptions = [
  {
    value: 'January',
    label: 'January'
  },
  {
    value: 'February',
    label: 'February'
  },
  {
    value: 'March',
    label: 'March'
  },
  {
    value: 'April',
    label: 'April'
  },
  {
    value: 'May',
    label: 'May'
  },
  {
    value: 'June',
    label: 'June'
  },
  {
    value: 'July',
    label: 'July'
  },
  {
    value: 'August',
    label: 'August'
  },
  {
    value: 'September',
    label: 'September'
  },
  {
    value: 'October',
    label: 'October'
  },
  {
    value: 'November',
    label: 'November'
  },
  {
    value: 'December',
    label: 'December'
  }
]

export function RecentLeadStatistic() {
  const [month, setMonth] = useState(monthOptions[1].value)

  const handleChangeMonth = (event) => {
    setMonth(event.target.value)
  }
  return (
    <div className="rounded-xl border-2 p-4 mb-5">
      <div className="flex justify-between mb-5">
        <div className="text-xl font-semibold">Recent Leads</div>
        <div className="w-36">
          <select className="w-full border border-black rounded py-2 px-3" onChange={handleChangeMonth} value={month}>
            <option value="">Select Month</option>
            {monthOptions.map((item) => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex justify-center">
        <Doughnut data={data} options={options} />
      </div>
    </div>
  )
}
