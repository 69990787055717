import { Button, Form, Input, notification } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { IoMdShareAlt } from 'react-icons/io'
import TickImage from 'src/assets/tick.png'
import * as ClaimServices from 'src/services/claim.service'
import { VALID_EMAIL } from '../../../constants/constants'

function PSXProcess({ signUpData, getClaimBusinessPayloadMailAdapter }) {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const dealerId = signUpData?.dealer_id ?? 0

  useEffect(() => {
    if (signUpData?.email) {
      form.setFieldsValue({ ...form.getFieldValue(), email: signUpData.email })
    }
  }, [signUpData])

  const openNotification = ({ message, description, type = 'success' }) => {
    const action = type === 'success' ? notification.success : notification.error
    action({
      message,
      description
    })
  }

  const handleSendMail = (type, email) => {
    const payload = getClaimBusinessPayloadMailAdapter()
    return ClaimServices.employeeSendMailAgreement({ ...payload, email }, type)
  }

  const handleSendProcessEmail = async () => {
    await form
      .validateFields()
      .then(() => {
        handleSendMail('process-mail', form.getFieldValue(['email']))
          .then(() => {
            openNotification({ message: 'Send mail successful' })
            navigate(`/dealership/${dealerId}`)
          })
          .catch((error) => {
            console.error(error)
            openNotification({ message: 'Send mail failed', type: 'error' })
          })
      })
      .catch(() => {})
  }

  const emailFormRender = (form, handleSubmit, title) => (
    <Form
      form={form}
      layout="vertical"
      name="psx-form-ref"
      className="w-full custom-form-email"
      spellCheck={false}
      initialValues={{
        email: signUpData?.email ? signUpData.email : ''
      }}
    >
      <div className="flex gap-5 justify-between flex-col-reverse xl:flex-row">
        <div className="flex flex-col xl:flex-row gap-3 xl:gap-5 xl:items-center">
          <div className="text-secondary-color text-base font-semibold">{`Send ${title} to my email address:`}</div>
          <div className="flex gap-4 w-full xl:w-auto xl:items-center">
            <Form.Item
              name="email"
              className="mb-0 sm-max:flex-1"
              rules={[
                {
                  required: true,
                  message: 'Email is required'
                },
                {
                  pattern: VALID_EMAIL,
                  message: 'Invalid email'
                }
              ]}
            >
              <Input
                name="email"
                placeholder="Email address"
                className="w-full p-3 box-border rounded-xl border-gray-300 border"
                type="text"
                style={{ minWidth: '240px' }}
              />
            </Form.Item>
            <Button
              className="hidden sm:flex px-6 py-5 h-12 rounded-lg justify-center items-center gap-2 text-base font-bold text-white bg-blue-700 "
              onClick={handleSubmit}
            >
              Send <IoMdShareAlt className="text-xl" />
            </Button>
            <Button
              className="hidden sm-max:flex px-6 py-5 h-12 rounded-lg justify-center items-center gap-2 text-base font-semibold text-white bg-blue-700"
              onClick={handleSubmit}
            >
              <IoMdShareAlt className="text-3xl" />
            </Button>
          </div>
        </div>
      </div>
    </Form>
  )

  return (
    <div className="mt-5 ml-0 xl:ml-10">
      <div className="xl:ml-48 py-7 flex flex-col">
        <div className="flex justify-center xl:justify-start">
          <img src={TickImage} height={150} width={180} alt="tick" />
        </div>
        <div className="text-2xl font-bold my-5">{`Congratulation, you're done!`}</div>
        <div className="mb-7 text-base font-medium">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc laoreet suscipit sagittis. Vivamus vel metus sit
          amet velit iaculis feugiat vel non tortor.
        </div>
        <div className="flex flex-col gap-4 text-base font-medium">
          <div>
            1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc laoreet suscipit sagittis. Vivamus vel
            metus sit amet velit iaculis feugiat vel non tortor.
          </div>
          <div>
            2. Sed accumsan ligula ac elit blandit elementum. Vivamus ut blandit lectus. Ut blandit malesuada dictum.
            Mauris ornare urna nec nunc sollicitudin, ac fringilla ligula cursus. Nunc ullamcorper sem sit amet velit
            viverra pellentesque.
          </div>
          <div>
            3. Nam ex sem, mollis vel molestie eu, facilisis ut est. Maecenas hendrerit lacus ex, ut bibendum est
            blandit nec. Fusce diam enim, hendrerit at enim vel, euismod porta orci. Praesent vulputate dui ac nis!
            bibendum, id pretium orci volutpat. Praesent ullamcorper quam metus, blandit tempus justo consectetur et.
          </div>
          <div className="mt-5 mb-8 text-base font-medium">
            Send your process email so we can redirect you to your inventory.
          </div>
        </div>
        {emailFormRender(form, handleSendProcessEmail, 'process')}
      </div>
    </div>
  )
}

PSXProcess.propTypes = {
  signUpData: PropTypes.object,
  getClaimBusinessPayloadMailAdapter: PropTypes.func
}

export default PSXProcess
