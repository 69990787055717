import { Form } from 'antd'
import { debounce } from 'lodash'
import * as PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import InputMask from 'react-input-mask'
import NumberInput from 'src/components/InputComponent/NumberInput'
import { STATES } from 'src/constants/constants'
import * as ClaimServices from 'src/services/claim.service'

const residentialStatusOptions = [
  { id: 'own', name: 'Own' },
  { id: 'mortgage', name: 'Mortgage' },
  { id: 'rent', name: 'Rent' }
]

const getListYears = () => {
  const listYears = []
  let year = 0
  while (year <= 50) {
    listYears.push(year)
    year += 1
  }
  return listYears
}

const getListMonths = () => {
  const listMonths = []
  let month = 0
  while (month <= 11) {
    listMonths.push(month)
    month += 1
  }
  return listMonths
}

function PreviousHomeAddress({
  previousHomeAddressRef,
  preSelectedResidentialStatus,
  handleChangePreResidentialStatus
}) {
  const listYears = getListYears()
  const listMonths = getListMonths()

  const onResolvingZipCode = async (event) => {
    const zipCode = event.target.value
    if (zipCode) {
      try {
        const location = await ClaimServices.getLocationFromZipCode(zipCode)

        if (location) {
          const { city, region } = location.data
          if (city && city !== '-') previousHomeAddressRef.current.setFieldsValue({ city })
          const state = STATES.find((e) => e.name === region)
          if (state) {
            previousHomeAddressRef.current.setFieldsValue({ state: state.name })
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const debounceZipCodeChange = useMemo(() => {
    return debounce((value) => {
      onResolvingZipCode(value)
    }, 500)
  }, [])

  return (
    <Form layout="vertical" ref={previousHomeAddressRef} name="home-address-ref">
      <div className="mt-4 step-4">
        <p className="text-2xl text-black font-bold mb-3">Previous Home Address</p>
        <Form.Item
          name="streetAddress"
          label="Street Address"
          rules={[
            { required: true, message: 'Street Address is required' },
            { pattern: /^[a-zA-Z0-9\s]+$/g, message: 'Alphanumeric string accepted' }
          ]}
          className="custom-antd-label block"
        >
          <input name="streetAddress" autoFocus className="w-full border border-black rounded py-2 px-3" type="text" />
        </Form.Item>
        <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1  gap-0 xl:gap-6 lg:gap-5 md:gap-4 sm:gap-0">
          <Form.Item
            name="zipCode"
            label="Zip Code"
            rules={[{ required: true, message: 'Zip code is required' }]}
            className="custom-antd-label block"
          >
            <InputMask
              name="zipCode"
              className="w-full border border-black rounded py-2 px-3"
              type="text"
              maxLength={5}
              onChange={debounceZipCodeChange}
            />
          </Form.Item>
          <Form.Item
            name="city"
            label="City"
            rules={[
              { required: true, message: 'City is required' },
              { pattern: /^[a-zA-Z\s]+$/g, message: 'City consist only of alphabets' }
            ]}
            className="custom-antd-label block"
          >
            <input name="city" className="w-full border border-black rounded py-2 px-3" type="text" />
          </Form.Item>
          <Form.Item
            name="state"
            label="State"
            rules={[{ required: true, message: 'State is required' }]}
            className="custom-antd-label block"
          >
            <select
              name="state"
              className="w-full bg-white border border-black rounded py-2 px-3 select-option-sell-your-car"
            >
              <option>Select state</option>
              {STATES.map((item) => (
                <option value={item.name} key={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </Form.Item>
          <Form.Item
            name="county"
            label="County"
            rules={[{ pattern: /^[a-zA-Z\s]+$/g, message: 'County consist only of alphabets' }]}
            className="custom-antd-label block"
          >
            <input name="county" className="w-full border border-black rounded py-2 px-3" type="text" />
          </Form.Item>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-0 xl:gap-6 lg:gap-5 md:gap-4 sm:gap-0">
          <Form.Item
            name="yearsAtAddress"
            label="Years at Address"
            rules={[{ required: true, message: 'Years at address is required' }]}
            className="custom-antd-label block"
          >
            <select className="w-full border border-black bg-white rounded py-2 px-3 select-option-sell-your-car">
              <option disabled selected>
                Select year
              </option>
              {listYears.map((year) => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </select>
          </Form.Item>
          <Form.Item
            name="monthsAtAddress"
            label="Months at Address"
            rules={[{ required: true, message: 'Months at address is required' }]}
            className="custom-antd-label block"
          >
            <select className="w-full border border-black bg-white rounded py-2 px-3 select-option-sell-your-car">
              <option disabled selected>
                Select month
              </option>
              {listMonths.map((month) => (
                <option value={month} key={month}>
                  {month}
                </option>
              ))}
            </select>
          </Form.Item>
          <div className="mb-5 xl:mb-0 lg:mb-0 md:mb-5 sm:mb-5">
            <p className="text-black text-sm mb-2 font-semibold">Residential Status</p>
            <div className="flex gap-2">
              {residentialStatusOptions.map((item) => (
                <div
                  className={`flex flex-col items-center px-2 shadow-xl rounded-md ${
                    preSelectedResidentialStatus === item.id ? 'bg-primary text-white' : ''
                  }`}
                  key={`color-${item.name}`}
                >
                  <button
                    type="button"
                    className={`w-16 h-10 rounded cursor-pointer flex justify-center items-center `}
                    data-status={item.id}
                    onClick={handleChangePreResidentialStatus}
                  >
                    {item.name}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
        {preSelectedResidentialStatus === residentialStatusOptions[1].id && (
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xl:gap-6 lg:gap-5 md:gap-4 sm:gap-0">
            <Form.Item
              name="mortgageLenderName"
              label="Mortgage Lender Name"
              rules={[
                { required: true, message: 'Mortgage lender name is required' },
                { pattern: /^[a-zA-Z\s]+$/g, message: 'Mortgage Lender Name consist only of alphabets' }
              ]}
              className="custom-antd-label sm:mb-0 block"
            >
              <input name="mortgageLenderName" className="w-full border border-black rounded py-2 px-3" type="text" />
            </Form.Item>
            <Form.Item
              name="monthlyMortgage"
              label="Monthly Mortgage"
              rules={[{ required: true, message: 'Monthly mortgage is required' }]}
              className="custom-antd-label sm:mb-0 block"
            >
              <NumberInput
                name="monthlyMortgage"
                placeholder="$"
                className="w-full border border-black rounded py-2 px-3"
              />
            </Form.Item>
          </div>
        )}

        {preSelectedResidentialStatus === residentialStatusOptions[2].id && (
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xl:gap-6 lg:gap-5 md:gap-4 sm:gap-0">
            <Form.Item
              name="landlordName"
              label="Landlord Name"
              rules={[
                { required: true, message: 'Landlord name is required' },
                { pattern: /^[a-zA-Z\s]+$/g, message: 'Landlord Name consist only of alphabets' }
              ]}
              className="custom-antd-label sm:mb-0 block"
            >
              <input name="landlordName" className="w-full border border-black rounded py-2 px-3" type="text" />
            </Form.Item>
            <Form.Item
              name="monthlyRent"
              label="Monthly Rent"
              rules={[{ required: true, message: 'Monthly rent is required' }]}
              className="custom-antd-label sm:mb-0 block"
            >
              <NumberInput
                name="monthlyRent"
                placeholder="$"
                className="w-full border border-black rounded py-2 px-3"
              />
            </Form.Item>
          </div>
        )}
      </div>
    </Form>
  )
}

PreviousHomeAddress.propTypes = {
  previousHomeAddressRef: PropTypes.object,
  preSelectedResidentialStatus: PropTypes.string,
  handleChangePreResidentialStatus: PropTypes.func
}

function HomeAddress({
  homeAddressFormRef,
  previousHomeAddressRef,
  selectedResidentialStatus,
  handleChangeResidentialStatus,
  preSelectedResidentialStatus,
  handleChangePreResidentialStatus
}) {
  const listYears = getListYears()
  const listMonths = getListMonths()
  const [showPreviousForm, setPreviousForm] = useState(false)

  const handleYearsAtAddress = () => {
    if (homeAddressFormRef?.current?.getFieldValue('yearsAtAddress')) {
      const yearsAtAddressValue = homeAddressFormRef?.current?.getFieldValue('yearsAtAddress') < 2
      setPreviousForm(yearsAtAddressValue)
    }
  }

  const onResolvingZipCode = async (event) => {
    const zipCode = event.target.value
    if (zipCode) {
      try {
        const location = await ClaimServices.getLocationFromZipCode(zipCode)
        if (location) {
          const { city, region } = location.data
          if (city && city !== '-') homeAddressFormRef.current.setFieldsValue({ city })
          const state = STATES.find((e) => e.name === region)
          if (state) {
            homeAddressFormRef.current.setFieldsValue({ state: state.name })
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const debounceZipCodeChange = useMemo(() => {
    return debounce((value) => {
      onResolvingZipCode(value)
    }, 500)
  }, [])

  return (
    <div>
      <Form layout="vertical" ref={homeAddressFormRef} name="home-address-ref">
        <div className="mt-4 step-4">
          <p className="text-2xl text-black font-bold mb-3">Home Address</p>
          <Form.Item
            name="streetAddress"
            label="Street Address"
            rules={[
              { required: true, message: 'Street Address is required' },
              { pattern: /^[a-zA-Z0-9\s]+$/g, message: 'Alphanumeric string accepted' }
            ]}
            className="custom-antd-label block"
          >
            <input
              name="streetAddress"
              autoFocus
              className="w-full border border-black rounded py-2 px-3"
              type="text"
            />
          </Form.Item>
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1  gap-0 xl:gap-6 lg:gap-5 md:gap-4 sm:gap-0">
            <Form.Item
              name="zipCode"
              label="Zip Code"
              rules={[{ required: true, message: 'Zip code is required' }]}
              className="custom-antd-label block"
            >
              <InputMask
                name="zipCode"
                className="w-full border border-black rounded py-2 px-3"
                type="text"
                maxLength={5}
                onChange={debounceZipCodeChange}
              />
            </Form.Item>
            <Form.Item
              name="city"
              label="City"
              rules={[
                { required: true, message: 'City is required' },
                { pattern: /^[a-zA-Z\s]+$/g, message: 'City consist only of alphabets' }
              ]}
              className="custom-antd-label block"
            >
              <input name="city" className="w-full border border-black rounded py-2 px-3" type="text" />
            </Form.Item>
            <Form.Item
              name="state"
              label="State"
              rules={[{ required: true, message: 'State is required' }]}
              className="custom-antd-label block"
            >
              <select
                name="state"
                className="w-full bg-white border border-black rounded py-2 px-3 select-option-sell-your-car"
              >
                <option>Select state</option>
                {STATES.map((item) => (
                  <option value={item.name} key={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
            </Form.Item>
            <Form.Item
              name="county"
              label="County"
              rules={[{ pattern: /^[a-zA-Z\s]+$/g, message: 'County consist only of alphabets' }]}
              className="custom-antd-label block"
            >
              <input name="county" className="w-full border border-black rounded py-2 px-3" type="text" />
            </Form.Item>
          </div>
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-0 xl:gap-6 lg:gap-5 md:gap-4 sm:gap-0">
            <Form.Item
              name="yearsAtAddress"
              label="Years at Address"
              rules={[{ required: true, message: 'Years at address is required' }]}
              className="custom-antd-label block"
            >
              <select
                onChange={handleYearsAtAddress}
                className="w-full border border-black bg-white rounded py-2 px-3 select-option-sell-your-car"
              >
                <option disabled selected>
                  Select year
                </option>
                {listYears.map((year) => (
                  <option value={year} key={year}>
                    {year}
                  </option>
                ))}
              </select>
            </Form.Item>
            <Form.Item
              name="monthsAtAddress"
              label="Months at Address"
              rules={[{ required: true, message: 'Months at address is required' }]}
              className="custom-antd-label block"
            >
              <select className="w-full border border-black bg-white rounded py-2 px-3 select-option-sell-your-car">
                <option disabled selected>
                  Select month
                </option>
                {listMonths.map((month) => (
                  <option value={month} key={month}>
                    {month}
                  </option>
                ))}
              </select>
            </Form.Item>
            <div className="mb-5 xl:mb-0 lg:mb-0 md:mb-5 sm:mb-5">
              <p className="text-black text-sm mb-2 font-semibold">Residential Status</p>
              <div className="flex gap-2">
                {residentialStatusOptions.map((item) => (
                  <div
                    className={`flex flex-col items-center px-2 shadow-xl rounded-md ${
                      selectedResidentialStatus === item.id ? 'bg-primary text-white' : ''
                    }`}
                    key={`color-${item.name}`}
                  >
                    <button
                      type="button"
                      className={`w-16 h-10 rounded cursor-pointer flex justify-center items-center `}
                      data-status={item.id}
                      onClick={handleChangeResidentialStatus}
                    >
                      {item.name}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {selectedResidentialStatus === residentialStatusOptions[1].id && (
            <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xl:gap-6 lg:gap-5 md:gap-4 sm:gap-0">
              <Form.Item
                name="mortgageLenderName"
                label="Mortgage Lender Name"
                rules={[
                  { required: true, message: 'Mortgage lender name is required' },
                  { pattern: /^[a-zA-Z\s]+$/g, message: 'Mortgage Lender Name consist only of alphabets' }
                ]}
                className="custom-antd-label sm:mb-0 block"
              >
                <input name="mortgageLenderName" className="w-full border border-black rounded py-2 px-3" type="text" />
              </Form.Item>
              <Form.Item
                name="monthlyMortgage"
                label="Monthly Mortgage"
                rules={[{ required: true, message: 'Monthly mortgage is required' }]}
                className="custom-antd-label sm:mb-0 block"
              >
                <NumberInput
                  name="monthlyMortgage"
                  placeholder="$"
                  className="w-full border border-black rounded py-2 px-3"
                />
              </Form.Item>
            </div>
          )}
          {selectedResidentialStatus === residentialStatusOptions[2].id && (
            <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xl:gap-6 lg:gap-5 md:gap-4 sm:gap-0">
              <Form.Item
                name="landlordName"
                label="Landlord Name"
                rules={[
                  { required: true, message: 'Landlord name is required' },
                  { pattern: /^[a-zA-Z\s]+$/g, message: 'Landlord Name consist only of alphabets' }
                ]}
                className="custom-antd-label sm:mb-0 block"
              >
                <input name="landlordName" className="w-full border border-black rounded py-2 px-3" type="text" />
              </Form.Item>
              <Form.Item
                name="monthlyRent"
                label="Monthly Rent"
                rules={[{ required: true, message: 'Monthly rent is required' }]}
                className="custom-antd-label sm:mb-0 block"
              >
                <NumberInput
                  name="monthlyRent"
                  placeholder="$"
                  className="w-full border border-black rounded py-2 px-3"
                />
              </Form.Item>
            </div>
          )}
        </div>
      </Form>
      <div className={`mt-5 border-t-2 ${!showPreviousForm && 'hidden'}`}>
        <PreviousHomeAddress
          preSelectedResidentialStatus={preSelectedResidentialStatus}
          handleChangePreResidentialStatus={handleChangePreResidentialStatus}
          previousHomeAddressRef={previousHomeAddressRef}
        />
      </div>
    </div>
  )
}

HomeAddress.propTypes = {
  homeAddressFormRef: PropTypes.object,
  previousHomeAddressRef: PropTypes.object,
  selectedResidentialStatus: PropTypes.string,
  handleChangeResidentialStatus: PropTypes.func,
  preSelectedResidentialStatus: PropTypes.string,
  handleChangePreResidentialStatus: PropTypes.func
}

export default HomeAddress
