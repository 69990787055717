import { Button, notification, Table } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { AiOutlineRight } from 'react-icons/ai'
import SharedLinks from 'src/components/AccountDashboard/SharedLinks'
import * as CreditApplicationForm from 'src/services/creditApplicationForm.service'
import * as CustomerService from 'src/services/customer.service'
import CreditApplicationDetail from '../CreditApplicationDetail'
import TableOfDealers from '../CreditApplicationDetail/TableOfDealers'

function MyCreditApplicationSection() {
  const [creaditApplication, setCreditApplication] = useState({ id: '' })
  const [customerData, setCustomerData] = useState([])
  const [creditApplicationData, setCreditApplicationData] = useState({})
  const [modalLoading, setModalLoading] = useState(false)
  const [customerDeals, setCustomerDeals] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showModalSharedLinks, setShowModalSharedLinks] = useState(false)
  const [showModalListOfDealers, setShowModalListOfDealers] = useState(false)

  const handleChangeSharedlinks = () => setShowModalSharedLinks(!showModalSharedLinks)

  const handleChangeShowModalListOfDealers = () => setShowModalListOfDealers(!showModalListOfDealers)

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleOnToggleCreditApp = (data) => {
    setCreditApplication(data)
    setShowModal(true)
  }

  useEffect(() => {
    const id = creaditApplication.id
    if (id) {
      setModalLoading(true)
      CreditApplicationForm.getCreditApplicationFormById(id)
        .then((response) => {
          setCreditApplicationData(response.data)
        })
        .catch((error) => {
          console.error(error)
          setModalLoading(false)
        })
        .finally(() => {
          setModalLoading(false)
        })
    }
  }, [creaditApplication])
  // for My Financing Offers

  const fetchCustomerData = useCallback((customerDeals) => {
    CustomerService.getCustomerDeals(customerDeals)
      .then((response) => {
        const results = response.data.records.map((item) => {
          return {
            _id: item._id,
            vehicle_name: item.vehicle_name,
            page_url: item.page_url,
            created_at: item.created_at,
            first_name: item.applicant_section?.first_name,
            last_name: item.applicant_section?.last_name,
            mobile_number: item.applicant_section?.mobile_number,
            email: item.applicant_section?.email,
            city: item.address_section?.city,
            state: item.address_section?.state,
            street_address: item.address_section?.street_address,
            zip_code: item.address_section?.zip_code
          }
        })

        setCustomerData(results)
        setCustomerDeals({
          ...customerDeals
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  const handleFinancingTableChange = (newCustomerDeals) => {
    setCustomerDeals(newCustomerDeals)
    fetchCustomerData(newCustomerDeals)
  }

  useEffect(() => {
    fetchCustomerData(customerDeals)
  }, [])

  const handleShareLinkToDealers = (values) => {
    CustomerService.handleShareTradeInVehicleLInk(values)
      .then(() => {
        notification.success({
          message: 'Success!',
          description: 'Credit app link successfully shared'
        })
      })
      .catch((error) => {
        notification.error({
          message: 'Error!',
          description: error?.response?.data?.message ?? 'Credit app link already shared to this dealer'
        })
      })
  }

  const tableColumnsCredit = [
    {
      title: 'DATE & TIME',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (data) => {
        return data ? <div>{moment(data).format('YYYY-MM-DD HH:mm:ss')}</div> : ''
      }
    },
    {
      title: 'VEHICLE',
      key: 'vehicle_name',
      render: (data) => {
        return data ? (
          <a href={data?.page_url} target="_blank" rel="noreferrer">
            {data?.vehicle_name}
          </a>
        ) : (
          ''
        )
      }
    },
    {
      title: 'FIRST NAME',
      dataIndex: 'first_name',
      key: 'first_name'
    },
    {
      title: 'LAST NAME',
      dataIndex: 'last_name',
      key: 'last_name'
    },
    {
      title: 'MOBILE PHONE',
      dataIndex: 'mobile_number',
      key: 'mobile_number'
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'CITY',
      dataIndex: 'city',
      key: 'city'
    },
    {
      title: 'STATE',
      dataIndex: 'state',
      key: 'state'
    },
    {
      title: 'ADDRESS',
      dataIndex: 'street_address',
      key: 'street_address'
    },
    {
      title: 'ZIP CODE',
      dataIndex: 'zip_code',
      key: 'zip_code'
    },
    {
      title: 'MORE',
      dataIndex: '_id',
      key: '_id',
      render: (data) => {
        return (
          <Button
            icon={<AiOutlineRight />}
            size="large"
            type="text"
            onClick={() => handleOnToggleCreditApp({ id: data })}
            className="hover:bg-primary hover:text-white flex items-center justify-center rounded-xl"
          />
        )
      }
    }
  ]

  return (
    <div className="container mb-6">
      <div className="font-semibold text-xl p-5">My Credit Applications</div>
      <Table
        className="my-leads-table text-center"
        pagination={{ current: 1, pageSize: 100 }}
        columns={tableColumnsCredit}
        dataSource={customerData}
        scroll={{ x: 1200 }}
        onChange={handleFinancingTableChange}
      />
      <CreditApplicationDetail
        showModal={showModal}
        creditApplicationData={creditApplicationData}
        modalLoading={modalLoading}
        handleCloseModal={handleCloseModal}
        handleChangeSharedlinks={handleChangeSharedlinks}
        handleChangeShowModalListOfDealers={handleChangeShowModalListOfDealers}
      />
      {showModalListOfDealers && (
        <TableOfDealers
          objectId={creditApplicationData._id}
          type="credit-application"
          handleShareLinkToDealers={handleShareLinkToDealers}
          showModal={showModalListOfDealers}
          handleCloseModal={handleChangeShowModalListOfDealers}
        />
      )}
      {showModalSharedLinks && (
        <SharedLinks
          id={creaditApplication.id}
          type="credit-application"
          showModalSharedLinks={showModalSharedLinks}
          setShowModalSharedLinks={setShowModalSharedLinks}
        />
      )}
    </div>
  )
}

export default MyCreditApplicationSection
