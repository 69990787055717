import React, { useState } from 'react'
import BodyStepper from 'src/components/Sell/BodyStepper'
import bannerImage2 from '../../assets/mobileBanner2.png'
import Slider from '../../components/Slider/Slider'
import AuthPopup from '../../components/Vehicle/BoxButtonGroup/AuthPopup'
import LoginFormModal from '../../components/Vehicle/BoxButtonGroup/LoginFormModal'
import SignUpFormModal from '../../components/Vehicle/BoxButtonGroup/SignUpFormModal'
import './style.scss'
import SinglePageMetaDecorator from '../../decorators/SinglePageMetaDecorator'
import metaPages from '../../data/metaPages.json'
// Loaded twice in case we need to change it in the future
// eslint-disable-next-line import/no-duplicates
import metaPageImage from '../../assets/sellYourCar.png'
// eslint-disable-next-line import/no-duplicates
import sellYourCarImage from '../../assets/sellYourCar.png'

function SellYourCar() {
  const [currentStep, setCurrentStep] = useState(0)
  const [showPopupConfirm, setShowPopupConfirm] = useState(false)
  const [, setShowCashDownPopupConfirm] = useState(false)
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [showSignUpForm, setShowSignUpForm] = useState(false)

  const handleShowLoginForm = () => {
    setShowPopupConfirm(false)
    setShowLoginForm(true)
  }

  const handleShowSignUpForm = () => {
    setShowPopupConfirm(false)
    setShowSignUpForm(true)
  }

  const callbackForForm = () => {}

  return (
    <>
      <SinglePageMetaDecorator
        title={metaPages.sell.title}
        description={metaPages.sell.description}
        imageUrl={metaPageImage}
        imageAlt={metaPages.sell.imageAlt}
      />
      <Slider className="web-slider sell-your-car hidden lg:block">
        <div className="banner banner-background-sell1">
          <div className="container mx-auto">
            <p className="mt-5 text-darkPrimary font-extrabold w-1/4 text-6xl" style={{ lineHeight: '4rem' }}>
              GET AN INSTANT OFFER
            </p>
            <p className="mt-5 text-2xl text-gray-500 w-1/3">
              Our offer is good for 7 days, and we will come to pick up the car
            </p>
          </div>
        </div>
        <div className="banner banner-background2">
          <div className="container mx-auto">
            <p className="mt-5 text-darkPrimary font-extrabold text-6xl">DEPENDABLE</p>
            <p className="text-2xl text-gray-500 w-1/2">
              Buy with CONFIDENCE and peace of mind! All vehicles CERTIFIED by NIADA and come with 36 month bumper to
              bumper warranty PLUS Vehicle History and Inspection Reports.
            </p>
          </div>
        </div>
      </Slider>
      <Slider className="mobile-slider sell-your-car lg:hidden">
        <div className="h-auto flex flex-col justify-between">
          <img src={sellYourCarImage} alt="" />
          <div className="container mx-auto text-center content-end">
            <p className="text-darkPrimary font-bold text-6xl px-8 uppercase">Get an instant offer</p>
            <p className="text-2xl text-deepGrey px-8 py-14">
              Our offer is good for 7 days, and we will come to pick up the car
            </p>
          </div>
        </div>
        <div className="h-full flex flex-col justify-between">
          <img src={bannerImage2} alt="" />
          <div className="bg-gray-200 py-6 flex flex-col items-center flex-1">
            <p className="text-darkPrimary font-extrabold text-4xl text-center mb-5">DEPENDABLE</p>
            <p className="text-2xl text-gray-500 w-2/3 text-center pb-20">
              Buy with CONFIDENCE and peace of mind! All vehicles CERTIFIED by NIADA and come with 36 month bumper to
              bumper warranty PLUS Vehicle History and Inspection Reports.
            </p>
          </div>
        </div>
      </Slider>
      {/* <div className="mb-5 container mx-auto h-24">
        <Stepper currentStep={currentStep} />
      </div> */}
      <div className="container mx-auto mb-12">
        <BodyStepper currentStep={currentStep} setCurrentStep={setCurrentStep} />
      </div>
      <AuthPopup
        show={showPopupConfirm}
        setShow={setShowPopupConfirm}
        handleShowLoginForm={handleShowLoginForm}
        handleShowSignUpForm={handleShowSignUpForm}
        closable={false}
        maskClosable={false}
      />
      <LoginFormModal showModal={showLoginForm} setShowModal={setShowLoginForm} callback={callbackForForm} />
      <SignUpFormModal
        showModal={showSignUpForm}
        setShowModal={setShowSignUpForm}
        callback={callbackForForm}
        setShowCashDownPopupConfirm={setShowCashDownPopupConfirm}
      />
    </>
  )
}

export default SellYourCar
