import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

function FinishDealStep1({ nextFinishDealStep, calculation, selectedTrade }) {
  const [visible, setVisible] = useState(false)
  const [contentHeight, setContentHeight] = useState(0)
  const contentRef = useRef(null)

  useEffect(() => {
    setContentHeight(contentRef.current.clientHeight + 100)
  }, [visible])

  const showContent = () => {
    setVisible(!visible)
  }

  const vehiclePrice = () => {
    return calculation.vehiclePrice.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })
  }

  const msrpPrice = () => {
    return calculation.msrp.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })
  }

  const taxesAndFeesPrice = () => {
    return calculation.totalTaxesAndFees.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })
  }

  return (
    <div className="step-review-deal">
      <p className="text-2xl text-black font-bold">Review Your Deal</p>
      <p className="text-gray-400 text-base mb-8">Great work! Review the information below to submit to dealer.</p>
      <div className="border-b border-gray-500 px-4 py-3">
        <div className="flex justify-between">
          <p className="text-lg text-black">Your Price</p>
          <p className="text-lg text-black">{vehiclePrice()}</p>
        </div>
        <div className="flex justify-between">
          <p className="text-sm text-gray-400">MSRP</p>
          <p className="text-sm text-gray-400">{msrpPrice()}</p>
        </div>
      </div>
      <div className="border-b border-gray-500 px-4 py-3 relative">
        <div className="absolute left-0 top-3 text-primary cursor-pointer" onClick={showContent} role="presentation">
          {!visible ? <PlusOutlined /> : <MinusOutlined />}
        </div>
        <div className="cursor-pointer" onClick={showContent} role="presentation">
          <div className="flex justify-between">
            <p className="text-lg text-black">Taxes & Fees</p>
            <p className="text-lg text-black">{taxesAndFeesPrice()}</p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-gray-400">Zip Code</p>
            <p className="text-sm text-gray-400">{calculation.zipCode}</p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-gray-400">Sales Taxes</p>
            <p className="text-sm text-gray-400">
              {calculation.salesTaxesCalculated.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
              })}
            </p>
          </div>
        </div>
        <div
          className="w-full transition-all duration-300 overflow-hidden"
          style={{ maxHeight: !visible ? 0 : `${contentHeight}px` }}
        >
          <div className="pt-2" ref={contentRef}>
            <div className="pt-2" ref={contentRef}>
              <div className="flex justify-between">
                <p className="text-sm text-gray-400">Total Tees</p>
                <p className="text-sm text-gray-400">{calculation.dealerFeesLabel}</p>
              </div>
              {calculation.dealerFeesArray?.fees?.map((item) => {
                return (
                  <div className="flex justify-between">
                    <p className="text-sm text-gray-400">{item.name}</p>
                    <p className="text-sm text-gray-400">{item.valueCurrency}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="border-b border-gray-500 px-4 py-3 mb-3">
        <div className="flex justify-between cursor-pointer" role="presentation">
          <p className="text-lg text-black">Total Price</p>
          <p className="text-lg text-black">
            {calculation.totalPriceInteger.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0
            })}
          </p>
        </div>
        <div className="w-full transition-all duration-300 overflow-hidden">
          <div className="pt-2" ref={contentRef}>
            <div className="flex justify-between">
              <p className="text-sm text-gray-400">Term</p>
              <p className="text-sm text-gray-400">{calculation.term}</p>
            </div>
            <div className="flex justify-between">
              <p className="text-sm text-gray-400">Payment</p>
              <p className="text-sm text-gray-400">
                {calculation.paymentMonthlyInteger.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0
                })}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-sm text-gray-400">Interest Rate</p>
              <p className="text-sm text-gray-400">{calculation.apr}%</p>
            </div>
            <div className="flex justify-between">
              <p className="text-sm text-gray-400">Trade in equity</p>
              <p className="text-sm text-gray-400">
                {selectedTrade?.vehicle?.estimatedvalue
                  ? selectedTrade.vehicle.estimatedvalue.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0
                    })
                  : 0}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-sm text-gray-400">Down Payment</p>
              <p className="text-sm text-gray-400">
                {calculation.cashDown.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0
                })}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-sm text-gray-400">Amount Financed</p>
              <p className="text-sm text-gray-400">{calculation.amountFinancedLabel}</p>
            </div>
          </div>
        </div>
      </div>
      <p className="text-sm px-4 text-gray-400 leading-4">
        *Estimated payment based on vehicle price of {vehiclePrice()}, includes {taxesAndFeesPrice()} in fees and taxes.
        Final transaction may include additional fees/charges and texes. This is not an offer or sales contract and is
        contingent upon dealer&apos;s acceptance of these terms. See dealer for terms of purchase.
      </p>
      <div className="flex xl:flex-row gap-2 mt-10 mb-5 justify-center">
        <button
          type="button"
          className="bg-primary rounded text-white px-16 py-2 hover:bg-activePrimary"
          onClick={nextFinishDealStep}
        >
          Finalize Your Deal
        </button>
      </div>
      <p className="text-sm px-4 text-gray-400 text-center">*Your latest updates will be sent to the dealer.</p>
    </div>
  )
}

FinishDealStep1.propTypes = {
  nextFinishDealStep: PropTypes.func.isRequired,
  calculation: PropTypes.object,
  selectedTrade: PropTypes.object
}

export default FinishDealStep1
