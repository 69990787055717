import { Button, Card, notification } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { STORAGE_KEYS } from 'src/constants/constants'
import { load } from 'src/lib/storage'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import { createUpdateBudgetCalculator } from '../../services/customer.service'
import './style.scss'

function MyWallet({ selectedCard, handleModal }) {
  const previousValue = load(STORAGE_KEYS.EZ_DEALZ_BUDGET) || {}
  const isLoggedIn = localStorage.getItem(STORAGE_KEYS.EZ_DEALZ_LOGIN) === 'true'
  const { customerLogin } = useAuthorizationContext()

  const openNotification = ({ message, description, type = 'success' }) => {
    const action = type === 'success' ? notification.success : notification.error
    action({
      message,
      description
    })
  }
  const handleEzDealzButton = () => {
    if (customerLogin) {
      createUpdateBudgetCalculator(previousValue).then(() => {
        openNotification({ message: 'Success' })
      })
    }

    if (!isLoggedIn) {
      handleModal(true, false)
    }
  }

  return (
    <>
      <div className="p-4 my-6 flex items-center font-bold text-xl text-center justify-between w-10/12">
        <p className="text-xl">Find, start and finish your vehicle deal your way all within your budget!</p>
      </div>
      <div className="bg-primary border-2 px-8 py-2 rounded-xl cursor-pointer" onClick={() => selectedCard(6)}>
        <p className="text-lg font-semibold text-white">My Budget</p>
      </div>

      <div className="mt-10 flex flex-col justify-center items-center bg-gray-200 w-full p-6 ">
        <span className="inline-grid lg:grid-cols-2 grid-cols-1 gap-6">
          {[
            {
              title: 'MY CREDIT',
              subTitle: previousValue?.creditScore?.split(' (')[0] || 'VERY GOOD',
              price: `(${previousValue?.creditScore?.split(' (')[1] || '700 - 739)'}`
            },
            {
              title: 'MY PAYMENT',
              subTitle: '$0/mo.',
              price: 'Estimated Payment'
            },
            {
              title: 'DOWN PAYMENT',
              percentage: `${previousValue?.cash ? '$' : ''}${
                previousValue?.percentage || parseFloat(previousValue?.cash || 0).toLocaleString()
              }${previousValue?.percentage ? '%' : ''}${!previousValue?.percentage && !previousValue?.cash ? '%' : ''}`
            },

            {
              title: 'MY TRADE',
              other: 'Add a vehicle'
            }
          ].map((element, index) => {
            return (
              <Card
                key={`card-${index}`}
                title={element.title}
                style={{ width: 220, borderRadius: 15 }}
                className="text-center cursor-pointer"
                onClick={() => selectedCard(index + 1)}
              >
                <p className={`text-xl font-bold ${!element.subTitle && 'hidden'}`}>{element.subTitle}</p>
                <p className="text-xl font-bold">{element.percentage}</p>
                <p className="text-lg">{element.price}</p>
                <p className="text-lg text-blue-400 font-semibold">{element.other}</p>
              </Card>
            )
          })}
        </span>
        <Button onClick={handleEzDealzButton} type="primary" className="my-8 w-3/4 h-12 rounded-lg font-bold">
          Save my Ez-Dealz account
        </Button>
        {!isLoggedIn && (
          <div className="text-black flex flex-wrap justify-center">
            <span className="text-blue-700 cursor-pointer pr-1" onClick={() => handleModal(true, true)}>
              Sign in{' '}
            </span>
            or
            <p className="text-blue-700 cursor-pointer px-1" onClick={() => handleModal(true, false)}>
              {' '}
              create an account{' '}
            </p>
            to access details from any device
          </div>
        )}
      </div>
    </>
  )
}

MyWallet.propTypes = {
  selectedCard: PropTypes.func,
  handleModal: PropTypes.func
}

export default MyWallet
