import { Button } from 'antd'
import React from 'react'

function DealerReview() {
  return (
    <div className="flex flex-col gap-4 mb-4">
      <span className="text-xl font-semibold">Dealer reviews</span>
      <span>There are no reviews currently.</span>
      <div>
        <Button type="primary">Add your review</Button>
      </div>
    </div>
  )
}

export default DealerReview
