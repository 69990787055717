import { Form } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { KBB_VEHICLE_TYPE } from 'src/constants/constants'
import * as KBBPowersAutomobileServices from '../../../services/kbb-automobile.service'
import * as KBBPowersportServices from '../../../services/kbb-powersports.service'
import './style.scss'

const mapAutomobileData = (response) => {
  return response.map(({ yearId: year, modelName: model, makeName: make, trimName: displayName, ...rest }) => ({
    displayName,
    year,
    make,
    model,
    ...rest
  }))
}

function VehicleFinanceStep2({
  vehicleType,
  nextStep,
  backStep,
  yearId,
  setYearId,
  makeObject,
  setMakeObject,
  modelObject,
  setModelObject,
  trimId,
  setTrimId,
  ownership,
  setOwnership,
  mileage,
  setMiliage,
  zipCode,
  setZipCode,
  active,
  setActive,
  debit,
  setDebit,
  callInitialApi,
  setCallInitialApi,
  setVehicles,
  setTradeVehicleInfo,
  isSellYourCar,
  listYears,
  setListYears,
  makeList,
  setMakeList,
  modelList,
  setModelList,
  trimList,
  setTrimList,
  vin,
  setVin
}) {
  const { make, makeId } = makeObject || {}
  const { model, modelId } = modelObject || {}
  const formRef = useRef()

  const handleGetYears = () => {
    KBBPowersportServices.getYears({})
      .then((response) => {
        setListYears(response.data)
      })
      .catch((error) => console.error(error))
  }

  const handleAutomobileGetYears = () => {
    KBBPowersAutomobileServices.getYears({})
      .then((response) => {
        setListYears(mapAutomobileData(response.data))
      })
      .catch((error) => console.error(error))
  }

  const handleGetVehicles = () => {
    nextStep()
    if (vehicleType === KBB_VEHICLE_TYPE.AUTOMOTIVE) {
      KBBPowersAutomobileServices.getVehicles({ yearId, makeId, modelId, trimId })
        .then((response) => {
          setVehicles(response.data)
          if (response.data && response.data.length > 0) {
            setTradeVehicleInfo(response.data[0])
          }
        })
        .catch((error) => console.error(error))
    } else {
      KBBPowersportServices.getVehicles({ yearId, makeId: make, modelId: model, trimId })
        .then((response) => {
          setVehicles(response.data)
          if (response.data && response.data.length > 0) {
            setTradeVehicleInfo(response.data[0])
          }
        })
        .catch((error) => console.error(error))
    }
  }

  const getMakeList = (yearId) => {
    KBBPowersportServices.getMakes({ yearId })
      .then((response) => {
        setMakeList(response.data)
      })
      .catch((error) => console.error(error))
  }

  const getAutomobileMakeList = (makeId) => {
    KBBPowersAutomobileServices.getMakes({ makeId, yearId })
      .then((response) => {
        setMakeList(mapAutomobileData(response.data))
      })
      .catch((error) => console.error(error))
  }

  const handleChangeYear = (event) => {
    setYearId(event.target.value)
    if (vehicleType === KBB_VEHICLE_TYPE.AUTOMOTIVE) {
      getAutomobileMakeList(event.target.value)
    } else {
      getMakeList(event.target.value)
    }
    // Set model and trim is empty arr
    setModelList([])
    setTrimList([])
  }

  const getModelist = (make) => {
    KBBPowersportServices.getModels({ yearId, make })
      .then((response) => {
        setModelList(response.data)
      })
      .catch((error) => console.error(error))
  }

  const getAutomobileModeList = (makeId) => {
    KBBPowersAutomobileServices.getAutomobileModels({ yearId, makeId })
      .then((response) => {
        setModelList(mapAutomobileData(response.data))
      })
      .catch((error) => console.error(error))
  }

  const handleChangeMake = (event) => {
    const makeId = event.target.value
    let make = ''

    for (let i = 0; i < makeList.length; i++) {
      if (makeList[i].makeId === parseInt(makeId, 10)) {
        make = makeList[i].make
        break
      }
    }

    setMakeObject({ make, makeId })

    if (vehicleType === KBB_VEHICLE_TYPE.AUTOMOTIVE) {
      getAutomobileModeList(makeId)
    } else {
      getModelist(make)
    }

    // set trim is empty arr
    setTrimList([])
  }

  const getTrimList = (modelId) => {
    KBBPowersportServices.getTrims({ yearId, makeId, modelId })
      .then((response) => {
        setTrimList(response.data)
      })
      .catch((error) => console.error(error))
  }

  const getAutomobileTrimList = (modelId) => {
    KBBPowersAutomobileServices.getAutomobileTrims({ yearId, makeId, modelId })
      .then((response) => {
        setTrimList(mapAutomobileData(response.data))
      })
      .catch((error) => console.error(error))
  }

  const handleChangeModel = (event) => {
    const model = event.target.selectedOptions[0].innerHTML
    const modelId = event.target.value
    setModelObject({ modelId, model })
    if (vehicleType === KBB_VEHICLE_TYPE.AUTOMOTIVE) {
      getAutomobileTrimList(modelId)
    } else {
      getTrimList(modelId)
    }
  }

  const handleChangeTrim = (event) => {
    setTrimId(event.target.value)
  }

  const handleChangeOwnership = (event) => {
    setOwnership(event.target.value)
  }

  const handleActiveChange = (e) => {
    const { value } = e.target
    setActive(value)
  }

  useEffect(() => {
    if (vehicleType === KBB_VEHICLE_TYPE.AUTOMOTIVE) {
      handleAutomobileGetYears()
    } else {
      handleGetYears()
    }
  }, [])

  useEffect(() => {
    if (callInitialApi) {
      if (vehicleType === KBB_VEHICLE_TYPE.AUTOMOTIVE) {
        getAutomobileMakeList(yearId)
        getAutomobileModeList(makeId)
        getAutomobileTrimList(modelId)
      } else {
        getMakeList(yearId)
        getModelist(make)
        getTrimList(modelId)
      }
      setCallInitialApi(false)
    }
  }, [callInitialApi, yearId, make, makeId, modelId, model])

  const handleFormSubmission = () => {
    formRef.current
      .validateFields()
      .then(() => {
        handleGetVehicles()
        nextStep()
      })
      .catch((errorfields) => {
        console.error(errorfields)
      })
  }

  const initialValues = { year: yearId, makeId, modelId, trimId, ownership, mileage, zipCode, debit }
  const useVinField = false

  return (
    <div className="mt-8 step-2">
      <p className="text-2xl text-black font-bold">Tell Us About Your Vehicle</p>
      <p className="text-gray-400 text-base mb-3">Enter the details of your vehicle below</p>
      <Form
        onFinish={handleFormSubmission}
        layout="vertical"
        name="vehicle-ref"
        className="py-0"
        ref={formRef}
        initialValues={initialValues}
      >
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-5">
          <Form.Item name="year" label="Year" rules={[{ required: true, message: 'Year is required' }]}>
            <select className="w-full border border-black rounded py-2 px-3" value={yearId} onChange={handleChangeYear}>
              <option value="">Select</option>
              {listYears &&
                listYears.map((item) => (
                  <option key={item.year} value={item.year}>
                    {item.year}
                  </option>
                ))}
            </select>
          </Form.Item>
          <Form.Item name="makeId" label="Make" rules={[{ required: true, message: 'Make is required' }]}>
            <select
              disabled={!makeList || makeList.length < 1}
              className="w-full border border-black rounded py-2 px-3"
              value={makeId}
              onChange={handleChangeMake}
            >
              <option value="">Select</option>
              {makeList &&
                makeList.length > 0 &&
                makeList.map((item) => (
                  <option key={item.makeId} value={item.makeId}>
                    {item.make}
                  </option>
                ))}
            </select>
          </Form.Item>
          <Form.Item name="modelId" label="Model" rules={[{ required: true, message: 'Model is required' }]}>
            <select
              disabled={!modelList || modelList.length < 1}
              className="w-full border border-black rounded py-2 px-3"
              value={modelId}
              onChange={handleChangeModel}
            >
              <option value="">Select</option>
              {modelList &&
                modelList.length > 0 &&
                modelList.map((item) => (
                  <option key={item.modelId} value={item.modelId}>
                    {item.model}
                  </option>
                ))}
            </select>
          </Form.Item>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-5">
          <div className="col-span-2">
            <Form.Item name="trimId" label="Trim" rules={[{ required: true, message: 'Trim is required' }]}>
              <select
                disabled={!trimList || trimList.length < 1}
                className="w-full border border-black rounded py-2 px-3"
                value={trimId}
                onChange={handleChangeTrim}
              >
                <option value="">Select</option>
                {trimList &&
                  trimList.length > 0 &&
                  trimList.map((item) => (
                    <option key={item.trimId} value={item.trimId}>
                      {item.displayName}
                    </option>
                  ))}
              </select>
            </Form.Item>
          </div>
          <Form.Item name="ownership" label="Ownership" rules={[{ required: true, message: 'Ownership is required' }]}>
            <select
              className="w-full border border-black rounded py-2 px-3"
              onChange={handleChangeOwnership}
              value={ownership}
            >
              <option value="">Select</option>
              <option value="purchase">Purchase</option>
              <option value="lease">Lease</option>
            </select>
          </Form.Item>
        </div>
        <div
          className={`grid grid-cols-1 gap-2 sm:gap-5 ${
            isSellYourCar && useVinField ? 'sm:grid-cols-2' : 'grid-cols-2'
          }`}
        >
          {isSellYourCar && useVinField && (
            <Form.Item name="VIN" label="VIN" rules={[{ required: true, message: 'VIN is required' }]}>
              <input
                className="w-full border border-black rounded py-2 px-3"
                type="text"
                placeholder="e.g. VIN 88733"
                value={vin}
                onChange={(e) => {
                  setVin(e.target.value)
                }}
              />
            </Form.Item>
          )}
          <Form.Item name="mileage" label="Mileage" rules={[{ required: true, message: 'Mileage is required' }]}>
            <input
              className="w-full border border-black rounded py-2 px-3"
              type="number"
              placeholder="e.g. 105,000"
              value={mileage}
              onChange={(e) => setMiliage(e.target.value)}
            />
          </Form.Item>
          <Form.Item name="zipCode" label="Zip Code" rules={[{ required: true, message: 'Zip Code is required' }]}>
            <input
              className="w-full border border-black rounded py-2 px-3"
              type="number"
              placeholder="92675"
              value={zipCode}
              onChange={(e) => {
                if (e.target.value.length < 6) {
                  setZipCode(e.target.value)
                }
              }}
              maxLength={5}
            />
          </Form.Item>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-5">
          <div>
            <p className="text-black text-sm mb-2">Is There An Active Loan On This Vehicle?</p>
            <select
              className="w-full border border-black rounded py-2 px-3"
              onChange={handleActiveChange}
              value={active}
            >
              <option value="no">NO</option>
              <option value="yes">YES</option>
            </select>
          </div>
          {active === 'yes' && (
            <Form.Item
              name="debit"
              label="How Much Do You Owe"
              className={`${active === 'no' ? 'hidden' : ''}`}
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <input
                className="w-full border border-black rounded py-2 px-3"
                type="number"
                placeholder="$0.00"
                value={debit}
                onChange={(e) => setDebit(e.target.value)}
              />
            </Form.Item>
          )}
        </div>
        <div className="flex flex-col sm:flex-row content-center justify-around flex-wrap mt-12">
          <button type="button" className="bg-gray-500 rounded text-white px-16 py-2 mb-2 sm:mb-0" onClick={backStep}>
            Back
          </button>
          <button
            type="submit"
            className="bg-primary rounded text-white px-16 py-2 hover:bg-activePrimary"
            onClick={() => {
              handleFormSubmission()
            }}
            disabled={false}
          >
            Next
          </button>
        </div>
      </Form>
    </div>
  )
}

VehicleFinanceStep2.propTypes = {
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  yearId: PropTypes.string,
  setYearId: PropTypes.func,
  makeObject: PropTypes.object,
  setMakeObject: PropTypes.func,
  modelObject: PropTypes.object,
  setModelObject: PropTypes.func,
  trimId: PropTypes.string,
  setTrimId: PropTypes.func,
  ownership: PropTypes.string,
  setOwnership: PropTypes.func,
  mileage: PropTypes.string,
  setMiliage: PropTypes.func,
  zipCode: PropTypes.string,
  setZipCode: PropTypes.func,
  active: PropTypes.string,
  setActive: PropTypes.func,
  debit: PropTypes.string,
  setDebit: PropTypes.func,
  callInitialApi: PropTypes.bool,
  setCallInitialApi: PropTypes.func,
  setVehicles: PropTypes.func,
  setTradeVehicleInfo: PropTypes.func,
  vehicleType: PropTypes.any.isRequired,
  isSellYourCar: PropTypes.bool,
  listYears: PropTypes.array,
  setListYears: PropTypes.func,
  makeList: PropTypes.array,
  setMakeList: PropTypes.func,
  modelList: PropTypes.array,
  setModelList: PropTypes.func,
  trimList: PropTypes.array,
  setTrimList: PropTypes.func,
  vin: PropTypes.string,
  setVin: PropTypes.func
}

export default VehicleFinanceStep2
