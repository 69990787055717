import { Button, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { AiOutlineDoubleLeft } from 'react-icons/ai'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useNavigate, useParams } from 'react-router-dom'
import { useMeasure } from 'react-use'
import ContactDealerForm from 'src/components/ContactDealerForm'
import Share from 'src/components/Share'
import StockComponent from 'src/components/StockComponent'
import { STORAGE_KEYS } from 'src/constants/constants'
import BetweenVehicles from '../../components/Vehicle/BetweenVehicles'
import Disclaimer from '../../components/Vehicle/Disclaimer'
import FinanceCashBox from '../../components/Vehicle/FinanceCashBox'
import OptionalEquipment from '../../components/Vehicle/OptionalEquipment'
import PriceComparison from '../../components/Vehicle/PriceComparison'
import SimilarVehicles from '../../components/Vehicle/SimilarVehicles'
import VehicleBookmark from '../../components/Vehicle/VehicleBookmark'
import VehicleDetail from '../../components/Vehicle/VehicleDetail'
import VehicleImage from '../../components/Vehicle/VehicleImage'
import VehicleMetaDecorator from '../../decorators/VehicleMetaDecorator'
import * as DealerService from '../../services/dealer.service'
import * as VehicleService from '../../services/vehicle.service'
import './style.scss'

const responsiveDefault = {
  0: {
    items: 1
  },
  768: {
    items: 2
  },
  992: {
    items: 3
  },
  1200: {
    items: 4
  },
  1376: {
    items: 5
  }
}

function Vehicle() {
  const { id: combineId } = useParams()
  const id = combineId.split('-').pop()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [carDetail, setCarDetail] = useState({})
  const [hasSetting700, setHasSetting700] = useState(false)
  const [responsive, setResponsive] = useState(responsiveDefault)
  const [ref, { width }] = useMeasure()
  const isMobile = width < 500
  const sharedByDealership = sessionStorage.getItem(STORAGE_KEYS.IS_SHARED_BY_DEALERSHIP) === 'true'

  useEffect(() => {
    if (width <= 760) {
      setResponsive({ 0: { items: 1 } })
    } else if (width <= 991) {
      setResponsive({ 0: { items: 2 } })
    } else if (width <= 1199) {
      setResponsive({ 0: { items: 3 } })
    } else if (width <= 1375) {
      setResponsive({ 0: { items: 4 } })
    } else if (width >= 1376) {
      setResponsive({ 0: { items: 5 } })
    }
  }, [width])

  const { vehicleOptions, vehicleDetail } = carDetail || {}

  const getVehicleDetailAndOptions = (id) => {
    VehicleService.getVehicleDetailAndOptions(id)
      .then((response) => {
        if (sharedByDealership) {
          sessionStorage.setItem(STORAGE_KEYS.SHARED_BY_DEALERSHIP_ID, response.data.vehicleDetail.dealer_id)
        }
        setCarDetail(response.data)
        setLoading(false)
      })
      .catch((e) => {
        navigate('/')
        console.error(e.message)
      })
  }

  if (sharedByDealership) {
    if (!sessionStorage.getItem(STORAGE_KEYS.SHARED_BY_DEALERSHIP_ID) && vehicleDetail?.dealer_id) {
      sessionStorage.setItem(STORAGE_KEYS.SHARED_BY_DEALERSHIP_ID, vehicleDetail?.dealer_id)
    }
  }

  const getSetting700Credentials = () => {
    DealerService.getSetting700({ vin: id })
      .then((response) => {
        const data = response.data
        setHasSetting700(data.hasSettings ?? false)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    if (id) {
      getVehicleDetailAndOptions(id)
      getSetting700Credentials()
    }
  }, [id])

  const handleRedirectListOfVehicle = () => {
    if (sharedByDealership) {
      navigate(`/dealership/${vehicleDetail?.dealer_id}`)
    } else {
      navigate('/buy')
    }
  }

  const vehiclePhoto = () => {
    if (vehicleDetail?.vehicle?.photo_url) {
      return vehicleDetail?.vehicle?.photo_url
    }

    return vehicleDetail?.vehicle?.photos[0] || ''
  }

  const vehiclePhotosForSlider = () => {
    const mainVehiclePhoto = vehiclePhoto()

    if (vehicleDetail?.photos.length > 0) {
      if (mainVehiclePhoto !== '') {
        if (vehicleDetail?.photos[0] === mainVehiclePhoto) {
          return vehicleDetail?.photos
        }

        const photos = vehicleDetail?.photos
        return photos.unshift(mainVehiclePhoto)
      }

      return vehicleDetail?.photos
    }

    return [mainVehiclePhoto]
  }

  return (
    <div className="pt-12" ref={ref}>
      <Button
        type="text"
        onClick={handleRedirectListOfVehicle}
        className="flex relative z-10 left-0 lg:left-16 xl:left-16 bottom-5 items-center text-blue-700 font-semibold"
        icon={<AiOutlineDoubleLeft className="mr-2" />}
      >
        {sharedByDealership ? 'Dealership Inventory' : 'All Results'}
      </Button>
      <div className="container">
        {vehicleDetail?.vehicle ? (
          <>
            <VehicleMetaDecorator
              title={`MotorMarketUSA | ${vehicleDetail.vehicle.year} ${vehicleDetail.vehicle.make} ${vehicleDetail.vehicle.model}`}
              description={`${vehicleDetail.vehicle.year} ${vehicleDetail.vehicle.make} ${vehicleDetail.vehicle.model} ${vehicleDetail.vehicle.transmission_name}`}
              imageUrl={vehiclePhoto()}
              imageAlt={`MotorMarketUSA | ${vehicleDetail.vehicle.year} ${vehicleDetail.vehicle.make} ${vehicleDetail.vehicle.model} ${vehicleDetail.vehicle.transmission_name}`}
            />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-10 mb-6">
              <StockComponent vehicle={carDetail?.vehicleDetail || {}} className="w-28 absolute z-10" />
              <div className="lg:col-span-7">
                <VehicleImage images={vehiclePhotosForSlider()} />
                <div className="grid grid-cols-1 gap-10 mb-12">
                  <div className="lg:col-span-7">
                    <VehicleBookmark loading={loading} info={vehicleDetail} />
                    <VehicleDetail vehicleDetail={vehicleDetail} />
                    <OptionalEquipment options={vehicleOptions} />
                  </div>
                </div>
              </div>
              <div className="lg:col-span-5">
                <div className="flex justify-center landscape-mode">
                  <div className="md:w-full lg:w-4/5">
                    <div className="mb-5 items-center justify-between">
                      <Share vehicle={vehicleDetail?.vehicle} />
                      <PriceComparison info={vehicleDetail?.vehicle} />
                    </div>
                    <FinanceCashBox hasSetting700={hasSetting700} info={vehicleDetail} />
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:hidden xl:hidden md:-mt-16">
              <ContactDealerForm info={vehicleDetail} />
            </div>
            <Disclaimer />
            <SimilarVehicles id={id} responsive={responsive} isMobile={isMobile} />
            <BetweenVehicles id={id} responsive={responsive} isMobile={isMobile} />
          </>
        ) : (
          <Spin tip="Loading..." className="w-full spiner" size="large" />
        )}
      </div>
    </div>
  )
}

export default Vehicle
