import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import defaultCarImage from 'src/assets/placeholder_car.jpg'
import './style.scss'

function VehicleMoreDetails({ info, status }) {
  const isMobile = window.innerWidth <= 500
  const [visible, setVisible] = useState(false)
  const [contentHeight, setContentHeight] = useState(0)
  const contentRef = useRef(null)
  const vehicleDetailRef = useRef(null)
  const {
    year,
    make,
    model,
    vin,
    body_type: bodyType,
    stock_number: stockNumber,
    miles,
    exterior_color: exteriorColor,
    interior_color: interiorColor,
    engine_name: engineName,
    transmission_name: transmissionName
  } = info || {}

  const showContent = () => {
    setVisible(!visible)
  }

  useEffect(() => {
    setContentHeight(contentRef.current.clientHeight)
  }, [visible])

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (vehicleDetailRef.current && !vehicleDetailRef.current.contains(e.target)) {
        setVisible(false)
      }
    })
  }, [])
  return (
    <div className="flex lg:w-2/5 relative" ref={vehicleDetailRef}>
      <button
        type="button"
        className="relative right-12 lg:right-16 h-full w-48 cursor-pointer z-30"
        onClick={showContent}
      >
        {!isMobile && (
          <img
            className={`absolute left-0 h-24 w-auto z-10 rounded-lg ${
              status !== '' ? 'top-1/2 -translate-y-2/4' : 'bottom-0'
            }`}
            src={info.photos?.[0] || defaultCarImage}
            alt=""
          />
        )}
        {/* {!isMobile && (
          <div
            className={`relative ${
              !visible && `top-36`
            } vehicle-more-detail px-2 py-3 text-white rounded flex items-center z-20`}
          >
            {!visible && (
              <div className="bg-white w-4 h-4 text-black flex items-center justify-center rounded mr-2 p-1">
                <UnorderedListOutlined className="text-xs" />
              </div>
            )}
            {!visible && 'Vehicle Details'}
          </div>
        )} */}
      </button>
      <div className="ml-3 py-3">
        <p className="text-white text-sm font-semibold">{`${year} ${make} ${model}`}</p>
        <p className="text-white text-2xl">{bodyType}</p>
      </div>
      <div className={`absolute lg:right-16 w-full flex flex-col z-20 ${status !== '' ? 'top-full' : 'bottom-full'}`}>
        <div className="flex">
          <button
            type="button"
            className={`px-3 py-0 text-white flex justify-center items-center bg-black ${
              status === 'finished' ? 'rounded-bl rounded-br' : 'rounded-tl rounded-tr'
            } ${status !== '' && !visible ? 'opacity-0' : 'opacity-100'}`}
            onClick={showContent}
          >
            {!visible ? 'Vehicle Details' : 'Hide Details'}
            {status === 'finished' && (
              <p
                className={`ml-4 transform transition-all duration-300 text-xl leading-2 ${
                  visible ? 'rotate-90' : '-rotate-90'
                }`}
              >
                ‹
              </p>
            )}
            {status !== 'finished' && (
              <p
                className={`ml-4 transform transition-all duration-300 text-xl leading-2 ${
                  visible ? 'rotate-90' : '-rotate-90'
                }`}
              >
                ›
              </p>
            )}
          </button>
        </div>
        <div
          className={`w-full transition-all duration-300 overflow-hidden bg-lightGray shadow-lg ${
            status !== '' ? 'order-first' : ''
          }`}
          style={{ maxHeight: !visible ? 0 : `${contentHeight}px` }}
        >
          <div className={`p-5 text-white ${status !== '' ? 'pt-12' : 'pb-16'}`} ref={contentRef}>
            <div className="flex text-xs mb-1">
              <p className="text-gray-600 w-2/5">VIN</p>
              <p className="text-black w-3/5">{vin}</p>
            </div>
            <div className="flex text-xs mb-1">
              <p className="text-gray-600 w-2/5">Stock Number</p>
              <p className="text-black w-3/5">{stockNumber}</p>
            </div>
            <div className="flex text-xs mb-1">
              <p className="text-gray-600 w-2/5">Mileage</p>
              <p className="text-black w-3/5">{miles}</p>
            </div>
            <div className="flex text-xs mb-1">
              <p className="text-gray-600 w-2/5">Fuel Economy</p>
              <p className="text-black w-3/5" />
            </div>
            <div className="flex text-xs mb-1">
              <p className="text-gray-600 w-2/5">Exterior Color</p>
              <p className="text-black w-3/5">{exteriorColor}</p>
            </div>
            <div className="flex text-xs mb-1">
              <p className="text-gray-600 w-2/5">Interior Color</p>
              <p className="text-black w-3/5">{interiorColor}</p>
            </div>
            <div className="flex text-xs mb-1">
              <p className="text-gray-600 w-2/5">Interior Material</p>
              <p className="text-black w-3/5">Leather</p>
            </div>
            <div className="flex text-xs mb-1">
              <p className="text-gray-600 w-2/5">Engine</p>
              <p className="text-black w-3/5">{engineName}</p>
            </div>
            <div className="flex text-xs mb-1">
              <p className="text-gray-600 w-2/5">Fuel Type</p>
              <p className="text-black w-3/5">Gasoline</p>
            </div>
            <div className="flex text-xs mb-1">
              <p className="text-gray-600 w-2/5">Transmission</p>
              <p className="text-black w-3/5">{transmissionName}</p>
            </div>
            <div className="flex text-xs mb-1">
              <p className="text-gray-600 w-2/5">Driveline</p>
              <p className="text-black w-3/5">Front-wheel Drive</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

VehicleMoreDetails.propTypes = {
  info: PropTypes.object,
  status: PropTypes.string.isRequired
}

export default VehicleMoreDetails
