import { Button, Select } from 'antd'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineRight } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { useEzDealzWalletContext } from 'src/EzDealzWalletProvider'
import { MAX_PAYMENT, MAX_PRICE, STORAGE_KEYS, VEHICLE_CLASS, VEHICLE_MILEAGE } from 'src/constants/constants'
import { clear, load, save } from 'src/lib/storage'
import { maskNumberThousands } from 'src/utils'
import './style.scss'

const { Option } = Select

const getRangePrice = ({ maxPayment, perMonth, ezDealzBudget }) => {
  let initialFromPrice = 200
  let initialToPrice = 275

  if (maxPayment?.includes('+')) {
    initialFromPrice = parseInt(maxPayment.replace(' +', ''), 10)
  } else if (maxPayment) {
    const price = maxPayment.split(' - ')
    initialFromPrice = parseInt(price[0], 10)
    initialToPrice = parseInt(price[1], 10)
  }

  if (perMonth === true) {
    initialFromPrice *= ezDealzBudget.termLength || 36
    initialToPrice *= ezDealzBudget.termLength || 36
  }

  if (perMonth === undefined || maxPayment === undefined) {
    return { initialFromPrice: 0, initialToPrice: 0 }
  }

  return {
    initialFromPrice,
    initialToPrice
  }
}

function Budget({ selectedCard }) {
  const ezDealzBudget = load(STORAGE_KEYS.EZ_DEALZ_BUDGET) || {}
  const [maxPayment, setMaxPayment] = useState(ezDealzBudget.maxPayment)
  const [perMonth, setPerMonth] = useState(ezDealzBudget.perMonth)
  const [vehicleClass, setVehicleClass] = useState(ezDealzBudget.vehicleClass)
  const [vehicleMileage, setVehicleMileage] = useState(ezDealzBudget.vehicleMileage)
  const budgetRef = useRef(ezDealzBudget)
  const navigate = useNavigate()
  const { setShowWalletModal } = useEzDealzWalletContext()

  const { initialFromPrice, initialToPrice } = getRangePrice({ maxPayment, perMonth, ezDealzBudget })

  const handleMaxPaymentChange = (value) => {
    setMaxPayment(value)
    budgetRef.current.maxPayment = value
  }

  const handleSearchAvailabeVehicle = () => {
    let params = {
      initial_from_price: 0,
      initial_to_price: 500000,
      initial_from_mileage: 0,
      initial_to_mileage: 200000,
      initial_trim: null
    }

    if (vehicleMileage?.includes('+')) {
      params = {
        ...params,
        initial_from_mileage: parseInt(vehicleMileage.replace(' +', ''), 10)
      }
    } else if (vehicleMileage) {
      const mileage = vehicleMileage.split(' - ')
      params = {
        ...params,
        initial_from_mileage: mileage[0],
        initial_to_mileage: mileage[1]
      }
    }

    if (vehicleClass !== '') {
      params = {
        ...params,
        initial_trim: vehicleClass
      }
    }

    if (initialFromPrice === 50000) {
      params = {
        ...params,
        initial_from_price: initialFromPrice
      }
    } else {
      params = {
        ...params,
        initial_from_price: initialFromPrice,
        initial_to_price: initialToPrice
      }
    }

    let initialPriceNew = 0

    if (ezDealzBudget.cash && parseInt(ezDealzBudget.cash, 10) > 0) {
      initialPriceNew += parseInt(ezDealzBudget.cash, 10)
    }

    if (ezDealzBudget.tradeIn && ezDealzBudget.tradeIn > 0) {
      initialPriceNew += ezDealzBudget.tradeIn
    }

    if (initialPriceNew > 0 && initialPriceNew < params.initial_to_price) {
      params = {
        ...params,
        initial_from_price: initialPriceNew
      }
    }

    navigate(`/buy?${queryString.stringify(params)}`, { replace: true })
    setShowWalletModal(false)
  }

  const handlePerMonthChange = (value) => {
    setMaxPayment()
    setPerMonth(value)
    budgetRef.current.perMonth = value
  }

  const handleVehicleClassChange = (value) => {
    setVehicleClass(value)
    budgetRef.current.vehicleClass = value
  }

  const handleVehicleMileageChange = (value) => {
    setVehicleMileage(value)
    budgetRef.current.vehicleMileage = value
  }

  useEffect(() => {
    return () => {
      save(STORAGE_KEYS.EZ_DEALZ_BUDGET, JSON.stringify(budgetRef.current))
    }
  }, [])

  if (perMonth === undefined) {
    setPerMonth(true)
  }

  const getSuggestedVehiclePrice = () => {
    const initialVehiclePrice = Math.floor((initialFromPrice + initialToPrice) / 2)
    let suggestedVehiclePrice = initialVehiclePrice

    if (ezDealzBudget?.tradeIn > 0) {
      suggestedVehiclePrice += parseInt(ezDealzBudget?.tradeIn, 10)
    }

    if (ezDealzBudget?.cash > 0) {
      suggestedVehiclePrice += parseInt(ezDealzBudget?.cash, 10)
    }

    if (ezDealzBudget?.percentage > 0) {
      const percentageValue = initialVehiclePrice * (parseInt(ezDealzBudget?.percentage, 10) / 100)
      suggestedVehiclePrice += percentageValue
    }

    return suggestedVehiclePrice
  }

  const handleResetCalculator = () => {
    clear(STORAGE_KEYS.EZ_DEALZ_BUDGET)
    budgetRef.current = {}
    setMaxPayment()
    setPerMonth()
    setVehicleClass()
    setVehicleMileage()
  }

  return (
    <div className="p-3 px-8 w-full bg-white text-black">
      <div className="flex justify-between w-full">
        <span className="text-2xl font-bold">My Budget</span>
        <Button className="relative font-semibold" danger onClick={handleResetCalculator}>
          Reset
        </Button>
      </div>
      <p className="text-base py-2">
        Give us a little information and we’ll bring you the vehicles that fit your budget and needs.
      </p>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-3">
        <Select
          size="large"
          placeholder={perMonth ? 'Max Payment' : 'Max Price'}
          value={maxPayment}
          onChange={handleMaxPaymentChange}
          allowClear
          showSearch
          listHeight={300}
        >
          {(perMonth ? MAX_PAYMENT : MAX_PRICE).map((option, index) => (
            <Option key={`payment-price-${index}`} value={option.value} className="p-2">
              {option.name}
            </Option>
          ))}
        </Select>
        <Select
          size="large"
          placeholder="Per month"
          onChange={handlePerMonthChange}
          value={perMonth}
          allowClear
          showSearch
        >
          {[
            {
              name: 'Per Month',
              value: true
            },
            {
              name: 'Vehicle Price',
              value: false
            }
          ].map((option, index) => (
            <Option key={`option-${index}`} value={option.value} className="p-2">
              {option.name}
            </Option>
          ))}
        </Select>
        <Select
          allowClear
          showSearch
          size="large"
          listHeight={150}
          placeholder="Vehicle Class"
          value={vehicleClass}
          onChange={handleVehicleClassChange}
        >
          {VEHICLE_CLASS.map((option, index) => (
            <Option key={`vehicle-class-${index}`} value={option.value} className="p-2">
              {option.name}
            </Option>
          ))}
        </Select>
        <Select
          allowClear
          showSearch
          size="large"
          listHeight={150}
          placeholder="Vehicle Mileage"
          value={vehicleMileage}
          name="vehicleMileage"
          onChange={handleVehicleMileageChange}
        >
          {VEHICLE_MILEAGE.map((option, index) => (
            <Option key={`vehicle-mileage-${index}`} value={option.name} className="p-2">
              {option.name}
            </Option>
          ))}
        </Select>
      </div>
      <div className="my-4">
        {[
          {
            title: 'Trade In Value',
            value: ezDealzBudget?.tradeIn || 0,
            to: 4,
            isPercentage: false,
            label: ''
          },
          {
            title: 'Down Payment',
            value: ezDealzBudget?.cash !== undefined ? ezDealzBudget?.cash || 0 : ezDealzBudget?.percentage || 0,
            to: 3,
            isPercentage: ezDealzBudget?.percentage,
            label: ''
          },
          {
            title: 'Credit & Term',
            value: ((ezDealzBudget?.newCarLoan || 0) + (ezDealzBudget?.usedCarLoan || 0)) / 2,
            to: 1,
            isPercentage: false,
            label: ` APR for ${ezDealzBudget?.termLength || 0} mo.`
          }
        ].map((budget, index) => {
          return (
            <button
              key={`budget-${index}`}
              className="flex justify-between w-full cursor-pointer"
              onClick={() => selectedCard(budget.to)}
              type="button"
            >
              <p className="font-bold">{budget.title}</p>
              <div className="items-center flex">
                <p className="font-semibold text-gray-600 text-base">
                  {budget.isPercentage
                    ? `${maskNumberThousands(budget.value)} %`
                    : `$${maskNumberThousands(budget.value)}`}
                  {budget.label}
                </p>
                <AiOutlineRight color="blue" size={20} />
              </div>
            </button>
          )
        })}
      </div>
      <div className="flex flex-col items-center bg-gray-100 mb-8 pt-2 w-ful">
        <p className="font-bold">Suggested Vehicle Price</p>
        <p className="font-bold text-2xl">${maskNumberThousands(getSuggestedVehiclePrice())}</p>
        <Button className="relative top-2 p-2 py-2 h-10 rounded-xl font-semibold" onClick={handleSearchAvailabeVehicle}>
          Search Available Vehicles
        </Button>
      </div>
    </div>
  )
}

Budget.propTypes = {
  selectedCard: PropTypes.func
}

export default Budget
