/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Table } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { MyFavorites } from 'src/components/AccountDashboard/MyFavorites'
import { MyTradeIn } from 'src/components/AccountDashboard/MyTradeIn'
import { SavedOffers } from 'src/components/AccountDashboard/SavedOffers'
import { SearchDashboard } from 'src/components/AccountDashboard/SearchDashboard'
import * as CustomerService from 'src/services/customer.service'
import MyCreditApplicationSection from './MyCreditApplicationSection'
import metaPages from '../../data/metaPages.json'
import SinglePageMetaDecorator from '../../decorators/SinglePageMetaDecorator'
import metaPageImageCustomerDashboard from '../../assets/background-image-login.png'

import './style.scss'

function AccountDashboard() {
  const navigate = useNavigate()
  const [searchText, setSearchText] = useState('')
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10
  })

  const handleNavigateFavorites = () => {
    navigate('/my-favourites')
  }

  const tableColumns = [
    {
      title: 'DATE & TIME',
      dataIndex: 'created_at',
      key: 'created_at'
    },
    {
      title: 'FIRST NAME',
      dataIndex: 'first_name',
      key: 'first_name'
    },
    {
      title: 'LAST NAME',
      dataIndex: 'last_name',
      key: 'last_name'
    },
    {
      title: 'TYPE',
      dataIndex: 'typeLabel',
      key: 'interest'
    },
    {
      title: 'TEST TIME',
      dataIndex: 'testDateAndTime',
      key: 'testDateAndTime'
    },
    {
      title: 'ZIP CODE',
      dataIndex: 'zipcode',
      key: 'zipcode'
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'PHONE',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'COMMENTS',
      dataIndex: 'comments',
      key: 'comments'
    },
    {
      title: 'VEHICLE',
      key: 'vehicle_name',
      render: (data) => {
        return <a href={data?.page_url}>{data?.vehicle_name}</a>
      }
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'DEALER NAME',
      key: 'dealer_name',
      render: (data) => {
        return <a href={`/dealership/${data.dealer._id}`}>{data?.dealer?.dealer_name}</a>
      }
    },
    {
      title: 'DEALER ADDRESS',
      key: 'dealer_address',
      render: (data) => {
        return <div>{data?.dealer?.contacts?.address}</div>
      }
    },
    {
      title: 'DEALER PHONE',
      key: 'dealer_phone',
      render: (data) => {
        return <div>{data?.dealer?.contacts?.phone}</div>
      }
    },
    {
      title: 'DEALER EMAIL',
      key: 'dealer_email',
      render: (data) => {
        return <div>{data?.dealer?.contacts?.email}</div>
      }
    },
    {
      title: 'APPROVAL',
      dataIndex: 'approval',
      key: 'approval'
    }
  ]

  // for My Ez-Dealz

  const fetchLeadData = useCallback((pagination) => {
    CustomerService.getCustomerLeads(pagination)
      .then((response) => {
        const { total, data } = response.data
        setData(data)
        setPagination({
          ...pagination,
          total
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  const handleTableChange = (newPagination) => {
    setPagination(newPagination)
    fetchLeadData(newPagination)
  }

  useEffect(() => {
    fetchLeadData(pagination)
  }, [])

  const callClarityScriptData = `(function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        ;(c[a].q = c[a].q || []).push(arguments)
      }
    t = l.createElement(r)
    t.async = 1
    t.src = 'https://www.clarity.ms/tag/' + i
    y = l.getElementsByTagName(r)[0]
    y.parentNode.insertBefore(t, y)
  })(window, document, 'clarity', 'script', 'ffbr0mkzud')`

  return (
    <>
      <SinglePageMetaDecorator
        title={metaPages.customerDashboard.title}
        description={metaPages.customerDashboard.description}
        imageUrl={metaPageImageCustomerDashboard}
        imageAlt={metaPages.customerDashboard.imageAlt}
      />
      <Helmet>
        {process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'production' && (
          <script>{callClarityScriptData}</script>
        )}
      </Helmet>
      <div className="my-5 container items-center mx-auto">
        <SearchDashboard onSearch={(value) => setSearchText(value)} />
      </div>
      <div className="container padding-x-0 mb-6 grid grid-cols-1 lg:grid-cols-3 gap-5">
        <div className="col-span-1 lg:col-span-2 rounded-xl border-2">
          <SavedOffers searchText={searchText} />
        </div>
        <div className="rounded-xl border-2 lg:mx-0">
          <MyTradeIn />
        </div>
      </div>
      <div className="container mb-6">
        <div className="font-semibold text-xl p-5">My Ez-Dealz</div>
        <Table
          className="my-leads-table text-center"
          pagination={pagination}
          columns={tableColumns}
          dataSource={data}
          scroll={{ x: 2500 }}
          onChange={handleTableChange}
        />
      </div>
      <MyCreditApplicationSection />
      <div className="mx-1 mb-12 pt-6">
        <div className="container rounded-xl border-2 pt-6">
          <div className="flex justify-between items-center">
            <div className="text-xl font-bold mb-4">My Favorites</div>
            <div className="text-primary cursor-pointer font-bold" onClick={handleNavigateFavorites}>
              View all &nbsp; &#62;
            </div>
          </div>
          <MyFavorites />
        </div>
      </div>
    </>
  )
}

export default AccountDashboard
