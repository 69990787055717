import { Button, Form } from 'antd'
import * as PropTypes from 'prop-types'
import React from 'react'
import { BsFillTrashFill } from 'react-icons/bs'
import InputMask from 'react-input-mask'
import { STATES } from 'src/constants/constants'

function References({ referencesFormRef }) {
  return (
    <Form layout="vertical" ref={referencesFormRef}>
      <Form.List layout="vertical" name="referenceList">
        {(referenceList, { add, remove }) => (
          <div className="mt-4 step-4">
            <p className="text-2xl text-black font-bold mb-3">References</p>
            {referenceList.map((item, index) => (
              <div key={`reference-list-${index}`}>
                <div className=" mb-3 flex gap-4 items-center">
                  <p className="font-bold text-base">{`Reference ${index + 1}`}</p>
                  <BsFillTrashFill className="text-lg cursor-pointer" onClick={() => remove(item.name)} />
                </div>
                <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 gap-0 xl:gap-6 lg:gap-5 md:gap-4">
                  <Form.Item
                    name={[index, 'firstName']}
                    label="First Name"
                    rules={[
                      { required: true, message: 'First name is required' },
                      { max: 20 },
                      { pattern: /^[a-zA-Z\s]+$/g, message: 'First Name consist only of alphabets' }
                    ]}
                    className="custom-antd-label"
                  >
                    <input name="firstName" className="w-full border border-black rounded py-2 px-3" type="text" />
                  </Form.Item>
                  <Form.Item
                    name="middleName"
                    label="Middle Name"
                    rules={[
                      { max: 20, message: 'Middle name cannot be longer than 20 characters' },
                      { pattern: /^[a-zA-Z\s]+$/g, message: 'Middle name can only consist of letters' }
                    ]}
                    className="custom-antd-label"
                  >
                    <input name="middleName" className="w-full border border-black rounded py-2 px-3" type="text" />
                  </Form.Item>
                  <Form.Item
                    name={[index, 'lastName']}
                    label="Last Name"
                    rules={[
                      { required: true, message: 'Last name is required' },
                      { max: 20 },
                      { pattern: /^[a-zA-Z\s]+$/g, message: 'Last Name consist only of alphabets' }
                    ]}
                    className="custom-antd-label"
                  >
                    <input name="lastName" className="w-full border border-black rounded py-2 px-3" type="text" />
                  </Form.Item>
                </div>

                <Form.Item
                  name={[index, 'streetAddress']}
                  label="Street Address"
                  rules={[
                    { required: true, message: 'Street Address is required' },
                    { pattern: /^[a-zA-Z0-9\s]+$/g, message: 'Alphanumeric string accepted' }
                  ]}
                  className="custom-antd-label"
                >
                  <input name="streetAddress" className="w-full border border-black rounded py-2 px-3" type="text" />
                </Form.Item>

                <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-0 xl:gap-6 lg:gap-5 md:gap-4">
                  <Form.Item
                    name={[index, 'zipCode']}
                    label="Zip Code"
                    rules={[{ required: true, message: 'Zip code is required' }]}
                    className="custom-antd-label"
                  >
                    <InputMask
                      name="zipCode"
                      className="w-full border border-black rounded py-2 px-3"
                      type="text"
                      maxLength={5}
                    />
                  </Form.Item>
                  <Form.Item
                    name={[index, 'city']}
                    label="City"
                    rules={[
                      { required: true, message: 'City is required' },
                      { pattern: /^[a-zA-Z\s]+$/g, message: 'City consist only of alphabets' }
                    ]}
                    className="custom-antd-label"
                  >
                    <input name="city" className="w-full border border-black rounded py-2 px-3" type="text" />
                  </Form.Item>
                </div>

                <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-0 xl:gap-6 lg:gap-5 md:gap-4">
                  <Form.Item
                    name={[index, 'state']}
                    label="State"
                    rules={[{ required: true, message: 'State is required' }]}
                    className="custom-antd-label"
                  >
                    <select
                      name="state"
                      className="w-full bg-white border border-black rounded py-2 px-3 select-option-sell-your-car"
                    >
                      <option>Select state</option>
                      {STATES.map((item) => (
                        <option value={item.name} key={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </Form.Item>
                  <Form.Item
                    name={[index, 'county']}
                    label="County"
                    rules={[
                      { required: true, message: 'County is required' },
                      { pattern: /^[a-zA-Z\s]+$/g, message: 'County consist only of alphabets' }
                    ]}
                    className="custom-antd-label"
                  >
                    <input name="county" className="w-full border border-black rounded py-2 px-3" type="text" />
                  </Form.Item>
                </div>
                <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-0 xl:gap-6 lg:gap-5 md:gap-4">
                  <Form.Item
                    name={[index, 'relationship']}
                    label="Relationship"
                    rules={[{ required: true, message: 'Relationship is required' }]}
                    className="custom-antd-label"
                  >
                    <select
                      name="relationship"
                      className="w-full bg-white border border-black rounded py-2 px-3 select-option-sell-your-car"
                    >
                      <option>Select Relationship</option>
                      <option>Parent</option>
                      <option>Employer</option>
                      <option>Relative</option>
                      <option>Spouse</option>
                      <option>Friend</option>
                    </select>
                  </Form.Item>
                  <Form.Item
                    name={[index, 'phone']}
                    label="Phone"
                    rules={[
                      { required: true, message: 'Phone is required' },
                      { pattern: /\d{3}[-]\d{3}[-]\d{4}/, message: 'Phone is invalid format' }
                    ]}
                    className="custom-antd-label"
                  >
                    <InputMask
                      name={`phone-${index}`}
                      mask="999-999-9999"
                      placeholder="NXX-NXX-XXXX"
                      className="w-full border border-black rounded py-2 px-3"
                      type="text"
                    />
                  </Form.Item>
                </div>
              </div>
            ))}

            <div className="flex justify-center">
              <Button className="px-3 font-semibold" size="large" onClick={add}>
                Add a Reference
              </Button>
            </div>
          </div>
        )}
      </Form.List>
    </Form>
  )
}

References.propTypes = {
  referencesFormRef: PropTypes.object
}

export default References
