import { Button, Modal } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useState } from 'react'
import CreditApplicationModal from '../CreditApplicationModal'

function ConfirmCreditApplicationModal({
  showModal,
  closeModal,
  createNewCreditApp,
  setInitialCreditAppData,
  creditApps
}) {
  const [showCreditAppList, setShowCreditAppList] = useState(false)

  const handleSelectingCreditApp = (data) => {
    setInitialCreditAppData(data)
    setShowCreditAppList(false)
  }

  const handleChangeShowModal = () => {
    setShowCreditAppList(false)
  }

  return (
    <>
      <CreditApplicationModal
        creditApps={creditApps}
        showModal={showCreditAppList}
        closeModal={handleChangeShowModal}
        setSelectedCreditApp={handleSelectingCreditApp}
      />
      <Modal
        centered
        visible={showModal}
        title="Do you want to start a new credit application OR use an existing credit application from your account?"
        onCancel={closeModal}
        okText="Close"
        className="calendar-modal"
        destroyOnClose
        width="50%"
        footer={[
          <Button type="primary" onClick={createNewCreditApp}>
            New
          </Button>,
          <Button type="primary" onClick={() => setShowCreditAppList(true)}>
            Use existing
          </Button>
        ]}
      />
    </>
  )
}

ConfirmCreditApplicationModal.propTypes = {
  showModal: PropTypes.bool,
  setInitialCreditAppData: PropTypes.func,
  createNewCreditApp: PropTypes.func,
  closeModal: PropTypes.func,
  creditApps: PropTypes.array
}

export default ConfirmCreditApplicationModal
