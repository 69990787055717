import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const hostname = process.env.REACT_APP_API_FRONTEND
const twitterSite = process.env.REACT_APP_TWITTER_SITE || ''

function MetaDecorator({ title, description, imageUrl, imageAlt }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={hostname + window.location.pathname + window.location.search} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image:alt" content={imageAlt} />
      {twitterSite !== '' && <meta name="twitter:site" content={twitterSite} />}
    </Helmet>
  )
}

MetaDecorator.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired
}

export default MetaDecorator
