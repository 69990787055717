import React, { useState } from 'react'
import { AiOutlineUser } from 'react-icons/ai'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import { MenuLinkAdminDashboard, MenuLinkAdminDashboardProduction } from 'src/constants/constants'
import { handleClearStorageUserInfo } from 'src/lib/storage'
import Logo from '../../../assets/new-logo.png'
import * as AuthServices from '../../../services/auth.service'
import './style.scss'

function AdminHeaderDashboard() {
  const {
    userLogin,
    setAdminAccount,
    setUserAccount,
    handleAdminAccountLogin,
    handleUserAccountLogin,
    handleAccountSettingsLogin
  } = useAuthorizationContext()
  const navigate = useNavigate()
  const location = useLocation()
  const [mobileMenu, setMobileMenu] = useState(false)
  const { width: screenWidth } = useWindowSize()
  const isMobile = screenWidth <= 1024

  const showMobileMenu = () => {
    setMobileMenu(true)
    document.querySelector('body').setAttribute('style', 'overflow: hidden;')
  }

  const closeMobileMenu = () => {
    setMobileMenu(false)
    document.querySelector('body').setAttribute('style', '')
  }

  const handleLogout = () => {
    if (userLogin) {
      AuthServices.userLogout().finally(() => {
        handleClearStorageUserInfo()
        handleUserAccountLogin(false)
        handleAccountSettingsLogin(null)
        setUserAccount()
        navigate('/')
      })
    } else {
      handleClearStorageUserInfo()
      handleAdminAccountLogin(false)
      handleAccountSettingsLogin(null)
      setAdminAccount()
      navigate('/')
    }
  }

  const isHomePage = window.location.pathname === '/'
  const handleSeeMoreDeals = () => {
    closeMobileMenu()
    const y = document.getElementById('best-deals-of-the-week').getBoundingClientRect().top + window.scrollY
    window.scroll({
      top: y,
      behavior: 'smooth'
    })
  }

  const hideOnProduction = process.env.REACT_APP_HIDE_ON_PRODUCTION === 'true'

  let menuLinkAdminDashboard = MenuLinkAdminDashboard
  if (hideOnProduction) {
    menuLinkAdminDashboard = MenuLinkAdminDashboardProduction
  }

  return (
    <>
      <div className="md:sticky md:shadow-lg left-0 w-full z-50" style={{ top: '-2px' }}>
        <div className="bg-white header-nav">
          <div className="container ml-container mx-auto flex justify-end gap-4 py-1">
            <div className={`flex items-center justify-end pt-3 login-menu  ${isMobile ? 'pb-5' : ''}`}>
              <AiOutlineUser size={20} />
              <p className="cursor-pointer pt-2 ml-1 hover:text-primary" onClick={handleLogout}>
                Logout
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-row items-center justify-between bg-white">
          <div className="container ml-container mx-auto flex items-center">
            <div className="w-full flex">
              <Link to="/">
                <div className="flex gap-1">
                  <img
                    className={`admin-logo ${
                      isMobile ? 'mobile py-2' : ''
                    } transition-all delay-150 duration-300 ease-in-out`}
                    src={Logo}
                    alt="Logo"
                  />
                </div>
              </Link>
            </div>

            <div className="lg:flex flex-row w-full items-center justify-end">
              <ul className="hidden list-none lg:flex flex-row items-end ml-0 lg:ml-10 nav-style">
                {menuLinkAdminDashboard.map((menu, index) => (
                  <li
                    className={`menu-item ${location.pathname === menu.link ? 'active' : ''}`}
                    key={`menu-item-${index}`}
                  >
                    <Link to={menu.link}>
                      <div className="px-0 py-1">{menu.name}</div>
                    </Link>
                  </li>
                ))}
              </ul>
              <svg
                stroke="currentColor"
                fill="currentColor"
                viewBox="0 0 24 24"
                className="h-6 w-auto lg:hidden cursor-pointer float-right"
                xmlns="http://www.w3.org/2000/svg"
                onClick={showMobileMenu}
              >
                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      {mobileMenu && (
        <div className="w-screen h-screen fixed top-0 left-0 overflow-y-scroll bg-white mobile-menu pb-6">
          <div className="h-full flex flex-col px-4">
            <div className="flex mobile-img flex-row justify-between py-4 items-center border-b">
              <img src={Logo} alt="" className="w-auto object-contain" />
              <button type="button" className="py-1 px-1 outline-none" onClick={closeMobileMenu}>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  className="h-8 w-auto"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
              </button>
            </div>
            <div className="flex flex-col justify-end w-full mx-auto">
              <ul className="flex flex-col list-none gap-6 items-baseline text-xl mb-10 mt-3 mobile">
                {MenuLinkAdminDashboard.map((menu, index) => (
                  <li
                    className={`block w-full text-primary mb-2 ${location.pathname === menu.link ? 'active' : ''}`}
                    key={`menu-mobile-item-${index}`}
                    onClick={closeMobileMenu}
                    role="presentation"
                  >
                    <Link to={menu.link}>
                      <div className="px-3 py-2 rounded-xl hover:bg-primary hover:text-white menu-link">
                        {menu.name}
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
              {isHomePage && (
                <div className="flex justify-center">
                  <button
                    type="button"
                    className="mt-6 rounded-xl border border-primary px-8 py-2 hover:bg-primary hover:text-white outline-none"
                    onClick={handleSeeMoreDeals}
                  >
                    See More Deals
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default AdminHeaderDashboard
