import * as PropTypes from 'prop-types'
import React from 'react'
import NoResults from '../../assets/NoResults.png'

function ExteriorColorFoundComponent({ vehicle, className = 'w-12 absolute stock z-10' }) {
  const { exterior_color_found: exteriorColorFound } = vehicle
  if (!exteriorColorFound) {
    return <img src={NoResults} alt="stock" className={className} />
  }
  return ''
}

ExteriorColorFoundComponent.propTypes = {
  vehicle: PropTypes.object,
  className: PropTypes.string
}

export default ExteriorColorFoundComponent
