import * as PropTypes from 'prop-types'
import React, { createContext, useContext, useMemo, useState } from 'react'

const VehicleInformationContext = createContext({})

export function VehicleInformationProvider({ children }) {
  const [vehicle, setVehicle] = useState({})
  const [financeDetail, setFinanceDetail] = useState({
    fee: 1500,
    payment: 279,
    terms: 72,
    apr: 7.98
  })
  const vehicleInfo = useMemo(
    () => ({
      vehicle,
      setVehicle,
      financeDetail,
      setFinanceDetail
    }),
    [vehicle, setVehicle, financeDetail, setFinanceDetail]
  )
  return <VehicleInformationContext.Provider value={vehicleInfo}>{children}</VehicleInformationContext.Provider>
}

VehicleInformationProvider.propTypes = {
  children: PropTypes.node
}

export function useVehicleInformationContext() {
  return useContext(VehicleInformationContext)
}
