import { InputNumber } from 'antd'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { INITIAL_PRICE, STORAGE_KEYS } from 'src/constants/constants'
import { getFilterList, getMakeModelList } from 'src/services/buy.service'
import { formatZipCode } from 'src/utils'
import { save } from '../../lib/storage'
import './style.scss'

function SearchCar({
  heading = true,
  buy = false,
  bgColor = true,
  makeField,
  setMakeField,
  modelField,
  setModelField,
  price,
  setPrice,
  zipCodeField,
  setZipCodeField
}) {
  const navigate = useNavigate()
  const [makeTypes, setMakeTypes] = useState([])
  const [modelTypes, setModelTypes] = useState([])
  const [minPrice, setMinPrice] = useState()
  const [maxPrice, setMaxPrice] = useState()

  const handleChangeMakeField = (value) => {
    setMakeField(value)
  }

  const handleChangeModelField = (value) => {
    setModelField(value)
  }

  const handleMinPriceChange = (value) => {
    setMinPrice(value)
    setPrice({
      ...price,
      min: value || 0
    })
  }

  const handleMaxPriceChange = (value) => {
    setMaxPrice(value)
    setPrice({
      ...price,
      max: value || 0
    })
  }

  const handleZipCodePriceChange = (value) => {
    setZipCodeField(formatZipCode(value))
  }

  const checkKeyDownIsArrow = (e) => {
    if (e.keyCode === 189) {
      e.preventDefault()
      return false
    }
  }

  const search = () => {
    sessionStorage.setItem(STORAGE_KEYS.DISABLE_CALL_API_GET_ZIP_CODE, 'true')
    const filterOptions = {}
    if (makeField && makeField.length > 0) filterOptions.make = makeField.map((item) => item.value)
    if (modelField && modelField.length > 0) filterOptions.model = modelField.map((item) => item.value)
    if (zipCodeField) filterOptions.zipCode = zipCodeField.toString()
    if (price.min) {
      filterOptions.price = { min: price.min, max: INITIAL_PRICE.max }
    }
    if (price.max) {
      filterOptions.price = { min: INITIAL_PRICE.min, ...filterOptions.price, max: price.max }
    }
    const initialFilterOptions = JSON.stringify(filterOptions)
    if (Object.keys(initialFilterOptions).length > 0) {
      sessionStorage.setItem(STORAGE_KEYS.SHOULD_CALL_SEARCH_VEHICLE, 'true')
    }
    save(STORAGE_KEYS.INITIAL_FILTER_OPTIONS, initialFilterOptions)
    navigate('/buy')
  }

  const handleGetMakeModelList = useCallback((makes) => {
    getMakeModelList(makes.map((item) => item.value))
      .then((response) => {
        const { data } = response
        const modelFilters = Object.keys(data).map((key) => ({
          value: key,
          label: key
        }))
        setModelTypes(modelFilters || [])
      })
      .catch((e) => {
        setModelTypes([])
        console.error(e)
      })
  }, [])

  useEffect(() => {
    getFilterList()
      .then((response) => {
        const makeFilter = response.data?.make?.map((item) => ({
          value: item.make,
          label: item.make
        }))
        setMakeTypes(makeFilter || [])
      })
      .catch((e) => {
        setMakeTypes([])
        console.error(e)
      })
  }, [])

  useEffect(() => {
    if (makeField && makeField.length > 0) {
      handleGetMakeModelList(makeField)
    } else {
      setModelTypes([])
    }
  }, [makeField, handleGetMakeModelList])

  useEffect(() => {
    if (makeField && makeField.maxLength === 0) {
      setModelField([])
    }
  }, [makeField])

  return (
    <div className={`${bgColor && 'search-car-wapper new p-5 pb-8'} ${!buy && 'p-4'} relative z-10 flex-1`}>
      {heading && <p className="search-car-heading-color font-bold text-xl mb-2">Find Your Car</p>}
      <div className="flex home-page-search-box">
        <div className="w-full grid grid-cols-12 gap-2">
          <div className="col-span-6">
            <Select
              name="make"
              isMulti
              allowClear={false}
              placeholder="Make"
              value={makeField}
              onChange={handleChangeMakeField}
              options={makeTypes}
              aria-label="make"
              className="h-min"
            />
          </div>
          <div className="col-span-6">
            <Select
              name="model"
              isMulti
              allowClear={false}
              placeholder="Model"
              value={modelField}
              onChange={handleChangeModelField}
              options={modelTypes}
              aria-label="model"
              className="h-min"
            />
          </div>

          <div className="col-span-4">
            <InputNumber
              name="min"
              onChange={handleMinPriceChange}
              onKeyDown={checkKeyDownIsArrow}
              placeholder="Min Price"
              maxLength={6}
              min={0}
              max={maxPrice}
              value={minPrice}
              className="h-min"
            />
          </div>

          <div className="col-span-4">
            <InputNumber
              name="max"
              onChange={handleMaxPriceChange}
              onKeyDown={checkKeyDownIsArrow}
              placeholder="Max Price"
              className="ml-1 h-min"
              maxLength={6}
              min={minPrice}
              value={maxPrice}
            />
          </div>

          <div className="col-span-4">
            <InputNumber
              name="zipCode"
              value={zipCodeField}
              onChange={handleZipCodePriceChange}
              onKeyDown={checkKeyDownIsArrow}
              placeholder="Zip Code"
              min={0}
              maxLength={5}
              className="h-min"
            />
          </div>

          <div className="col-span-12 flex items-center justify-center">
            <button
              type="button"
              onClick={search}
              className={`home-search-btn bg-blue-500 hover:bg-blue-400 text-white outline-none font-bold py-3 w-72 ${
                buy ? 'hidden md:block ml-2' : ''
              }`}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

SearchCar.defaultProps = {
  heading: true,
  buy: false,
  bgColor: true
}

SearchCar.propTypes = {
  heading: PropTypes.bool,
  buy: PropTypes.bool,
  bgColor: PropTypes.bool,
  makeField: PropTypes.array,
  setMakeField: PropTypes.func,
  modelField: PropTypes.array,
  setModelField: PropTypes.func,
  price: PropTypes.object,
  setPrice: PropTypes.func,
  zipCodeField: PropTypes.any,
  setZipCodeField: PropTypes.func
}

export default SearchCar
