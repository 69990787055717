import React from 'react'
import PropTypes from 'prop-types'
import MetaDecorator from './MetaDecorator'

function VehicleMetaDecorator({ title, description, imageUrl, imageAlt }) {
  let url = imageUrl.replace('http:', '')
  url = url.replace('https:', '')
  url = `https:${url}`

  return <MetaDecorator title={title} description={description} imageUrl={url} imageAlt={imageAlt} />
}

VehicleMetaDecorator.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired
}

export default VehicleMetaDecorator
