export const newPriceTables = {
  isSuccessful: 'Yes',
  message: 'Record(s) fetched successfully',
  data: [
    {
      _id: '62ece640a91a23b13106b662',
      type: 'basic',
      average_vehicles_stock: '<30',
      pricing_table: {
        none: { 'month to Month': 599, 12: 559, 24: 299, 36: 199 },
        member: { 'month to Month': 399, 12: 359, 24: 299, 36: 199 }
      }
    },
    {
      _id: '62ece640a91a23b13106b663',
      type: 'basic',
      average_vehicles_stock: '31-100',
      pricing_table: {
        none: { 'month to Month': 699, 12: 659, 24: 399, 36: 299 },
        member: { 'month to Month': 499, 12: 459, 24: 399, 36: 299 }
      }
    },
    {
      _id: '62ece640a91a23b13106b664',
      type: 'basic',
      average_vehicles_stock: '101-300',
      pricing_table: {
        none: { 'month to Month': 899, 12: 859, 24: 599, 36: 399 },
        member: { setUp: 899, 'month to Month': 699, 12: 659, 24: 599, 36: 399 }
      }
    },
    {
      _id: '62ece640a91a23b13106b665',
      type: 'basic',
      average_vehicles_stock: '300+',
      pricing_table: {
        none: { 'month to Month': 'custom', 12: 'custom', 24: 'custom', 36: 'custom' },
        member: { 'month to Month': 'custom', 12: 'custom', 24: 'custom', 36: 'custom' }
      }
    },
    {
      _id: '62ece640a91a23b13106b666',
      type: 'ILM',
      average_vehicles_stock: '<30',
      pricing_table: {
        none: { setup: 799, 'month to Month': 599, 12: 559, 24: 299, 36: 199 },
        member: { setup: 399, 'month to Month': 399, 12: 359, 24: 299, 36: 199 }
      }
    },
    {
      _id: '62ece640a91a23b13106b667',
      type: 'ILM',
      average_vehicles_stock: '31-100',
      pricing_table: {
        none: { setup: 999, 'month to Month': 699, 12: 659, 24: 399, 36: 299 },
        member: { setup: 499, 'month to Month': 499, 12: 459, 24: 399, 36: 299 }
      }
    },
    {
      _id: '62ece640a91a23b13106b668',
      type: 'ILM',
      average_vehicles_stock: '101-300',
      pricing_table: {
        none: { setup: 1199, 'month to Month': 899, 12: 859, 24: 599, 36: 399 },
        member: { setup: 699, 'month to Month': 699, 12: 659, 24: 599, 36: 399 }
      }
    },
    {
      _id: '62ece640a91a23b13106b669',
      type: 'ILM',
      average_vehicles_stock: '300+',
      pricing_table: {
        none: { 'month to Month': 'custom', 12: 'custom', 24: 'custom', 36: 'custom' },
        member: { 'month to Month': 'custom', 12: 'custom', 24: 'custom', 36: 'custom' }
      }
    },
    {
      _id: '62ece640a91a23b13106b66a',
      type: '3rd Party Lead Integration',
      average_vehicles_stock: '<30',
      pricing_table: { none: { setup: 0, 'month to Month': 399 }, member: { setup: 0, 'month to Month': 199 } }
    },
    {
      _id: '62ece640a91a23b13106b66b',
      type: '3rd Party Lead Integration',
      average_vehicles_stock: '31-100',
      pricing_table: { none: { setup: 0, 'month to Month': 499 }, member: { setup: 0, 'month to Month': 199 } }
    },
    {
      _id: '62ece640a91a23b13106b66c',
      type: '3rd Party Lead Integration',
      average_vehicles_stock: '101-300',
      pricing_table: { none: { setup: 0, 'month to Month': 599 }, member: { setup: 0, 'month to Month': 199 } }
    },
    {
      _id: '62ece640a91a23b13106b66d',
      type: '3rd Party Lead Integration',
      average_vehicles_stock: '300+',
      pricing_table: {
        none: { setup: 0, 'month to Month': 'custom' },
        member: { setup: 0, 'month to Month': 'custom' }
      }
    },
    {
      _id: '62ece640a91a23b13106b66e',
      type: 'SMS Package',
      average_vehicles_stock: '<30',
      pricing_table: { none: { setup: 0, 'month to Month': 399 }, member: { setup: 0, 'month to Month': 199 } }
    },
    {
      _id: '62ece640a91a23b13106b66f',
      type: 'SMS Package',
      average_vehicles_stock: '31-100',
      pricing_table: { none: { setup: 0, 'month to Month': 499 }, member: { setup: 0, 'month to Month': 399 } }
    },
    {
      _id: '62ece640a91a23b13106b670',
      type: 'SMS Package',
      average_vehicles_stock: '101-300',
      pricing_table: { none: { setup: 0, 'month to Month': 599 }, member: { setup: 0, 'month to Month': 499 } }
    },
    {
      _id: '62ece640a91a23b13106b671',
      type: 'SMS Package',
      average_vehicles_stock: '300+',
      pricing_table: {
        none: { setup: 0, 'month to Month': 'custom' },
        member: { setup: 0, 'month to Month': 'custom' }
      }
    },
    {
      _id: '62ece640a91a23b13106b672',
      type: 'Call Package',
      average_vehicles_stock: '<30',
      pricing_table: { none: { setup: 0, 'month to Month': 499 }, member: { setup: 0, 'month to Month': 299 } }
    },
    {
      _id: '62ece640a91a23b13106b673',
      type: 'Call Package',
      average_vehicles_stock: '31-100',
      pricing_table: { none: { setup: 0, 'month to Month': 599 }, member: { setup: 0, 'month to Month': 499 } }
    },
    {
      _id: '62ece640a91a23b13106b674',
      type: 'Call Package',
      average_vehicles_stock: '101-300',
      pricing_table: { none: { setup: 0, 'month to Month': 699 }, member: { setup: 0, 'month to Month': 599 } }
    },
    {
      _id: '62ece640a91a23b13106b675',
      type: 'Call Package',
      average_vehicles_stock: '300+',
      pricing_table: {
        none: { setup: 0, 'month to Month': 'custom' },
        member: { setup: 0, 'month to Month': 'custom' }
      }
    },
    {
      _id: '62ece640a91a23b13106b676',
      type: 'Marketing Automation',
      average_vehicles_stock: '<30',
      pricing_table: {
        none: { setup: 799, 'month to Month': 599, 12: 559, 24: 299, 36: 199 },
        member: { setup: 399, 'month to Month': 499, 12: 359, 24: 299, 36: 199 }
      }
    },
    {
      _id: '62ece640a91a23b13106b677',
      type: 'Marketing Automation',
      average_vehicles_stock: '31-100',
      pricing_table: {
        none: { setup: 999, 'month to Month': 699, 12: 659, 24: 399, 36: 299 },
        member: { setup: 699, 'month to Month': 599, 12: 459, 24: 399, 36: 299 }
      }
    },
    {
      _id: '62ece640a91a23b13106b678',
      type: 'Marketing Automation',
      average_vehicles_stock: '101-300',
      pricing_table: {
        none: { setup: 1199, 'month to Month': 899, 12: 859, 24: 599, 36: 399 },
        member: { setup: 999, 'month to Month': 699, 12: 659, 24: 599, 36: 399 }
      }
    },
    {
      _id: '62ece640a91a23b13106b679',
      type: 'Marketing Automation',
      average_vehicles_stock: '300+',
      pricing_table: {
        none: { setup: 'custom', 'month to Month': 'custom', 12: 'custom', 24: 'custom', 36: 'custom' },
        member: { setup: 'custom', 'month to Month': 'custom', 12: 'custom', 24: 'custom', 36: 'custom' }
      }
    },
    {
      _id: '62ece640a91a23b13106b67a',
      type: 'MMUSA Custom Store',
      average_vehicles_stock: '<30',
      pricing_table: { none: { setup: 0, 'month to Month': 399 }, member: { setup: 0, 'month to Month': 199 } }
    },
    {
      _id: '62ece640a91a23b13106b67b',
      type: 'MMUSA Custom Store',
      average_vehicles_stock: '31-100',
      pricing_table: { none: { setup: 0, 'month to Month': 399 }, member: { setup: 0, 'month to Month': 199 } }
    },
    {
      _id: '62ece640a91a23b13106b67c',
      type: 'MMUSA Custom Store',
      average_vehicles_stock: '101-300',
      pricing_table: { none: { setup: 0, 'month to Month': 399 }, member: { setup: 0, 'month to Month': 199 } }
    },
    {
      _id: '62ece640a91a23b13106b67d',
      type: 'MMUSA Custom Store',
      average_vehicles_stock: '300+',
      pricing_table: { none: { setup: 0, 'month to Month': 399 }, member: { setup: 0, 'month to Month': 199 } }
    },
    {
      _id: '62ece640a91a23b13106b67e',
      type: 'Integrated Custom Web Site',
      average_vehicles_stock: '<30',
      pricing_table: { none: { setup: 0, 'month to Month': 499 }, member: { setup: 0, 'month to Month': 299 } }
    },
    {
      _id: '62ece640a91a23b13106b67f',
      type: 'Integrated Custom Web Site',
      average_vehicles_stock: '31-100',
      pricing_table: { none: { setup: 0, 'month to Month': 699 }, member: { setup: 0, 'month to Month': 499 } }
    },
    {
      _id: '62ece640a91a23b13106b680',
      type: 'Integrated Custom Web Site',
      average_vehicles_stock: '101-300',
      pricing_table: { none: { setup: 0, 'month to Month': 899 }, member: { setup: 0, 'month to Month': 699 } }
    },
    {
      _id: '62ece640a91a23b13106b681',
      type: 'Integrated Custom Web Site',
      average_vehicles_stock: '300+',
      pricing_table: {
        none: { setup: 0, 'month to Month': 'custom' },
        member: { setup: 0, 'month to Month': 'custom' }
      }
    },
    {
      _id: '62ece640a91a23b13106b682',
      type: 'FiMyRide',
      average_vehicles_stock: '<30',
      pricing_table: {
        none: { setup: 0, 'App month to Month': 199, 'Per month to Month': 59 },
        member: { setup: 0, 'App month to Month': 199, 'Per month to Month': 59 }
      }
    },
    {
      _id: '62ece640a91a23b13106b683',
      type: 'FiMyRide',
      average_vehicles_stock: '31-100',
      pricing_table: {
        none: { setup: 0, 'App month to Month': 199, 'Per month to Month': 59 },
        member: { setup: 0, 'App month to Month': 199, 'Per month to Month': 59 }
      }
    },
    {
      _id: '62ece640a91a23b13106b684',
      type: 'FiMyRide',
      average_vehicles_stock: '101-300',
      pricing_table: {
        none: { setup: 0, 'App month to Month': 199, 'Per month to Month': 59 },
        member: { setup: 0, 'App month to Month': 199, 'Per month to Month': 59 }
      }
    },
    {
      _id: '62ece640a91a23b13106b685',
      type: 'FiMyRide',
      average_vehicles_stock: '300+',
      pricing_table: {
        none: { setup: 0, 'App month to Month': 199, 'Per month to Month': 59 },
        member: { setup: 0, 'App month to Month': 199, 'Per month to Month': 59 }
      }
    }
  ]
}
