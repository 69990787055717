import { Button, Modal, Pagination, Table } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import * as CustomerService from 'src/services/customer.service'

function TableOfDealers({
  showModal,
  handleCloseModal,
  handleShareLinkToDealers,
  objectId,
  type = 'trade-in',
  title = 'Share trade-in vehicle to dealer users'
}) {
  const [listOfDealers, setListOfDealers] = useState([])
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const getDealers = useCallback(() => {
    CustomerService.getDealersForSharingLinks({ page, rowsPerPage, status: 'on' })
      .then((response) => {
        setListOfDealers(response.data.data)
        setTotal(response.data.total)
      })
      .catch(() => {
        setListOfDealers([])
        setPage(1)
      })
  }, [page, rowsPerPage])

  useEffect(() => {
    if (!loading) {
      getDealers()
      setLoading(true)
    }
  }, [loading])

  const handleChangePage = (page, rowsPerPage) => {
    setPage(page)
    setRowsPerPage(rowsPerPage)
    setLoading(false)
  }

  const handleChangePageSize = (page, rowsPerPage) => {
    setRowsPerPage(rowsPerPage)
    setLoading(false)
  }

  const columns = [
    {
      title: 'DEALERSHIP',
      dataIndex: 'dealership',
      align: 'left',
      render: (text, record) => <span>{record?.dealer?.dealer_name}</span>
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      align: 'left'
    },
    {
      title: 'FIRST NAME',
      dataIndex: 'first_name'
    },
    {
      title: 'LAST NAME',
      dataIndex: 'last_name'
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      render: (text, record) => (
        <Button
          type="primary"
          onClick={() => handleShareLinkToDealers({ to_user_id: record.id, object_id: objectId, type })}
        >
          Share
        </Button>
      )
    }
  ]

  let modalTitle = title
  if (type === 'credit-application') {
    modalTitle = 'Share credit application to dealers'
  }

  return (
    <Modal
      centered
      visible={showModal}
      title={modalTitle}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      okText="Close"
      className="calendar-modal"
      destroyOnClose
      width="75%"
      footer={[
        <Button type="primary" onClick={handleCloseModal}>
          Close
        </Button>
      ]}
    >
      <div>
        <Table
          scroll={{ y: 500 }}
          pagination={{ position: ['none', 'none'], pageSize: rowsPerPage }}
          columns={columns}
          dataSource={listOfDealers}
        />
        <div className="flex justify-center mb-8 pagination-margin-top-20">
          <div className="flex items-center">
            <Pagination
              className="flex"
              current={page}
              responsive
              total={listOfDealers.length > 0 ? total : 0}
              pageSize={rowsPerPage}
              onChange={handleChangePage}
              onShowSizeChange={handleChangePageSize}
              pageSizeOptions={[10, 20, 30, 40, 50]}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

TableOfDealers.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleShareLinkToDealers: PropTypes.func,
  objectId: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string
}

export default TableOfDealers
