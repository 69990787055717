import { Button, Modal, notification } from 'antd'
import { camelCase, mapKeys } from 'lodash'
import * as PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { BsPrinterFill } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import { STORAGE_KEYS } from 'src/constants/constants'
import { load } from 'src/lib/storage'
import { setCreditApplication } from 'src/services/creditApplicationForm.service'
import AuthPopup from '../BoxButtonGroup/AuthPopup'
import LoginFormModal from '../BoxButtonGroup/LoginFormModal'
import SignUpFormModal from '../BoxButtonGroup/SignUpFormModal'
import AdditionalIncome from './AdditionalIncome'
import ApplicationSection from './ApplicationSection'
import CreditApplicationDetail from './CreditApplicationDetail'
import DriverLicense from './DriverLicense'
import HomeAddress from './HomeAddress'
import JobInformation from './JobInformation'
import References from './References'
import './style.scss'

const residentialStatusOptions = [
  { id: 'own', name: 'Own' },
  { id: 'mortgage', name: 'Mortgage' },
  { id: 'rent', name: 'Rent' }
]

const jobTypeOptions = [
  { id: 'fulltime', name: 'Full time' },
  { id: 'parttime', name: 'Part time' }
]

const convertSnakeCaseToCamelCase = (data) => {
  const result = mapKeys(data, (value, key) => camelCase(key))
  return result
}

function CreditFinance({ creditAppDetail, initialStep = 1, vehicleId }) {
  const { customerAccount, customerLogin } = useAuthorizationContext()
  const [currentStep, setCurrentStep] = useState(initialStep)
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [showSignUpForm, setShowSignUpForm] = useState(false)
  const [showPopupConfirm, setShowPopupConfirm] = useState(false)
  const applicationFormRef = useRef()
  const homeAddressFormRef = useRef()
  const previousHomeAddressRef = useRef()
  const driverLicenseFormRef = useRef()
  const jobInformationFormRef = useRef()
  const previousInformationFormRef = useRef()
  const additionalIncomeFormRef = useRef()
  const referencesFormRef = useRef()
  const navigate = useNavigate()
  const {
    address_section: addressSection,
    previous_address_section: previousAddressSection,
    applicant_section: applicationSection,
    income_section: incomeSection,
    job_section: jobSection,
    previous_job_section: previousJobSection,
    license_section: licenseSection
  } = creditAppDetail

  const [creditAppCompleteDetail, setCreditAppCompleteDetail] = useState(creditAppDetail)

  const [selectedResidentialStatus, setSelectedResidentialStatus] = useState(residentialStatusOptions[1].id)
  const handleChangeResidentialStatus = (event) => {
    const { status } = event.target.dataset
    setSelectedResidentialStatus(status)
  }

  const [preSelectedResidentialStatus, setPreSelectedResidentialStatus] = useState(residentialStatusOptions[1].id)
  const handleChangePreResidentialStatus = (event) => {
    const { status } = event.target.dataset
    setPreSelectedResidentialStatus(status)
  }

  const [jobType, setJobType] = useState(jobTypeOptions[0].id)
  const handleChangeJobType = (event) => {
    const { type } = event.target.dataset
    setJobType(type)
  }

  const [preJobType, setPreJobType] = useState(jobTypeOptions[0].id)
  const handleChangePreJobType = (event) => {
    const { type } = event.target.dataset
    setPreJobType(type)
  }

  useEffect(() => {
    if (customerLogin && applicationFormRef.current && customerAccount.id) {
      applicationFormRef.current.setFieldsValue({
        firstName: customerAccount.first_name,
        lastName: customerAccount.last_name,
        email: customerAccount.email
      })
    }
  }, [customerLogin, customerAccount])

  useEffect(() => {
    if (applicationSection && applicationFormRef.current) {
      const result = convertSnakeCaseToCamelCase(applicationSection)
      applicationFormRef.current.setFieldsValue(result)
    }
  }, [applicationSection])

  useEffect(() => {
    if (addressSection && homeAddressFormRef.current) {
      const result = convertSnakeCaseToCamelCase(addressSection)
      homeAddressFormRef.current.setFieldsValue(result)
    }
  }, [addressSection])

  useEffect(() => {
    if (previousAddressSection && previousHomeAddressRef.current) {
      const result = convertSnakeCaseToCamelCase(previousAddressSection)
      previousHomeAddressRef.current.setFieldsValue(result)
    }
  }, [previousAddressSection])

  useEffect(() => {
    if (incomeSection && additionalIncomeFormRef.current) {
      const result = convertSnakeCaseToCamelCase(incomeSection)
      additionalIncomeFormRef.current.setFieldsValue(result)
    }
  }, [incomeSection])

  useEffect(() => {
    if (jobSection && jobInformationFormRef.current) {
      const result = convertSnakeCaseToCamelCase(jobSection)
      jobInformationFormRef.current.setFieldsValue(result)
    }
  }, [jobSection])

  useEffect(() => {
    if (previousJobSection && previousInformationFormRef.current) {
      const result = convertSnakeCaseToCamelCase(previousJobSection)
      previousInformationFormRef.current.setFieldsValue(result)
    }
  }, [previousJobSection])

  useEffect(() => {
    if (licenseSection && driverLicenseFormRef.current) {
      const result = convertSnakeCaseToCamelCase(licenseSection)
      driverLicenseFormRef.current.setFieldsValue(result)
    }
  }, [licenseSection])

  const openNotification = (type) => {
    const action = type === 'success' ? notification.success : notification.error
    const message =
      type === 'success'
        ? 'Credit Application Form has been added'
        : 'There was error while saving the form. Please try again.'
    action({
      message,
      placement: 'topRight'
    })
  }

  const [showModal, setShowModal] = useState(false)

  const disabledPrevious = currentStep === 1
  const isFinalStep = currentStep === 6

  const nextStep = async () => {
    let formRef = applicationFormRef.current
    switch (currentStep) {
      case 1:
        formRef = applicationFormRef.current
        break
      case 2:
        formRef = homeAddressFormRef.current
        break
      case 3:
        formRef = driverLicenseFormRef.current
        break
      case 4:
        formRef = jobInformationFormRef.current
        break
      case 5:
        formRef = additionalIncomeFormRef.current
        break
      case 6:
        formRef = referencesFormRef.current
        break
      default:
        formRef = applicationFormRef.current
    }

    const selectedValues = {
      selectedResident: selectedResidentialStatus,
      selectedJobType: jobType,
      preSelectedResidentialStatus,
      preJobType
    }

    const data = {
      selectedValues,
      vehicleId,
      appSection: applicationFormRef.current?.getFieldsValue(),
      addressSection: homeAddressFormRef.current?.getFieldsValue(),
      licenseSection: driverLicenseFormRef.current?.getFieldsValue(),
      jobSection: jobInformationFormRef.current?.getFieldsValue(),
      incomeSection: additionalIncomeFormRef.current?.getFieldsValue(),
      previousHomeAddress: previousHomeAddressRef.current?.getFieldsValue(),
      previousJobInformation: previousInformationFormRef.current?.getFieldsValue(),
      references: referencesFormRef.current?.getFieldsValue()?.referenceList,
      step: currentStep
    }

    const userLogin = load(STORAGE_KEYS.CUSTOMER_LOGIN)

    if (userLogin !== null) {
      setCreditApplication(data)
        .then((response) => {
          setCreditAppCompleteDetail(response?.data)

          if (isFinalStep) {
            navigate('/customer-dashboard')
            openNotification('success')
            setShowModal(true)
          }
        })
        .catch(() => {
          openNotification()
        })
    } else {
      setShowPopupConfirm(true)
    }

    const values = await formRef
      .validateFields()
      .then((response) => {
        return response
      })
      .catch((error) => error)
    if (currentStep === 2 && formRef.getFieldValue('yearsAtAddress') < 2) {
      const values = await previousHomeAddressRef.current
        .validateFields()
        .then((response) => response)
        .catch((error) => error)
      if (values.errorFields && values.errorFields.length > 0) return
    }
    if (currentStep === 4 && formRef.getFieldValue('yearsAtJob') < 2) {
      const values = await previousInformationFormRef.current
        .validateFields()
        .then((response) => response)
        .catch((error) => error)
      if (values.errorFields && values.errorFields.length > 0) return
    }
    if (values.errorFields && values.errorFields.length > 0) return

    if (currentStep < 6) {
      setCurrentStep(currentStep + 1)
    }
  }

  const backStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const handleShowLoginForm = () => {
    setShowPopupConfirm(false)
    setShowLoginForm(true)
  }

  const handleShowSignUpForm = () => {
    setShowPopupConfirm(false)
    setShowSignUpForm(true)
  }

  const callbackForForm = () => {}
  const setShowCashDownPopupConfirm = () => {}

  const creditApplicationRef = useRef()
  const handlePrintCrediApplication = useReactToPrint({
    content: () => creditApplicationRef.current
  })

  const handleChangeShowModal = () => {
    setShowModal(!showModal)
  }

  return (
    <div>
      <div className={currentStep === 1 ? 'block' : 'hidden'}>
        <ApplicationSection applicationFormRef={applicationFormRef} />
      </div>
      <div className={currentStep === 2 ? 'block' : 'hidden'}>
        <HomeAddress
          homeAddressFormRef={homeAddressFormRef}
          previousHomeAddressRef={previousHomeAddressRef}
          selectedResidentialStatus={selectedResidentialStatus}
          handleChangeResidentialStatus={handleChangeResidentialStatus}
          preSelectedResidentialStatus={preSelectedResidentialStatus}
          handleChangePreResidentialStatus={handleChangePreResidentialStatus}
        />
      </div>
      <div className={currentStep === 3 ? 'block' : 'hidden'}>
        <DriverLicense driverLicenseFormRef={driverLicenseFormRef} />
      </div>
      <div className={currentStep === 4 ? 'block' : 'hidden'}>
        <JobInformation
          jobInformationFormRef={jobInformationFormRef}
          previousInformationFormRef={previousInformationFormRef}
          jobType={jobType}
          handleChangeJobType={handleChangeJobType}
          preJobType={preJobType}
          handleChangePreJobType={handleChangePreJobType}
        />
      </div>
      <div className={currentStep === 5 ? 'block' : 'hidden'}>
        <AdditionalIncome additionalIncomeFormRef={additionalIncomeFormRef} />
      </div>
      <div className={currentStep === 6 ? 'block' : 'hidden'}>
        <References referencesFormRef={referencesFormRef} />
      </div>
      <div className="flex justify-between gap-3 mt-12 pb-8">
        <button
          type="button"
          className="bg-primary rounded text-white px-10 py-2 hover:bg-activePrimary"
          onClick={backStep}
          disabled={disabledPrevious}
        >
          Back
        </button>
        <button
          type="button"
          className="bg-primary rounded text-white px-10 py-2 hover:bg-activePrimary"
          onClick={nextStep}
        >
          {isFinalStep ? 'Submit' : 'Next'}
        </button>
      </div>

      <Modal
        centered
        visible={showModal}
        onOk={handleChangeShowModal}
        onCancel={handleChangeShowModal}
        okText="Close"
        className="dealer-custom-deals-modal"
        destroyOnClose
        width="70%"
        footer={[
          <div className="flex justify-end">
            <Button
              className="flex gap-2 justify-center items-center font-semibold text-md rounded-lg p-4"
              icon={<BsPrinterFill className="text-md" />}
              type="primary"
              onClick={handlePrintCrediApplication}
            >
              Print
            </Button>
          </div>
        ]}
      >
        <CreditApplicationDetail
          creditApplicationData={creditAppCompleteDetail}
          creditApplicationRef={creditApplicationRef}
        />
      </Modal>

      <AuthPopup
        show={showPopupConfirm}
        setShow={setShowPopupConfirm}
        handleShowLoginForm={handleShowLoginForm}
        handleShowSignUpForm={handleShowSignUpForm}
        closable={false}
        maskClosable={false}
      />

      <LoginFormModal
        showModal={showLoginForm}
        setShowModal={setShowLoginForm}
        callback={callbackForForm}
        setShowCashDownPopupConfirm={setShowCashDownPopupConfirm}
      />
      <SignUpFormModal
        showModal={showSignUpForm}
        setShowModal={setShowSignUpForm}
        callback={callbackForForm}
        setShowCashDownPopupConfirm={setShowCashDownPopupConfirm}
      />
    </div>
  )
}

CreditFinance.propTypes = {
  creditAppDetail: PropTypes.object,
  initialStep: PropTypes.number,
  vehicleId: PropTypes.string
}

export default CreditFinance
