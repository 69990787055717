import React, { useEffect, useState } from 'react'
import { Form, Table, Input, notification } from 'antd'
import * as PropTypes from 'prop-types'
import * as DealerService from '../../../services/dealer.service'
import './style.scss'

function RateAdjustmentForScoreAndAgeOfVehicle({ dealerId, canManagePaymentSettings }) {
  const [dataYears, setDataYears] = useState([])
  const [dataRates, setDataRates] = useState([])
  const [form] = Form.useForm()

  const handleChangeYears = (index, name, event) => {
    const value = event.target.value
    const rowsInput = [...dataYears]
    rowsInput[index][name].value = value
    rowsInput[index][name].label = value
    setDataYears(rowsInput)
  }

  const renderInputYear = (index, column, name) => {
    return (
      <Form.Item
        onChange={(event) => handleChangeYears(index, name, event)}
        name={`year_${name}_${index}`}
        key={`year_${name}_${index}`}
        initialValue={column.value}
        rules={[{ required: true, message: 'Year is required' }]}
      >
        <Input type="number" name={`year_${name}_${index}`} placeholder={column.value} prefix="-" />
      </Form.Item>
    )
  }

  const renderLabelYear = (column) => {
    let columnValue = column.label
    if (column.value > 0) {
      columnValue = `-${column.label}`
    }
    return <div className="text-center">{`${columnValue}`}</div>
  }

  const columnsYears = [
    {
      align: 'left',
      title: 'Age Of Vehicle',
      children: [
        {
          className: 'title-hidden',
          width: 80,
          title: 'Range From',
          dataIndex: 'range_from',
          key: 'year_range_from',
          render: (column) => <div className="text-center">{`${column.label}`}</div>
        },
        {
          className: 'title-hidden',
          width: 80,
          title: 'Range To',
          dataIndex: 'range_to',
          key: 'year_range_to',
          render: (column) => <div className="text-center">{`${column.label}`}</div>
        },
        {
          className: 'title-hidden',
          width: 80,
          title: 'column_0',
          dataIndex: 'column_0',
          key: 'year_column_0',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputYear(index, column, 'column_0')
            }
            if (!canManagePaymentSettings) {
              return renderLabelYear(column)
            }
          }
        },
        {
          className: 'title-hidden',
          width: 80,
          title: 'column_1',
          dataIndex: 'column_1',
          key: 'year_column_1',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputYear(index, column, 'column_1')
            }
            if (!canManagePaymentSettings) {
              return renderLabelYear(column)
            }
          }
        },
        {
          className: 'title-hidden',
          width: 80,
          title: 'column_2',
          dataIndex: 'column_2',
          key: 'year_column_2',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputYear(index, column, 'column_2')
            }
            if (!canManagePaymentSettings) {
              return renderLabelYear(column)
            }
          }
        },
        {
          className: 'title-hidden',
          width: 80,
          title: 'column_3',
          dataIndex: 'column_3',
          key: 'year_column_3',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputYear(index, column, 'column_3')
            }
            if (!canManagePaymentSettings) {
              return renderLabelYear(column)
            }
          }
        },
        {
          className: 'title-hidden',
          width: 80,
          title: 'column_4',
          dataIndex: 'column_4',
          key: 'year_column_4',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputYear(index, column, 'column_4')
            }
            if (!canManagePaymentSettings) {
              return renderLabelYear(column)
            }
          }
        },
        {
          className: 'title-hidden',
          width: 80,
          title: 'column_5',
          dataIndex: 'column_5',
          key: 'year_column_5',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputYear(index, column, 'column_5')
            }
            if (!canManagePaymentSettings) {
              return renderLabelYear(column)
            }
          }
        },
        {
          className: 'title-hidden',
          width: 80,
          title: 'column_6',
          dataIndex: 'column_6',
          key: 'year_column_6',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputYear(index, column, 'column_6')
            }
            if (!canManagePaymentSettings) {
              return renderLabelYear(column)
            }
          }
        },
        {
          className: 'title-hidden',
          width: 80,
          title: 'column_7',
          dataIndex: 'column_7',
          key: 'year_column_7',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputYear(index, column, 'column_7')
            }
            if (!canManagePaymentSettings) {
              return renderLabelYear(column)
            }
          }
        },
        {
          className: 'title-hidden',
          width: 80,
          title: 'column_8',
          dataIndex: 'column_8',
          key: 'year_column_8',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputYear(index, column, 'column_8')
            }
            if (!canManagePaymentSettings) {
              return renderLabelYear(column)
            }
          }
        }
      ]
    }
  ]

  const handleChangeRangesRates = (index, name, event) => {
    const value = event.target.value
    const rowsInput = [...dataRates]
    rowsInput[index][name].value = value
    rowsInput[index][name].label = value
    setDataRates(rowsInput)
  }

  const renderInputRateRange = (index, column, name) => {
    return (
      <Form.Item
        onChange={(event) => handleChangeRangesRates(index, name, event)}
        name={`range_${name}_${index}`}
        key={`range_${name}_${index}`}
        initialValue={column.label}
        rules={[{ required: true, message: 'Range is required' }]}
      >
        <Input type="number" name={`range_${name}_${index}`} placeholder={column.label} />
      </Form.Item>
    )
  }

  const renderInputRateValue = (index, column, name) => {
    return (
      <Form.Item
        onChange={(event) => handleChangeRangesRates(index, name, event)}
        name={`rate_${name}_${index}`}
        key={`rate_${name}_${index}`}
        initialValue={column.label}
        rules={[{ required: true, message: 'Rate is required' }]}
      >
        <Input type="text" name={`rate_${name}_${index}`} placeholder={column.label} />
      </Form.Item>
    )
  }

  const renderLabelRate = (column) => {
    return <div className="text-center">{`${column.label}`}</div>
  }

  const columnsRates = [
    {
      align: 'left',
      title: 'Credit Score',
      children: [
        {
          width: 80,
          title: 'Range From',
          dataIndex: 'range_from',
          key: 'rate_range_from',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputRateRange(index, column, 'range_from')
            }
            if (!canManagePaymentSettings) {
              return renderLabelRate(column)
            }
          }
        },
        {
          width: 80,
          title: 'Range To',
          dataIndex: 'range_to',
          key: 'rate_range_to',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputRateRange(index, column, 'range_to')
            }
            if (!canManagePaymentSettings) {
              return renderLabelRate(column)
            }
          }
        },
        {
          className: 'title-hidden',
          width: 80,
          title: 'column_0',
          dataIndex: 'column_0',
          key: 'rate_column_0',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputRateValue(index, column, 'column_0')
            }
            if (!canManagePaymentSettings) {
              return renderLabelRate(column)
            }
          }
        },
        {
          className: 'title-hidden',
          width: 80,
          title: 'column_1',
          dataIndex: 'column_1',
          key: 'rate_column_1',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputRateValue(index, column, 'column_1')
            }
            if (!canManagePaymentSettings) {
              return renderLabelRate(column)
            }
          }
        },
        {
          className: 'title-hidden',
          width: 80,
          title: 'column_2',
          dataIndex: 'column_2',
          key: 'rate_column_2',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputRateValue(index, column, 'column_2')
            }
            if (!canManagePaymentSettings) {
              return renderLabelRate(column)
            }
          }
        },
        {
          className: 'title-hidden',
          width: 80,
          title: 'column_3',
          dataIndex: 'column_3',
          key: 'rate_column_3',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputRateValue(index, column, 'column_3')
            }
            if (!canManagePaymentSettings) {
              return renderLabelRate(column)
            }
          }
        },
        {
          className: 'title-hidden',
          width: 80,
          title: 'column_4',
          dataIndex: 'column_4',
          key: 'rate_column_4',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputRateValue(index, column, 'column_4')
            }
            if (!canManagePaymentSettings) {
              return renderLabelRate(column)
            }
          }
        },
        {
          className: 'title-hidden',
          width: 80,
          title: 'column_5',
          dataIndex: 'column_5',
          key: 'rate_column_5',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputRateValue(index, column, 'column_5')
            }
            if (!canManagePaymentSettings) {
              return renderLabelRate(column)
            }
          }
        },
        {
          className: 'title-hidden',
          width: 80,
          title: 'column_6',
          dataIndex: 'column_6',
          key: 'rate_column_6',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputRateValue(index, column, 'column_6')
            }
            if (!canManagePaymentSettings) {
              return renderLabelRate(column)
            }
          }
        },
        {
          className: 'title-hidden',
          width: 80,
          title: 'column_7',
          dataIndex: 'column_7',
          key: 'rate_column_7',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputRateValue(index, column, 'column_7')
            }
            if (!canManagePaymentSettings) {
              return renderLabelRate(column)
            }
          }
        },
        {
          className: 'title-hidden',
          width: 80,
          title: 'column_8',
          dataIndex: 'column_8',
          key: 'rate_column_8',
          render: (column, data, index) => {
            if (canManagePaymentSettings) {
              return renderInputRateValue(index, column, 'column_8')
            }
            if (!canManagePaymentSettings) {
              return renderLabelRate(column)
            }
          }
        }
      ]
    }
  ]

  useEffect(() => {
    DealerService.getCreditScoreVehicleAgeRatesAndYears(dealerId)
      .then((response) => {
        setDataYears(response.data.years)
        setDataRates(response.data.rates)
      })
      .catch((error) => {
        setDataYears([])
        setDataRates([])
        console.error(error)
      })
  }, [])

  const handleFormSubmission = async () => {
    const params = {}
    params.years = dataYears
    params.rates = dataRates
    params.dealer_id = dealerId

    DealerService.creditScoreVehicleAgeRatesAndYears(params)
      .then((response) => {
        setDataYears(response.data.years)
        setDataRates(response.data.rates)
        notification.success({
          message: 'Success!',
          description: 'Updated successful.'
        })
      })
      .catch((error) => {
        notification.error({
          message: 'Error!',
          description: 'Updated failed.'
        })
        console.error(error)
      })
  }

  return (
    <Form form={form} onFinish={handleFormSubmission}>
      <div className="ant-table-content">
        <Form.Item className="flex items-end w-full mt-0 submit" shouldUpdate>
          <div>
            <Table
              columns={columnsYears}
              className="payment-settings-table-credit-score-vehicle-age-years"
              dataSource={dataYears}
              scroll={{ x: 'max-content' }}
              pagination={{ position: ['none', 'none'] }}
            />
            <Table
              columns={columnsRates}
              className="payment-settings-table-credit-score-vehicle-age-rates"
              dataSource={dataRates}
              scroll={{ x: 'max-content' }}
              pagination={{ position: ['none', 'none'] }}
            />
          </div>
          {canManagePaymentSettings && (
            <div className="mt-5">
              <button
                type="submit"
                className="bg-primary hover:bg-activePrimary font-semibold rounded-lg px-8 py-2 outline-none text-lg w-fit text-white flex justify-center"
              >
                Save
              </button>
            </div>
          )}
        </Form.Item>
      </div>
    </Form>
  )
}

RateAdjustmentForScoreAndAgeOfVehicle.propTypes = {
  dealerId: PropTypes.number,
  canManagePaymentSettings: PropTypes.bool
}

export default RateAdjustmentForScoreAndAgeOfVehicle
