import { Button, Modal, Spin } from 'antd'
import * as PropTypes from 'prop-types'
import React from 'react'

function CreditApplicationDetail({
  showModal,
  handleCloseModal,
  modalLoading,
  creditApplicationData,
  handleChangeShowModalListOfDealers,
  handleChangeSharedlinks
}) {
  return (
    <Modal
      centered
      visible={showModal}
      title="Credit Application"
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      okText="Close"
      className="calendar-modal max-w-[1200px]"
      destroyOnClose
      width="90%"
      footer={[
        <Button type="primary" onClick={handleChangeShowModalListOfDealers}>
          Share to dealers
        </Button>,
        <Button type="primary" onClick={handleChangeSharedlinks}>
          Shared links
        </Button>,
        <Button type="primary" onClick={handleCloseModal}>
          Close
        </Button>
      ]}
    >
      {modalLoading ? (
        <Spin tip="Loading..." className="w-full spiner" size="large" />
      ) : (
        <div className="section-group">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {creditApplicationData?.applicant_section ? (
              <div className="">
                <div className="group  mb-2">
                  <h2 className="text-xl">Applicant Information</h2>
                  <div className="">
                    <div className="flex gap-x-1">
                      <b>First Name:</b>
                      <p>{creditApplicationData?.applicant_section?.first_name}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Middle Name:</b>
                      <p>{creditApplicationData?.applicant_section?.middle_name}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Last Name:</b>
                      <p>{creditApplicationData?.applicant_section?.last_name}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Email:</b>
                      <p>{creditApplicationData?.applicant_section?.email}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Date of Birth:</b>
                      <p>{creditApplicationData?.applicant_section?.date_of_birth}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Social Security Number:</b>
                      <p>{creditApplicationData?.applicant_section?.social_security_number}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Mobile Number:</b>
                      <p>{creditApplicationData?.applicant_section?.mobile_number}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Marital Status:</b>
                      <p>{creditApplicationData?.applicant_section?.marital_status}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Citizenship Status:</b>
                      <p>{creditApplicationData?.applicant_section?.citizenship_status}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {creditApplicationData?.address_section ? (
              <div className="">
                <div className="group  mb-2">
                  <h2 className="text-xl">Address Information</h2>
                  <div className="">
                    <div className="flex gap-x-1">
                      <b>Street Address:</b>
                      <p>{creditApplicationData?.address_section?.street_address}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Zip Code:</b>
                      <p>{creditApplicationData?.address_section?.zip_code}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>City:</b>
                      <p>{creditApplicationData?.address_section?.city}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>State:</b>
                      <p>{creditApplicationData?.address_section?.state}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>County:</b>
                      <p>{creditApplicationData?.address_section?.county}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Years At Address:</b>
                      <p>{creditApplicationData?.address_section?.years_at_address}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Months At Address:</b>
                      <p>{creditApplicationData?.address_section?.months_at_address}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Residential Status:</b>
                      <p>{creditApplicationData?.address_section?.residential_status}</p>
                    </div>
                    {creditApplicationData?.address_section?.mortgage_lender_name && (
                      <div className="flex gap-x-1">
                        <b>Mortgage Lender Name:</b>
                        <p>{creditApplicationData?.address_section?.mortgage_lender_name}</p>
                      </div>
                    )}
                    {creditApplicationData?.address_section?.monthly_mortgage && (
                      <div className="flex gap-x-1">
                        <b>Monthly Mortgage:</b>
                        <p>{creditApplicationData?.address_section?.monthly_mortgage}</p>
                      </div>
                    )}
                    {creditApplicationData?.address_section?.landlord_name && (
                      <div className="flex gap-x-1">
                        <b>Landlord Name:</b>
                        <p>{creditApplicationData?.address_section?.landlord_name}</p>
                      </div>
                    )}
                    {creditApplicationData?.address_section?.monthly_rent && (
                      <div className="flex gap-x-1">
                        <b>Monthly Rent:</b>
                        <p>{creditApplicationData?.address_section?.monthly_rent}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null}

            {creditApplicationData?.previous_address_section ? (
              <div className="">
                <div className="group  mb-2">
                  <h2 className="text-xl">Previous Address Information</h2>
                  <div className="">
                    <div className="flex gap-x-1">
                      <b>Street Address:</b>
                      <p>{creditApplicationData?.previous_address_section?.street_address}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Zip Code:</b>
                      <p>{creditApplicationData?.previous_address_section?.zip_code}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>City:</b>
                      <p>{creditApplicationData?.previous_address_section?.city}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>State:</b>
                      <p>{creditApplicationData?.previous_address_section?.state}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>County:</b>
                      <p>{creditApplicationData?.previous_address_section?.county}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Years At Address:</b>
                      <p>{creditApplicationData?.previous_address_section?.years_at_address}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Months At Address:</b>
                      <p>{creditApplicationData?.previous_address_section?.months_at_address}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Residential Status:</b>
                      <p>{creditApplicationData?.previous_address_section?.residential_status}</p>
                    </div>
                    {creditApplicationData?.previous_address_section?.mortgage_lender_name && (
                      <div className="flex gap-x-1">
                        <b>Mortgage Lender Name:</b>
                        <p>{creditApplicationData?.previous_address_section?.mortgage_lender_name}</p>
                      </div>
                    )}
                    {creditApplicationData?.previous_address_section?.monthly_mortgage && (
                      <div className="flex gap-x-1">
                        <b>Monthly Mortgage:</b>
                        <p>{creditApplicationData?.previous_address_section?.monthly_mortgage}</p>
                      </div>
                    )}
                    {creditApplicationData?.previous_address_section?.landlord_name && (
                      <div className="flex gap-x-1">
                        <b>Landlord Name:</b>
                        <p>{creditApplicationData?.previous_address_section?.landlord_name}</p>
                      </div>
                    )}
                    {creditApplicationData?.previous_address_section?.monthly_rent && (
                      <div className="flex gap-x-1">
                        <b>Monthly Rent:</b>
                        <p>{creditApplicationData?.previous_address_section?.monthly_rent}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null}

            {creditApplicationData?.job_section ? (
              <div className="">
                <div className="group  mb-2">
                  <h2 className="text-xl">Job Information</h2>
                  <div className="">
                    <div className="flex gap-x-1">
                      <b>Employer Name:</b>
                      <p>{creditApplicationData?.job_section?.employer_name}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Job Status:</b>
                      <p>{creditApplicationData?.job_section?.job_status}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Annual Gross Income:</b>
                      <p>{creditApplicationData?.job_section?.annual_gross_income}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Annual Net Income:</b>
                      <p>{creditApplicationData?.job_section?.annual_net_income}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Work Phone:</b>
                      <p>{creditApplicationData?.job_section?.work_phone}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Job Title:</b>
                      <p>{creditApplicationData?.job_section?.job_title}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Street Address:</b>
                      <p>{creditApplicationData?.job_section?.street_address}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Zip Code:</b>
                      <p>{creditApplicationData?.job_section?.zip_code}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>City:</b>
                      <p>{creditApplicationData?.job_section?.city}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>State:</b>
                      <p>{creditApplicationData?.job_section?.state}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>County:</b>
                      <p>{creditApplicationData?.job_section?.county}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Years At Job:</b>
                      <p>{creditApplicationData?.job_section?.years_at_job}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Months At Job:</b>
                      <p>{creditApplicationData?.job_section?.months_at_job}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Job Type:</b>
                      <p>{creditApplicationData?.job_section?.job_type}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {creditApplicationData?.previous_job_section ? (
              <div className="">
                <div className="group  mb-2">
                  <h2 className="text-xl">Previous Job Information</h2>
                  <div className="">
                    <div className="flex gap-x-1">
                      <b>Employer Name:</b>
                      <p>{creditApplicationData?.previous_job_section?.employer_name}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Job Status:</b>
                      <p>{creditApplicationData?.previous_job_section?.job_status}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Annual Gross Income:</b>
                      <p>{creditApplicationData?.previous_job_section?.annual_gross_income}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Annual Net Income:</b>
                      <p>{creditApplicationData?.previous_job_section?.annual_net_income}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Work Phone:</b>
                      <p>{creditApplicationData?.previous_job_section?.work_phone}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Job Title:</b>
                      <p>{creditApplicationData?.previous_job_section?.job_title}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Street Address:</b>
                      <p>{creditApplicationData?.previous_job_section?.street_address}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Zip Code:</b>
                      <p>{creditApplicationData?.previous_job_section?.zip_code}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>City:</b>
                      <p>{creditApplicationData?.previous_job_section?.city}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>State:</b>
                      <p>{creditApplicationData?.previous_job_section?.state}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>County:</b>
                      <p>{creditApplicationData?.previous_job_section?.county}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Years At Job:</b>
                      <p>{creditApplicationData?.previous_job_section?.years_at_job}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Months At Job:</b>
                      <p>{creditApplicationData?.previous_job_section?.months_at_job}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Job Type:</b>
                      <p>{creditApplicationData?.previous_job_section?.job_type}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {creditApplicationData && creditApplicationData?.license_section ? (
              <div className="">
                <div className="group  mb-2">
                  <h2 className="text-xl">License Information</h2>
                  <div className="">
                    <div className="flex gap-x-1">
                      <b>License Number:</b>
                      <p>{creditApplicationData?.license_section?.license_number}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>License State:</b>
                      <p>{creditApplicationData?.license_section?.license_state}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>License Issued Date:</b>
                      <p>{creditApplicationData?.license_section?.license_issued_date}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>License Expiry Date:</b>
                      <p>{creditApplicationData?.license_section?.license_expiry_date}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {creditApplicationData && creditApplicationData?.income_section ? (
              <div className="">
                <div className="group  mb-2">
                  <h2 className="text-xl">Income Information</h2>
                  <div className="">
                    <div className="flex gap-x-1">
                      <b>Additional Annual Income:</b>
                      <p>{creditApplicationData?.income_section?.additional_annual_income}</p>
                    </div>
                    <div className="flex gap-x-1">
                      <b>Additional Income Source:</b>
                      <p>{creditApplicationData?.income_section?.additional_income_source}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </Modal>
  )
}

CreditApplicationDetail.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  modalLoading: PropTypes.bool,
  creditApplicationData: PropTypes.object,
  handleChangeShowModalListOfDealers: PropTypes.func,
  handleChangeSharedlinks: PropTypes.func
}

export default CreditApplicationDetail
