import { Button, Modal, Table } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AiOutlineRight } from 'react-icons/ai'
import { BsPrinterFill } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import * as dealerService from '../../../services/dealer.service'
import CreditApplicationModal from './CreditApplicationModal'
import './style.scss'

const columns = (onSelectOffer) => [
  {
    title: 'DATE & TIME',
    dataIndex: 'dateTime',
    sorter: true
  },
  {
    title: 'CUSTOMER',
    dataIndex: 'customer',
    sorter: true
  },
  {
    title: 'LEAD EMAIL',
    dataIndex: 'email'
  },
  {
    title: 'VEHICLE',
    dataIndex: 'vehicle',
    sorter: true
  },
  {
    title: 'SALE PRICE',
    dataIndex: 'price',
    render: (text, record, index) => (
      <div key={index} className="flex gap-4 items-center justify-between">
        <span>${record.price?.toLocaleString() || 0}</span>
        <Button
          icon={<AiOutlineRight />}
          size="large"
          type="text"
          disabled={record.record_type === 'lead'}
          onClick={() => onSelectOffer(record.key, record.record_type)}
          className="hover:bg-primary hover:text-white flex items-center justify-center rounded-xl"
        />
      </div>
    )
  }
]

const getParams = (params) => ({
  results: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params
})

export function DealerCustomDeals({ editPage, vehicleId, dealershipAccount }) {
  const [ezDealsAnalytics, setEzDealsAnalytics] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedOffer, setSelectedOffer] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10
  })
  const navigate = useNavigate()
  const {
    customer,
    payment,
    tradeins,
    vehicle,
    date_time: dateTime,
    sale_price: salePrice,
    vehicle_name: vehicleName,
    credit_app: creditApplicationData
  } = selectedOffer

  const canAssignLeads = undefined !== dealershipAccount ? dealershipAccount?.permissions?.assign_leads : true
  const handleNavigateMySales = () => {
    navigate('/dealer-sales')
  }

  const fetchData = (params = {}) => {
    const allParams = {
      ...params,
      employee_id: !dealershipAccount?.permissions?.assign_leads ? dealershipAccount?.employee_id : ''
    }

    if (vehicleId) {
      setLoading(true)
      dealerService
        .getOffersByVehicle(vehicleId, getParams(allParams))
        .then((result) => {
          setEzDealsAnalytics(result.data.records)
          setLoading(false)
          setPagination({
            ...allParams.pagination,
            total: result.data.count
          })
        })
        .finally(() => setLoading(false))
    } else if (dealershipAccount?.dealer_id) {
      setLoading(true)
      dealerService
        .getOffersByDealer(dealershipAccount?.dealer_id, getParams(allParams))
        .then((result) => {
          setEzDealsAnalytics(result.data.records)
          setLoading(false)
          setPagination({
            ...allParams.pagination,
            total: result.data.count
          })
        })
        .finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    fetchData({
      pagination
    })
  }, [dealershipAccount, vehicleId])

  const handleTableChange = (newPagination, filters, sorter) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: newPagination,
      ...filters
    })
  }

  const handleChangeShowModal = () => {
    setShowModal(!showModal)
  }

  const handleGetSelectedOffer = useCallback(
    (id, type) => {
      if (!loading) {
        setLoading(true)
        dealerService
          .getDetails(id, type)
          .then((response) => {
            setSelectedOffer(response.data)
            setShowModal(true)
          })
          .catch((error) => console.error(error))
          .finally(() => setLoading(false))
      }
    },
    [loading]
  )

  const tableColumns = useMemo(() => {
    return columns(handleGetSelectedOffer)
  }, [handleGetSelectedOffer])

  const redirectVehiclePage = () => {
    navigate(`/dealer-vehicles/${vehicle.id}`)
  }

  const dealDetailRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => dealDetailRef.current
  })

  const [newModal, setNewModal] = useState(false)

  const handleShowCreditApplicationDetail = () => {
    if (canAssignLeads) {
      setNewModal(true)
    }
  }
  const creditApplicaitonRef = useRef()
  const handlePrintCrediApplication = useReactToPrint({
    content: () => creditApplicaitonRef.current
  })

  return (
    <>
      <div className="grid grid-cols-1 xl:flex xl:justify-between lg:flex lg:justify-between md:grid-cols-2 sm:grid-cols-1 gap-3">
        <div className="font-semibold text-xl">Custom Deals</div>
        <div className="grid grid-cols-3 gap-5 items-center">
          {!editPage ? (
            <div className="text-primary cursor-pointer font-bold" onClick={handleNavigateMySales}>
              All sales &nbsp; &#62;
            </div>
          ) : null}
        </div>
      </div>
      <Table
        className="custom-deal"
        pagination={pagination}
        columns={tableColumns}
        dataSource={ezDealsAnalytics}
        scroll={{ x: 800 }}
        loading={loading}
        onChange={handleTableChange}
      />
      <Modal
        centered
        visible={showModal}
        onOk={handleChangeShowModal}
        onCancel={handleChangeShowModal}
        closable={false}
        okText="Close"
        className="dealer-custom-deals-modal"
        destroyOnClose
        width="80%"
        height="90%"
        footer={[
          <div className="flex justify-end">
            <Button
              className="flex gap-2 justify-center items-center font-semibold text-lg rounded-lg p-4"
              icon={<BsPrinterFill className="text-xl" />}
              type="primary"
              onClick={newModal ? handlePrintCrediApplication : handlePrint}
            >
              Print
            </Button>
          </div>
        ]}
      >
        {!newModal ? (
          <div ref={dealDetailRef}>
            {/* header */}
            <div
              className="px-5 lg:px-10 pt-5 pb-3 grid grid-cols-5 gap-3 lg:gap-5 rounded-3xl"
              style={{ background: '#F6F9FD' }}
            >
              <div className="font-bold text-xl">Deal details</div>
              <div className="col-span-2">
                <div className="text-lightGray text-sm">Sold vehicle</div>
                <div className="text-base">{vehicleName}</div>
              </div>
              <div>
                <div className="text-lightGray text-sm">Date & time</div>
                <div className="text-base">{dateTime}</div>
              </div>
              <div>
                <div className="text-lightGray text-sm">Sale price</div>
                <div className="text-base">${salePrice?.toLocaleString() || 0}</div>
              </div>
            </div>
            {/* customer */}
            <div className="px-5 lg:px-10 pt-5 pb-3 grid grid-cols-5 gap-3 lg:gap-5">
              <div className="text-lightGray text-base uppercase">Customer</div>
              <div className="col-span-4 flex gap-3 lg:gap-5 justify-between">
                <div>
                  <div className="text-lightGray text-sm">Name</div>
                  <div className="text-base">{customer?.name || ''}</div>
                </div>
                <div>
                  <div className="text-lightGray text-sm">Email</div>
                  <div className="text-base">{customer?.email || ''}</div>
                </div>
                <div>
                  <div className="text-lightGray text-sm">Address</div>
                  <div className="text-base">{customer?.address || ''}</div>
                </div>
                <div>
                  <div className="text-lightGray text-sm">Phone</div>
                  <div className="text-base">{customer?.phone || ''}</div>
                </div>
                <div>
                  <div className="text-lightGray text-sm">Score</div>
                  <div className="text-base">{customer?.score || 0}</div>
                </div>
              </div>
            </div>
            {/* Vehicle info */}
            <div className="px-5 lg:px-10 pt-5 pb-3 grid grid-cols-5 gap-3 lg:gap-5">
              <div className="text-lightGray text-base uppercase">Vehicle info</div>
              <div className="col-span-4 flex flex-col gap-5">
                <div className="flex gap-3 lg:gap-5 justify-between">
                  <div className="flex gap-5">
                    <div>
                      <div className="text-lightGray text-sm">Year</div>
                      <div className="text-base">{vehicle?.year || ''}</div>
                    </div>
                    <div>
                      <div className="text-lightGray text-sm">Make</div>
                      <div className="text-base">{vehicle?.make || ''}</div>
                    </div>
                    <div>
                      <div className="text-lightGray text-sm">Model</div>
                      <div className="text-base">{vehicle?.model || ''}</div>
                    </div>
                  </div>
                  <div>
                    <div className="text-lightGray text-sm">Trim</div>
                    <div className="text-base">{vehicle?.trim || ''}</div>
                  </div>
                  <div>
                    <div className="text-lightGray text-sm">Engine</div>
                    <div className="text-base">{vehicle?.engine || ''}</div>
                  </div>
                  <div>
                    <div className="text-lightGray text-sm">Fuel</div>
                    <div className="text-base">{vehicle?.fuel || ''}</div>
                  </div>
                </div>
                <div className="flex gap-3 lg:gap-5 justify-between">
                  <div className="flex gap-5">
                    <div>
                      <div className="text-lightGray text-sm">Mileage</div>
                      <div className="text-base">{vehicle?.mileage || ''}</div>
                    </div>
                    <div>
                      <div className="text-lightGray text-sm">Transmission</div>
                      <div className="text-base">{vehicle?.transmission || ''}</div>
                    </div>
                    <div>
                      <div className="text-lightGray text-sm">Ext.color</div>
                      <div className="text-base">{vehicle?.ext_color || ''}</div>
                    </div>
                  </div>
                  <div>
                    <div className="text-lightGray text-sm">Int.color</div>
                    <div className="text-base">{vehicle?.int_color || ''}</div>
                  </div>
                  <div>
                    <div className="text-lightGray text-sm">VIN</div>
                    <div className="text-base">{vehicle?.vin || ''}</div>
                  </div>
                  <div>
                    <div className="text-lightGray text-sm">Stock#</div>
                    <div className="text-base">{vehicle?.stock || ''}</div>
                  </div>
                </div>
                <div
                  className="text-primary font-medium cursor-pointer flex items-center"
                  onClick={redirectVehiclePage}
                >
                  {'Vehicle page >'}
                </div>
              </div>
            </div>
            {/* offer details */}
            <div className="px-5 lg:px-10 pt-5 pb-3 grid grid-cols-5 gap-3 lg:gap-5">
              <div className="text-lightGray text-base uppercase">Offer details</div>
              <div className="col-span-4 flex gap-3 lg:gap-5 justify-between">
                <div>
                  <div className="text-lightGray text-sm">Retail price</div>
                  <div className="text-base">$18,888</div>
                </div>
                <div>
                  <div className="text-lightGray text-sm">Saving</div>
                  <div className="text-base">$1,000</div>
                </div>
                <div>
                  <div className="text-lightGray text-sm">A.D.M</div>
                  <div className="text-base">$1,393</div>
                </div>
                <div>
                  <div className="text-lightGray text-sm">Sale price</div>
                  <div className="text-base">${salePrice?.toLocaleString() || 0}</div>
                </div>
              </div>
            </div>
            {/* payment */}
            <div className="px-5 lg:px-10 pt-5 pb-3 grid grid-cols-5 gap-3 lg:gap-5">
              <div className="text-lightGray text-base uppercase">Payment</div>
              <div className="col-span-4 flex gap-3 lg:gap-5 justify-between">
                {payment && Object.values(payment).length > 0 ? (
                  <>
                    <div>
                      <div className="text-lightGray text-sm">Payment</div>
                      <div className="text-base">Bank loan | Bank of America</div>
                      <div
                        className="text-primary font-medium cursor-pointer flex items-center"
                        onClick={handleShowCreditApplicationDetail}
                      >
                        {'Completed Credit App >'}
                      </div>
                    </div>
                    <div>
                      <div className="text-lightGray text-sm">Down payment</div>
                      <div className="text-base">
                        ${payment?.cashdown ? parseInt(payment.cashdown, 10)?.toLocaleString() : 0}
                      </div>
                    </div>

                    <div>
                      <div className="text-lightGray text-sm">Terms</div>
                      <div className="text-base">{payment?.frequency || ''}</div>
                    </div>
                    <div>
                      <div className="text-lightGray text-sm">Interest</div>
                      <div className="text-base">7.5%</div>
                    </div>
                    <div>
                      <div className="text-lightGray text-sm">Financed amount</div>
                      <div className="text-base">$15,500</div>
                    </div>
                  </>
                ) : (
                  <div className="text-base">No credit application submitted</div>
                )}
              </div>
            </div>
            {/* trade ins */}
            <div className="px-5 lg:px-10 pt-5 pb-3 grid grid-cols-5 gap-3 lg:gap-5">
              <div className="text-lightGray text-base uppercase">Trade in(s)</div>
              <div className="col-span-4 flex flex-col gap-5">
                {tradeins && tradeins.length > 0 ? (
                  tradeins?.map((item, idx) => {
                    return (
                      <div className="flex gap-5 lg:gap-8" key={idx}>
                        <div>
                          <div className="text-lightGray text-sm">Vehicle</div>
                          <div className="text-base">{item?.vehicle_name || ''}</div>
                        </div>
                        <div>
                          <div className="text-lightGray text-sm">Mileage</div>
                          <div className="text-base">{item?.mileage?.toLocaleString() || 0}</div>
                        </div>
                        <div>
                          <div className="text-lightGray text-sm">Value</div>
                          <div className="text-base">${item?.estimatedvalue?.toLocaleString() || 0}</div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className="text-base">No trade-in vehicle</div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <CreditApplicationModal
            creditApplicationData={creditApplicationData}
            setNewModal={setNewModal}
            creditApplicaitonRef={creditApplicaitonRef}
          />
        )}
      </Modal>
    </>
  )
}

DealerCustomDeals.propTypes = {
  editPage: PropTypes.bool,
  vehicleId: PropTypes.string,
  dealershipAccount: PropTypes.object
}
