import { Button, Modal } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { BsPrinterFill } from 'react-icons/bs'
import { useReactToPrint } from 'react-to-print'

export function TradeDetailModal({ trade, showModal, handleCloseModal }) {
  const { vehicle, customer, comment } = trade
  const {
    color,
    condition,
    engine,
    make,
    mileage,
    model,
    ownership,
    transmission,
    trim,
    vin,
    year,
    zipcode,
    estimatedvalue
  } = vehicle || {}
  const { email, firstname, lastname, phone } = customer || {}
  const fields = {
    year,
    make,
    model,
    engine,
    mileage,
    ownership,
    transmission,
    trim,
    vin,
    zipcode,
    color,
    condition,
    email,
    'first name': firstname,
    'last name': lastname,
    phone,
    comment,
    'Trade-in value': estimatedvalue ? `$${estimatedvalue.toLocaleString()}` : 0
  }

  const tradeDetailRef = useRef()
  const handlePrintTradeDetail = useReactToPrint({
    content: () => tradeDetailRef.current
  })

  return (
    <Modal
      centered
      visible={showModal}
      title=""
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      okText="Close"
      className="calendar-modal"
      destroyOnClose
      width={680}
      footer={[
        <div className="flex justify-end">
          <Button
            className="flex gap-2 justify-center items-center font-semibold text-md rounded-lg p-4"
            icon={<BsPrinterFill className="text-md" />}
            type="primary"
            onClick={handlePrintTradeDetail}
          >
            Print
          </Button>
        </div>
      ]}
    >
      <div ref={tradeDetailRef} className="px-10 py-5">
        <div className="text-2xl text-center mb-4">Trade Detail</div>
        <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2">
          {Object.keys(fields).map((key) => (
            <div key={key} className="flex gap-2 flex-row">
              <div className="capitalize font-semibold">{key}:</div>
              <div>{fields[key]}</div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

TradeDetailModal.propTypes = {
  trade: PropTypes.object,
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func
}
