import { Button, Modal } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { BsPrinterFill } from 'react-icons/bs'
import { useReactToPrint } from 'react-to-print'
import CreditApplicationDetail from '../../Vehicle/CreditFinance/CreditApplicationDetail'

export function CreditAppDetailModal({ creditAppDetail, showModal, handleCloseModal }) {
  const creditApplicationRef = useRef()

  const handlePrintCreditApplication = useReactToPrint({
    content: () => creditApplicationRef.current
  })

  return (
    <Modal
      centered
      visible={showModal}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      okText="Close"
      className="dealer-custom-deals-modal"
      destroyOnClose
      width="70%"
      footer={[
        <div className="flex justify-end">
          <Button
            className="flex gap-2 justify-center items-center font-semibold text-md rounded-lg p-4"
            icon={<BsPrinterFill className="text-md" />}
            type="primary"
            onClick={handlePrintCreditApplication}
          >
            Print
          </Button>
        </div>
      ]}
    >
      <CreditApplicationDetail creditApplicationData={creditAppDetail} creditApplicationRef={creditApplicationRef} />
    </Modal>
  )
}

CreditAppDetailModal.propTypes = {
  creditAppDetail: PropTypes.object,
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func
}
