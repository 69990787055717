import { VerticalAlignTopOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useWindowScroll } from 'react-use'

function ScrollTopButton() {
  const scrollInfo = useWindowScroll()
  const location = useLocation()

  const goTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    goTop()
  }, [location.pathname])

  return (
    <Button
      className={`back-top ${scrollInfo.y > 300 ? 'show-btn' : ''}`}
      shape="circle"
      icon={<VerticalAlignTopOutlined />}
      onClick={goTop}
    />
  )
}

export default ScrollTopButton
