import qs from 'qs'
import queryString from 'query-string'
import HttpService from './http.service'
import HttpClientService from './httpClient.service'

export function getDealers({ page, rowsPerPage, status, keyword, dealerId, employeeId, includeAdmin }) {
  return HttpService.post('dealers', {
    page,
    per_page: rowsPerPage,
    status,
    keyword,
    dealer_id: dealerId,
    employee_id: employeeId,
    include_admin: includeAdmin
  }).then((result) => {
    const { data } = result
    return data
  })
}

export function getEmployees({ page, rowsPerPage, status, keyword, dealerId, employeeId }) {
  const params = queryString.stringify({
    page,
    per_page: rowsPerPage,
    status,
    keyword,
    dealer_id: dealerId,
    employee_id: employeeId,
    role: 'dealer'
  })
  return HttpService.get(`employees?${params}`).then((result) => {
    const { data } = result
    return data
  })
}

export function getDealerLeads(dealerId) {
  return HttpService.get(`get-leads-by-dealer/${dealerId}`).then((result) => {
    const { data } = result
    return data
  })
}

export function getDealerUserLeads(employeeId) {
  return HttpService.get(`get-leads-by-dealer-user/${employeeId}`).then((result) => {
    const { data } = result
    return data
  })
}

export function updateDealerStatusBulkAction({ dealers, status }) {
  return HttpClientService.post('update-dealers-status-bulk-action', {
    dealers,
    status
  }).then((result) => {
    const { data } = result
    return data
  })
}

export function updateDealer({
  employeeId,
  dealerId,
  dealerName,
  firstName,
  lastName,
  address,
  email,
  phone,
  website,
  city,
  state,
  zip,
  registered,
  status,
  permissions,
  updateRole,
  dealershipStatus,
  engagementTracking,
  emailTracking,
  showOtherNearbyDealers,
  smartboard,
  inventoryFeeds
}) {
  return HttpClientService.post('update-dealer', {
    employee_id: employeeId,
    dealer_id: dealerId,
    dealer_name: dealerName,
    first_name: firstName,
    last_name: lastName,
    address,
    email,
    phone,
    website,
    city,
    state,
    zip,
    registered,
    status,
    permissions,
    updateRole,
    dealershipStatus,
    engagementTracking,
    emailTracking,
    showOtherNearbyDealers,
    smartboard,
    inventoryFeeds
  }).then((result) => {
    const { data } = result
    return data
  })
}

export function getCarsForSaleTemplateLink() {
  const baseURL = process.env.REACT_APP_API_SERVER
  return `${baseURL}/cars-for-sale-download-template`
}

export function getInventorySyndicationFileLink(dealerId) {
  const baseURL = process.env.REACT_APP_API_SERVER
  return `${baseURL}/inventory-syndication-download-file/${dealerId}`
}

export function getInventorySyndicationTemplateLink(dealerId) {
  const baseURL = process.env.REACT_APP_API_SERVER
  return `${baseURL}/inventory-syndication-download-template/${dealerId}`
}

export function deleteDealerInventoryFeed({ dealerId, index }) {
  return HttpClientService.post('delete-dealer-inventory-feed', {
    dealer_id: dealerId,
    index
  }).then((result) => {
    const { data } = result
    return data
  })
}

export function updateDealerInventoryFeedElseWhere({ dealerId, index, action }) {
  return HttpClientService.post('update-dealer-inventory-feed-else-where', {
    dealer_id: dealerId,
    index,
    action
  }).then((result) => {
    const { data } = result
    return data
  })
}

export function updateDealerInventoryFeedInSearchX({ dealerId, index, action }) {
  return HttpClientService.post('update-dealer-inventory-feed-in-search-x', {
    dealer_id: dealerId,
    index,
    action
  }).then((result) => {
    const { data } = result
    return data
  })
}

export function generatePasswordEmployee({ dealerId, employeeId, adminRequest, email }) {
  return HttpClientService.post('employee-generate-password', {
    dealer_id: dealerId,
    employee_id: employeeId,
    admin_request: adminRequest,
    email
  }).then((result) => {
    const { data } = result
    return data
  })
}

export function createDealer(payload) {
  return HttpClientService.post('create-dealer', payload).then((result) => {
    return result
  })
}

export function getDealerDetail(id) {
  return HttpService.get(`get-dealer-details/${id}`).then((result) => {
    const { data } = result
    return data
  })
}

export function getDealerCarsForSaleSettings(id) {
  return HttpService.get(`get-dealer-cars-for-sale-settings/${id}`).then((result) => {
    const { data } = result
    return data
  })
}

export function setDealerCarsForSaleSettings({ dealerId, action }) {
  return HttpService.post('set-dealer-cars-for-sale-settings', {
    dealer_id: parseInt(dealerId, 10),
    action
  }).then((result) => {
    const { data } = result
    return data
  })
}

export function sendDealerCarsForSaleSettingsEmail({ dealerId, email }) {
  return HttpService.post('send-dealer-cars-for-sale-settings-email', {
    dealer_id: parseInt(dealerId, 10),
    email
  }).then((result) => {
    const { data } = result
    return data
  })
}

export function getSearchSorting({ sortBy, orderBy, page, rowsPerPage, dealerId, keyword }) {
  return HttpClientService.post('dealer-search-sorting', {
    dealer_id: parseInt(dealerId, 10),
    sort_by: sortBy,
    order_by: orderBy,
    page,
    limit: rowsPerPage,
    keyword
  }).then((result) => {
    const { data } = result
    return data
  })
}

export function getNearbyDealers(id) {
  return HttpService.get(`nearby-dealers/${id}`).then((result) => {
    const { data } = result
    return data
  })
}

export function getDealerByFeature({
  zipCode,
  price,
  year,
  mileage,
  make,
  model,
  transmission,
  rowsPerPage: limit,
  trim,
  page,
  dealerId,
  color,
  vehicleType,
  certifiedVehicles,
  sortBy,
  orderBy,
  keyword
}) {
  const arrZipCode = zipCode && zipCode.split(',').map((item) => item.trim())
  const params = {
    zipCode: arrZipCode,
    price,
    year,
    mileage,
    make,
    model,
    transmission_type: transmission,
    limit,
    page,
    dealer_id: parseInt(dealerId, 10),
    body_type: trim,
    color,
    vehicleType,
    certified_vehicles: certifiedVehicles,
    sortBy,
    orderBy,
    keyword
  }
  return HttpClientService.post('dealer-search-by-feature', params).then((result) => {
    const { data } = result
    return data
  })
}

export function searchDealershipInfo(keyword) {
  return HttpService.post('search-by-dealer-info', { keyword }).then((result) => {
    const { data } = result
    return data
  })
}

export function searchDealershipMemberNumber(keyword) {
  return HttpService.post('search-by-dealer-member-number', { keyword }).then((result) => {
    const { data } = result
    return data
  })
}

export function searchDealershipMemberNumberExactMatch(keyword) {
  return HttpService.post('search-by-dealer-member-number-exact-match', { keyword }).then((result) => {
    const { data } = result
    return data
  })
}

export function getDealerShipDashboardInfo() {
  return HttpClientService.get('fetch-dealership-dashboard-info').then((result) => {
    const { data } = result
    return data
  })
}

export function bankRoutingNumberResolve(routingNumber) {
  const params = {
    routing_number: routingNumber
  }
  return HttpService.post('bank-routing-number/resolve', params).then((result) => {
    const { data } = result
    if (data.isSuccessful) return data.data
    return ''
  })
}

export function memberPrice(memberNumber) {
  const params = {
    member_number: memberNumber
  }
  return HttpService.post('member-price', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getMemberPriceByStateISO(stateIso) {
  return HttpClientService.get(`get-member-price-by-state/${stateIso}`).then((result) => {
    const { data } = result
    return data
  })
}

export function getNewClaimedList() {
  return HttpClientService.get('dealers/new-claimed-list').then((result) => {
    const { data } = result
    return data
  })
}

export function getOffersByVehicle(vehicleId, params) {
  return HttpService.get(`offers/get-by-vehicle/${vehicleId}?${qs.stringify(params)}`).then((result) => {
    const { data } = result
    return data
  })
}

export function getOffersByDealer(dealerId, params) {
  return HttpService.get(`offers/get-by-dealer/${dealerId}?${qs.stringify(params)}`).then((result) => {
    const { data } = result
    return data
  })
}

export function dealershipOffers(params) {
  return HttpClientService.post(`dealership/offers`, params).then((result) => {
    const { data } = result
    return data
  })
}

export function dealershipOffersCount() {
  return HttpClientService.post(`dealership/offers-count`).then((result) => {
    const { data } = result
    return data
  })
}

export function getDetails(id, type) {
  return HttpService.get(`offers/get-detail/${id}?type=${type}`).then((result) => {
    const { data } = result
    return data
  })
}

export function getDealershipDealers() {
  return HttpClientService.get('dealership/dealers').then((result) => {
    const { data } = result
    return data
  })
}

export function updateDealerOffers(params) {
  return HttpClientService.put('dealership/update-offers', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getOffer(offerId) {
  return HttpClientService.get(`dealership/get-offer/${offerId}`).then((result) => {
    const { data } = result
    return data
  })
}

export function dealershipHasCPop(recordId) {
  const params = {
    record_id: recordId
  }
  return HttpClientService.post('dealership/has-c-pop', params).then((result) => {
    const { data } = result
    return data
  })
}

export function dealershipCPopOffers(offerId) {
  const params = {
    id: offerId
  }
  return HttpClientService.post('dealership/c-pop-offers', params).then((result) => {
    const { data } = result
    return data
  })
}

export function dealershipCloneOffer(initialId, offerId, term, downPayment) {
  const params = {
    initial_id: initialId,
    id: offerId,
    term,
    down_payment: downPayment
  }
  return HttpClientService.post('dealership/clone-offer', params).then((result) => {
    const { data } = result
    return data
  })
}

export function dealershipCPopOffersUpdate(initialId, currentId, terms, downPayments) {
  const params = {
    initial_id: initialId,
    current_id: currentId,
    terms,
    down_payments: downPayments
  }
  return HttpClientService.post('dealership/c-pop-offers-update', params).then((result) => {
    const { data } = result
    return data
  })
}

export function dealershipCPopOffersPublish(initialOfferId, ids) {
  const params = {
    initial_id: initialOfferId,
    ids
  }
  return HttpClientService.post('dealership/c-pop-offers-publish', params).then((result) => {
    const { data } = result
    return data
  })
}

export function dealershipCustomerMakeDeposit(initialOfferId, $currentOfferId, depositAmount) {
  const params = {
    initial_id: initialOfferId,
    current_id: $currentOfferId,
    deposit: depositAmount
  }
  return HttpClientService.post('dealership/customer-make-deposit', params).then((result) => {
    const { data } = result
    return data
  })
}

export function dealershipCustomerVisitCPop(cPopId) {
  const params = {
    id: cPopId
  }
  return HttpClientService.post('dealership/customer-visit-c-pop', params).then((result) => {
    const { data } = result
    return data
  })
}

export function dealershipCPopOfferAddVehicle(initialId, vehicleId, term, downPayment) {
  const params = {
    initial_id: initialId,
    vehicle_id: vehicleId,
    term,
    down_payment: downPayment
  }
  return HttpClientService.post('dealership/c-pop-offers-add-vehicle', params).then((result) => {
    const { data } = result
    return data
  })
}

export function dealershipDeleteClonedOffer(initialId, offerId) {
  const params = {
    initial_id: initialId,
    id: offerId
  }
  return HttpClientService.post('dealership/delete-cloned-offer', params).then((result) => {
    const { data } = result
    return data
  })
}

export function sendEmail(params) {
  return HttpClientService.post('dealership/send-email', params).then((result) => {
    const { data } = result
    return data
  })
}

export function accountUpdate(params) {
  return HttpClientService.post('dealership-account/update', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getCreditScoreVehicleAgeRatesAndYears(dealerId) {
  return HttpService.get(`get-credit-score-vehicle-age-rates-and-years/${dealerId}`).then((result) => {
    const { data } = result
    return data
  })
}

export function creditScoreVehicleAgeRatesAndYears(params) {
  return HttpClientService.post('credit-score-vehicle-age-rates-and-years', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getCreditScoreVehicleYearTermInterestRates(params) {
  return HttpService.post('get-credit-score-vehicle-year-term-interest-rates', params).then((result) => {
    const { data } = result
    return data
  })
}

export function creditScoreVehicleYearTermInterestRates(params) {
  return HttpClientService.post('credit-score-vehicle-year-term-interest-rates', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getDealerFees(dealerId) {
  return HttpService.get(`dealers/dealer-fees/${dealerId}`).then((result) => {
    const { data } = result
    return data
  })
}

export function getDealerFeesUpdate(dealerId) {
  return HttpService.put(`dealers/dealer-fees/${dealerId}/update`).then((result) => {
    const { data } = result
    return data
  })
}

export function dealerFeesUpdate(params) {
  return HttpClientService.put('dealer-fees/update', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getCreditScoreRateYears(dealerId) {
  return HttpClientService.get(`get-credit-score-rate-years/${dealerId}`).then((result) => {
    const { data } = result
    return data
  })
}

export function updateCreditScoreRateYearsRecord(params) {
  return HttpClientService.put('credit-score/rate-years/update-record', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getCreditScoreRangeRates(dealerId) {
  return HttpClientService.get(`get-credit-score/range-rates/${dealerId}`).then((result) => {
    const { data } = result
    return data
  })
}

export function updateCreditScoreRangeRatesRecord(params) {
  return HttpClientService.put('credit-score/range-rates/update-record', params).then((result) => {
    const { data } = result
    return data
  })
}

export function setUnClaimStatus(dealershipIds) {
  const params = {
    dealership_ids: dealershipIds
  }
  return HttpClientService.post('dealers/set-unclaim-status', params).then((result) => {
    const { data } = result
    return data
  })
}

export function setClaimStatus(dealershipIds) {
  const params = {
    dealership_ids: dealershipIds
  }
  return HttpClientService.post('dealers/set-claim-status', params).then((result) => {
    const { data } = result
    return data
  })
}

export function updateADFEmail(params) {
  return HttpClientService.put('dealership/update-adfxml', params).then((result) => {
    const { data } = result
    return data
  })
}

export function updateDealershipInformation(params) {
  return HttpClientService.put('dealership/update-dealership-information', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getSetting700({ vin }) {
  return HttpClientService.get(`dealership/settings/700/${vin}`).then((result) => {
    const { data } = result
    return data
  })
}

export function updateSetting700(params) {
  return HttpClientService.post('dealership/settings/700', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getTrade(tradeId) {
  return HttpClientService.get(`dealership/get-trade/${tradeId}`).then((result) => {
    const { data } = result
    return data
  })
}

export function updateNoReplyNeeded(params) {
  return HttpClientService.post('update-no-reply-needed', params).then((result) => {
    const { data } = result
    return data
  })
}

export function updateDealershipLogo(file, dealershipId) {
  const formData = new FormData()
  formData.append('dealer_logo', file)
  return HttpClientService.post(`dealership/update-logo/${dealershipId}`, formData).then((result) => {
    const { data } = result
    return data
  })
}

export function getDealershipVehicleVisits(dealerId) {
  return HttpService.get(`get-dealership-vehicle-visits/${dealerId}`).then((result) => {
    const { data } = result
    return data
  })
}

export function deleteDealershipLogo(dealershipId) {
  return HttpClientService.post(`dealership/delete-logo/${dealershipId}`, {}).then((result) => {
    const { data } = result
    return data
  })
}

export function getDealerRatings(dealerId) {
  return HttpClientService.get(`get-dealer-ratings/${dealerId}`).then((result) => {
    const { data } = result
    return data
  })
}

export function switchDealership({ fromDealerId, toDealerId }) {
  return HttpClientService.post('switch-dealership', {
    from_dealer_id: fromDealerId,
    to_dealer_id: toDealerId
  }).then((result) => {
    const { data } = result
    return data
  })
}

export function uploadFromSyndicationInventoryManualDropFile({ dealerId, uploadId }) {
  return HttpClientService.post('upload-from-syndication-inventory-manual-drop-file', {
    dealer_id: dealerId,
    upload_id: uploadId
  }).then((result) => {
    const { data } = result
    return data
  })
}

export function getVehicleCalculationRecord({ id }) {
  return HttpClientService.get(`get-vehicle-calculation-record/${id}`).then((result) => {
    const { data } = result
    return data
  })
}
