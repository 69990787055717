import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import './style.scss'

export function SearchDashboard({ onSearch }) {
  const { userLocation, customerAccount } = useAuthorizationContext()
  const [zipCode, setZipCode] = useState('')
  const [search, setSearch] = useState('')

  const onchangeSearch = (e) => {
    setSearch(e.target.value)
    if (e.target.value === '') {
      onSearch(e.target.value)
    }
  }

  const onKeyPress = (e) => {
    if (e.charCode === 13) {
      onSearch(search)
    }
  }

  const handleClick = () => {
    onSearch(search)
  }

  useEffect(() => {
    if (userLocation?.city) {
      const { city, region } = userLocation
      setZipCode(`${city}, ${region}`)
    }
  }, [userLocation])

  return (
    <div className="grid lg:grid-cols-7 gap-3">
      <div className="search-dashboard flex flex-col col-span-3">
        <div className="title">{`Hello ${customerAccount?.first_name || ''}!`}</div>
        <div className="subtitle">Here you have a quick overview on your favorite vehicles</div>
      </div>
      <div className="rounded-xl relative z-10 flex-1 flex justify-end col-span-2">
        <div className="relative w-full h-full">
          <input
            onChange={onchangeSearch}
            onKeyPress={onKeyPress}
            className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded"
            type="text"
            value={search}
            placeholder="Search for vehicle or dealership"
          />
          <SearchOutlined
            onClick={handleClick}
            className="text-blue-500 absolute right-3 top-4 -translate-y-2/4 cursor-pointer"
          />
        </div>
      </div>
      <div className="ml-5 flex items-center gap-3 col-span-2 justify-end">
        <span className="select-location-dashboard">My Location: </span>
        <Input name="zipCode" placeholder="Zip code" value={zipCode} disabled />
      </div>
    </div>
  )
}

SearchDashboard.propTypes = {
  onSearch: PropTypes.func.isRequired
}
