import { notification } from 'antd'
import moment from 'moment'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import * as CustomerServices from 'src/services/customer.service'
import AuthPopup from '../BoxButtonGroup/AuthPopup'
import LoginFormModal from '../BoxButtonGroup/LoginFormModal'
import SignUpFormModal from '../BoxButtonGroup/SignUpFormModal'
import VehicleValueNotFoundPopup from '../BoxButtonGroup/VehicleValueNotFoundPopUp'

function TradeInValue({ tradeVehicleInfo, tradeId, info, backStep, tradeInValue }) {
  const { year, yearId, make, makeName, model, modelName } = tradeVehicleInfo
  const yearDisplay = year || yearId || ''
  const makeDisplay = make || makeName || ''
  const modelDisplay = model || modelName || ''
  const currentYear = new Date().getFullYear()
  const currentDate = moment(new Date()).format('MM/DD/YYYY')
  const { customerLogin } = useAuthorizationContext()
  const [sendOfferButtonDisabled, setSendOfferButtonDisabled] = useState(false)
  const [showPopupConfirm, setShowPopupConfirm] = useState(false)
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [showSignUpForm, setShowSignUpForm] = useState(false)
  const [showVehicleValueNotFoundPopup, setShowVehicleValueNotFoundPopup] = useState(false)
  const [, setLoginRequired] = useState(false)
  const [, setShowCashDownPopupConfirm] = useState(false)

  useEffect(() => {
    if (tradeInValue < 1) {
      setShowVehicleValueNotFoundPopup(true)
    }
  }, [tradeInValue])

  const openNotification = ({ message, description, type = 'success' }) => {
    const action = type === 'success' ? notification.success : notification.error
    action({
      message,
      description
    })
  }

  const sendOffer = () => {
    setSendOfferButtonDisabled(true)
    CustomerServices.saveOffer({ vehicleId: info._id, tradeInId: tradeId })
      .then((response) => {
        const { isSuccessful } = response
        if (isSuccessful === 'Yes') {
          openNotification({
            message: 'The trade offer was sent successfully.'
          })
        }
      })
      .catch((err) =>
        openNotification({
          message: "The trade offer can't be sent.",
          description: err.message,
          type: 'error'
        })
      )
  }

  const handleProceedBtn = () => {
    if (customerLogin === false) {
      setShowPopupConfirm(true)
    } else {
      sendOffer()
    }
  }

  const handleShowLoginForm = () => {
    setShowPopupConfirm(false)
    setShowLoginForm(true)
  }

  const handleShowSignUpForm = () => {
    setShowPopupConfirm(false)
    setShowSignUpForm(true)
  }

  const callbackForForm = () => {
    sendOffer()
  }

  return (
    <div className="flex flex-col gap-20 pb-28">
      <div className="mt-8 step-3">
        <p className="text-2xl text-black font-bold">Your Trade-In Value</p>
        <p className="text-base">{`Your vehicle ${yearDisplay} ${makeDisplay} ${modelDisplay} has an approximate trade-in value of $${tradeInValue?.toLocaleString()}`}</p>
      </div>
      <div>
        {`© ${currentYear} Kelley Blue Book Co., Inc. All rights reserved. ${currentDate} National Edition. The specific
    information required to determine the value for this particular vehicle was supplied by the person
    generating this report. This report is intended for the individual use of the person generating this
    report only and shall not be sold or transmitted to another party. Kelley Blue Book assumes no
    responsibility for errors or omissions.`}
      </div>
      <div className="flex justify-around mt-12">
        {info && tradeId ? (
          <div className="flex gap-5 flex-col">
            <b>Would like to get a confirmed offer for your trade from the dealer?</b>
            <div className="flex gap-3">
              <button type="button" className="bg-gray-500 rounded text-white px-14 py-2" onClick={backStep}>
                Back
              </button>
              <button
                disabled={sendOfferButtonDisabled}
                type="button"
                className="bg-primary rounded text-white px-14 py-2"
                onClick={handleProceedBtn}
              >
                Proceed
              </button>
            </div>
          </div>
        ) : null}
      </div>
      <AuthPopup
        show={showPopupConfirm}
        setShow={setShowPopupConfirm}
        handleShowLoginForm={handleShowLoginForm}
        handleShowSignUpForm={handleShowSignUpForm}
        setLoginRequired={setLoginRequired}
      />
      <LoginFormModal
        showModal={showLoginForm}
        setShowModal={setShowLoginForm}
        callback={callbackForForm}
        setShowCashDownPopupConfirm={setShowCashDownPopupConfirm}
      />
      <SignUpFormModal
        showModal={showSignUpForm}
        setShowModal={setShowSignUpForm}
        callback={callbackForForm}
        setShowCashDownPopupConfirm={setShowCashDownPopupConfirm}
      />
      <VehicleValueNotFoundPopup
        showPopup={showVehicleValueNotFoundPopup}
        setShowPopup={setShowVehicleValueNotFoundPopup}
      />
    </div>
  )
}

TradeInValue.propTypes = {
  tradeVehicleInfo: PropTypes.object,
  tradeId: PropTypes.string,
  info: PropTypes.object,
  backStep: PropTypes.func,
  tradeInValue: PropTypes.any
}

export default TradeInValue
