import { Button, Form, Input, Modal, notification, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { STORAGE_KEYS } from '../../constants/constants'
import { load } from '../../lib/storage'
import * as DealerService from '../../services/dealer.service'
import AddDeleteTableRows from '../../components/AddDeleteTableRows'
import RateAdjustmentForScoreAndAgeOfVehicle from '../../components/DealerDashboard/RateAdjustmentForScoreAndAgeOfVehicle'
import CreditScoreVehicleYearModelTermInterestRate from '../../components/DealerDashboard/CreditScoreVehicleYearModelTermInterestRate'
import './style.scss'

const tableYearsColumns = [
  {
    title: 'Rate 36',
    dataIndex: 'rate_year_36',
    key: 'rate_year_36',
    render: (value) => <div className="text-center">{`-${value}`}</div>
  },
  {
    title: 'Rate 48',
    dataIndex: 'rate_year_48',
    key: 'rate_year_48',
    render: (value) => <div className="text-center">{`-${value}`}</div>
  },
  {
    title: 'Rate 60',
    dataIndex: 'rate_year_60',
    key: 'rate_year_60',
    render: (value) => <div className="text-center">{`-${value}`}</div>
  },
  {
    title: 'Rate 72',
    dataIndex: 'rate_year_72',
    key: 'rate_year_72',
    render: (value) => <div className="text-center">{`-${value}`}</div>
  }
]

const formYearsFields = [
  {
    label: 'Rate 36',
    name: 'rate_year_36',
    required: true,
    type: 'number',
    prefix: '-'
  },
  {
    label: 'Rate 48',
    name: 'rate_year_48',
    required: true,
    type: 'number',
    prefix: '-'
  },
  {
    label: 'Rate 60',
    name: 'rate_year_60',
    required: true,
    type: 'number',
    prefix: '-'
  },
  {
    label: 'Rate 72',
    name: 'rate_year_72',
    required: true,
    type: 'number',
    prefix: '-'
  }
]

const tableColumns = [
  {
    title: 'Score Range',
    dataIndex: 'scoreRange1',
    key: 'scoreRange1',
    colSpan: 2,
    width: 150,
    render: (value) => (value > 0 ? value : 'Below')
  },
  {
    title: 'Score Range',
    dataIndex: 'scoreRange2',
    key: 'scoreRange2',
    colSpan: 0,
    width: 150
  },
  {
    title: 'Estimated Rate 36',
    dataIndex: 'estimatedRate36',
    key: 'estimatedRate36',
    render: (value) => <div className="text-center">{`${value.toFixed(2)}%`}</div>
  },
  {
    title: 'Estimated Rate 48',
    dataIndex: 'estimatedRate48',
    key: 'estimatedRate48',
    render: (value) => <div className="text-center">{`${value.toFixed(2)}%`}</div>
  },
  {
    title: 'Estimated Rate 60',
    dataIndex: 'estimatedRate60',
    key: 'estimatedRate60',
    render: (value) => <div className="text-center">{`${value.toFixed(2)}%`}</div>
  },
  {
    title: 'Estimated Rate 72',
    dataIndex: 'estimatedRate72',
    key: 'estimatedRate72',
    render: (value) => <div className="text-center">{`${value.toFixed(2)}%`}</div>
  }
]

const formFields = [
  {
    label: 'Score Range From',
    name: 'scoreRange1',
    required: true
  },
  {
    label: 'Score Range To',
    name: 'scoreRange2',
    required: true
  },
  {
    label: 'Estimated Rate 36',
    name: 'estimatedRate36',
    required: true,
    type: 'number',
    suffix: '%'
  },
  {
    label: 'Estimated Rate 48',
    name: 'estimatedRate48',
    required: true,
    type: 'number',
    suffix: '%'
  },
  {
    label: 'Estimated Rate 60',
    name: 'estimatedRate60',
    required: true,
    type: 'number',
    suffix: '%'
  },
  {
    label: 'Estimated Rate 72',
    name: 'estimatedRate72',
    required: true,
    type: 'number',
    suffix: '%'
  }
]

function AdminDealershipPaymentSettings() {
  const [rowPosition, setRowPosition] = useState(0)
  const [tableYearsData, setTableYearsData] = useState([])
  const [tableData, setTableData] = useState([])
  const [showYearsModal, setShowYearsModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [formYears] = Form.useForm()
  const [form] = Form.useForm()

  const dealerId = load(STORAGE_KEYS.DEALER_ID) || 0

  const handleChangeShowYearsModal = () => setShowYearsModal(!showYearsModal)
  const handleChangeShowModal = () => setShowModal(!showModal)

  const handleFormYearsSubmission = () => {
    const parameters = formYears.getFieldsValue()
    parameters.dealer_id = dealerId
    DealerService.updateCreditScoreRateYearsRecord(parameters)
      .then((response) => {
        setTableYearsData(response.data)
        notification.success({
          message: 'Success!',
          description: 'Updated rate years successful.'
        })
      })
      .catch((error) => {
        notification.error({
          message: 'Error!',
          description: 'Updated rate years failed.'
        })
        console.error(error)
      })
    handleChangeShowYearsModal()
  }

  const handleFormSubmission = () => {
    const parameters = form.getFieldsValue()
    parameters.dealer_id = dealerId
    parameters.position = rowPosition
    DealerService.updateCreditScoreRangeRatesRecord(parameters)
      .then((response) => {
        setTableData(response.data)
        notification.success({
          message: 'Success!',
          description: 'Updated range and rate successful.'
        })
      })
      .catch((error) => {
        notification.error({
          message: 'Error!',
          description: 'Updated range and rate failed.'
        })
        console.error(error)
      })
    handleChangeShowModal()
  }

  const onFormYearsChange = (e) => {
    if (e.charCode) formYears.setFieldsValue({ ...formYears.getFieldValue(), [e.target.name]: e.target.value })
  }

  const onFormChange = (e) => {
    if (e.charCode) form.setFieldsValue({ ...form.getFieldValue(), [e.target.name]: e.target.value })
  }

  const onSelectYearsRow = (record) => {
    formYears.setFieldsValue(record)
  }

  const onSelectRow = (record) => {
    form.setFieldsValue(record)
  }

  useEffect(() => {
    DealerService.getCreditScoreRateYears(dealerId)
      .then((response) => {
        setTableYearsData(response.data)
      })
      .catch((error) => {
        setTableYearsData([])
        console.error(error)
      })
  }, [])

  useEffect(() => {
    DealerService.getCreditScoreRangeRates(dealerId)
      .then((response) => {
        setTableData(response.data)
      })
      .catch((error) => {
        setTableData([])
        console.error(error)
      })
  }, [])

  return (
    <div className="container">
      <div className="mx-auto my-10 px-4 rounded-xl border-2 py-4">
        <div>Credit Score Rate Years</div>
        <Table
          columns={tableYearsColumns}
          className="mt-5 payment-settings-table"
          dataSource={tableYearsData}
          scroll={{ x: 'max-content' }}
          pagination={{ position: ['none', 'none'] }}
          onRow={(record) => {
            return {
              onClick: () => {
                onSelectYearsRow(record)
                handleChangeShowYearsModal()
              }
            }
          }}
        />
      </div>
      <Modal
        visible={showYearsModal}
        title="Edit Credit Score Rate Years"
        footer={null}
        style={{ top: 20 }}
        onCancel={handleChangeShowYearsModal}
        className="w-full"
      >
        <Form
          form={formYears}
          layout="vertical"
          name="vehicle-ref-ranges"
          onFinish={handleFormYearsSubmission}
          onChange={onFormYearsChange}
        >
          {formYearsFields.map((item, index) => {
            return (
              <div key={item.name}>
                <p className="text-base font-semibold">{item.label}</p>
                <Form.Item
                  name={item.name}
                  rules={[
                    {
                      required: item.required,
                      message: `${item.label} is required`
                    }
                  ]}
                  className="custom-antd-label flex"
                >
                  <Input
                    placeholder={item.label}
                    name={item.name}
                    autoFocus={index === 1}
                    className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
                    prefix={item.prefix}
                  />
                </Form.Item>
              </div>
            )
          })}
          <Form.Item className="flex items-end w-full mt-5 submit" shouldUpdate>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                className="w-48 px-6 py-5 h-12 rounded-lg flex justify-center items-center gap-2 text-base font-semibold text-white bg-blue-700"
              >
                Save
              </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>
      <div className="mx-auto my-10 px-4 rounded-xl border-2 py-4">
        <div>Credit Score Ranges & Rates</div>
        <Table
          columns={tableColumns}
          className="mt-5 payment-settings-table"
          dataSource={tableData}
          scroll={{ x: 'max-content' }}
          pagination={{ position: ['none', 'none'] }}
          onRow={(record) => {
            return {
              onClick: () => {
                onSelectRow(record)
                handleChangeShowModal()
                setRowPosition(record.position)
              }
            }
          }}
        />
      </div>
      <Modal
        visible={showModal}
        title="Edit Credit Score Range & Rate"
        footer={null}
        style={{ top: 20 }}
        onCancel={handleChangeShowModal}
        className="w-full"
      >
        <Form
          form={form}
          layout="vertical"
          name="vehicle-ref-years"
          onFinish={handleFormSubmission}
          onChange={onFormChange}
        >
          {formFields.map((item, index) => {
            return (
              <div key={item.name}>
                <p className="text-base font-semibold">{item.label}</p>
                <Form.Item
                  name={item.name}
                  rules={[
                    {
                      required: item.required,
                      message: `${item.label} is required`
                    }
                  ]}
                  className="custom-antd-label flex"
                >
                  <Input
                    placeholder={item.label}
                    name={item.name}
                    autoFocus={index === 1}
                    className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
                    suffix={item.suffix}
                  />
                </Form.Item>
              </div>
            )
          })}
          <Form.Item className="flex items-end w-full mt-5 submit" shouldUpdate>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                className="w-48 px-6 py-5 h-12 rounded-lg flex justify-center items-center gap-2 text-base font-semibold text-white bg-blue-700"
              >
                Save
              </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>

      <div className="mx-auto my-10 px-4 rounded-xl border-2 py-4">
        <div>Dealer Fees</div>
        <div className="ant-table-wrapper mt-5 payment-settings-table">
          <AddDeleteTableRows dealerId={dealerId} canManagePaymentSettings />
        </div>
      </div>

      <div className="mx-auto my-10 px-4 rounded-xl border-2 py-4">
        <div>Rate Adjustment For Score And Age Of Vehicle</div>
        <div className="ant-table-wrapper mt-5 payment-settings-table">
          <RateAdjustmentForScoreAndAgeOfVehicle dealerId={dealerId} canManagePaymentSettings />
        </div>
      </div>

      <div className="mx-auto my-10 px-4 rounded-xl border-2 py-4">
        <div>Credit Score Vehicle Year Model Term Interest Rate</div>
        <div className="ant-table-wrapper mt-5 payment-settings-table">
          <CreditScoreVehicleYearModelTermInterestRate dealerId={dealerId} canManagePaymentSettings />
        </div>
      </div>

    </div>
  )
}

export default AdminDealershipPaymentSettings
