import { Form } from 'antd'
import * as PropTypes from 'prop-types'
import React from 'react'
import { STATES } from 'src/constants/constants'

function DriverLicense({ driverLicenseFormRef }) {
  return (
    <Form layout="vertical" ref={driverLicenseFormRef} name="driver-license-ref">
      <div className="mt-4 step-4">
        <p className="text-2xl text-black font-bold mb-3">Driver License</p>
        <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:cols-2 gap-0 xl:gap-6 lg:gap-5 md:gap-4">
          <Form.Item
            name="licenseNumber"
            label="License Number"
            rules={[{ pattern: /^[a-zA-Z0-9\s]+$/g, message: 'Alphanumeric string accepted' }]}
            className="custom-antd-label block"
          >
            <input
              name="licenseNumber"
              autoFocus
              className="w-full border border-black rounded py-2 px-3"
              type="text"
              value=""
            />
          </Form.Item>
          <Form.Item name="licenseState" label="License State" className="custom-antd-label block">
            <select
              value=""
              name="licenseState"
              className="w-full bg-white border border-black rounded py-2 px-3 select-option-sell-your-car"
            >
              <option>Select license state</option>
              {STATES.map((item) => (
                <option value={item.name} key={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </Form.Item>
          <Form.Item name="licenseIssuedDate" label="License Issued" className="custom-antd-label block">
            <input value="" name="licenseIssuedDate" type="date" className="w-full border border-black rounded py-2 px-3" />
          </Form.Item>

          <Form.Item name="licenseExpiryDate" label="License Expiry" className="custom-antd-label mb-0 block">
            <input value="" name="licenseExpiryDate" type="date" className="w-full border border-black rounded py-2 px-3" />
          </Form.Item>
        </div>
      </div>
    </Form>
  )
}

DriverLicense.propTypes = {
  driverLicenseFormRef: PropTypes.object
}

export default DriverLicense