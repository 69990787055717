import React from 'react'
import './style.scss'
import SinglePageMetaDecorator from '../../decorators/SinglePageMetaDecorator'
import metaPages from '../../data/metaPages.json'
import metaPageImage from '../../assets/new-logo.png'

function TermsPrivacy() {
  return (
    <div className="container mt-5 termsPrivacyContainer">
      <SinglePageMetaDecorator
        title={metaPages.termsPrivacy.title}
        description={metaPages.termsPrivacy.description}
        imageUrl={metaPageImage}
        imageAlt={metaPages.termsPrivacy.imageAlt}
      />
      <div className="fl-rich-text">
        <h1 className="fl-heading">
          <span className="fl-heading-text">Privacy Policy&nbsp;</span>
        </h1>
        <h3>
          <strong>What information do we collect?</strong>
        </h3>
        <ul>
          <li>
            We collect information from you when you register on the site, place an order, respond to a survey or
            communication such as e-mail, or participate in another site feature.
          </li>
          <li>
            When ordering or registering, we may ask you for your name, e-mail address, mailing address, phone number,
            other information. You may, however, visit our site anonymously.
          </li>
          <li>
            Like many websites, we use “cookies” to enhance your experience and gather information about visitors and
            visits to our websites. Please refer to the “Do we use ‘cookies’?” section below for information about
            cookies and how we use them.
          </li>
        </ul>
        <h3>
          <strong>How do we use your information?</strong>
        </h3>
        <p>
          We may use the information we collect from you when you register, purchase products, respond to a survey or
          marketing communication, surf the website, or use certain other site features in the following ways:
        </p>
        <ul>
          <li>
            To personalize your site experience and to allow us to deliver the type of content and product offerings in
            which you are most interested.
          </li>
          <li>To allow us to better service you in responding to your customer service requests.</li>
          <li>To quickly process your transactions.</li>
          <li>To send you promotional emails or product offerings from PSXDigital.</li>
        </ul>
        <h3>
          <strong>How do we protect visitor information?</strong>
        </h3>
        <p>
          We implement a variety of security measures to maintain the safety of your personal information. Your personal
          information is contained behind secured networks and is only accessible by a limited number of persons who
          have special access rights to such systems, and are required to keep the information confidential. When you
          place orders or access your personal information, we offer the use of a secure server. All sensitive/credit
          information you supply is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our
          databases to be only accessed as stated above.
        </p>
        <h3>
          <strong>Do we use “cookies”?</strong>
        </h3>
        <p>
          Yes. Cookies are small files that a site or its service provider transfers to your computer’s hard drive
          through your Web browser (if you allow) that enables the site’s or service provider’s systems to recognize
          your browser and capture and remember certain information. For instance, we use cookies to help us remember
          and process the items in your shopping cart. They are also used to help us understand your preferences based
          on previous or current site activity, which enables us to provide you with improved services. We also use
          cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better
          site experiences and tools in the future.
        </p>
        <p>
          We may contract with third-party service providers to assist us in better understanding our site visitors.
          These service providers are not permitted to use the information collected on our behalf except to help us
          conduct and improve our business.
        </p>
        <p>
          You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off
          all cookies. You do this through your browser (like Netscape Navigator or Internet Explorer) settings. Each
          browser is a little different, so look at your browser Help menu to learn the correct way to modify your
          cookies. If you turn cookies off, you won’t have access to many features that make your site experience more
          efficient and some of our services will not function properly. However, you can still place orders over the
          telephone by contacting customer service.
        </p>
        <h3>
          <strong>Do we disclose the information we collect to outside parties?</strong>
        </h3>
        <p>
          This site is being monitored by one or more third-party monitoring software(s), and may capture information
          about your visit that will help us improve the quality of our service. You may opt-out from the data that{' '}
          <a href="https://smart-pixl.com" target="_blank" rel="noopener noreferrer" className="text-blue-500">
            Smart Pixl
          </a>{' '}
          is collecting on your visit through a universal consumer options page located at{' '}
          <a
            href="https://smart-pixl.com/Unsub/unsub.html"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500"
          >
            Smart Pixl Unsubscribe.
          </a>
        </p>
        <p>
          We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information
          unless we provide you with advance notice, except as described below. The term “outside parties” does not
          include PSXDigital. It also does not include website hosting partners and other parties who assist us in
          operating our website, conducting our business, or servicing you, so long as those parties agree to keep this
          information confidential. We may also release your information when we believe release is appropriate to
          comply with the law, enforce our site policies, or protect ours or others’ rights, property, or safety.
        </p>
        <p>
          However, non-personally identifiable visitor information may be provided to other parties for marketing,
          advertising, or other uses.
        </p>
        <h3>
          <strong>Third party links</strong>
        </h3>
        <p>
          In an attempt to provide you with increased value, we may include third party links on our site. These linked
          sites have separate and independent privacy policies. We therefore have no responsibility or liability for the
          content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and
          welcome any feedback about these linked sites (including if a specific link does not work).
        </p>
        <h3>
          <strong>Changes to our policy</strong>
        </h3>
        <p>If we decide to change our privacy policy, we will post those changes on this page.</p>
        <h3>
          <strong>Questions and feedback</strong>
        </h3>
        <p>
          We welcome your questions, comments, and concerns about privacy. Please send us any and all feedback
          pertaining to privacy, or any other issue.
        </p>
        <h3>
          <strong>Online Policy Only</strong>
        </h3>
        <p>
          This online privacy policy applies only to information collected through our website and not to information
          collected offline.
        </p>
        <h3>
          <strong>Your consent</strong>
        </h3>
        <p>By using our site, you consent to our&nbsp;Privacy Policy.</p>
        <h3>
          <strong>Accessibility Policy</strong>
        </h3>
        <p>
          We are committed to ensuring that our communications, including our website, are accessible to people with
          disabilities. We seek to ensure that all the pages on our website are designed to meet W3C Web Content
          Accessibility Guidelines (WCAG) 2.0, Level AA conformance. We review our website periodically to check and
          improve accessibility, and we favor third-party web content and technology providers based on their
          accessibility. If you face any barriers on our website, please do not hesitate to let us know so we can take
          corrective action immediately.
        </p>
      </div>
    </div>
  )
}

export default TermsPrivacy
