import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { STORAGE_KEYS } from 'src/constants/constants'
import { save } from 'src/lib/storage'
import * as AuthServices from 'src/services/auth.service'
import './style.scss'

export function DealerVerification({ verificationType }) {
  const { verificationToken, userRole, userId } = useParams()

  const navigate = useNavigate()
  const [isExpired, setIsExpired] = useState(false)

  useEffect(() => {
    if (verificationType !== 'forgot-password' && verificationToken) {
      AuthServices.dealershipActivation(verificationToken)
        .then((response) => {
          const { data } = response
          const { _id: employeeId, password_token: passwordToken } = data
          const employee = response.data
          employee.user_id = employeeId
          employee.verification_type = verificationType
          employee.password_token = passwordToken

          save(STORAGE_KEYS.VERIFICATION_DEALER_ACCOUNT, JSON.stringify(employee))
          if (verificationType === 'generate-password') {
            navigate(`/create-password/${employeeId}`)
          } else {
            navigate(`/claim/${employeeId}`)
          }
        })
        .catch((error) => {
          console.error(error)
          setIsExpired(true)
        })
    } else if (verificationType === 'forgot-password' && verificationToken && userRole && userId) {
      AuthServices.forgotPasswordCheck(verificationToken, userRole, userId)
        .then((response) => {
          const { data } = response
          const { _id: userId, user_role: userRole, password_token: passwordToken } = data
          const obj = response.data
          obj.user_id = userId
          obj.user_role = userRole
          obj.verification_type = verificationType
          obj.password_token = passwordToken
          save(STORAGE_KEYS.VERIFICATION_DEALER_ACCOUNT, JSON.stringify(obj))
          navigate(`/create-password/${userId}`)
        })
        .catch((error) => {
          console.error(error)
          setIsExpired(true)
        })
    } else {
      setIsExpired(true)
    }
  }, [verificationToken])

  if (!isExpired)
    return (
      <div style={{ fontSize: '32px' }} className="verification-expired-table">
        Verifying...
      </div>
    )

  return (
    <div className="verification-expired-table">
      <div style={{ padding: '0 35px;' }}>
        <h1 className="verification-expired-title">{`We're sorry...`}</h1>
        <span className="verification-expired-line" />
        <p className="verification-expired-description">BUT THIS LINK HAS EXPIRED.</p>
      </div>
    </div>
  )
}

DealerVerification.propTypes = {
  verificationType: PropTypes.string.isRequired
}
