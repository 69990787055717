import { notification } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import ContactDealerForm from 'src/components/ContactDealerForm'
import * as CustomerServices from 'src/services/customer.service'
import kelleyImage from '../../../assets/kbb-logo.png'
import AuthPopup from './AuthPopup'
import LoginFormModal from './LoginFormModal'
import SignUpFormModal from './SignUpFormModal'

import './style.scss'

function BoxButtonGroup({
  info,
  setIsCalculateTaxesModalVisible,
  isCalculateTaxesModalVisible,
  setInitStep,
  cashDown,
  loans,
  estimatedFinancePayment,
  loginRequired,
  setLoginRequired,
  setShowCashDownPopupConfirm,
  calculationId
}) {
  const { customerLogin } = useAuthorizationContext()
  const [showPopupConfirm, setShowPopupConfirm] = useState(false)
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [showSignUpForm, setShowSignUpForm] = useState(false)
  const [addedOffer, setAddedOffer] = useState(false)
  const showVehicleFinanceModal = () => {
    setIsCalculateTaxesModalVisible(true)
  }

  useEffect(() => {
    if (customerLogin === false && loginRequired) {
      setShowPopupConfirm(true)
    }
  }, [loginRequired])

  useEffect(() => {
    if (customerLogin && !isCalculateTaxesModalVisible) {
      CustomerServices.getCustomerOfferByVehicleId(info._id)
        .then(() => {
          setAddedOffer(true)
        })
        .catch((error) => {
          setAddedOffer(false)
          console.error(error)
        })
    }
  }, [customerLogin, isCalculateTaxesModalVisible])

  const handleAddTradeIn = () => {
    showVehicleFinanceModal()
    setInitStep(-2)
  }

  const openNotification = ({ message, description, type = 'success' }) => {
    const action = type === 'success' ? notification.success : notification.error
    action({
      message,
      description
    })
  }

  const handleShowLoginForm = () => {
    setShowPopupConfirm(false)
    setShowLoginForm(true)
    setLoginRequired(false)
    setShowCashDownPopupConfirm(false)
  }

  const handleShowSignUpForm = () => {
    setShowPopupConfirm(false)
    setShowSignUpForm(true)
    setLoginRequired(false)
    setShowCashDownPopupConfirm(false)
  }

  const callbackForForm = () => {}

  const continueMyEzDeal = () => {
    showVehicleFinanceModal()
  }

  const finishYourDeal = () => {
    showVehicleFinanceModal()
    setInitStep(15)
  }

  const handleAddToEzDealAccount = () => {
    const params = {
      vehicleId: info._id,
      cashdown: cashDown,
      frequency: `${loans} months`,
      name: `$${estimatedFinancePayment}/mo*`,
      calculationId
    }
    CustomerServices.saveOffer(params)
      .then((response) => {
        const { data, isSuccessful } = response
        const { status_code: statusCode, success, message } = data
        if (success || isSuccessful === 'Yes') {
          showVehicleFinanceModal()
        } else if (statusCode === 401) {
          setShowPopupConfirm(true)
        } else {
          openNotification({
            message: 'This vehicle has already been added to your Ez-Dealz account',
            description: message,
            type: 'error'
          })
          showVehicleFinanceModal()
        }
      })
      .catch(() =>
        openNotification({
          message: 'An error has occurred .Please try again later',
          type: 'error'
        })
      )
  }

  const hideOnProduction = process.env.REACT_APP_HIDE_ON_PRODUCTION === 'true'

  return (
    <>
      {!hideOnProduction && (
        <button
          type="button"
          className="relative bg-danger hover:bg-activeDanger rounded-lg px-2 py-3 outline-none text-base w-full text-white flex justify-center mb-5"
          onClick={handleAddTradeIn}
        >
          <span className="mr-8">+ Add Trade-In</span>
          <img src={kelleyImage} alt="" className="h-12 sm:h-14 absolute right-0 top-1/2 --translate-y" />
        </button>
      )}
      {addedOffer && (
        <button
          type="button"
          className="bg-primary hover:bg-activePrimary rounded-lg px-2 py-3 outline-none text-base w-full text-white flex justify-center mb-5"
          onClick={finishYourDeal}
        >
          Submit Your Offer
        </button>
      )}
      {!hideOnProduction && (
        <button
          type="button"
          className="border border-primary hover:bg-primary hover:text-white rounded-lg px-2 py-3 outline-none text-base w-full text-primary flex justify-center mb-8"
          onClick={addedOffer ? continueMyEzDeal : handleAddToEzDealAccount}
        >
          {addedOffer ? 'Continue my Ez-Deal' : 'Add to my Ez-Dealz account'}
        </button>
      )}
      {!hideOnProduction && (
        <p className="text-xs text-center">
          *Est. mo. pmt. based on adv. price of $18,995 at 5.77% APR for 60 mos w/ $1,900 down for qualified buyers.
          Terms may vary.
          <span className="cursor-pointer ml-1 text-primary font-semibold">See Details</span>
        </p>
      )}
      <div className="hidden lg:flex xl:flex">
        <ContactDealerForm info={info} />
      </div>
      <AuthPopup
        show={showPopupConfirm}
        setShow={setShowPopupConfirm}
        handleShowLoginForm={handleShowLoginForm}
        handleShowSignUpForm={handleShowSignUpForm}
        setLoginRequired={setLoginRequired}
      />
      <LoginFormModal
        showModal={showLoginForm}
        setShowModal={setShowLoginForm}
        callback={callbackForForm}
        setShowCashDownPopupConfirm={setShowCashDownPopupConfirm}
      />
      <SignUpFormModal
        showModal={showSignUpForm}
        setShowModal={setShowSignUpForm}
        callback={callbackForForm}
        setShowCashDownPopupConfirm={setShowCashDownPopupConfirm}
      />
    </>
  )
}

BoxButtonGroup.propTypes = {
  setIsCalculateTaxesModalVisible: PropTypes.func,
  isCalculateTaxesModalVisible: PropTypes.bool,
  info: PropTypes.object.isRequired,
  setInitStep: PropTypes.func,
  cashDown: PropTypes.any,
  loans: PropTypes.number,
  estimatedFinancePayment: PropTypes.number,
  loginRequired: PropTypes.bool,
  setLoginRequired: PropTypes.func,
  setShowCashDownPopupConfirm: PropTypes.func,
  calculationId: PropTypes.string
}

export default BoxButtonGroup
