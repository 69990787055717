import * as PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { STORAGE_KEYS } from 'src/constants/constants'
import { save } from 'src/lib/storage'
import './style.scss'

function DealershipHeader({ dealerInfo }) {
  const navigate = useNavigate()
  const redirectClaimYourBusinessPage = () => {
    const { contacts, dealer_name: name, _id, lat, lng } = dealerInfo
    const { address, city, phone, state, urls, zip } = contacts
    const startDealership = {
      address,
      city,
      email: '',
      dealerName: name,
      phone,
      state,
      powersportsDealerUrl: urls.powersports_dealer,
      zip,
      lat,
      lng,
      dealerId: _id
    }
    save(STORAGE_KEYS.START_STEP_CLAIM_YOUR_DEALERSHIP, '2')
    save(STORAGE_KEYS.START_DEALERSHIP_CLAIM_YOUR_DEALERSHIP, JSON.stringify(startDealership))
    navigate('/claim', { state: { dealerInfo } })
  }
  const sharedByDealership = sessionStorage.getItem(STORAGE_KEYS.IS_SHARED_BY_DEALERSHIP) === 'true'

  const getSalesLink = () => {
    if (dealerInfo?.contacts?.phone && dealerInfo?.contacts?.salesPhone) {
      const phone = dealerInfo.contacts.phone
      const salesPhone = dealerInfo.contacts.salesPhone
      return `<a href="tel:${salesPhone}">${phone}</a>`
    }
    return ''
  }

  return (
    <div className="flex items-center justify-between claim-delarship">
      {dealerInfo?.dealer_logo && (
        <div className="w-full">
          <img width={200} src={dealerInfo?.dealer_logo} alt="logo" />
        </div>
      )}
      <div className="mb-5">
        <span className="font-bold text-2xl">{dealerInfo?.dealer_name}</span>
        <div className="flex gap-4 mt-2 text-base">
          <div>
            <p>{dealerInfo?.contacts?.address}</p>
            {dealerInfo?._id && (
              <p>{`${dealerInfo?.contacts?.city}, ${dealerInfo?.contacts?.state} ${dealerInfo?.contacts?.zip}`}</p>
            )}
            <div className="flex gap-3 items-center">
              <a
                href={dealerInfo?.contacts?.urls?.powersports_dealer}
                target="_blank"
                rel="noreferrer"
                className="text-blue-700"
              >
                {dealerInfo?.contacts?.urls?.powersports_dealer || 'No available'}
              </a>
            </div>
            <div className="flex gap-3 sales-container">
              <p>
                <span className="font-bold">Sales:</span>
                <span>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: getSalesLink() }} />
                </span>
              </p>
              <p>
                <span className="font-bold">Service:</span>
                <span>{` ${dealerInfo?.contacts?.phone || ''}`}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        {!dealerInfo.is_new_claimed && !dealerInfo.is_claimed && !sharedByDealership && (
          <button
            onClick={redirectClaimYourBusinessPage}
            type="button"
            className="hover:bg-primary text-primary hover:text-white font-semibold rounded-lg p-4 outline-none text-lg w-full claim-your-business flex justify-center uppercase border bg-blue-100"
          >
            Claim this dealership
          </button>
        )}
        {dealerInfo.is_new_claimed && !dealerInfo.is_claimed && !sharedByDealership && (
          <button
            type="button"
            className="text-primary font-semibold rounded-lg p-4 outline-none text-lg w-full claim-your-business flex justify-center uppercase border bg-blue-100"
          >
            Claim pending
          </button>
        )}
      </div>
    </div>
  )
}

DealershipHeader.propTypes = {
  dealerInfo: PropTypes.shape({
    dealer_name: PropTypes.string,
    _id: PropTypes.number,
    lat: PropTypes.number,
    lng: PropTypes.number,
    urlFacebook: PropTypes.string,
    is_claimed: PropTypes.bool,
    is_new_claimed: PropTypes.bool,
    dealer_logo: PropTypes.string,
    contacts: PropTypes.shape({
      address: PropTypes.string,
      urls: PropTypes.object,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.number,
      phone: PropTypes.string,
      salesPhone: PropTypes.string
    }),
    logo: PropTypes.string
  }).isRequired
}

export default DealershipHeader
