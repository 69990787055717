import { Form } from 'antd'
import * as PropTypes from 'prop-types'
import React from 'react'
import NumberInput from 'src/components/InputComponent/NumberInput'

function AdditionalIncome({ additionalIncomeFormRef }) {
  return (
    <Form layout="vertical" ref={additionalIncomeFormRef} name="additional-income-ref">
      <div className="mt-4 step-4">
        <p className="text-2xl text-black font-bold mb-3">Additional Income</p>
        <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-0 xl:gap-6 lg:gap-5 md:gap-4">
          <Form.Item name="additionalAnnualIncome" label="Additional Annual Income" className="custom-antd-label block">
            <NumberInput
              autoFocus
              name="additionalAnnualIncome"
              placeholder="$"
              className="w-full border border-black rounded py-2 px-3"
            />
          </Form.Item>
          <Form.Item
            name="additionalIncomeSource"
            label="Additional Income Source"
            rules={[{ pattern: /^[a-zA-Z\s]+$/g, message: 'Additional Income Source consist only of alphabets' }]}
            className="custom-antd-label block"
          >
            <input name="additionalIncomeSource" className="w-full border border-black rounded py-2 px-3" type="text" />
          </Form.Item>
        </div>
      </div>
    </Form>
  )
}

AdditionalIncome.propTypes = {
  additionalIncomeFormRef: PropTypes.object
}

export default AdditionalIncome
