import HttpClientService from './httpClient.service'

export function getAdminDashboardInfo() {
  return HttpClientService.get('get-admin').then((result) => {
    const { data } = result
    return data
  })
}

export function getCreditScoreRateYears() {
  return HttpClientService.get('credit-score/rate-years').then((result) => {
    const { data } = result
    return data
  })
}

export function updateCreditScoreRateYearsRecord(params) {
  return HttpClientService.put('credit-score/rate-years/update-record', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getCreditScoreRangeRates() {
  return HttpClientService.get('credit-score/range-rates').then((result) => {
    const { data } = result
    return data
  })
}

export function updateCreditScoreRangeRatesRecord(params) {
  return HttpClientService.put('credit-score/range-rates/update-record', params).then((result) => {
    const { data } = result
    return data
  })
}

export function deleteMyInventoryVehicle(id) {
  return HttpClientService.put(`delete-vehicle-inventory/${id}`).then((result) => {
    const { data } = result
    return data
  })
}

export function updateShowNiadaDealersSetting(value) {
  const params = {
    show_niada_dealers: value
  }
  return HttpClientService.post('admin-configurable-settings-update-show-niada-dealers', params).then((result) => {
    const { data } = result
    return data
  })
}

export function updateShowPromoVideoSetting(value) {
  const params = {
    show_promo_video: value
  }
  return HttpClientService.post('admin-configurable-settings-update-show-promo-video', params).then((result) => {
    const { data } = result
    return data
  })
}

export function updatePsxAuthTokenSettings({ isActive, token }) {
  const params = {
    is_active: isActive,
    token
  }
  return HttpClientService.post('admin-configurable-settings-update-psx-auth-token', params).then((result) => {
    const { data } = result
    return data
  })
}

export function relatedDealershipsForSuperAdmin({ dealerId, keyword, page, perPage }) {
  return HttpClientService.post('related-dealerships-for-super-admin', {
    dealer_id: dealerId,
    keyword,
    page,
    per_page: perPage
  }).then((result) => {
    const { data } = result
    return data
  })
}

export function relatedDealershipsForSuperAdminByUserId({ id, keyword, page, perPage }) {
  return HttpClientService.post('related-dealerships-for-super-admin-by-user-id', {
    user_id: id,
    keyword,
    page,
    per_page: perPage
  }).then((result) => {
    const { data } = result
    return data
  })
}

export function toggleDealershipFromSuperAdmin({ dealerId, toggleDealerId, action }) {
  return HttpClientService.post('toggle-dealership-from-super-admin', {
    dealer_id: dealerId,
    toggle_dealer_id: toggleDealerId,
    action
  }).then((result) => {
    const { data } = result
    return data
  })
}

export function toggleDealershipFromSuperAdminByUserId({ id, toggleDealerId, action }) {
  return HttpClientService.post('toggle-dealership-from-super-admin-by-user-id', {
    user_id: id,
    toggle_dealer_id: toggleDealerId,
    action
  }).then((result) => {
    const { data } = result
    return data
  })
}
