import { Button, Form, Modal, notification } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import { STORAGE_KEYS, VALID_EMAIL } from 'src/constants/constants'
import { clear, save } from 'src/lib/storage'
import * as AuthService from 'src/services/auth.service'

const validPhoneNumber = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/

const userSetup = [
  {
    label: 'First Name',
    name: 'firstName',
    required: true,
    type: 'text'
  },
  {
    label: 'Last Name',
    name: 'lastName',
    required: true,
    type: 'text'
  },
  {
    label: 'Email',
    name: 'email',
    required: true,
    type: 'text'
  },
  {
    label: 'Create a password',
    name: 'password',
    required: true,
    type: 'password'
  },
  {
    label: 'Confirm password',
    name: 'confirmPassword',
    required: true,
    dependencies: ['password'],
    type: 'password'
  },
  {
    label: 'Phone Number',
    name: 'phone',
    required: false,
    type: 'number'
  },
  {
    label: 'Zip Code',
    name: 'zipCode',
    required: true,
    type: 'number'
  }
]

function SignUpFormModal({ showModal, setShowModal, callback, setShowCashDownPopupConfirm }) {
  const { handleCustomerAccountLogin, handleAccountSettingsLogin } = useAuthorizationContext()
  const [cookies, , removeCookie] = useCookies()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [form] = Form.useForm()
  const [, forceUpdate] = useState({})

  const openNotification = (type, errorMessage) => {
    const action = type === 'success' ? notification.success : notification.error
    const message = type === 'success' ? 'Sign up success' : errorMessage
    action({
      message,
      placement: 'topRight'
    })
  }

  useEffect(() => {
    forceUpdate({})
  }, [])

  const handleFormSubmission = (value) => {
    if (cookies.customer_cash_down) {
      value.cashDown = cookies.customer_cash_down
    }
    AuthService.customerSignup(value)
      .then((response) => {
        const { isSuccessful, data } = response
        if (isSuccessful === 'Yes') {
          const { access_token: accessToken, token_type: tokenType } = data
          const userSettings = JSON.stringify(data)
          save(STORAGE_KEYS.ACCESS_TOKEN, accessToken)
          save(STORAGE_KEYS.TOKEN_TYPE, tokenType)
          save(STORAGE_KEYS.CUSTOMER_LOGIN, 'true')
          save(STORAGE_KEYS.USER_SETTINGS, userSettings)
          clear(STORAGE_KEYS.FAVOURITE_CARS)
          handleCustomerAccountLogin(true)
          handleAccountSettingsLogin(userSettings)
          callback()
          setShowModal(false)
          setShowCashDownPopupConfirm(false)
          removeCookie('customer_cash_down')
          removeCookie('customer_zip_code')

          // we clear the shared dealership session
          sessionStorage.removeItem(STORAGE_KEYS.IS_SHARED_BY_DEALERSHIP)
          sessionStorage.removeItem(STORAGE_KEYS.SHARED_BY_DEALERSHIP_ID)
        } else {
          openNotification('error', data.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const validation = (form) => {
    const paternMessage = {
      pattern: /^[a-zA-Z0-9\s]+$/g,
      message: 'Alphanumeric string accepted'
    }
    switch (form.name) {
      case 'email':
        paternMessage.pattern = VALID_EMAIL
        paternMessage.message = 'Invalid message format'
        return paternMessage
      case 'confirmPassword':
        return ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('The two passwords that you entered do not match!'))
          }
        })
      case 'password':
        paternMessage.required = form.required
        paternMessage.pattern = /^(?=.*[\d])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,16}$/
        paternMessage.message =
          'Must Contain min 8 Characters, a Uppercase, a Lowercase, a Number and a Special Character from (!, ?, @, #, $, %, ^, &, *)'
        return paternMessage
      case 'phone':
        return () => ({
          validator(_, value) {
            if (!value || validPhoneNumber.test(value)) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('Invalid phone number!'))
          }
        })
      case 'zipCode':
        paternMessage.pattern = /^[0-9]{4,5}\s*$/
        paternMessage.message = 'Invalid zip code'
        return paternMessage
      default:
        return paternMessage
    }
  }

  const handleChangeShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleChangeShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  useEffect(() => {
    forceUpdate({})
    if (cookies.customer_zip_code && cookies.customer_zip_code > 0) {
      form.resetFields()
      form.setFieldsValue({
        zipCode: cookies.customer_zip_code
      })
    }
  }, [form, cookies])

  return (
    <Modal
      centered
      title="Sign up form"
      visible={showModal}
      closable
      onCancel={() => setShowModal(false)}
      className="calendar-modal my-5"
      width={450}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        name="vehicle-ref"
        className="mx-4 lg:px-8 items-center justify-center"
        onFinish={handleFormSubmission}
      >
        {userSetup.map((item, index) => {
          return (
            <Form.Item
              name={item.name}
              hasFeedback
              dependencies={item?.dependencies}
              rules={[
                {
                  required: item.required && !item.type === 'password',
                  message: `${item.label} is required`
                },
                validation(item)
              ]}
              className="mt-5"
              key={item.name}
            >
              {item.type === 'password' ? (
                <div className="relative border rounded-xl border-gray">
                  <input
                    placeholder={item.label}
                    name={item.name}
                    autoFocus={index === 0}
                    className={`w-full p-4 box-border rounded-xl border-gray-300 border focus:outline-none ${
                      item.type === 'password' && 'pr-8'
                    }`}
                    type={(item.name === 'password' ? showPassword : showConfirmPassword) ? 'text' : 'password'}
                  />
                  {item.name === 'password' &&
                    (showPassword ? (
                      <AiFillEyeInvisible
                        onClick={handleChangeShowPassword}
                        className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                      />
                    ) : (
                      <AiFillEye
                        onClick={handleChangeShowPassword}
                        className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                      />
                    ))}
                  {item.name === 'confirmPassword' &&
                    (showConfirmPassword ? (
                      <AiFillEyeInvisible
                        onClick={handleChangeShowConfirmPassword}
                        className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                      />
                    ) : (
                      <AiFillEye
                        onClick={handleChangeShowConfirmPassword}
                        className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                      />
                    ))}
                </div>
              ) : (
                <input
                  placeholder={item.label}
                  name={item.name}
                  autoFocus={index === 0}
                  className="w-full p-4 box-border rounded-xl border-gray-300 border focus:outline-none"
                  type={item.type}
                />
              )}
            </Form.Item>
          )
        })}

        <Form.Item className="mt-8 items-center" shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              className="w-full h-12 rounded-xl	"
              disabled={
                !form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length
              }
            >
              SIGN UP
            </Button>
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}

SignUpFormModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  callback: PropTypes.func,
  setShowCashDownPopupConfirm: PropTypes.func
}

export default SignUpFormModal
