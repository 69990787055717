import React, { useEffect, useState } from 'react'
import { Button, Pagination, Table } from 'antd'
import { useParams } from 'react-router-dom'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { SearchOutlined } from '@ant-design/icons'
import * as AdminService from '../../services/admin.service'
import './style.scss'

function AdminRelatedDealerships() {
  const { id } = useParams()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [keyword, setKeyword] = useState('')
  const [loading, setLoading] = useState(false)
  const [reloadDealers, setReloadDealers] = useState([])
  const [reloadDealersCount, setReloadDealersCount] = useState(0)
  const [relatedDealersCount, setRelatedDealersCount] = useState(0)

  useEffect(() => {
    if (id) {
      setLoading(true)
      AdminService.relatedDealershipsForSuperAdminByUserId({ id, keyword, page, perPage })
        .then((response) => {
          setReloadDealers(response.data.data)
          setReloadDealersCount(response.data.total)

          let countRelated = 0
          // eslint-disable-next-line array-callback-return
          response.data.data.map((item) => {
            if (item.is_related) {
              countRelated++
            }
          })
          setRelatedDealersCount(countRelated)
        })
        .catch((e) => {
          setReloadDealers([])
          setReloadDealersCount(0)
          setPage(1)
          setPerPage(20)
          setKeyword('')
          console.log(e)
        })
        .finally(() => setLoading(false))
    }
  }, [id, relatedDealersCount])

  const handleTableChange = (newPage, newPerPage) => {
    setLoading(true)
    setPage(newPage)
    setPerPage(newPerPage)
    AdminService.relatedDealershipsForSuperAdminByUserId({
      id,
      keyword,
      page: newPage,
      perPage: newPerPage
    })
      .then((response) => {
        setReloadDealers(response.data.data)
        setReloadDealersCount(response.data.total)

        let countRelated = 0
        // eslint-disable-next-line array-callback-return
        response.data.data.map((item) => {
          if (item.is_related) {
            countRelated++
          }
        })
        setRelatedDealersCount(countRelated)
      })
      .catch((e) => {
        setReloadDealers([])
        setReloadDealersCount(0)
        setPage(1)
        setPerPage(20)
        setKeyword('')
        console.log(e)
      })
      .finally(() => setLoading(false))
  }

  const handleRelateDealer = (toggleDealerId) => {
    AdminService.toggleDealershipFromSuperAdminByUserId({
      id,
      toggleDealerId,
      action: 'add'
    }).finally(() => handleTableChange(1, 20))
  }

  const handleUnRelateDealer = (toggleDealerId) => {
    AdminService.toggleDealershipFromSuperAdminByUserId({
      id,
      toggleDealerId,
      action: 'remove'
    }).finally(() => handleTableChange(1, 20))
  }

  const handleSearchChange = (e) => {
    const value = e.target.value
    setKeyword(value)
  }

  const pressEnterKey = (event) => {
    if (event.charCode === 13 && !loading) {
      handleTableChange(1, 20)
    }
  }

  const tableColumns = [
    {
      title: 'NAME',
      key: 'dealer_name',
      dataIndex: 'dealer_name'
    },
    {
      title: 'ZIP',
      key: 'zip',
      dataIndex: 'zip'
    },
    {
      title: 'ADDRESS',
      key: 'address',
      dataIndex: 'address'
    },
    {
      title: 'STATE',
      key: 'state',
      dataIndex: 'state'
    },
    {
      title: 'CITY',
      key: 'city',
      dataIndex: 'city'
    },
    {
      title: 'ACTION',
      key: 'dealer_id',
      render: (data) => {
        if (!data.is_related) {
          return (
            <Button
              type="primary"
              disabled={relatedDealersCount > 9}
              className="rounded-lg font-bold m-2"
              onClick={() => handleRelateDealer(data.dealer_id)}
            >
              Add to Related
            </Button>
          )
        }
        return (
          <Button
            type="primary"
            className="rounded-lg font-bold m-2"
            danger
            onClick={() => handleUnRelateDealer(data.dealer_id)}
          >
            Remove from Related
          </Button>
        )
      }
    }
  ]

  return loading ? (
    <div className="loader mx-auto my-44" />
  ) : (
    <div className="container">
      <div className="mx-auto mt-2 mb-12 pt-6 px-4 rounded-xl border-2">
        <div className="w-full flex items-center justify-center mb-5">
          <div className="relative w-10/12 lg:w-3/12 xl:w-3/12">
            <input
              onChange={handleSearchChange}
              className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
              type="text"
              placeholder="Search"
              defaultValue={keyword}
              onKeyPress={pressEnterKey}
            />
            {loading ? (
              <AiOutlineLoading3Quarters className="text-blue-500 absolute right-3 top-1/3 -translate-y-2/4 animate-spin" />
            ) : (
              <SearchOutlined
                onClick={() => handleTableChange(1, 20)}
                className="text-blue-500 absolute right-3 top-1/3 -translate-y-2/4"
              />
            )}
          </div>
        </div>
        <p className="font-semibold text-xl col-span-2 mb-3">Dealerships</p>
        <div>
          <Table
            scroll={{ x: 768, y: 500 }}
            loading={loading}
            showHeader
            className="components-table-demo-nested item-center admin-dealers"
            pagination={{ position: ['none', 'none'], pageSize: perPage }}
            columns={tableColumns}
            dataSource={reloadDealers}
          />
          <div className="flex justify-center mb-8 pagination-margin-top-20">
            <div className="flex items-center">
              <Pagination
                className="flex"
                current={page}
                responsive
                total={reloadDealersCount}
                pageSize={perPage}
                showSizeChanger={false}
                onChange={handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminRelatedDealerships
