import { CalendarOutlined } from '@ant-design/icons'
import { Calendar, Modal } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import * as CustomerServices from 'src/services/customer.service'
import './style.scss'

const times = ['morning', 'afternoon', 'evening']

function ScheduleDriveStep1({ nextScheduleStep, time, setTime, selectedData, setSelectedData, vehicleId }) {
  const [calendarDate, setCalendarDate] = useState(selectedData)
  const [calendarVisible, setCalendarVisible] = useState(false)

  const dateRange = Array.from({ length: 7 }, (_, i) =>
    moment.max(moment(selectedData).add(-3, 'd'), moment()).add(i, 'd')
  )

  const handleDateChange = (date) => {
    setSelectedData(date)
    setCalendarDate(date)
  }

  const onDateChange = (date) => {
    setCalendarDate(date)
  }

  const onCalendarOK = () => {
    setCalendarVisible(false)
    setSelectedData(calendarDate)
  }

  const onCalendarCancel = () => {
    setCalendarVisible(false)
  }

  const disabledDate = (current) => {
    return current && current < moment().endOf('day').subtract(1, 'days')
  }

  const handleNextStep = () => {
    nextScheduleStep()
    CustomerServices.handleSaveLeadTestDrive({
      time,
      vehicleId,
      step: 1,
      date: moment(selectedData).format('YYYY-MM-DD')
    }).catch((error) => {
      console.error(error)
    })
  }

  return (
    <>
      <p className="text-2xl text-black font-bold">Schedule a Test Drive</p>
      <p className="text-gray-400 text-base mb-8">
        Let us know the date and time you are available and we&apos;ll set it up for you.
      </p>
      <p className="text-black font-bold text-base mb-1">What Day Is Best?</p>
      <div className="flex justify-between mb-8">
        {dateRange.map((date, index) => (
          <button
            key={`date-${index}`}
            type="button"
            className={`w-1/9 shadow flex flex-col justify-center items-center bg-white rounded py-2 border-b-2 capitalize ${
              moment(moment(selectedData).format('YYYY-MM-DD')).isSame(moment(date).format('YYYY-MM-DD'))
                ? 'border-primary'
                : 'border-lightGray'
            }`}
            onClick={() => handleDateChange(date)}
          >
            <p className="text-black text-xs leading-3">{date.format('MMM')}</p>
            <p className="text-black text-base font-semibold">{date.format('DD')}</p>
            <p className="text-black text-xs leading-3">{date.format('ddd')}</p>
          </button>
        ))}
        <button
          type="button"
          className="w-1/9 px-3 leading-4 shadow flex flex-col justify-center items-center bg-white rounded py-2 text-primary text-xs"
          onClick={() => setCalendarVisible(true)}
        >
          <CalendarOutlined className="text-xl mb-1" />
          Pick a Date
        </button>
      </div>
      <p className="text-black font-bold text-base mb-1">What Time Of Day Works For You?</p>
      <div className="flex justify-between">
        {times.map((item, index) => (
          <button
            key={`time-${index}`}
            type="button"
            className={`w-1/4 shadow flex justify-center items-center bg-white rounded py-2 border-b-2 capitalize ${
              item === time ? 'border-primary' : 'border-lightGray'
            }`}
            onClick={() => setTime(item)}
          >
            {item}
          </button>
        ))}
      </div>
      <div className="flex justify-center mt-12 pb-5 xl:pb-2">
        <button
          type="button"
          className="bg-primary rounded text-white px-16 py-2 hover:bg-activePrimary"
          onClick={handleNextStep}
        >
          Next
        </button>
      </div>
      <Modal
        centered
        visible={calendarVisible}
        closable={false}
        onOk={onCalendarOK}
        onCancel={onCalendarCancel}
        className="calendar-modal"
        width={340}
        destroyOnClose
      >
        <div className="w-full">
          <Calendar fullscreen={false} onChange={onDateChange} value={calendarDate} disabledDate={disabledDate} />
        </div>
      </Modal>
    </>
  )
}

ScheduleDriveStep1.propTypes = {
  nextScheduleStep: PropTypes.func.isRequired,
  time: PropTypes.string,
  setTime: PropTypes.func,
  selectedData: PropTypes.any,
  setSelectedData: PropTypes.func,
  vehicleId: PropTypes.string
}

export default ScheduleDriveStep1
