import axios from 'axios'

const baseURL = process.env.REACT_APP_API_SERVER || 'http://localhost:8000/api'

const http = axios.create({ baseURL: `${baseURL}/` })

function get(url, headers = {}, params = {}) {
  return http.get(url, {
    ...params,
    headers: { ...headers }
  })
}

function post(url, data, headers = {}, params = {}) {
  return http.post(url, data, {
    ...params,
    headers: { ...headers }
  })
}

function put(url, data, headers = {}) {
  return http.put(url, data, {
    headers: { ...headers }
  })
}

function remove(url, data, headers = {}) {
  return http.delete(url, {
    headers: { ...headers },
    data
  })
}

export default {
  http,
  get,
  post,
  put,
  remove
}
