import HttpService from './http.service'
import HttpClientService from './httpClient.service'

export const loginWithEmail = ({ email, password, cashdown, zipcode, tradeIns, loginType, avatar, ip, fullName }) => {
  return HttpService.post('login-with-email', {
    email,
    password,
    cashdown,
    zipcode,
    trade_ins: tradeIns,
    login_type: loginType,
    avatar,
    ip,
    full_name: fullName
  }).then((result) => {
    const { data } = result
    return data
  })
}

export const customerLogin = ({ email, password, cashdown, zipcode, tradeIns, loginType, avatar, ip, fullName }) => {
  return HttpService.post('customer-login', {
    email,
    password,
    cashdown,
    zipcode,
    trade_ins: tradeIns,
    login_type: loginType,
    avatar,
    ip,
    full_name: fullName
  }).then((result) => {
    const { data } = result
    return data
  })
}

export const customerSignup = ({ phone, firstName, lastName, email, password, zipCode, cashDown, customerBudgetCalculator }) => {
  const params = {
    contacts: {
      phone: phone ? phone.toString() : undefined,
      name: {
        first: firstName,
        last: lastName
      },
      address: {
        zip: zipCode
      }
    },
    email,
    password,
    cash_down: cashDown,
    customer_budget_calculator: customerBudgetCalculator
  }
  return HttpService.post('customer-signup', params)
    .then((result) => {
      const { data } = result
      return data
    })
    .catch((error) => {
      return error.response
    })
}

export const dealershipLogin = ({ email, password, role }) => {
  return HttpService.post('employee-login', { email, password, role }).then((result) => {
    const { data } = result
    return data
  })
}

export const LoginForm = ({ contactName, contactEmail, contactMessage }) => {
  return HttpService.post('contact-form', { contactName, contactEmail, contactMessage }).then((result) => {
    const { data } = result
    return data
  })
}

export const dealershipLoginCreatePassword = ({
  username,
  employeeId,
  password,
  repeatPassword,
  cpoProvider,
  passwordToken
}) => {
  const params = {
    username,
    employee_id: employeeId,
    password,
    password_confirmation: repeatPassword,
    cpo_provider: cpoProvider,
    password_token: passwordToken
  }
  return HttpService.post('employee-create-password', params).then((result) => {
    const { data } = result
    return data
  })
}

export const dealershipSignup = (payload) => {
  return HttpService.post('employees', payload)
    .then((result) => {
      const { data } = result
      return data
    })
    .catch((e) => {
      throw new Error(e.response?.data?.message || 'Error')
    })
}

export const dealershipSaveClaimProcessStep = (payload) => {
  return HttpService.post('dealership-save-claim-process-step', payload).then((result) => {
    const { data } = result
    return data
  })
}

export const dealershipActivation = (verificationToken) => {
  return HttpService.get(`employee/activation/${verificationToken}`).then((result) => {
    const { data } = result
    return data
  })
}

export const adminLogin = ({ email, password }) => {
  return HttpService.post('admin-login', { email, password }).then((result) => {
    const { data } = result
    return data
  })
}

export const userLogin = ({ email, password, role }) => {
  return HttpService.post('user-login', { email, password, role }).then((result) => {
    const { data } = result
    return data
  })
}

export function userLogout() {
  return HttpClientService.get('user-logout').then((result) => {
    const { data } = result
    return data
  })
}

export const forgotPassword = ({ email }) => {
  return HttpService.post('forgot-password', { email }).then((result) => {
    const { data } = result
    return data
  })
}

export const forgotPasswordCheck = (verificationToken, userRole, userId) => {
  return HttpService.post('forgot-password-check', {
    verification_token: verificationToken,
    user_role: userRole,
    user_id: userId
  }).then((result) => {
    const { data } = result
    return data
  })
}

export const loginCreatePassword = ({ username, userId, password, repeatPassword, userRole, passwordToken }) => {
  const params = {
    username,
    user_id: userId,
    password,
    password_confirmation: repeatPassword,
    user_role: userRole,
    password_token: passwordToken
  }
  return HttpService.post('login-create-password', params).then((result) => {
    const { data } = result
    return data
  })
}

export const checkValidAccount = (email) => {
  return HttpService.post('employees/check-valid-account', { email }).then((result) => {
    const { data } = result
    return data
  })
}
