import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Collapse } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { KBB_VEHICLE_TYPE } from 'src/constants/constants'
import { flatten, groupBy } from 'src/utils'
import * as KBBPowersAutomobileServices from '../../../services/kbb-automobile.service'
import './style.scss'

const { Panel } = Collapse

const expandIcon = ({ isActive }) =>
  isActive ? <MinusOutlined className="text-primary" /> : <PlusOutlined className="text-primary" />

const extraLabel = (items) => (
  <p className="font-xs font-semibold mr-1">
    Selected:{' '}
    {items.filter((item) => item.selected).length ? (
      <span className="font-xs font-normal">
        {items
          .filter((item) => item.selected)
          .map((item) => item.optionName)
          .join()}
      </span>
    ) : (
      <span className="font-xs font-normal">Default</span>
    )}
  </p>
)

function VehicleFinanceStep5({
  tradeVehicleInfo,
  nextStep,
  backStep,
  vehicleType,
  setFinalConfiguration,
  color
  // selectedOtherColor
}) {
  const { year, make, model, yearId, makeName, modelName, vehicleId } = tradeVehicleInfo
  const [equipments, setEquipments] = useState({})
  const [status, setStatus] = useState('hide')
  const [activeKey, setActiveKey] = useState([])

  const handleViewChange = (type) => {
    if (type === 'show') {
      const numbers = Object.keys(equipments)
      setActiveKey(numbers)
    } else {
      setActiveKey([])
    }
    setStatus(type)
  }

  const handleGetVehicleOptions = () => {
    KBBPowersAutomobileServices.getVehicleOptions({ vehicleId })
      .then((response) => {
        const equipments = groupBy(response.data, 'categoryGroup')
        const defaultExteriorColor = {
          selected: true,
          categoryGroup: 'Exterior Color',
          categoryName: 'Main Body Color',
          hasRelationships: true,
          isConfigurable: true,
          isConsumer: true,
          isTypical: false,
          optionName: color,
          optionType: 'Color',
          vehicleOptionId: 'otherColorId'
        }
        let containOtherColor = false
        let exteriorColorOptions = [defaultExteriorColor]
        if (equipments['Exterior Color'] !== undefined) {
          exteriorColorOptions = equipments['Exterior Color'].map((item) => {
            if (item.optionName === color) {
              containOtherColor = true
              return { ...item, selected: true }
            }
            return { ...item, selected: false }
          })
        }
        if (!containOtherColor) {
          exteriorColorOptions.unshift(defaultExteriorColor)
        }
        equipments['Exterior Color'] = exteriorColorOptions
        setEquipments(equipments)
      })
      .catch((error) => console.log(error))
  }

  const handleCollapseChange = (panel) => {
    setActiveKey(panel)
  }

  const handleNext = () => {
    if (vehicleType === KBB_VEHICLE_TYPE.AUTOMOTIVE) {
      const vehicleOptionIds = []
      const configurationChanges = []
      flatten(Object.values(equipments)).forEach((obj, index) => {
        if (obj.vehicleOptionId !== 'otherColorId') {
          vehicleOptionIds.push(obj.vehicleOptionId)
          configurationChanges.push({
            sequence: index + 1,
            vehicleOptionId: obj.vehicleOptionId,
            action: obj.selected ? 'selected' : 'deselected'
          })
        }
      })
      KBBPowersAutomobileServices.postApplyConfiguration({ vehicleId, vehicleOptionIds, configurationChanges })
        .then((response) => {
          const { finalConfiguration } = response.data
          setFinalConfiguration(finalConfiguration)
          nextStep()
        })
        .catch((error) => console.log(error))
    } else {
      nextStep()
    }
  }

  const handleToggle = (item, key) => {
    const updatedList = equipments[key].map((obj) => {
      if (obj.vehicleOptionId === item.vehicleOptionId) {
        return { ...obj, selected: !item.selected }
      }
      return obj
    })
    setEquipments({
      ...equipments,
      [key]: updatedList
    })
  }

  useEffect(() => {
    if (vehicleType === KBB_VEHICLE_TYPE.AUTOMOTIVE) {
      handleGetVehicleOptions()
    }
  }, [vehicleType])

  const yearDisplay = year || yearId || ''
  const makeDisplay = make || makeName || ''
  const modelDisplay = model || modelName || ''

  return (
    <div className="mt-8 step-5">
      <p className="text-2xl text-black font-bold">What Options Are On Your Car?</p>
      <p className="text-gray-400 text-base mb-3">
        {`To save you time we've pre-selected the standard options for your ${yearDisplay} ${makeDisplay} ${modelDisplay}.`}
      </p>
      <div className="flex justify-end">
        <div className="flex p-1 bg-gray-300 rounded">
          <button
            type="button"
            className={`text-sm px-3 ${status === 'show' ? 'bg-white text-primary' : 'text-darkGray'}`}
            onClick={() => handleViewChange('show')}
          >
            Show All
          </button>
          <button
            type="button"
            className={`text-sm px-3 ${status === 'hide' ? 'bg-white text-primary' : 'text-darkGray'}`}
            onClick={() => handleViewChange('hide')}
          >
            Hide All
          </button>
        </div>
      </div>
      <Collapse
        expandIconPosition="right"
        className="equipment-collapse"
        expandIcon={expandIcon}
        activeKey={activeKey}
        onChange={handleCollapseChange}
      >
        {Object.keys(equipments).map((key) => {
          return (
            <Panel header={key} extra={extraLabel(equipments[key])} key={key}>
              <div className="grid grid-cols-2 gap-5">
                {equipments[key].map((item, id) => (
                  <button
                    type="button"
                    className={`flex justify-center items-center bg-white rounded py-2 border-b-2 ${
                      item.selected ? 'border-primary' : 'border-lightGray'
                    }`}
                    key={`panel-button-${id}`}
                    onClick={() => handleToggle(item, key)}
                  >
                    {item.selected && (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="12" className="mr-1">
                        <path
                          fill="#2676d9"
                          d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                        />
                      </svg>
                    )}
                    {item.optionName}
                  </button>
                ))}
              </div>
            </Panel>
          )
        })}
      </Collapse>
      <div className="flex justify-around mt-12">
        <button type="button" className="bg-gray-500 rounded text-white px-16 py-2" onClick={backStep}>
          Back
        </button>
        <button
          type="button"
          className="bg-primary rounded text-white px-16 py-2 hover:bg-activePrimary"
          onClick={handleNext}
        >
          Next
        </button>
      </div>
    </div>
  )
}

VehicleFinanceStep5.propTypes = {
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  tradeVehicleInfo: PropTypes.object.isRequired,
  vehicleType: PropTypes.string,
  setFinalConfiguration: PropTypes.func,
  color: PropTypes.string
  // selectedOtherColor: PropTypes.bool
}

export default VehicleFinanceStep5
