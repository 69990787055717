import { STORAGE_KEYS } from 'src/constants/constants'

export function getPublicIp() {
  return fetch('https://gregeoip.com/ip')
    .then((data) => data.text())
    .then((data) => {
      const response = JSON.parse(data)
      let ip = '127.0.0.1'
      if (response.status === 'success') {
        ip = response.data
      }
      return ip
    })
}

export const checkIsEmptyData = (value) => {
  return !value || value === '-'
}

export const groupBy = (objectArray, property) => {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property]
    if (!acc[key]) {
      acc[key] = [{ ...obj, selected: true }]
    } else {
      acc[key].push(obj)
    }
    return acc
  }, {})
}

export const flatten = (arr) => {
  return arr.reduce((flat, toFlatten) => {
    return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten)
  }, [])
}

export const sharedByDealership = sessionStorage.getItem(STORAGE_KEYS.IS_SHARED_BY_DEALERSHIP) === 'true'
export const dealershipId = sessionStorage.getItem(STORAGE_KEYS.SHARED_BY_DEALERSHIP_ID)

export const getHomeUrl = ({ sharedByDealership, dealershipId }) => {
  return sharedByDealership ? `/dealership/${dealershipId}` : '/'
}

export function getSingleVehicleUrl(vehicleUrl) {
  if (vehicleUrl && sharedByDealership) {
    let url = vehicleUrl.split(`${window.location.origin}/vehicles`)[1]
    // let url = vehicleUrl.split('https://dev.motormarketusa.com/vehicles')[1]
    if (url) {
      url = `/shared-vehicles${url}`
    }
    return url || `/dealership/${dealershipId}`
  }
  if (vehicleUrl) {
    return vehicleUrl.split(window.location.origin)[1] || '/'
  }
  return '/'
}

export function generateSearchURL(params) {
  let result = '/buy'
  const values = Object.values(params)
  for (let index = 0; index < values.length; ++index) {
    if (values[index] && typeof values[index] === 'string') {
      result += `/${values[index]}`
    } else if (typeof values[index] === 'object') {
      if (values[index]?.length === 1) {
        result += `/${values[index][0]}`
      } else if (!Array.isArray(values[index]) && values[index]?.min && values[index]?.min === values[index]?.max) {
        result += `/${values[index].min}`
      }
    }
  }
  return result
}

export function phoneFormat(str) {
  return str && str.replace(/\D+/g, '').replace(/^(\d{3})(\d{3})(\d{4}).*/, '$1-$2-$3')
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function maskNumberThousands(newValue, isNumber) {
  const amt = Number(newValue)

  if (isNumber) {
    return (amt && amt.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })) || '0'
  }

  return (amt && amt.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })) || '0.00'
}

export function formatZipCode(zipCode) {
  let formattedZipCode = zipCode

  if (zipCode < 1000) {
    formattedZipCode = `00${zipCode}`
  } else if (zipCode > 1000 && zipCode < 10000) {
    formattedZipCode = `0${zipCode}`
  }

  return formattedZipCode
}
