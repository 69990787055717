import { Button, Checkbox, Form } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { BiChevronLeft } from 'react-icons/bi'
import * as ClaimService from 'src/services/claim.service'
import { newPriceTables } from '../mockPrice'
import PaymentDetails from './PaymentDetails'
import * as DealerService from '../../../services/dealer.service'
import LogoMMUSA from '../../../assets/new-login-logo.png'
import LogoNiada from '../../../assets/logo_niada.png'
import LogoNiadaAlabama from '../../../assets/logo_niada_alabama.png'
import LogoNiadaAlaska from '../../../assets/logo_niada_alaska.png'
import LogoNiadaArizona from '../../../assets/logo_niada_arizona.png'
import LogoNiadaArkansas from '../../../assets/logo_niada_arkansas.png'
import LogoNiadaCalifornia from '../../../assets/logo_niada_california.png'
import LogoNiadaColorado from '../../../assets/logo_niada_colorado.png'
import LogoNiadaCarolinas from '../../../assets/logo_niada_carolinas.png'
import LogoNiadaFlorida from '../../../assets/logo_niada_florida.png'
import LogoNiadaGeorgia from '../../../assets/logo_niada_georgia.png'
import LogoNiadaHawaii from '../../../assets/logo_niada_hawaii.png'
import LogoNiadaIdaho from '../../../assets/logo_niada_idaho.png'
import LogoNiadaIllinois from '../../../assets/logo_niada_illinois.png'
import LogoNiadaIndiana from '../../../assets/logo_niada_indiana.png'
import LogoNiadaIowa from '../../../assets/logo_niada_iowa.png'
import LogoNiadaKansas from '../../../assets/logo_niada_kansas.png'
import LogoNiadaMassachusetts from '../../../assets/logo_niada_massachusetts.png'
import LogoNiadaMichigan from '../../../assets/logo_niada_michigan.png'
import LogoNiadaMidatlantic from '../../../assets/logo_niada_midatlantic.png'
import LogoNiadaMississippi from '../../../assets/logo_niada_mississippi.png'
import LogoNiadaMissouri from '../../../assets/logo_niada_missouri.png'
import LogoNiadaMontana from '../../../assets/logo_niada_montana.png'
import LogoNiadaNebraska from '../../../assets/logo_niada_nebraska.png'
import LogoNiadaNevada from '../../../assets/logo_niada_nevada.png'
import LogoNiadaNewHampshire from '../../../assets/logo_niada_new_hampshire.png'
import LogoNiadaNewJersey from '../../../assets/logo_niada_new_jersey.png'
import LogoNiadaNewYork from '../../../assets/logo_niada_new_york.png'
import LogoNiadaNorthland from '../../../assets/logo_niada_northland.png'
import LogoNiadaOhio from '../../../assets/logo_niada_ohio.png'
import LogoNiadaOregon from '../../../assets/logo_niada_oregon.png'
import LogoNiadaSouthDakota from '../../../assets/logo_niada_south_dakota.png'
import LogoNiadaTennessee from '../../../assets/logo_niada_tennessee.png'
import LogoNiadaTexas from '../../../assets/logo_niada_texas.png'
import LogoNiadaUtah from '../../../assets/logo_niada_utah.png'
import LogoNiadaVirginia from '../../../assets/logo_niada_virginia.png'
import LogoNiadaWashington from '../../../assets/logo_niada_washington.png'
import LogoNiadaWisconsin from '../../../assets/logo_niada_wisconsin.png'
import LogoNiadaWyoming from '../../../assets/logo_niada_wyoming.png'
import './style.scss'

const basicPriceKeysList = ['month to Month', 12, 24, 36]
const enhancementPriceKeysList = [
  {
    id: 'ilmPrice',
    name: 'Internet Lead Manager (ILM) Pro'
  },
  {
    id: 'partyLeadIntegrationPirce',
    name: '3rd Party Lead Integration'
  },
  {
    id: 'smsPackagePrice',
    name: 'SMS Messaging'
  },
  {
    id: 'callPackagePrice',
    name: 'Call tracking and Recording'
  },
  {
    id: 'customStorePrice',
    name: 'Custom MMUSA Store'
  },
  {
    id: 'fiMyRidePrice',
    name: 'FiMyRide Credit & Finance'
  },
  {
    id: 'integratedCustomWebSitePrice',
    name: 'MMUSA Integrated website'
  },
  {
    id: 'marketingAutomationPrice',
    name: 'MMUSA Integrated MKT automation'
  }
]

function BasicInventoryListingEnrollment({
  dealershipInfo,
  backStep,
  nextStep,
  setPaymentInfo,
  getClaimBusinessPayloadMailAdapter,
  step
}) {
  const [form] = Form.useForm()
  const [selectedPrice, setSelectedPrice] = useState('month to Month')
  const [selectedEnhancementPrices, setSelectedEnhancementPrices] = useState([])
  const [notEnhancement, setNotEnhancement] = useState(false)
  const [priceTableList, setPriceTableList] = useState(newPriceTables.data)
  const [paymentMethod, setPaymentMethod] = useState('ACH')
  const [paymentDetails, setPaymentDetails] = useState({})
  let { isNiadaMember, vehicleQuantity } = dealershipInfo
  const [showListMyInventory, setIsShowListMyInventory] = useState(false)
  const [showTotalPrice, setShowTotalPrice] = useState(false)
  const [memberPrice, setMemberPrice] = useState(null)

  const payload = getClaimBusinessPayloadMailAdapter()
  const stateIso = payload?.contacts?.state || ''

  const memberNumber = dealershipInfo.memberNumber ?? ''

  isNiadaMember = isNiadaMember || 'yes'
  vehicleQuantity = vehicleQuantity || '<30'
  const isMember = isNiadaMember === 'yes'

  useEffect(() => {
    if (memberNumber !== undefined || (memberNumber !== '' && stateIso.length > 0)) {
      DealerService.getMemberPriceByStateISO(stateIso).then((response) => {
        setMemberPrice(response.data)
      })
    }
  }, [memberNumber, dealershipInfo])

  const pricesObject = useMemo(() => {
    const basicPriceObject = priceTableList.find(
      (item) => item.type === 'basic' && item.average_vehicles_stock === vehicleQuantity
    )
    const ilmPriceObject = priceTableList.find(
      (item) => item.type === 'ILM' && item.average_vehicles_stock === vehicleQuantity
    )

    const partyLeadIntegrationPirce = priceTableList.find(
      (item) => item.type === '3rd Party Lead Integration' && item.average_vehicles_stock === vehicleQuantity
    )

    const smsPackagePrice = priceTableList.find(
      (item) => item.type === 'SMS Package' && item.average_vehicles_stock === vehicleQuantity
    )

    const callPackagePrice = priceTableList.find(
      (item) => item.type === 'Call Package' && item.average_vehicles_stock === vehicleQuantity
    )

    const customStorePrice = priceTableList.find(
      (item) => item.type === 'MMUSA Custom Store' && item.average_vehicles_stock === vehicleQuantity
    )

    const fiMyRidePrice = priceTableList.find(
      (item) => item.type === 'FiMyRide' && item.average_vehicles_stock === vehicleQuantity
    )

    const integratedCustomWebSitePrice = priceTableList.find(
      (item) => item.type === 'Integrated Custom Web Site' && item.average_vehicles_stock === vehicleQuantity
    )

    const marketingAutomationPrice = priceTableList.find(
      (item) => item.type === 'Marketing Automation' && item.average_vehicles_stock === vehicleQuantity
    )

    return {
      basicPrice: isMember ? basicPriceObject?.pricing_table?.member : basicPriceObject?.pricing_table?.none,
      ilmPrice: isMember ? ilmPriceObject?.pricing_table?.member : ilmPriceObject?.pricing_table?.none,
      partyLeadIntegrationPirce: isMember
        ? partyLeadIntegrationPirce?.pricing_table?.member
        : partyLeadIntegrationPirce?.pricing_table?.none,
      smsPackagePrice: isMember ? smsPackagePrice?.pricing_table?.member : smsPackagePrice?.pricing_table?.none,
      callPackagePrice: isMember ? callPackagePrice?.pricing_table?.member : callPackagePrice?.pricing_table?.none,
      customStorePrice: isMember ? customStorePrice?.pricing_table?.member : customStorePrice?.pricing_table?.none,
      fiMyRidePrice: isMember ? fiMyRidePrice?.pricing_table?.member : fiMyRidePrice?.pricing_table?.none,
      integratedCustomWebSitePrice: isMember
        ? integratedCustomWebSitePrice?.pricing_table?.member
        : integratedCustomWebSitePrice?.pricing_table?.none,
      marketingAutomationPrice: isMember
        ? marketingAutomationPrice?.pricing_table?.member
        : marketingAutomationPrice?.pricing_table?.none
    }
  }, [priceTableList, vehicleQuantity, isMember])

  const { basicPrice } = pricesObject

  const handleCheckboxMonthly = (values) => {
    setSelectedEnhancementPrices(values)
  }

  const handleChangeNotEnhancement = (event) => {
    const checked = event.target.checked
    setNotEnhancement(checked)
    if (checked) {
      setSelectedEnhancementPrices([])
    }
  }

  const totalMonthly = useMemo(() => {
    if (isMember && memberPrice) {
      return memberPrice.member_price
    }
    const bill = basicPrice?.[selectedPrice] || 0
    if (notEnhancement) {
      return typeof bill === 'string' ? 'custom' : bill
    }
    return selectedEnhancementPrices.reduce((total, current) => {
      const setupPrice = pricesObject[current]?.setup || pricesObject[current]?.setUp || 0
      let monthlyPrice = pricesObject[current]?.[selectedPrice] || pricesObject[current]?.['month to Month'] || 0
      if (current === 'fiMyRidePrice') {
        monthlyPrice = pricesObject[current]['App month to Month'] + pricesObject[current]['Per month to Month']
      }
      // if any prices is string => return "custom" text
      if (typeof total === 'string' || typeof monthlyPrice === 'string' || typeof setupPrice === 'string') {
        return 'custom'
      }
      if (typeof setupPrice === 'number' && typeof monthlyPrice === 'number') {
        return total + setupPrice + monthlyPrice
      }
      return total
    }, bill)
  }, [basicPrice, selectedPrice, selectedEnhancementPrices, pricesObject, notEnhancement])

  const isCustomPrice = typeof totalMonthly === 'string'

  const onNextStep = async () => {
    await form
      .validateFields()
      .then(async () => {
        setPaymentInfo({
          selectedPrice,
          totalMonthly,
          paymentMethod,
          paymentDetails
        })
        nextStep()
      })
      .catch(() => {})
  }

  const onClickListMyInventory = () => {
    setIsShowListMyInventory(true)
  }

  useEffect(() => {
    setIsShowListMyInventory(false)
    setShowTotalPrice(false)
    setSelectedEnhancementPrices(['sms_pkg', 'mmusa_custom_store'])
  }, [selectedPrice])

  useEffect(() => {
    setIsShowListMyInventory(false)
    setShowTotalPrice(false)
  }, [step])

  useEffect(() => {
    ClaimService.getPricingTable()
      .then((response) => {
        setPriceTableList(response.data)
      })
      .catch((error) => console.error(error))
  }, [])

  useEffect(() => {
    setShowTotalPrice(false)
  }, [JSON.stringify(selectedEnhancementPrices)])

  const getNiadaImage = (marker) => {
    let image = LogoNiada
    if (marker === 'logo_niada_alabama') {
      image = LogoNiadaAlabama
    }
    if (marker === 'logo_niada_alaska') {
      image = LogoNiadaAlaska
    }
    if (marker === 'logo_niada_arizona') {
      image = LogoNiadaArizona
    }
    if (marker === 'logo_niada_arkansas') {
      image = LogoNiadaArkansas
    }
    if (marker === 'logo_niada_california') {
      image = LogoNiadaCalifornia
    }
    if (marker === 'logo_niada_colorado') {
      image = LogoNiadaColorado
    }
    if (marker === 'logo_niada_carolinas') {
      image = LogoNiadaCarolinas
    }
    if (marker === 'logo_niada_florida') {
      image = LogoNiadaFlorida
    }
    if (marker === 'logo_niada_georgia') {
      image = LogoNiadaGeorgia
    }
    if (marker === 'logo_niada_hawaii') {
      image = LogoNiadaHawaii
    }
    if (marker === 'logo_niada_idaho') {
      image = LogoNiadaIdaho
    }
    if (marker === 'logo_niada_illinois') {
      image = LogoNiadaIllinois
    }
    if (marker === 'logo_niada_indiana') {
      image = LogoNiadaIndiana
    }
    if (marker === 'logo_niada_kansas') {
      image = LogoNiadaKansas
    }
    if (marker === 'logo_niada_iowa') {
      image = LogoNiadaIowa
    }
    if (marker === 'logo_niada_massachusetts') {
      image = LogoNiadaMassachusetts
    }
    if (marker === 'logo_niada_michigan') {
      image = LogoNiadaMichigan
    }
    if (marker === 'logo_niada_midatlantic') {
      image = LogoNiadaMidatlantic
    }
    if (marker === 'logo_niada_mississippi') {
      image = LogoNiadaMississippi
    }
    if (marker === 'logo_niada_missouri') {
      image = LogoNiadaMissouri
    }
    if (marker === 'logo_niada_montana') {
      image = LogoNiadaMontana
    }
    if (marker === 'logo_niada_nebraska') {
      image = LogoNiadaNebraska
    }
    if (marker === 'logo_niada_nevada') {
      image = LogoNiadaNevada
    }
    if (marker === 'logo_niada_new_hampshire') {
      image = LogoNiadaNewHampshire
    }
    if (marker === 'logo_niada_new_jersey') {
      image = LogoNiadaNewJersey
    }
    if (marker === 'logo_niada_new_york') {
      image = LogoNiadaNewYork
    }
    if (marker === 'logo_niada_northland') {
      image = LogoNiadaNorthland
    }
    if (marker === 'logo_niada_ohio') {
      image = LogoNiadaOhio
    }
    if (marker === 'logo_niada_oregon') {
      image = LogoNiadaOregon
    }
    if (marker === 'logo_niada_south_dakota') {
      image = LogoNiadaSouthDakota
    }
    if (marker === 'logo_niada_tennessee') {
      image = LogoNiadaTennessee
    }
    if (marker === 'logo_niada_texas') {
      image = LogoNiadaTexas
    }
    if (marker === 'logo_niada_utah') {
      image = LogoNiadaUtah
    }
    if (marker === 'logo_niada_virginia') {
      image = LogoNiadaVirginia
    }
    if (marker === 'logo_niada_washington') {
      image = LogoNiadaWashington
    }
    if (marker === 'logo_niada_wisconsin') {
      image = LogoNiadaWisconsin
    }
    if (marker === 'logo_niada_wyoming') {
      image = LogoNiadaWyoming
    }

    return image
  }

  return (
    <div className="mt-14 ml-0 xl:ml-10 lg:ml-10">
      <div className="font-bold mb-5 text-lg">Basic Inventory Listing</div>
      <div className="flex flex-col lg:flex-row gap-5 items-center">
        <div className="field-label w-full flex-1 lg:w-40 text-center lg:text-left">
          {`Price for ${isMember ? 'members' : 'non-members'}:`}
          {!isMember && <div className="font-normal">Includes your State Association and NIADA Membership</div>}
        </div>

        {!isMember && (
          <div className="flex flex-col items-center lg:flex-row">
            <div className="overflow-y-hidden lg:flex lg:justify-start grid grid-cols-2 gap-2 flex-wrap">
              {basicPriceKeysList.map((key) => (
                <div
                  key={key}
                  className={`w-40 lg:w-36 h-28 cursor-pointer bg-blue-50 rounded-lg flex justify-center items-center px-2 ${
                    selectedPrice === key ? 'border-primary border-2' : ''
                  }`}
                  onClick={() => setSelectedPrice(key)}
                >
                  <div className="flex flex-col gap-2 text-center">
                    <div className={`text-base ${selectedPrice === key ? 'font-bold' : ''}`}>
                      {key === 'month to Month' ? 'Month 2 Month' : `${key} Months`}
                    </div>
                    <div className="text-primary-color text-lg font-bold">
                      {typeof basicPrice[key] === 'string' ? 'Custom Pricing' : `$${basicPrice[key]}`}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {isMember && memberPrice && (
          <div>
            <div className="grid grid-cols-3 ">
              <div className="grid grid-cols-1 gap-x-5">
                <div className="flex items-center">
                  <img src={LogoMMUSA} alt="Logo MMUSA" />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-x-5 pl-7">
                <div className="flex items-center text-gray-600">Retail Price:</div>
              </div>
              <div className="grid grid-cols-1 gap-x-5">
                <div className="flex items-center font-semibold pl-7">{`$${memberPrice.retail_price}/mo.`}</div>
              </div>
            </div>
            <div className="grid grid-cols-3 mt-3">
              <div className="grid grid-cols-1">
                <div className="flex items-center">
                  <img src={LogoNiada} alt="Logo Niada" />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-x-5 pl-7">
                <div className="flex items-center text-gray-600">Discount:</div>
              </div>
              <div className="grid grid-cols-1 gap-x-5">
                <div className="flex items-center font-bold pl-7">{`$${memberPrice.discount_niada}/mo.`}</div>
              </div>
            </div>
            <div className="grid grid-cols-3 mt-3">
              <div className="grid grid-cols-1">
                <div className="flex items-center">
                  <img src={getNiadaImage(memberPrice.logo_dues)} alt="Logo Dues" />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-x-5 pl-7">
                <span className="text-gray-600">Discount:</span>
                <span className="font-bold">{`$${memberPrice.discount_association}`}</span>
                <span className="font-bold fontSize24">+</span>
                <span className="text-gray-600">Dues Credit:</span>
                <span className="font-bold">{`$${memberPrice.dues_credit}`}</span>
              </div>
              <div className="grid grid-cols-1 gap-x-5">
                <div className="flex items-center font-bold pl-7">{`$${memberPrice.const_one_zero_one}/mo.`}</div>
              </div>
            </div>
            <div className="grid grid-cols-2 mt-3">
              <div className="grid grid-cols-1">
                <div className="flex items-center font-extrabold">Member Price:</div>
              </div>
              <div className="grid grid-cols-1 gap-x-5">
                <div className="flex justify-end">
                  <div
                    key="pricing-for-month"
                    className="w-40 lg:w-36 h-28 cursor-pointer bg-blue-50 rounded-lg flex justify-center items-center px-2 border-primary border-2"
                  >
                    <div className="flex flex-col gap-2 text-center">
                      <div className="text-base font-bold">Per-Month</div>
                      <div className="text-primary-color text-lg font-bold">{`$${memberPrice.member_price}`}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {!isMember && (
        <div className="border-b flex justify-center lg:justify-end pb-10">
          <div className="mt-2">
            <Button
              onClick={() => onClickListMyInventory()}
              className="p-4 h-12 rounded-lg flex items-center text-base font-semibold text-white bg-blue-700"
            >
              List My Inventory
            </Button>
          </div>
        </div>
      )}

      {!isMember && showListMyInventory && (
        <div className="w-full flex-1">
          <Checkbox.Group className="w-full" onChange={handleCheckboxMonthly} value={selectedEnhancementPrices}>
            <table className="table-auto flex-1 w-full">
              <thead>
                <tr>
                  <th>{}</th>
                  <th className="text-right text-lg text-gray-400 font-normal">Set up</th>
                  <th className="text-right text-lg text-gray-400 font-normal lg:px-4 px-2">Monthly</th>
                  <th>{}</th>
                </tr>
              </thead>
              <tbody>
                {enhancementPriceKeysList.map((item) => {
                  const isSelected = selectedEnhancementPrices.includes(item.id)
                  const setupPrice = pricesObject[item.id]?.setup || pricesObject[item.id]?.setUp || 0
                  let monthlyPrice =
                    pricesObject[item.id][selectedPrice] || pricesObject[item.id]['month to Month'] || 0
                  if (item.id === 'fiMyRidePrice') {
                    monthlyPrice = `${pricesObject[item.id]['App month to Month']} + $${
                      pricesObject[item.id]['Per month to Month']
                    } per app`
                  }
                  return (
                    <tr key={item.id} className={`${isSelected ? 'bg-blue-50' : ''}`}>
                      <td
                        className={`py-2 lg:pl-4 pl-2 sm-max:pl-0 text-left text-lg ${isSelected ? 'font-bold' : ''}`}
                      >
                        {item.name}
                      </td>
                      <td className="py-2 text-right text-primary-color font-bold text-lg">
                        {typeof setupPrice === 'string' ? 'Custom Pricing' : `$${setupPrice}`}
                      </td>
                      <td className="py-2 lg:px-4 px-2 text-right text-primary-color font-bold text-lg">
                        {typeof monthlyPrice === 'string' ? 'Custom Pricing' : `$${monthlyPrice}`}
                      </td>
                      <td className="lg:pr-3 pr-0 pricing-table-checkbox-column">
                        <Checkbox value={item.id} disabled={notEnhancement} />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Checkbox.Group>
          <div className="pt-10 pl-4 sm-max:pl-0 flex flex-col md:flex-row items-center justify-between">
            <Checkbox checked={notEnhancement} className="text-lg" onChange={handleChangeNotEnhancement}>
              I do not wish to enhance my MotorMarketUSA experience right now
            </Checkbox>
            <Button
              onClick={() => setShowTotalPrice(true)}
              className="p-4 h-12 sm-max:mt-3 rounded-lg flex items-center text-base font-semibold text-white bg-blue-700"
            >
              Calculate total
            </Button>
          </div>
        </div>
      )}

      {!isMember && showTotalPrice && (
        <div className="py-7 border-b flex flex-row gap-5 justify-between">
          <div className="text-xl font-bold">Total monthly billing:</div>
          <div className="flex flex-col gap-1 items-end w-50">
            <div className="text-primary-color font-bold flex items-end gap-0 leading-none">
              {!isCustomPrice ? (
                <>
                  <div className="text-3xl leading-6 v ">{`$${totalMonthly}`}</div>
                  <div className="text-base">/month</div>
                </>
              ) : (
                <p>Custom Pricing</p>
              )}
            </div>
            <div className="text-secondary-color text-tiny">Basic + Enhancements</div>
          </div>
        </div>
      )}

      <PaymentDetails
        setPaymentMethod={setPaymentMethod}
        paymentMethod={paymentMethod}
        setPaymentDetails={setPaymentDetails}
        getClaimBusinessPayloadMailAdapter={getClaimBusinessPayloadMailAdapter}
        selectedPrice={selectedPrice}
        form={form}
        totalMonthly={totalMonthly}
      />
      <div className="flex lg:justify-end gap-5 justify-between py-7">
        <Button
          className="px-6 py-5 h-12 border-0  flex justify-center items-center gap-2 text-base font-semibold text-blue-700"
          onClick={backStep}
        >
          <BiChevronLeft className="text-xl" /> Back
        </Button>
        <Button
          className="w-48 px-6 py-5 h-12 rounded-lg flex justify-center items-center gap-2 text-base font-semibold text-white bg-blue-700"
          onClick={onNextStep}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

BasicInventoryListingEnrollment.propTypes = {
  dealershipInfo: PropTypes.object,
  backStep: PropTypes.func,
  nextStep: PropTypes.func,
  setPaymentInfo: PropTypes.func,
  getClaimBusinessPayloadMailAdapter: PropTypes.func,
  step: PropTypes.number
}

export default BasicInventoryListingEnrollment
