import { Button, Collapse, Modal, InputNumber, Input, Slider, Col, Row, Table, Pagination } from 'antd'
import React, { useEffect, useState } from 'react'
import { AiFillEdit, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { useNavigate, useParams } from 'react-router-dom'
import { useMeasure } from 'react-use'
import * as DealerService from '../../../services/dealer.service'
import * as BuyService from '../../../services/buy.service'
import { useAuthorizationContext } from '../../../AuthorizationProvider'
import './style.scss'
import { DEFAULT_TERM_MARKS } from '../../../constants/constants'

const { Search } = Input
const { Panel } = Collapse

const ezDealzDataDefault = [
  {
    image: '',
    title: '2011 Dodge Caliber',
    details: {
      Model: 'Caliber',
      Trim: 'Coupe',
      Color: 'Red'
    },
    protections: {},
    taxOffers: {
      'Sales Taxes': '35,6789',
      'Dealer Fees': '87,654',
      'Your Zip': 232
    },
    cashDown: {
      'Down Payment': '3,500'
    },
    tradeIn: {
      'Vehicle Name': '',
      Mileage: '',
      'Estimated Value': '0'
    },
    finance: {
      'Total Down Payment': '23,500',
      'Remaining Balance': '50,345.9',
      Term: '60 mo',
      'Monthly Payment': '9,424/mo'
    }
  }
]

export function EzDealz() {
  const { dealershipAccount } = useAuthorizationContext()
  let dealerId = dealershipAccount?.dealer_id ?? 0
  const { index } = useParams()
  const searchParams = new URLSearchParams(document.location.search)
  if (searchParams.get('review')) {
    dealerId = 0
  }
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [youAreApproved, setYouAreApproved] = useState(dealerId < 1)
  const [activeKey, setActiveKey] = useState([])
  const [activeKeyOffer, setActiveKeyOffer] = useState([])
  const [modalFor, setModalFor] = useState('')
  const [ezDealzData, setEzDealzData] = useState(ezDealzDataDefault)
  const [editOfferId, setEditOfferId] = useState('')
  const [editTermValue, setEditTermValue] = useState(0)
  const [editDownPaymentValue, setEditDownPaymentValue] = useState(0)
  const [customerName, setCustomerName] = useState('')
  const [mainTerm, setMainTerm] = useState([])
  const [mainTermInitial, setMainTermInitial] = useState(36)
  const [minDownPayment, setMinDownPayment] = useState(0)
  const [maxDownPayment, setMaxDownPayment] = useState(0)
  const [downPaymentStep, setDownPaymentStep] = useState(0)
  const [marksDownPayment, setMarksDownPayment] = useState({})
  const [mainDownPayment, setMainDownPayment] = useState([])
  const [mainDownPaymentInitial, setMainDownPaymentInitial] = useState(0)
  const [mustUpdateOffers, setMustUpdateOffers] = useState(false)
  const [searchVehicle, setSearchVehicle] = useState('')
  const [buyCars, setBuyCars] = useState([])
  const [totalBuyCars, setTotalBuyCars] = useState(0)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pagination, setPagination] = useState({
    current: page,
    pageSize: rowsPerPage
  })
  const [initialOfferId, setInitialOfferId] = useState('')
  const [deleteOfferId, setDeleteOfferId] = useState('')
  const [depositOfferId, setDepositOfferId] = useState('')
  const [reviewOfferId, setReviewOfferId] = useState('')
  const [depositAmount, setDepositAmount] = useState(0)
  const [searchedCars, setSearchedCars] = useState([])
  const [showSearch, setShowSearch] = useState(false)
  const [publishedOffersUrl, setPublishedOffersUrl] = useState('')
  const [isCopied, setIsCopied] = useState(false)
  const [ref, { width }] = useMeasure()
  const isMobile = width < 500

  const searchQuery = (search) => {
    if (search && search.length > 0) {
      BuyService.getSearchSuggestionElasticsearchPerDealer(search, dealerId)
        .then((response) => {
          setSearchedCars(response.data)
          setShowSearch(true)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  const showList = () => {
    navigate('/dealer-dashboard')
  }
  const onChange = (key) => {
    setActiveKeyOffer(key)
  }

  const onChangeOffer = (key) => {
    setActiveKey(key)
  }

  const updateOffers = (terms, downPayments) => {
    const parsedTerms = []
    Object.keys(terms).forEach((key) => {
      parsedTerms.push({
        id: key,
        value: terms[key]
      })
    })
    const parsedDownPayment = []
    Object.keys(downPayments).forEach((key) => {
      parsedDownPayment.push({
        id: key,
        value: downPayments[key]
      })
    })
    setLoading(true)
    DealerService.dealershipCPopOffersUpdate(initialOfferId, editOfferId, parsedTerms, parsedDownPayment)
      .then((result) => {
        if (result.isSuccessful === 'No') {
          showList()
        } else {
          const offers = result.data.result.offers
          setEzDealzData(offers)
          setEditOfferId(result.data.currentOfferId)

          const downPayments = []
          const terms = []
          offers.forEach((element) => {
            downPayments[element._id] = element.downPayment
            terms[element._id] = element.term
          })
          setMainDownPayment(downPayments)
          setMainTerm(terms)
        }
      })
      .finally(() => setLoading(false))
  }

  const onChangeTerm = (value, id) => {
    if (Number.isNaN(value)) {
      return
    }
    const newMainTerm = []
    Object.keys(mainTerm).forEach((key) => {
      newMainTerm[key] = key !== id ? mainTerm[key] : value
    })
    setMainTerm(newMainTerm)
    setMustUpdateOffers(true)
  }

  const onChangeTermAll = (value) => {
    if (Number.isNaN(value)) {
      return
    }
    const newMainTerm = []
    Object.keys(mainTerm).forEach((key) => {
      newMainTerm[key] = value
    })
    setMainTerm(newMainTerm)
    setMainTermInitial(value)
    setMustUpdateOffers(true)
  }

  const onChangeDownPayment = (value, id) => {
    if (Number.isNaN(value)) {
      return
    }
    const newMainDownPayment = []
    Object.keys(mainDownPayment).forEach((key) => {
      newMainDownPayment[key] = key !== id ? mainDownPayment[key] : value
    })
    setMainDownPayment(newMainDownPayment)
    setMustUpdateOffers(true)
  }

  const onChangeDownPaymentAll = (value) => {
    if (Number.isNaN(value)) {
      return
    }
    const newMainDownPayment = []
    Object.keys(mainDownPayment).forEach((key) => {
      newMainDownPayment[key] = value
    })
    setMainDownPayment(newMainDownPayment)
    setMainDownPaymentInitial(value)
    setMustUpdateOffers(true)
  }

  useEffect(() => {
    if (mustUpdateOffers) {
      const delayDebounceFn = setTimeout(() => {
        setMustUpdateOffers(false)
        updateOffers(mainTerm, mainDownPayment)
      }, 1500)
      return () => clearTimeout(delayDebounceFn)
    }
  }, [mustUpdateOffers, mainTerm, mainDownPayment])

  useEffect(() => {
    if (dealerId < 1 && !searchParams.get('review')) {
      DealerService.dealershipCustomerVisitCPop(index)
    }
  }, [])

  const renderDetail = (key, value, row) => {
    const renderEditFor = ['Down Payment - disable this for now', 'Term - disable this for now'].indexOf(key) > -1

    let useKey = key
    if (renderEditFor && key === 'Down Payment') {
      useKey = 'downPayment'
    }
    if (renderEditFor && key === 'Term') {
      useKey = 'newTerm'
    }

    return (
      <div className="flex justify-between">
        <p className="text-gray-600">{key}</p>
        <div className="flex items-center justify-between">
          <p className={`text-sm ${renderEditFor && 'pr-2'}`}>{value}</p>
          {renderEditFor && (
            <AiFillEdit
              color="blue"
              onClick={() => {
                setModalFor(useKey)
                setEditOfferId(row._id)
                if (renderEditFor && key === 'Down Payment') {
                  setEditDownPaymentValue(value)
                }
                if (renderEditFor && key === 'Term') {
                  setEditTermValue(value)
                }
              }}
            />
          )}
        </div>
      </div>
    )
  }

  useEffect(() => {
    setLoading(true)
    DealerService.dealershipCPopOffers(index)
      .then((result) => {
        if (result.isSuccessful === 'No') {
          showList()
        } else {
          const offers = result.data.offers
          setEzDealzData(offers)
          setEditOfferId(offers[0]._id)
          setInitialOfferId(offers[0]._id)
          setCustomerName(result.data.customerName)
          setMainTermInitial(result.data.term)
          setMainDownPaymentInitial(result.data.downPayment)
          setMinDownPayment(result.data.minDownPaymentStep)
          setMaxDownPayment(result.data.maxDownPaymentStep)
          setDownPaymentStep(result.data.downPaymentStep)
          setMarksDownPayment(result.data.marksDownPayment)

          const downPayments = []
          const terms = []
          offers.forEach((element) => {
            downPayments[element._id] = element.downPayment
            terms[element._id] = element.term
          })
          setMainDownPayment(downPayments)
          setMainTerm(terms)
        }
      })
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    DealerService.getSearchSorting({ page, rowsPerPage, sortBy: 'all', orderBy: 'asc', dealerId })
      .then((response) => {
        setBuyCars(response.data.data)
        setTotalBuyCars(response.data.total)
      })
      .catch((e) => {
        setBuyCars([])
        setTotalBuyCars(0)
        setPage(1)
        setRowsPerPage(20)
        console.log(e)
      })
  }, [dealerId])

  const handleTableChange = (newPage) => {
    const newPagination = {
      current: newPage,
      pageSize: rowsPerPage
    }
    setPage(newPage)
    setPagination(newPagination)
    DealerService.getSearchSorting({
      page: newPagination.current,
      rowsPerPage: newPagination.pageSize,
      sortBy: 'all',
      orderBy: 'asc',
      dealerId,
      keyword: searchVehicle,
      pagination
    })
      .then((response) => {
        setBuyCars(response.data.data)
        setTotalBuyCars(response.data.total)
      })
      .catch((e) => {
        setBuyCars([])
        setTotalBuyCars(0)
        setPage(1)
        setRowsPerPage(20)
        console.log(e)
      })
  }

  const handleSearchVehicle = (value) => {
    setPagination({
      current: 1,
      pageSize: rowsPerPage
    })
    DealerService.getSearchSorting({
      page: 1,
      rowsPerPage,
      sortBy: 'all',
      orderBy: 'asc',
      dealerId,
      keyword: `${value}&searchID=1914`
    })
      .then((response) => {
        setBuyCars(response.data.data)
        setTotalBuyCars(response.data.total)
      })
      .catch((e) => {
        setBuyCars([])
        setTotalBuyCars(0)
        setPage(1)
        setRowsPerPage(20)
        console.log(e)
      })
  }

  const onSearchVehicle = (value) => {
    setShowSearch(false)
    setSearchVehicle(value)
    handleSearchVehicle(value)
  }

  const selectSearchedValue = (car) => {
    setShowSearch(false)
    setSearchVehicle(car)
    handleSearchVehicle(car)
  }

  const onPublishOffersCancel = () => {
    setModalFor('')
  }

  const onPublishOffersClickYes = () => {
    setModalFor('')

    const ids = []
    Object.keys(mainTerm).forEach((key) => {
      ids.push(key)
    })

    setLoading(true)
    DealerService.dealershipCPopOffersPublish(initialOfferId, ids)
      .then((result) => {
        if (result.isSuccessful === 'No') {
          showList()
        } else {
          const url = result.data.url
          setPublishedOffersUrl(url)
          setModalFor('publishedOffersUrlCopy')
        }
      })
      .finally(() => setLoading(false))
  }

  const onDeleteOfferCancel = () => {
    setModalFor('')
    setDeleteOfferId('')
  }

  const onDeleteOfferClickYes = () => {
    setModalFor('')
    setDeleteOfferId('')

    setLoading(true)
    DealerService.dealershipDeleteClonedOffer(initialOfferId, deleteOfferId)
      .then((result) => {
        if (result.isSuccessful === 'No') {
          showList()
        } else {
          const offers = result.data.result.offers
          setEzDealzData(offers)
          setEditOfferId(result.data.previousOfferId)

          const downPayments = []
          const terms = []
          offers.forEach((element) => {
            downPayments[element._id] = element.downPayment
            terms[element._id] = element.term
          })
          setMainDownPayment(downPayments)
          setMainTerm(terms)
        }
      })
      .finally(() => setLoading(false))
  }

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    }
    return document.execCommand('copy', true, text)
  }

  const handleCopyClick = () => {
    copyTextToClipboard(publishedOffersUrl)
      .then(() => {
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, 1500)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleMakeDeposit = () => {
    setModalFor('')
    setDepositOfferId('')

    setLoading(true)
    DealerService.dealershipCustomerMakeDeposit(initialOfferId, depositOfferId, depositAmount)
      .then((result) => {
        if (result.isSuccessful === 'No') {
          showList()
        } else {
          const offers = result.data.result.offers
          setEzDealzData(offers)
          setEditOfferId(result.data.currentOfferId)

          const downPayments = []
          const terms = []
          offers.forEach((element) => {
            downPayments[element._id] = element.downPayment
            terms[element._id] = element.term
          })
          setMainDownPayment(downPayments)
          setMainTerm(terms)
        }
      })
      .finally(() => setLoading(false))
  }

  const onChangeDepositAmount = (value) => {
    if (Number.isNaN(value)) {
      return
    }
    setDepositAmount(value)
  }

  const renderModalBody = (modalFor) => {
    switch (modalFor) {
      case 'makeDeposit':
        return (
          <>
            <div className="grid grid-cols-12">
              <div className="col-span-6">
                <span className="font-bold text-gray-400">Deposit amount</span>
              </div>
              <div className="col-span-6">
                <InputNumber
                  min={0}
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  className="placeholder:text-xs placeholder:text-inputColor text-inputColor p-0 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded w-full"
                  value={depositAmount}
                  onChange={onChangeDepositAmount}
                />
              </div>
            </div>
            <div className="grid grid-cols-12 mt-5">
              <div className="col-span-12">
                <Button type="primary" className="rounded-lg w-full" onClick={handleMakeDeposit}>
                  MAKE DEPOSIT
                </Button>
              </div>
            </div>
          </>
        )

      case 'publishedOffersUrlCopy':
        return (
          <>
            <div className="grid grid-cols-12 text-center">
              <div className="col-span-12">
                <Input className="rounded w-full" type="text" value={publishedOffersUrl} readOnly />
              </div>
            </div>
            <div className="grid grid-cols-12 text-center mt-5">
              <div className="col-span-12">
                <Button block onClick={handleCopyClick}>
                  <span>{isCopied ? 'Copied!' : 'Copy'}</span>
                </Button>
              </div>
            </div>
          </>
        )

      case 'deleteOffer':
        return (
          <>
            <div className="grid grid-cols-12 text-center">
              <div className="col-span-12">Are you sure?</div>
            </div>
            <div className="grid grid-cols-12 mt-5">
              <div className="col-span-6">
                <Button block onClick={() => onDeleteOfferClickYes()}>
                  Yes
                </Button>
              </div>
              <div className="col-span-6">
                <Button block onClick={() => onDeleteOfferCancel()}>
                  No
                </Button>
              </div>
            </div>
          </>
        )

      case 'publishOffers':
        return (
          <>
            <div className="grid grid-cols-12 text-center">
              <div className="col-span-12">Are you sure?</div>
            </div>
            <div className="grid grid-cols-12 mt-5">
              <div className="col-span-6">
                <Button block onClick={() => onPublishOffersClickYes()}>
                  Yes
                </Button>
              </div>
              <div className="col-span-6">
                <Button block onClick={() => onPublishOffersCancel()}>
                  No
                </Button>
              </div>
            </div>
          </>
        )

      case 'downPayment':
        return (
          <div className="flex items-center justify-around">
            <p>Cash Down : </p>
            <input
              className="placeholder:text-xs placeholder:text-inputColor text-inputColor p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
              type="text"
              placeholder="$5,250"
              prefix="$"
              defaultValue={editDownPaymentValue}
              onChange={(e) => setEditDownPaymentValue(e.target.value)}
            />
            <Button type="primary" className="rounded-lg" onClick={() => alert('Save Cash Down')}>
              SUBMIT
            </Button>
          </div>
        )

      case 'newTerm':
        return (
          <div className="flex items-center justify-around">
            <p>Months : </p>
            <input
              className="placeholder:text-xs placeholder:text-inputColor text-inputColor p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
              type="text"
              placeholder="36"
              defaultValue={editTermValue}
              onChange={(e) => setEditTermValue(e.target.value)}
            />
            <Button type="primary" className="rounded-lg" onClick={() => alert('Save Term')}>
              SUBMIT
            </Button>
          </div>
        )

      case 'yourZip':
        return (
          <div className="flex items-center justify-around">
            <p>Your Zip : </p>
            <input
              className="placeholder:text-xs placeholder:text-inputColor text-inputColor p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
              type="text"
              placeholder="55210"
            />
            <Button type="primary" className="rounded-lg">
              SUBMIT
            </Button>
          </div>
        )

      case 'term':
        return (
          <div className="flex flex-col items-center">
            <div className="my-9 grid grid-cols-2 gap-3">
              {[
                {
                  label: '48 months'
                },
                {
                  label: '60 months'
                },
                {
                  label: '72 months'
                },
                {
                  label: '120 months'
                }
              ].map((element, index) => {
                return (
                  <div key={index} className="py-7 bg-blue-100 col-span-1 px-3 rounded-xl">
                    <p className="font-bold">{element.label}</p>
                    <div className="pt-1 flex items-center">
                      <p>Rate:</p>
                      <input
                        name="rate"
                        className="p-1 w-full box-border border-gray-300 border focus:outline-none"
                        type="number"
                      />
                    </div>
                  </div>
                )
              })}
            </div>
            <Button type="primary" size="large" className="rounded-xl">
              SUBMIT
            </Button>
          </div>
        )

      default:
        return null
    }
  }

  const renderModalTitle = (modalFor) => {
    switch (modalFor) {
      case 'downPayment':
        return <p>Set Down Payment</p>
      case 'term':
        return <p>Choose Terms</p>
      case 'newTerm':
        return <p>Set Term</p>
      case 'yourZip':
        return <p>Change your location</p>
      case 'deleteOffer':
        return <p>Delete Offer</p>
      case 'publishOffers':
        return <p>Publish Offers</p>
      case 'publishedOffersUrlCopy':
        return <p>Published Offers URL</p>
      case 'makeDeposit':
        return <p>Make Deposit</p>
      default:
        return null
    }
  }

  const handlePrint = () => {
    window.print()
  }

  const handleReset = () => {
    const newMainTerm = []
    Object.keys(mainTerm).forEach((key) => {
      newMainTerm[key] = mainTermInitial
    })
    setMainTerm(newMainTerm)

    const newMainDownPayment = []
    Object.keys(mainDownPayment).forEach((key) => {
      newMainDownPayment[key] = mainDownPaymentInitial
    })
    setMainDownPayment(newMainDownPayment)
    setMustUpdateOffers(true)
  }

  const handlePublish = () => {
    setModalFor('publishOffers')
  }

  const handleCloneOffer = (id) => {
    const downPayment = mainDownPayment[id]
    let term = mainTerm[id]
    if (Array.isArray(term)) {
      term = term[0]
    }

    setLoading(true)
    DealerService.dealershipCloneOffer(initialOfferId, id, term, downPayment)
      .then((result) => {
        if (result.isSuccessful === 'No') {
          showList()
        } else {
          const offers = result.data.result.offers
          setEzDealzData(offers)
          setEditOfferId(result.data.newOfferId)

          const downPayments = []
          const terms = []
          offers.forEach((element) => {
            downPayments[element._id] = element.downPayment
            terms[element._id] = element.term
          })
          setMainDownPayment(downPayments)
          setMainTerm(terms)
        }
      })
      .finally(() => setLoading(false))
  }

  const handleDeleteOffer = (id) => {
    setModalFor('deleteOffer')
    setDeleteOfferId(id)
  }

  const handleIllTakeIt = (id) => {
    setDepositOfferId(id)
    setDepositAmount(mainDownPayment[id])
    setModalFor('makeDeposit')
  }

  const handleAddVehicle = (vehicleId) => {
    const downPayment = mainDownPayment[editOfferId]
    let term = mainTerm[editOfferId]
    if (Array.isArray(term)) {
      term = term[0]
    }

    setLoading(true)
    DealerService.dealershipCPopOfferAddVehicle(initialOfferId, vehicleId, term, downPayment)
      .then((result) => {
        if (result.isSuccessful === 'No') {
          showList()
        } else {
          const offers = result.data.result.offers
          setEzDealzData(offers)
          setEditOfferId(result.data.newOfferId)

          const downPayments = []
          const terms = []
          offers.forEach((element) => {
            downPayments[element._id] = element.downPayment
            terms[element._id] = element.term
          })
          setMainDownPayment(downPayments)
          setMainTerm(terms)
        }
      })
      .finally(() => setLoading(false))
  }

  const tableColumns = [
    {
      title: 'IMG',
      key: 'image',
      render: (data) => {
        if (data?.photos[0]) {
          return <img alt="car" src={data.photos[0]} style={{ width: 70, height: '100%' }} />
        }
      }
    },
    {
      title: 'VEHICLE',
      key: 'vehicle',
      render: (data) => {
        let renderLink = `<a href="${data.frontend_url}" target="_blank" rel="noreferrer">`
        renderLink += `${data.year} ${data.make} ${data.model}`
        if (data?.stock_number) {
          renderLink += `<br/><b>Stock number</b> ${data.stock_number}`
        }
        if (data?.exterior_color) {
          renderLink += `<br/><b>Exterior color</b> ${data.exterior_color}`
        }
        // eslint-disable-next-line react/no-danger
        return <div dangerouslySetInnerHTML={{ __html: renderLink }} />
      }
    },
    {
      title: '',
      key: '_id',
      render: (data) => {
        return (
          <Button type="primary" className="rounded-lg font-bold" onClick={() => handleAddVehicle(data._id)}>
            Add
          </Button>
        )
      }
    }
  ]

  const handleSetReviewOffer = (id, state) => {
    setEditOfferId(id)
    if (state === 'in') {
      setReviewOfferId(id)
    } else {
      setReviewOfferId('')
    }
  }

  const onYouAreApprovedChange = () => {
    setYouAreApproved(false)
  }

  return (
    <div>
      {youAreApproved && mainDownPaymentInitial > 0 ? (
        <div className="w-full h-full" ref={ref}>
          {isMobile ? (
            <>
              <div className="text-center p-5 bg-activePrimary">
                <p className="text-blue-50 font-bold fontSize-2-4">{`You're Are Approved!`}</p>
                <p className="text-blue-50 fontSize-1-4">Congratulations!</p>
              </div>
              <div className="text-center p-5 bg-gray-200">
                <p className="mt-10">
                  <p className="font-bold fontSize24 text-gray-600">Your Down Payment</p>
                  <p className="font-bold fontSize-2-4 text-gray-700 mt-5 dollar">{mainDownPaymentInitial}</p>
                  <p className="mt-5">
                    <Button type="primary" className="bg-blue-500 font-bold" onClick={onYouAreApprovedChange}>
                      VIEW OFFER
                    </Button>
                  </p>
                  <p className="fontSize18 text-gray-600 mt-10">
                    Visit your personalized offer page to learn more,
                    <br />
                    change terms or approve offer.
                  </p>
                </p>
              </div>
            </>
          ) : (
            <div className="grid grid-cols-12 you-are-approved-min-height">
              <div className="col-span-5 bg-activePrimary text-center">
                <p className="mt-96">
                  <p className="text-blue-50 font-bold fontSize-2-4">{`You're Are Approved!`}</p>
                  <p className="text-blue-50 fontSize-1-4">Congratulations!</p>
                </p>
              </div>
              <div className="col-span-7 bg-gray-200 text-center">
                <p className="mt-80">
                  <p className="font-bold fontSize24 text-gray-600">Your Down Payment</p>
                  <p className="font-bold fontSize-2-4 text-gray-700 mt-10 dollar">{mainDownPaymentInitial}</p>
                  <p className="mt-10">
                    <Button type="primary" className="bg-blue-500 font-bold" onClick={onYouAreApprovedChange}>
                      VIEW OFFER
                    </Button>
                  </p>
                  <p className="fontSize18 text-gray-600 mt-10 mb-10">
                    Visit your personalized offer page to learn more,
                    <br />
                    change terms or approve offer.
                  </p>
                </p>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="pt-0 bg-gray-200" ref={ref}>
          {!isMobile && dealerId < 1 && (
            <div className="flex text-center p-5 bg-activePrimary md:shadow-lg left-0 w-full" style={{ top: -2 }}>
              <AiOutlineLeft
                size={25}
                onClick={dealerId > 0 ? showList : () => handleSetReviewOffer('', 'out')}
                className="cursor-pointer mr-6 text-blue-50 font-bold"
              />
              <p className="text-blue-50 font-bold">My Offers</p>
            </div>
          )}
          {isMobile && (
            <div className="flex text-center p-5 bg-activePrimary">
              <AiOutlineLeft
                size={25}
                onClick={dealerId > 0 ? showList : () => handleSetReviewOffer('', 'out')}
                className="cursor-pointer mr-6 text-blue-50 font-bold"
              />
              {dealerId > 0 ? (
                <p className="text-blue-50">
                  Custom Ez-Dealz for <span className="font-bold">{customerName}</span>
                </p>
              ) : (
                <p className="text-blue-50 font-bold">My Offers</p>
              )}
            </div>
          )}
          <div className={`flex justify-between ${!isMobile ? 'px-16 py-3' : 'px-2 p-1'} `}>
            <div className={`${!isMobile ? 'flex ' : ''} flex-row w-full ${!isMobile ? 'hidden md:flex' : ''}`}>
              {!isMobile && dealerId > 0 && (
                <>
                  <div className="md:basis-1/1">
                    <AiOutlineLeft
                      size={25}
                      onClick={showList}
                      className="cursor-pointer mr-6 text-blue-500 font-bold"
                    />
                  </div>
                  <div className="md:basis-1/1 text-center">
                    <p>
                      Custom Ez-Dealz for <span className="font-bold">{customerName}</span>. Review your officer, change
                      terms or compare with other offers
                    </p>
                  </div>
                  <div className="md:basis-1/2 flex items-center justify-right">
                    <span className="font-bold text-gray-600 mr-5">Down Payment</span>
                    <Col span={12}>
                      <Slider
                        range
                        min={minDownPayment}
                        max={maxDownPayment}
                        step={downPaymentStep}
                        marks={marksDownPayment}
                        onChange={onChangeDownPaymentAll}
                        disabled={loading}
                        value={mainDownPaymentInitial}
                        className="mr-10"
                      />
                    </Col>
                    <InputNumber
                      span={10}
                      disabled
                      formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      className={`placeholder:text-xs placeholder:text-inputColor text-inputColor p-1 mr-1 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded${
                        !isMobile ? ' w-1/2' : ' w-full'
                      }`}
                      value={mainDownPaymentInitial}
                    />
                  </div>
                  <div className="md:basis-1/2 flex items-center justify-around">
                    <span className="font-bold text-gray-600">Terms</span>
                    <Col span={12}>
                      <Slider
                        range
                        min={36}
                        max={72}
                        step={12}
                        marks={DEFAULT_TERM_MARKS}
                        onChange={onChangeTermAll}
                        disabled={loading}
                        value={mainTermInitial}
                        className="ml-5 mr-5"
                      />
                    </Col>
                    <Col span={10}>
                      <InputNumber
                        disabled
                        min={36}
                        max={72}
                        className={`placeholder:text-xs placeholder:text-inputColor text-inputColor p-1 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded${
                          !isMobile ? ' w-1/2' : ' w-full'
                        }`}
                        value={`${mainTermInitial}/Mo`}
                      />
                    </Col>
                  </div>
                  <div className="md:basis-1/1 flex items-center justify-around">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" className="text-blue-500 font-bold" onClick={handleReset}>
                      Reset
                    </a>
                  </div>
                </>
              )}

              {dealerId > 0 && reviewOfferId.length < 1 && (
                <div className="md:basis-1/1 flex items-center justify-around ml-3">
                  <Button type="primary" size="large" className="rounded-xl" onClick={handlePublish} disabled={loading}>
                    PUBLISH OFFERS
                  </Button>
                </div>
              )}
            </div>

            {!isMobile && (
              <div className="flex flex-col md:hidden">
                <div className="flex items-center flex-row w-full">
                  <div className="md:basis-1/3 basis-1/12">
                    <AiOutlineLeft size={25} onClick={showList} className="cursor-pointer mr-6" />
                  </div>
                  <div className="md:basis-1/3 basis-11/12 text-center">
                    <p>
                      Custom Ez-Dealz for <span className="font-bold">{customerName}</span>
                      <br />
                      <span className="text-[11px]">
                        Review your officer, change terms or compare with other offers
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          {isMobile ? (
            <>
              <div className="px-2 pb-3" id="customEzDealz">
                {reviewOfferId.length < 1 &&
                  ezDealzData.length > 0 &&
                  ezDealzData.map((ezDealz, index) => {
                    return (
                      <div
                        onClick={() => setEditOfferId(ezDealz._id)}
                        key={index + 1}
                        className="bg-white items-center justify-center p-4 mb-3 rounded-lg pt-2"
                      >
                        <div className="grid grid-cols-12">
                          <div className="col-span-5">
                            {ezDealz?.image && (
                              <img
                                alt="car"
                                src={ezDealz.image}
                                style={{ width: '100%', height: 100 }}
                                className="mt-3 mb-3"
                              />
                            )}
                          </div>
                          <div className="col-span-6">
                            <p className="font-bold">{ezDealz.title}</p>
                            <p className="text-gray-700 mt-2">
                              Stk# {ezDealz.vehicleStockNumber !== '' ? ezDealz.vehicleStockNumber : 'N/A'}
                            </p>
                            <p className="font-bold text-blue-500 mt-2">
                              Delivered {ezDealz.deliveredOn !== '' ? ezDealz.deliveredOn : 'N/A'}
                            </p>
                          </div>
                          <div className="col-span-1">
                            <AiOutlineRight
                              size={25}
                              onClick={() => handleSetReviewOffer(ezDealz._id, 'in')}
                              className="cursor-pointer mr-6 mt-9 text-blue-500 font-bold"
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-12">
                          <div className="col-span-5">
                            <p className="font-bold text-gray-400">Monthly Payment</p>
                            <p className="font-bold text-gray-800">{ezDealz.monthlyPayment}</p>
                          </div>
                          <div className="col-span-3">
                            <p className="font-bold text-gray-400">Rate (APR)</p>
                            <p className="font-bold text-gray-800">{ezDealz.aprPercentage}%</p>
                          </div>
                          <div className="col-span-4">
                            <p className="font-bold text-gray-400">Down Payment</p>
                            <p className="font-bold text-gray-800">${ezDealz.downPayment}</p>
                          </div>
                        </div>
                        <div className="row pt-3 pb-3">
                          <hr />
                        </div>
                        <div className="row text-center">
                          <p className="font-bold text-gray-300">
                            Auto pay {ezDealz.monthlyPayment} is scheduled for {ezDealz.nextScheduledPaymentOn}
                          </p>
                        </div>

                        {dealerId > 0 && (
                          <div className="row pt-3 pb-3">
                            <hr />
                          </div>
                        )}

                        {ezDealzData.length < 5 && dealerId > 0 && (
                          <div>
                            <Button
                              disabled={ezDealz._id !== editOfferId || loading}
                              type="primary"
                              className="w-full my-4 rounded-lg relative font-bold"
                              onClick={() => handleCloneOffer(ezDealz._id)}
                            >
                              Clone Offer
                            </Button>
                          </div>
                        )}

                        {ezDealz.isCloned && ezDealz._id === editOfferId && dealerId > 0 && (
                          <div className="text-center mb-3 font-bold">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a href="#" className="text-red-500" onClick={() => handleDeleteOffer(ezDealz._id)}>
                              Delete Offer
                            </a>
                          </div>
                        )}
                      </div>
                    )
                  })}

                {reviewOfferId.length > 0 &&
                  ezDealzData.length > 0 &&
                  ezDealzData.map((ezDealz, index) => {
                    return (
                      <div
                        key={index + 1}
                        className={`${
                          ezDealz._id === editOfferId ? 'bg-white ' : 'hidden '
                        } items-center justify-center p-4 rounded-lg pt-2`}
                      >
                        <div className="grid grid-cols-12">
                          <div className="col-span-11">
                            {ezDealz?.image && (
                              <img
                                alt="car"
                                src={ezDealz.image}
                                style={{ width: '100%', height: 175 }}
                                className="mt-3 mb-3"
                              />
                            )}
                          </div>
                          <div className="col-span-1">
                            <AiOutlineLeft
                              size={25}
                              onClick={() => handleSetReviewOffer(ezDealz._id, 'out')}
                              className="cursor-pointer mr-6 mt-14 text-blue-500 font-bold"
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-12">
                          <div className="col-span-9">
                            <p className="font-bold">{ezDealz.title}</p>
                          </div>
                          <div className="col-span-3">
                            <p className="font-bold text-gray-400">Monthly Payment</p>
                            <p className="font-bold text-gray-800">{ezDealz.monthlyPayment}</p>
                          </div>
                        </div>

                        <div className="text-center">
                          <p className="font-bold text-gray-400">PAYMENT OPTIONS</p>
                        </div>
                        <div className="mt-3">
                          <Row className="flex items-center justify-around">
                            <Col span={12}>
                              <span className="font-bold text-gray-600">Down Payment</span>
                            </Col>
                            <Col span={12} className="float-right">
                              <InputNumber
                                disabled
                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="placeholder:text-xs placeholder:text-inputColor text-inputColor p-1 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full w-full"
                                value={mainDownPayment[ezDealz._id]}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <Slider
                                disabled={ezDealz._id !== editOfferId || loading}
                                range
                                min={minDownPayment}
                                max={maxDownPayment}
                                step={downPaymentStep}
                                marks={marksDownPayment}
                                onChange={(value) => onChangeDownPayment(value, ezDealz._id)}
                                value={mainDownPayment[ezDealz._id]}
                                className="ml-5 mr-5"
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="mt-3">
                          <Row className="flex items-center justify-around">
                            <Col span={12}>
                              <span className="font-bold text-gray-600">Terms</span>
                            </Col>
                            <Col span={12}>
                              <InputNumber
                                disabled
                                min={36}
                                max={72}
                                className="placeholder:text-xs placeholder:text-inputColor text-inputColor p-1 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full w-full"
                                value={`${mainTerm[ezDealz._id]} Months`}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <Slider
                                disabled={ezDealz._id !== editOfferId || loading}
                                range
                                min={36}
                                max={72}
                                step={12}
                                marks={DEFAULT_TERM_MARKS}
                                onChange={(value) => onChangeTerm(value, ezDealz._id)}
                                value={mainTerm[ezDealz._id]}
                              />
                            </Col>
                          </Row>
                        </div>

                        <div className="text-center mt-3 mb-3">
                          <p className="font-bold text-gray-400">OFFER DETAILS</p>
                        </div>

                        <div className="grid grid-cols-12">
                          <div className="col-span-7">
                            <p className="text-gray-400">Rate (APR)</p>
                          </div>
                          <div className="col-span-5 text-right">
                            <p className="font-bold text-blue-500">{ezDealz.aprPercentage}%</p>
                          </div>
                        </div>
                        <div className="grid grid-cols-12">
                          <div className="col-span-7">
                            <p className="text-gray-400">Monthly Payment</p>
                          </div>
                          <div className="col-span-5 text-right">
                            <p className="font-bold text-blue-500">{ezDealz.monthlyPayment}</p>
                          </div>
                        </div>

                        <Collapse
                          expandIconPosition="right"
                          defaultActiveKey={[activeKey]}
                          className={`my-3 border-0 ${ezDealz._id === editOfferId ? 'bg-white' : 'bg-gray-400'}`}
                          onChange={onChange}
                        >
                          <Panel
                            header={<p className="font-bold text-gray-300">DEAL DETAILS</p>}
                            key={`${index}_details`}
                          >
                            {Object.keys(ezDealz.details).map((key) =>
                              renderDetail(key, ezDealz.details[key], ezDealz)
                            )}
                          </Panel>

                          <Panel
                            header={<p className="font-bold text-gray-300">SALES TAXES & DEALER FEES</p>}
                            key={`${index}_sales_taxes_and_dealer_fees`}
                          >
                            {Object.keys(ezDealz.taxOffers).map((key) =>
                              renderDetail(key, ezDealz.taxOffers[key], ezDealz)
                            )}
                          </Panel>

                          <Panel
                            header={<p className="font-bold text-gray-300">CASH DOWN</p>}
                            key={`${index}_cash_down`}
                          >
                            {Object.keys(ezDealz.cashDown).map((key) =>
                              renderDetail(key, ezDealz.cashDown[key], ezDealz)
                            )}
                          </Panel>

                          <Panel header={<p className="font-bold text-gray-300">TRADE IN</p>} key={`${index}_trade_in`}>
                            {Object.keys(ezDealz.tradeIn).length > 0
                              ? Object.keys(ezDealz.tradeIn).map((key) =>
                                  renderDetail(key, ezDealz.tradeIn[key], ezDealz)
                                )
                              : 'No trade-in vehicle'}
                          </Panel>

                          <Panel header={<p className="font-bold text-gray-300">FINANCE</p>} key={`${index}_finance`}>
                            {Object.keys(ezDealz.finance).map((key) =>
                              renderDetail(key, ezDealz.finance[key], ezDealz)
                            )}
                          </Panel>
                        </Collapse>

                        {dealerId < 1 && (
                          <div className="mt-5">
                            <Button
                              disabled={ezDealz._id !== editOfferId || ezDealz.deposit > 0 || loading}
                              type="primary"
                              className="w-full my-4 rounded-lg relative font-bold"
                              onClick={() => handleIllTakeIt(ezDealz._id)}
                            >
                              {ezDealz.deposit > 0
                                ? `Deposit pending for $${ezDealz.deposit.toLocaleString()}`
                                : 'ACCEPT OFFER'}
                            </Button>
                          </div>
                        )}
                        {dealerId > 0 && ezDealz.deposit > 0 && !loading && (
                          <div className="mt-5 text-center font-bold">
                            {`Deposit pending for $${ezDealz.deposit.toLocaleString()}`}
                          </div>
                        )}
                      </div>
                    )
                  })}

                {reviewOfferId.length < 1 && ezDealzData.length < 5 && dealerId > 0 && (
                  <div
                    key={ezDealzData.length + 1}
                    className="bg-white items-center justify-center p-4 pt-0 rounded-lg"
                  >
                    <div className="mb-3 text-center">
                      <p className="font-bold text-gray-400 mt-5 pt-3">ADD A VEHICLE</p>
                    </div>
                    <div className="mb-3 text-center">
                      <Search
                        className="placeholder:text-xs placeholder:text-inputColor text-inputColor border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded w-full"
                        placeholder="Search in inventory"
                        defaultValue={searchVehicle}
                        onSearch={onSearchVehicle}
                        onChange={(event) => searchQuery(event.target.value)}
                        allowClear
                        enterButton
                      />
                      {showSearch && searchedCars?.length > 0 && (
                        <div className="dropdown">
                          <ul className="bg-white border border-gray-300 rounded-md w-auto">
                            {searchedCars?.map((car, index) => (
                              <li
                                key={index}
                                onClick={() => {
                                  selectSearchedValue(car)
                                }}
                                className="hover:bg-gray-50 list-none text-sm px-2 py-3 cursor-pointer"
                                role="presentation"
                              >
                                {car}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    <div>
                      <Table
                        showHeader={false}
                        className="my-leads-table text-center"
                        pagination={{ position: ['none', 'none'], pageSize: rowsPerPage }}
                        columns={tableColumns}
                        dataSource={buyCars}
                        onChange={handleTableChange}
                      />
                      <div className="flex justify-center mb-8 pagination-margin-top-20">
                        <div className="flex items-center">
                          <Pagination
                            className="flex"
                            current={page}
                            responsive
                            total={totalBuyCars}
                            pageSize={rowsPerPage}
                            showSizeChanger={false}
                            onChange={handleTableChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {dealerId < 1 && (
                <div className="md:basis-1/1 flex items-center justify-around ml-3 pb-5">
                  <Button type="primary" size="large" className="rounded-xl" onClick={handlePrint} disabled={loading}>
                    PRINT OFFERS
                  </Button>
                </div>
              )}
            </>
          ) : (
            <div className={`overflow-x-auto${!isMobile ? ' flex px-16' : ' px-2'}`} id="customEzDealz">
              {ezDealzData.length > 0 &&
                ezDealzData.map((ezDealz, index) => {
                  return (
                    <div
                      onClick={() => setEditOfferId(ezDealz._id)}
                      key={index + 1}
                      className="bg-white items-center justify-center mr-4 p-4 mb-6 rounded-lg pt-0"
                    >
                      <div className="grid grid-cols-12">
                        <div className="col-span-12 vertical-h-175">
                          {ezDealz?.image && <img alt="car" src={ezDealz.image} className="mt-3 mb-3" />}
                        </div>
                      </div>

                      <div className="grid grid-cols-12">
                        <div className="col-span-12">
                          <p className="font-bold">{ezDealz.title}</p>
                        </div>
                      </div>

                      <div className="grid grid-cols-12">
                        <div className="col-span-7">
                          <p className="text-gray-700 mt-2">
                            Stk# {ezDealz.vehicleStockNumber !== '' ? ezDealz.vehicleStockNumber : 'N/A'}
                          </p>
                        </div>
                        <div className="col-span-5 text-right">
                          <p className="font-bold text-blue-500 mt-2">
                            Delivered {ezDealz.deliveredOn !== '' ? ezDealz.deliveredOn : 'N/A'}
                          </p>
                        </div>
                      </div>
                      <div className="grid grid-cols-12">
                        <div className="col-span-7">
                          <p className="text-gray-400">Monthly Payment</p>
                        </div>
                        <div className="col-span-5 text-right">
                          <p className="font-bold text-gray-800">{ezDealz.monthlyPayment}</p>
                        </div>
                      </div>

                      <div className="row pt-3 pb-3">
                        <hr />
                      </div>

                      <div className="row text-center">
                        <p className="font-bold text-gray-400">
                          Auto pay {ezDealz.monthlyPayment} is scheduled for {ezDealz.nextScheduledPaymentOn}
                        </p>
                      </div>

                      {ezDealzData.length < 5 && dealerId > 0 && (
                        <div>
                          <Button
                            disabled={ezDealz._id !== editOfferId || loading}
                            type="primary"
                            className="w-full my-4 rounded-lg relative font-bold"
                            onClick={() => handleCloneOffer(ezDealz._id)}
                          >
                            Clone Offer
                          </Button>
                        </div>
                      )}

                      {ezDealz.isCloned && ezDealz._id === editOfferId && dealerId > 0 && (
                        <div className="text-center mb-3 font-bold">
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a href="#" className="text-red-500" onClick={() => handleDeleteOffer(ezDealz._id)}>
                            Delete Offer
                          </a>
                        </div>
                      )}

                      <div className="text-center mt-3 mb-3">
                        {dealerId > 0 ? (
                          <p className="font-bold text-gray-400">PAYMENT OPTIONS</p>
                        ) : (
                          <p className="font-bold text-gray-400">PERSONALIZE YOUR DEAL</p>
                        )}
                      </div>

                      <div className="mt-3">
                        <Row className="flex items-center justify-around">
                          <Col span={12}>
                            <span className="font-bold text-gray-600">Down Payment</span>
                          </Col>
                          <Col span={12} className="float-right">
                            <InputNumber
                              disabled
                              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                              className="placeholder:text-xs placeholder:text-inputColor text-inputColor p-1 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full w-full"
                              value={mainDownPayment[ezDealz._id]}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Slider
                              disabled={ezDealz._id !== editOfferId || loading}
                              range
                              min={minDownPayment}
                              max={maxDownPayment}
                              step={downPaymentStep}
                              marks={marksDownPayment}
                              onChange={(value) => onChangeDownPayment(value, ezDealz._id)}
                              value={mainDownPayment[ezDealz._id]}
                              className="ml-5 mr-5"
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="mt-3">
                        <Row className="flex items-center justify-around">
                          <Col span={12}>
                            <span className="font-bold text-gray-600">Terms</span>
                          </Col>
                          <Col span={12}>
                            <InputNumber
                              disabled
                              min={36}
                              max={72}
                              className="placeholder:text-xs placeholder:text-inputColor text-inputColor p-1 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full w-full"
                              value={`${mainTerm[ezDealz._id]} Months`}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Slider
                              disabled={ezDealz._id !== editOfferId || loading}
                              range
                              min={36}
                              max={72}
                              step={12}
                              marks={DEFAULT_TERM_MARKS}
                              onChange={(value) => onChangeTerm(value, ezDealz._id)}
                              value={mainTerm[ezDealz._id]}
                            />
                          </Col>
                        </Row>
                      </div>

                      <Collapse
                        expandIconPosition="right"
                        defaultActiveKey={[activeKeyOffer]}
                        className="border-0 bg-white"
                        onChange={onChangeOffer}
                      >
                        <Panel header={<p className="font-bold text-primary">OFFER DETAILS</p>} key={index + 1}>
                          <div className="grid grid-cols-12">
                            <div className="col-span-7">
                              <p className="text-gray-400">Rate (APR)</p>
                            </div>
                            <div className="col-span-5 text-right">
                              <p className="font-bold text-blue-500">{ezDealz.aprPercentage}%</p>
                            </div>
                          </div>
                          <div className="grid grid-cols-12">
                            <div className="col-span-7">
                              <p className="text-gray-400">Monthly Payment</p>
                            </div>
                            <div className="col-span-5 text-right">
                              <p className="font-bold text-blue-500">{ezDealz.monthlyPayment}</p>
                            </div>
                          </div>

                          <Collapse
                            expandIconPosition="right"
                            defaultActiveKey={[activeKey]}
                            className="my-3 border-0 bg-white"
                            onChange={onChange}
                          >
                            <Panel
                              header={<p className="font-bold text-gray-300">DEAL DETAILS</p>}
                              key={`${index}_details`}
                            >
                              {Object.keys(ezDealz.details).map((key) =>
                                renderDetail(key, ezDealz.details[key], ezDealz)
                              )}
                            </Panel>

                            <Panel
                              header={<p className="font-bold text-gray-300">SALES TAXES & DEALER FEES</p>}
                              key={`${index}_sales_taxes_and_dealer_fees`}
                            >
                              {Object.keys(ezDealz.taxOffers).map((key) =>
                                renderDetail(key, ezDealz.taxOffers[key], ezDealz)
                              )}
                            </Panel>

                            <Panel
                              header={<p className="font-bold text-gray-300">CASH DOWN</p>}
                              key={`${index}_cash_down`}
                            >
                              {Object.keys(ezDealz.cashDown).map((key) =>
                                renderDetail(key, ezDealz.cashDown[key], ezDealz)
                              )}
                            </Panel>

                            <Panel
                              header={<p className="font-bold text-gray-300">TRADE IN</p>}
                              key={`${index}_trade_in`}
                            >
                              {Object.keys(ezDealz.tradeIn).length > 0
                                ? Object.keys(ezDealz.tradeIn).map((key) =>
                                    renderDetail(key, ezDealz.tradeIn[key], ezDealz)
                                  )
                                : 'No trade-in vehicle'}
                            </Panel>

                            <Panel header={<p className="font-bold text-gray-300">FINANCE</p>} key={`${index}_finance`}>
                              {Object.keys(ezDealz.finance).map((key) =>
                                renderDetail(key, ezDealz.finance[key], ezDealz)
                              )}
                            </Panel>
                          </Collapse>

                          {dealerId < 1 && (
                            <div className="mt-5">
                              <Button
                                disabled={ezDealz._id !== editOfferId || ezDealz.deposit > 0 || loading}
                                type="primary"
                                className="w-full my-4 rounded-lg relative font-bold"
                                onClick={() => handleIllTakeIt(ezDealz._id)}
                              >
                                {ezDealz.deposit > 0
                                  ? `Deposit pending for $${ezDealz.deposit.toLocaleString()}`
                                  : 'ACCEPT OFFER'}
                              </Button>
                            </div>
                          )}
                          {dealerId > 0 && ezDealz.deposit > 0 && !loading && (
                            <div className="mt-5 text-center font-bold">
                              {`Deposit pending for $${ezDealz.deposit.toLocaleString()}`}
                            </div>
                          )}
                        </Panel>
                      </Collapse>
                    </div>
                  )
                })}

              {ezDealzData.length < 5 && dealerId > 0 && (
                <div
                  key={ezDealzData.length + 1}
                  className="bg-white items-center justify-center p-4 pt-0 mb-6 rounded-lg"
                >
                  <div className="mb-3 text-center">
                    <p className="font-bold text-gray-400 mt-3">ADD A VEHICLE</p>
                  </div>
                  <div className="mb-3 text-center">
                    <Search
                      className="placeholder:text-xs placeholder:text-inputColor text-inputColor border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded w-full"
                      placeholder="Search in inventory"
                      defaultValue={searchVehicle}
                      onSearch={onSearchVehicle}
                      onChange={(event) => searchQuery(event.target.value)}
                      allowClear
                      enterButton
                    />
                    {showSearch && searchedCars?.length > 0 && (
                      <div className="dropdown">
                        <ul className="bg-white border border-gray-300 rounded-md w-auto">
                          {searchedCars?.map((car, index) => (
                            <li
                              key={index}
                              onClick={() => {
                                selectSearchedValue(car)
                              }}
                              className="hover:bg-gray-50 list-none text-sm px-2 py-3 cursor-pointer"
                              role="presentation"
                            >
                              {car}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div>
                    <Table
                      showHeader={false}
                      className="my-leads-table text-center"
                      pagination={{ position: ['none', 'none'], pageSize: rowsPerPage }}
                      columns={tableColumns}
                      dataSource={buyCars}
                      onChange={handleTableChange}
                    />
                    <div className="flex justify-center mb-8 pagination-margin-top-20">
                      <div className="flex items-center">
                        <Pagination
                          className="flex"
                          current={page}
                          responsive
                          total={totalBuyCars}
                          pageSize={rowsPerPage}
                          showSizeChanger={false}
                          onChange={handleTableChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <Modal
            visible={modalFor !== ''}
            title={renderModalTitle(modalFor)}
            footer={[
              <Button
                type="primary"
                onClick={() => {
                  setModalFor('')
                  setDeleteOfferId('')
                  setDepositOfferId('')
                }}
              >
                Close
              </Button>
            ]}
            onCancel={() => {
              setModalFor('')
              setDeleteOfferId('')
              setDepositOfferId('')
            }}
          >
            {renderModalBody(modalFor)}
          </Modal>
        </div>
      )}
    </div>
  )
}
