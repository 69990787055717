/* eslint-disable no-await-in-loop */
import { InboxOutlined } from '@ant-design/icons'
import { message, Modal, Upload, Button } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useState } from 'react'
import * as DealerService from '../../services/dealer.service'

const { Dragger } = Upload

function beforeUpload(file) {
  const isCsv = file.type === 'text/csv'
  if (!isCsv) {
    message.error('You can only upload CSV file!')
  }
  const isLt5M = file.size / 1024 / 1024 <= 5
  if (!isLt5M) {
    message.error('File must smaller than or equal 5MB!')
  }
  return isCsv && isLt5M
}

function UploadInventoryModal({ dealerId, showModal, handleCloseModal, handleRefetchVehicle }) {
  const inventorySyndicationTemplateLink = DealerService.getInventorySyndicationTemplateLink(dealerId)

  const [isSuccess, setIsSuccess] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadId, setUploadId] = useState(0)
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  const [reportUrl, setReportUrl] = useState(null)
  const [buttonStatus, setButtonStatus] = useState('upload')
  const [buttonStatusDisabled, setButtonStatusDisabled] = useState(true)

  const handleChange = async (data) => {
    const { fileList } = data
    for (let index = 0; index < fileList.length; index += 1) {
      if (
        fileList[index].status === 'done' &&
        fileList[index].response.isSuccessful === 'Yes' &&
        fileList[index].response.data.uploadId > 0
      ) {
        setUploadId(fileList[index].response.data.uploadId)
        setSuccessMessage(null)
        setReportUrl(null)
        setErrorMessage(null)
        setButtonStatusDisabled(false)
        setIsUploading(false)
        setIsSuccess(true)
      } else if (
        fileList[index].status === 'done' &&
        fileList[index].response.isSuccessful === 'Yes' &&
        fileList[index].response.data.uploadId < 1
      ) {
        setSuccessMessage(null)
        setReportUrl(null)
        setErrorMessage(fileList[index].response?.data?.message)
        setIsUploading(false)
        setButtonStatusDisabled(true)
      } else if (fileList[index].status === 'uploading') {
        setErrorMessage(null)
        setSuccessMessage(null)
        setReportUrl(null)
        setIsUploading(true)
        setIsSuccess(false)
      }
    }
  }

  const buttonLabel = (status) => {
    let label = 'Upload'
    if (status === 'upload') {
      label = 'Upload'
    } else if (status === 'exit') {
      label = 'Exit Uploader'
    } else if (status === 'uploading') {
      label = 'Uploading...'
    }
    return label
  }

  const props = {
    action: `${process.env.REACT_APP_API_SERVER}/inventory-syndication-file-upload/${dealerId}`
  }

  const handleClose = () => {
    setErrorMessage(null)
    setSuccessMessage(null)
    setReportUrl(null)
    setButtonStatusDisabled(true)
    setButtonStatus('upload')
    setIsUploading(false)
    setIsSuccess(false)
    handleRefetchVehicle(true)
    handleCloseModal()
  }

  const handleButtonClick = (status) => {
    if (status === 'exit') {
      handleClose()
    }
    if (status === 'upload' && uploadId > 0) {
      setButtonStatus('uploading')
      DealerService.uploadFromSyndicationInventoryManualDropFile({ dealerId, uploadId })
        .then((response) => {
          const data = response.data
          console.log(data)
          setButtonStatusDisabled(false)
          setButtonStatus('exit')
          setSuccessMessage(data.message)
          if (data.report.length) {
            setReportUrl(data.report)
          }
          setIsUploading(false)
          setIsSuccess(false)
          setErrorMessage(null)
        })
        .catch((error) => {
          const errorMessage = error?.response?.data?.message ?? ''
          setButtonStatusDisabled(true)
          setButtonStatus('upload')
          setIsUploading(false)
          setIsSuccess(false)
          setIsSuccess(false)
          setSuccessMessage(null)
          setReportUrl(null)
          if (errorMessage.length) {
            setErrorMessage(errorMessage)
          }
        })
    }
  }

  return (
    <Modal
      centered
      visible={showModal}
      title="Upload Inventory"
      onOk={handleClose}
      onCancel={handleClose}
      okText="Close"
      className=""
      destroyOnClose
      width="40%"
      footer={[
        <div className="flex justify-between text-center text-gray-500">
          IMPORTANT: .csv file type permitted. CSV template updates regularly. We recommend you to download a new one
          each time you want to add new inventory. Do not change the name. Filename Ex. dealerid-inventory-MMDDYYYY.csv
        </div>
      ]}
    >
      <div className="w-full">
        <div className="p-2">
          <a href={inventorySyndicationTemplateLink} className="cursor-pointer text-primary" download>
            Download Template
          </a>
        </div>
        <div className="p-2">
          <Dragger
            {...props}
            name="file"
            listType="picture-card"
            className="avatar-uploader mt-4"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            accept="text/csv"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
          </Dragger>
        </div>
        {isSuccess && (
          <div className="p-2 mt-3">
            <p className="text-green-600">Success...</p>
          </div>
        )}
        {isUploading && (
          <div className="p-2 mt-3">
            <p className="text-gray-800">Checking file...</p>
          </div>
        )}
        {errorMessage && (
          <div className="p-2 mt-3">
            <p className="text-red-500">{errorMessage}</p>
          </div>
        )}
        {successMessage && (
          <div className="p-2 mt-3">
            <p className="text-green-600">{successMessage}</p>
          </div>
        )}
        {reportUrl && (
            <div className="p-2 mt-3">
              <a href={reportUrl} className="cursor-pointer text-primary" download>
                Download Report
              </a>
            </div>
        )}
        <div className="p-2 mt-3">
          <Button
            disabled={buttonStatusDisabled}
            type="primary"
            htmlType="submit"
            onClick={() => handleButtonClick(buttonStatus)}
            className="w-48 px-6 py-5 h-12 rounded-lg flex justify-center items-center gap-2 text-base font-semibold text-white bg-blue-700"
          >
            {buttonLabel(buttonStatus)}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

UploadInventoryModal.propTypes = {
  dealerId: PropTypes.number,
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleRefetchVehicle: PropTypes.func
}

export default UploadInventoryModal
