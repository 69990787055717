import { Button, Modal, notification, Table } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import * as CustomerServices from 'src/services/customer.service'

function SharedLinks({ id, showModalSharedLinks, setShowModalSharedLinks, type = 'trade-in' }) {
  const [listSharedLinks, setlistSharedLinks] = useState([])
  const baseUrl = window.location.origin

  const handleCutAccess = (code) => {
    CustomerServices.turnOffSharedLink(code)
      .then(() => {
        const sharedLinks = listSharedLinks.map((item) => {
          if (item.code === code) {
            return { ...item, is_valid: false }
          }
          return item
        })
        setlistSharedLinks(sharedLinks)
        notification.success({
          message: 'Success!',
          description: 'Successfully turned off sharing the link'
        })
      })
      .catch((error) => {
        notification.error({
          message: 'Error!',
          description: 'Failed to turn off sharing the link'
        })
        console.error(error)
      })
  }

  const columns = [
    {
      title: 'Dealership',
      dataIndex: ['dealer_name']
    },
    {
      title: 'Dealer Users',
      dataIndex: ['fullName']
    },
    {
      title: 'Access URL',
      dataIndex: 'code',
      width: '30%',
      className: 'overflow-hidden',
      ellipsis: true,
      render: (text, record) => {
        let link
        if (type === 'trade-in') {
          link = `${baseUrl}/sharing-a-vehicle-to-dealer/?code=${record?.code}&trade_id=${record?.object_id}`
        } else {
          link = `${baseUrl}/sharing-credit-application-to-dealer/?code=${record?.code}&credit_application_id=${record?.object_id}`
        }

        return (
          <a href={link} className="cursor-pointer text-primary" target="_blank" rel="noreferrer">
            {link}
          </a>
        )
      }
    },
    {
      title: 'Expiration Date',
      dataIndex: ['expiration']
    },
    {
      title: 'Action',
      dataIndex: 'code',
      render: (text, record) => {
        if (record.is_valid) {
          return (
            <Button type="primary" onClick={() => handleCutAccess(record.code)}>
              Remove access
            </Button>
          )
        }
        return ''
      }
    }
  ]

  useEffect(() => {
    if (id) {
      CustomerServices.getSharedLinksById({ id, type })
        .then((response) => {
          setlistSharedLinks(response.data)
        })
        .catch((error) => console.error(error))
    }
  }, [id, type])

  const handleChangeSharedlinks = () => setShowModalSharedLinks(!showModalSharedLinks)

  const handleRowClick = () => {}
  return (
    <Modal
      centered
      visible={showModalSharedLinks}
      title="Shared links"
      onOk={handleChangeSharedlinks}
      onCancel={handleChangeSharedlinks}
      okText="Close"
      className="calendar-modal"
      destroyOnClose
      width="50%"
      footer={[
        <Button type="primary" onClick={handleChangeSharedlinks}>
          Close
        </Button>
      ]}
    >
      <Table
        columns={columns}
        dataSource={listSharedLinks}
        onRow={(row) => ({
          onClick: () => handleRowClick(row)
        })}
      />
    </Modal>
  )
}

SharedLinks.propTypes = {
  id: PropTypes.string,
  showModalSharedLinks: PropTypes.bool,
  setShowModalSharedLinks: PropTypes.func,
  type: PropTypes.string
}

export default SharedLinks
