import { Button } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { AiOutlineLeft } from 'react-icons/ai'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useNavigate, useParams } from 'react-router-dom'
import CPO from '../../../assets/CPO.png'
import NoResults from '../../../assets/NoResults.png'
import VIP from '../../../assets/VIP.png'
import * as VehicleService from '../../../services/vehicle.service'
import { DealerCustomDeals } from '../DealerCustomDeals'
import DealerVehicleImage from './DealerVehicleImage'
import SimilarVehicles from './SimilarVehicles'
import './style.scss'
import VehicleDetail from './VehicleDetail'

function DealerVehicle({ createNewVehicle }) {
  const { id } = useParams()
  const navigate = useNavigate()
  const [carDetail, setCarDetail] = useState({})
  const [carOptions, setCarOptions] = useState([])

  const { vehicleDetail, similarVehicles = [] } = carDetail
  const {
    year,
    make,
    model,
    new_used_cert_flag: newUsedCertFlag,
    exterior_color_found: exteriorColorFound
  } = vehicleDetail || {}
  const [loading, setLoading] = useState(true)

  const getVehicleDetail = (id) => {
    VehicleService.getVehicleDetail(id)
      .then((response) => {
        setCarDetail(response.data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (id) {
      getVehicleDetail(id)
    }
  }, [id])

  useEffect(() => {
    if (carDetail) {
      if(Object.values(carDetail).length !== 0) {
        setCarOptions(carDetail.vehicleOptions)
      }
    }
  }, [carDetail])

  const handleNavigateDashboard = () => {
    navigate('/dealer-dashboard')
  }

  let stockImageSrc = null
  if (newUsedCertFlag) {
    if (newUsedCertFlag === 'N') {
      stockImageSrc = CPO
    } else if (newUsedCertFlag === 'U') {
      stockImageSrc = VIP
    }
  }

  let exteriorColorFoundFlag = null
  if (exteriorColorFound) {
    exteriorColorFoundFlag = exteriorColorFound
  }

  const hideOnProduction = process.env.REACT_APP_HIDE_ON_PRODUCTION === 'true'

  // added tw line
  return (
    <div className="pt-12">
      <div className="container">
        <div className="mb-4 flex justify-between">
          <div className="flex gap-3 items-center">
            <Button onClick={handleNavigateDashboard} className="text-blue-500" type="text" icon={<AiOutlineLeft />} />
            <div>
              <p className="text-lg font-bold">{loading ? 'Loading' : `${year} ${make} ${model}`}</p>
              <p className="text-sm">Edit your vehicle details, organize pictures or change pricing.</p>
            </div>
          </div>
        </div>
        <div className=" rounded border p-4 mb-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-10">
            {stockImageSrc && <img src={stockImageSrc} alt="CPO" className="w-20 md:w-28 absolute z-10" />}
            {!exteriorColorFoundFlag && (
              <img src={NoResults} alt="CPO" className="w-20 md:w-28 absolute z-10 sm:w-22" />
            )}
            <div className="lg:col-span-7">
              <DealerVehicleImage vehicleId={id} images={vehicleDetail?.photos} />
            </div>
            <div className="lg:col-span-5">
              <VehicleDetail
                loading={loading}
                info={vehicleDetail}
                id={id}
                setCarDetail={setCarDetail}
                carOptions={carOptions}
                similarVehicles={similarVehicles}
                createNewVehicle={createNewVehicle}
              />
            </div>
          </div>
          {!createNewVehicle && hideOnProduction && (
            <>
              <div className="border-t py-5" />
              <DealerCustomDeals editPage vehicleId={id} />
            </>
          )}
        </div>
        {!createNewVehicle && <SimilarVehicles cars={similarVehicles} />}
      </div>
    </div>
  )
}

DealerVehicle.propTypes = {
  createNewVehicle: PropTypes.bool
}

export default DealerVehicle
