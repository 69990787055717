import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Modal, Table, Tabs } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { maskNumberThousands } from 'src/utils'
import { useAuthorizationContext } from '../../../AuthorizationProvider'
import { TABLE_TYPES } from '../../AdminDashboard/PricingTables/constants'

const { TabPane } = Tabs

const tabKeys = {
  AnnualSchedule: {
    id: 'AnnualSchedule',
    name: 'Annual Schedule',
    tableType: TABLE_TYPES.SMALL_TABLE,
    pricingTable: 'basic'
  },
  MonthlySchedule: {
    id: 'MonthlySchedule',
    name: 'Monthly Schedule',
    tableType: TABLE_TYPES.SMALL_TABLE,
    pricingTable: 'basic'
  }
}

const tableColumnsAmortizationSchedule = [
  {
    title: 'Instalment',
    dataIndex: 'instalment',
    key: 'instalment'
  },
  {
    title: 'Beginning Balance',
    dataIndex: 'beginningBalanceLabel',
    key: 'beginningBalanceLabel'
  },
  {
    title: 'Interest',
    dataIndex: 'interestLabel',
    key: 'interestLabel'
  },
  {
    title: 'Principal',
    dataIndex: 'principalLabel',
    key: 'principalLabel'
  },
  {
    title: 'Ending Balance',
    dataIndex: 'endingBalanceLabel',
    key: 'endingBalanceLabel'
  }
]

function PaymentBreakDownModal({ showModal, handleCloseModal, financeCalculation }) {
  const [cookie] = useCookies()
  const { accountSettings } = useAuthorizationContext()

  const [zipCode, setZipCode] = useState(accountSettings?.zip ?? '')
  const [zipCodeError, setZipCodeError] = useState(false)
  const [resolvedZip, setResolvedZip] = useState(true)
  const [visibleInfo, setIsVisibleInfo] = useState(false)

  const [taxesAndFees, setTaxesAndFees] = useState(0)
  const [salesTaxes, setSalesTaxes] = useState(0)
  const [dealerFees, setDealerFees] = useState(0)
  const [dealerFeesObj, setDealerFeesObj] = useState({})
  const [estimatedCashPayment, setEstimatedCashPayment] = useState(0)

  const [visible, setVisible] = useState(false)
  const [visibleCashTerm, setVisibleCashTerm] = useState(false)
  const [contentHeight, setContentHeight] = useState(0)
  const contentRef = useRef(null)

  const [contentHeightCashTerm, setContentHeightCashTerm] = useState(0)
  const contentRefCashTerm = useRef(null)

  const [tabKey, setTabKey] = useState('AnnualSchedule')
  const [showAmortizationScheduleModal, setShowAmortizationScheduleModal] = useState(false)
  const [tableDataAmortizationSchedule, setTableDataAmortizationSchedule] = useState([])
  const [amortizationAnnualArray, setAmortizationAnnualArray] = useState(0)
  const [amortizationMonthlyArray, setAmortizationMonthlyArray] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (showModal) {
      setLoading(true)
      let zipCodeValue = zipCode

      if (cookie.customer_zip_code && cookie.customer_zip_code > 0) {
        zipCodeValue = cookie.customer_zip_code
      }

      if (zipCodeValue) {
        setZipCode(zipCodeValue)
        setResolvedZip(true)
      }

      setTaxesAndFees(financeCalculation?.totalTaxesAndFeesInteger)
      setSalesTaxes(parseFloat(financeCalculation?.salesTaxesCalculated))
      setDealerFees(financeCalculation?.dealerFeesLabel)
      setDealerFeesObj(financeCalculation?.dealerFeesArray)
      setEstimatedCashPayment(financeCalculation?.paymentTotalInteger)
      setAmortizationAnnualArray(financeCalculation?.amortizationAnnualArray)
      setAmortizationMonthlyArray(financeCalculation?.amortizationMonthlyArray)

      setLoading(false)
    }
  }, [showModal])

  useEffect(() => {
    setContentHeight(contentRef?.current?.clientHeight ?? 250)
  }, [visible])

  useEffect(() => {
    setContentHeightCashTerm(contentRefCashTerm?.current?.clientHeight ?? 250)
  }, [visibleCashTerm])

  useEffect(() => {
    if (!resolvedZip) {
      setIsVisibleInfo(true)
    } else {
      setIsVisibleInfo(false)
    }
  }, [resolvedZip])

  const showContent = () => {
    setVisible(!visible)
  }

  const showContentCashTerm = () => {
    setVisibleCashTerm(!visibleCashTerm)
  }

  const cashDownLabel = () => {
    if (financeCalculation?.cashDown !== undefined) {
      return financeCalculation?.cashDown.toLocaleString()
    }
    return '0'
  }

  const handleChangeZipCode = (event) => {
    const zipCodeValue = event.target.value.replace(/\D/g, '')
    setZipCode(zipCodeValue)
    if (zipCodeValue.length >= 4 && zipCodeValue.length <= 5) {
      setZipCode(zipCodeValue)
      setZipCodeError(false)
      setResolvedZip(true)
    } else {
      setZipCodeError(true)
    }
  }

  const onChangeOpenAmortizationSchedule = () => {
    setShowAmortizationScheduleModal(!showAmortizationScheduleModal)
    setTableDataAmortizationSchedule(amortizationAnnualArray)
  }

  const onChangeTabKey = (key) => {
    if (key === 'AnnualSchedule') {
      setTableDataAmortizationSchedule(amortizationAnnualArray)
    }
    if (key === 'MonthlySchedule') {
      setTableDataAmortizationSchedule(amortizationMonthlyArray)
    }

    setTabKey(key)
  }

  const resetAmortizationSchedule = () => {
    setShowAmortizationScheduleModal(false)
    setTabKey('AnnualSchedule')
  }

  return (
    <>
      <Modal
        centered
        visible={showModal}
        title="Payment Breakdown"
        onCancel={handleCloseModal}
        className="calendar-modal"
        destroyOnClose
        width={650}
        footer={[]}
        key="payment-break-down-modal"
      >
        {loading ? (
          <AiOutlineLoading3Quarters className="text-xl animate-spin" />
        ) : (
          [
            <Tabs className="finance-cash-tabs" type="card" size="large">
              <TabPane tab={<p className="leading-2 font-semibold text-center">Finance</p>} key="finance">
                <div className="px-2 payment-breakdown pt-5 pb-8">
                  <div className="text-sm flex justify-between">
                    <div className="flex flex-col gap-2">
                      <span className="font-bold">Term</span>
                      <span className="font-semibold">{financeCalculation?.term} mos</span>
                    </div>
                    <div className="flex flex-col gap-2">
                      <span className="font-bold">APR</span>
                      <span className="font-semibold">{financeCalculation?.apr}%</span>
                    </div>
                    <div className="flex flex-col gap-2">
                      <span className="font-bold">Cash Down</span>
                      <span className="font-semibold text-right">{`$${maskNumberThousands(
                        financeCalculation?.cashDown || 0,
                        true
                      )}`}</span>
                    </div>
                  </div>

                  <div className="border-t my-3 border-gray-500" />

                  <div className="flex justify-between mb-3 text-sm">
                    <div>Vehicle Price</div>
                    <div>{`$${maskNumberThousands(financeCalculation?.vehiclePrice || 0, true)}`}</div>
                  </div>

                  <div className="flex justify-between mb-3 text-sm">
                    <div>Cash Down</div>
                    <div className="text-green-500">{`-$${cashDownLabel()}`}</div>
                  </div>

                  <div className="flex justify-between mb-3 text-sm">
                    <div>Zip Code</div>
                    <div>
                      <input
                        maxLength={5}
                        name="zipCode"
                        className={`ext-right border ${
                          zipCodeError ? 'border-red-500' : 'border-lightGray'
                        } text-right border text-blue-800 focus:text-blueFv-600 focus:outline-none`}
                        placeholder="e.g. 10116"
                        onChange={handleChangeZipCode}
                        value={zipCode}
                        type="text"
                      />
                    </div>
                  </div>
                  {visibleInfo && (
                    <p className="text-red-800 text-xs">Your zip code could not be resolved, please fill in manually</p>
                  )}
                  <div className="flex justify-between text-sm" onClick={showContent} role="presentation">
                    <div>Taxes & Fees</div>
                    <div className="flex gap-1 items-center">
                      <span>${maskNumberThousands(taxesAndFees, true)}</span>
                      <span
                        className="cursor-pointer text-blue-600 text-md -mt-2"
                        onClick={showContent}
                        role="presentation"
                      >
                        {!visible ? <PlusOutlined /> : <MinusOutlined />}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between mb-3 text-sm">
                    <div
                      className="w-full transition-all duration-300 overflow-hidden"
                      style={{ maxHeight: !visible ? 0 : `${contentHeight}px` }}
                    >
                      <div className="pt-2" ref={contentRef}>
                        <div className="pt-2" ref={contentRef}>
                          <div className="flex justify-between mb-2">
                            <p className="text-sm">Sales Taxes</p>
                            <p className="text-sm">${maskNumberThousands(salesTaxes, true)}</p>
                          </div>
                          <div className="flex justify-between mb-1">
                            <p className="text-sm">Dealer Fees</p>
                            <p className="text-sm">{dealerFees}</p>
                          </div>
                          {dealerFeesObj?.fees?.map((item, index) => {
                            return (
                              <div className="flex justify-between" key={index}>
                                <p className="text-sm ml-3.5">{item.name}</p>
                                <p className="text-sm">{item.valueCurrency}</p>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-t mb-3 border-gray-500" />

                  <div className="flex justify-between text-sm font-bold mb-3">
                    <div>Est. Cash Payment</div>
                    <div>{`$${maskNumberThousands(estimatedCashPayment, true)}`}</div>
                  </div>

                  <div className="border-t mb-3 border-gray-500" />

                  <div className="text-sm px-4 w-full pt-4">
                    <p
                      className="text-blue-600 text-lg text-center cursor-pointer"
                      onClick={onChangeOpenAmortizationSchedule}
                    >
                      See Amortization Schedule
                    </p>
                  </div>
                </div>
              </TabPane>
              <TabPane
                tab={
                  <>
                    <p className="leading-2 text-center">Cash</p>
                    <p className="text-primary text-center text-base font-semibold leading-2">or Use My Bank</p>
                  </>
                }
                key="cash"
              >
                <div className="px-2 payment-breakdown pt-5 pb-8">
                  <div className="flex justify-between mb-3 text-sm">
                    <div>Vehicle Price</div>
                    <div>{`$${maskNumberThousands(financeCalculation?.vehiclePrice || 0, true)}`}</div>
                  </div>

                  <div className="flex justify-between mb-3 text-sm">
                    <div>Zip Code</div>
                    <div>
                      <input
                        maxLength={5}
                        name="zipCode"
                        placeholder="e.g. 10116"
                        className={`ext-right border ${
                          zipCodeError ? 'border-red-500' : 'border-lightGray'
                        } text-right border text-blue-800 focus:text-blueFv-600 focus:outline-none`}
                        onChange={handleChangeZipCode}
                        value={zipCode}
                        type="text"
                      />
                    </div>
                  </div>

                  {visibleInfo && (
                    <p className="text-red-800 text-xs">Your zip code could not be resolved, please fill in manually</p>
                  )}

                  <div className="flex justify-between text-sm" onClick={showContentCashTerm} role="presentation">
                    <div>Taxes & Fees</div>
                    <div className="flex gap-1 items-center">
                      <span>${maskNumberThousands(taxesAndFees, true)}</span>
                      <span
                        className="cursor-pointer text-blue-600 text-md -mt-2"
                        onClick={showContentCashTerm}
                        role="presentation"
                      >
                        {!visibleCashTerm ? <PlusOutlined /> : <MinusOutlined />}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between mb-3 text-sm">
                    <div
                      className="w-full transition-all duration-300 overflow-hidden"
                      style={{ maxHeight: !visibleCashTerm ? 0 : `${contentHeightCashTerm}px` }}
                    >
                      <div className="pt-2" ref={contentRefCashTerm}>
                        <div className="pt-2" ref={contentRefCashTerm}>
                          <div className="flex justify-between mb-2">
                            <p className="text-sm">Sales Taxes</p>
                            <p className="text-sm">${maskNumberThousands(salesTaxes, true)}</p>
                          </div>
                          <div className="flex justify-between mb-1">
                            <p className="text-sm">Dealer Fees</p>
                            <p className="text-sm">{dealerFees}</p>
                          </div>
                          {dealerFeesObj?.fees?.map((item) => {
                            return (
                              <div className="flex justify-between">
                                <p className="text-sm ml-3.5">{item.name}</p>
                                <p className="text-sm">{item.valueCurrency}</p>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-t mb-3 border-gray-500" />

                  <div className="flex justify-between text-sm font-bold mb-3">
                    <div>Est. Cash Payment</div>
                    <div>{`$${maskNumberThousands(estimatedCashPayment, true)}`}</div>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          ]
        )}
      </Modal>
      <Modal
        centered
        title="Amortization Schedule"
        visible={showAmortizationScheduleModal}
        closable
        onCancel={() => resetAmortizationSchedule()}
        className="calendar-modal"
        width={750}
        footer={null}
        key="amortization-schedule-modal"
      >
        <div className="w-full text-center">
          <Tabs
            className="pricing-tables"
            defaultActiveKey={tabKey}
            activeKey={tabKey}
            tabPosition="top"
            onChange={onChangeTabKey}
            renderTabBar={(props, TabNavList) => <TabNavList {...props} mobile />}
          >
            {Object.keys(tabKeys).map((key) => (
              <TabPane tab={tabKeys[key].name} key={tabKeys[key].id}>
                {Object.keys(tableDataAmortizationSchedule).map((key) => (
                  <Table
                    columns={tableColumnsAmortizationSchedule}
                    dataSource={tableDataAmortizationSchedule[key].instalments}
                    className="mt-5"
                    scroll={{ x: 'max-content' }}
                    pagination={false}
                    footer={() => tableDataAmortizationSchedule[key].yearName}
                  />
                ))}
              </TabPane>
            ))}
          </Tabs>
        </div>
      </Modal>
    </>
  )
}

PaymentBreakDownModal.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  financeCalculation: PropTypes.object
}

export default PaymentBreakDownModal
