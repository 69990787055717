import { Divider } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import { useEzDealzWalletContext } from 'src/EzDealzWalletProvider'
import userAvator from 'src/assets/icon/userAvator.png'
import { MenuLinkAccountDashboard, STORAGE_KEYS, USER_ROLES } from 'src/constants/constants'
import { handleClearStorageUserInfo } from 'src/lib/storage'
import { getHomeUrl } from 'src/utils'
import Logo from '../../../assets/new-logo.png'
import './style.scss'

function HeaderDashboard() {
  const { handleCustomerAccountLogin, setCustomerAccount, customerAccount, setAccountSettings } =
    useAuthorizationContext()
  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = window.innerWidth < 1024
  const [mobileMenu, setMobileMenu] = useState(false)
  const prevScrollPosRef = useRef(0)
  const sharedByDealership = sessionStorage.getItem(STORAGE_KEYS.IS_SHARED_BY_DEALERSHIP) === 'true'
  const dealershipId = sessionStorage.getItem(STORAGE_KEYS.SHARED_BY_DEALERSHIP_ID)
  const { setShowWalletModal } = useEzDealzWalletContext()

  const showMobileMenu = () => {
    setMobileMenu(true)
    document.querySelector('body').setAttribute('style', 'overflow: hidden;')
  }

  const closeMobileMenu = () => {
    setMobileMenu(false)
    document.querySelector('body').setAttribute('style', '')
  }

  const handleLogout = () => {
    handleClearStorageUserInfo()
    handleCustomerAccountLogin(false)
    setCustomerAccount({})
    setAccountSettings({})
    navigate('/')
  }

  const customerFirstName = customerAccount?.first_name || ''
  const customerAvatar = customerAccount?.avatar ? customerAccount?.avatar : userAvator

  const handleScroll = useCallback(() => {
    if (isMobile) {
      const scrollY = window.pageYOffset || document.documentElement.scrollTop
      const header = document.getElementById('header-dashboard')
      const height = header.offsetHeight
      if (scrollY < prevScrollPosRef.current) {
        header.style.visibility = 'visible'
        header.style.position = 'sticky'
      } else if (scrollY > height) {
        header.style.visibility = 'hidden'
        header.style.position = ''
      } else if (scrollY < 1) {
        header.style.position = 'initial'
      }
      prevScrollPosRef.current = scrollY
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  const handleClickLogo = () => {
    const role = customerAccount?.role || ''
    if (role === USER_ROLES.CUSTOMER) {
      navigate('/my-account')
      setMobileMenu(false)
    }
  }

  const isHomePage = window.location.pathname === '/'
  const handleSeeMoreDeals = () => {
    closeMobileMenu()
    const y = document.getElementById('best-deals-of-the-week').getBoundingClientRect().top + window.scrollY
    window.scroll({
      top: y,
      behavior: 'smooth'
    })
  }

  const isHideOnProduction = process.env.REACT_APP_HIDE_ON_PRODUCTION === 'true'

  const handleClearCache = () => {}

  return (
    <>
      <div id="header-dashboard" className="md:sticky md:shadow-lg left-0 top-0 w-full z-50" style={{ top: '-2px' }}>
        <div className="bg-white header-nav">
          <div
            className={`container ml-container mx-auto flex items-center 
          justify-end gap-1 py-1 pt-3 ${isMobile ? 'pb-5' : 'pr-4'}`}
          >
            <div className="flex cursor-pointer" onClick={handleClickLogo}>
              <img
                className="sm:ml-2 mr-1 w-8 p-1 rounded-full ring-1 ring-gray-300 dark:ring-gray-500"
                src={customerAvatar}
                alt="HL"
              />
            </div>
            <div className="flex gap-1">
              <p>Hi</p>
              <p className="cursor-pointer" onClick={handleClickLogo}>
                {customerFirstName && <span className="hover:text-primary">{customerFirstName}</span>},
              </p>
              <p>welcome back!</p>
              <Divider type="vertical" />
              <p onClick={handleLogout} className="cursor-pointer hover:text-primary">
                Logout
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-row items-center justify-between bg-white">
          <div className="container ml-container mx-auto flex items-center">
            <div className="w-full flex">
              <Link to={getHomeUrl({ sharedByDealership, dealershipId })}>
                <div className="flex gap-1">
                  <img
                    className={`customer-dashboard-logo ${isMobile ? 'mobile py-2' : ''} ${
                      isHideOnProduction ? '' : 'width-240'
                    } transition-all delay-150 duration-300 ease-in-out`}
                    src={Logo}
                    alt="Logo"
                  />
                </div>
              </Link>
            </div>

            <div className="lg:flex flex-row w-full items-center justify-end">
              <ul className="hidden list-none lg:flex flex-row items-end ml-0 lg:ml-10 nav-style">
                {MenuLinkAccountDashboard(sharedByDealership)
                  .filter((item) => !item.hidden)
                  .map((menu, index) => (
                    <li
                      className={`menu-item ${location.pathname === menu.link ? 'active' : ''} ${
                        menu.hidden ? 'hidden' : ''
                      }`}
                      key={`menu-item-${index}`}
                      onClick={menu.link ? handleClearCache : setShowWalletModal}
                    >
                      {menu.link ? (
                        <Link to={menu.link}>
                          <div className="px-0 py-1">{menu.name}</div>
                        </Link>
                      ) : (
                        <div className="cursor-pointer px-0 py-1">{menu.name}</div>
                      )}
                    </li>
                  ))}
              </ul>
              <svg
                stroke="currentColor"
                fill="currentColor"
                viewBox="0 0 24 24"
                className="h-6 w-auto lg:hidden cursor-pointer float-right mobile-nav-menu"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
                onClick={showMobileMenu}
              >
                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      {mobileMenu && (
        <div className="w-screen h-screen fixed top-0 left-0 overflow-y-scroll bg-white mobile-menu pb-6">
          <div className="h-full flex flex-col px-4">
            <div className="flex mobile-img flex-row justify-between py-4 items-center border-b">
              <img src={Logo} alt="" className="customer-dashboard mobile-menu-logo object-contain" />
              <button type="button" className="py-1 px-1 outline-none" onClick={closeMobileMenu}>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  className="h-8 w-auto"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
              </button>
            </div>
            <div className="flex flex-col justify-end w-full mx-auto">
              <ul className="flex flex-col list-none gap-6 items-baseline text-xl mb-10 mt-3 mobile">
                {MenuLinkAccountDashboard(sharedByDealership)
                  .filter((item) => !item.hidden)
                  .map((menu, index) => (
                    <li
                      className={`block w-full text-primary mb-2 ${location.pathname === menu.link ? 'active' : ''} ${
                        menu.hidden ? 'hidden' : ''
                      }`}
                      key={`menu-mobile-item-${index}`}
                      onClick={menu.link ? closeMobileMenu : setShowWalletModal}
                      role="presentation"
                    >
                      {menu.link ? (
                        <Link to={menu.link}>
                          <div className="px-3 py-2 rounded-xl hover:bg-primary hover:text-white menu-link">
                            {menu.name}
                          </div>
                        </Link>
                      ) : (
                        <div className="cursor-pointer px-0 py-1">{menu.name}</div>
                      )}
                    </li>
                  ))}
              </ul>
              {isHomePage && (
                <div className="flex justify-center">
                  <button
                    type="button"
                    className="mt-6 rounded-xl border border-primary px-8 py-2 hover:bg-primary hover:text-white outline-none"
                    onClick={handleSeeMoreDeals}
                  >
                    See More Deals
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default HeaderDashboard
