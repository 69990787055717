import { CheckOutlined, EditOutlined } from '@ant-design/icons'
import { Calendar, Modal, notification } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import * as CustomerServices from 'src/services/customer.service'
import bookMarkImage from '../../../assets/finance/bookmark.png'
import vehicleImage from '../../../assets/finance/vehicle.png'

function ScheduleDriveStepFinal({
  startFinishYourDeal,
  startAutomotiveKBB,
  time,
  selectedData,
  setSelectedData,
  vehicleInfo,
  leadId
}) {
  const [calendarDate, setCalendarDate] = useState(selectedData)
  const [calendarVisible, setCalendarVisible] = useState(false)
  let {
    dealer_name: dealerName,
    dealer_address: dealerAddress,
    dealer_city: dealerCity,
    dealer_state: dealerState,
    dealer_zip: dealerZip,
    dealer_phone: dealerPhone
  } = vehicleInfo

  if (dealerName === undefined) {
    const vehicleDealer = vehicleInfo?.dealer

    dealerName = vehicleDealer?.dealer_name
    dealerAddress = vehicleDealer?.address
    dealerCity = vehicleDealer?.city
    dealerState = vehicleDealer?.state
    dealerZip = vehicleDealer?.zip
    dealerPhone = vehicleDealer?.phone
  }

  const handleUpdateSchedule = () => {
    CustomerServices.updateScheduleTestDrive({
      leadId,
      date: moment(calendarDate).format('YYYY-MM-DD')
    })
      .then(() => {
        notification.success({
          message: 'Success!',
          description: 'Successfully updated test drive schedule.'
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const onCalendarOK = () => {
    setCalendarVisible(false)
    setSelectedData(calendarDate)
    handleUpdateSchedule()
  }

  const onCalendarCancel = () => {
    setCalendarVisible(false)
    setCalendarDate(selectedData)
  }

  const onDateChange = (date) => {
    setCalendarDate(date)
  }

  const disabledDate = (current) => {
    return current && current < moment().endOf('day').subtract(1, 'days')
  }

  return (
    <>
      <p className="text-2xl text-black font-bold">You&apos;re All Set!</p>
      <p className="text-gray-400 text-base mb-8">
        If you have immediate questions, please call us at {` ${dealerPhone}`}.
      </p>
      <div className="bg-white rounded-xl overflow-hidden shadow mb-8">
        <div className="p-3 flex items-center">
          <div className="flex justify-center items-center w-7 h-7 bg-completedColor rounded-full text-white mr-4">
            <CheckOutlined />
          </div>
          <div>
            <p className="text-gray-400 text-sm">{`See you on the ${time} of`}</p>
            <p className="flex gap-2 text-lg font-bold text-black">
              {moment(selectedData).format('MMM DD, YYYY')}
              <span
                className="text-primary flex gap-1 items-center font-normal text-base cursor-pointer"
                onClick={() => setCalendarVisible(true)}
              >
                <EditOutlined className="text-lg" /> Edit
              </span>
            </p>
          </div>
        </div>
        <div className="p-4 bg-gray-300">
          <p className="text-lg font-bold text-black mb-3">How to Find Us</p>
          <div className="flex">
            <div className="w-1/3">
              <p className="text-gray-500 text-base mb-3">
                <span>{dealerName}</span>
                <br />
                {`${dealerAddress}, ${dealerCity}, ${dealerState} ${dealerZip}`}
              </p>
              <p className="text-sm text-primary cursor-pointer">Get Directions</p>
            </div>
          </div>
        </div>
      </div>
      <p className="text-2xl text-black font-bold">What would you like to do next?</p>
      <p className="text-gray-400 text-base mb-8">Take the next step to save time before heading to the dealership.</p>
      <div className="flex gap-3 justify-between pb-12 border-b border-black">
        <div className="shadow-lg rounded-lg flex gap-3 flex-col items-center overflow-hidden w-[60%] bg-white">
          <div className="bg-primary p-3 w-full">
            <p className="text-white text-sm font-semibold">Recommended Next Step</p>
          </div>
          <div className="flex flex-col p-4">
            <p className="text-black text-lg font-semibold mb-2">Value Your Vehicle</p>
            <p className="text-sm mb-7">
              Use Kelley Blue Book® to see what your current car is worth towards your new vehicle.
            </p>
            <div className="flex justify-between">
              <button
                type="button"
                className="w-7/12 bg-primary rounded-lg text-white text-sm py-2 hover:bg-activePrimary"
                onClick={startAutomotiveKBB}
              >
                Start
              </button>
              <img src={vehicleImage} alt="" className="w-[30px]" />
            </div>
          </div>
        </div>
        <div className="shadow-lg rounded-lg flex flex-col justify-between overflow-hidden w-[35%] bg-primary p-3">
          <p className="text-white text-sm font-semibold">Don&apos;t have a vehicle to trade-in?</p>
          <p className="text-sm mb-7 text-white">There are more ways for you to save time and money.</p>
          <button
            type="button"
            className="w-full bg-white rounded-lg text-primary text-sm py-2"
            onClick={startFinishYourDeal}
          >
            View My Deal
          </button>
        </div>
      </div>
      <div className="flex flex-col items-center px-6 py-4 justify-center">
        <img src={bookMarkImage} alt="" className="mb-2" />
        <p className="text-black font-semibold mb-2">Save Your Progress</p>
        <p className="text-gray-600 text-center text-xs mb-3">
          Use a custom link to return to your deal anytime, anywhere
        </p>
        <p className="cursor-pointer text-primary text-sm">Get My Deal Link</p>
      </div>
      <Modal
        centered
        visible={calendarVisible}
        closable={false}
        onOk={onCalendarOK}
        onCancel={onCalendarCancel}
        className="calendar-modal"
        width={340}
        destroyOnClose
      >
        <div className="w-full">
          <Calendar fullscreen={false} onChange={onDateChange} value={calendarDate} disabledDate={disabledDate} />
        </div>
      </Modal>
    </>
  )
}

ScheduleDriveStepFinal.propTypes = {
  startFinishYourDeal: PropTypes.func.isRequired,
  startAutomotiveKBB: PropTypes.func.isRequired,
  time: PropTypes.string,
  selectedData: PropTypes.any,
  setSelectedData: PropTypes.func,
  vehicleInfo: PropTypes.object,
  leadId: PropTypes.string
}

export default ScheduleDriveStepFinal
