import { notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { CustomDeals } from 'src/components/AdminDashboard/CustomDeals'
import { SearchDealerDashboard } from 'src/components/DealerDashboard/SearchDealerDashboard'
import { EngagementStatistic } from 'src/components/DealerDashboard/Statistic/EngagementStatistic'
import { VisitorStatistic } from 'src/components/DealerDashboard/Statistic/VisitorStatistic'
import { ConfigurableSettings } from 'src/components/AdminDashboard/ConfigurableSettings'
import * as DealerServices from 'src/services/dealer.service'
import metaPages from '../../data/metaPages.json'
import SinglePageMetaDecorator from '../../decorators/SinglePageMetaDecorator'
import metaPageImageAdminDashboard from '../../assets/background-image-login.png'
import './style.scss'

function AdminDashboard() {
  const [loading, setLoading] = useState(false)
  const [reloadDealers, setReloadDealers] = useState(false)
  const [searchText, setSearchText] = useState('')

  const searchableData = (searchData) => {
    setSearchText(searchData)
  }

  const openNotification = (data) => {
    const dealership = data.dealer_name
    notification.info({
      message: 'A new dealership has been claimed',
      description: `Dealership ${dealership} has been claimed.`,
      placement: 'top',
      duration: 5
    })
  }

  useEffect(() => {
    DealerServices.getNewClaimedList()
      .then((response) => {
        const { data } = response
        if (data?.length > 0) {
          openNotification(data[0])
        }
      })
      .catch((error) => console.error(error))
  }, [])

  return (
    <div className="container relative">
      <SinglePageMetaDecorator
        title={metaPages.adminDashboard.title}
        description={metaPages.adminDashboard.description}
        imageUrl={metaPageImageAdminDashboard}
        imageAlt={metaPages.adminDashboard.imageAlt}
      />
      <SearchDealerDashboard filterParentData={searchableData} isHidden isAdmin setReloadDealers={setReloadDealers} />
      <div className="mb-6 gap-5">
        <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 mb-5 gap-0 xl:gap-6 lg:gap-5 md:gap-4 sm:gap-0">
          <VisitorStatistic />
          <div className="col-span-2 mt-5">
            <EngagementStatistic />
          </div>
        </div>
        <div className="mx-auto mb-12 pt-6 px-4 rounded-xl border-2">
          <CustomDeals
            loading={loading}
            setLoading={setLoading}
            searchText={searchText}
            reloadDealers={reloadDealers}
            setReloadDealers={setReloadDealers}
            filterParentData={searchableData}
          />
        </div>
        <div className="mx-auto mb-12 pt-6 px-4 rounded-xl border-2">
          <ConfigurableSettings />
        </div>
      </div>
    </div>
  )
}

export default AdminDashboard
