/* eslint-disable no-useless-escape */
import { Form } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useState } from 'react'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import InputMask from 'react-input-mask'
import { VALID_EMAIL } from '../../../../constants/constants'

function ApplicationSection({ applicationFormRef }) {
  const [show, setShow] = useState(false)

  const handleChangeShow = () => {
    setShow(!show)
  }

  return (
    <Form layout="vertical" ref={applicationFormRef} name="application-ref">
      <div className="mt-4 step-4">
        <p className="text-2xl text-black font-bold mb-3">Applicant</p>
        <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-0 xl:gap-6 lg:gap-5 md:gap-4 sm:gap-0">
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[
              { required: true, message: 'First name is required' },
              { max: 20, message: 'First name cannot be longer than 20 characters' },
              { pattern: /^[a-zA-Z\s]+$/g, message: 'First name can only consist of letters' }
            ]}
            className="custom-antd-label block"
          >
            <input name="firstName" autoFocus className="w-full border border-black rounded py-2 px-3" type="text" />
          </Form.Item>
          <Form.Item
            name="middleName"
            label="Middle Name"
            rules={[
              { max: 20, message: 'Middle name cannot be longer than 20 characters' },
              { pattern: /^[a-zA-Z\s]+$/g, message: 'Middle name can only consist of letters' }
            ]}
            className="custom-antd-label block"
          >
            <input name="middleName" className="w-full border border-black rounded py-2 px-3" type="text" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[
              { required: true, message: 'Last name is required' },
              { max: 20, message: 'Last name cannot be longer than 20 characters' },
              { pattern: /^[a-zA-Z\s]+$/g, message: 'Last name can only consist of letter' }
            ]}
            className="custom-antd-label block"
          >
            <input name="lastName" className="w-full border border-black rounded py-2 px-3" type="text" />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Email is required' },
              {
                pattern: VALID_EMAIL,
                message: 'Email is invalid'
              }
            ]}
            className="custom-antd-label block"
          >
            <input name="email" className="w-full border border-black rounded py-2 px-3" type="text" />
          </Form.Item>

          <Form.Item
            name="dateOfBirth"
            label="Date of Birth"
            rules={[{ required: true, message: 'Date of birth is required' }]}
            className="custom-antd-label block"
          >
            <input name="dateOfBirth" className="w-full border border-black rounded py-2 px-3" type="date" />
          </Form.Item>

          <Form.Item
            name="socialSecurityNumber"
            label="Social Security Number"
            rules={[
              { required: true, message: 'SSN is required' },
              { pattern: /\d{3}[-]\d{2}[-]\d{4}/, message: 'SSN is invalid format' }
            ]}
            className="custom-antd-label block"
          >
            <div className="relative border border-black rounded">
              <InputMask
                mask="999-99-9999"
                name="socialSecurityNumber"
                placeholder="111-22-3333"
                className="w-full py-2 px-3 border-0 focus:outline-none"
                type={show ? 'text' : 'password'}
              />
              {show ? (
                <AiFillEyeInvisible
                  onClick={handleChangeShow}
                  className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                />
              ) : (
                <AiFillEye
                  onClick={handleChangeShow}
                  className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                />
              )}
            </div>
          </Form.Item>

          <Form.Item
            name="mobileNumber"
            label="Mobile Number"
            rules={[
              {
                pattern: /(\d{3}[-]\d{3}[-]\d{4})|(\d{10})/,
                message: 'Mobile number is invalid format'
              }
            ]}
            className="custom-antd-label block"
          >
            <InputMask
              name="mobileNumber"
              mask="999-999-9999"
              placeholder="NXX-NXX-XXXX"
              className="w-full border border-black rounded py-2 px-3"
              type="text"
            />
          </Form.Item>

          <Form.Item name="maritalStatus" label="Marital Status" className="custom-antd-label block">
            <select
              name="maritalStatus"
              className="w-full bg-white border border-black rounded py-2 px-3 select-option-sell-your-car"
            >
              <option>Select Marital Status</option>
              <option value="married">Married</option>
              <option value="single">Single</option>
              <option value="separated">Separated</option>
              <option value="widowed">Widowed</option>
              <option value="divorced">Divorced</option>
            </select>
          </Form.Item>
          <Form.Item
            name="citizenshipStatus"
            label="Citizenship Status"
            rules={[
              { required: true, message: 'Citizenship status is required' },
              ({ getFieldValue }) => ({
                validator() {
                  if (getFieldValue('citizenshipStatus') === 'Select Citizenship Status') {
                    return Promise.reject(new Error('Citizenship status is required'))
                  }
                  return Promise.resolve()
                }
              })
            ]}
            className="custom-antd-label mb-0 block"
          >
            <select
              name="citizenshipStatus"
              className="w-full bg-white border border-black rounded py-2 px-3 select-option-sell-your-car"
            >
              <option>Select Citizenship Status</option>
              <option>US Citizen</option>
              <option>Permanent Resident (green card holder)</option>
              <option>None of the above</option>
            </select>
          </Form.Item>
        </div>
      </div>
    </Form>
  )
}

ApplicationSection.propTypes = {
  applicationFormRef: PropTypes.object
}

export default ApplicationSection
