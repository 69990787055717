import { Button, Modal } from 'antd'
import * as PropTypes from 'prop-types'
import React from 'react'

function ZipCodePopup({
  zipCode,
  showZipCodePopupConfirm,
  setShowZipCodePopupConfirm,
  handleZipCodeUpdate,
  closable,
  maskClosable
}) {
  const handleClose = () => {
    setShowZipCodePopupConfirm(false)
  }

  return (
    <Modal
      centered
      title="Update zip code"
      visible={showZipCodePopupConfirm}
      closable={closable}
      maskClosable={maskClosable}
      onCancel={() => setShowZipCodePopupConfirm(false)}
      className="calendar-modal"
      width={450}
      footer={[
        <div className="flex justify-between gap-4">
          <Button block onClick={handleZipCodeUpdate}>
            Yes
          </Button>
          <Button block onClick={handleClose}>
            No
          </Button>
        </div>
      ]}
    >
      <div className="w-full text-center">
        <p>Would you like to save {zipCode} as you default zip code</p>
      </div>
    </Modal>
  )
}

ZipCodePopup.propTypes = {
  zipCode: PropTypes.any,
  showZipCodePopupConfirm: PropTypes.bool,
  setShowZipCodePopupConfirm: PropTypes.func,
  handleZipCodeUpdate: PropTypes.func,
  closable: PropTypes.bool,
  maskClosable: PropTypes.bool
}

export default ZipCodePopup
