import * as PropTypes from 'prop-types'
import React, { createContext, useContext, useEffect, useMemo } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { clarity } from 'react-microsoft-clarity'

const UserHelmetProvider = createContext({})

export function HelmetHeadProvider({ children }) {
  const helmetContext = {}
  const walletState = useMemo(() => ({}), [])

  useEffect(() => {
    clarity.init('ffbr0mkzud')
  }, [])

  return (
    <UserHelmetProvider.Provider value={walletState}>
      <HelmetProvider context={helmetContext}>{children}</HelmetProvider>
    </UserHelmetProvider.Provider>
  )
}

HelmetHeadProvider.propTypes = {
  children: PropTypes.node
}

export function useHelmetProvider() {
  return useContext(UserHelmetProvider)
}
