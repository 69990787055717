import queryString from 'query-string'
import httpService from './http.service'
import HttpClientService from './httpClient.service'
// eslint-disable-next-line import/named
import { SOURCE_SELL_YOUR_CAR, SOURCE_TRADE_IN } from '../constants/constants'

const getTradeInParams = (options) => {
  let dataParams = {}
  const { forStepWizardOnly } = options

  if (forStepWizardOnly) {
    const { make, model, year, ownership, firstName, lastName, email, stepWizard, tradeToVehicleId, vehicleId, trim } =
      options

    dataParams = {
      vehicle: {
        make,
        model,
        year,
        ownership,
        vehicleId,
        trim
      },
      customer: {
        firstname: firstName,
        lastname: lastName,
        email
      },
      stepWizard,
      tradeToVehicleId
    }
  } else {
    const {
      vin,
      make,
      model,
      year,
      trim,
      ownership,
      mileage,
      zipcode,
      owePrice,
      condition,
      engine,
      transmission,
      color,
      firstName,
      lastName,
      email,
      phone,
      comment,
      estimatedvalue,
      linkCode,
      modelId,
      makeId,
      trimId,
      stepWizard,
      vehicleId,
      tradeToVehicleId
    } = options

    const source = window.location.pathname !== '/sell' ? SOURCE_TRADE_IN : SOURCE_SELL_YOUR_CAR
    dataParams = {
      vehicle: {
        vin,
        make,
        model,
        year,
        trim,
        ownership,
        mileage,
        zipcode,
        owePrice,
        condition,
        engine,
        transmission,
        color,
        estimatedvalue,
        modelId,
        makeId,
        trimId,
        vehicleId
      },
      customer: {
        firstname: firstName,
        lastname: lastName,
        email,
        phone
      },
      comment,
      link_code: linkCode,
      source,
      stepWizard,
      tradeToVehicleId
    }
  }

  return dataParams
}

export function getCustomerDashboardInfo() {
  return HttpClientService.get('get-customer').then((result) => {
    const { data } = result
    return data
  })
}

export function setFavouriteVehicles(vehicles) {
  return HttpClientService.post('customer/favourite-vehicles', { favourite_vehicle: vehicles }).then((result) => {
    const { data } = result
    return data
  })
}

export function setCustomerCashDown({ cashDown }) {
  const params = {
    cash_down: cashDown
  }
  return HttpClientService.post('user-customer-cash-down-update', params).then((result) => {
    const { data } = result
    return data
  })
}

export function setCustomerZipCode({ zipCode }) {
  const params = {
    zip_code: zipCode
  }
  return HttpClientService.post('user-customer-zip-code-update', params).then((result) => {
    const { data } = result
    return data
  })
}

export function setFavouriteDealers(dealers) {
  return HttpClientService.post('customer/favourite-dealers', { favourite_dealer: dealers }).then((result) => {
    const { data } = result
    return data
  })
}

export function getListVehiclesByCustomer() {
  return HttpClientService.get('customer/vehicles').then((result) => {
    const { data } = result
    return data
  })
}

export function accountUpdate(params) {
  return HttpClientService.post('customer-account/update', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getFavoriteVehiclesByCustomer() {
  return HttpClientService.get('customer/favourite-vehicles').then((result) => {
    const { data } = result
    return data
  })
}

export function getFavoriteDealersByCustomer() {
  return HttpClientService.get('customer/favourite-dealers').then((result) => {
    const { data } = result
    return data
  })
}

export function handleSaveTrade({
  vin,
  make,
  model,
  year,
  trim,
  ownership,
  mileage,
  zipcode,
  owePrice,
  condition,
  engine,
  transmission,
  color,
  firstName,
  lastName,
  email,
  phone,
  comment,
  estimatedvalue,
  notLoggedIn,
  linkCode,
  modelId,
  makeId,
  trimId,
  stepWizard,
  vehicleId,
  tradeToVehicleId
}) {
  const source = window.location.pathname !== '/sell' ? SOURCE_TRADE_IN : SOURCE_SELL_YOUR_CAR
  const params = {
    vehicle: {
      vin,
      make,
      model,
      year,
      trim,
      ownership,
      mileage,
      zipcode,
      owePrice,
      condition,
      engine,
      transmission,
      color,
      estimatedvalue,
      modelId,
      makeId,
      trimId,
      vehicleId
    },
    customer: {
      firstname: firstName,
      lastname: lastName,
      email,
      phone
    },
    comment,
    link_code: linkCode,
    source,
    stepWizard,
    tradeToVehicleId
  }

  return HttpClientService.post(notLoggedIn ? 'trades/save' : 'trades', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getCustomerTrades() {
  return HttpClientService.get('customer/trades').then((result) => {
    const { data } = result
    return data
  })
}

export function getTradeDetail(id) {
  return HttpClientService.get(`trades/${id}`).then((result) => {
    const { data } = result
    return data
  })
}

export function checkExistingTradeIn(vehicleId) {
  return HttpClientService.get(`customer/tradein-by-vehicle/${vehicleId}`).then((result) => {
    const { data } = result
    return data
  })
}

export function switchOffer({ offerId, tradeInId }) {
  return HttpClientService.post('trades/switch-offer', { offer_id: offerId, trade_in_id: tradeInId }).then((result) => {
    const { data } = result
    return data
  })
}

export function updateTrade(id, params) {
  const dataParams = getTradeInParams(params)

  return HttpClientService.put(`trades/${id}`, dataParams).then((result) => {
    const { data } = result
    return data
  })
}

export function getSharedLinksById({ id, type }) {
  return HttpClientService.get(`customer/sharing-link/${type}/${id}`).then((result) => {
    const { data } = result
    return data
  })
}

export function turnOffSharedLink(code) {
  return HttpClientService.post(`sharing-link/turn-off-sharing/${code}`).then((result) => {
    const { data } = result
    return data
  })
}

export function saveOffer({ vehicleId, cashdown, frequency, name, tradeInId, calculationId }) {
  const params = {
    vehicle_id: vehicleId,
    finance: {
      cashdown,
      frequency,
      name
    },
    trade_in_id: tradeInId,
    calculation_id: calculationId
  }
  return HttpClientService.post('offers', params)
    .then((result) => {
      const { data } = result
      return data
    })
    .catch((error) => error.response)
}

export function saveDeposit(params) {
  return HttpClientService.post('offers', params)
    .then((result) => {
      const { data } = result
      return data
    })
    .catch((error) => error.response)
}

export function getCustomerOffers({ keyword, sort }) {
  const params = queryString.stringify({
    keyword,
    sort
  })
  return HttpClientService.get(`customer/offers?${params}`).then((result) => {
    const { data } = result
    return data
  })
}

export function getCustomerOfferByVehicleId(vehicleId) {
  return HttpClientService.get(`/customer/offer-by-vehicle/${vehicleId}`).then((result) => {
    const { data } = result
    return data
  })
}

export function handleStoreTrade({ make, model, year }) {
  const params = {
    vehicle: {
      make,
      model,
      year
    }
  }
  return HttpClientService.post('trades/store', params).then((result) => {
    const { data } = result
    return data
  })
}

export const handleSaveLeadTestDrive = ({
  firstName,
  lastName,
  phone,
  email,
  comments,
  vehicleId,
  time,
  date,
  step
}) => {
  return HttpClientService.post('save-lead-test-drive', {
    first_name: firstName,
    last_name: lastName,
    vehicle_id: vehicleId,
    phone,
    email,
    test_time: time,
    test_date: date,
    comments,
    step,
    page_url: window.location.href
  }).then((result) => {
    const { data } = result
    return data
  })
}

export function getLeadTestDriveByVehicleId(vehicleId) {
  return HttpClientService.get(`customer/schedule-test-drive/${vehicleId}`).then((result) => {
    const { data } = result
    return data
  })
}

export function getCustomerLeads(pagination) {
  const params = queryString.stringify(pagination)
  return HttpClientService.get(`customer/leads?${params}`).then((result) => {
    const { data } = result
    return data
  })
}

export function getCustomerDeals(customerDeals) {
  const params = queryString.stringify(customerDeals)
  return HttpClientService.get(`customer/credit-application-forms?${params}`).then((result) => {
    const { data } = result
    return data
  })
}

export function checkValidLink(code) {
  return httpService.get(`sharing-link/check-valid/${code}`).then((result) => {
    const { data } = result
    return data
  })
}

export function handleShareTradeInVehicleLInk(values) {
  return HttpClientService.post('sharing-link/share', values).then((result) => {
    const { data } = result
    return data
  })
}

export function updateCustomerLogo(file) {
  const formData = new FormData()
  formData.append('customer_logo', file)
  return HttpClientService.post('customer/update-logo', formData).then((result) => {
    const { data } = result
    return data
  })
}

export function createUpdateBudgetCalculator(payload) {
  const params = {
    data: payload
  }
  return HttpClientService.post('customer/create-update-budget-calculator', params).then((result) => {
    const { data } = result
    return data
  })
}

export function removeTrade(id) {
  return HttpClientService.remove(`trades/${id}`).then((result) => {
    const { data } = result
    return data
  })
}

export function saveTradeinToLoginCustomer(id) {
  return HttpClientService.post(`save-tradein-to-customer/${id}`, {}).then((result) => {
    const { data } = result
    return data
  })
}

export function getDealersForSharingLinks({ page, rowsPerPage, status }) {
  const params = queryString.stringify({
    page,
    per_page: rowsPerPage,
    status
  })

  return HttpClientService.get(`dealers-for-sharing-links?${params}`).then((result) => {
    const { data } = result
    return data
  })
}

export function getSharingLink(code) {
  return httpService.get(`sharing-link/get-link-details/${code}`).then((result) => {
    const { data } = result
    return data
  })
}

export const updateScheduleTestDrive = ({ leadId, date }) => {
  return HttpClientService.post(`update-schedule-test-drive/${leadId}`, {
    schedule_date: date
  }).then((result) => {
    const { data } = result
    return data
  })
}

export function updateOffer(id, params) {
  return HttpClientService.put(`offers/${id}`, params).then((result) => {
    const { data } = result
    return data
  })
}
