import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as CustomerServices from 'src/services/customer.service'

function TradeInVehicleDetail() {
  const navigate = useNavigate()
  const [verifying, setVerifying] = useState(true)
  const [searchParams] = useSearchParams()
  const linkCode = searchParams.get('code')
  const tradeId = searchParams.get('trade_id')
  const [trade, setTrade] = useState({})
  const { vehicle, customer, comment } = trade
  const {
    color,
    condition,
    engine,
    make,
    mileage,
    model,
    ownership,
    transmission,
    trim,
    vin,
    year,
    zipcode,
    estimatedvalue
  } = vehicle || {}
  const { email, firstname, lastname, phone } = customer || {}
  const fields = {
    year,
    make,
    model,
    engine,
    mileage,
    ownership,
    transmission,
    trim,
    vin,
    zipcode,
    color,
    condition,
    email,
    'first name': firstname,
    'last name': lastname,
    phone,
    comment,
    'Trade-in value': estimatedvalue ? `$${estimatedvalue.toLocaleString()}` : 0
  }

  useEffect(() => {
    if (linkCode) {
      CustomerServices.checkValidLink(linkCode)
        .catch(() => {
          navigate('/link-expired')
        })
        .finally(() => setVerifying(false))
    }
  }, [linkCode])

  useEffect(() => {
    if (!verifying) {
      CustomerServices.getTradeDetail(tradeId)
        .then((response) => {
          setTrade(response.data)
        })
        .catch((error) => console.error(error))
    }
  }, [tradeId, verifying])

  if (verifying) {
    return (
      <div style={{ fontSize: '32px' }} className="verification-expired-table">
        Verifying...
      </div>
    )
  }

  return (
    <div className="container md:w-full lg:w-3/4 xl:w-1/2 px-5 py-32 flex gap-10 flex-col">
      <div className="text-2xl text-center">Trade-in vehicle detail</div>
      <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2">
        {Object.keys(fields).map((key) => (
          <div key={key} className="flex gap-2 flex-row">
            <div className="capitalize font-semibold">{key}:</div>
            <div>{fields[key]}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TradeInVehicleDetail
