import { PlusOutlined } from '@ant-design/icons'
import { Button, Form, Modal, notification, Table } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { BsTrash } from 'react-icons/bs'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import * as CustomerServices from 'src/services/customer.service'

export function MyTradeIn({
  handleShowValueYourVehicle,
  selectedTrade,
  setSelectedTrade,
  selectedOffer,
  setValueYourVehicleStepStatus
}) {
  const { customerLogin } = useAuthorizationContext()
  const [form] = Form.useForm()
  const [, forceUpdate] = useState({})
  const [showMyTrade, setShowMyTrade] = useState(false)
  const [listTrades, setListTrades] = useState([])
  const [loading, setLoading] = useState(false)

  const [tradeIdToDelete, setTradeIdToDelete] = useState('')
  const [showConfirmDeleteTradeIn, setShowConfirmDeleteTradeIn] = useState(false)
  const [hasDeletedTrade, setHasDeletedTrade] = useState(false)

  const handleFormSubmission = () => {
    const values = form.getFieldsValue()
    CustomerServices.handleStoreTrade(values)
      .then((response) => {
        setListTrades([response.data, ...listTrades])
        setShowMyTrade(false)
        notification.success({
          message: 'Success!',
          description: 'Added a trade in.'
        })
      })
      .catch((error) => {
        console.error(error)
        notification.error({
          message: 'Error!',
          description: 'Cannot complete this action. Please try again!'
        })
      })
  }

  useEffect(() => {
    if (selectedTrade?._id) {
      CustomerServices.getTradeDetail(selectedTrade._id)
        .then((response) => {
          setSelectedTrade(response.data)
        })
        .catch((error) => console.error(error))
    }
  }, [selectedTrade?._id])

  const getCustomerTrades = () => {
    CustomerServices.getCustomerTrades()
      .then((response) => {
        const { data } = response
        setListTrades(data)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    forceUpdate({})
    if (customerLogin) {
      getCustomerTrades()
    }
  }, [customerLogin])

  useEffect(() => {
    if (hasDeletedTrade) {
      getCustomerTrades()
    }
  }, [hasDeletedTrade])

  const openNotification = ({ message, description, type = 'success' }) => {
    const action = type === 'success' ? notification.success : notification.error
    action({
      message,
      description
    })
  }

  const handleRowClick = (row) => {
    // we wont allow switching trade if vehicle is not yet added to ez deal
    if (!selectedOffer._id) {
      return
    }

    setLoading(true)
    CustomerServices.switchOffer({ offerId: selectedOffer._id, tradeInId: row._id })
      .then((response) => {
        const { isSuccessful } = response
        setSelectedTrade(response.data)
        setValueYourVehicleStepStatus('completed')
        if (isSuccessful === 'Yes') {
          openNotification({
            message: 'The offer was updated successfully.'
          })
        }
      })
      .catch((err) =>
        openNotification({
          message: "The offer can't be updated. Please try again later.",
          description: err.message,
          type: 'error'
        })
      )
      .finally(() => setLoading(false))
  }

  const handleDeleteTradeinConfirmModal = () => {
    setShowConfirmDeleteTradeIn(!showConfirmDeleteTradeIn)
  }

  const handleDeleteTradeinCancel = () => {
    setShowConfirmDeleteTradeIn(false)
  }

  const handleDeleteTradeinConfirm = () => {
    setHasDeletedTrade(false)
    CustomerServices.removeTrade(tradeIdToDelete)
      .then((response) => {
        const { isSuccessful } = response
        if (isSuccessful === 'Yes') {
          if (tradeIdToDelete === selectedTrade?._id) {
            setSelectedTrade({})
            setValueYourVehicleStepStatus('start')
          }

          setHasDeletedTrade(true)
          setShowConfirmDeleteTradeIn(false)
          setTradeIdToDelete('')
          notification.success({
            message: 'Success!',
            description: 'Successfully removed trade in.'
          })
        }
      })
      .catch((error) => {
        console.error(error)
        notification.error({
          message: 'Error!',
          description: 'Cannot complete this action. Please try again.'
        })
      })
  }

  const removeTradeIn = (tradeId) => {
    setTradeIdToDelete(tradeId)
    setShowConfirmDeleteTradeIn(true)
  }

  const columns = [
    {
      title: 'MAKER',
      dataIndex: ['vehicle', 'make']
    },
    {
      title: 'MODEL',
      dataIndex: ['vehicle', 'model']
    },
    {
      title: 'YEAR',
      dataIndex: ['vehicle', 'year']
    },
    {
      title: 'SOURCE',
      dataIndex: ['source']
    },
    {
      title: 'Delete',
      dataIndex: 'action',
      render: (text, record) => (
        <BsTrash
          onClick={(e) => {
            e.stopPropagation()
            removeTradeIn(record?._id)
          }}
          className="cursor-pointer"
        />
      )
    }
  ]

  return (
    <>
      <div className="mr-8">
        <div className="flex flex-row gap-4 p-5 justify-between">
          <p className="font-semibold text-xl">My Trade ins</p>
          <Button
            type="primary"
            onClick={() => {
              handleShowValueYourVehicle()
            }}
            className="flex items-center"
            icon={<PlusOutlined />}
          >
            Add
          </Button>
        </div>
        <Table
          className="my-trade-ins-table text-center"
          scroll={{ x: 300 }}
          columns={columns}
          dataSource={listTrades}
          rowClassName={(record) => {
            if (record._id === selectedTrade?._id) {
              return 'bg-red-300'
            }
          }}
          onRow={(row) => ({
            onClick: () => handleRowClick(row)
          })}
          loading={loading}
        />
      </div>

      <Modal
        key="add-trade-in"
        centered
        visible={showMyTrade}
        title="Add My Trade ins"
        okText="Submit"
        onOk={handleFormSubmission}
        onCancel={() => setShowMyTrade(false)}
        className="calendar-modal"
        width={340}
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          name="vehicle-ref"
          className="mx-4 lg:px-8 items-center justify-center "
          onFinish={handleFormSubmission}
        >
          {[
            {
              label: 'Make',
              name: 'make',
              required: true,
              type: 'text'
            },
            {
              label: 'Model',
              name: 'model',
              required: true,
              type: 'text'
            },
            {
              label: 'Year',
              name: 'year',
              required: true,
              type: 'number',
              maxLength: 4
            }
          ].map((form, index) => {
            return (
              <Form.Item
                key={form.name}
                name={form.name}
                hasFeedback
                dependencies={form?.dependencies}
                rules={[
                  {
                    required: form.required,
                    message: `${form.label} is required`
                  }
                ]}
                className="custom-antd-label"
              >
                <input
                  placeholder={form.label}
                  name={form.name}
                  maxLength={form.maxLength}
                  autoFocus={index === 0}
                  className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
                  type={form.type}
                />
              </Form.Item>
            )
          })}
        </Form>
      </Modal>
      <Modal
        key="trade-in-confirm-modal"
        centered
        title=""
        visible={showConfirmDeleteTradeIn}
        onCancel={handleDeleteTradeinConfirmModal}
        closable={false}
        okText="Close"
        className=""
        width={350}
        destroyOnClose
        footer={[
          <div className="flex justify-end">
            <Button
              type="button"
              className="bg-primary hover:bg-activePrimary hover:text-white font-semibold rounded-lg outline-none h-full text-white justify-center uppercase"
              onClick={() => handleDeleteTradeinConfirm()}
            >
              CONFIRM
            </Button>
            <Button
              type="button"
              className="hover:bg-activePrimary font-semibold rounded-lg outline-none h-full text-black hover:text-white justify-center uppercase"
              onClick={handleDeleteTradeinCancel}
            >
              CANCEL
            </Button>
          </div>
        ]}
      >
        <div className="pb-2 flex gap-3 lg:gap-5 justify-center rounded-3xl">
          <div className="font-semibold text-md text-center">Are you sure you want to delete this trade in?</div>
        </div>
      </Modal>
    </>
  )
}

MyTradeIn.propTypes = {
  handleShowValueYourVehicle: PropTypes.func,
  selectedTrade: PropTypes.object,
  setSelectedTrade: PropTypes.func,
  selectedOffer: PropTypes.object,
  setValueYourVehicleStepStatus: PropTypes.func
}
