import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { notification, Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { updateCustomerLogo } from 'src/services/customer.service'
import { beforeUpload, getBase64 } from 'src/utils/file'

function UploadingCustomerLogoComponent({
  triggerUploadCustomerLogo,
  setTriggerUploadCustomerLogo,
  initialImageUrl,
  customerAccount,
  setCustomerAccount
}) {
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState(initialImageUrl)
  const [file, setFile] = useState()

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false)
        setImageUrl(url)
      })
    }
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8
        }}
      >
        Upload
      </div>
    </div>
  )

  const dummyRequest = ({ file, onSuccess }) => {
    setFile(file)
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  const openNotificationSuccess = () => {
    const action = notification.success
    const message = 'Avatar updated'
    action({
      message,
      placement: 'topRight'
    })
  }

  const refreshPage = () => {
    window.location.reload(false)
  }

  useEffect(() => {
    if (triggerUploadCustomerLogo && file) {
      setTriggerUploadCustomerLogo(false)
      updateCustomerLogo(file)
        .then((response) => {
          customerAccount.avatar = response.data
          setCustomerAccount(customerAccount)
          openNotificationSuccess()
          setTimeout(() => {
            refreshPage()
          }, 500)
        })
        .catch((error) => {
          let errorMessage = 'Failed to upload the customer logo'
          if (error?.response?.data?.meta?.errors?.customer_logo[0]) {
            errorMessage = error.response.data.meta.errors.customer_logo[0]
          }
          notification.error({
            message: 'Error!',
            description: errorMessage
          })
        })
    }
  }, [triggerUploadCustomerLogo, file])

  return (
    <ImgCrop showReset zoomSlider aspectSlider>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={dummyRequest}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        accept="image/jpeg, image/png ,image/jpg"
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="avatar"
            style={{
              width: '100%'
            }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
    </ImgCrop>
  )
}

UploadingCustomerLogoComponent.propTypes = {
  setTriggerUploadCustomerLogo: PropTypes.func,
  triggerUploadCustomerLogo: PropTypes.bool,
  initialImageUrl: PropTypes.string,
  customerAccount: PropTypes.object,
  setCustomerAccount: PropTypes.func
}

export default UploadingCustomerLogoComponent
