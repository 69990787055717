import { Button, Modal } from 'antd'
import * as PropTypes from 'prop-types'
import React from 'react'

function AuthPopup({ show, setShow, handleShowLoginForm, handleShowSignUpForm, closable, maskClosable, setLoginRequired }) {
  const onCancel = () => {
    setLoginRequired(false)
    setShow(false)
  }

  return (
    <Modal
      centered
      title="Authentication required"
      visible={show}
      closable={closable}
      maskClosable={maskClosable}
      onCancel={() => onCancel()}
      className="calendar-modal"
      width={450}
      footer={[
        <div className="flex justify-between gap-4">
          <Button block onClick={handleShowLoginForm}>
            Login
          </Button>
          <Button block onClick={handleShowSignUpForm}>
            Sign up
          </Button>
        </div>
      ]}
    >
      <div className="w-full text-center">
        <p>Please create an account or log in as a customer to continue</p>
      </div>
    </Modal>
  )
}

AuthPopup.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  handleShowLoginForm: PropTypes.func,
  handleShowSignUpForm: PropTypes.func,
  closable: PropTypes.bool,
  maskClosable: PropTypes.bool,
  setLoginRequired: PropTypes.func
}

export default AuthPopup
