import { Form, Input, Modal, notification } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import { STORAGE_KEYS } from 'src/constants/constants'
import { clear, load, save } from 'src/lib/storage'
import * as AuthService from 'src/services/auth.service'

function LoginFormModal({ showModal, setShowModal, callback, setShowCashDownPopupConfirm }) {
  const formRef = useRef()
  const { handleCustomerAccountLogin, handleAccountSettingsLogin } = useAuthorizationContext()
  const [cookies, , removeCookie] = useCookies()
  const [show, setShow] = useState(false)

  const openNotification = (type) => {
    const action = type === 'success' ? notification.success : notification.error
    const message = type === 'success' ? 'Logged in successfully' : 'Incorrect user/email or password'
    action({
      message,
      placement: 'topRight'
    })
  }

  const handleChangeShow = () => {
    setShow(!show)
  }

  const handleSignIn = async () => {
    const values = await formRef.current
      .validateFields()
      .then((response) => response)
      .catch((error) => error)
    if (values.errorFields && values.errorFields.length > 0) return
    const { email, password } = values
    const cashdown = cookies.customer_cash_down ? cookies.customer_cash_down : 0
    const zipcode = cookies.customer_zip_code ? cookies.customer_zip_code : 0
    const tradeIns = load(STORAGE_KEYS.CUSTOMER_TRADE_INS) || []
    AuthService.loginWithEmail({ email, password, cashdown, zipcode, tradeIns })
      .then((response) => {
        const data = response.data
        const { access_token: accessToken, token_type: tokenType } = data
        const userSettings = JSON.stringify(data)
        save(STORAGE_KEYS.ACCESS_TOKEN, accessToken)
        save(STORAGE_KEYS.TOKEN_TYPE, tokenType)
        save(STORAGE_KEYS.CUSTOMER_LOGIN, 'true')
        save(STORAGE_KEYS.USER_SETTINGS, userSettings)
        clear(STORAGE_KEYS.FAVOURITE_CARS)
        clear(STORAGE_KEYS.CUSTOMER_TRADE_INS)
        handleCustomerAccountLogin(true)
        handleAccountSettingsLogin(userSettings)
        openNotification('success')
        callback()
        setShowModal(false)
        setShowCashDownPopupConfirm(false)
        removeCookie('customer_cash_down')
        removeCookie('customer_zip_code')

        // we clear the shared dealership session
        sessionStorage.removeItem(STORAGE_KEYS.IS_SHARED_BY_DEALERSHIP)
        sessionStorage.removeItem(STORAGE_KEYS.SHARED_BY_DEALERSHIP_ID)
      })
      .catch((error) => {
        openNotification('error')
        console.error(error)
      })
  }

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      handleSignIn()
    }
  }

  return (
    <Modal
      centered
      title="Login form"
      visible={showModal}
      closable
      onCancel={() => setShowModal(false)}
      className="calendar-modal"
      width={450}
      footer={null}
    >
      <Form layout="vertical" ref={formRef} name="vehicle-ref">
        <div className="lg:px-12">
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Email/Username is required' }]}
            className="custom-antd-label"
          >
            <Input
              name="email"
              placeholder="Email/Username"
              className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border"
              type="text"
              onPressEnter={handleKeypress}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                pattern: /^(?=.*[\d])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,16}$/,
                message:
                  'Must Contain min 8 Characters, a Uppercase, a Lowercase, a Number and a Special Character from (!, ?, @, #, $, %, ^, &, *)'
              }
            ]}
            className="custom-antd-label"
          >
            <div className="relative border rounded-xl border-gray">
              <Input
                name="password"
                placeholder="Password"
                className="w-full p-4 box-border rounded-xl border-gray-300 border"
                type={show ? 'text' : 'password'}
                onPressEnter={handleKeypress}
              />
              {show ? (
                <AiFillEyeInvisible
                  onClick={handleChangeShow}
                  className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                />
              ) : (
                <AiFillEye
                  onClick={handleChangeShow}
                  className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                />
              )}
            </div>
          </Form.Item>
          <div className="mt-6">
            <button
              type="button"
              className="bg-primary hover:bg-activePrimary font-semibold rounded-lg px-2 py-3 outline-none text-lg w-full text-white flex justify-center uppercase"
              onClick={handleSignIn}
            >
              Sign in
            </button>
          </div>
        </div>
      </Form>
    </Modal>
  )
}

LoginFormModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  callback: PropTypes.func,
  setShowCashDownPopupConfirm: PropTypes.func
}

export default LoginFormModal
