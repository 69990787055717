/* eslint-disable jsx-a11y/label-has-associated-control */
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, InputNumber, Modal, Select, Table, Tabs, notification } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import ReactInputMask from 'react-input-mask'
import { APR_TYPE_DEFAULT, STATES, VALID_EMAIL } from 'src/constants/constants'
import { useAuthorizationContext } from '../../../AuthorizationProvider'
import * as BuyVehicleServices from '../../../services/buy.service'
import { calculateLoan } from '../../../services/credit.service'
import { setCustomerCashDown, setCustomerZipCode } from '../../../services/customer.service'
import * as VehicleService from '../../../services/vehicle.service'
import { TABLE_TYPES } from '../../AdminDashboard/PricingTables/constants'
import BoxButtonGroup from '../BoxButtonGroup'
import CashDownPopup from '../BoxButtonGroup/CashDownPopup'
import ZipCodePopup from '../BoxButtonGroup/ZipCodePopup'
import './style.scss'

const { Option } = Select
const { TabPane } = Tabs

const tabKeys = {
  AnnualSchedule: {
    id: 'AnnualSchedule',
    name: 'Annual Schedule',
    tableType: TABLE_TYPES.SMALL_TABLE,
    pricingTable: 'basic'
  },
  MonthlySchedule: {
    id: 'MonthlySchedule',
    name: 'Monthly Schedule',
    tableType: TABLE_TYPES.SMALL_TABLE,
    pricingTable: 'basic'
  }
}

const tableColumnsAmortizationSchedule = [
  {
    title: 'Instalment',
    dataIndex: 'instalment',
    key: 'instalment'
  },
  {
    title: 'Beginning Balance',
    dataIndex: 'beginningBalanceLabel',
    key: 'beginningBalanceLabel'
  },
  {
    title: 'Interest',
    dataIndex: 'interestLabel',
    key: 'interestLabel'
  },
  {
    title: 'Principal',
    dataIndex: 'principalLabel',
    key: 'principalLabel'
  },
  {
    title: 'Ending Balance',
    dataIndex: 'endingBalanceLabel',
    key: 'endingBalanceLabel'
  }
]

const preQualificationForm = [
  {
    label: 'First Name',
    name: 'firstName',
    required: true,
    type: 'text',
    step: 1
  },
  {
    label: 'Last Name',
    name: 'lastName',
    required: true,
    type: 'text',
    step: 1
  },
  {
    label: 'Email',
    name: 'email',
    required: true,
    type: 'email',
    step: 1
  },
  {
    label: 'Mobile Phone',
    name: 'mobilePhone',
    required: false,
    type: 'text',
    step: 1
  },
  {
    label: 'Address',
    name: 'address',
    required: true,
    type: 'text',
    step: 2
  },
  {
    label: 'City',
    name: 'city',
    required: true,
    type: 'text',
    step: 2
  },
  {
    label: 'State',
    name: 'state',
    required: true,
    type: 'select',
    options: STATES,
    placeholder: 'Select state',
    step: 2
  },
  {
    label: 'Zip',
    name: 'zip',
    required: true,
    type: 'number',
    step: 2
  },
  {
    label: 'SSN',
    name: 'ssn',
    required: true,
    type: 'text',
    step: 2
  }
]

function FinanceBox({
  info,
  openBreakdown,
  setOpenBreakdown,
  isCalculateTaxesModalVisible,
  setIsCalculateTaxesModalVisible,
  creditScorePositionRanges,
  creditScorePosition,
  setCreditScorePosition,
  cashDown,
  setCashDown,
  loans,
  setLoans,
  setInitStep,
  apr,
  setApr,
  hasSetting700,
  getCalculation
}) {
  const { customerLogin, customerAccount, accountSettings, setAccountSettings } = useAuthorizationContext()
  const [cookie, setCookie, removeCookie] = useCookies()
  const [loginRequired, setLoginRequired] = useState(false)
  const [showCashDownPopupConfirm, setShowCashDownPopupConfirm] = useState(false)
  const [showZipCodePopupConfirm, setShowZipCodePopupConfirm] = useState(false)
  const [step, setStep] = useState(1)
  const [showSSNField, setShowSSNField] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showAmortizationScheduleModal, setShowAmortizationScheduleModal] = useState(false)
  const [tabKey, setTabKey] = useState('AnnualSchedule')
  const [tableDataAmortizationSchedule, setTableDataAmortizationSchedule] = useState([])
  const [preQualified, setPreQualified] = useState({})
  const [knownScore, setKnownScore] = useState(false)
  const [form] = Form.useForm()
  const [, forceUpdate] = useState({})
  const [knownCredit, setKnownCredit] = useState(false)
  const [preQualification, setPreQualification] = useState(false)
  const [calculationId, setCalculationId] = useState('')
  const [loanApr36, setLoanApr36] = useState(apr)
  const [loanApr48, setLoanApr48] = useState(apr)
  const [loanApr60, setLoanApr60] = useState(apr)
  const [loanApr72, setLoanApr72] = useState(apr)
  const [loanApr36Active, setLoanApr36Active] = useState(false)
  const [loanApr48Active, setLoanApr48Active] = useState(false)
  const [loanApr60Active, setLoanApr60Active] = useState(false)
  const [loanApr72Active, setLoanApr72Active] = useState(false)
  const [liborApr36, setLiborApr36] = useState(0)
  const [liborApr48, setLiborApr48] = useState(0)
  const [liborApr60, setLiborApr60] = useState(0)
  const [liborApr72, setLiborApr72] = useState(0)
  const [estimatedFinancePayment, setEstimatedFinancePayment] = useState(0)
  const [estimatedCashPayment, setEstimatedCashPayment] = useState(0)
  const [zipCode, setZipCode] = useState(0)
  const [zipCodeValue, setZipCodeValue] = useState(0)
  const [zipCodeError, setZipCodeError] = useState(false)
  const [amortizationAnnualArray, setAmortizationAnnualArray] = useState(0)
  const [amortizationMonthlyArray, setAmortizationMonthlyArray] = useState(0)
  const [visible, setVisible] = useState(false)
  const [taxesAndFees, setTaxesAndFees] = useState(0)
  const [salesTaxes, setSalesTaxes] = useState(0)
  const [dealerFees, setDealerFees] = useState(0)
  const [dealerFeesObj, setDealerFeesObj] = useState({})
  const [cashDownError, setCashDownError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [contentHeight, setContentHeight] = useState(0)
  const [resolvedZip, setResolvedZip] = useState(true)
  const [visibleInfo, setIsVisibleInfo] = useState(false)
  const [iKnowMyCreditScore, setIKnowMyCreditScore] = useState(false)
  const contentRef = useRef(null)

  useEffect(() => {
    creditScorePositionRanges.forEach((item) => {
      if (item.position === creditScorePosition) {
        setLoanApr36(item.rate_36)
        setLoanApr48(item.rate_48)
        setLoanApr60(item.rate_60)
        setLoanApr72(item.rate_72)
      }
    })
  }, [creditScorePositionRanges])

  const getApr36 = () => {
    return liborApr36 > 0 ? liborApr36 : loanApr36
  }
  const getApr48 = () => {
    return liborApr48 > 0 ? liborApr48 : loanApr48
  }
  const getApr60 = () => {
    return liborApr60 > 0 ? liborApr60 : loanApr60
  }
  const getApr72 = () => {
    return liborApr72 > 0 ? liborApr72 : loanApr72
  }

  const openNotification = (type, message) => {
    const action = type === 'success' ? notification.success : notification.error
    action({
      message,
      placement: 'topRight'
    })
  }

  useEffect(() => {
    setZipCode(accountSettings.zip)
  }, [accountSettings.zip])

  useEffect(() => {
    if (cookie.customer_zip_code && cookie.customer_zip_code > 0 && cookie.customer_zip_code !== zipCode) {
      setZipCode(cookie.customer_zip_code)
      setResolvedZip(true)
    }
  }, [cookie])

  useEffect(() => {
    setContentHeight(contentRef.current.clientHeight)
  }, [visible])

  const showContent = () => {
    setVisible(!visible)
  }

  const onChangeOpenBreakdown = () => {
    setOpenBreakdown(!openBreakdown)
  }

  const onChangeOpenAmortizationSchedule = () => {
    setShowAmortizationScheduleModal(!showAmortizationScheduleModal)
    setTableDataAmortizationSchedule(amortizationAnnualArray)
  }

  const onChangeTabKey = (key) => {
    if (key === 'AnnualSchedule') {
      setTableDataAmortizationSchedule(amortizationAnnualArray)
    }
    if (key === 'MonthlySchedule') {
      setTableDataAmortizationSchedule(amortizationMonthlyArray)
    }

    setTabKey(key)
  }

  useEffect(() => {
    VehicleService.getZipCodeBaseIpAddress()
      .then((res) => {
        setResolvedZip(res.data.zipCodeResolved)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (!resolvedZip) {
      setIsVisibleInfo(true)
    } else {
      setIsVisibleInfo(false)
    }
  }, [resolvedZip])

  const calculate = () => {
    setLoading(true)
    let creditScore = 0
    let zipCodeValue = zipCode
    let userId = ''

    if (customerLogin && accountSettings.zip !== undefined) {
      userId = accountSettings.id
      creditScore = accountSettings.credit_score
      zipCodeValue = accountSettings.zip
    }

    if (customerLogin && cookie.customer_zip_code && cookie.customer_zip_code > 0) {
      zipCodeValue = cookie.customer_zip_code
    }

    const vehicleId = info?.vehicle?._id || ''
    const tradeIn = 0

    calculateLoan({
      userId,
      vehicleId,
      tradeIn,
      cashDown,
      loanTerm: loans,
      loanApr: apr,
      creditScore,
      isCash: 0,
      zipCodeValue,
      iKnowMyCreditScore
    })
      .then((response) => {
        const data = response.data
        setCalculationId(data.calculationId)
        setEstimatedFinancePayment(data.paymentMonthlyInteger)
        if (data.paymentMonthlyInteger < 0) {
          setCashDownError(true)
        }
        if (!customerLogin) {
          setZipCode(data.zipCode)
        }
        setEstimatedCashPayment(data.paymentTotalInteger)
        setLoanApr36Active(data.activeRate36)
        setLoanApr48Active(data.activeRate48)
        setLoanApr60Active(data.activeRate60)
        setLoanApr72Active(data.activeRate72)
        setAmortizationAnnualArray(data.amortizationAnnualArray)
        setAmortizationMonthlyArray(data.amortizationMonthlyArray)
        setTaxesAndFees(data.totalTaxesAndFeesInteger)
        setSalesTaxes(data.salesTaxesCalculated)
        setDealerFees(data.dealerFeesLabel)
        setDealerFeesObj(data.dealerFeesArray)
        setResolvedZip(data.zipCodeResolved)
        if (data.aprType !== APR_TYPE_DEFAULT) {
          setLiborApr36(data.liborApr36)
          setLiborApr48(data.liborApr48)
          setLiborApr60(data.liborApr60)
          setLiborApr72(data.liborApr72)
        }

        getCalculation(data)
      })
      .catch((error) => {
        openNotification('error', error.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    removeCookie('customer_zip_code')
    removeCookie('customer_cash_down', { path: '/vehicles/' })

    if (cashDown < 1) {
      calculate()
      setCookie('customer_cash_down', cashDown)
    } else {
      const timer = setTimeout(() => {
        const vehiclePrice = info?.vehicle?.price || 0
        if (
          customerLogin &&
          !cookie.customer_cash_down &&
          accountSettings.cash_down !== undefined &&
          accountSettings.cash_down !== cashDown &&
          calculationId.length > 0
        ) {
          setShowCashDownPopupConfirm(true)
        } else if (
          !showCashDownPopupConfirm &&
          !customerLogin &&
          !cookie.customer_cash_down &&
          Math.round(vehiclePrice * 0.2) !== cashDown &&
          calculationId.length > 0
        ) {
          setShowCashDownPopupConfirm(true)
        } else if (
          !showZipCodePopupConfirm &&
          !cookie.customer_zip_code &&
          zipCodeValue.length >= 4 &&
          zipCodeValue.length <= 5
        ) {
          setShowZipCodePopupConfirm(true)
        } else if (
          !showZipCodePopupConfirm &&
          cookie.customer_zip_code &&
          cookie.customer_zip_code !== zipCodeValue &&
          zipCodeValue.length >= 4 &&
          zipCodeValue.length <= 5
        ) {
          setShowZipCodePopupConfirm(true)
        }
        setCookie('customer_cash_down', cashDown)
        setCookie('customer_zip_code', zipCodeValue)

        calculate()
      }, 1500)

      return () => clearTimeout(timer)
    }
  }, [info, cashDown, loans, apr, zipCodeValue, showCashDownPopupConfirm, showZipCodePopupConfirm])

  const handleCreditScorePositionRangeChange = (selected) => {
    setCreditScorePosition(selected.target.value)
    const selectedRange = creditScorePositionRanges[selected.target.value]
    setLoanApr36(selectedRange.rate_36)
    setLoanApr48(selectedRange.rate_48)
    setLoanApr60(selectedRange.rate_60)
    setLoanApr72(selectedRange.rate_72)
    if (loans === 36) {
      setApr(selectedRange.rate_36)
    } else if (loans === 48) {
      setApr(selectedRange.rate_48)
    } else if (loans === 60) {
      setApr(selectedRange.rate_60)
    } else if (loans === 72) {
      setApr(selectedRange.rate_72)
    }
    setIKnowMyCreditScore(true)

    calculate()
  }

  const handleFormSubmission = () => {
    const value = form.getFieldsValue()
    const phoneRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/
    if (value.mobilePhone && !phoneRegex.test(value.mobilePhone)) {
      delete value.mobilePhone
    }
    if (!showSSNField) delete value.ssn
    value.dealerId = info.dealer_id ?? 0
    if (customerAccount?.id) {
      value.customerId = customerAccount.id
    }
    BuyVehicleServices.getSevenHundredCredit(value)
      .then((response) => {
        const { position, score, XPN } = response.data
        // eslint-disable-next-line no-nested-ternary
        const currentScore = score && score > 0 ? score : undefined
        if (!currentScore && !showSSNField) {
          setShowModal(true)
        } else if (showSSNField) {
          const { score } = XPN
          const currentScore = score && score > 0 ? score : undefined
          setPreQualification(!preQualification)
          setPreQualified({
            message: 'Your Credit Score was retrieved successfully',
            score: currentScore
          })
          setKnownScore(!currentScore)
        } else {
          setPreQualification(!preQualification)
          setPreQualified({
            message: 'Your Credit Score was retrieved successfully',
            score: currentScore
          })
          setKnownScore(!currentScore)
          creditScorePositionRanges.forEach((item) => {
            if (item.position === position) {
              setLoanApr36(item.rate_36)
              setLoanApr48(item.rate_48)
              setLoanApr60(item.rate_60)
              setLoanApr72(item.rate_72)

              if (loans === 36) {
                setApr(item.rate_36)
              } else if (loans === 48) {
                setApr(item.rate_48)
              } else if (loans === 60) {
                setApr(item.rate_60)
              } else if (loans === 72) {
                setApr(item.rate_72)
              }
            }
          })
        }
      })
      .catch(() => {
        if (showSSNField) {
          setPreQualification(!preQualification)
          setKnownScore(true)
        } else {
          setShowModal(true)
        }
      })
      .finally(() => calculate())
  }

  const handleKnownScore = () => {
    setKnownCredit(true)
    setPreQualification(false)
    setKnownScore(false)
  }

  useEffect(() => {
    forceUpdate({})
  }, [])

  const handleChooseScoreManually = () => {
    setShowModal(false)
    setShowSSNField(false)
    setPreQualification(!preQualification)
    setKnownScore(true)
  }

  const handleTryAgainWithSSN = () => {
    setShowModal(false)
    setShowSSNField(true)
  }

  const handleChangeCashDown = (value) => {
    const vehiclePrice = info?.vehicle?.price || 0
    if (value === undefined || value === null || value >= vehiclePrice) {
      setCashDown(0)
      setCashDownError(true)
    } else {
      setCashDown(value)
      setCashDownError(false)
    }
  }

  const handleChangeZipCode = (event) => {
    const zipCodeValue = event.target.value.replace(/\D/g, '')
    setZipCode(zipCodeValue)
    if (zipCodeValue.length >= 4 && zipCodeValue.length <= 5) {
      setZipCodeValue(zipCodeValue)
      setZipCodeError(false)
      setResolvedZip(true)
    } else {
      setZipCodeError(true)
    }
  }

  const handleCashDownUpdate = () => {
    if (!customerLogin) {
      setLoginRequired(true)
    } else {
      setCustomerCashDown({ cashDown })
        .then((response) => {
          const data = response.data
          setAccountSettings(data)
          openNotification('success', 'Successfully updated')
        })
        .catch((error) => {
          openNotification('error', error.message)
        })
    }
    setShowCashDownPopupConfirm(false)
  }

  const handleZipCodeUpdate = () => {
    if (!customerLogin) {
      setLoginRequired(true)
    } else {
      setCustomerZipCode({ zipCode })
        .then((response) => {
          const data = response.data
          setAccountSettings(data)
          calculate()
          openNotification('success', 'Successfully updated')
        })
        .catch((error) => {
          openNotification('error', error.message)
        })
    }
    setShowZipCodePopupConfirm(false)
  }

  const handleNumberOnlyInput = (event) => {
    const key = Number(event.key)
    if (event.key !== 'Backspace' && Number.isNaN(key)) {
      return event.preventDefault()
    }
    return true
  }

  const handleChangeLengthOfLoans = (loans, apr) => {
    setLoans(loans)
    setApr(apr)
  }

  const validation = (form) => {
    const paternMessage = {
      pattern: /^[a-zA-Z\s]+$/g,
      message: 'Only string accepted'
    }
    switch (form.name) {
      case 'email':
        paternMessage.pattern = VALID_EMAIL
        paternMessage.message = 'Invalid email format'
        return paternMessage
      case 'mobilePhone':
        paternMessage.pattern = /(^[0-9]{3}-[0-9]{3}-[0-9]{4}$)|(^[_]{3}-[_]{3}-[_]{4}$)/
        paternMessage.message = 'Invalid phone number format'
        return paternMessage
      case 'address':
        paternMessage.pattern = /^\s*\S+(?:\s+\S+){2}/
        paternMessage.message = 'Invalid address format'
        return paternMessage
      case 'city':
        paternMessage.pattern = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/
        paternMessage.message = 'Invalid city format'
        return paternMessage
      case 'zip':
        paternMessage.pattern = /^[0-9]{4,5}$/
        paternMessage.message = 'Zip can be only 4 to 5 numbers'
        return paternMessage
      case 'ssn':
        paternMessage.pattern = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/
        paternMessage.message = 'Invalid SSN format'
        return paternMessage
      default:
        return paternMessage
    }
  }

  const renderFields = (field, index) => {
    if (field.type === 'select') {
      return (
        <Select
          placeholder={field.placeholder || `Select $${field.name}`}
          onChange={(value) => {
            form.setFieldsValue({ [field.name]: value })
          }}
          size="large"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().startsWith(input.toLowerCase())}
          className="w-full"
        >
          {field.options.map((option) => (
            <Option key={option.name} value={option.name}>
              {option.name}
            </Option>
          ))}
        </Select>
      )
    }
    if (field.name === 'mobilePhone') {
      return (
        <ReactInputMask
          mask="999-999-9999"
          className="w-full py-2 px-3 border-0 focus:outline-none"
          name="mobilePhone"
          placeholder="123-456-7890"
        />
      )
    }
    return (
      <input
        name={field.name}
        autoFocus={index === 0}
        className="w-full py-2 px-3 border-0 focus:outline-none"
        type={field.type}
      />
    )
  }

  const handleChangeStep = () => {
    setStep((step) => (step === 1 ? 2 : 1))
    setVisible(false)
    setContentHeight(0)
  }

  const cashDownValue = () => {
    if (cashDown !== undefined) {
      return cashDown.toLocaleString()
    }
    return 0
  }

  const cashDownLabel = () => {
    if (cashDown !== undefined) {
      return cashDown.toLocaleString()
    }
    return '0'
  }

  const resetAmortizationSchedule = () => {
    setShowAmortizationScheduleModal(false)
    setTabKey('AnnualSchedule')
  }

  return (
    <div className="pt-8 finance-box">
      <div className="relative">
        <div className="ml-2">
          <p className="text-base text-center font-bold">Est.Finance Payment</p>
          <p className={`text-4xl text-center font-bold${loading ? 'text-gray-600' : ''}`}>
            ${estimatedFinancePayment.toLocaleString()}/mo*
          </p>
          <p className="text-center">{`Based on Price of $${info?.vehicle?.price?.toLocaleString()}`}</p>
          {!cashDownError && (
            <p className="text-primary text-lg text-center mb-5 cursor-pointer" onClick={onChangeOpenBreakdown}>
              {openBreakdown ? 'Back to Calculate My Payment' : 'See payment Breakdown'}
            </p>
          )}
        </div>
      </div>
      <div className={`${!preQualified.score ? 'hidden' : ''}`}>
        <div className="bg-light rounded-lg p-4 my-8">
          <p className="text-center text-gray mb-2">{preQualified.message}</p>
          <p className="text-center text-gray">{`"Your Credit Score is ${preQualified.score}"`}</p>
        </div>
      </div>
      <div className={`${!knownScore ? 'hidden' : ''}`}>
        <div className="bg-light rounded-lg p-4 my-8">
          <p className="text-gray text-center text-base" onClick={handleKnownScore}>
            Unfortunately, we could not obtain your Credit Score. Click{' '}
            <span className="text-blue-500 cursor-pointer">here</span> to choose your score.
          </p>
        </div>
      </div>
      <div className={`${openBreakdown ? 'hidden' : ''}`}>
        <div className="grid mb-3">
          <div className="flex">
            {hasSetting700 && (
              <button
                disabled={cashDownError}
                type="button"
                className={`border border-primary hover:bg-primary hover:text-white rounded-lg px-2 py-3 outline-none text-base w-full ${
                  !knownCredit && preQualification ? 'bg-primary text-white' : 'text-primary'
                } flex justify-center my-4 mr-4`}
                onClick={() => {
                  setKnownCredit(false)
                  setPreQualification(!preQualification)
                }}
              >
                Get Pre-Qualified
              </button>
            )}
            <button
              disabled={cashDownError}
              type="button"
              className={`border border-primary hover:bg-primary hover:text-white rounded-lg px-2 py-3 outline-none text-base w-full flex justify-center my-4  ${
                knownCredit ? 'bg-primary text-white' : 'text-primary'
              }`}
              onClick={() => {
                setKnownCredit(!knownCredit)
                setPreQualification(false)
              }}
            >
              I know my credit rating
            </button>
          </div>
          {!knownCredit && preQualification && (
            <div className="bg-light p-6">
              <p className="font-bold text-center">Get Pre-Qualified</p>
              <p className="text-center mb-3">We will never share or distribute your information.</p>
              <Form
                form={form}
                layout="vertical"
                name="vehicle-ref"
                className="mx-4 items-center justify-center "
                onFinish={handleFormSubmission}
              >
                {preQualificationForm
                  .filter((field) => {
                    if (field.name === 'ssn' && !showSSNField) return false
                    return true
                  })
                  .map((field, index) => {
                    return (
                      <Form.Item
                        key={index}
                        name={field.name}
                        label={field.label}
                        rules={[
                          {
                            required: field.required,
                            message: `${field.label} is required`
                          },
                          validation(field)
                        ]}
                        className={`custom-antd-label ${field.step === step ? '' : 'hidden'}`}
                      >
                        <div className="flex gap-0 border-2xl rounded items-center">{renderFields(field, index)}</div>
                      </Form.Item>
                    )
                  })}

                <Form.Item className="mt-8 items-center" shouldUpdate>
                  {() => (
                    <div className="flex gap-4">
                      {step === 1 && (
                        <Button type="primary" onClick={handleChangeStep}>
                          Next
                        </Button>
                      )}
                      {step === 2 && (
                        <div className="flex gap-4">
                          <Button type="primary" onClick={handleChangeStep}>
                            Back
                          </Button>
                          <Button
                            type="primary"
                            htmlType="submit"
                            disabled={
                              !form.isFieldsTouched(true) ||
                              !!form.getFieldsError().filter(({ errors }) => errors.length).length
                            }
                          >
                            Get Pre-Qualified
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                </Form.Item>
              </Form>

              <p className="text-center text-lg pt-2" onClick={handleKnownScore}>
                Click <span className="text-blue-500 cursor-pointer">here</span> to choose your score.
              </p>
            </div>
          )}
          {knownCredit && (
            <>
              <label className="text-lg mb-2">Credit Rating</label>
              <select
                value={creditScorePosition}
                onChange={handleCreditScorePositionRangeChange}
                className="border border-lightGray rounded-lg px-2 py-3 outline-none text-base"
              >
                {creditScorePositionRanges.map((option) => (
                  <option value={option.position} key={option.position} className="p-2">
                    {option.label}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>
        <div className="flex flex-col mb-3">
          <label className="text-lg mb-2">Length of Loan</label>
          <div className="btn-mos-group">
            <button
              disabled={cashDownError || !loanApr36Active}
              type="button"
              className={`flex-1 ${loans === 36 ? 'bg-primary text-white' : ''}`}
              onClick={() => handleChangeLengthOfLoans(36, loanApr36)}
            >
              <p className="text-base">36 mos</p>
              <p className="text-xs">{getApr36()}% APR</p>
            </button>
            <button
              disabled={cashDownError || !loanApr48Active}
              type="button"
              className={`flex-1 ${loans === 48 ? 'bg-primary text-white' : ''}`}
              onClick={() => handleChangeLengthOfLoans(48, loanApr48)}
            >
              <p className="text-base">48 mos</p>
              <p className="text-xs">{getApr48()}% APR</p>
            </button>
            <button
              disabled={cashDownError || !loanApr60Active}
              type="button"
              className={`flex-1 ${loans === 60 ? 'bg-primary text-white' : ''}`}
              onClick={() => handleChangeLengthOfLoans(60, loanApr60)}
            >
              <p className="text-base">60 mos</p>
              <p className="text-xs">{getApr60()}% APR</p>
            </button>
            <button
              disabled={cashDownError || !loanApr72Active}
              type="button"
              className={`flex-1 ${loans === 72 ? 'bg-primary text-white' : ''}`}
              onClick={() => handleChangeLengthOfLoans(72, loanApr72)}
            >
              <p className="text-base">72 mos</p>
              <p className="text-xs">{getApr72()}% APR</p>
            </button>
          </div>
        </div>

        <div className="flex flex-col mb-12">
          {!cashDownError ? (
            <label className="text-lg mb-2">Cash Down</label>
          ) : (
            <label className="text-lg mb-2 text-red-600">
              Cash Down can not be equal to or greater than vehicle price
            </label>
          )}
          <InputNumber
            className={`border ${
              cashDownError ? 'border-red-500' : 'border-lightGray'
            } rounded-lg px-2 py-3 w-full outline-none text-base`}
            defaultValue={cashDown}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={handleChangeCashDown}
            onKeyDown={handleNumberOnlyInput}
          />
        </div>
        <BoxButtonGroup
          info={info}
          setInitStep={setInitStep}
          setIsCalculateTaxesModalVisible={setIsCalculateTaxesModalVisible}
          isCalculateTaxesModalVisible={isCalculateTaxesModalVisible}
          setCashDown={setCashDown}
          cashDown={cashDown}
          loans={loans}
          estimatedFinancePayment={estimatedFinancePayment}
          loginRequired={loginRequired}
          setLoginRequired={setLoginRequired}
          setShowCashDownPopupConfirm={setShowCashDownPopupConfirm}
          calculationId={calculationId}
        />
        {(customerLogin || showCashDownPopupConfirm) && (
          <CashDownPopup
            cashDown={cashDown}
            showCashDownPopupConfirm={showCashDownPopupConfirm}
            setShowCashDownPopupConfirm={setShowCashDownPopupConfirm}
            handleCashDownUpdate={handleCashDownUpdate}
          />
        )}
        {(customerLogin || showZipCodePopupConfirm) && (
          <ZipCodePopup
            zipCode={zipCode}
            showZipCodePopupConfirm={showZipCodePopupConfirm}
            setShowZipCodePopupConfirm={setShowZipCodePopupConfirm}
            handleZipCodeUpdate={handleZipCodeUpdate}
          />
        )}
      </div>

      <div className={`px-2 payment-breakdown pt-5 pb-8 ${openBreakdown ? '' : 'hidden'}`}>
        <div className="text-xl font-bold text-center">Payment Breakdown</div>
        <div className="text-sm flex justify-between">
          <div className="flex flex-col gap-2">
            <span>Term</span>
            <span className="font-semibold">{loans} mos</span>
          </div>
          <div className="flex flex-col gap-2">
            <span>APR</span>
            <span className="font-semibold">{apr}%</span>
          </div>
          <div className="flex flex-col gap-2">
            <span>Cash Down</span>
            <span className="font-semibold">{`$${cashDownValue()}`}</span>
          </div>
        </div>
        <div className="border-t my-3 border-gray-500 mx-3" />
        <div className="flex justify-between mb-3 text-sm">
          <div>Vehicle Price</div>
          <div>{`$${info?.price?.toLocaleString() || 0}`}</div>
        </div>
        <div className="flex justify-between mb-3 text-sm">
          <div>Cash Down</div>
          <div className="text-green-500">{`-$${cashDownLabel()}`}</div>
        </div>
        <div className="flex justify-between mb-3 text-sm">
          <div>Zip Code</div>
          <div>
            <input
              maxLength={5}
              name="zipCode"
              placeholder="e.g. 10116"
              className={`ext-right border ${
                zipCodeError ? 'border-red-500' : 'border-lightGray'
              } text-right border text-blue-800 focus:text-blueFv-600 focus:outline-none`}
              onChange={handleChangeZipCode}
              value={zipCode}
              type="text"
            />
          </div>
        </div>
        {visibleInfo && (
          <p className="text-red-800 text-xs">Your zip code could not be resolved, please fill in manually</p>
        )}
        <div className="flex justify-between text-sm" onClick={showContent} role="presentation">
          <div>Taxes & Fees</div>
          <div className="flex gap-1 items-center">
            <span>${taxesAndFees}</span>
            <div className="cursor-pointer text-blue-600 text-lg" onClick={showContent} role="presentation">
              {!visible ? <PlusOutlined /> : <MinusOutlined />}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-3 text-sm">
          <div
            className="w-full transition-all duration-300 overflow-hidden"
            style={{ maxHeight: !visible ? 0 : `${contentHeight}px` }}
          >
            <div className="pt-2" ref={contentRef}>
              <div className="pt-2" ref={contentRef}>
                <div className="flex justify-between mb-2">
                  <p className="text-sm">Sales Taxes</p>
                  <p className="text-sm">${salesTaxes.toFixed(2).toLocaleString()}</p>
                </div>
                <div className="flex justify-between mb-1">
                  <p className="text-sm">Dealer Fees</p>
                  <p className="text-sm">{dealerFees}</p>
                </div>
                {dealerFeesObj?.fees?.map((item, index) => {
                  return (
                    <div className="flex justify-between" key={index}>
                      <p className="text-sm ml-3.5">{item.name}</p>
                      <p className="text-sm">{item.valueCurrency}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="border-t mb-3 border-gray-500 mx-3" />
        <div className="flex justify-between text-sm font-bold mb-3">
          <div>Est. Cash Payment</div>
          <div>{`$${estimatedCashPayment.toLocaleString()}`}</div>
        </div>
        <div className="border-t mb-3 border-gray-500 mx-3" />
        <div className="text-sm px-4 w-full pt-4">
          <Button size="large" className="text-blue-300 w-full" onClick={onChangeOpenBreakdown}>
            Close
          </Button>
        </div>
        <div className="text-sm px-4 w-full pt-4">
          <p className="text-blue-600 text-lg text-center cursor-pointer" onClick={onChangeOpenAmortizationSchedule}>
            See Amortization Schedule
          </p>
        </div>
      </div>
      <Modal
        centered
        title="Choose credit type"
        visible={showModal}
        closable
        onCancel={() => setShowModal(false)}
        className="calendar-modal"
        width={450}
        footer={[
          <div className="flex justify-between">
            <Button block onClick={handleChooseScoreManually}>
              I know my score
            </Button>
            <Button block onClick={handleTryAgainWithSSN}>
              {`I'll enter my Social Security Number`}
            </Button>
          </div>
        ]}
      >
        <div className="w-full text-center">
          <p>
            We were unable to obtain your credit profile. Please provide your social security number to continue and try
            again. If you know your current credit score, you can choose to enter it manually.
          </p>
        </div>
      </Modal>
      <Modal
        centered
        title="Amortization Schedule"
        visible={showAmortizationScheduleModal}
        closable
        onCancel={() => resetAmortizationSchedule()}
        className="calendar-modal"
        width={750}
        footer={null}
      >
        <div className="w-full text-center">
          <Tabs
            className="pricing-tables"
            defaultActiveKey={tabKey}
            activeKey={tabKey}
            tabPosition="top"
            onChange={onChangeTabKey}
            renderTabBar={(props, TabNavList) => <TabNavList {...props} mobile />}
          >
            {Object.keys(tabKeys).map((key) => (
              <TabPane tab={tabKeys[key].name} key={tabKeys[key].id}>
                {Object.keys(tableDataAmortizationSchedule).map((key) => (
                  <Table
                    columns={tableColumnsAmortizationSchedule}
                    dataSource={tableDataAmortizationSchedule[key].instalments}
                    className="mt-5"
                    scroll={{ x: 'max-content' }}
                    pagination={false}
                    footer={() => tableDataAmortizationSchedule[key].yearName}
                  />
                ))}
              </TabPane>
            ))}
          </Tabs>
        </div>
      </Modal>
    </div>
  )
}

FinanceBox.propTypes = {
  info: PropTypes.object,
  openBreakdown: PropTypes.bool,
  setOpenBreakdown: PropTypes.func,
  setIsCalculateTaxesModalVisible: PropTypes.func,
  creditScorePositionRanges: PropTypes.array,
  creditScorePosition: PropTypes.any,
  setCreditScorePosition: PropTypes.func,
  cashDown: PropTypes.number,
  setCashDown: PropTypes.func,
  setInitStep: PropTypes.func,
  loans: PropTypes.number,
  setLoans: PropTypes.func,
  apr: PropTypes.number,
  setApr: PropTypes.func,
  hasSetting700: PropTypes.bool,
  getCalculation: PropTypes.func,
  isCalculateTaxesModalVisible: PropTypes.bool
}

export default FinanceBox
