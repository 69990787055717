import React from 'react'
import { MyFavorites } from 'src/components/AccountDashboard/MyFavorites'

function CustomerFavouriteVehicles() {
  return (
    <div className="container mx-auto mb-12 mt-10 pt-6 rounded-xl border-2">
      <div className="flex justify-between items-center">
        <div className="text-xl font-bold mb-4">My Favorites</div>
      </div>
      <MyFavorites />
    </div>
  )
}

export default CustomerFavouriteVehicles
