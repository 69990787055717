import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Spin } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import VehicleCard from 'src/components/Buy/vehicleCard'
import { STORAGE_KEYS } from 'src/constants/constants'
import { load } from 'src/lib/storage'
import * as VehicleService from '../../../services/vehicle.service'

const settings = {
  dots: false,
  infinite: false,
  speed: 400,
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: 0,
  draggable: true,
  responsive: [
    {
      breakpoint: 1375,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 760,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

function BetweenVehicles({ id, isMobile }) {
  const navigate = useNavigate()
  const favouriteCarsLocal = load(STORAGE_KEYS.FAVOURITE_CARS) || []
  const [favouriteCars, setFavouriteCars] = useState(favouriteCarsLocal)
  const [cars, setCars] = useState([])
  const [min, setMin] = useState(0)
  const [max, setMax] = useState(0)
  const [loading, setLoading] = useState(true)
  const sharedByDealership = sessionStorage.getItem(STORAGE_KEYS.IS_SHARED_BY_DEALERSHIP) === 'true'

  const handleBetweenSliderNext = () => {
    document.querySelector('.vehicles-between .slick-arrow.slick-next').click()
  }

  const handleBetweenSliderPrev = () => {
    document.querySelector('.vehicles-between .slick-arrow.slick-prev').click()
  }

  const fetchRelatedPriceVehicles = async () => {
    try {
      const filterDealer = sharedByDealership ? 1 : 0
      const result = await VehicleService.getRelatedPriceVehicles(id, filterDealer)
      const fetchedCars = result.data.data.vehicles
      setCars(fetchedCars)
      if (fetchedCars.length > 0) {
        setMin(result.data.data.priceRange.min)
        setMax(result.data.data.priceRange.max)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) {
      fetchRelatedPriceVehicles()
    }
  }, [id])

  const handleNavigate = () => {
    const dealershipId = sessionStorage.getItem(STORAGE_KEYS.SHARED_BY_DEALERSHIP_ID)
    navigate(`/dealership/${dealershipId}`)
  }

  if (loading) {
    return (
      <>
        <p className="text-2xl font-bold text-black mb-2">{`Vehicles Between $${min}k - $${max}k`}</p>
        <Spin tip="Loading..." className="w-full my-16" />
      </>
    )
  }

  const renderNoVehicles = sharedByDealership ? (
    <div className="flex gap-1 justify-center py-10">
      <div>{`Vehicles Between $${min}k - $${max}k not available.`}</div>
      <div
        className="hover:text-blue-700 cursor-pointer"
        onClick={handleNavigate}
      >{`Check Dealership Inventory >`}</div>
    </div>
  ) : (
    <div>No similar vehicles</div>
  )

  if (cars.length > 0) {
    for (let index = 0; index < settings.responsive.length; ++index) {
      settings.responsive[index].settings.infinite = cars.length > settings.responsive[index].settings.slidesToShow
    }
  }

  return (
    <>
      <p className="text-2xl font-bold text-black mb-2">{`Vehicles Between $${min}k - $${max}k`}</p>
      <div className="relative">
        {cars && cars.length > 0 ? (
          <Slider className="mb-12 vehicles-between slider-slick" {...settings}>
            {cars.map((car) => (
              <VehicleCard car={car} key={car._id} favouriteCars={favouriteCars} setFavouriteCars={setFavouriteCars} />
            ))}
          </Slider>
        ) : (
          renderNoVehicles
        )}
        {!isMobile && cars && cars.length > 5 && (
          <>
            <Button
              shape="circle"
              icon={<LeftOutlined />}
              className="absolute cursor-pointer z-20 -left-12 top-1/2 -translate-y-2/4"
              onClick={handleBetweenSliderPrev}
            />
            <Button
              shape="circle"
              icon={<RightOutlined />}
              className="absolute cursor-pointer z-20 -right-12 top-1/2 -translate-y-2/4"
              onClick={handleBetweenSliderNext}
            />
          </>
        )}
      </div>
    </>
  )
}

BetweenVehicles.propTypes = {
  id: PropTypes.string,
  isMobile: PropTypes.bool,
  responsive: PropTypes.object
}

export default BetweenVehicles
