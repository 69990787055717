import { Form } from 'antd'
import { debounce } from 'lodash'
import * as PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import InputMask from 'react-input-mask'
import NumberInput from 'src/components/InputComponent/NumberInput'
import { STATES } from 'src/constants/constants'
import * as ClaimServices from 'src/services/claim.service'

const jobStatusOptions = [
  { id: 'retired', name: 'Retired' },
  { id: 'military', name: 'Military' },
  { id: 'unemployed', name: 'Unemployed' },
  { id: 'self', name: 'Self' },
  { id: '1099', name: '1099' },
  { id: 'homemaker', name: 'Homemaker' },
  { id: 'student', name: 'Student' },
  { id: 'other', name: 'Other' },
  { id: 'employed', name: 'Employed' },
  { id: 'disability', name: 'Disability' }
]

const jobTypeOptions = [
  { id: 'fulltime', name: 'Full time' },
  { id: 'parttime', name: 'Part time' }
]

const getListYearsAtJob = () => {
  const listYears = []
  let year = 0
  while (year <= 50) {
    listYears.push(year)
    year += 1
  }
  return listYears
}

const getListMonthsAtJob = () => {
  const listMonths = []
  let month = 0
  while (month <= 11) {
    listMonths.push(month)
    month += 1
  }
  return listMonths
}

function PreviousJobInformation({ previousInformationFormRef, preJobType, handleChangePreJobType }) {
  const listYears = getListYearsAtJob()
  const listMonths = getListMonthsAtJob()

  const handleNetAnnualIncome = () => {
    const annualGrossIncomeValue = previousInformationFormRef?.current?.getFieldValue('annualGrossIncome')
    previousInformationFormRef?.current?.setFieldsValue({
      annualNetIncome: Math.round(annualGrossIncomeValue * 0.8 * 100) / 100
    })
  }

  const onResolvingZipCode = async (event) => {
    const zipCode = event.target.value
    if (zipCode) {
      try {
        const location = await ClaimServices.getLocationFromZipCode(zipCode)

        if (location) {
          const { city, region } = location.data
          if (city && city !== '-') previousInformationFormRef.current.setFieldsValue({ city })
          const state = STATES.find((e) => e.name === region)
          if (state) {
            previousInformationFormRef.current.setFieldsValue({ state: state.name })
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const debounceZipCodeChange = useMemo(() => {
    return debounce((value) => {
      onResolvingZipCode(value)
    }, 500)
  }, [])

  return (
    <Form layout="vertical" ref={previousInformationFormRef} name="job-information-ref">
      <div className="mt-4 step-4">
        <p className="text-2xl text-black font-bold mb-3">Previous Job Information</p>
        <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-0 xl:gap-6 lg:gap-5 md:gap-4">
          <Form.Item
            name="employerName"
            label="Employer Name"
            rules={[
              { required: true, message: 'Employer name is required' },
              { pattern: /^[a-zA-Z0-9\s]+$/g, message: 'Alphanumeric string accepted' }
            ]}
            className="custom-antd-label block"
          >
            <input name="employerName" autoFocus className="w-full border border-black rounded py-2 px-3" type="text" />
          </Form.Item>
          <Form.Item
            name="jobStatus"
            label="Job Status"
            rules={[
              { required: true, message: 'Job status is required' },
              ({ getFieldValue }) => ({
                validator() {
                  if (getFieldValue('jobStatus') === 'Select Job Status') {
                    return Promise.reject(new Error('Job status is required'))
                  }
                  return Promise.resolve()
                }
              })
            ]}
            className="custom-antd-label block"
          >
            <select
              // name="jobStatus"
              className="w-full border border-black bg-white rounded py-2 px-3 select-option-sell-your-car"
            >
              <option>Select Job Status</option>
              {jobStatusOptions.map((jobStatus, idx) => (
                <option value={jobStatus.id} key={`option-${jobStatus.id}-${idx}`}>
                  {jobStatus.name}
                </option>
              ))}
            </select>
          </Form.Item>
          <Form.Item
            name="annualGrossIncome"
            label="Annual Gross Income"
            rules={[{ required: true, message: 'Annual gross income is required' }]}
            className="custom-antd-label block"
          >
            <NumberInput
              name="annualGrossIncome"
              onChange={handleNetAnnualIncome}
              placeholder="$"
              className="w-full border border-black rounded py-2 px-3"
            />
          </Form.Item>
          <Form.Item
            name="annualNetIncome"
            label="Annual Net Income"
            rules={[{ required: true, message: 'Annual net income is required' }]}
            className="custom-antd-label block"
          >
            <NumberInput
              name="annualNetIncome"
              placeholder="$"
              className="w-full border border-black rounded py-2 px-3"
            />
          </Form.Item>

          <Form.Item
            name="workPhone"
            label="Work Phone"
            rules={[
              { required: true, message: 'Work phone is required' },
              { pattern: /\d{3}[-]\d{3}[-]\d{4}/, message: 'Work phone is invalid format' }
            ]}
            className="custom-antd-label block"
          >
            <InputMask
              name="workPhone"
              placeholder="NXX-NXX-XXXX"
              className="w-full border border-black rounded py-2 px-3"
              mask="999-999-9999"
              type="text"
            />
          </Form.Item>
          <Form.Item
            name="jobTitle"
            label="Job Title"
            rules={[
              { required: true, message: 'Job title is required' },
              { pattern: /^[a-zA-Z\s]+$/g, message: 'Job Title consist only of alphabets' }
            ]}
            className="custom-antd-label block"
          >
            <input name="jobTitle" className="w-full border border-black rounded py-2 px-3" type="text" />
          </Form.Item>
        </div>

        <Form.Item
          name="streetAddress"
          label="Street Address"
          rules={[
            { required: true, message: 'Street Address is required' },
            { pattern: /^[a-zA-Z0-9\s]+$/g, message: 'Alphanumeric string accepted' }
          ]}
          className="custom-antd-label block"
        >
          <input name="streetAddress" autoFocus className="w-full border border-black rounded py-2 px-3" type="text" />
        </Form.Item>

        <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-0 xl:gap-6 lg:gap-5 md:gap-4">
          <Form.Item
            name="zipCode"
            label="Zip Code"
            rules={[{ required: true, message: 'Zip code is required' }]}
            className="custom-antd-label block"
          >
            <InputMask
              name="zipCode"
              className="w-full border border-black rounded py-2 px-3"
              type="text"
              maxLength={5}
              onChange={debounceZipCodeChange}
            />
          </Form.Item>
          <Form.Item
            name="city"
            label="City"
            rules={[
              { required: true, message: 'City is required' },
              { pattern: /^[a-zA-Z\s]+$/g, message: 'City consist only of alphabets' }
            ]}
            className="custom-antd-label block"
          >
            <input name="city" className="w-full border border-black rounded py-2 px-3" type="text" />
          </Form.Item>

          <Form.Item
            name="state"
            label="State"
            rules={[
              { required: true, message: 'State is required' },
              ({ getFieldValue }) => ({
                validator() {
                  if (getFieldValue('state') === 'Select state') {
                    return Promise.reject(new Error('State is required'))
                  }
                  return Promise.resolve()
                }
              })
            ]}
            className="custom-antd-label block"
          >
            <select
              name="state"
              placeholder="Select state"
              className="w-full bg-white border border-black rounded py-2 px-3 select-option-sell-your-car"
            >
              <option>Select state</option>
              {STATES.map((item, idx) => (
                <option key={`option-${item.name}-${idx}`}>{item.name}</option>
              ))}
            </select>
          </Form.Item>
          <Form.Item
            name="county"
            label="County"
            rules={[
              { required: true, message: 'County is required' },
              { pattern: /^[a-zA-Z\s]+$/g, message: 'Country consist only of alphabets' }
            ]}
            className="custom-antd-label block"
          >
            <input name="country" className="w-full border border-black rounded py-2 px-3" type="text" />
          </Form.Item>

          <Form.Item
            name="yearsAtJob"
            label="Years at Job"
            rules={[{ required: true, message: 'Years at job is required' }]}
            className="custom-antd-label block"
          >
            <select className="w-full border border-black bg-white rounded py-2 px-3 select-option-sell-your-car">
              <option disabled selected>
                Select year
              </option>
              {listYears.map((year, idx) => (
                <option key={`options-year-${year}-${idx}`}>{year}</option>
              ))}
            </select>
          </Form.Item>
          <Form.Item
            name="monthsAtJob"
            label="Months at Job"
            rules={[{ required: true, message: 'Months at job is required' }]}
            className="custom-antd-label block"
          >
            <select className="w-full border border-black bg-white rounded py-2 px-3 select-option-sell-your-car">
              <option disabled selected>
                Select month
              </option>
              {listMonths.map((month, idx) => (
                <option key={`option-month-${month}-${idx}`}>{month}</option>
              ))}
            </select>
          </Form.Item>
        </div>

        <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-0 xl:gap-6 lg:gap-5 md:gap-4">
          <div>
            <p className="text-black text-sm mb-2 font-semibold">Job Type</p>
            <div className="flex gap-2">
              {jobTypeOptions.map((item, idx) => (
                <div
                  className={`flex flex-col items-center px-2 shadow-xl rounded-md ${
                    preJobType === item.id ? 'bg-primary text-white' : undefined
                  }`}
                  key={`color-${item.name}-${idx}`}
                >
                  <button
                    type="button"
                    className="w-16 h-10 rounded cursor-pointer flex justify-center items-center"
                    data-type={item.id}
                    onClick={handleChangePreJobType}
                  >
                    {item.name}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Form>
  )
}

PreviousJobInformation.propTypes = {
  previousInformationFormRef: PropTypes.object,
  preJobType: PropTypes.string,
  handleChangePreJobType: PropTypes.func
}

function JobInformation({
  jobInformationFormRef,
  previousInformationFormRef,
  jobType,
  handleChangeJobType,
  preJobType,
  handleChangePreJobType
}) {
  const listYears = getListYearsAtJob()
  const listMonths = getListMonthsAtJob()
  const [showPreviousForm, setPreviousForm] = useState(false)

  const handleYearsAtJob = () => {
    if (jobInformationFormRef?.current?.getFieldValue('yearsAtJob')) {
      const yearsAtJobValue = jobInformationFormRef?.current?.getFieldValue('yearsAtJob') < 2
      setPreviousForm(yearsAtJobValue)
    }
  }

  const handleNetAnnualIncome = () => {
    const annualGrossIncomeValue = jobInformationFormRef?.current?.getFieldValue('annualGrossIncome')
    jobInformationFormRef?.current?.setFieldsValue({
      annualNetIncome: Math.round(annualGrossIncomeValue * 0.8 * 100) / 100
    })
  }

  const onResolvingZipCode = async (event) => {
    const zipCode = event.target.value
    if (zipCode) {
      try {
        const location = await ClaimServices.getLocationFromZipCode(zipCode)

        if (location) {
          const { city, region } = location.data
          if (city && city !== '-') jobInformationFormRef.current.setFieldsValue({ city })
          const state = STATES.find((e) => e.name === region)
          if (state) {
            jobInformationFormRef.current.setFieldsValue({ state: state.name })
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const debounceZipCodeChange = useMemo(() => {
    return debounce((value) => {
      onResolvingZipCode(value)
    }, 500)
  }, [])

  return (
    <>
      <Form layout="vertical" ref={jobInformationFormRef} name="job-information-ref" onChange={handleYearsAtJob}>
        <div className="mt-4 step-4">
          <p className="text-2xl text-black font-bold mb-3">Job</p>
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-0 xl:gap-6 lg:gap-5 md:gap-4">
            <Form.Item
              name="employerName"
              label="Employer Name"
              rules={[
                { required: true, message: 'Employer name is required' },
                { pattern: /^[a-zA-Z0-9\s]+$/g, message: 'Alphanumeric string accepted' }
              ]}
              className="custom-antd-label block"
            >
              <input
                name="employerName"
                autoFocus
                className="w-full border border-black rounded py-2 px-3"
                type="text"
              />
            </Form.Item>
            <Form.Item
              name="jobStatus"
              label="Job Status"
              rules={[{ required: true, message: 'Job status is required' }]}
              className="custom-antd-label block"
            >
              <select
                name="jobStatus"
                className="w-full border border-black bg-white rounded py-2 px-3 select-option-sell-your-car"
              >
                <option>Select Job Status</option>
                {jobStatusOptions.map((jobStatus, idx) => (
                  <option value={jobStatus.id} key={`option-${jobStatus.id}-${idx}`}>
                    {jobStatus.name}
                  </option>
                ))}
              </select>
            </Form.Item>
            <Form.Item
              name="annualGrossIncome"
              label="Annual Gross Income"
              rules={[{ required: true, message: 'Annual gross income is required' }]}
              className="custom-antd-label block"
            >
              <NumberInput
                name="annualGrossIncome"
                placeholder="$"
                className="w-full border border-black rounded py-2 px-3"
                onChange={handleNetAnnualIncome}
              />
            </Form.Item>
            <Form.Item
              name="annualNetIncome"
              label="Annual Net Income"
              rules={[{ required: true, message: 'Annual net income is required' }]}
              className="custom-antd-label block"
            >
              <NumberInput
                name="annualNetIncome"
                placeholder="$"
                className="w-full border border-black rounded py-2 px-3"
              />
            </Form.Item>

            <Form.Item
              name="workPhone"
              label="Work Phone"
              rules={[
                { required: true, message: 'Work phone is required' },
                { pattern: /\d{3}[-]\d{3}[-]\d{4}/, message: 'Work phone is invalid format' }
              ]}
              className="custom-antd-label block"
            >
              <InputMask
                name="workPhone"
                placeholder="NXX-NXX-XXXX"
                className="w-full border border-black rounded py-2 px-3"
                mask="999-999-9999"
                type="text"
              />
            </Form.Item>
            <Form.Item
              name="jobTitle"
              label="Job Title"
              rules={[
                { required: true, message: 'Job title is required' },
                { pattern: /^[a-zA-Z\s]+$/g, message: 'Job Title consist only of alphabets' }
              ]}
              className="custom-antd-label block"
            >
              <input name="jobTitle" className="w-full border border-black rounded py-2 px-3" type="text" />
            </Form.Item>
          </div>

          <Form.Item
            name="streetAddress"
            label="Street Address"
            rules={[
              { required: true, message: 'Street Address is required' },
              { pattern: /^[a-zA-Z0-9\s]+$/g, message: 'Alphanumeric string accepted' }
            ]}
            className="custom-antd-label block"
          >
            <input
              name="streetAddress"
              autoFocus
              className="w-full border border-black rounded py-2 px-3"
              type="text"
            />
          </Form.Item>

          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-0 xl:gap-6 lg:gap-5 md:gap-4">
            <Form.Item
              name="zipCode"
              label="Zip Code"
              rules={[{ required: true, message: 'Zip code is required' }]}
              className="custom-antd-label block"
            >
              <InputMask
                name="zipCode"
                className="w-full border border-black rounded py-2 px-3"
                type="text"
                maxLength={5}
                onChange={debounceZipCodeChange}
              />
            </Form.Item>
            <Form.Item
              name="city"
              label="City"
              rules={[
                { required: true, message: 'City is required' },
                { pattern: /^[a-zA-Z\s]+$/g, message: 'City consist only of alphabets' }
              ]}
              className="custom-antd-label block"
            >
              <input name="city" className="w-full border border-black rounded py-2 px-3" type="text" />
            </Form.Item>

            <Form.Item
              name="state"
              label="State"
              rules={[{ required: true, message: 'State is required' }]}
              className="custom-antd-label block"
            >
              <select
                name="state"
                className="w-full bg-white border border-black rounded py-2 px-3 select-option-sell-your-car"
              >
                <option>Select state</option>
                {STATES.map((item, idx) => (
                  <option key={`option-${item.name}-${idx}`}>{item.name}</option>
                ))}
              </select>
            </Form.Item>
            <Form.Item
              name="county"
              label="County"
              rules={[
                { required: true, message: 'County is required' },
                { pattern: /^[a-zA-Z\s]+$/g, message: 'Country consist only of alphabets' }
              ]}
              className="custom-antd-label block"
            >
              <input name="country" className="w-full border border-black rounded py-2 px-3" type="text" />
            </Form.Item>

            <Form.Item
              name="yearsAtJob"
              label="Years at Job"
              rules={[{ required: true, message: 'Years at job is required' }]}
              className="custom-antd-label block"
            >
              <select className="w-full border border-black bg-white rounded py-2 px-3 select-option-sell-your-car">
                <option disabled selected>
                  Select year
                </option>
                {listYears.map((year, idx) => (
                  <option key={`options-year-${year}-${idx}`}>{year}</option>
                ))}
              </select>
            </Form.Item>
            <Form.Item
              name="monthsAtJob"
              label="Months at Job"
              rules={[{ required: true, message: 'Months at job is required' }]}
              className="custom-antd-label block"
            >
              <select className="w-full border border-black bg-white rounded py-2 px-3 select-option-sell-your-car">
                <option disabled selected>
                  Select month
                </option>
                {listMonths.map((month, idx) => (
                  <option key={`option-month-${month}-${idx}`}>{month}</option>
                ))}
              </select>
            </Form.Item>
          </div>

          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-0 xl:gap-6 lg:gap-5 md:gap-4">
            <div>
              <p className="text-black text-sm mb-2 font-semibold">Job Type</p>
              <div className="flex gap-2">
                {jobTypeOptions.map((item, idx) => (
                  <div
                    className={`flex flex-col items-center px-2 shadow-xl rounded-md ${
                      jobType === item.id ? 'bg-primary text-white' : undefined
                    }`}
                    key={`color-${item.name}-${idx}`}
                  >
                    <button
                      type="button"
                      className="w-16 h-10 rounded cursor-pointer flex justify-center items-center"
                      data-type={item.id}
                      onClick={handleChangeJobType}
                    >
                      {item.name}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Form>
      <div className={`mt-5 border-t-2 ${!showPreviousForm && 'hidden'}`}>
        <PreviousJobInformation
          preJobType={preJobType}
          handleChangePreJobType={handleChangePreJobType}
          previousInformationFormRef={previousInformationFormRef}
        />
      </div>
    </>
  )
}

JobInformation.propTypes = {
  jobInformationFormRef: PropTypes.object,
  previousInformationFormRef: PropTypes.object,
  preJobType: PropTypes.string,
  handleChangeJobType: PropTypes.func,
  jobType: PropTypes.string,
  handleChangePreJobType: PropTypes.func
}

export default JobInformation
