import carImage from '../assets/car.png'
import buyCarImage from '../assets/icon/buy-car.png'
import financeImage from '../assets/icon/finance.png'
import sellCarImage from '../assets/icon/sell-car.png'

export const carInfo = {
  id: '1',
  image: carImage,
  images: [
    carImage,
    carImage,
    carImage,
    carImage,
    carImage,
    carImage,
    carImage,
    carImage,
    carImage,
    carImage,
    carImage,
    carImage
  ],
  name: '',
  style: '',
  mileage: '',
  engine: '',
  transmission: '',
  price: 0,
  make: '',
  year: '',
  model: '',
  comparisonData: {
    low: {
      price: 0,
      distance: 0
    },
    middle: {
      price: 0,
      distance: 0
    },
    high: {
      price: 0,
      distance: 0
    }
  },
  favorite: true,
  comparison: true,
  extColor: 'Gray',
  intColor: 'Black',
  vin: '',
  stock: '7575',
  fullType: 'GAS',
  histories: [
    {
      title: 'JUNK / SALVAGE / LOSS'
    },
    {
      title: 'ODOMETER READING',
      content: [
        ['21754 miles', '11/03/21', 'Reported reading', 'from title'],
        ['21754 miles', '11/03/21', 'Reported reading', 'from title']
      ]
    },
    {
      title: 'TITLE INFORMATION',
      content: [
        ['11/03/21', 'Title issued', 'California'],
        ['11/03/21', 'Title issued', 'California']
      ]
    }
  ],
  equipment: [
    'Air filtration',
    'Side curtain airbags: front',
    'Front air conditioning: automatic alimate control',
    'rear',
    'Front air conditioning sones: single',
    'Side-curtain airbag rollover sensor',
    'Rear vents: second row',
    'Antenna type: element',
    'Airbag deactivation: occupant sensing passenger',
    'Auxiliary audio input: Bluetooth',
    'Front airbags: dual',
    'USB',
    'Side airbags: front',
    'Radio: AM/FM',
    'Radio data system',
    'Speed sensitive volume control'
  ]
}

export const similarCars = [
  {
    id: '1',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    favorite: true,
    comparison: true
  },
  {
    id: '2',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: false
  },
  {
    id: '3',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true
  },
  {
    id: '4',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: false
  },
  {
    id: '5',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true
  },
  {
    id: '6',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    favorite: true,
    comparison: true
  },
  {
    id: '7',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: false
  },
  {
    id: '8',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true
  },
  {
    id: '9',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: false
  },
  {
    id: '10',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true
  }
]

export const priceBetweenCars = [
  {
    id: '1',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    favorite: true,
    comparison: true
  },
  {
    id: '2',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: false
  },
  {
    id: '3',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true
  },
  {
    id: '4',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: false
  },
  {
    id: '5',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true
  },
  {
    id: '6',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: false
  },
  {
    id: '7',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true
  },
  {
    id: '8',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: false
  },
  {
    id: '9',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true
  }
]

export const carSummaries = [
  {
    title: 'BUY A CAR',
    detail: 'Thousands of cars to choose from our dealer network.',
    link: '/buy',
    linkDetail: 'Search Inventory',
    image: buyCarImage
  },
  {
    title: 'SELL YOUR CAR',
    detail: 'We will give a fair offer on your car, and pick it up from your driveway',
    link: '/sell',
    linkDetail: 'Find Out More',
    image: sellCarImage
  },
  {
    title: 'FINANCING',
    detail: 'Find out your payment terms and monthly payment before you buy',
    link: '/finance',
    linkDetail: 'Get Prequalified',
    image: financeImage
  }
]

export const bestDeals = [
  {
    id: '1',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    favorite: true,
    comparison: true
  },
  {
    id: '2',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: false
  },
  {
    id: '3',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true
  },
  {
    id: '4',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: false
  },
  {
    id: '5',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true
  },
  {
    id: '6',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparison: false
  },
  {
    id: '7',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 22995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true
  },
  {
    id: '8',
    image: carImage,
    name: '2018 Jeep Compass',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparison: false
  }
]

export const cars = [
  {
    image: carImage,
    name: '2018 Toyoto civic',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: '5 Speed Automatic',
    price: 19000,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    trim: '4dr Sdn w/Premium Pkg',
    favorite: true,
    comparison: true,
    make: 'Toyota',
    year: '2018',
    model: 'TL'
  },
  {
    image: carImage,
    name: '2016 Honda city',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: false,
    trim: '4dr Sdn w/Premium Pkg',
    make: 'Honda',
    year: '2016',
    model: 'city'
  },
  {
    image: carImage,
    name: '2015 Suzuki mehran',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true,
    trim: '4dr Sdn w/Premium Pkg',
    make: 'Suzuki',
    year: '2015',
    model: 'mehran'
  },
  {
    image: carImage,
    name: '2011 Toyota Civic',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: false,
    trim: '4dr Sdn w/Premium Pkg',
    make: 'Toyota',
    year: '2011',
    model: 'civic'
  },
  {
    image: carImage,
    name: '2017 Toyota Hillux',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 24995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true,
    trim: '4dr Sdn w/Premium Pkg',
    make: 'Toyota',
    year: '2017',
    model: 'hillux'
  },
  {
    image: carImage,
    name: '2018 Honda Civic',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparison: false,
    make: 'Honda',
    year: '2018',
    model: 'civic',
    trim: '4dr Sdn w/Premium Pkg'
  },
  {
    image: carImage,
    name: '2017 Suzuki Alto',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 22995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true,
    trim: '4dr Sdn w/Premium Pkg',
    make: 'Suzuki',
    year: '2017',
    model: 'Alto'
  },
  {
    image: carImage,
    name: '2018 Honda Civic',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparison: false,
    make: 'Honda',
    year: '2018',
    model: 'civic',
    trim: '4dr Sdn w/Premium Pkg'
  },
  {
    image: carImage,
    name: '2017 Suzuki Alto',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 22995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true,
    make: 'Suzuki',
    year: '2017',
    model: 'Alto',
    trim: '4dr SUV nology Package'
  },
  {
    image: carImage,
    name: '2018 Honda Civic',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparison: false,
    make: 'Honda',
    year: '2018',
    model: 'civic',
    trim: '4dr SUV nology Package'
  },
  {
    image: carImage,
    name: '2018 Honda Civic',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparison: false,
    make: 'Honda',
    year: '2018',
    model: 'civic',
    trim: '4dr SUV nology Package'
  },
  {
    image: carImage,
    name: '2017 Suzuki Alto',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 22995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true,
    make: 'Suzuki',
    year: '2017',
    model: 'Alto',
    trim: '4dr SUV nology Package'
  },
  {
    image: carImage,
    name: '2018 Honda Civic',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparison: false,
    make: 'Honda',
    year: '2018',
    model: 'civic',
    trim: '4dr SUV nology Package'
  },
  {
    image: carImage,
    name: '2017 Suzuki Alto',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 22995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true,
    make: 'Suzuki',
    year: '2017',
    model: 'Alto',
    trim: '4dr SUV nology Package'
  },
  {
    image: carImage,
    name: '2018 Honda Civic',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparison: false,
    make: 'Honda',
    year: '2018',
    model: 'civic',
    trim: '4dr SUV nology Package'
  },
  {
    image: carImage,
    name: '2018 Honda Civic',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparison: false,
    make: 'Honda',
    year: '2018',
    model: 'civic',
    trim: '4dr SUV nology Package'
  },
  {
    image: carImage,
    name: '2017 Suzuki Alto',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 22995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true,
    make: 'Suzuki',
    year: '2017',
    model: 'Alto',
    trim: '4dr SUV nology Package'
  },
  {
    image: carImage,
    name: '2018 Honda Civic',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparison: false,
    make: 'Honda',
    year: '2018',
    model: 'civic',
    trim: '4dr SUV nology Package'
  },
  {
    image: carImage,
    name: '2017 Suzuki Alto',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 22995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true,
    make: 'Suzuki',
    year: '2017',
    model: 'Alto',
    trim: 'Sedan'
  },
  {
    image: carImage,
    name: '2018 Honda Civic',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparison: false,
    make: 'Honda',
    year: '2018',
    trim: 'Sedan',
    model: 'civic'
  },
  {
    image: carImage,
    name: '2018 Honda Civic',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparison: false,
    make: 'Honda',
    trim: 'Sedan',
    year: '2018',
    model: 'civic'
  },
  {
    image: carImage,
    name: '2017 Suzuki Alto',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 22995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true,
    make: 'Suzuki',
    year: '2017',
    trim: 'Sedan',
    model: 'Alto'
  },
  {
    image: carImage,
    name: '2018 Honda Civic',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparison: false,
    make: 'Honda',
    year: '2018',
    trim: 'Sedan',
    model: 'civic'
  },
  {
    image: carImage,
    name: '2017 Suzuki Alto',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 22995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true,
    make: 'Suzuki',
    year: '2017',
    trim: 'Sedan',
    model: 'Alto'
  },
  {
    image: carImage,
    name: '2018 Honda Civic',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparison: false,
    make: 'Honda',
    year: '2018',
    model: 'civic',
    trim: 'Sedan'
  },
  {
    image: carImage,
    name: '2018 Honda Civic',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparison: false,
    make: 'Honda',
    year: '2018',
    model: 'civic',
    trim: 'SUV'
  },
  {
    image: carImage,
    name: '2017 Suzuki Alto',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: false,
    comparisonData: {
      low: {
        price: 12995,
        distance: 12995
      },
      middle: {
        price: 22995,
        distance: 12995
      },
      high: {
        price: 32995,
        distance: 12995
      }
    },
    comparison: true,
    make: 'Suzuki',
    year: '2017',
    model: 'Alto',
    trim: 'SUV'
  },
  {
    image: carImage,
    name: '2018 Honda Civic',
    style: 'Latitude 4×4',
    mileage: '81,981',
    engine: '2.4L 4 Cylinders',
    transmission: 'Automatic',
    price: 22995,
    favorite: true,
    comparison: false,
    make: 'Honda',
    year: '2018',
    model: 'civic',
    trim: 'SUV'
  }
]
