import { googleLogout, useGoogleLogin } from '@react-oauth/google'
import { Form, Input, notification } from 'antd'
import axios from 'axios'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import { STORAGE_KEYS } from 'src/constants/constants'
import { clear, load, save } from 'src/lib/storage'
import LoginLogo from '../../assets/new-logo.png'
import metaPages from '../../data/metaPages.json'
import SinglePageMetaDecorator from '../../decorators/SinglePageMetaDecorator'
import * as AuthService from '../../services/auth.service'
import * as CustomerService from '../../services/customer.service'
import { getPublicIp } from '../../utils'
import './style.scss'
// Loaded twice in case we need to change it in the future
// eslint-disable-next-line import/no-duplicates
import metaPageImageLogin from '../../assets/background-image-login.png'
// eslint-disable-next-line import/no-duplicates
import metaPageImageForgotPassword from '../../assets/background-image-login.png'

const facebookAppId =
  process.env.REACT_APP_FACEBOOK_APP_ID !== undefined && process.env.REACT_APP_FACEBOOK_APP_ID !== ''
    ? process.env.REACT_APP_FACEBOOK_APP_ID
    : ''
const googleClientId =
  process.env.REACT_APP_GOOGLE_CLIENT_ID !== undefined && process.env.REACT_APP_GOOGLE_CLIENT_ID !== ''
    ? process.env.REACT_APP_GOOGLE_CLIENT_ID
    : ''

function Login({ isLogin, isLoginAdmin, isForgotPassword }) {
  const currentYear = new Date().getFullYear()
  const [googleUser, setGoogleUser] = useState([])
  const [googleProfile, setGoogleProfile] = useState([])

  const title = isLogin && !isForgotPassword ? metaPages.login.title : metaPages.forgotPassword.title
  const description = isLogin && !isForgotPassword ? metaPages.login.description : metaPages.forgotPassword.description
  const imageUrl = isLogin && !isForgotPassword ? metaPageImageLogin : metaPageImageForgotPassword
  const imageAlt = isLogin && !isForgotPassword ? metaPages.login.imageAlt : metaPages.forgotPassword.imageAlt

  const formRef = useRef()
  const navigate = useNavigate()
  const { handleCustomerAccountLogin, handleDealerAccountLogin, handleAdminAccountLogin, handleAccountSettingsLogin } =
    useAuthorizationContext()
  const [show, setShow] = useState(false)
  const [cookies, , removeCookies] = useCookies()

  const hideOnProduction = process.env.REACT_APP_HIDE_ON_PRODUCTION === 'true'

  const openNotification = (type) => {
    const action = type === 'success' ? notification.success : notification.error
    const message = type === 'success' ? 'Logged in successfully' : 'Incorrect user/email or password'
    action({
      message,
      placement: 'topRight'
    })
  }

  const openNotificationForgotPassword = (type) => {
    const action = type === 'success' ? notification.success : notification.error
    const message =
      type === 'success' ? 'We have sent a verification link to your email address' : 'Incorrect user/email'
    action({
      message,
      placement: 'topRight'
    })
  }

  const handleChangeShow = () => {
    setShow(!show)
  }

  const redirectClaimYourBusinessPage = () => {
    navigate('/claim')
  }

  const handleForgotPassword = async () => {
    const values = await formRef.current
      .validateFields()
      .then((response) => response)
      .catch((error) => error)
    if (values.errorFields && values.errorFields.length > 0) return
    const { email } = values
    AuthService.forgotPassword({ email })
      .then(() => {
        openNotificationForgotPassword('success')
      })
      .catch((error) => {
        openNotificationForgotPassword('error')
        console.error(error)
      })
  }

  const handleSignIn = async (customValues) => {
    const ip = await getPublicIp()
    let loginType = 'normal'
    let role = 'customer'
    let email = ''
    let password = ''
    let avatar = ''
    let fullName = ''

    if (customValues?.loginType !== undefined) {
      role = 'customer'
      loginType = customValues.loginType
      email = customValues.email
      password = customValues.password
      avatar = customValues.avatar
      fullName = customValues.fullName
    } else {
      const values = await formRef.current
        .validateFields()
        .then((response) => response)
        .catch((error) => error)
      if (values.errorFields && values.errorFields.length > 0) return
      role = values.role
      email = values.email
      password = values.password
    }

    if (isLoginAdmin || role === 'admin') {
      AuthService.adminLogin({ email, password })
        .then((response) => {
          const data = response.data
          const { access_token: accessToken, token_type: tokenType } = data
          const userSettings = JSON.stringify(data)
          save(STORAGE_KEYS.ACCESS_TOKEN, accessToken)
          save(STORAGE_KEYS.TOKEN_TYPE, tokenType)
          save(STORAGE_KEYS.ADMIN_LOGIN, 'true')
          save(STORAGE_KEYS.USER_SETTINGS, userSettings)
          openNotification('success')
          handleAdminAccountLogin(true)
          handleAccountSettingsLogin(userSettings)
          navigate('/admin-dashboard')
        })
        .catch((error) => {
          openNotification('error')
          console.error(error)
        })
    } else {
      const tradeIns = load(STORAGE_KEYS.CUSTOMER_TRADE_INS) || []
      let action = AuthService.loginWithEmail
      if (role === 'customer') {
        action = AuthService.customerLogin
      }

      action({ email, password, tradeIns, loginType, avatar, ip, fullName })
        .then((response) => {
          const data = response.data
          const { access_token: accessToken, token_type: tokenType, role } = data
          const userSettings = JSON.stringify(data)
          save(STORAGE_KEYS.ACCESS_TOKEN, accessToken)
          save(STORAGE_KEYS.TOKEN_TYPE, tokenType)
          save(STORAGE_KEYS.USER_SETTINGS, userSettings)
          handleAccountSettingsLogin(userSettings)
          if (role === 'customer') {
            save(STORAGE_KEYS.CUSTOMER_LOGIN, 'true')
            clear(STORAGE_KEYS.FAVOURITE_CARS)
            clear(STORAGE_KEYS.CUSTOMER_TRADE_INS)
            handleCustomerAccountLogin(true)

            openNotification('success')

            if (cookies.trade_in) {
              const tradeIn = cookies.trade_in

              CustomerService.getCustomerDashboardInfo().then((customerResponse) => {
                if (customerResponse.isSuccessful === 'Yes' && Object.keys(customerResponse.data).length > 0) {
                  const budgetCalculator = customerResponse?.customer_budget_calculator ?? []
                  save(STORAGE_KEYS.EZ_DEALZ_BUDGET, JSON.stringify(budgetCalculator))
                  CustomerService.handleSaveTrade({
                    vin: undefined,
                    make: tradeIn.makeName,
                    model: tradeIn.modelName,
                    year: tradeIn.year,
                    trim: tradeIn.trim,
                    ownership: 'purchase',
                    mileage: tradeIn.mileage,
                    zipcode: tradeIn.zip,
                    owePrice: undefined,
                    condition: tradeIn.condition.toLowerCase(),
                    engine: undefined,
                    transmission: undefined,
                    color: undefined,
                    firstName: customerResponse.data.contacts?.name?.first,
                    lastName: customerResponse.data.contacts?.name?.last,
                    email: customerResponse.data.email,
                    phone: customerResponse.data.contacts?.phone,
                    comment: undefined,
                    estimatedvalue: tradeIn.estimatedvalue
                  }).then(() => {
                    removeCookies('trade_in')
                  })
                }
              })
            }

            // we clear the shared dealership session
            sessionStorage.removeItem(STORAGE_KEYS.IS_SHARED_BY_DEALERSHIP)
            sessionStorage.removeItem(STORAGE_KEYS.SHARED_BY_DEALERSHIP_ID)

            navigate('/customer-dashboard')
          } else {
            save(STORAGE_KEYS.DEALER_LOGIN, 'true')
            handleDealerAccountLogin(true)
            openNotification('success')
            navigate('/dealer-dashboard')
          }
        })
        .catch((error) => {
          openNotification('error')
          console.error(error)
        })
    }
  }

  const sharedByDealership = sessionStorage.getItem(STORAGE_KEYS.IS_SHARED_BY_DEALERSHIP) === 'true'
  const handleRedirectToHome = () => {
    if (sharedByDealership) {
      const dealershipId = sessionStorage.getItem(STORAGE_KEYS.SHARED_BY_DEALERSHIP_ID)
      navigate(`/dealership/${dealershipId}`)
    } else {
      navigate('/')
    }
  }

  const responseFacebook = (response) => {
    if (response?.id !== undefined) {
      const customValues = {}
      customValues.email = response.email
      customValues.password = response.id
      customValues.fullName = response.name
      customValues.avatar = response?.picture?.data?.url || ''
      customValues.loginType = 'facebook'
      handleSignIn(customValues)
    } else {
      openNotification('error')
      console.error(response)
    }
  }

  const logOut = () => {
    googleLogout()
    setGoogleProfile(null)
  }

  useEffect(() => {
    logOut()
  }, [])

  useEffect(() => {
    if (googleUser) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`, {
          headers: {
            Authorization: `Bearer ${googleUser.access_token}`,
            Accept: 'application/json'
          }
        })
        .then((res) => {
          setGoogleProfile(res.data)
        })
        .catch((err) => console.error(err))
    }
  }, [googleUser])

  useEffect(() => {
    if (googleProfile?.id !== undefined) {
      const customValues = {}
      customValues.email = googleProfile.email
      customValues.password = googleProfile.id
      customValues.fullName = googleProfile.name
      customValues.avatar = googleProfile?.picture || ''
      customValues.loginType = 'google'
      handleSignIn(customValues)
    }
  }, [googleProfile])

  const loginGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setGoogleUser(codeResponse),
    onError: (error) => console.error('Login Failed:', error)
  })

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      handleSignIn()
    }
  }

  return (
    <div className="login-page">
      <SinglePageMetaDecorator title={title} description={description} imageUrl={imageUrl} imageAlt={imageAlt} />
      <div className="auth-main-block">
        <div className="form-login">
          <img
            className="cursor-pointer"
            onClick={handleRedirectToHome}
            width="100%"
            height="100%"
            src={LoginLogo}
            alt="login logo"
          />
          {isLoginAdmin && <p className="text-black text-center my-4 text-xl font-bold">Login Admin</p>}
          {isForgotPassword && <p className="text-black text-center my-4 text-xl font-bold">Forgot Password</p>}
          <Form layout="vertical" ref={formRef} name="vehicle-ref">
            <div className="mt-8 lg:px-12">
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Email/Username is required' }]}
                className="custom-antd-label"
              >
                <Input
                  name="email"
                  placeholder="Email/Username"
                  className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border"
                  type="text"
                  onPressEnter={handleKeypress}
                />
              </Form.Item>
              {!isForgotPassword && (
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      pattern: /^(?=.*[\d])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,16}$/,
                      message:
                        'Must Contain min 8 Characters, a Uppercase, a Lowercase, a Number and a Special Character from (!, ?, @, #, $, %, ^, &, *)'
                    }
                  ]}
                  className="custom-antd-label"
                >
                  <div className="relative border rounded-xl border-gray">
                    <Input
                      name="password"
                      placeholder="Password"
                      className="w-full py-2 pl-3 pr-10 rounded-xl border-0"
                      type={show ? 'text' : 'password'}
                      onPressEnter={handleKeypress}
                    />
                    {show ? (
                      <AiFillEyeInvisible
                        onClick={handleChangeShow}
                        className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                      />
                    ) : (
                      <AiFillEye
                        onClick={handleChangeShow}
                        className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                      />
                    )}
                  </div>
                </Form.Item>
              )}
              <div className="mt-6">
                {!isForgotPassword && (
                  <button
                    type="button"
                    className="bg-primary hover:bg-activePrimary font-semibold rounded-lg px-2 py-3 outline-none text-lg w-full text-white flex justify-center uppercase"
                    onClick={handleSignIn}
                  >
                    Sign in
                  </button>
                )}
                {isForgotPassword && (
                  <>
                    <button
                      type="button"
                      className="bg-primary hover:bg-activePrimary font-semibold rounded-lg px-2 py-3 outline-none text-lg w-full text-white flex justify-center uppercase"
                      onClick={handleForgotPassword}
                    >
                      REQUEST LINK
                    </button>
                    <button
                      type="button"
                      className="hover:bg-primary text-primary hover:text-white font-semibold rounded-lg px-2 py-3 outline-none text-lg w-full claim-your-business flex justify-center uppercase go-back-button"
                      onClick={() => navigate(-1)}
                    >
                      OR GO BACK
                    </button>
                  </>
                )}
              </div>
              {isLogin && !hideOnProduction && (
                <div className="mt-6 flex justify-between text-base font-semibold">
                  <div className="text-primary cursor-pointer">
                    <button type="button" className="" onClick={() => navigate('/forgot-password')}>
                      {'Forgot password >'}
                    </button>
                  </div>
                  <div className="text-primary cursor-pointer">
                    <button type="button" className="" onClick={() => navigate('/signup')}>
                      {'Sign up >'}
                    </button>
                  </div>
                </div>
              )}
              {!isLogin && !isLoginAdmin && (
                <div className="mt-6 flex justify-between text-base font-semibold">
                  <div className="text-primary cursor-pointer">
                    <button type="button" className="" onClick={() => navigate('/forgot-password')}>
                      {'Forgot password >'}
                    </button>
                  </div>
                </div>
              )}
              {isLogin && !sharedByDealership && (
                <div className="mt-14">
                  <button
                    onClick={redirectClaimYourBusinessPage}
                    type="button"
                    className="hover:bg-primary text-primary hover:text-white font-semibold rounded-lg px-2 py-3 outline-none text-lg w-full claim-your-business flex justify-center uppercase"
                  >
                    Claim Your Dealership
                  </button>
                </div>
              )}
            </div>
          </Form>
          {isLogin && !hideOnProduction && (facebookAppId !== '' || googleClientId !== '') && (
            <div className="mt-14 lg:px-12">
              <div className="text-center text-lg font-semibold">CUSTOMER</div>
            </div>
          )}
          {isLogin && !hideOnProduction && facebookAppId !== '' && (
            <div className="mt-6 lg:px-12">
              <div className="text-center">
                <FacebookLogin
                  appId={facebookAppId}
                  fields="name,email,picture"
                  scope="public_profile,email"
                  callback={responseFacebook}
                  render={(renderProps) => (
                    <button
                      type="button"
                      className="bg-primary hover:bg-activePrimary font-semibold rounded-lg px-2 py-3 outline-none text-lg w-full text-white flex justify-center uppercase"
                      onClick={renderProps.onClick}
                    >
                      LOGIN WITH FACEBOOK
                    </button>
                  )}
                />
              </div>
            </div>
          )}
          {isLogin && !hideOnProduction && googleClientId !== '' && (
            <div className="mt-6 lg:px-12">
              <div className="text-center">
                <button
                  type="button"
                  className="bg-primary hover:bg-activePrimary font-semibold rounded-lg px-2 py-3 outline-none text-lg w-full text-white flex justify-center uppercase"
                  onClick={() => loginGoogle()}
                >
                  LOGIN WITH GOOGLE
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="text-white">Powered by MOTOix ©{currentYear}</div>
    </div>
  )
}

Login.propTypes = {
  isLogin: PropTypes.bool.isRequired,
  isLoginAdmin: PropTypes.bool.isRequired,
  isForgotPassword: PropTypes.bool.isRequired
}

export default Login
