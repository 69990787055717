import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import BodyStepper from 'src/components/Financing/BodyStepper'
import { STORAGE_KEYS } from 'src/constants/constants'
import * as CustomerServices from 'src/services/customer.service'

function SharingCreditApplicationToCustomer() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const dealershipId = searchParams.get('dealer_id')
  const linkCode = searchParams.get('code')
  const [verifying, setVerifying] = useState(true)
  const [currentStep, setCurrentStep] = useState(1)

  useEffect(() => {
    sessionStorage.setItem(STORAGE_KEYS.IS_SHARED_BY_DEALERSHIP, 'true')
    sessionStorage.setItem(STORAGE_KEYS.SHARED_BY_DEALERSHIP_ID, dealershipId)
    if (linkCode) {
      CustomerServices.checkValidLink(linkCode)
        .catch(() => {
          navigate('/link-expired')
        })
        .finally(() => setVerifying(false))
    }
  }, [dealershipId, linkCode])

  if (verifying) {
    return (
      <div style={{ fontSize: '32px' }} className="verification-expired-table">
        Verifying...
      </div>
    )
  }

  return (
    <div className="container md:w-full lg:w-3/4 xl:w-1/2 px-5 py-10">
      <BodyStepper currentStep={currentStep} setCurrentStep={setCurrentStep} linkCode={linkCode} />
    </div>
  )
}

export default SharingCreditApplicationToCustomer
