import { Button, Divider, Form, Input, Modal, Radio, Select, Space, Switch, Table, Tooltip, notification } from 'antd'
import moment from 'moment'
import * as PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { BsFillCircleFill, BsFillInfoCircleFill, BsInfoCircle } from 'react-icons/bs'
import { FaAngleRight } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { SearchOutlined, CheckOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import {
  CUSTOMER_STATUS_OPTIONS,
  // DEFAULT_OPTIONS,
  EMAIL_STATUS_OPTIONS,
  EZ_DEALZ_STATUS_OPTIONS,
  EZ_DEALZ_STATUS_OPTIONS_FILTER
} from 'src/constants/constants'
import * as CreditApplicationForm from 'src/services/creditApplicationForm.service'
import * as CustomerService from 'src/services/customer.service'
import { capitalizeFirstLetter, getSingleVehicleUrl, maskNumberThousands } from 'src/utils'
import * as dealerService from '../../../services/dealer.service'
import * as notificationService from '../../../services/notification.service'
import { CreditAppDetailModal } from '../CreditAppDetailModal'
import { CustomerModal } from '../CustomerModal'
import { SendEmailModal } from '../SendEmailModal'
import { TradeDetailModal } from '../TradeDetailModal'
import './style.scss'

const { Option } = Select

const data = []

const getParams = (params) => ({
  results: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params
})

export function EzDealzSent({ searchText, dealershipAccount, refetchInventory }) {
  const [sortedInfo, setSortedInfo] = useState({})
  const navigate = useNavigate()
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [initialData, setInitialData] = useState([...data])
  const [calculationRecord, setCalculationRecord] = useState({})
  const [loading, setLoading] = useState(true)
  const [salesPeople, setSalesPeople] = useState([])
  const [salesPeopleFilter, setSalesPeopleFilter] = useState([])
  const defaultSalesPeople = {
    name: 'Select',
    value: 'Select'
  }
  const [, setNewRow] = useState()
  const [selectedSalesPeople, setSelectedSalesPeople] = useState(defaultSalesPeople)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10
  })

  const [searchColumnText, setSearchColumnText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef('')

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchColumnText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchColumnText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    // eslint-disable-next-line react/no-unstable-nested-components
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={`${selectedKeys[0] || ''}`}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearchColumnText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: true })
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    // eslint-disable-next-line react/no-unstable-nested-components
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchColumnText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  })

  const [refresh, setRefresh] = useState(false)

  const [historyNotificationStatus, setHistoryNotificationStatus] = useState(null)

  const handleOnFilterHistoryNotificationStatus = (value) => {
    setHistoryNotificationStatus(value)
  }

  const openNotification = ({ message, description, type = 'success' }) => {
    const action = type === 'success' ? notification.success : notification.error
    action({
      message,
      description
    })
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
      setSelectedRows(selectedRows)
    }
  }

  const handleClick = (index) => {
    navigate(`/lead-details/${index}`)
  }

  const fetchData = (params = {}) => {
    setLoading(true)
    dealerService.getDealershipDealers().then((result) => {
      setSalesPeople(result.data)
      const salesPeople = []
      result.data.forEach((item, index) => {
        salesPeople[index] = {
          text: item.name,
          value: item.label
        }
      })
      setSalesPeopleFilter(salesPeople)
    })
    dealerService
      .dealershipOffers(getParams(params))
      .then((result) => {
        setInitialData(result.data.records)
        setPagination({
          ...params.pagination,
          total: result.data.count
        })
        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
      })
  }

  const [showCustomerModal, setShowCustomerModal] = useState(false)
  const [offer, setOffer] = useState({})
  const [showFinancingModal, setShowFinancingModal] = useState(false)
  const [rowFinancingStatus, setRowFinancingStatus] = useState('')
  const [rowFinancingModalTitle, setRowFinancingModalTitle] = useState('')
  const [financingRow, setFinancingRow] = useState({})
  const financingStatusOptions = ['pending', 'approved', 'conditional', 'declined']
  const [showFinancingConfirmation, setShowFinancingConfirmation] = useState(false)
  const formRef = useRef()
  const [financingStatusData, setFinancingStatusData] = useState({})
  const [showFinancingTermsModal, setShowFinancingTermsModal] = useState(false)
  const [financingTermsTitle, setFinancingTermsTitle] = useState('')
  const [offerFinancingData, setOfferFinancingData] = useState({})
  const [financingStatusSubmitState, setFinancingStatusSubmitState] = useState(false)

  const handleCustomerModal = () => {
    setShowCustomerModal(!showCustomerModal)
  }

  const handleFinancingModal = () => {
    setShowFinancingModal(!showFinancingModal)
    setFinancingStatusSubmitState(false)
    if (!showFinancingModal) {
      setCalculationRecord({})
    }
  }

  const handleCustomerClick = (record) => {
    setOffer(record)
    handleCustomerModal()
  }

  const setEzDealStatus = (record) => {
    if (record.deliv === 'Yes') {
      record.status = EZ_DEALZ_STATUS_OPTIONS[0]
    } else if (record.deposit > 0) {
      record.status = EZ_DEALZ_STATUS_OPTIONS[1]
    } else if (
      (record.record_type === 'offer' ||
        record.record_type === 'credit_application' ||
        record.record_type === 'lead') &&
      record.lastMessage === ''
    ) {
      record.status = EZ_DEALZ_STATUS_OPTIONS[2]
    } else if (record.depositOnDealer === 'Yes') {
      record.status = EZ_DEALZ_STATUS_OPTIONS[3]
    } else if (record.is_unread && record.history_notification_status === EMAIL_STATUS_OPTIONS[4].text) {
      record.status = EZ_DEALZ_STATUS_OPTIONS[4]
    } else if (
      !record.no_reply_needed &&
      (record.history_notification_status === EMAIL_STATUS_OPTIONS[0].text ||
        record.history_notification_status === EMAIL_STATUS_OPTIONS[1].text ||
        record.history_notification_status === EMAIL_STATUS_OPTIONS[3].text ||
        record.history_notification_status === 'Needs Reply')
    ) {
      record.status = EZ_DEALZ_STATUS_OPTIONS[5]
    }
  }

  const getCustomerStatus = (record, type) => {
    let property = ''

    if (record.lastCustomerVisit) {
      const now = moment()
      const lastVisitDate = moment(record.lastCustomerVisit, 'MM/DD/YY hh:mma')
      const hoursDiff = Math.abs(now.diff(lastVisitDate, 'hours'))

      if (hoursDiff === 0) {
        property = type === 'text' ? CUSTOMER_STATUS_OPTIONS[0].text : CUSTOMER_STATUS_OPTIONS[0].color
      } else if (hoursDiff <= 48) {
        property = type === 'text' ? CUSTOMER_STATUS_OPTIONS[1].text : CUSTOMER_STATUS_OPTIONS[1].color
      } else if (hoursDiff > 48) {
        property = type === 'text' ? 'hidden' : 'hidden'
      }
    }

    return property
  }

  const getCpopColor = (record) => {
    let color = ''

    if (record.visits > 0 && record.cPopId) {
      color = 'lightgreen'
    } else if (!record.cPopId) {
      color = 'purple'
    } else {
      color = 'none'
    }

    return color
  }

  const handleDepositChange = (value, record) => {
    setLoading(true)
    const params = {
      offers: [record.key],
      depositOnDealer: value
    }
    dealerService
      .updateDealerOffers(params)
      .then(() => {
        const newRecords = initialData.map((data) => {
          if (data.key === record.key) {
            data.depositOnDealer = value
          }

          return data
        })
        setInitialData(newRecords)
        setLoading(false)
      })
      .catch((e) => console.error(e))
  }

  const handleFinancingStatus = (status, record) => {
    setRowFinancingStatus(status)
    setFinancingRow(record)

    const calculationId = record?.calculation_id || ''
    if (calculationId.length > 0) {
      dealerService
          .getVehicleCalculationRecord({id: calculationId})
          .then((response) => {
            setCalculationRecord(response.data)
          })
          .catch((e) => console.error(e))
    }

    if (status !== 'declined') {
      setShowFinancingModal(true)
    }

    switch (status) {
      case 'pending':
        setRowFinancingModalTitle('Financing Details')
        break

      case 'approved':
        setRowFinancingModalTitle('Approval Terms')
        break

      case 'conditional':
        setRowFinancingModalTitle('Conditional Approval Terms')
        break

      default:
        break
    }
  }

  const handleFinancingTermsModal = () => {
    setShowFinancingTermsModal(!showFinancingTermsModal)
  }

  const handleFinancingStatusTerms = (status, record) => {
    if (status === 'approved') {
      setFinancingTermsTitle('Approval Terms')
    }

    if (status === 'conditional') {
      setFinancingTermsTitle('Conditional Approval Terms')
    }

    setShowFinancingTermsModal(true)
    setOfferFinancingData(record.ezdealsfinancing)
  }

  const handleFinancingStatusChange = (selected) => {
    handleFinancingStatus(selected, financingRow)
  }

  const handleFinancingColumn = (record) => {
    const financingStatus = record.financing_status
    if (financingStatus === 'pending') {
      return (
        <div
          className="text-center text-blue-500 cursor-pointer flex items-center justify-center gap-1"
          onClick={() => handleFinancingStatus(financingStatus, record)}
        >
          <span>{capitalizeFirstLetter(financingStatus)}</span>
          <FaAngleRight size={14} />
        </div>
      )
    }

    if (financingStatus === 'declined') {
      return <span className="text-red-500">{capitalizeFirstLetter(financingStatus)}</span>
    }

    if (financingStatus === 'approved' || financingStatus === 'conditional' || financingStatus === 'conditioned') {
      const financingTextStatus = financingStatus === 'conditioned' ? 'conditional' : financingStatus
      return (
        <div
          className="text-center cursor-pointer flex items-center justify-center gap-1"
          onClick={() => handleFinancingStatusTerms(financingTextStatus, record)}
        >
          <span>{capitalizeFirstLetter(financingTextStatus)}</span>
          <BsFillInfoCircleFill size={15} color="blue" />
        </div>
      )
    }
  }

  const handleFinancingConfirmation = () => {
    setShowFinancingConfirmation(!showFinancingConfirmation)
  }

  const handleFinancingConfirmCancel = () => {
    setShowFinancingConfirmation(false)
  }

  const handleFinancingSubmission = async () => {
    const values = await formRef.current
      .validateFields()
      .then((response) => response)
      .catch((error) => error)
    if (values.errorFields && values.errorFields.length > 0) return

    setFinancingStatusData(values)
    setShowFinancingConfirmation(true)
  }

  const handleFinancingConfirmSubmit = () => {
    if (financingRow) {
      setFinancingStatusSubmitState(true)
      dealerService
        .updateDealerOffers({
          offers: [financingRow.record_id],
          financing_status: rowFinancingStatus,
          ...financingStatusData
        })
        .then(() => {
          fetchData()
          openNotification({ message: 'Success' })

          setFinancingStatusSubmitState(false)
          handleFinancingModal()
          setRowFinancingModalTitle('')
          setFinancingStatusData({})
          setRowFinancingStatus('')
          setFinancingRow({})
        })
        .catch((e) => {
          openNotification({ message: e.message, type: 'error' })
        })

      setShowFinancingConfirmation(false)
    } else {
      openNotification({ message: 'Error found. Please try again!', type: 'error' })
    }
  }

  const [selectedTrade, setSelectedTrade] = useState({})
  const [showTradeInfoModal, setShowTradeInfoModal] = useState(false)
  const [cPopId, setCPopId] = useState('')
  const [showEmailSendingModal, setShowEmailSendingModal] = useState(false)
  const [useFor, setUseFor] = useState('')
  const [notifications, setNotifications] = useState([])
  const [showCreditAppInfoModal, setShowCreditAppInfoModal] = useState(false)
  const [selectedCreditApp, setSelectedCreditApp] = useState({})

  const handleHistoryNotifications = (recordId, recordType) => {
    notificationService
      .historyNotificationsEmail(recordId, recordType)
      .then((result) => {
        setNotifications(result.data)
      })
      .catch((e) => {
        console.error(e.message)
      })
  }

  const handleTradeInfoModal = () => {
    setShowTradeInfoModal(!showTradeInfoModal)
  }

  const handleTradein = (tradeId) => {
    setLoading(true)
    CustomerService.getTradeDetail(tradeId).then((response) => {
      setSelectedTrade(response.data)
      setShowTradeInfoModal(true)
      setLoading(false)
    })
  }

  const handleEmailSendingModal = () => {
    setShowEmailSendingModal(!showEmailSendingModal)
  }

  const handleCreditAppInfoModal = () => {
    setShowCreditAppInfoModal(!showCreditAppInfoModal)
  }

  const handleCreditApp = (creditAppId) => {
    setLoading(true)
    CreditApplicationForm.getCreditApplicationFormById(creditAppId).then((response) => {
      setSelectedCreditApp(response.data)
      setShowCreditAppInfoModal(true)
      setLoading(false)
    })
  }

  const handleAcceptedDelivered = (name, status, record) => {
    setLoading(true)

    const params = {
      offers: [record.key]
    }
    params[name] = status

    dealerService
      .updateDealerOffers(params)
      .then(() => {
        const newRecords = initialData.map((data) => {
          if (data.key === record.key && name === 'accepted') {
            data.accpt = status
          }

          if (data.key === record.key && name === 'delivered') {
            data.deliv = status
          }

          return data
        })
        setInitialData(newRecords)
        setLoading(false)
        openNotification({ message: 'Success' })
      })
      .catch((e) => {
        setLoading(false)
        openNotification({ message: e.message, type: 'error' })
      })
  }

  const columns = [
    {
      title: 'DATE & TIME',
      dataIndex: 'dateTimeFormatted',
      sorter: {
        compare: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
      },
      sortOrder: sortedInfo.field === 'dateTimeFormatted' ? sortedInfo.order : null
    },
    {
      title: 'SALESPERSON',
      dataIndex: 'salesPersons',
      onFilter: (value, record) => record.salesPersons.startsWith(value),
      filterSearch: true,
      filters: salesPeopleFilter
    },
    {
      title: 'CUSTOMER',
      dataIndex: 'customer',
      width: '15%',
      ...getColumnSearchProps('customer'),
      render: (data, record) => (
        <div className="">
          <p
            className="text-blue-500 text-medium cursor-pointer flex gap-1"
            onClick={() => handleCustomerClick(record)}
          >
            {data}
            {getCustomerStatus(record, 'text') === 'hidden' ? null : (
              <Tooltip
                className="c-pop-tooltip ml-1 mr-1"
                title={getCustomerStatus(record, 'text')}
                placement="bottomLeft"
              >
                <BsFillCircleFill size={6} color={getCustomerStatus(record, 'color')} />
              </Tooltip>
            )}
          </p>
          <p className="flex gap-1 text-xs">
            {record.trade_in_id !== '' ? (
              <div className="text-blue-500 font-bold cursor-pointer" onClick={() => handleTradein(record.trade_in_id)}>
                Trade
              </div>
            ) : (
              <div
                className="text-gray-400 cursor-pointer"
                onClick={() => {
                  handleHistoryNotifications(record?.record_id, record?.record_type)
                  setOffer(record)
                  setCPopId(record?.cPopId ?? '')
                  setUseFor('trade')
                  setShowEmailSendingModal(true)
                }}
              >
                Trade
              </div>
            )}
            <Divider type="vertical" className="mt-1" />
            {record.creditAppId !== '' ? (
              <div
                className="text-blue-500 font-bold cursor-pointer"
                onClick={() => handleCreditApp(record.creditAppId)}
              >
                Credit app
              </div>
            ) : (
              <div
                className="text-gray-400 cursor-pointer"
                onClick={() => {
                  handleHistoryNotifications(record?.record_id, record?.record_type)
                  setOffer(record)
                  setCPopId(record?.cPopId ?? '')
                  setUseFor('credit-app')
                  setShowEmailSendingModal(true)
                }}
              >
                Credit app
              </div>
            )}
          </p>
        </div>
      )
    },
    {
      title: 'VEHICLE',
      dataIndex: 'vehicle',
      render: (data, record) => {
        const { frontend_url: vehicleUrl } = record
        const newVehicleUrl = getSingleVehicleUrl(vehicleUrl)
        return record.vehicle_id ? (
          <p
            className="text-blue-500 cursor-pointer"
            onClick={() => window.open(newVehicleUrl, '_blank', 'noopener,noreferrer')}
          >
            {data}
          </p>
        ) : (
          data
        )
      }
    },
    {
      title: 'TEST DRIVE',
      dataIndex: 'testDrive',
      align: 'center',
      width: '5%'
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      onFilter: (value, record) => record?.status?.name === value,
      filters: EZ_DEALZ_STATUS_OPTIONS_FILTER,
      onChange: (value) => handleOnFilterHistoryNotificationStatus(value),
      hidden: !dealershipAccount?.smartboard,
      render: (data, record) => {
        return (
          <div className="font-semibold" style={{ color: `${record?.status?.color}` }}>
            <span
              className={
                record?.status?.name === EZ_DEALZ_STATUS_OPTIONS[2].name
                  ? 'new-inbound-lead-status'
                  : 'smartboard-status'
              }
              style={{ borderColor: `${record?.status?.color}` }}
            />
            {record?.status?.name}
          </div>
        )
      }
    },
    {
      title: 'CREDIT SUBMIT',
      dataIndex: 'creditAppState',
      render: (creditAppState) => <div className="text-center">{creditAppState}</div>,
      align: 'center',
      onFilter: (value, record) => record.creditAppState === value,
      filters: [
        {
          text: 'Yes',
          value: 'Yes'
        },
        {
          text: 'No',
          value: 'No'
        }
      ]
    },
    {
      title: 'FINANCING',
      dataIndex: 'financing_status',
      render: (value, record) => handleFinancingColumn(record),
      align: 'center',
      onFilter: (value, record) => record.financing_status === value,
      filters: [
        {
          text: 'Pending',
          value: 'pending'
        },
        {
          text: 'Approved',
          value: 'approved'
        },
        {
          text: 'Conditional',
          value: 'conditional'
        },
        {
          text: 'Declined',
          value: 'declined'
        }
      ]
    },
    {
      title: 'DEPOSIT',
      dataIndex: 'depositOnDealer',
      onFilter: (value, record) => record.depositOnDealer === value,
      filters: [
        {
          text: 'Yes',
          value: 'Yes'
        },
        {
          text: 'No',
          value: 'No'
        }
      ],
      render: (data, record) => {
        return (
          <Switch
            className="deposit-switch"
            checked={record?.depositOnDealer === 'Yes'}
            checkedChildren={<CheckOutlined style={{ verticalAlign: 'middle', paddingBottom: '2px' }} />}
            onChange={(checked) => handleDepositChange(checked ? 'Yes' : 'No', record)}
          />
        )
      },
      align: 'center'
    },
    {
      title: (
        <div className="flex items-center gap-1">
          <span>ACCEPTED</span>
          <Tooltip
            className="c-pop-tooltip ml-1 mr-1"
            title="Accepted: This offer has been accepted by the dealership and is pending delivery."
            placement="bottomLeft"
          >
            <BsInfoCircle size={15} color="blue" />
          </Tooltip>
        </div>
      ),
      dataIndex: 'accpt',
      onFilter: (value, record) => record.accpt === value,
      filters: [
        {
          text: 'Yes',
          value: 'Yes'
        },
        {
          text: 'No',
          value: 'No'
        }
      ],
      render: (data, record) => {
        return (
          <Switch
            className="accepted-switch"
            checked={record?.accpt === 'Yes'}
            checkedChildren={<CheckOutlined style={{ verticalAlign: 'middle', paddingBottom: '2px' }} />}
            onChange={(checked) => handleAcceptedDelivered('accepted', checked ? 'Yes' : 'No', record)}
          />
        )
      },
      align: 'center',
      width: '5%'
    },
    {
      title: (
        <div className="flex items-center gap-1">
          <span>DELIVERED</span>
          <Tooltip
            className="c-pop-tooltip ml-1 mr-1"
            title="Delivered: This unit has been delivered to the customer, completing the transaction."
            placement="bottomLeft"
          >
            <BsInfoCircle size={15} color="blue" />
          </Tooltip>
        </div>
      ),
      dataIndex: 'deliv',
      onFilter: (value, record) => record.deliv === value,
      filters: [
        {
          text: 'Yes',
          value: 'Yes'
        },
        {
          text: 'No',
          value: 'No'
        }
      ],
      render: (data, record) => {
        return (
          <Switch
            className="delivered-switch"
            checked={record?.deliv === 'Yes'}
            checkedChildren={<CheckOutlined style={{ verticalAlign: 'middle', paddingBottom: '2px' }} />}
            onChange={(checked) => handleAcceptedDelivered('delivered', checked ? 'Yes' : 'No', record)}
          />
        )
      },
      align: 'center',
      width: '5%'
    },
    {
      title: 'C-POP',
      dataIndex: 'cPopState',
      onFilter: (value, record) => record.cPopState === value,
      filters: [
        {
          text: 'Yes',
          value: 'Yes'
        },
        {
          text: 'No',
          value: 'No'
        }
      ],
      render: (text, record, index) => (
        <p
          onClick={() => handleClick(record.key)}
          key={index}
          className="font-bold flex items-center justify-end gap-1 c-pop text-blue-500 cursor-pointer"
        >
          <span style={{ color: getCpopColor(record) }}>
            {!record.cPopId ? 'No' : ''} C-POP {record.visits > 0 && record.cPopId ? ' interacted' : ''}
          </span>

          <FaAngleRight size={14} />
        </p>
      ),
      align: 'center',
      width: '7%'
    }
  ].filter((item) => !item.hidden)

  useEffect(() => {
    fetchData({
      pagination
    })
  }, [dealershipAccount, refetchInventory, refresh])

  useEffect(() => {
    if (initialData && initialData.length > 0) {
      const records = [...initialData]
      records.map((record) => {
        if (typeof record.status === 'undefined') {
          record.status = {
            key: null,
            name: null,
            color: null,
            text: null
          }
        }
        setEzDealStatus(record)
        return record
      })
    }
  }, [initialData])

  useEffect(() => {
    if (searchText !== '') {
      const filteredData = data.find(
        (o) =>
          o.customer.includes(searchText) ||
          o.vehicle.includes(searchText) ||
          o.salesPersons.includes(searchText) ||
          o.phone.includes(searchText) ||
          o.approval.includes(searchText)
      )
      if (filteredData) {
        setInitialData([filteredData])
      } else {
        setInitialData([])
      }
    } else {
      setInitialData(data)
    }
  }, [searchText])

  const handleTableChange = (newPagination, filters, sorter) => {
    setSortedInfo(sorter)
    let sorts = []
    if (Array.isArray(sorter)) {
      sorts = sorter.map((item) => [item.field, item.order])
    } else if (sorter.order && sorter.field) {
      sorts.push([sorter.field, sorter.order])
    } else {
      // sorts.push(['created_at', 'desc'])
    }
    fetchData({
      sorts,
      pagination: newPagination,
      history_notification_status: historyNotificationStatus,
      ...filters
    })
  }

  const handleBulkAction = (name, status) => {
    const params = []
    params[name] = status

    if (selectedRows?.length > 0) {
      dealerService
        .updateDealerOffers({ offers: selectedRowKeys, ...params })
        .then(() => {
          setSelectedSalesPeople(defaultSalesPeople)
          fetchData()
          openNotification({ message: 'Success' })
        })
        .catch((e) => {
          openNotification({ message: e.message, type: 'error' })
        })
    } else {
      openNotification({
        message: 'You need to select at least one lead. Please try again!',
        type: 'error'
      })
    }
  }

  const handleCallback = () => {
    setRefresh(true)
  }

  // Show only Salespeople Bulk action, keep the other just in case
  // const hideOnProduction = process.env.REACT_APP_HIDE_ON_PRODUCTION === 'true'
  //
  // let filterOptions = [
  //   {
  //     name: 'employee_id',
  //     label: 'Salespeople',
  //     options: salesPeople
  //   },
  //   {
  //     name: 'is_credit',
  //     label: 'Credit Submit',
  //     options: DEFAULT_OPTIONS
  //   },
  //   {
  //     name: 'accepted',
  //     label: 'Accepted',
  //     options: DEFAULT_OPTIONS
  //   },
  //   {
  //     name: 'delivered',
  //     label: 'Delivered',
  //     options: DEFAULT_OPTIONS
  //   }
  // ]
  //
  // if (hideOnProduction) {
  //   let filterOptions = [
  //     {
  //       name: 'employee_id',
  //       label: 'Salespeople',
  //       options: salesPeople
  //     }
  //   ]
  // }

  const filterOptions = [
    {
      name: 'employee_id',
      label: 'Bulk Reassignment by Salespeople',
      options: salesPeople
    }
  ]

  return (
    <>
      <div className="ez-dealz-sent">
        <div className="items-center w-12/12 py-4 lg:py-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          {filterOptions.map((filters) => {
            return (
              <div key={filters.label} className="flex items-center w-full flex-wrap">
                <p className="pr-4 text-gray-400 min-w-max">{filters.label}</p>
                <Select
                  value={selectedSalesPeople.value}
                  size="large"
                  style={{ width: '100%' }}
                  onChange={(e) => handleBulkAction(filters.name, e)}
                >
                  {filters.options.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </div>
            )
          })}
        </div>
        <Table
          className="text-center mt-5"
          columns={columns}
          dataSource={initialData}
          onChange={handleTableChange}
          pagination={{ position: ['bottomCenter', 'bottomCenter'], pagination }}
          loading={loading}
          rowSelection={rowSelection}
          onRow={(row) => {
            return {
              onClick: () => {
                // setSelectedRowKeys(row)
                setSelectedRows(row)
                setNewRow(row)
              }
            }
          }}
        />
      </div>
      <CustomerModal
        showModal={showCustomerModal}
        handleCloseModal={handleCustomerModal}
        offer={offer}
        dealershipAccount={dealershipAccount}
        handleCallback={handleCallback}
        fetchData={fetchData}
      />
      <Modal
        centered
        visible={showFinancingModal}
        title={rowFinancingModalTitle}
        onOk={handleFinancingModal}
        onCancel={() => {
          handleFinancingModal()
          setRowFinancingModalTitle('')
          setRowFinancingStatus('')
          setFinancingRow({})
        }}
        okText="Close"
        className="calendar-modal financing-status-modal rounded-3xl"
        key="financing-status-modal"
        destroyOnClose
        width={400}
        footer={[
          <div className="pb-3 pr-3">
            <button
              type="button"
              className="bg-primary hover:bg-activePrimary font-semibold w-36 rounded-md px-2 py-3 outline-none text-md text-white uppercase"
              onClick={handleFinancingSubmission}
              disabled={financingStatusSubmitState}
            >
              Submit
            </button>
          </div>
        ]}
      >
        <Form layout="vertical" ref={formRef} name="financing-status-ref">
          <div>
            <div className="flex grid grid-cols-12 pb-4">
              <div className="col-span-12">
                <label className="form-label flex items-center gap-2" htmlFor="financing_status">
                  <span className="w-36 financing-status-label">Financing Status:</span>
                  <Form.Item
                    name="financing_status"
                    rules={[{ required: true, message: 'Financing Status is required' }]}
                    className="custom-antd-label b"
                    initialValue="pending"
                  >
                    <Select
                      className="w-48 rounded-xl"
                      value={rowFinancingStatus}
                      onChange={handleFinancingStatusChange}
                    >
                      {financingStatusOptions.map((option) => (
                        <Option key={option} value={option}>
                          {capitalizeFirstLetter(option)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </label>
              </div>
            </div>

            {(rowFinancingStatus === 'approved' || rowFinancingStatus === 'conditional' && calculationRecord.length > 0) && (
              <>
                <div className="flex items-center gap-7 pb-4">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="form-label flex items-center gap-1" htmlFor="financing_apr">
                    <span className="w-16 financing-label">APR:</span>
                    <Form.Item
                      name="financing_apr"
                      rules={[
                        { required: true, message: 'APR is required' },
                        ({ getFieldValue }) => ({
                          validator() {
                            if (getFieldValue('financing_apr') < 0 || getFieldValue('financing_apr') > 100) {
                              return Promise.reject(new Error('APR value must be within 0 to 100'))
                            }
                            return Promise.resolve()
                          }
                        })
                      ]}
                      className="custom-antd-label flex"
                      initialValue={calculationRecord?.aprPercentageLabel || 0}
                    >
                      <Input type="number" name="financing_apr" className="financing-field w-20" suffix="%" />
                    </Form.Item>
                  </label>

                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="form-label flex items-center justify-end gap-2" htmlFor="financing_max_term">
                    <span className="w-20 financing-label">Max term:</span>
                    <Form.Item
                      name="financing_max_term"
                      rules={[
                        { required: true, message: 'Max term is required' },
                        ({ getFieldValue }) => ({
                          validator() {
                            if (getFieldValue('financing_max_term') < 0 || getFieldValue('financing_max_term') > 100) {
                              return Promise.reject(new Error('Max term value must be within 0 to 100'))
                            }
                            return Promise.resolve()
                          }
                        })
                      ]}
                      className="custom-antd-label flex"
                      initialValue={calculationRecord?.term || 0}
                    >
                      <Input
                        type="number"
                        name="financing_max_term"
                        className="financing-field w-20"
                        placeholder=""
                        suffix="mo"
                      />
                    </Form.Item>
                  </label>
                </div>

                <div className="flex items-center pb-4">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="form-label flex items-center gap-7" htmlFor="financing_min_cash_down">
                    <span className="w-46 financing-label flex justify-start">Min Cash Down Payment:</span>
                    <Form.Item
                      name="financing_min_cash_down"
                      rules={[
                        {
                          required: true,
                          message: 'Min Cash Down Payment is required'
                        },
                        ({ getFieldValue }) => ({
                          validator() {
                            if (getFieldValue('financing_min_cash_down') < 0) {
                              return Promise.reject(new Error('No negative value for Min Cash Down Payment'))
                            }
                            return Promise.resolve()
                          }
                        })
                      ]}
                      className="custom-antd-label flex"
                      initialValue={calculationRecord?.cashDown || 0}
                    >
                      <Input
                        type="number"
                        name="financing_min_cash_down"
                        className="financing-field w-40 flex flex-end"
                        placeholder=""
                        prefix="$"
                      />
                    </Form.Item>
                  </label>
                </div>

                <div className="flex items-center gap-10 pb-4">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="form-label flex items-center gap-2" htmlFor="financing_ltv">
                    <div className="w-16 flex items-center">
                      <span className="financing-label">LTV</span>
                      <Tooltip className="c-pop-tooltip ml-1 mr-1" title="Loan-to-Value" placement="bottomLeft">
                        <BsFillInfoCircleFill size={15} color="gray" />
                      </Tooltip>
                      :
                    </div>
                    <Form.Item
                      name="financing_ltv"
                      rules={[
                        { required: true, message: 'LTV is required' },
                        ({ getFieldValue }) => ({
                          validator() {
                            if (getFieldValue('financing_ltv') < 0 || getFieldValue('financing_ltv') > 100) {
                              return Promise.reject(new Error('LTV value must be within 0 to 100'))
                            }
                            return Promise.resolve()
                          }
                        })
                      ]}
                      className="custom-antd-label flex"
                      initialValue={0}
                    >
                      <Input type="number" name="financing_ltv" className="financing-field w-20" suffix="%" />
                    </Form.Item>
                  </label>

                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="form-label flex items-center justify-end gap-2" htmlFor="financing_pti">
                    <div className="w-16 flex items-center">
                      <span className="financing-label">PTI</span>
                      <Tooltip className="c-pop-tooltip ml-1 mr-1" title="Payment-to-Income" placement="bottomLeft">
                        <BsFillInfoCircleFill size={15} color="gray" />
                      </Tooltip>
                      :
                    </div>
                    <Form.Item
                      name="financing_pti"
                      rules={[
                        { required: true, message: 'PTI is required' },
                        ({ getFieldValue }) => ({
                          validator() {
                            if (getFieldValue('financing_pti') < 0 || getFieldValue('financing_pti') > 100) {
                              return Promise.reject(new Error('PTI value must be within 0 to 100'))
                            }
                            return Promise.resolve()
                          }
                        })
                      ]}
                      className="custom-antd-label flex"
                      initialValue={0}
                    >
                      <Input type="number" name="financing_pti" className="financing-field w-20" suffix="%" />
                    </Form.Item>
                  </label>
                </div>

                <div className="flex grid grid-cols-12">
                  <div className="col-span-6">
                    <span className="py-1 text-sm flex items-center">Deposit:</span>
                  </div>
                  <div className="col-span-6">
                    <Form.Item name="financing_deposit" className="custom-antd-label flex">
                      <Radio.Group className="flex justify-end">
                        <Space direction="horizontal">
                          <Radio key="financing-deposit-yes" value="1" className="p-2">
                            Yes
                          </Radio>
                          <Radio key="financing-deposit-no" value="0" className="p-2">
                            No
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
          </div>
        </Form>
      </Modal>
      <Modal
        centered
        title=""
        visible={showFinancingConfirmation}
        onCancel={handleFinancingConfirmation}
        closable={false}
        okText="Close"
        className=""
        width={350}
        destroyOnClose
        footer={[
          <div className="flex justify-end">
            <Button
              type="button"
              className="bg-primary hover:bg-activePrimary hover:text-white font-semibold rounded-lg outline-none h-full text-white justify-center uppercase"
              onClick={() => handleFinancingConfirmSubmit()}
            >
              CONFIRM
            </Button>
            <Button
              type="button"
              className="hover:bg-activePrimary font-semibold rounded-lg outline-none w-36 h-full text-black hover:text-white justify-center uppercase"
              onClick={handleFinancingConfirmCancel}
            >
              CANCEL
            </Button>
          </div>
        ]}
      >
        <div className="pb-2 flex gap-3 lg:gap-5 justify-center rounded-3xl">
          <div className="font-semibold text-md text-center">Please confirm this financing status update</div>
        </div>
      </Modal>
      <Modal
        centered
        title={financingTermsTitle}
        visible={showFinancingTermsModal}
        onCancel={() => {
          handleFinancingTermsModal()
          setOfferFinancingData({})
          setFinancingTermsTitle('')
        }}
        okText="Close"
        className="calendar-modal financing-status-modal financing-terms rounded-3xl"
        width={300}
        destroyOnClose
        footer={[
          <div className="pb-3 pr-3">
            <button
              type="button"
              className="bg-primary hover:bg-activePrimary font-semibold w-20 rounded-md py-2 outline-none text-md text-white uppercase"
              onClick={() => {
                handleFinancingTermsModal()
                setOfferFinancingData({})
                setFinancingTermsTitle('')
              }}
            >
              Close
            </button>
          </div>
        ]}
      >
        <div>
          <div className="flex justify-center grid grid-cols-12 pb-2">
            <div className="col-span-6 flex items-center justify-start">
              <span className="field-name">APR:</span>
            </div>
            <div className="col-span-6 flex items-center justify-end">
              <span className="field-value">{maskNumberThousands(offerFinancingData?.apr) ?? 0} %</span>
            </div>
          </div>

          <div className="flex justify-center grid grid-cols-12 pb-2">
            <div className="col-span-6 flex items-center justify-start">
              <span className="field-name">Max Term:</span>
            </div>
            <div className="col-span-6 flex items-center justify-end">
              <span className="field-value">{offerFinancingData?.max_term ?? 0} mo</span>
            </div>
          </div>

          <div className="flex justify-center grid grid-cols-12 pb-2">
            <div className="col-span-8 flex items-center justify-start">
              <span className="field-name">Min Cash Down Payment:</span>
            </div>
            <div className="col-span-4 flex items-center justify-end">
              <span className="field-value">$ {maskNumberThousands(offerFinancingData?.min_cash_down) ?? 0}</span>
            </div>
          </div>

          <div className="flex justify-center grid grid-cols-12 pb-2">
            <div className="col-span-6 flex items-center justify-start">
              <span className="field-name">LTV</span>
              <Tooltip className="c-pop-tooltip ml-1 mr-1" title="Loan-to-Value" placement="bottomLeft">
                <BsFillInfoCircleFill size={12} color="gray" />
              </Tooltip>
              :
            </div>
            <div className="col-span-6 flex items-center justify-end">
              <span className="field-value">{maskNumberThousands(offerFinancingData?.ltv) ?? 0} %</span>
            </div>
          </div>

          <div className="flex justify-center grid grid-cols-12 pb-2">
            <div className="col-span-6 flex items-center justify-start">
              <span className="field-name">PTI</span>
              <Tooltip className="c-pop-tooltip ml-1 mr-1" title="Payment-to-Income" placement="bottomLeft">
                <BsFillInfoCircleFill size={12} color="gray" />
              </Tooltip>
              :
            </div>
            <div className="col-span-6 flex items-center justify-end">
              <span className="field-value">{maskNumberThousands(offerFinancingData?.pti) ?? 0} %</span>
            </div>
          </div>

          <div className="flex justify-center grid grid-cols-12">
            <div className="col-span-6 flex items-center justify-start">
              <span className="field-name">Deposit:</span>
            </div>
            <div className="col-span-6 flex items-center justify-end">
              <span className="field-value">{offerFinancingData?.deposit ? 'Yes' : 'No'}</span>
            </div>
          </div>
        </div>
      </Modal>
      <TradeDetailModal
        key="trade-detail-modal-from-dashboard"
        showModal={showTradeInfoModal}
        handleCloseModal={handleTradeInfoModal}
        trade={selectedTrade}
      />
      <SendEmailModal
        offer={offer}
        key="send-email-modal-from-dashboard"
        showModal={showEmailSendingModal}
        handleCloseModal={handleEmailSendingModal}
        dealershipAccount={dealershipAccount}
        handleCallback={handleCallback}
        fetchData={fetchData}
        cPopId={cPopId}
        notifications={notifications}
        handleHistoryNotifications={handleHistoryNotifications}
        useFor={useFor}
      />
      <CreditAppDetailModal
        key="credit-app-modal-from-dashboard"
        showModal={showCreditAppInfoModal}
        handleCloseModal={handleCreditAppInfoModal}
        creditAppDetail={selectedCreditApp}
      />
    </>
  )
}

EzDealzSent.propTypes = {
  searchText: PropTypes.string.isRequired,
  dealershipAccount: PropTypes.object,
  refetchInventory: PropTypes.bool
}
