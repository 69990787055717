/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { CloseOutlined } from '@ant-design/icons'
import { Button, Modal, Select, Table } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import VehicleFinanceMobileModal from 'src/components/Vehicle/VehicleFinanceMobileModal'
import VehicleFinanceModal from 'src/components/Vehicle/VehicleFinanceModal'
import * as CustomerServices from 'src/services/customer.service'
import './style.scss'

const options = [
  {
    label: 'Date',
    value: 'date_of_offer'
  },
  {
    label: 'Dealership',
    value: 'dealership'
  },
  {
    label: 'Year',
    value: 'year'
  },
  {
    label: 'Make',
    value: 'make'
  },
  {
    label: 'Model',
    value: 'model'
  },
  {
    label: 'Body Type',
    value: 'body'
  },
  {
    label: 'Sale price',
    value: 'price'
  },
  {
    label: 'Financing',
    value: 'financing'
  }
]

const getCustomStyles = () => ({
  option: (provided, state) => ({
    ...provided,
    border: '1px solid #f2f0f0',
    borderRadius: '5%',
    color: state.isSelected ? 'white' : 'blue'
  }),
  control: (provided, state) => ({
    ...provided,
    border: '1px solid rgb(140, 139, 139)',
    borderRadius: '5%',
    backgroundColor: state.isDisabled ? '#dfdfdf' : '#ffffff'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms'
  })
})

export function SavedOffers({ searchText, isSavedOffersPage }) {
  const { customerLogin } = useAuthorizationContext()
  const isMobile = window.innerWidth <= 1024
  const [initStep, setInitStep] = useState(-1)
  const customStyles = getCustomStyles()
  const [initialData, setInitialData] = useState([])
  const [calculation, setCalculation] = useState({})
  const [sortBy, setSortBy] = useState(options[4].value)
  const previousOfferRef = useRef([])
  const navigate = useNavigate()
  const [selectedVehicle, setSelectedVehicle] = useState({})
  const [isCalculateTaxesModalVisible, setIsCalculateTaxesModalVisible] = useState(false)
  const handleNavigateSavedOffers = () => {
    navigate('/saved-offers')
  }

  const handleOpenBuildYourDeal = (e, record) => {
    e.stopPropagation()
    setIsCalculateTaxesModalVisible(true)
    setSelectedVehicle(record.vehicle)
    setCalculation(record.calculation)
  }

  const columns = useMemo(
    () => [
      {
        title: 'DATE OF OFFER',
        dataIndex: 'date'
      },
      {
        title: 'DEALER',
        dataIndex: 'dealership'
      },
      {
        title: 'VEHICLE',
        dataIndex: 'vehicleName',
        render: (vehicle, record) => (
          <div className="underline cursor-pointer" onClick={(e) => handleOpenBuildYourDeal(e, record)}>
            {vehicle}
          </div>
        )
      },
      {
        title: 'SALE PRICE',
        dataIndex: ['vehicle', 'price'],
        render: (price) => (price ? `$${price.toLocaleString()}` : '_')
      },
      {
        title: 'FINANCING',
        dataIndex: 'financing'
      },
      {
        title: 'STATUS',
        dataIndex: 'status'
      },
      {
        title: 'Has C-POP',
        key: 'has_c_pop',
        render: (data) => {
          if (data?.hasCPopUrl && data?.cPopUrl) {
            return <a href={data.cPopUrl}>C-POP</a>
          }
          return ''
        }
      }
    ],
    [handleOpenBuildYourDeal]
  )

  const handleChangeSortBy = (value) => {
    setSortBy(value)
  }

  useEffect(() => {
    if (customerLogin) {
      CustomerServices.getCustomerOffers({ keyword: searchText || undefined, sort: sortBy })
        .then((response) => {
          const responseData = response.data
          const data = responseData
            ?.filter((e) => e.vehicle)
            ?.map((item) => {
              const { vehicleName, dealerName, date, vehicle, finance } = item
              const { frequency } = finance
              return {
                ...item,
                date,
                vehicleName,
                financing: frequency,
                dealership: dealerName,
                status: item.status === 'pending' ? 'Pending confirmation from Dealer' : '',
                body: vehicle?.body
              }
            })
          data.calculation = responseData.calculation
          setInitialData(data)
          previousOfferRef.current = data
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [customerLogin, sortBy, searchText])

  // useEffect(() => {
  //   if (searchText !== '') {
  //     const filteredData = previousOfferRef.current.filter(
  //       (o) => o.vehicle_name?.includes(searchText) || o.first_name?.includes(searchText)
  //     )
  //     if (filteredData) {
  //       setInitialData(filteredData)
  //     } else {
  //       setInitialData([])
  //     }
  //   } else {
  //     setInitialData(previousOfferRef.current)
  //   }
  // }, [searchText])

  const handleCancel = () => {
    setIsCalculateTaxesModalVisible(false)
  }

  const vehicleFinanceModalHeader = (
    <>
      <div className="lg:flex lg:items-end">
        <p className="text-black font-bold text-2xl mr-1">Build your deal</p>
        <p className="text-xl font-medium">Car-buying made fast, easy, transparent.</p>
      </div>
      <Button
        shape="circle"
        icon={<CloseOutlined />}
        className="absolute right-4 top-3 -translate-y-2/4 bg-whiteTransparent2 z-30"
        onClick={handleCancel}
      />
      <div className="absolute bottom-0 right-12 px-4 py-0 sm:mt-10 bg-darkWhite border-t border-l border-r how-border">
        <p className="text-xs text-primary">How it works</p>
      </div>
    </>
  )

  return (
    <div>
      <div className="flex lg:flex-row flex-col">
        <div className="font-semibold text-xl p-5">Saved offers</div>
        <div className="flex lg:flex-row lg:items-center flex-col items-start gap-4 xl:pr-7 md:pr-5 sm:pr-4">
          <div className="lg:ml-5 flex items-center gap-3 col-span-2 justify-end">
            <span className="select-sortby-dashboard text-xl">Sort by: </span>
            <Select
              name="sortBy"
              placeholder="Sort by"
              value={sortBy}
              onChange={handleChangeSortBy}
              styles={customStyles}
              options={options}
              className="h-full w-32"
            />
          </div>
          {!isSavedOffersPage && (
            <div className="text-primary cursor-pointer font-bold" onClick={handleNavigateSavedOffers}>
              Saved offers &nbsp; &#62;
            </div>
          )}
        </div>
      </div>
      <div className="w-screen lg:w-full">
        <Table scroll={{ x: 900 }} columns={columns} dataSource={initialData} />
      </div>
      <Modal
        title={vehicleFinanceModalHeader}
        visible={isCalculateTaxesModalVisible}
        footer={null}
        style={{ top: 40 }}
        onCancel={handleCancel}
        width="95%"
        closable={false}
        destroyOnClose
        wrapClassName="vehicle-finance-modal"
      >
        <div className={!isMobile ? 'hidden' : ''}>
          <VehicleFinanceMobileModal
            info={selectedVehicle}
            initStep={initStep}
            setInitStep={setInitStep}
            calculation={calculation}
            cashDown={0}
          />
        </div>
        <div className={isMobile ? 'hidden' : ''}>
          <VehicleFinanceModal
            info={selectedVehicle}
            initStep={initStep}
            setInitStep={setInitStep}
            calculation={calculation}
            cashDown={0}
          />
        </div>
      </Modal>
    </div>
  )
}

SavedOffers.propTypes = {
  searchText: PropTypes.string.isRequired,
  isSavedOffersPage: PropTypes.bool
}
