import * as PropTypes from 'prop-types'
import React, { createContext, useContext, useMemo, useState } from 'react'

const EzDealzWalletContext = createContext({})

export function EzDealzWalletProvider({ children }) {
  const [showWalletModal, setShowWalletModal] = useState(false)

  const walletState = useMemo(
    () => ({
      showWalletModal,
      setShowWalletModal
    }),
    [showWalletModal, setShowWalletModal]
  )
  return <EzDealzWalletContext.Provider value={walletState}>{children}</EzDealzWalletContext.Provider>
}

EzDealzWalletProvider.propTypes = {
  children: PropTypes.node
}

export function useEzDealzWalletContext() {
  return useContext(EzDealzWalletContext)
}
