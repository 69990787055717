import { notification } from 'antd'
import { Field, Form, Formik } from 'formik'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import ReactInputMask from 'react-input-mask'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import * as CustomerServices from 'src/services/customer.service'
import * as Yup from 'yup'

function PhoneComponent(props) {
  return (
    <ReactInputMask
      {...props}
      mask="999-999-9999"
      placeholder="e.g. 456-789-0123"
      className="w-full border border-black rounded outline-none py-2 px-3"
      type="text"
    />
  )
}

function ScheduleDriveStep2({
  resetStep,
  nextScheduleStep,
  time,
  selectedData,
  scheduleTestDriveData,
  vehicleId,
  setScheduleTestDriveData
}) {
  const { customerAccount, customerLogin } = useAuthorizationContext()

  const [initialValues, setInitialValues] = useState({
    firstName: scheduleTestDriveData.first_name || '',
    lastName: scheduleTestDriveData.last_name || '',
    email: scheduleTestDriveData.email || '',
    phone: scheduleTestDriveData.phone || '',
    comments: scheduleTestDriveData.comments || ''
  })
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Please enter a valid first name.'),
    lastName: Yup.string().required('Please enter a valid last name.'),
    email: Yup.string().email('Please enter a valid email address').required('Please enter a email address'),
    phone: Yup.string().matches(/(\d{3}[-]?\d{3}[-]?\d{4})|(___-___-____)/, 'Phone number is not valid')
  })

  const handleSubmit = (values) => {
    CustomerServices.handleSaveLeadTestDrive({
      ...values,
      time,
      vehicleId,
      step: 2,
      date: moment(selectedData).format('YYYY-MM-DD')
    })
      .then((response) => {
        setScheduleTestDriveData(response?.data)

        notification.success({
          message: 'Success!',
          description: 'Emailed about a scheduled test drive!'
        })

        nextScheduleStep()
      })
      .catch(() => {
        notification.error({
          message: 'Error!',
          description: 'Cannot send an email about scheduled test drive. Please try again!'
        })
      })
  }

  useEffect(() => {
    if (customerLogin && customerAccount) {
      setInitialValues({
        firstName: customerAccount?.first_name,
        lastName: customerAccount?.last_name,
        email: customerAccount?.email,
        phone: customerAccount?.phone,
        comments: ''
      })
    }
  }, [customerLogin, customerAccount])

  return (
    <div className="rounded-xl p-6 bg-white shadow">
      <p className="text-2xl text-black font-bold">Let&apos;s Schedule Your Test Drive</p>
      <p className="text-gray-400 text-base mb-3">
        Tell us a little information to get in touch and confirm your appointment.
      </p>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, handleBlur, handleChange, errors, touched, values, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-5 mb-5">
              <div>
                <p className="text-black text-sm mb-2">First Name</p>
                <Field
                  name="firstName"
                  type="text"
                  className={`w-full border border-black rounded outline-none py-2 px-3 ${
                    errors.firstName && touched.firstName ? 'border-danger focus:border-danger' : ''
                  }`}
                  placeholder="e.g. John"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.firstName && touched.firstName && (
                  <p className="text-danger text-sm">
                    <span className="text-xl font-semibold leading-3">×</span> {errors.firstName}
                  </p>
                )}
              </div>
              <div>
                <p className="text-black text-sm mb-2">Last Name</p>
                <Field
                  name="lastName"
                  type="text"
                  className={`w-full border border-black rounded outline-none py-2 px-3 ${
                    errors.lastName && touched.lastName ? 'border-danger focus:border-danger' : ''
                  }`}
                  placeholder="e.g. Smith"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.lastName && touched.lastName && (
                  <p className="text-danger text-sm">
                    <span className="text-xl font-semibold leading-3">×</span> {errors.lastName}
                  </p>
                )}
              </div>
              <div>
                <p className="text-black text-sm mb-2">Email</p>
                <Field
                  name="email"
                  type="email"
                  className={`w-full border border-black rounded outline-none py-2 px-3 ${
                    errors.email && touched.email ? 'border-danger focus:border-danger' : ''
                  }`}
                  placeholder="e.g. JSmith@gmail.com"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email && (
                  <p className="text-danger text-sm">
                    <span className="text-xl font-semibold leading-3">×</span> {errors.email}
                  </p>
                )}
              </div>
              <div>
                <p className="text-black text-sm mb-2">
                  Phone <span className="text-lightGray">(Optional)</span>
                </p>
                <Field
                  name="phone"
                  type="text"
                  className="w-full border border-black rounded outline-none py-2 px-3"
                  placeholder="e.g. 456-789-0123"
                  as={PhoneComponent}
                />
                {errors.phone && touched.phone && (
                  <p className="text-danger text-sm">
                    <span className="text-xl font-semibold leading-3">×</span> {errors.phone}
                  </p>
                )}
              </div>
              <div className="col-span-2">
                <p className="text-black text-sm mb-2">
                  Comments <span className="text-lightGray">(Optional)</span>
                </p>
                <textarea
                  className="border border-black rounded outline-none p-3 w-full resize-none"
                  rows="4"
                  onChange={(e) => setFieldValue('comments', e.target.value)}
                  value={values.comments}
                  maxLength={500}
                />
                <div className="flex justify-end">
                  <p className="text-xs text-darkGray">{500 - values.comments.length} characters remaining</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center mt-8">
              <button type="submit" className="bg-primary rounded text-white px-16 py-2 hover:bg-activePrimary">
                Continue
              </button>
              <button
                type="button"
                className="text-primary hover:underline text-base cursor-pointer mt-4"
                onClick={resetStep}
              >
                Back To My Deal
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

ScheduleDriveStep2.propTypes = {
  resetStep: PropTypes.func.isRequired,
  nextScheduleStep: PropTypes.func.isRequired,
  time: PropTypes.any,
  selectedData: PropTypes.any,
  scheduleTestDriveData: PropTypes.object,
  vehicleId: PropTypes.string,
  setScheduleTestDriveData: PropTypes.func
}

export default ScheduleDriveStep2
