import { Form, Input, Radio } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import ReactInputMask from 'react-input-mask'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import AuthPopup from 'src/components/Vehicle/BoxButtonGroup/AuthPopup'
import { DEFAULT_OPTIONS, STORAGE_KEYS } from 'src/constants/constants'
import { load, save } from 'src/lib/storage'
import { maskNumberThousands } from 'src/utils'
import kelleyImage from '../../../assets/kbb-logo.png'
import LoginFormModal from '../../Vehicle/BoxButtonGroup/LoginFormModal'
import LoginPopup from '../../Vehicle/BoxButtonGroup/LoginPopup'
import SignUpFormModal from '../../Vehicle/BoxButtonGroup/SignUpFormModal'
import MyTradeInModal from '../TradeInModal'
import './style.scss'

const tradeInForm = [
  {
    label: 'Trade-In Value',
    name: 'tradeIn',
    required: true,
    type: 'number'
  },
  {
    label: 'Still making payment on your car?',
    name: 'makingPayment',
    required: true,
    type: 'radio',
    options: DEFAULT_OPTIONS
  }
]

const makingPayment = [
  {
    label: 'Amount Owed',
    name: 'amountOwed',
    required: true,
    type: 'number',
    placeholder: '$0'
  },
  {
    label: 'Making payments to?',
    name: 'makingPayment',
    required: true,
    type: 'text'
  },
  {
    label: 'My account number',
    name: 'accountNumber',
    required: true,
    type: 'number'
  },
  {
    label: 'Phone number I call for help with my payments',
    name: 'phoneNumber',
    required: true,
    type: 'tel'
  }
]

function TradeIn({ selectedCard, setMyWalletView, setStack }) {
  const { customerLogin } = useAuthorizationContext()
  const previousValue = load(STORAGE_KEYS.EZ_DEALZ_BUDGET) || {}
  const [form] = Form.useForm()
  const [, forceUpdate] = useState({})
  const [tradeIn, setTradeIn] = useState(previousValue.tradeIn || 0)
  const [makePayment, setMakePayment] = useState('Yes')
  const [modalVisible, setModalVisible] = useState(false)
  const [showPopupConfirm, setShowPopupConfirm] = useState(false)
  const [, setLoginRequired] = useState(false)
  const [show, setShow] = useState(false)
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [showSignUpForm, setShowSignUpForm] = useState(false)
  const [, setShowCashDownPopupConfirm] = useState(false)
  let selectedData = previousValue?.selectedData || undefined

  useEffect(() => {
    setMakePayment(previousValue.makingPayment)
    form.setFieldsValue(previousValue)
    forceUpdate({})
  }, [])

  const handleFormSubmission = () => {
    const value = form.getFieldsValue()
    const tradeIn = form.getFieldValue('tradeIn')
    if (tradeIn === 0) {
      selectedData = undefined
    }
    save(STORAGE_KEYS.EZ_DEALZ_BUDGET, JSON.stringify({ ...previousValue, ...value, selectedData }))
    setTradeIn(tradeIn)
  }
  const handleShowLoginForm = () => {
    setShowPopupConfirm(false)
    setShowLoginForm(true)
  }

  const handleShowSignUpForm = () => {
    setShowPopupConfirm(false)
    setShowSignUpForm(true)
  }

  const callbackForForm = () => {
    setShowLoginForm(false)
  }

  const handleShowModal = () => (customerLogin ? setModalVisible(true) : setShowPopupConfirm(true))

  const handleCloseModal = () => {
    setModalVisible(false)
  }

  const handleAddTradeIn = (record) => {
    setTradeIn(record.vehicle.estimatedvalue)
    const newValue = { ...previousValue, tradeIn: record.vehicle.estimatedvalue }
    form.setFieldsValue(newValue)
    handleFormSubmission()
    setModalVisible(false)
  }

  const onChangeTradeValue = (value) => {
    if (value === '') {
      const newValue = { ...previousValue, tradeIn: 0 }
      form.setFieldsValue(newValue)
      handleFormSubmission()
    }
  }

  const renderForm = (form, index) => {
    if (form.type === 'radio') {
      return (
        <Radio.Group onChange={(e) => setMakePayment(e.target.value)} value={makePayment}>
          {form.options.map((option) => (
            <Radio value={option.value}>{option.name}</Radio>
          ))}
        </Radio.Group>
      )
    }
    if (form.name === 'phoneNumber') {
      return (
        <ReactInputMask
          mask="999-999-9999"
          placeholder="NXX-NXX-XXXX"
          className="w-full p-4 py-3 box-border rounded-xl border-gray-300 border focus:outline-none"
          type="text"
        />
      )
    }
    return (
      <Input
        name={form.name}
        placeholder={form.placeholder || form.label}
        autoFocus={index === 0}
        className="w-full p-4 py-3 box-border rounded-xl border-gray-300 border focus:outline-none"
        type={form.type}
        onWheel={(e) => e.target.blur()}
        allowClear
        onChange={(e) => onChangeTradeValue(e.target.value)}
      />
    )
  }

  const validation = (form) => {
    const patternMessage = {
      pattern: /[a-zA-Z]/g,
      message: 'Only string accepted'
    }
    switch (form.name) {
      case 'phoneNumber':
        patternMessage.pattern = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/
        patternMessage.message = 'Invalid phone number format'
        return patternMessage
      default:
        return {}
    }
  }

  const goToTradeInStep = () => {
    setMyWalletView(5)
    setStack((stack) => [...stack, 4])
  }

  return (
    <div className="p-6 px-8 w-full bg-white text-black">
      <p className="text-xl font-bold">Trade-In Value</p>
      <p className="font-bold text-orange-500 text-3xl">
        <span className="text-2xl">$</span>
        {maskNumberThousands(tradeIn)}
      </p>
      <p className="text-base text-justify my-4">
        We will use your trade-in value to add to your down payment giving you a wider range of vehicles to choose from.
        You can use your own estimated value here and you can also get an estimated value from Kelley Blue Book® along
        with a guaranteed cash offer from our network of dealers in just a few moments.
      </p>
      <p className="text-blue-600 hover: cursor-pointer" onClick={() => selectedCard(5)}>
        Get an estimated Kelley Blue Book&#174; Trade-In Value
      </p>
      <Form
        form={form}
        layout="vertical"
        name="vehicle-ref"
        className="mt-4 w-9/12 items-center justify-center "
        onChange={handleFormSubmission}
      >
        {tradeInForm.map((form, index) => {
          return (
            <div key={index}>
              <p className="py-2 text-base font-semibold">{form.label}</p>
              <Form.Item
                name={form.name}
                hasFeedback
                rules={[
                  {
                    required: form.required,
                    message: `${form.label} is required`
                  },
                  validation(form)
                ]}
                className="custom-antd-label"
              >
                {renderForm(form, index)}
              </Form.Item>
            </div>
          )
        })}

        {makePayment === 'Yes' &&
          makingPayment.map((form, index) => {
            return (
              <div key={index}>
                <p className="py-2 text-base font-semibold">{form.label}</p>
                <Form.Item
                  name={form.name}
                  hasFeedback
                  rules={[
                    {
                      required: form.required,
                      message: `${form.label} is required`
                    },
                    validation(form)
                  ]}
                  className="custom-antd-label"
                >
                  {renderForm(form, index)}
                </Form.Item>
              </div>
            )
          })}
      </Form>
      <div className="-mx-8 bg-lightgray mt-10 p-6 border-t-2">
        <div className="flex">
          <div className="mr-8">
            <p className="font-bold mb-2 text-base">Kelley Blue Book&#174;</p>
            <p className="text-justify text-sky-400">
              Want to trade your car with a dealer? Trade or sell your car at a local dealership.
            </p>
            <p className="text-blue-600 mt-4 hover: cursor-pointer" onClick={goToTradeInStep}>
              Get Your Kelley Blue Book&#174; Value Now
            </p>
            <p className="text-blue-600 mt-2 hover: cursor-pointer" onClick={handleShowModal}>
              Get a trade-in value from a previous trade
            </p>
            <MyTradeInModal
              customerLogin={customerLogin}
              modalVisible={modalVisible}
              handleCloseModal={handleCloseModal}
              handleAddTradeIn={handleAddTradeIn}
            />
            <AuthPopup
              show={showPopupConfirm}
              setShow={setShowPopupConfirm}
              handleShowLoginForm={handleShowLoginForm}
              handleShowSignUpForm={handleShowSignUpForm}
              closable={false}
              maskClosable={false}
              setLoginRequired={setLoginRequired}
            />
            <LoginPopup show={show} setShow={setShow} handleShowLoginForm={handleShowLoginForm} />
            <LoginFormModal
              showModal={showLoginForm}
              setShowModal={setShowLoginForm}
              callback={callbackForForm}
              setShowCashDownPopupConfirm={setShowLoginForm}
            />
            <SignUpFormModal
              showModal={showSignUpForm}
              setShowModal={setShowSignUpForm}
              callback={callbackForForm}
              setShowCashDownPopupConfirm={setShowCashDownPopupConfirm}
            />
          </div>
          <img src={kelleyImage} alt="" className="h-24" />
        </div>
      </div>
    </div>
  )
}

TradeIn.propTypes = {
  selectedCard: PropTypes.func,
  setStack: PropTypes.func,
  setMyWalletView: PropTypes.func
}

export default TradeIn
