import HttpService from './http.service'
import { KBB_VEHICLE_TYPE } from '../constants/constants'

export function getVehiclesByVin(vinId) {
  const params = {
    type: KBB_VEHICLE_TYPE.POWERSPORTS,
    vin: vinId
  }
  return HttpService.post('kbb/vehicle-details-and-options-by-vin', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getVehicles({ vehicleClass, yearId, makeId, modelId, trimId, limit = 100, start = 0 }) {
  const params = {
    type: KBB_VEHICLE_TYPE.POWERSPORTS,
    vehicleClass,
    yearId,
    makeId,
    modelId,
    trimId,
    limit,
    start
  }
  return HttpService.post('kbb/vehicles', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getVehicleDetail(vehicleId) {
  const params = {
    type: KBB_VEHICLE_TYPE.POWERSPORTS,
    vehicleId
  }
  return HttpService.post('kbb/vehicle-details', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getYears({ vehicleClass, category, makeId, modelId, limit = 100, start = 0 }) {
  const params = {
    type: KBB_VEHICLE_TYPE.POWERSPORTS,
    vehicleClass,
    category,
    makeId,
    modelId,
    limit,
    start
  }
  return HttpService.post('kbb/years', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getMakes({ vehicleClass, category, yearId, limit = 100, start = 0 }) {
  const params = {
    type: KBB_VEHICLE_TYPE.POWERSPORTS,
    vehicleClass,
    category,
    yearId,
    limit,
    start
  }
  return HttpService.post('kbb/makes', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getModels({ vehicleClass, category, yearId, makeId, limit = 100, start = 0 }) {
  const params = {
    type: KBB_VEHICLE_TYPE.POWERSPORTS,
    vehicleClass,
    category,
    yearId,
    makeId,
    limit,
    start
  }
  return HttpService.post('kbb/models', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getTrims({ vehicleClass, yearId, makeId, modelId, bodyStyle }) {
  const params = {
    type: KBB_VEHICLE_TYPE.POWERSPORTS,
    vehicleClass,
    yearId,
    makeId,
    modelId,
    bodyStyle
  }
  return HttpService.post('kbb/trims', params).then((result) => {
    const { data } = result
    return data
  })
}

export function postApplyConfiguration({ vehicleId, vehicleOptionIds, configurationChanges }) {
  const params = {
    type: KBB_VEHICLE_TYPE.POWERSPORTS,
    startingConfiguration: {
      vehicleId,
      vehicleOptionIds
    },
    configurationChanges
  }
  return HttpService.post('kbb/apply-configuration', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getFinalVehicleValue(configuration, mileage, zipCode) {
  const params = {
    type: KBB_VEHICLE_TYPE.POWERSPORTS,
    configuration,
    mileage,
    zipCode,
    valuationDate: new Date()
  }
  return HttpService.post('kbb/calculate-vehicle-value', params).then((result) => {
    const { data } = result
    return data
  })
}
