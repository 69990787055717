import { Button, Modal } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useState } from 'react'
import ExistingTradeInsModal from '../ExistingTradeInsModal'

function VehicleFinanceSelectionModal({ showModal, closeModal, createNewTradeIn, setInitialTradeInData, tradeIns }) {
  const [showTradeIns, setShowTradeIns] = useState(false)

  const handleSelectingTradeIn = (data) => {
    setInitialTradeInData(data)
    setShowTradeIns(false)
  }

  const handleChangeShowModal = () => {
    setShowTradeIns(false)
  }

  return (
    <>
      <ExistingTradeInsModal
        tradeIns={tradeIns}
        showModal={showTradeIns}
        closeModal={handleChangeShowModal}
        setSelectedTradeIn={handleSelectingTradeIn}
      />
      <Modal
        centered
        visible={showModal}
        title="Do you want to start a new trade in OR use an existing trade in from your account?"
        onCancel={closeModal}
        okText="Close"
        className="calendar-modal"
        destroyOnClose
        width="50%"
        footer={[
          <Button type="primary" onClick={createNewTradeIn}>
            New
          </Button>,
          <Button type="primary" onClick={() => setShowTradeIns(true)}>
            Use existing
          </Button>
        ]}
      />
    </>
  )
}

VehicleFinanceSelectionModal.propTypes = {
  showModal: PropTypes.bool,
  setInitialTradeInData: PropTypes.func,
  createNewTradeIn: PropTypes.func,
  closeModal: PropTypes.func,
  tradeIns: PropTypes.array
}

export default VehicleFinanceSelectionModal
