import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as UserServices from 'src/services/user.service'
import './style.scss'

function DealerVerificationByToken() {
  const { verificationToken } = useParams()

  const navigate = useNavigate()
  const [isExpired, setIsExpired] = useState(false)

  useEffect(() => {
    if (verificationToken) {
      UserServices.activation({ verificationToken })
        .then((response) => {
          const { data } = response
          const id = data.id
          navigate(`/claim/${id}`)
        })
        .catch((error) => {
          console.error(error)
          setIsExpired(true)
        })
    } else {
      setIsExpired(true)
    }
  }, [verificationToken])

  if (!isExpired)
    return (
      <div style={{ fontSize: '32px' }} className="verification-expired-table">
        Verifying...
      </div>
    )

  return (
    <div className="verification-expired-table">
      <div style={{ padding: '0 35px;' }}>
        <h1 className="verification-expired-title">{`We're sorry...`}</h1>
        <span className="verification-expired-line" />
        <p className="verification-expired-description">BUT THIS LINK HAS EXPIRED.</p>
      </div>
    </div>
  )
}

export default DealerVerificationByToken
