import React from 'react'
import * as AuthService from '../../services/auth.service'
import './style.scss'
import SinglePageMetaDecorator from '../../decorators/SinglePageMetaDecorator'
import metaPages from '../../data/metaPages.json'
import metaPageImage from '../../assets/new-logo.png'

function Contact() {
  const [formStatus] = React.useState('Send')
  const onSubmit = async (e) => {
    e.preventDefault()
    const { name, email, message } = e.target.elements
    const contactName = name.value
    const contactEmail = email.value
    const contactMessage = message.value

    // const res =  await Axios.post(`/contact-form`, { ...conFom }, { withCredentials: false })
    AuthService.LoginForm({ contactName, contactEmail, contactMessage })
      .then((response) => {
        if (response.isSuccessful) {
          const messageDiv = document.getElementById('form-alert-message')
          messageDiv.style.display = 'block'
          document.getElementById('name').value = ''
          document.getElementById('message').value = ''
          document.getElementById('email').value = ''
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div className="container mt-5 formContainer">
      <SinglePageMetaDecorator
        title={metaPages.contact.title}
        description={metaPages.contact.description}
        imageUrl={metaPageImage}
        imageAlt={metaPages.contact.imageAlt}
      />
      <div id="form-alert-message">
        <h1>Your message is sent successfully!</h1>
        <h3>We will be in touch with you soon.</h3>
        <h3>
          Go to <a href="/">home page</a>
        </h3>
      </div>
      <div id="form-alert-message-error">
        <h1>Something went wrong.</h1>
        <h3>Please try again later.</h3>
        <h3>
          Go to <a href="/">home page</a>
        </h3>
      </div>
      <form id="form-contact-div" className="form form-contact" onSubmit={onSubmit}>
        <h2 className="mb-3">Get in touch with us!</h2>
        <div className="mb-3 form-group">
          <label className="form-label" htmlFor="name">
            <span>Name</span>
            <input className="form-control contactFormInput" type="text" id="name" required />
          </label>
        </div>
        <div className="mb-3 form-group">
          <label className="form-label" htmlFor="email">
            <span>Email</span>
            <input className="form-control contactFormInput" type="email" id="email" required />
          </label>
        </div>
        <div className="mb-3 form-group">
          <label className="form-label" htmlFor="message">
            <span>Message</span>
            <textarea rows="5" className="form-control contactFormInput" id="message" required />
          </label>
        </div>
        <button className="btn btn-danger contactFormInputBtn" type="submit">
          {formStatus}
        </button>
      </form>
    </div>
  )
}

export default Contact
