export const VEHICLES_STOCK = [
  {
    id: '<30',
    name: '30 or less'
  },
  {
    id: '31-100',
    name: '31-100'
  },
  {
    id: '101-300',
    name: '101-300'
  },
  {
    id: '300+',
    name: '300+'
  }
]
