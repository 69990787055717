import { CheckOutlined } from '@ant-design/icons'
import { Button, Checkbox, Collapse, Form, Input, Slider, notification } from 'antd'
import { debounce } from 'lodash'
import * as PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import { useOrientation, useWindowSize } from 'react-use'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import { INITIAL_PRICE, STORAGE_KEYS } from 'src/constants/constants'
import { load, save } from 'src/lib/storage'
import { filterValues } from 'src/services/buy.service'
import httpService from 'src/services/http.service'
import { maskNumberThousands } from 'src/utils'
import './style.scss'

const { Panel } = Collapse

const marketPriceOptions = [
  { count: 25, name: 'Above Market' },
  { count: 20, name: 'Good Deal' },
  { count: 15, name: 'Fair deal' },
  { count: 45, name: 'Below Market' }
]

const zipRangeOptions = [
  { value: 5, label: '5 miles' },
  { value: 10, label: '10 miles' },
  { value: 15, label: '15 miles' },
  { value: 25, label: '25 miles' },
  { value: 50, label: '50 miles' },
  { value: 100, label: '100 miles' },
  { value: 200, label: '200 miles' },
  { value: 300, label: '300 miles' },
  { value: -1, label: 'Unrestricted' }
]

const transmissionFilter = [
  { name: 'A', displayName: 'Automatic', count: 0 },
  { name: 'M', displayName: 'Manual', count: 0 },
  { name: 'Unspecified', displayName: 'Unspecified', count: 0 },
  { name: 'CVT', displayName: 'CVT', count: 0 }
]

const similarDealFilter = [
  { key: 'AM', name: 'Above Market', count: 0 },
  { key: 'FD', name: 'Fair Deal', count: 0 },
  { key: 'GD', name: 'Good Deal', count: 0 },
  { key: 'BM', name: 'Below Market', count: 0 }
]

function findTransmissionName(transmission) {
  let name = ''
  transmissionFilter.forEach((item) => {
    if (item.name === transmission) {
      name = item.displayName
    }
  })
  return name
}

function findSimilarDealTypeName(dealType) {
  let name = ''
  similarDealFilter.forEach((item) => {
    if (item.key === dealType) {
      name = item.name
    }
  })
  return name
}

const initYear = {
  min: 2009,
  max: 2023
}
const initMileage = {
  min: 0,
  max: 200000
}

const getFilterBody = ({
  zipCode,
  zipRange,
  price,
  year,
  mileage,
  make,
  model,
  trim,
  transmission,
  color,
  certifiedVehicles,
  keyword,
  dealerId,
  similarDeal
}) => {
  const filterBody = {}
  if (zipCode !== '') filterBody.zipCode = zipCode
  filterBody.zipRadius =
    zipRange !== '' && zipRange !== undefined && zipCode !== '' && zipCode !== undefined ? zipRange : -1
  if (price && (price.min !== INITIAL_PRICE.min || price.max !== INITIAL_PRICE.max)) {
    filterBody.price = { min: Number(price.min), max: Number(price.max) }
  }
  if (year && (year.min !== initYear.min || year.max !== initYear.max)) filterBody.year = year
  if (mileage && (mileage.min !== initMileage.min || mileage.max !== initMileage.max)) {
    filterBody.mileage = { min: Number(mileage.min), max: Number(mileage.max) }
  }
  if (make && make.length > 0) filterBody.make = make
  if (model && model.length > 0) filterBody.model = model
  if (trim && trim.length > 0) filterBody.trim = trim
  if (color && color.length > 0) filterBody.color = color
  if (transmission && transmission.length > 0) filterBody.transmission = transmission
  if (color && color.length > 0) filterBody.color = color
  filterBody.certifiedVehicles = certifiedVehicles
  if (keyword !== '') filterBody.keyword = keyword
  if (dealerId && dealerId > 0) filterBody.dealerId = dealerId
  if (similarDeal && similarDeal.length > 0) filterBody.similarDeal = similarDeal
  return filterBody
}

function FilterBuyCar({
  loading,
  certified,
  setCertified,
  price,
  setPrice,
  year,
  setYear,
  mileage,
  setMileage,
  make,
  setMake,
  model,
  setModel,
  trim,
  setTrim,
  transmission,
  setTransmission,
  zipCode,
  setZipCode,
  zipRange,
  setZipRange,
  clearFilter,
  className,
  showOnlyFavoriteVehicles,
  setShowOnlyFavoriteVehicles,
  favouriteCars,
  oldBuyCarRef,
  buyCars,
  setBuyCars,
  vehicleType,
  setVehicleType,
  marketPrice,
  setMarketPrice,
  color,
  setColor,
  showZipCodeNotiBox,
  keyword,
  setKeyword,
  dealerId,
  reloadFilters,
  setReloadFilters,
  similarDeal,
  setSimilarDeal,
  query
}) {
  const initialFilterOptions = load(STORAGE_KEYS.INITIAL_FILTER_OPTIONS) || undefined
  const initialZipCode = initialFilterOptions?.zipCode || ''
  const initialZipRange = initialFilterOptions?.zipRange || -1
  const initialFilterCount = load(STORAGE_KEYS.INITIAL_FILTER_COUNT) || undefined
  const [certifiedCheck, setCertifiedCheck] = useState(false)
  const [loadingModels, setLoadingModels] = useState(false)
  const [loadingFilters, setLoadingFilters] = useState(loading)
  const [loadingFiltersMake, setLoadingFiltersMake] = useState(false)
  const [loadingFiltersModel, setLoadingFiltersModel] = useState(false)
  const [makeTypes, setMakeTypes] = useState([])
  const [modelTypes, setModelTypes] = useState([])
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([])
  const [colorOptions, setColorOptions] = useState([])
  const [bodyTrimTypes, setBodyTrimTypes] = useState([])
  const [transmissionTypes, setTransmissionTypes] = useState([])
  const [tempZipCode, setTempZipCode] = useState(zipCode)
  const [tempPrice, setTempPrice] = useState(price)
  const [tempYear, setTempYear] = useState(year)
  const [tempMileage, setTempMileage] = useState(mileage)
  const [form] = Form.useForm()
  const { type: rotateType } = useOrientation()
  const { width: screenWidth } = useWindowSize()
  const isMobile = screenWidth <= 1024

  const { customerLogin } = useAuthorizationContext()

  // count vehicles by make, model, trim, transmission
  const [countVehiclesFilter, setCountVehiclesFilter] = useState({
    makeCountVehicles: initialFilterCount?.makeCountVehicles || 0,
    modelCountVehicles: initialFilterCount?.modelCountVehicles || 0,
    // trimCountVehicles: initialFilterCount?.makeCountVehicles || 0,
    transmissionCountVehicles: initialFilterCount?.transmissionCountVehicles || 0,
    marketPriceCountVehicles: initialFilterCount?.marketPriceCountVehicles || 0,
    similarDealCountVehicles: initialFilterCount?.similarDealCountVehicles || 0
  })

  const [countMakes, setCountMakes] = useState(initialFilterCount?.makeCountVehicles || 0)
  const [countModels, setCountModels] = useState(initialFilterCount?.modelCountVehicles || 0)
  const [countTransmissions, setCountTransmissions] = useState(initialFilterCount?.transmissionCountVehicles || 0)

  const [similarDealsType, setSimilarDealsType] = useState([])
  const [countSimilarDeal, setCountSimilarDeal] = useState(initialFilterCount?.similarDealCountVehicles || 0)

  const {
    // makeCountVehicles,
    // modelCountVehicles,
    // trimCountVehicles,
    // transmissionCountVehicles,
    marketPriceCountVehicles
  } = countVehiclesFilter

  const handleCountVehiclesFilter = (label, value) => {
    const countVehicles = {
      ...countVehiclesFilter,
      [label]: value
    }
    setCountVehiclesFilter(countVehicles)
    save(STORAGE_KEYS.INITIAL_FILTER_COUNT, JSON.stringify(countVehicles))
    if (label === 'makeCountVehicles') {
      setCountMakes(value)
    } else if (label === 'modelCountVehicles') {
      setCountModels(value)
    } else if (label === 'transmissionCountVehicles') {
      setCountTransmissions(value)
    } else if (label === 'similarDealCountVehicles') {
      setCountSimilarDeal(value)
    }
  }

  const handleMakeChange = (checkedValues) => {
    setLoadingModels(make.length !== checkedValues.length)
    setLoadingFiltersMake(false)
    setLoadingFiltersModel(false)
    setMake(checkedValues)
    let countVehicles = 0
    checkedValues.forEach((item) => {
      const selected = makeTypes.find((make) => make.name === item)
      if (selected) {
        countVehicles += selected.count
      }
    })
    handleCountVehiclesFilter('makeCountVehicles', countVehicles)
  }

  const handleModelChange = (checkedValues) => {
    setLoadingFiltersMake(false)
    setLoadingFiltersModel(false)
    setModel(checkedValues)
    let countVehicles = 0
    checkedValues.forEach((item) => {
      const selected = modelTypes.find((model) => model.name === item)
      if (selected) {
        countVehicles += selected.count
      }
    })
    handleCountVehiclesFilter('modelCountVehicles', countVehicles)
  }

  const handleBodyTrimChange = (checkedValues) => {
    setTrim(checkedValues)
    let countVehicles = 0
    checkedValues.forEach((item) => {
      const selected = bodyTrimTypes.find((trim) => trim.name === item)
      if (selected) {
        countVehicles += selected.count
      }
    })
    handleCountVehiclesFilter('trimCountVehicles', countVehicles)
  }

  const handleTransmissionChange = (checkedValues) => {
    setTransmission(checkedValues)
    let countVehicles = 0
    checkedValues.forEach((item) => {
      const selected = transmissionTypes.find((transmission) => transmission.name === item)

      if (selected) {
        countVehicles += Math.floor(selected.count)
      }
    })
    handleCountVehiclesFilter('transmissionCountVehicles', countVehicles)
  }

  const handleVehicleTypeChange = (checkedValues) => {
    setVehicleType(checkedValues)
    let countVehicles = 0
    checkedValues.forEach((item) => {
      const selected = vehicleTypeOptions.find((type) => type.name === item)
      if (selected) {
        countVehicles += selected.count
      }
    })
    handleCountVehiclesFilter('vehicleTypeCountVehicles', countVehicles)
  }

  const handleMarketPriceChange = (checkedValues) => {
    setMarketPrice(checkedValues)
    let countVehicles = 0
    checkedValues.forEach((item) => {
      const selected = marketPriceOptions.find((type) => type.name === item)
      if (selected) {
        countVehicles += selected.count
      }
    })
    handleCountVehiclesFilter('marketPriceCountVehicles', countVehicles)
  }

  const handleSimilarDealChange = (checkedValues) => {
    setSimilarDeal(checkedValues)
    let countVehicles = 0
    checkedValues.forEach((item) => {
      const selected = similarDealsType.find((similarDeal) => similarDeal.key === item)

      if (selected) {
        countVehicles += Math.floor(selected.count)
      }
    })
    handleCountVehiclesFilter('similarDealCountVehicles', countVehicles)
  }

  const handleFilterValues = useCallback(() => {
    setLoadingFilters(true)
    const filterBody = getFilterBody({
      certifiedVehicles: certified,
      zipCode,
      zipRange,
      price,
      year,
      mileage,
      make,
      model,
      trim,
      transmission,
      color,
      vehicleType,
      keyword,
      dealerId,
      similarDeal
    })
    filterValues({ ...filterBody })
      .then((response) => {
        const data = response.data

        const makeFilter = []
        data?.make?.forEach((item) => {
          makeFilter.push({ name: item.make, count: item.count })
        })

        if (make.length > 0) {
          let countVehicles = 0
          make.forEach((item) => {
            const selected = makeFilter.find((make) => make.name === item)
            if (selected) {
              countVehicles += selected.count
            }
          })
          handleCountVehiclesFilter('makeCountVehicles', countVehicles)
        }

        data?.transmission?.forEach((item) => {
          switch (item.name) {
            case 'A':
              transmissionFilter[0].count = item.count
              break
            case 'M':
              transmissionFilter[1].count = item.count
              break
            case 'Unspecified':
              transmissionFilter[2].count = item.count
              break
            case 'CVT':
              transmissionFilter[3].count = item.count
              break
            default:
              transmissionFilter.push({
                ...item,
                displayName: item.name
              })
              break
          }
        })

        if (make && make.length > 0) {
          const modelFilters = []
          data?.model?.forEach((model) => {
            modelFilters.push({
              name: model.name,
              count: model?.count || 0
            })
          })
          setModelTypes(modelFilters)
          if (model.length > 0) {
            let countVehicles = 0
            model.forEach((item) => {
              const selected = modelFilters.find((model) => model.name === item)
              if (selected) {
                countVehicles += selected.count
              }
            })
            handleCountVehiclesFilter('modelCountVehicles', countVehicles)
          }
        } else {
          setModelTypes([])
        }

        if (transmission.length > 0) {
          let countVehicles = 0
          transmission.forEach((item) => {
            const selected = transmissionFilter.find((model) => model.name === item)
            if (selected) {
              countVehicles += selected.count
            }
          })
          handleCountVehiclesFilter('transmissionCountVehicles', countVehicles)
        }

        setMakeTypes(makeFilter || [])
        setTransmissionTypes(transmissionFilter || [])
        setVehicleTypeOptions(data.vehicle_type || [])
        setColorOptions(data.color || [])
        setBodyTrimTypes(data.bodies || [])
        setLoadingModels(false)
        setLoadingFilters(false)
        setLoadingFiltersMake(false)
        setLoadingFiltersModel(false)
        setCertifiedCheck(false)
        setSimilarDealsType(data.similar_deals || [])
      })
      .catch((e) => {
        console.error(e)
        setLoadingModels(false)
        setLoadingFilters(false)
        setLoadingFiltersMake(false)
        setLoadingFiltersModel(false)
        setCertifiedCheck(false)
      })
  }, [
    certified,
    zipCode,
    zipRange,
    JSON.stringify(price),
    JSON.stringify(year),
    JSON.stringify(mileage),
    JSON.stringify(make),
    JSON.stringify(model),
    JSON.stringify(trim),
    JSON.stringify(transmission),
    JSON.stringify(color),
    JSON.stringify(vehicleType),
    keyword,
    dealerId,
    JSON.stringify(similarDeal),
    getFilterBody
  ])

  useEffect(() => {
    if (
      certifiedCheck ||
      (zipCode && zipCode !== initialZipCode) ||
      (zipRange && zipRange !== initialZipRange) ||
      price.min !== INITIAL_PRICE.min ||
      price.max !== INITIAL_PRICE.max ||
      year.min !== initYear.min ||
      year.max !== initYear.max ||
      mileage.min !== initMileage.min ||
      mileage.max !== initMileage.max ||
      (make && make.length > 0) ||
      (model && model.length > 0) ||
      (trim && trim.length > 0) ||
      (transmission && transmission.length > 0) ||
      (color && color.length > 0) ||
      (vehicleType && vehicleType.length > 0) ||
      (similarDeal && similarDeal.length > 0) ||
      keyword !== '' ||
      (dealerId && dealerId > 0)
    ) {
      handleFilterValues()
    } else {
      setModelTypes([])
      setTransmissionTypes([])
      setSimilarDealsType([])
    }
  }, [
    zipCode,
    certified,
    zipRange,
    JSON.stringify(price),
    JSON.stringify(year),
    JSON.stringify(mileage),
    JSON.stringify(make),
    JSON.stringify(model),
    JSON.stringify(trim),
    JSON.stringify(transmission),
    JSON.stringify(color),
    JSON.stringify(vehicleType),
    JSON.stringify(similarDeal),
    keyword,
    dealerId,
    handleFilterValues
  ])

  useEffect(() => {
    if (reloadFilters) {
      handleFilterValues()
      setReloadFilters(false)
    }
  }, [reloadFilters])

  useEffect(() => {
    if (price) {
      setTempPrice(price)
    }
  }, [JSON.stringify(price)])

  useEffect(() => {
    if (year) {
      setTempYear(year)
    }
  }, [JSON.stringify(year)])

  useEffect(() => {
    if (mileage) {
      setTempMileage(mileage)
    }
  }, [JSON.stringify(mileage)])

  useEffect(() => {
    if (zipCode) {
      setTempZipCode(zipCode)
      form.setFieldsValue({ zipCode })
    } else {
      setTempZipCode('')
      form.setFieldsValue({ zipCode: '' })
    }
  }, [zipCode])

  useEffect(() => {
    if (zipRange) {
      const zipCodeMileRange = zipRangeOptions.find((item) => item.value === parseInt(zipRange, 10))
      form.setFieldsValue({ zipRange: zipCodeMileRange })
    }
  }, [zipRange])

  useEffect(() => {
    if (price || year || mileage || zipCode) {
      setLoadingFiltersMake(true)
      setLoadingFiltersModel(true)
    }
  }, [JSON.stringify(price), JSON.stringify(year), JSON.stringify(mileage), JSON.stringify(zipCode)])

  useEffect(() => {
    if (query !== '') {
      setKeyword(query)
    } else {
      setCertified([])
      setLoadingFilters(true)
      const filterBody = getFilterBody({
        certifiedVehicles: certified,
        zipCode,
        zipRange,
        price,
        year,
        mileage,
        make,
        model,
        trim,
        transmission,
        color,
        vehicleType,
        keyword,
        similarDeal,
        dealerId
      })
      filterValues({ ...filterBody })
        .then((response) => {
          const makeNameArr = []
          const makeFilter = response.data?.make?.map((item) => {
            makeNameArr.push(item.make)
            return { name: item.make, count: item.count }
          })

          response.data?.transmission.forEach((item) => {
            switch (item.name) {
              case 'A':
                transmissionFilter[0].count = item.count
                break
              case 'M':
                transmissionFilter[1].count = item.count
                break
              case 'Unspecified':
                transmissionFilter[2].count = item.count
                break
              case 'CVT':
                transmissionFilter[3].count = item.count
                break
              default:
                transmissionFilter.push({
                  ...item,
                  displayName: item.name
                })
                break
            }
          })

          setMakeTypes(makeFilter || [])
          setTransmissionTypes(transmissionFilter || [])
          setVehicleTypeOptions(response.data?.vehicle_type || [])
          setBodyTrimTypes(response.data?.bodies || [])
          setColorOptions(response.data?.color || [])
          setLoadingModels(false)
          setLoadingFilters(false)
          setLoadingFiltersMake(false)
          setLoadingFiltersModel(false)
          setSimilarDealsType(response.data?.similar_deals || [])
        })
        .catch((e) => {
          console.error(e)
          setLoadingModels(false)
          setLoadingFilters(false)
          setLoadingFiltersMake(false)
          setLoadingFiltersModel(false)
        })
    }
  }, [keyword])

  const debouncePriceChange = useMemo(() => {
    return debounce((value) => {
      setPrice({
        min: value[0],
        max: value[1]
      })
    }, 500)
  }, [])

  const handlePriceChange = (value) => {
    setTempPrice({
      min: value[0],
      max: value[1]
    })
  }

  const handleCertifiedVehicles = (value) => {
    setCertified(value)
    setCertifiedCheck(false)
  }

  const debounceYearChange = useMemo(() => {
    return debounce((value) => {
      setYear({
        min: value[0],
        max: value[1]
      })
    }, 500)
  }, [])

  const handleYearChange = (value) => {
    setTempYear({
      min: value[0],
      max: value[1]
    })
  }

  const debounceMileageChange = useMemo(() => {
    return debounce((value) => {
      setMileage({
        min: value[0],
        max: value[1]
      })
    }, 500)
  }, [])

  const handleMileageChange = (value) => {
    setTempMileage({
      min: value[0],
      max: value[1]
    })
  }

  const handleChangeColor = (checkedValues) => {
    setColor(checkedValues)
  }

  const setZipCodeUpdated = () => {
    save(STORAGE_KEYS.ZIP_CODE_CHANGED, 'true')
  }

  const handleZipRangeChange = (selectedValue) => {
    setZipCodeUpdated()
    if (selectedValue.value < 1) {
      setReloadFilters(true)
      setTempZipCode('')
      form.setFieldsValue({ ...form.getFieldValue(), zipCode: '' })
      form.setFieldsValue({ ...form.getFieldValue(), zipRange: { value: -1, label: 'Unrestricted' } })
      handleFilterValues()
    }
    if (zipCode !== '' && zipCode !== undefined) {
      setZipRange(selectedValue.value)
    } else if (selectedValue.value > 0) {
      setTempZipCode('')
      form.setFieldsValue({ ...form.getFieldValue(), zipCode: '' })
      form.setFieldsValue({ ...form.getFieldValue(), zipRange: { value: -1, label: 'Unrestricted' } })
      notification.error({
        message: 'Radius error',
        description: 'To filter by radius first provide zip code.'
      })
    }
  }

  useEffect(() => {
    return () => {
      debouncePriceChange.cancel()
      debounceYearChange.cancel()
      debounceMileageChange.cancel()
    }
  }, [])

  const onZipCodeChange = (event) => {
    const value = event.target.value
    setTempZipCode(value)
    if (value.length <= 5) {
      setZipCodeUpdated()
      setZipCode(value)
      if (!zipRange || zipRange < 1) {
        setZipRange(100)
      }
    }
  }

  const handleChangeFavoriteVehicles = () => {
    setShowOnlyFavoriteVehicles(!showOnlyFavoriteVehicles)
    if (!showOnlyFavoriteVehicles) {
      oldBuyCarRef.current = buyCars
      setBuyCars(favouriteCars)
    } else if (oldBuyCarRef.current.length > 0) {
      setBuyCars(oldBuyCarRef.current)
    }
  }

  const priceFormatter = (value) => `$${value}`

  const extraMake = (
    <div className={`${make.length ? '' : 'hidden'}`}>
      <div className="bg-gray-100 text-xs mb-2">{`${countMakes} vehicles`}</div>
      <div className={`flex bg-gray-100 text-xs flex-wrap rounded ${make.length ? 'p-1' : 'hidden'}`}>
        {!!make.length &&
          make.map((item, index) => (
            <div className="flex items-center mr-2 mb-1" key={`make-check-${index}`}>
              <CheckOutlined className="text-blue-600 mr-1" />
              <p>{item}</p>
            </div>
          ))}
      </div>
    </div>
  )

  const extraModel = (
    <div className={`${model.length ? '' : 'hidden'}`}>
      <div className="bg-gray-100 text-xs mb-2">{`${countModels} vehicles`}</div>
      <div className={`flex bg-gray-100 text-xs flex-wrap rounded ${model.length ? 'p-1' : 'hidden'}`}>
        {!!model.length &&
          model.map((item, index) => (
            <div className="flex items-center mr-2 mb-1" key={`model-check-${index}`}>
              <CheckOutlined className="text-blue-600 mr-1" />
              <p>{item}</p>
            </div>
          ))}
      </div>
    </div>
  )

  const extraTrim = (
    <div className={`${trim.length ? '' : 'hidden'}`}>
      {/* <div className="bg-gray-100 text-xs mb-2">{`${trimCountVehicles} vehicles`}</div> */}
      <div className={`flex bg-gray-100 text-xs flex-wrap rounded ${trim.length ? 'p-1' : 'hidden'}`}>
        {!!trim.length &&
          trim.map((item, index) => (
            <div className="flex items-center mr-2 mb-1" key={`trim-check-${index}`}>
              <CheckOutlined className="text-blue-600 mr-1" />
              <p>{item}</p>
            </div>
          ))}
      </div>
    </div>
  )

  const extraTransmission = (
    <div className={`${transmission.length ? '' : 'hidden'}`}>
      <div className="bg-gray-100 text-xs mb-2">{`${countTransmissions} vehicles`}</div>
      <div className={`flex bg-gray-100 text-xs flex-wrap rounded ${transmission.length ? 'p-1' : 'hidden'}`}>
        {!!transmission.length &&
          transmission.map((item, index) => (
            <div className="flex items-center mr-2 mb-1" key={`transmission-check-${index}`}>
              <CheckOutlined className="text-blue-600 mr-1" />
              <p>{findTransmissionName(item)}</p>
            </div>
          ))}
      </div>
    </div>
  )

  const extraVehicleType = (
    <div className={`${vehicleType.length ? '' : 'hidden'}`}>
      <div className={`flex bg-gray-100 text-xs flex-wrap rounded ${vehicleType.length ? 'p-1' : 'hidden'}`}>
        {!!vehicleType.length &&
          vehicleType.map((item, index) => (
            <div className="flex items-center mr-2 mb-1" key={`make-check-${index}`}>
              <CheckOutlined className="text-blue-600 mr-1" />
              <p>{item}</p>
            </div>
          ))}
      </div>
    </div>
  )

  const extraMarketPrice = (
    <div className={`${marketPrice.length ? '' : 'hidden'}`}>
      <div className="bg-gray-100 text-xs mb-2">{`${marketPriceCountVehicles} vehicles`}</div>
      <div className={`flex bg-gray-100 text-xs flex-wrap rounded ${marketPrice.length ? 'p-1' : 'hidden'}`}>
        {!!marketPrice.length &&
          marketPrice.map((item, index) => (
            <div className="flex items-center mr-2 mb-1" key={`make-check-${index}`}>
              <CheckOutlined className="text-blue-600 mr-1" />
              <p>{item}</p>
            </div>
          ))}
      </div>
    </div>
  )

  const extraColor = (
    <div className={`${color.length ? '' : 'hidden'}`}>
      <div className={`flex bg-gray-100 text-xs flex-wrap rounded ${color.length ? 'p-1' : 'hidden'}`}>
        {!!color.length &&
          color.map((item, index) => (
            <div className="flex items-center mr-2 mb-1" key={`make-check-${index}`}>
              <CheckOutlined className="text-blue-600 mr-1" />
              <p>{item}</p>
            </div>
          ))}
      </div>
    </div>
  )

  const extraSimilarDeal = (
    <div className={`${similarDeal.length ? '' : 'hidden'}`}>
      <div className="bg-gray-100 text-xs mb-2">{`${countSimilarDeal} vehicles`}</div>
      <div className={`flex bg-gray-100 text-xs flex-wrap rounded ${similarDeal.length ? 'p-1' : 'hidden'}`}>
        {!!similarDeal.length &&
          similarDeal.map((item, index) => (
            <div className="flex items-center mr-2 mb-1" key={`similar-deal-check-${index}`}>
              <CheckOutlined className="text-blue-600 mr-1" />
              <p>{findSimilarDealTypeName(item)}</p>
            </div>
          ))}
      </div>
    </div>
  )

  const hideOnProduction = process.env.REACT_APP_HIDE_ON_PRODUCTION === 'true'

  return (
    <div
      className={`mt-2 border p-3 z-0 overflow-y-auto overflow-x-hidden ${className}`}
      style={{
        maxHeight: rotateType === 'landscape-primary' && isMobile ? 'calc(100vh + 150px)' : 'calc(100vh - 200px)'
      }}
    >
      <p className="text-lg font-semibold">More Filters</p>
      <Collapse expandIconPosition="right" className="filter-buy" defaultActiveKey={['zip code']}>
        <Panel header="Zip Code" key="zip code">
          <Form layout="horizontal" name="vehicle-ref" className="py-0" form={form}>
            <Form.Item name="zipRange">
              <Select
                name="zipRange"
                placeholder="Mileage radius"
                value={zipRange}
                onChange={handleZipRangeChange}
                className="h-full"
                options={zipRangeOptions}
                aria-label="zipRange"
              />
            </Form.Item>

            <Form.Item
              name="zipCode"
              rules={[
                {
                  pattern: /^(?:\d{5})?$/,
                  message: 'Invalid zip code'
                },
                {
                  validateTrigger: 'onSubmit',
                  validator(_, value) {
                    return httpService
                      .get(`/zip/is-valid/${value}`)
                      .then((result) => {
                        if (!result?.data?.data?.isValid) {
                          return Promise.reject(new Error('Invalid zip'))
                        }
                        return Promise.resolve()
                      })
                      .catch(() => {
                        return Promise.reject(new Error('Invalid zip'))
                      })
                  }
                }
              ]}
              className="mb-0"
            >
              <Input
                maxLength={5}
                name="zipCode"
                placeholder="e.g. 10116"
                className="w-10/12 mr-2 zip-code-input border text-blue-800 focus:text-blueFv-600 focus:outline-none"
                onChange={onZipCodeChange}
                value={tempZipCode}
                type="text"
              />
            </Form.Item>
            {showZipCodeNotiBox && <div className="mt-1">Please enter your zip code here.</div>}
          </Form>
        </Panel>
        <Panel header="Certified Vehicles" key="certifiedVehicles">
          <Checkbox.Group style={{ width: '100%' }} onChange={handleCertifiedVehicles} value={certified}>
            <div className="flex justify-between mb-1.5" key="certifiedVehicles-cpo">
              <Checkbox value="cpo" className="flex items-center flex-1">
                Certified Pre-Owned
              </Checkbox>
            </div>
            <div className="flex justify-between mb-1.5" key="certifiedVehicles-vip">
              <Checkbox value="vip" className="flex items-center flex-1">
                Verified Inspection Program
              </Checkbox>
            </div>
          </Checkbox.Group>
        </Panel>
        <Panel header="Price" key="price">
          <div className="flex justify-between">
            <p>${tempPrice.min.toLocaleString('en-US')}</p>
            <p>${tempPrice.max.toLocaleString('en-US')}</p>
          </div>
          <Slider
            range
            onAfterChange={(value) => debouncePriceChange(value)}
            value={[tempPrice.min, tempPrice.max]}
            onChange={handlePriceChange}
            max={500000}
            tipFormatter={priceFormatter}
            aria-label="Price"
            title="Price"
          />
        </Panel>
        <Panel header="Year" key="year">
          <div className="flex justify-between">
            <p>{tempYear.min}</p>
            <p>{tempYear.max}</p>
          </div>
          <Slider
            range
            value={[tempYear.min, tempYear.max]}
            onAfterChange={(value) => debounceYearChange(value)}
            onChange={handleYearChange}
            max={2023}
            min={2009}
            aria-label="year"
            title="Year"
          />
        </Panel>
        <Panel header="Mileage" key="mileage">
          <div className="flex justify-between">
            <p>{tempMileage.min}</p>
            <p>{tempMileage.max}</p>
          </div>
          <Slider
            range
            onAfterChange={(value) => debounceMileageChange(value)}
            value={[tempMileage.min, tempMileage.max]}
            onChange={handleMileageChange}
            max={200000}
            min={0}
            aria-label="mileage"
            title="Mileage"
          />
        </Panel>
        <Panel header="Body" key="trim" extra={!!trim.length && extraTrim}>
          <Checkbox.Group style={{ width: '100%' }} onChange={handleBodyTrimChange} value={trim}>
            {bodyTrimTypes?.map((type, index) => (
              <div className="flex justify-between mb-1.5" key={`body-trim-type-${index}`}>
                <Checkbox value={type.name} className="flex items-center flex-1">
                  {type.name}
                </Checkbox>
              </div>
            ))}
          </Checkbox.Group>
        </Panel>
        <Panel header="Color" key="color" extra={!!color.length && extraColor}>
          <Checkbox.Group style={{ width: '100%' }} value={color} onChange={handleChangeColor}>
            {colorOptions?.map((color, index) => (
              <div className="flex justify-between mb-1.5" key={`make-type-${index}`}>
                <Checkbox value={color.name} className="flex items-center flex-1">
                  {color.name}
                </Checkbox>
              </div>
            ))}
          </Checkbox.Group>
        </Panel>
        <div className={`py-4 border-b ${!customerLogin && 'hidden'}`}>
          <Button className="px-0 border-0 w-full text-left hover:text-black" onClick={handleChangeFavoriteVehicles}>
            {showOnlyFavoriteVehicles ? 'Close My Favorite' : 'Show My Favorite'}
          </Button>
        </div>
        <Panel header="Make" key="make" extra={!!make.length && extraMake}>
          <Checkbox.Group style={{ width: '100%' }} value={make} onChange={handleMakeChange}>
            {makeTypes?.map((type, index) => (
              <div className="flex justify-between mb-1.5" key={`make-type-${index}`}>
                <Checkbox value={type.name} className="flex items-center flex-1" disabled={type.count === 0}>
                  {type.name}
                </Checkbox>
                {!loadingFiltersMake ? (
                  <p className="text-gray-300">
                    <span className="text-black">{maskNumberThousands(type.count, true)}</span> vehicle
                    {type.count === 1 ? '' : 's'}
                  </p>
                ) : (
                  <div className="loader-filters" />
                )}
              </div>
            ))}
          </Checkbox.Group>
        </Panel>
        <Panel header="Model" key="model" extra={!!model.length && extraModel}>
          {!loadingModels ? (
            <Checkbox.Group style={{ width: '100%' }} onChange={handleModelChange} value={model}>
              {modelTypes?.map((type, index) => (
                <div className="flex justify-between mb-1.5" key={`model-type-${index}`}>
                  <Checkbox value={type.name} className="flex items-center flex-1" disabled={type.count === 0}>
                    {type.name}
                  </Checkbox>
                  {!loadingFiltersModel ? (
                    <p className="text-gray-300">
                      <span className="text-black">{maskNumberThousands(type.count, true)}</span> vehicle
                      {type.count === 1 ? '' : 's'}
                    </p>
                  ) : (
                    <div className="loader-filters" />
                  )}
                </div>
              ))}
            </Checkbox.Group>
          ) : (
            <div className="loader-filters" />
          )}
        </Panel>
        <Panel header="Transmission" key="transmission" extra={!!transmission.length && extraTransmission}>
          <Checkbox.Group style={{ width: '100%' }} onChange={handleTransmissionChange} value={transmission}>
            {transmissionTypes?.map((type, index) => (
              <div className="flex items-center justify-between mb-1.5" key={`transmission-type-${index}`}>
                <Checkbox value={type.name} className="flex items-center flex-1" disabled={type.count === 0}>
                  {type.displayName}
                </Checkbox>
                {!loadingFilters ? (
                  <p className="text-gray-300">
                    <span className="text-black">{maskNumberThousands(type.count, true)}</span> vehicle
                    {type.count === 1 ? '' : 's'}
                  </p>
                ) : (
                  <div className="loader-filters" />
                )}
              </div>
            ))}
          </Checkbox.Group>
        </Panel>
        {!hideOnProduction && (
          <>
            <Panel header="Vehicles" key="vehicles" extra={!!vehicleType.length && extraVehicleType}>
              <Checkbox.Group style={{ width: '100%' }} value={vehicleType} onChange={handleVehicleTypeChange}>
                {vehicleTypeOptions?.map((type, index) => (
                  <div className="flex justify-between mb-1.5" key={`make-type-${index}`}>
                    <Checkbox value={type.name} className="flex items-center flex-1" disabled={type.count === 0}>
                      {type.name}
                    </Checkbox>
                    {!loadingFilters ? (
                      <p className="text-gray-300">
                        <span className="text-black">{maskNumberThousands(type.count, true)}</span> vehicle
                        {type.count === 1 ? '' : 's'}
                      </p>
                    ) : (
                      <div className="loader-filters" />
                    )}
                  </div>
                ))}
              </Checkbox.Group>
            </Panel>
            <Panel
              className="hidden"
              header="Market Price"
              key="market"
              extra={!!marketPrice.length && extraMarketPrice}
            >
              <Checkbox.Group style={{ width: '100%' }} value={marketPrice} onChange={handleMarketPriceChange}>
                {marketPriceOptions?.map((market, index) => (
                  <div className="flex justify-between mb-1.5" key={`make-type-${index}`}>
                    <Checkbox value={market.name} className="flex items-center flex-1">
                      {market.name}
                    </Checkbox>
                    <p className="text-gray-300">
                      <span className="text-black">{market.count}</span> vehicle
                      {market.count === 1 ? '' : 's'}
                    </p>
                  </div>
                ))}
              </Checkbox.Group>
            </Panel>
          </>
        )}
        {model.length > 0 && make.length > 0 && (
          <Panel header="Similar Deals" key="similar-deals" extra={similarDeal !== '' && extraSimilarDeal}>
            <Checkbox.Group style={{ width: '100%' }} onChange={handleSimilarDealChange} value={similarDeal}>
              {similarDealsType?.map((type, index) => (
                <div className="flex items-center justify-between mb-1.5" key={`similar-deals-type-${index}`}>
                  <Checkbox value={type.key} className="flex items-center flex-1" disabled={type.count === 0}>
                    {type.name}
                  </Checkbox>
                  {!loadingFilters ? (
                    <p className="text-gray-300 pt-2">
                      <span className={type.count > 0 ? 'text-black' : 'text-gray'}>
                        {maskNumberThousands(type.count, true)}
                      </span>{' '}
                      vehicle
                      {type.count === 1 ? '' : 's'}
                    </p>
                  ) : (
                    <div className="loader-filters" />
                  )}
                </div>
              ))}
            </Checkbox.Group>
          </Panel>
        )}
      </Collapse>
      <button
        type="button"
        className="my-5 bg-primary hover:bg-activePrimary flex justify-center text-base items-center p-2 w-full text-white"
        onClick={clearFilter}
      >
        Clear Filter
      </button>
    </div>
  )
}

FilterBuyCar.propTypes = {
  loading: PropTypes.bool,
  price: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }).isRequired,
  setPrice: PropTypes.func.isRequired,
  certified: PropTypes.array,
  setCertified: PropTypes.func.isRequired,
  year: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }).isRequired,
  setYear: PropTypes.func.isRequired,
  mileage: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }).isRequired,
  setMileage: PropTypes.func.isRequired,
  make: PropTypes.array.isRequired,
  setMake: PropTypes.func.isRequired,
  model: PropTypes.array.isRequired,
  setModel: PropTypes.func.isRequired,
  trim: PropTypes.array.isRequired,
  setTrim: PropTypes.func.isRequired,
  transmission: PropTypes.array.isRequired,
  setTransmission: PropTypes.func.isRequired,
  zipCode: PropTypes.any.isRequired,
  setZipCode: PropTypes.func.isRequired,
  zipRange: PropTypes.number,
  setZipRange: PropTypes.func,
  clearFilter: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  showOnlyFavoriteVehicles: PropTypes.bool.isRequired,
  setShowOnlyFavoriteVehicles: PropTypes.func.isRequired,
  favouriteCars: PropTypes.array.isRequired,
  buyCars: PropTypes.array.isRequired,
  oldBuyCarRef: PropTypes.object.isRequired,
  setBuyCars: PropTypes.func.isRequired,
  vehicleType: PropTypes.array,
  setVehicleType: PropTypes.func,
  marketPrice: PropTypes.array,
  setMarketPrice: PropTypes.func,
  color: PropTypes.array,
  setColor: PropTypes.func,
  showZipCodeNotiBox: PropTypes.bool,
  keyword: PropTypes.string,
  setKeyword: PropTypes.func,
  dealerId: PropTypes.number,
  reloadFilters: PropTypes.bool,
  setReloadFilters: PropTypes.func,
  query: PropTypes.string,
  similarDeal: PropTypes.array.isRequired,
  setSimilarDeal: PropTypes.func.isRequired
}

export default FilterBuyCar
