import HttpService from './httpClient.service'

export const storeLeadSendEmail = ({
  dealerId,
  firstName,
  lastName,
  interest,
  vehicleName,
  pageURL,
  zipcode,
  phone,
  email,
  comments,
  vehicleId,
  customerId,
  reason,
  leadId
}) => {
  return HttpService.post(leadId ? `upset-lead/${leadId}` : 'upset-lead', {
    interest,
    zipcode,
    phone,
    email,
    comments,
    page_url: pageURL,
    last_name: lastName,
    dealer_id: dealerId,
    first_name: firstName,
    vehicle_name: vehicleName,
    vehicle_id: vehicleId,
    customer_id: customerId,
    reason_submit_inquiry: reason
  }).then((result) => {
    const { data } = result
    return data
  })
}

export const checkExistLead = ({ interest, vehicleId, customerId, email }) => {
  return HttpService.post('check-exist-lead', {
    interest,
    vehicle_id: vehicleId,
    customer_id: customerId,
    email
  }).then((result) => {
    const { data } = result
    return data
  })
}
