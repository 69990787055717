import { Button, Radio } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useState } from 'react'
import { AiFillPhone, AiTwotoneMail } from 'react-icons/ai'
import { BiChevronLeft } from 'react-icons/bi'
import UploadingDealershipLogoComponent from 'src/components/UploadingDealershipLogoComponent'
import { VEHICLES_STOCK } from '../constants'

function DealershipInfo({
  dealershipDetail,
  nextStep,
  backStep,
  setDealershipInfo,
  triggerUploadDealershipLogo,
  setTriggerUploadDealershipLogo,
  dealerId
}) {
  // Step 3 data
  const [isNiadaMember, setIsNiadaMember] = useState()
  const [vehicleQuantity, setVehicleQuantity] = useState('')
  const { firstName, lastName, physicalAddress, city, state, zip, mobile, email } = dealershipDetail

  const onClickNext = () => {
    if (vehicleQuantity && vehicleQuantity)
      setDealershipInfo({
        isNiadaMember,
        vehicleQuantity
      })
    nextStep()
  }

  return (
    <div className="grid grid-cols-4 mt-24">
      <div className="md:col-start-2 md:col-span-3 col-span-12 flex flex-col md:justify-between md:items-center justify-start md:flex-row-reverse">
        <div className="text-lg flex flex-col p-6 bg-blue-100 bg-opacity-50 ">
          <div className="uppercase mb-2 text-gray-500 font-bold">Owner contact info</div>
          <div className="font-bold">{`${firstName} ${lastName}`}</div>
          <div>{physicalAddress}</div>
          <div>
            <span>{city}</span>
            <span className="ml-2">{state?.label || state}</span>
            <span className="ml-2">{zip}</span>
          </div>
          <div className="flex items-center">
            <AiFillPhone className="text-blue-500 mr-2" />
            {mobile}
          </div>
          <div className="flex items-center">
            <AiTwotoneMail className="text-blue-500 mr-2" />
            {email}
          </div>
        </div>
        <div>
          <div className="mb-8 mt-16 md:mt-0 text-lg">Are you a NIADA member?</div>
          <Radio.Group
            onChange={(e) => {
              setIsNiadaMember(e.target.value)
            }}
            value={isNiadaMember}
          >
            <div className="flex flex-col text-lg">
              <Radio value="yes" className="p-2">
                Yes
              </Radio>
              <Radio value="no" className="p-2">
                No
              </Radio>
            </div>
          </Radio.Group>
        </div>
      </div>

      <div className="md:col-start-2 md:col-span-3 col-span-12 mt-10">
        {isNiadaMember === 'no' && (
          <div className="mb-4 text-lg mr-2 font-bold">
            <p>No problem we’ve got you!</p>
            <p>Your NIADA dues are included with MotorMarketUSA</p>
          </div>
        )}
        <hr />
        <div className="mt-10">
          <div className="mb-8 text-lg">
            How many vehicles do you average in stock?<span className="text-red-500 font-bold ml-2">*</span>
          </div>
          <Radio.Group
            onChange={(e) => {
              setVehicleQuantity(e.target.value)
            }}
          >
            <div className="flex flex-col text-lg">
              {VEHICLES_STOCK.map((item) => (
                <Radio key={item.id} value={item.id} className="p-2">
                  {item.name}
                </Radio>
              ))}
            </div>
          </Radio.Group>
        </div>
        <div className="mt-10">
          <div className="mb-8 text-lg">Select dealership logo</div>
          <UploadingDealershipLogoComponent
            setTriggerUploadDealershipLogo={setTriggerUploadDealershipLogo}
            triggerUploadDealershipLogo={triggerUploadDealershipLogo}
            dealerId={dealerId}
          />
          <p className="w-1/2 italic text-xs">
            You can upload any standard image file <b>(JPG, PNG) for up to 2MB file size</b>.
          </p>
          <p className="w-1/2 italic text-xs">
            For optimal display of the logo, we recommend uploading a <b>square image</b>.
          </p>
        </div>
      </div>

      <div className="md:col-start-4 col-span-12 gap-5 flex justify-end items-center mt-12">
        <Button
          className="px-0 py-5 h-12 border-0 flex justify-center items-center gap-2 text-base font-bold text-blue-700"
          onClick={backStep}
        >
          <BiChevronLeft className="text-2xl" /> Back
        </Button>
        <Button
          type="primary"
          onClick={onClickNext}
          className="bg-blue-600 h-12 w-60 font-bold rounded-xl px-10"
          disabled={!vehicleQuantity || isNiadaMember === undefined}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

DealershipInfo.propTypes = {
  dealershipDetail: PropTypes.object.isRequired,
  setDealershipInfo: PropTypes.func,
  nextStep: PropTypes.func,
  backStep: PropTypes.func,
  triggerUploadDealershipLogo: PropTypes.bool,
  setTriggerUploadDealershipLogo: PropTypes.func,
  dealerId: PropTypes.number
}

export default DealershipInfo
