/* eslint-disable no-await-in-loop */
import { InboxOutlined } from '@ant-design/icons'
import { Button, message, Modal, notification, Upload } from 'antd'
import UploadList from 'antd/es/upload/UploadList'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { ReactSortable } from 'react-sortablejs'
import carImage from 'src/assets/placeholder_car.jpg'
import { useAuthorizationContext } from '../../../../AuthorizationProvider'
import * as vehicleService from '../../../../services/vehicle.service'
import './style.scss'

const { Dragger } = Upload

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!')
  }
  return isJpgOrPng
}

function DealerVehicleImage({ vehicleId, images }) {
  const { dealershipAccount } = useAuthorizationContext()
  const canEditInventory = undefined !== dealershipAccount ? dealershipAccount?.permissions?.edit_inventory : true
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [imageList, setImageList] = useState(images)
  const [refetchImageList, setRefetchImageList] = useState(false)

  const handleChange = async (data) => {
    const { fileList } = data
    const temp = []
    for (let index = 0; index < fileList.length; index +=1) {
      if (fileList[index].status === 'done' && fileList[index].response?.data) {
        const imageUrl = fileList[index].response?.data
        temp.push({ ...fileList[index], thumbUrl: imageUrl })
      } else if (fileList[index].status === 'uploading') {
        let imageUrl = ''
        if (fileList[index].thumbUrl !== null) {
          imageUrl = fileList[index].thumbUrl
        } else {
          imageUrl = await getBase64(fileList[index].originFileObj)
        }
        temp.push({ ...fileList[index], thumbUrl: imageUrl })
      } else {
        temp.push(fileList[index])
      }
    }
    setImageList(temp)
  }

  const handleCancel = () => {
    setPreviewVisible(false)
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      if (file.originFileObj) {
        file.preview = await getBase64(file.originFileObj)
      } else if (file.thumbUrl) {
        file.preview = file.thumbUrl
      }
    }

    if (file.thumbUrl) {
      file.url = file.thumbUrl
      file.preview = file.thumbUrl
    }

    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
  }

  const handleOnRemove = (file) => {
    vehicleService.removeImage(vehicleId, file)
  }

  const handleImageRemove = async (file) => {
    const oldFileList = imageList
    handleOnRemove(file)
    const newFileList = oldFileList.filter((item) => item.uid !== file.uid)
    setImageList(newFileList)
  }

  const order = {
    vehicle_id: vehicleId,
    order: imageList
  }

  useEffect(() => {
    if (images) {
      const tempImageList = images.map((item) => ({ thumbUrl: item, status: 'done' }))
      setImageList(tempImageList)
    } else {
      setImageList([])
    }
  }, [JSON.stringify(images)])

  const props = {
    action: `${process.env.REACT_APP_API_SERVER}/vehicle-store-image/${vehicleId}`
  }

  const handleOnSubmit = (event) => {
    event.preventDefault()

    vehicleService
      .setVehicleOrderImage(order)
      .then((res) => {
        if (res.isSuccessful === 'Yes') {
          notification.success({
            message: 'Updated successfully!',
            description: ''
          })
        }
      })
      .catch(() => {
        notification.error({
          message: 'Error!',
          description: 'Cannot complete this action. Please try again!'
        })
      })
  }

  return (
    <div>
      <div>
        <Carousel
          autoPlay
          infiniteLoop
          showArrows
          useKeyboardArrows
          showIndicators={false}
          showStatus={false}
          dynamicHeight={false}
          showThumbs
        >
          {imageList?.length ? (
            imageList?.map((image) => <img src={image.thumbUrl} alt="" key={`vehicle-image-${image.thumbUrl}`} />)
          ) : (
            <img src={carImage} alt="" key="vehicle-image-default" />
          )}
        </Carousel>
        {imageList && (
          <ReactSortable
            list={imageList}
            className="list-image flex flex-wrap"
            setList={(newState) => {
              setImageList(newState)
            }}
            onChange={() => setRefetchImageList(true)}
          >
            {imageList &&
              imageList?.map((item, idx) => (
                <UploadList
                  key={idx + 1}
                  locale={{ previewFile: 'Preview', removeFile: 'Remove' }}
                  showDownloadIcon={false}
                  listType="picture-card"
                  onPreview={handlePreview}
                  onRemove={handleImageRemove}
                  onChange={handleChange}
                  items={[item]}
                />
              ))}
          </ReactSortable>
        )}
      </div>
      <form onSubmit={handleOnSubmit}>
        {refetchImageList ? (
          <Button
            type="primary"
            htmlType="submit"
            className="w-48 px-6 py-5 h-12 rounded-lg flex justify-center items-center gap-2 text-base font-semibold text-white bg-blue-700"
          >
            Save Photo Order
          </Button>
        ) : null}
      </form>

      <Dragger
        {...props}
        name="file"
        listType="picture-card"
        className="avatar-uploader mt-4"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        onPreview={handlePreview}
        onRemove={handleOnRemove}
        multiple
        accept="image/jpeg, image/png ,image/jpg"
        fileList={imageList}
        disabled={!canEditInventory}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
      </Dragger>
      <Modal visible={previewVisible} title="Vehicle Image" footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  )
}

DealerVehicleImage.propTypes = {
  vehicleId: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string)
}

export default DealerVehicleImage
