import { CheckOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Popconfirm } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { maskNumberThousands } from 'src/utils'
import PaymentBreakDownModal from '../PaymentBreakDownModal'

function VehicleProtectionCard({ info, widthClass, financeCalculation }) {
  const [showPaymentTermsModal, setShowPaymentTermsModal] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    if (financeCalculation?.paymentMonthlyInteger > 0) {
      setLoading(false)
    }
  }, [financeCalculation, loading])

  const handlePaymentTermsModal = () => {
    setShowPaymentTermsModal(!showPaymentTermsModal)
  }

  const handleViewPaymentTerms = () => {
    setShowPaymentTermsModal(true)
  }

  const renderCard = useMemo(() => {
    if (info.status === 'completed' && info.type === 'paymentTerms') {
      return (
        <div
          className={`shadow-lg rounded-lg flex flex-col items-center overflow-hidden bg-white ${widthClass || 'w-32'}`}
        >
          <div className={`${loading ? '' : 'bg-dark'} flex justify-center items-center px-3 py-2 w-full`}>
            <p className="text-white text-xs font-semibold">{loading ? <span>&nbsp;</span> : `Completed`} </p>

            {!loading && (
              <div className="flex justify-center items-center w-5 h-5 bg-completedColor ml-1 rounded-full text-white">
                <CheckOutlined />
              </div>
            )}
          </div>
          <div className="flex flex-col items-center p-3">
            <p className="text-black font-bold text-base leading-5 text-center mb-3">{info.title}</p>
            <img src={info.image} alt="" className="mb-1" />
            <p className="text-sm mb-2">
              ${maskNumberThousands(financeCalculation?.paymentMonthlyInteger || 0, true)}/mo*
            </p>

            {!loading ? (
              <button
                type="button"
                onClick={() => handleViewPaymentTerms()}
                className="w-full flex justify-center items-center py-1 text-primary"
              >
                View
                <PlusCircleOutlined className="ml-2" />
              </button>
            ) : (
              <div>&nbsp;</div>
            )}
          </div>
        </div>
      )
    }
    if (info.status === 'completed' && info.type !== 'valueYourVehicle') {
      return (
        <div
          className={`shadow-lg rounded-lg flex flex-col items-center overflow-hidden bg-white ${widthClass || 'w-32'}`}
        >
          <div className="bg-dark flex justify-center items-center px-3 py-2 w-full">
            <p className="text-white text-xs font-semibold">Completed</p>
            <div className="flex justify-center items-center w-5 h-5 bg-completedColor ml-1 rounded-full text-white">
              <CheckOutlined />
            </div>
          </div>
          <div className="flex flex-col gap-2 items-center p-3">
            <p className="text-black font-bold text-base leading-5 text-center mb-3">{info.title}</p>
            <img src={info.image} alt="" className="mb-1" />
            <button
              type="button"
              className="w-full flex justify-center items-center py-1 text-primary border border-primary rounded-lg hover:bg-primary hover:text-white"
              onClick={info.onStepClick}
            >
              Complete
            </button>
          </div>
        </div>
      )
    }
    if (info.status === 'completed' && info.type === 'valueYourVehicle') {
      return (
        <div
          className={`shadow-lg rounded-lg flex flex-col items-center overflow-hidden bg-white ${widthClass || 'w-32'}`}
        >
          <div className="bg-dark flex justify-center items-center px-3 py-2 w-full">
            <p className="text-white text-xs font-semibold">Completed</p>
            <div className="flex justify-center items-center w-5 h-5 bg-completedColor ml-1 rounded-full text-white">
              <CheckOutlined />
            </div>
          </div>
          <div className="flex flex-col gap-2 items-center p-3">
            <p className="text-black font-bold text-base leading-5 text-center mb-3">{info.title}</p>
            <img src={info.image} alt="" className="mb-1" />
            <Popconfirm
              placement="topLeft"
              title={
                <div className="flex flex-col gap-2">
                  <div className="font-semibold">You have already added a trade-in vehicle for this Ez-Deal</div>
                  <div>Do you want to update the information?</div>
                </div>
              }
              onConfirm={info.confirmFunc}
              onCancel={info.cancelFunc}
              okText="Yes"
              cancelText="No, Just Show Details"
            >
              <button
                type="button"
                className="w-full flex justify-center items-center py-1 text-green-500 rounded-lg hover:bg-primary hover:text-white"
              >
                Trade-in added
              </button>
            </Popconfirm>
          </div>
        </div>
      )
    }
    if (info.isRecommended) {
      return (
        <div
          className={`shadow-lg rounded-lg flex flex-col items-center overflow-hidden bg-white ${widthClass || 'w-72'}`}
        >
          <div className="bg-primary px-3 py-2 w-full">
            <p className="text-white text-sm font-semibold">Recommended Next Step</p>
          </div>
          <div className="flex flex-col p-4">
            <p className="text-black text-lg font-semibold mb-2">Value Your Vehicle</p>
            <p className="text-sm mb-7">
              Use Kelley Blue Book® to see what your current car is worth towards your new vehicle.
            </p>
            <div className="flex justify-between">
              <button
                type="button"
                className="w-7/12 bg-primary rounded-lg text-white text-sm py-2 hover:bg-activePrimary"
                onClick={info.onStepClick}
              >
                Start
              </button>
              <img src={info.image} alt="" className="w-[30px]" />
            </div>
          </div>
        </div>
      )
    }
    return (
      <div
        className={`shadow-lg rounded-lg flex flex-col items-center overflow-hidden bg-white py-4 px-3 ${
          widthClass || 'w-32'
        }`}
      >
        <p className="text-black font-bold text-base text-center">{info.title}</p>
        <img src={info.image} alt="" className="my-7" />
        <button
          type="button"
          className="w-full flex justify-center items-center py-1 text-primary border border-primary rounded-lg hover:bg-primary hover:text-white"
          onClick={info.onStepClick}
        >
          {info.status === 'finish_up' ? 'Finish up' : 'Start'}
        </button>
      </div>
    )
  }, [info])
  return (
    <>
      {renderCard}
      <PaymentBreakDownModal
        showModal={showPaymentTermsModal}
        handleCloseModal={handlePaymentTermsModal}
        financeCalculation={financeCalculation}
      />
    </>
  )
}

VehicleProtectionCard.propTypes = {
  info: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    type: PropTypes.string,
    buttonLabel: PropTypes.string,
    step: PropTypes.number,
    status: PropTypes.string,
    isRecommended: PropTypes.bool,
    onStepClick: PropTypes.func,
    confirmFunc: PropTypes.func,
    cancelFunc: PropTypes.func
  }).isRequired,
  widthClass: PropTypes.string,
  financeCalculation: PropTypes.object
}

export default VehicleProtectionCard
