import { Button, Divider, Modal, Tooltip } from 'antd'
import parse from 'html-react-parser'
import * as PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { BsFillInfoCircleFill } from 'react-icons/bs'
import { FaAngleDown, FaAngleRight, FaAngleUp } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import userAvator from 'src/assets/icon/userAvator.png'
import { phoneFormat } from 'src/utils'
import { dealershipHasCPop } from '../../../services/dealer.service'
import * as notificationService from '../../../services/notification.service'
import { SendEmailModal } from '../SendEmailModal'
import './style.scss'

export function CustomerModal({ offer, showModal, handleCloseModal, dealershipAccount, handleCallback, fetchData }) {
  const navigate = useNavigate()
  const [customerAvatar, setCustomerAvatar] = useState('')
  const [dealSource, setDealSource] = useState('')
  const [customerEmail, setCustomerEmail] = useState('')
  const [customerPhone, setCustomerPhone] = useState('')
  const [cPopId, setCPopId] = useState('')
  const [lastMessage, setLastMessage] = useState('')
  const [lastMessageDate, setLastMessageDate] = useState('')

  const [notifications, setNotifications] = useState([])
  const [showMoreMessages, setShowMoreMessages] = useState(false)
  const [showAllMessages, setShowAllMessages] = useState(false)
  const [messageHistory, setMessageHistory] = useState([])

  const [moreMessages, setMoreMessages] = useState(false)
  const [allMessages, setAllMessages] = useState(false)

  const [showEmailSendingModal, setShowEmailSendingModal] = useState(false)
  const canTrackEmails = undefined !== dealershipAccount ? dealershipAccount?.email_tracking : true

  const [refresh, setRefresh] = useState(false)

  const handleShowMoreMessages = () => {
    setShowMoreMessages(!showMoreMessages)
    setShowAllMessages(true)
    setMoreMessages(true)
    setAllMessages(false)
  }

  const handleShowLessMessages = () => {
    handleShowMoreMessages()
    setShowAllMessages(false)
    setMoreMessages(false)
    setAllMessages(false)
  }

  const handleShowAllMessages = () => {
    setShowAllMessages(!showAllMessages)
    setShowMoreMessages(true)
    setAllMessages(true)
    setMoreMessages(false)
  }

  const handleHasCPop = (recordId, recordType) => {
    dealershipHasCPop(recordId, recordType)
      .then((result) => {
        setCPopId(result?.data?._id || '')
      })
      .catch(() => {
        setCPopId('')
      })
  }

  const handleHistoryNotifications = (recordId, recordType) => {
    notificationService
      .historyNotificationsEmail(recordId, recordType)
      .then((result) => {
        setNotifications(result.data)
      })
      .catch((e) => {
        console.error(e.message)
      })
  }

  useEffect(() => {
    const useForEmptyData = '---'

    setCustomerEmail(offer?.email)

    if (offer?.customerAvatar !== '') {
      setCustomerAvatar(offer?.customerAvatar)
    } else {
      setCustomerAvatar(userAvator)
    }

    if (offer?.type !== '') {
      setDealSource(offer?.type)
    } else {
      setDealSource(useForEmptyData)
    }

    if (offer?.phone !== '') {
      setCustomerPhone(phoneFormat(offer?.phone))
    } else {
      setCustomerPhone(useForEmptyData)
    }

    if (offer?.lastMessage !== '') {
      setLastMessage(offer?.lastMessage)
    }

    if (offer?.lastMessageDate !== '') {
      setLastMessageDate(offer?.lastMessageDate)
    }

    const { record_id: recordId, record_type: recordType } = offer || {}
    if (recordId !== '' && recordId !== undefined && recordType !== '' && recordType !== undefined) {
      handleHasCPop(recordId, recordType)
      handleHistoryNotifications(recordId, recordType)
    }
  }, [offer, refresh])

  useEffect(() => {
    let initialData = []
    if (notifications.length > 0) {
      if (moreMessages) {
        initialData = notifications
          .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
          .filter((_, idx) => idx < 5)
        setMessageHistory(initialData)
      }
      if (allMessages) {
        initialData = notifications.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
        setMessageHistory(initialData)
      }
    } else {
      setMessageHistory([])
    }
  }, [notifications, moreMessages, allMessages])

  const renderMessage = useCallback((email) => {
    const { message, shared_vehicles: sharedLinks } = email

    if (sharedLinks?.length > 0) {
      const elementSharedLinks = sharedLinks?.map((item) => (
        <a href={item.url} className="text-blue-500 mr-1">
          <span>{item.name};</span>
        </a>
      ))
      return (
        <>
          {message && (
            <div className="gap-1">
              <span className="font-semibold">Message: </span>
              <span>{message}</span>
            </div>
          )}
          {elementSharedLinks && (
            <div className="gap-1">
              <span className="mr-1 font-semibold">Shared vehicles:</span>
              {elementSharedLinks}
            </div>
          )}
        </>
      )
    }
    return <div>{parse(message)}</div>
  }, [])

  const handleClickCPOP = () => {
    if (offer?.cPopId) {
      navigate(`/c-pop/${offer?.cPopId}?review=yes`)
    }
  }

  const handleClickEmail = () => {
    setShowEmailSendingModal(true)
    handleCloseModal()
  }

  const handleEmailSendingModal = () => {
    setShowEmailSendingModal(!showEmailSendingModal)
  }

  const handleCustomerModalCallback = () => {
    setRefresh(true)
    handleCallback()
  }

  return (
    <>
      <Modal
        centered
        visible={showModal}
        title=""
        onOk={handleCloseModal}
        onCancel={() => {
          handleCloseModal()
          setShowMoreMessages(false)
          setShowAllMessages(false)
          setMessageHistory([])
          setMoreMessages(false)
          setAllMessages(false)
        }}
        okText="Close"
        className="calendar-modal customer-modal rounded-3xl"
        key="customer-modal"
        destroyOnClose
        width={600}
        footer={[
          <div className="customer-modal-actions flex grid grid-cols-12 gap-3 mb-5">
            <div className="col-span-4" />
            <div className="col-span-8">
              <div className="flex grid grid-cols-12">
                <div className="col-span-6 flex justify-start">
                  {!showAllMessages && (
                    <Button className="uppercase" type="text" size="small" onClick={handleShowAllMessages}>
                      <p className="font-bold flex items-center gap-1 uppercase">
                        <span>All Messages</span>
                        <FaAngleRight size={14} />
                      </p>
                    </Button>
                  )}
                </div>
                <div className="col-span-6">
                  {showMoreMessages && (
                    <Button type="text" size="small" onClick={handleShowLessMessages}>
                      <p className="font-bold flex items-center gap-1">
                        <span>Show less</span>
                        <FaAngleUp className="pt-1" size={17} />
                      </p>
                    </Button>
                  )}
                  {!showMoreMessages && (
                    <Button type="text" size="small" onClick={handleShowMoreMessages}>
                      <p className="font-bold flex items-center gap-1">
                        <span>Show more</span>
                        <FaAngleDown className="pt-1" size={17} />
                      </p>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ]}
      >
        <div className="flex grid grid-cols-12 gap-3">
          <div className="col-span-4 customer-avatar">
            <img className="w-40 border border-gray-200" src={customerAvatar} alt="HL" />
          </div>
          <div className="col-span-8 customer-details">
            <div className="mb-2">
              <span className="field-name flex items-top close-line">Customer Name</span>
              <p className="field-value customer-name close-line">{offer?.customer}</p>
            </div>
            <div className="flex grid grid-cols-12 gap-3 mb-2">
              <div className="col-span-5">
                <span className="field-name close-line">Source</span>
                <p className="field-value close-line">{dealSource}</p>
              </div>
              <div className="col-span-7">
                <div className="field-name close-line flex items-center gap-1">
                  <Tooltip className="c-pop-tooltip" title="Customer Personal Offer Page" placement="bottomLeft">
                    <BsFillInfoCircleFill size={15} color="gray" />
                  </Tooltip>
                  <p
                    className="font-bold flex items-center text-blue-500 cursor-pointer gap-1"
                    onClick={handleClickCPOP}
                    target="_blank"
                  >
                    <span>{offer?.cPopId ? 'C-POP (Y)' : 'C-POP'}</span>
                    <FaAngleRight size={14} />
                  </p>
                </div>
                <p className="field-value close-line flex items-center">
                  {offer?.visits} Visits | Last: on {offer?.lastVisit}
                </p>
              </div>
            </div>
            <div className="flex grid grid-cols-12 gap-3 mb-4">
              <div className="col-span-8">
                <span className="field-name close-line">Email</span>
                {canTrackEmails ? (
                  <p className="field-value close-line text-blue-500 cursor-pointer" onClick={handleClickEmail}>
                    {customerEmail}
                  </p>
                ) : (
                  <p className="field-value close-line">{customerEmail}</p>
                )}
              </div>
              <div className="col-span-4">
                <span className="field-name close-line">Phone</span>
                <p className="field-value close-line">{customerPhone}</p>
              </div>
            </div>
            {!showMoreMessages && !showAllMessages ? (
              [
                lastMessageDate && lastMessage ? (
                  <>
                    <div key="last-message-label" className="flex grid grid-cols-12 gap-3">
                      <div className="col-span-6">
                        <span className="field-name close-line">Last message</span>
                      </div>
                      <div className="col-span-6 flex items-center justify-end">
                        <div className="field-name close-line">{lastMessageDate}</div>
                      </div>
                    </div>
                    <div key="last-message-message" className="flex grid grid-cols-12 gap-3">
                      <div className="col-span-12 field-value close-line">{parse(lastMessage)}</div>
                    </div>
                  </>
                ) : (
                  <div key="no-last-message" className="flex grid grid-cols-12 gap-3">
                    <div className="col-span-12">
                      <span className="field-name close-line">There is no email history for this customer</span>
                    </div>
                  </div>
                )
              ]
            ) : (
              <>
                {messageHistory?.length === 0 && (
                  <div key="no-message-history" className="flex grid grid-cols-12 gap-3">
                    <div className="col-span-12">
                      <span className="field-name close-line">There is no email history for this customer</span>
                    </div>
                  </div>
                )}
                {messageHistory?.length > 0 && (
                  <div key="message-history-section" className="flex grid grid-cols-12 gap-3">
                    <div className="col-span-6">
                      <span className="field-name">Messages:</span>
                    </div>
                  </div>
                )}
                {messageHistory?.length > 0 &&
                  messageHistory?.map((n, index) => (
                    <>
                      <div key={`message-history-${index}`}>
                        <div className="flex grid grid-cols-12 gap-3">
                          <div className="col-span-6">
                            {n.name ? (
                              <span className="field-name dealership font-bold close-line">{n.name}:</span>
                            ) : (
                              <span className="field-name customer font-bold close-line">{offer?.customer}:</span>
                            )}
                          </div>
                          <div className="col-span-6 flex items-center justify-end">
                            <span className="field-name close-line">{n.createdAtFormatted}</span>
                          </div>
                        </div>
                        <div className="flex grid grid-cols-12 gap-3">
                          <div className="col-span-12 field-value close-line-2">
                            {n.subject && (
                              <>
                                <span className="font-semibold">Subject:</span>
                                <span>{n.subject}</span>
                              </>
                            )}
                            {renderMessage(n)}
                          </div>
                        </div>
                      </div>
                      {index < messageHistory.length - 1 && (
                        <Divider key={`message-history-divider-${index}`} className="my-3" />
                      )}
                    </>
                  ))}
              </>
            )}
          </div>
        </div>
      </Modal>
      <SendEmailModal
        offer={offer}
        key="send-email-modal"
        showModal={showEmailSendingModal}
        notifications={notifications}
        handleCloseModal={handleEmailSendingModal}
        dealershipAccount={dealershipAccount}
        handleCallback={handleCustomerModalCallback}
        fetchData={fetchData}
        handleHistoryNotifications={handleHistoryNotifications}
        cPopId={cPopId}
        useFor="all"
      />
    </>
  )
}

CustomerModal.propTypes = {
  offer: PropTypes.object,
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  dealershipAccount: PropTypes.object,
  handleCallback: PropTypes.func,
  fetchData: PropTypes.func
}
