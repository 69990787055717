import axios from 'axios'
import { STORAGE_KEYS } from 'src/constants/constants'
import { load } from 'src/lib/storage'

const baseURL = process.env.REACT_APP_API_SERVER

const httpClient = axios.create({ baseURL: `${baseURL}/` })

httpClient.interceptors.request.use(async (request) => {
  const accessToken = load(STORAGE_KEYS.ACCESS_TOKEN, false)
  const tokenType = load(STORAGE_KEYS.TOKEN_TYPE, false)
  if (accessToken) {
    return {
      ...request,
      headers: {
        ...request.headers,
        authorization: `${tokenType} ${accessToken}`
      }
    }
  }
  return request
})

function get(url, headers = {}, params = {}) {
  return httpClient.get(url, {
    ...params,
    headers: { ...headers }
  })
}

function post(url, data, headers = {}, params = {}) {
  return httpClient.post(url, data, {
    ...params,
    headers: { ...headers }
  })
}

function put(url, data, headers = {}) {
  return httpClient.put(url, data, {
    headers: { ...headers }
  })
}

function remove(url, data, headers = {}) {
  return httpClient.delete(url, {
    headers: { ...headers },
    data
  })
}

export default {
  httpClient,
  get,
  post,
  put,
  remove
}
