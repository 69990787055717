import React, { useEffect, useState } from 'react'
import { AiFillStar } from 'react-icons/ai'
import { useParams } from 'react-router-dom'
import * as DealerService from 'src/services/dealer.service'

function NearbyDealership() {
  const { id } = useParams()
  const [nearbyDealers, setNearbyDealers] = useState([])

  useEffect(() => {
    DealerService.getNearbyDealers(id)
      .then((response) => {
        setNearbyDealers(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  if (nearbyDealers?.length > 0) {
    return (
      <div>
        <span className="text-xl">Other Nearby Dealers</span>
        <div className="grid md:grid-cols-3 sm:grid-cols-1 my-5 max-w-4xl">
          {nearbyDealers?.map((item, index) => (
            <div className="mb-5" key={item._id}>
              <div>
                <a
                  target="_blank"
                  href={`/dealership/${item._id}`}
                  title={item.dealer_name}
                  className="text-blue-700"
                  rel="noreferrer"
                >
                  {item.dealer_name}
                </a>
                <span>{` - ${index + Math.round(Math.random() * 10)} listings`}</span>
              </div>
              <div>
                <span>{item.address}</span>
                <div className="cg-rating-wrap clearfix">
                  <div className="ratingValue">
                    <div className="flex gap-1 items-center">
                      <AiFillStar color="orange" />
                      <AiFillStar color="orange" />
                      <AiFillStar color="orange" />
                      <AiFillStar color="orange" />
                      <AiFillStar color="orange" />
                      <span className="totalRatings">{`${Math.round(Math.random() * 10)} reviews`}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return null
}

export default NearbyDealership
