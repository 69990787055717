import { LeftOutlined } from '@ant-design/icons'
import moment from 'moment'
import * as PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import SavedOffersTable from 'src/components/SavedOffersTable'
import { KBB_VEHICLE_TYPE, STORAGE_KEYS } from 'src/constants/constants'
import { load, save } from 'src/lib/storage'
import * as CreditApplicationForm from 'src/services/creditApplicationForm.service'
import * as CustomerServices from 'src/services/customer.service'
import bookMarkImage from '../../../assets/finance/bookmark.png'
import dealImage from '../../../assets/finance/deal.png'
import financingImage from '../../../assets/finance/financing.png'
import gearsImage from '../../../assets/finance/gears.png'
import paymentImage from '../../../assets/finance/payment.png'
import scheduleImage from '../../../assets/finance/schedule.png'
import vehicleImage from '../../../assets/finance/vehicle.png'
import kelleyImage from '../../../assets/kbb-logo.png'
import CreditFinance from '../CreditFinance'
import ConfirmCreditApplicationModal from '../CreditFinance/ConfirmCreditApplicationModal'
import FinishDealStep1 from '../FinishDeal/finishDealStep1'
import FinishDealStepFinal from '../FinishDeal/finishDealStepFinal'
import ScheduleDriveStep1 from '../ScheduleDrive/scheduleDriveStep1'
import ScheduleDriveStep2 from '../ScheduleDrive/scheduleDriveStep2'
import ScheduleDriveStepFinal from '../ScheduleDrive/scheduleDriveStepFinal'
import VehicleFinanceStep1 from '../VehicleFinanceSteps/step1'
import VehicleFinanceStep2 from '../VehicleFinanceSteps/step2'
import VehicleFinanceStep3 from '../VehicleFinanceSteps/step3'
import VehicleFinanceStep4 from '../VehicleFinanceSteps/step4'
import VehicleFinanceStep5 from '../VehicleFinanceSteps/step5'
import VehicleFinanceStepFinal from '../VehicleFinanceSteps/stepFinal'
import TradeInValue from '../VehicleFinanceSteps/tradeInValue'
import VehicleMoreDetails from '../VehicleMoreDetails'
import VehicleProtectionCard from '../VehicleProtectionCard'
import './style.scss'

function VehicleFinanceMobileModal({ info, initStep, setInitStep, calculation, handleCancel }) {
  const { customerAccount, customerLogin } = useAuthorizationContext()
  const [step, setStep] = useState(initStep ?? -1)
  const [rowData, setRowData] = useState({})
  const vehicleType = KBB_VEHICLE_TYPE.AUTOMOTIVE
  const [status, setStatus] = useState('')
  const [callInitialApi, setCallInitialApi] = useState(false)
  const [yearId, setYearId] = useState('')
  const [makeObject, setMakeObject] = useState({
    makeId: '',
    make: ''
  })
  const [modelObject, setModelObject] = useState({
    modelId: '',
    model: ''
  })
  const [trimId, setTrimId] = useState('')
  const [ownership, setOwnership] = useState('')
  const [vin, setVin] = useState('')
  const [mileage, setMiliage] = useState()
  const [zipCode, setZipCode] = useState()
  const [active, setActive] = useState('no')
  const [debit, setDebit] = useState()
  const [selectedCondition, setSelectedCondition] = useState()
  const [engine, setEngine] = useState()
  const [transmission, setTransmission] = useState()
  const [color, setColor] = useState()
  const [userObject, setUserObject] = useState({})
  const [vehicles, setVehicles] = useState({})
  const [tradeVehicleInfo, setTradeVehicleInfo] = useState({ tradeIn: 0 })
  const [isReadLess, setIsReadLess] = useState(true)
  const [time, setTime] = useState('evening')
  const [selectedData, setSelectedData] = useState(moment())
  const [listYears, setListYears] = useState([])
  const [makeList, setMakeList] = useState([])
  const [modelList, setModelList] = useState([])
  const [trimList, setTrimList] = useState([])
  const [finalConfiguration, setFinalConfiguration] = useState({})
  const [scheduleTestDriveData, setScheduleTestDriveData] = useState({})
  const [selectedOffer, setSelectedOffer] = useState({})
  const [selectedOtherColor, setSelectedOtherColor] = useState(false)
  const [valueYourVehicleStepStatus, setValueYourVehicleStepStatus] = useState('start')
  const {
    financing_step_status: financingStepStatus,
    schedule_test_drive_step_status: scheduleTestDriveStepStatus,
    finish_your_deal_step_status: finishYourDealStepStatus
  } = selectedOffer
  const [tradeId, setTradeId] = useState()
  const customerTradeIns = load(STORAGE_KEYS.CUSTOMER_TRADE_INS) || []
  const [tradeInValue, setTradeInValue] = useState('')

  const [showFinanceConfirmModal, setShowFinanceConfirmModal] = useState(false)
  const [creditApps, setCreditApps] = useState([])
  const [applyForFinancingCompleted, setApplyForFinancingCompleted] = useState(false)
  const [initialCreditAppData, setInitialCreditAppData] = useState({})
  const [creditAppDetail, setCreditAppDetail] = useState({})
  const [initialFinanceStep, setInitialFinanceStep] = useState(1)
  const [selectedTrade, setSelectedTrade] = useState({})

  useEffect(() => {
    return () => setInitStep(-1)
  }, [])

  useEffect(() => {
    if (customerLogin) {
      CustomerServices.getCustomerDeals()
        .then((response) => {
          setCreditApps(response.data.records)
          if (response?.data?.records) {
            response?.data?.records.forEach((record) => {
              if (info._id === record._id) {
                setApplyForFinancingCompleted(true)
              }
            })
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [customerLogin])

  useEffect(() => {
    if (initialCreditAppData._id) {
      CreditApplicationForm.getCreditApplicationFormById(initialCreditAppData._id)
        .then((response) => {
          setCreditAppDetail(response.data)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [initialCreditAppData._id])

  useEffect(() => {
    if (customerLogin && info._id && (step === -1 || (initStep !== undefined && initStep === 15))) {
      CustomerServices.checkExistingTradeIn(info._id)
        .then((response) => {
          setValueYourVehicleStepStatus('completed')
          setSelectedTrade(response.data)
        })
        .catch((error) => {
          console.error(error)
          setValueYourVehicleStepStatus('start')
        })
    }
  }, [customerLogin, info._id, step])

  useEffect(() => {
    if (selectedTrade?.vehicle && selectedTrade?.customer) {
      const { vehicle, customer } = selectedTrade
      const { color, condition, makeId, make, mileage, modelId, model, ownership, trimId, year, zipcode } = vehicle
      const { email, firstname, lastname, phone } = customer
      setYearId(year)
      setMakeObject({
        makeId,
        make
      })
      setModelObject({
        modelId,
        model
      })
      setTrimId(trimId)
      setOwnership(ownership)
      setMiliage(mileage)
      setZipCode(zipcode)
      setUserObject({
        email,
        phone,
        firstName: firstname,
        lastName: lastname
      })
      setColor(color)
      setSelectedCondition(condition)
      setCallInitialApi(true)
    }
  }, [JSON.stringify(selectedTrade)])

  const nextStep = () => {
    setStep(step + 1)
  }

  const backStep = () => {
    setStep(step - 1)
  }

  const startStep = () => {
    setStatus('started')
    window.setTimeout(() => {
      nextStep()
    }, 3200)

    window.setTimeout(() => {
      setStatus('finished')
    }, 4000)
  }

  const resetStep = () => {
    setStatus('back')
    window.setTimeout(() => {
      setStep(-1)
    }, 2000)

    window.setTimeout(() => {
      setStatus('')
    }, 4000)
  }

  useEffect(() => {
    if (initStep === undefined || initStep !== 15) {
      setStep(-1)
      setStatus('')
    }
  }, [])

  const handleProtection = (type, nextStep) => {
    const types = ['valueYourVehicle', 'schedule', 'deal', 'financing']
    if (types.includes(type)) {
      setStatus('started')
      window.setTimeout(() => {
        setStep(nextStep)
      }, 3200)

      window.setTimeout(() => {
        setStatus('finished')
      }, 4000)
    }
  }

  const nextScheduleFormStep = () => {
    setStep(11)
  }

  const nextScheduleFinal = () => {
    setStep(12)
  }

  const nextFinishDealFormStep = () => {
    setStep(16)
  }

  const startFinishYourDeal = () => {
    setStep(15)
  }

  const startAutomotiveKBB = () => {
    setStep(0)
  }

  const handleSubmitTrade = (usrObj) => {
    if (vehicles && vehicles[0]) {
      const { trimName } = vehicles[0]

      let estimatedvalue = tradeVehicleInfo.tradeIn || 0
      if (estimatedvalue < 1 && usrObj.tradeIn !== undefined && usrObj.tradeIn > 0) {
        estimatedvalue = usrObj.tradeIn
      }

      CustomerServices.handleSaveTrade({
        vin,
        make: makeObject.make,
        makeId: makeObject.makeId,
        model: modelObject.model,
        modelId: modelObject.modelId,
        year: yearId,
        trim: trimName,
        trimId,
        ownership,
        mileage,
        zipcode: zipCode,
        owePrice: undefined,
        condition: selectedCondition,
        engine,
        transmission,
        color,
        firstName: usrObj.firstName || userObject.firstName,
        lastName: usrObj.lastName || userObject.lastName,
        email: usrObj.email || userObject.email,
        phone: usrObj.phone || userObject.phone,
        comment: usrObj.comments || userObject.comments,
        estimatedvalue,
        notLoggedIn: !customerAccount.id
      }).then((response) => {
        setTradeId(response.data._id)
        if (!customerAccount._id) {
          const tradeIns = customerTradeIns
          tradeIns.push(response.data._id)
          save(STORAGE_KEYS.CUSTOMER_TRADE_INS, JSON.stringify(tradeIns))
        }
      })
    }
  }

  useEffect(() => {
    if (info._id && step < 0 && (initStep === undefined || initStep !== 15)) {
      CustomerServices.getLeadTestDriveByVehicleId(info._id)
        .then((response) => {
          const { test_time: testTime, test_date: testDate } = response.data
          setTime(testTime)
          setSelectedData(moment(testDate))
          setScheduleTestDriveData(response.data)
        })
        .catch((error) => {
          console.error(error)
        })
      CustomerServices.getCustomerOfferByVehicleId(info._id)
        .then((response) => {
          setSelectedOffer(response.data)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [info, step])

  const startCards = useMemo(() => {
    return [
      {
        title: 'Payment Terms',
        image: paymentImage,
        type: 'paymentTerms',
        status: 'completed',
        onStepClick: () => {}
      },
      {
        title: 'Value Your Vehicle',
        image: vehicleImage,
        type: 'valueYourVehicle',
        status: valueYourVehicleStepStatus,
        isRecommended: true,
        step: 0,
        confirmFunc: () => handleProtection('valueYourVehicle', 1),
        cancelFunc: handleCancel,
        onStepClick: () => handleProtection('valueYourVehicle', 0)
      },
      {
        title: 'Accessories',
        image: gearsImage,
        type: 'accessories',
        status: 'start'
      },
      {
        title: 'Apply for Financing',
        image: financingImage,
        type: 'financing',
        status: financingStepStatus,
        step: 8,
        onStepClick: () => {
          if (customerLogin && creditApps.length > 0 && applyForFinancingCompleted) {
            setShowFinanceConfirmModal(true)
          } else {
            handleProtection('financing', 8)
          }
        }
      },
      {
        title: 'Schedule Test Drive',
        image: scheduleImage,
        type: 'schedule',
        status: scheduleTestDriveStepStatus,
        onStepClick: () => {
          if (scheduleTestDriveStepStatus === 'finish_up') {
            handleProtection('schedule', 11)
          } else if (scheduleTestDriveStepStatus === 'completed') {
            handleProtection('schedule', 12)
          } else {
            handleProtection('schedule', 10)
          }
        },
        step: 10
      },
      {
        title: 'Finish Your Deal',
        image: dealImage,
        type: 'deal',
        status: finishYourDealStepStatus,
        step: 15,
        onStepClick: () => handleProtection('deal', 15)
      }
    ]
  }, [valueYourVehicleStepStatus, financingStepStatus, scheduleTestDriveStepStatus, finishYourDealStepStatus, applyForFinancingCompleted])

  const changeShowConfirmFinanceModal = () => {
    setShowFinanceConfirmModal(!showFinanceConfirmModal)
  }

  return (
    <>
      {step === -1 && (
        <div className="py-8 px-12 border-b border-lightGray">
          <div className="lg:grid grid-cols-12 gap-5 md:block">
            <div className="col-span-12">
              <div className="flex justify-around items-center flex-wrap gap-5">
                {startCards.map((card, index) => (
                  <VehicleProtectionCard
                    info={card}
                    handleProtection={handleProtection}
                    key={`protection-card-${index}`}
                    widthClass="w-80"
                  />
                ))}
              </div>
            </div>
            <div className="col-span-3 py-3 pb-8">
              <div className="border-l border-darkGray flex flex-col items-center px-6 py-4 h-full justify-center">
                <img src={bookMarkImage} alt="" className="mb-2" />
                <p className="text-black font-semibold mb-2">Save Your Progress</p>
                <p className="text-gray-600 text-center text-xs mb-3">
                  Use a custom link to return to your deal anytime, anywhere
                </p>
                <p className="cursor-pointer text-primary text-sm">Get My Deal Link</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`bg-primary w-full flex justify-center absolute left-0 z-20 bottom-0 ${
          status !== '' ? 'vehicle-finance-start-footer' : ''
        } ${status === 'back' ? 'vehicle-finance-back-footer' : ''}`}
      >
        {step !== -1 && (
          <button
            onClick={resetStep}
            type="button"
            className="absolute left-0 top-0 bg-darkPrimary flex items-center px-3 py-2 text-white rounded-br-lg border-b border-r border-white"
          >
            <LeftOutlined className="text-xl" />
            <div className="ml-2">
              <p className="text-xs">Back to My Deal</p>
            </div>
          </button>
        )}
        <div className="grid">
          {/* <div className="bg-blue pb-12">
            <FinanceDetail
              info={info}
              status={status}
              type={type}
              tradeVehicleInfo={tradeVehicleInfo}
              step={step}
              vehicleType={vehicleType}
              cashDown={cashDown}
            />
          </div> */}
          {step === -1 && <VehicleMoreDetails info={{ ...info, ...rowData }} status={status} />}
        </div>
      </div>
      {step === -1 && (
        <div className="mb-48">
          <SavedOffersTable
            selectedTrade={selectedTrade}
            setSelectedTrade={setSelectedTrade}
            vehicleId={info?._id}
            selectedOffer={selectedOffer}
            startStep={startStep}
            setRowData={(data) => setRowData(data)}
            setValueYourVehicleStepStatus={setValueYourVehicleStepStatus}
          />
        </div>
      )}
      <div className="pt-28 step-wrapper">
        <div className="flex justify-center">
          {step !== -1 && (
            <div className="w-11/12 xl:w-7/12 lg:w-7/12 md:w-9/12 sm:w-10/12">
              {step < 5 && (
                <>
                  <div className="flex mb-3">
                    <div className="flex-1 step-title-wrapper">
                      <p className="text-base text-primary font-bold">Search</p>
                    </div>
                    <div className="flex-1 step-title-wrapper">
                      <p className="text-base text-primary font-bold">Details</p>
                    </div>
                    <div className="flex-1 step-title-wrapper">
                      <p className="text-base text-primary font-bold">Conditions</p>
                    </div>
                    <div className="flex-1 step-title-wrapper">
                      <p className="text-base text-primary font-bold">Vehicle</p>
                    </div>
                    <div className="flex-1 step-title-wrapper">
                      <p className="text-base text-primary font-bold">Equipment</p>
                    </div>
                  </div>
                  <div className="bg-gray-200 h-1 w-full">
                    <div
                      className="h-full bg-info rounded transition-all duration-300"
                      style={{ width: `${((step + 1) * 100) / 5}%` }}
                    />
                  </div>
                </>
              )}

              {step === 0 && (
                <VehicleFinanceStep1
                  nextStep={nextStep}
                  setYearId={setYearId}
                  setMakeObject={setMakeObject}
                  setModelObject={setModelObject}
                  setTrimId={setTrimId}
                  setCallInitialApi={setCallInitialApi}
                  vehicleType={vehicleType}
                />
              )}
              {step === 1 && (
                <VehicleFinanceStep2
                  nextStep={nextStep}
                  backStep={backStep}
                  vehicleType={vehicleType}
                  yearId={yearId}
                  setYearId={setYearId}
                  setMakeObject={setMakeObject}
                  makeObject={makeObject}
                  setModelObject={setModelObject}
                  modelObject={modelObject}
                  setTrimId={setTrimId}
                  trimId={trimId}
                  ownership={ownership}
                  setOwnership={setOwnership}
                  vin={vin}
                  setVin={setVin}
                  mileage={mileage}
                  setMiliage={setMiliage}
                  zipCode={zipCode}
                  setZipCode={setZipCode}
                  active={active}
                  setActive={setActive}
                  debit={debit}
                  setDebit={setDebit}
                  callInitialApi={callInitialApi}
                  setCallInitialApi={setCallInitialApi}
                  vehicles={vehicles}
                  setVehicles={setVehicles}
                  setTradeVehicleInfo={setTradeVehicleInfo}
                  listYears={listYears}
                  setListYears={setListYears}
                  makeList={makeList}
                  setMakeList={setMakeList}
                  modelList={modelList}
                  setModelList={setModelList}
                  trimList={trimList}
                  setTrimList={setTrimList}
                />
              )}
              {step === 2 && (
                <VehicleFinanceStep3
                  nextStep={nextStep}
                  backStep={backStep}
                  selectedCondition={selectedCondition}
                  setSelectedCondition={setSelectedCondition}
                  tradeVehicleInfo={tradeVehicleInfo}
                />
              )}
              {step === 3 && (
                <VehicleFinanceStep4
                  nextStep={nextStep}
                  backStep={backStep}
                  engine={engine}
                  setEngine={setEngine}
                  transmission={transmission}
                  setTransmission={setTransmission}
                  color={color}
                  setColor={setColor}
                  tradeVehicleInfo={tradeVehicleInfo}
                  setSelectedOtherColor={setSelectedOtherColor}
                  selectedOtherColor={selectedOtherColor}
                />
              )}
              {step === 4 && (
                <VehicleFinanceStep5
                  nextStep={nextStep}
                  backStep={backStep}
                  tradeVehicleInfo={tradeVehicleInfo}
                  vehicleType={vehicleType}
                  setFinalConfiguration={setFinalConfiguration}
                  color={color}
                  selectedOtherColor={selectedOtherColor}
                />
              )}

              {step === 5 && (
                <VehicleFinanceStepFinal
                  nextStep={nextStep}
                  backStep={backStep}
                  resetStep={resetStep}
                  userObject={userObject}
                  setUserObject={setUserObject}
                  handleSubmitTrade={handleSubmitTrade}
                  finalConfiguration={finalConfiguration}
                  mileage={mileage}
                  zipCode={zipCode}
                  setTradeInValue={setTradeInValue}
                  selectedCondition={selectedCondition}
                />
              )}
              {step === 6 && (
                <TradeInValue
                  vehicleId={info?._id}
                  tradeVehicleInfo={tradeVehicleInfo}
                  tradeId={tradeId}
                  info={info}
                  backStep={backStep}
                  tradeInValue={tradeInValue}
                />
              )}

              {step === 8 && (
                <CreditFinance
                  vehicleId={info?._id}
                  initialStep={initialFinanceStep}
                  creditAppDetail={creditAppDetail}
                />
              )}

              {step === 10 && (
                <ScheduleDriveStep1
                  nextScheduleStep={nextScheduleFormStep}
                  time={time}
                  setTime={setTime}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  vehicleId={info?._id}
                />
              )}

              {step === 11 && (
                <ScheduleDriveStep2
                  selectedData={selectedData}
                  resetStep={() => setStep(10)}
                  nextScheduleStep={nextScheduleFinal}
                  scheduleTestDriveData={scheduleTestDriveData}
                  time={time}
                  vehicleId={info?._id}
                />
              )}

              {step === 12 && (
                <ScheduleDriveStepFinal
                  startFinishYourDeal={startFinishYourDeal}
                  startAutomotiveKBB={startAutomotiveKBB}
                  time={time}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  vehicleInfo={info}
                />
              )}

              {step === 15 && (
                <FinishDealStep1
                  selectedTrade={selectedTrade}
                  nextFinishDealStep={nextFinishDealFormStep}
                  calculation={calculation}
                />
              )}

              {step === 16 && <FinishDealStepFinal resetStep={resetStep} />}

              {step < 5 && (
                <>
                  <div className="mt-8 mb-12 flex">
                    <img src={kelleyImage} alt="" width="90" style={{ height: '56px' }} />
                    <div className="ml-3">
                      <p className="text-gray-700 text-sm font-medium">About Kelley Blue Book® Values</p>
                      <p className="text-gray-400 text-xs font-light">
                        {` For over 90 years, Kelley Blue Book® has been The Trusted Resource for used car values, helping car owners understand what their used car is worth. Kelley Blue Book® Values are the results of ${
                          isReadLess
                            ? '...'
                            : ` massive amounts of data, including actual sales transactions and auction prices, which are then analyzed and adjusted to account for seasonality and market trends. Kelley Blue Book® Values reflect local conditions in over 100 different geographic regions and are undated weekly to give consumers the most up-to-date used car pricing information.`
                        }`}
                        <p className="text-black text-xs font-semibold" onClick={() => setIsReadLess(!isReadLess)}>
                          {isReadLess ? 'Read More >>' : '<< Read Less'}
                        </p>
                      </p>
                    </div>
                  </div>
                  <p className="text-black text-xs font-semibold text-center">MotorMarketUSA Autos</p>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <ConfirmCreditApplicationModal
        showModal={showFinanceConfirmModal}
        closeModal={changeShowConfirmFinanceModal}
        creditApps={creditApps}
        createNewCreditApp={() => {
          setShowFinanceConfirmModal(false)
          setInitialFinanceStep(1)
          setCreditAppDetail({})
          handleProtection('financing', 8)
        }}
        setInitialCreditAppData={(data) => {
          setShowFinanceConfirmModal(false)
          handleProtection('financing', 8)
          setInitialFinanceStep(6)
          setInitialCreditAppData(data)
        }}
      />
    </>
  )
}

VehicleFinanceMobileModal.propTypes = {
  info: PropTypes.object,
  initStep: PropTypes.number,
  setInitStep: PropTypes.func,
  calculation: PropTypes.object,
  handleCancel: PropTypes.func
}

export default VehicleFinanceMobileModal
