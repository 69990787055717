import { Modal, Button } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useState } from 'react'
import * as DealerService from '../../../services/dealer.service'
import { useAuthorizationContext } from '../../../AuthorizationProvider'
import { handleClearStorageUserInfo, save } from '../../../lib/storage'
import { STORAGE_KEYS } from '../../../constants/constants'

export function SwitchDealershipModal({ dealerId, dealerships, showModal, handleCloseModal }) {
  const { handleDealerAccountLogin, handleAccountSettingsLogin } = useAuthorizationContext()

  const refreshPage = () => {
    window.location.reload(false)
    window.location.replace(`${window.location.origin}/dealer-dashboard`)
  }

  const [relatedDealership, setRelatedDealerships] = useState(dealerships)
  const handleChangeDealership = (toDealerId) => {
    DealerService.switchDealership({ fromDealerId: dealerId, toDealerId })
      .then((response) => {
        handleClearStorageUserInfo()

        setRelatedDealerships(response.data.dealerships)

        const data = response.data.loginData
        const { access_token: accessToken, token_type: tokenType } = data
        const userSettings = JSON.stringify(data)
        save(STORAGE_KEYS.ACCESS_TOKEN, accessToken)
        save(STORAGE_KEYS.TOKEN_TYPE, tokenType)
        save(STORAGE_KEYS.USER_SETTINGS, userSettings)
        handleAccountSettingsLogin(userSettings)
        save(STORAGE_KEYS.DEALER_LOGIN, 'true')
        handleDealerAccountLogin(true)
        refreshPage()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <Modal
      centered
      visible={showModal}
      title=""
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      okText="Close"
      className="calendar-modal"
      destroyOnClose
      width={640}
      footer={false}
    >
      <div className="px-10 py-5">
        <div className="text-2xl text-center mb-4">Dealerships</div>
        <div className="grid grid-cols-1 xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1">
          {relatedDealership.map((item, key) => (
            <div key={key} className="flex flex-row justify-center mt-2">
              <Button
                className="rounded text-blue-700 font-semibold w-full"
                disabled={item.dealer_id === dealerId}
                onClick={() => handleChangeDealership(item.dealer_id)}
              >
                {item.dealer_name}
              </Button>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

SwitchDealershipModal.propTypes = {
  dealerId: PropTypes.number,
  dealerships: PropTypes.array,
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func
}
