import { Pagination, Spin } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import VehicleCard from 'src/components/Buy/vehicleCard'
import * as CustomerServices from '../../../services/customer.service'

export function MyFavorites() {
  const [vehicles, setVehicles] = useState([])
  const { favouriteVehicles, setFavouriteVehicles } = useAuthorizationContext()
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [loading, setLoading] = useState(true)
  const [allFavVehicles, setAllFavVehicles] = useState([])

  const setFavVehicles = () => {
    return favouriteVehicles
      ?.filter((el) => el)
      ?.map((item) => {
        const vehicle = item
        return {
          ...vehicle,
          _id: item._id,
          body: vehicle.body,
          engine_name: vehicle.engine_name,
          transmission_name: vehicle.transmission_name,
          priceMask: vehicle?.priceMask || item?.price?.toLocaleString() || 0
        }
      })
  }

  useMemo(() => {
    setFavVehicles()
  }, [favouriteVehicles])

  const getVehicles = useCallback(() => {
    setLoading(true)
    CustomerServices.getFavoriteVehiclesByCustomer()
      .then((response) => {
        setAllFavVehicles(response)
        setFavVehicles()
        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
      })
  }, [page])

  const paginate = (page, pageSize) => {
    setVehicles(allFavVehicles.slice((page - 1) * pageSize, page * pageSize))
  }

  useEffect(() => {
    getVehicles()
  }, [])

  useEffect(() => {
    paginate(page, rowsPerPage)
  }, [page, rowsPerPage, allFavVehicles])

  const handleChangePage = (page, pageSize) => {
    setPage(page)
    setRowsPerPage(pageSize)
  }

  return (
    <>
      {loading ? (
        <div className="spiner">
          <Spin size="large" tip="Loading..." />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 m-6 ml-2">
          {vehicles.length > 0 ? (
            vehicles?.map((car) => (
              <VehicleCard
                car={car}
                key={car._id}
                favouriteCars={favouriteVehicles}
                setFavouriteCars={setFavouriteVehicles}
              />
            ))
          ) : (
            <div className="text-center col-span-1 md:col-span-2 lg:col-span-4">No data</div>
          )}
        </div>
      )}

      {!loading && (
        <div className="xl:w-11/12 lg:w-11/12 flex justify-center items-center mb-8">
          <Pagination
            className="flex"
            responsive
            current={page}
            total={allFavVehicles.length > 0 ? allFavVehicles.length : 0}
            pageSize={rowsPerPage}
            onChange={handleChangePage}
            pageSizeOptions={[10, 20, 50, 100]}
          />
        </div>
      )}
    </>
  )
}
