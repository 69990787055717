export const isHideOnProduction = process.env.REACT_APP_HIDE_ON_PRODUCTION === 'true'

export const SOURCE_TRADE_IN = 'Trade-In'
export const SOURCE_SELL_YOUR_CAR = 'Sell Your Car'

export const APR_TYPE_DEFAULT = 'default'
export const APR_TYPE_LIBOR = 'libor'

export const USER_ROLES = {
  GUEST: 'guest',
  ADMIN: 'admin',
  CUSTOMER: 'customer',
  DEALERSHIP_USER: 'dealership_user',
  DEALERSHIP_ADMIN: 'dealership_admin'
}

export const NOTIFICATION_TYPES = {
  SMS: 'sms',
  EMAIL: 'email'
}

export const MenuLinks = [
  {
    name: 'Home',
    link: '/'
  },
  {
    name: 'Budget Calculator',
    link: '',
    hidden: isHideOnProduction
  },
  {
    name: 'Buy a Car',
    link: '/buy'
  },
  {
    name: 'Sell Your Car',
    link: '/sell',
    hidden: isHideOnProduction
  },
  {
    name: 'Claim Your Dealership',
    link: '/claim'
  },
  {
    name: 'NIADA Members',
    link: '/Niada-Claim'
  },
  {
    name: 'Get Pre-approved',
    link: '/finance',
    hidden: isHideOnProduction
  },
  {
    name: 'About MotorMarketUSA',
    link: '/about',
    hidden: isHideOnProduction
  }
]

export const MenuLinkAccountDashboard = (sharedByDealership) => [
  {
    name: 'Dashboard',
    link: '/customer-dashboard'
  },
  {
    name: 'Budget Calculator',
    link: '',
    hidden: isHideOnProduction
  },
  {
    name: 'My Favorites',
    link: '/my-favourites',
    hidden: isHideOnProduction || sharedByDealership
  },
  {
    name: 'Buy A Car',
    link: '/buy',
    hidden: sharedByDealership
  },
  {
    name: 'Sell Your Car',
    link: '/sell',
    hidden: isHideOnProduction || sharedByDealership
  },
  {
    name: 'Get Pre-approved',
    link: '/finance',
    hidden: isHideOnProduction || sharedByDealership
  },
  {
    name: 'About MotorMarketUSA',
    link: '/about',
    hidden: isHideOnProduction || sharedByDealership
  }
]

export const MenuLinkDealerDashboard = [
  {
    name: 'Dashboard',
    link: '/dealer-dashboard'
  },
  {
    name: 'My Inventory',
    link: '/dealer-dashboard#my-inventory'
  },
  {
    name: 'My Favorites',
    link: '/dealer-favorites',
    hidden: isHideOnProduction
  },
  {
    name: 'My Sales',
    link: '/dealer-sales',
    hidden: isHideOnProduction
  },
  {
    name: 'My Trade Ins',
    link: '/dealer-trade-ins',
    hidden: isHideOnProduction
  },
  {
    name: 'Finances',
    link: '/dealer-finances',
    hidden: isHideOnProduction
  },
  {
    name: 'Reports',
    link: '/dealer-reports'
  }
]

export const MenuLinkAdminDashboard = [
  {
    name: 'Dashboard',
    link: '/admin-dashboard'
  },
  {
    name: 'Payment Settings',
    link: '/admin-payment-settings'
  },
  {
    name: 'Dealers',
    link: '/admin-dealers'
  },
  {
    name: 'Vehicles',
    link: '/admin-vehicles'
  },
  {
    name: 'Transactions',
    link: '/admin-transactions'
  },
  {
    name: 'Finances',
    link: '/admin-finances'
  },
  {
    name: 'Stats',
    link: '/admin-stats'
  },
  {
    name: 'Reports',
    link: '/dealer-reports'
  }
]

export const MenuLinkAdminDashboardProduction = [
  {
    name: 'Dashboard',
    link: '/admin-dashboard'
  },
  {
    name: 'Payment Settings',
    link: '/admin-payment-settings'
  },
  {
    name: 'Dealers',
    link: '/admin-dealers'
  },
  {
    name: 'Vehicles',
    link: '/admin-vehicles'
  },
  {
    name: 'Transactions',
    link: '/admin-transactions'
  },
  {
    name: 'Finances',
    link: '/admin-finances'
  },
  {
    name: 'Stats',
    link: '/admin-stats'
  },
  {
    name: 'Reports',
    link: '/dealer-reports'
  }
]

export const STORAGE_KEYS = {
  MAKE: 'make',
  MODEL: 'model',
  BODY_TYPE: 'bodyType',
  YEAR: 'year',
  PRICE_RANGE: 'priceRange',
  SHOULD_CALL_SEARCH_VEHICLE: 'shouldCallSearchVehicle',
  CUSTOMER_LOGIN: 'customer_login',
  DEALER_LOGIN: 'dealer_login',
  DEALER_ID: 'dealer_id',
  ADMIN_LOGIN: 'admin_login',
  FAVOURITE_CARS: 'favourite_cars',
  TOKEN_TYPE: 'token_type',
  ACCESS_TOKEN: 'access_token',
  USER_LOGIN: 'user_login',
  USER_SETTINGS: 'user_settings',
  INITIAL_FILTER_OPTIONS: 'initial_filter_options',
  INITIAL_FILTER_COUNT: 'initial_filter_count',
  INITIAL_FILTER_ZIP_CODE: 'initial_filter_zip_code',
  VERIFICATION_DEALER_ACCOUNT: 'verification_dealer_account',
  EZ_DEALZ_BUDGET: 'ez_dealz_budget',
  EZ_DEALZ_LOGIN: 'dealermall-customer_login',
  START_STEP_CLAIM_YOUR_DEALERSHIP: 'start_step_claim_your_dealership',
  START_DEALERSHIP_CLAIM_YOUR_DEALERSHIP: 'start_dealership_claim_your_dealership',
  CONTACT_DEALER_FORM_SENT: 'contact_dealer_form_sent',
  DISABLE_CALL_API_GET_ZIP_CODE: 'disable_call_api_get_zip_code',
  INVENTORY_PAGINATION: 'inventory_pagination',
  CUSTOMER_TRADE_INS: 'customer_trade_ins',
  ZIP_CODE_CHANGED: 'zip_code_changed',
  IS_SHARED_BY_DEALERSHIP: 'is_shared_by_dealership',
  SHARED_BY_DEALERSHIP_ID: 'shared_by_dealership_id',
  CUSTOMER_CREDIT_APPS: 'customer_credit_app'
}

export const STATES = [
  {
    name: 'Alabama',
    abbreviation: 'AL'
  },
  {
    name: 'Alaska',
    abbreviation: 'AK'
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS'
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ'
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR'
  },
  {
    name: 'California',
    abbreviation: 'CA'
  },
  {
    name: 'Colorado',
    abbreviation: 'CO'
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT'
  },
  {
    name: 'Delaware',
    abbreviation: 'DE'
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC'
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM'
  },
  {
    name: 'Florida',
    abbreviation: 'FL'
  },
  {
    name: 'Georgia',
    abbreviation: 'GA'
  },
  {
    name: 'Guam',
    abbreviation: 'GU'
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI'
  },
  {
    name: 'Idaho',
    abbreviation: 'ID'
  },
  {
    name: 'Illinois',
    abbreviation: 'IL'
  },
  {
    name: 'Indiana',
    abbreviation: 'IN'
  },
  {
    name: 'Iowa',
    abbreviation: 'IA'
  },
  {
    name: 'Kansas',
    abbreviation: 'KS'
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY'
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA'
  },
  {
    name: 'Maine',
    abbreviation: 'ME'
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH'
  },
  {
    name: 'Maryland',
    abbreviation: 'MD'
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA'
  },
  {
    name: 'Michigan',
    abbreviation: 'MI'
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN'
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS'
  },
  {
    name: 'Missouri',
    abbreviation: 'MO'
  },
  {
    name: 'Montana',
    abbreviation: 'MT'
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE'
  },
  {
    name: 'Nevada',
    abbreviation: 'NV'
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH'
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ'
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM'
  },
  {
    name: 'New York',
    abbreviation: 'NY'
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC'
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND'
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP'
  },
  {
    name: 'Ohio',
    abbreviation: 'OH'
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK'
  },
  {
    name: 'Oregon',
    abbreviation: 'OR'
  },
  {
    name: 'Palau',
    abbreviation: 'PW'
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA'
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR'
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI'
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC'
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD'
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN'
  },
  {
    name: 'Texas',
    abbreviation: 'TX'
  },
  {
    name: 'Utah',
    abbreviation: 'UT'
  },
  {
    name: 'Vermont',
    abbreviation: 'VT'
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI'
  },
  {
    name: 'Virginia',
    abbreviation: 'VA'
  },
  {
    name: 'Washington',
    abbreviation: 'WA'
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV'
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI'
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY'
  }
]
export const KBB_VEHICLE_TYPE = {
  POWERSPORTS: 'Powersports',
  AUTOMOTIVE: 'Automotive'
}

export const PSX_AUTH_TOKEN_IS_ACTIVE_OPTIONS = [
  {
    name: 'NO',
    value: 'NO'
  },
  {
    name: 'YES',
    value: 'YES'
  }
]

export const SHOW_NIADA_DEALERS_OPTIONS = [
  {
    name: 'Show All',
    value: 'show_all'
  },
  {
    name: 'Show only NIADA Dealers',
    value: 'show_only'
  },
  {
    name: 'Do not show NIADA Dealers',
    value: 'do_not_show'
  }
]

export const SHOW_PROMO_VIDEO_OPTIONS = [
  {
    name: 'YES',
    value: 'show_yes'
  },
  {
    name: 'NO',
    value: 'show_no'
  }
]

export const CPO_PROVIDERS = [
  {
    name: 'Gulf States Finance',
    value: 'Gulf States Finance'
  },
  {
    name: 'AUL',
    value: 'AUL'
  },
  {
    name: 'GWC',
    value: 'GWC'
  },
  {
    name: 'Southwest Dealer Services',
    value: 'Southwest Dealer Services'
  },
  {
    name: 'Pro Guard',
    value: 'Pro Guard'
  }
]

export const TERM_LENGTH = [
  {
    name: 36,
    value: 36
  },
  {
    name: 48,
    value: 48
  },
  {
    name: 60,
    value: 60
  },
  {
    name: 72,
    value: 72
  }
]

export const CREDIT_SCORE = [
  {
    name: 'Excellent (740 - 900)',
    value: 'excellent'
  },
  {
    name: 'Very Good (700 - 739)',
    value: 'excellent'
  },
  {
    name: 'Good (670 - 699)',
    value: 'excellent'
  },
  {
    name: 'Fair (630 - 669)',
    value: 'fair'
  },
  {
    name: 'Rebuilding (580 - 629)',
    value: 'rebuilding'
  }
]

export const DEFAULT_TERM_MARKS = {
  36: '36',
  48: '48',
  60: '60',
  72: '72'
}

export const DEFAULT_OPTIONS = [
  {
    name: 'Yes',
    value: 'Yes'
  },
  {
    name: 'No',
    value: 'No'
  }
]

export const USER_PERMISSIONS = [
  {
    label: 'Manage Dealer users',
    value: 'manage_dealers'
  },
  {
    label: 'Assign Leads',
    value: 'assign_leads'
  },
  {
    label: 'Edit Inventory',
    value: 'edit_inventory'
  },
  {
    label: 'Manage Payment Settings',
    value: 'manage_payment_settings'
  }
]

export const ENGAGEMENT_STATUS_OPTIONS = [
  {
    text: 'Added a lead',
    value: 'Added a lead'
  },
  {
    text: 'Added a trade',
    value: 'Added a trade'
  },
  {
    text: 'Added a credit app',
    value: 'Added a credit app'
  },
  {
    text: 'Offer Sent',
    value: 'Offer Sent'
  },
  {
    text: 'Offer Opened',
    value: 'Offer Opened'
  }
]

export const EMAIL_STATUS_OPTIONS = [
  {
    text: 'Sent',
    value: 'Sent'
  },
  {
    text: 'Delivered',
    value: 'Delivered'
  },
  {
    text: 'Opened',
    value: 'Opened'
  },
  {
    text: 'Clicked',
    value: 'Clicked'
  },
  {
    text: 'Needs Reply',
    value: 'Needs Reply'
  },
  {
    text: 'Customer Reply',
    value: 'Customer Reply'
  }
]

export const VEHICLE_CLASS = [
  {
    name: 'Sedan',
    value: 'Sedan'
  },
  {
    name: 'Truck',
    value: 'Cab Over Engine'
  },
  {
    name: 'Pickup',
    value: 'Pickup'
  },
  {
    name: 'SUV',
    value: 'SUV'
  },
  {
    name: 'Convertible',
    value: 'Convertible'
  },
  {
    name: 'Coupe',
    value: 'Coupe'
  },
  {
    name: 'Hatchback',
    value: 'Hatchback'
  },
  {
    name: 'Van / Minivan',
    value: 'Mini-Van'
  },
  {
    name: 'Conventional',
    value: 'Conventional'
  },
  {
    name: 'Commercial',
    value: 'Commercial'
  },
  {
    name: 'Wagon',
    value: 'Full-Size'
  },
  {
    name: 'AWD / FWD',
    value: 'AWD-FWD'
  },
  {
    name: 'Hybrid / Electric',
    value: 'Hybrid-Electric'
  }
]

// Map VEHICLE_CLASS to these
//       "name" => "Cab Over Engine"
//       "name" => "Chassis"
//       "name" => "Commercial"
//       "name" => "Conventional"
//       "name" => "Convertible"
//       "name" => "Coupe"
//       "name" => "Full-Size"
//       "name" => "Hatchback"
//       "name" => "Mini-Van"
//       "name" => "Pickup"

export const VEHICLE_MILEAGE = [
  {
    name: '0 - 5000',
    value: '5000'
  },
  {
    name: '5001 - 10000',
    value: '5001 - 10000'
  },
  {
    name: '10001 - 15000',
    value: '10001 - 15000'
  },
  {
    name: '15001 - 20000',
    value: '15001 - 20000'
  },
  {
    name: '20001 - 25000',
    value: '20001 - 25000'
  },
  {
    name: '25001 - 30000',
    value: '25001 - 30000'
  },
  {
    name: '30001 - 35000',
    value: '30001 - 35000'
  },
  {
    name: '35001 - 40000',
    value: '35001 - 40000'
  },
  {
    name: '40001 - 50000',
    value: '40001 - 50000'
  },
  {
    name: '50000 +',
    value: '50000 +'
  }
]

export const MAX_PAYMENT = [
  {
    name: '$200 - $275',
    value: '200 - 275'
  },
  {
    name: '$276 - $350',
    value: '276 - 350'
  },
  {
    name: '$351 - $425',
    value: '351 - 425'
  },
  {
    name: '$426 - $500',
    value: '426 - 500'
  },
  {
    name: '$501 - $575',
    value: '501 - 575'
  },
  {
    name: '$576 - $650',
    value: '576 - 650'
  },
  {
    name: '$651 +',
    value: '651 +'
  }
]

export const MAX_PRICE = [
  {
    name: '$5000 - $10000',
    value: '5000 - 10000'
  },
  {
    name: '$10001 - $15000',
    value: '10001 - 15000'
  },
  {
    name: '$15001 - $20000',
    value: '15001 - 20000'
  },
  {
    name: '$20001 - $25000',
    value: '20001 - 25000'
  },
  {
    name: '$25001 - $30000',
    value: '25001 - 30000'
  },
  {
    name: '$30001 - $40000',
    value: '30001 - 40000'
  },
  {
    name: '$40001 - $50000',
    value: '40001 - 50000'
  },
  {
    name: '$50000 +',
    value: '50000 +'
  }
]

export const CONDITION = [
  {
    name: 'Fair',
    value: 'fair'
  },
  {
    name: 'Good',
    value: 'good'
  },
  {
    name: 'Very Good',
    value: 'veryGood'
  },
  {
    name: 'Excellent',
    value: 'excellent'
  }
]

export const APPROVAL = [
  {
    name: 'Approved',
    value: 'Approved'
  },
  {
    name: 'Conditioned',
    value: 'Conditioned'
  },
  {
    name: 'Declined',
    value: 'Declined'
  }
]

export const SALES_PEOPLE = [
  {
    name: 'Larry Bruce',
    value: 'Larry Bruce '
  },
  {
    name: 'Jonah Jones',
    value: 'Jonah Jones'
  },
  {
    name: 'John Bottone',
    value: 'John Bottone'
  }
]

export const TERMS = [
  {
    name: 'Month-to-month',
    value: 'month to Month'
  },
  {
    name: '12 months',
    value: '12'
  },
  {
    name: '24 months',
    value: '24'
  },
  {
    name: '36 months',
    value: '36'
  }
]

export const INITIAL_PRICE = {
  min: 0,
  max: 500000
}

export const EZ_DEALZ_STATUS_OPTIONS = [
  {
    key: 0,
    name: 'Sold',
    color: '#5aaf23',
    text: 'Sold'
  },
  {
    key: 1,
    name: 'Ready for Deposit',
    color: '#4e8ddb',
    text: 'Ready for Deposit'
  },
  {
    key: 2,
    name: 'New inbound lead',
    color: '#dddddf',
    text: 'New inbound lead'
  },
  {
    key: 3,
    name: 'Deposit placed',
    color: '#0faef2',
    text: 'Deposit placed'
  },
  {
    key: 4,
    name: 'Inbound message not answered',
    color: '#d44d5c',
    text: 'Inbound message not answered'
  },
  {
    key: 5,
    name: 'Outbound message not replied',
    color: '#e5d24f',
    text: 'Outbound message not replied'
  }
]

export const EZ_DEALZ_STATUS_OPTIONS_FILTER = [
  {
    text: EZ_DEALZ_STATUS_OPTIONS[0].name,
    value: EZ_DEALZ_STATUS_OPTIONS[0].name
  },
  {
    text: EZ_DEALZ_STATUS_OPTIONS[1].name,
    value: EZ_DEALZ_STATUS_OPTIONS[1].name
  },
  {
    text: EZ_DEALZ_STATUS_OPTIONS[2].name,
    value: EZ_DEALZ_STATUS_OPTIONS[2].name
  },
  {
    text: EZ_DEALZ_STATUS_OPTIONS[3].name,
    value: EZ_DEALZ_STATUS_OPTIONS[3].name
  },
  {
    text: EZ_DEALZ_STATUS_OPTIONS[4].name,
    value: EZ_DEALZ_STATUS_OPTIONS[4].name
  },
  {
    text: EZ_DEALZ_STATUS_OPTIONS[5].name,
    value: EZ_DEALZ_STATUS_OPTIONS[5].name
  }
]

export const SMARTBOARD_DEACTIVED_MSG =
  'SmartBoard not activated. For more information, please contact your MMUSA representative.'

export const CUSTOMER_STATUS_OPTIONS = [
  {
    text: 'User Online Now',
    color: 'green'
  },
  {
    text: 'User Visited in last 48 Hours',
    color: 'orange'
  }
]

export const VALID_EMAIL = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,4})+$/
export const VALID_ZIP = /^[0-9]{4,5}\s*$/
// TODO - add for others: phone etc.
