import * as PropTypes from 'prop-types'
import AddDeleteTableRows from '../../AddDeleteTableRows'
import { useAuthorizationContext } from '../../../AuthorizationProvider'

function FeeTable({ dealerId }) {
  const { dealershipAccount } = useAuthorizationContext()
  const canManagePaymentSettings = undefined !== dealershipAccount ? dealershipAccount?.permissions?.manage_payment_settings : true

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <div className="container">
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <div className="mx-auto my-10 px-4 rounded-xl border-2 py-4">
        {/* eslint-disable-next-line react/react-in-jsx-scope */}
        <div>Dealer Fees</div>
        {/* eslint-disable-next-line react/react-in-jsx-scope */}
        <div className="ant-table-wrapper mt-5 payment-settings-table">
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <AddDeleteTableRows dealerId={dealerId} canManagePaymentSettings={canManagePaymentSettings} />
        </div>
      </div>
    </div>
  )
}

FeeTable.propTypes = {
  dealerId: PropTypes.number
}

export default FeeTable
