import * as PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import VehicleFinanceStep1 from 'src/components/Vehicle/VehicleFinanceSteps/step1'
import VehicleFinanceStep2 from 'src/components/Vehicle/VehicleFinanceSteps/step2'
import VehicleFinanceStep3 from 'src/components/Vehicle/VehicleFinanceSteps/step3'
import VehicleFinanceStep4 from 'src/components/Vehicle/VehicleFinanceSteps/step4'
import VehicleFinanceStep5 from 'src/components/Vehicle/VehicleFinanceSteps/step5'
import VehicleFinanceStepFinal from 'src/components/Vehicle/VehicleFinanceSteps/stepFinal'
import TradeInValue from 'src/components/Vehicle/VehicleFinanceSteps/tradeInValue'
import { KBB_VEHICLE_TYPE } from 'src/constants/constants'
import * as CustomerServices from 'src/services/customer.service'
import kelleyImage from '../../../assets/kbb-logo.png'

import './style.scss'

function BodyStepper({ currentStep, setCurrentStep }) {
  const { customerAccount } = useAuthorizationContext()
  const vehicleType = KBB_VEHICLE_TYPE.AUTOMOTIVE
  const [callInitialApi, setCallInitialApi] = useState(false)
  const [yearId, setYearId] = useState('')
  const [makeObject, setMakeObject] = useState({
    makeId: '',
    make: ''
  })
  const [modelObject, setModelObject] = useState({
    modelId: '',
    model: ''
  })
  const [trimId, setTrimId] = useState('')
  const [ownership, setOwnership] = useState('')
  const [vin, setVin] = useState('')
  const [mileage, setMiliage] = useState()
  const [zipCode, setZipCode] = useState()
  const [active, setActive] = useState('no')
  const [debit, setDebit] = useState()
  const [selectedCondition, setSelectedCondition] = useState()
  const [engine, setEngine] = useState()
  const [transmission, setTransmission] = useState()
  const [color, setColor] = useState()
  const [userObject, setUserObject] = useState({})
  const [vehicles, setVehicles] = useState({})
  const [tradeVehicleInfo, setTradeVehicleInfo] = useState({ tradeIn: 0 })
  const [listYears, setListYears] = useState([])
  const [makeList, setMakeList] = useState([])
  const [modelList, setModelList] = useState([])
  const [trimList, setTrimList] = useState([])
  const [finalConfiguration, setFinalConfiguration] = useState([])
  const [selectedOtherColor, setSelectedOtherColor] = useState(false)
  const [tradeInValue, setTradeInValue] = useState('')

  const backStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const nextStep = () => {
    setCurrentStep(currentStep + 1)
  }

  const resetStep = () => {
    setCurrentStep(0)
  }

  const handleSubmitTrade = (values) => {
    if (vehicles && vehicles[0]) {
      const { trimName } = vehicles[0]
      const { firstName, lastName, email, phone, comments, tradeIn } = values

      let estimatedvalue = tradeVehicleInfo.tradeIn || 0
      if (estimatedvalue < 1 && tradeIn !== undefined && tradeIn > 0) {
        estimatedvalue = tradeIn
      }

      CustomerServices.handleSaveTrade({
        vin,
        make: makeObject.make,
        makeId: makeObject.makeId,
        model: modelObject.model,
        modelId: modelObject.modelId,
        year: yearId,
        trim: trimName,
        trimId,
        ownership,
        mileage,
        zipcode: zipCode,
        owePrice: undefined,
        condition: selectedCondition,
        engine,
        transmission,
        color,
        firstName,
        lastName,
        email,
        phone,
        comment: comments,
        estimatedvalue,
        notLoggedIn: !customerAccount.id
      })
    }
  }

  return (
    <div className="pt-10 currentStep-wrapper">
      <div className="block sm:flex justify-center">
        <div className="xl:w-7/12 lg:w-8/12 md:w-full">
          {currentStep < 5 && (
            <>
              <div className="flex mb-3 mobile-step">
                <div className="flex-1 currentStep-title-wrapper mx-2 mb-2 sm:mb-0">
                  <p className="text-base text-primary font-bold">Search</p>
                </div>
                <div className="flex-1 currentStep-title-wrapper mx-2">
                  <p className="text-base text-primary font-bold">Details</p>
                </div>
                <div className="flex-1 currentStep-title-wrapper mx-2">
                  <p className="text-base text-primary font-bold">Conditions</p>
                </div>
                <div className="flex-1 currentStep-title-wrapper mx-2">
                  <p className="text-base text-primary font-bold">Vehicle</p>
                </div>
                <div className="flex-1 currentStep-title-wrapper mx-2">
                  <p className="text-base text-primary font-bold">Equipment</p>
                </div>
              </div>
              <div className="bg-gray-200 h-1 w-full">
                <div
                  className="h-full bg-info rounded transition-all duration-300"
                  style={{ width: `${((currentStep + 1) * 100) / 5}%` }}
                />
              </div>
            </>
          )}

          {currentStep === 0 && (
            <VehicleFinanceStep1
              nextStep={nextStep}
              setYearId={setYearId}
              setMakeObject={setMakeObject}
              setModelObject={setModelObject}
              setTrimId={setTrimId}
              setCallInitialApi={setCallInitialApi}
              vehicleType={vehicleType}
            />
          )}
          {currentStep === 1 && (
            <VehicleFinanceStep2
              nextStep={nextStep}
              backStep={backStep}
              vehicleType={vehicleType}
              yearId={yearId}
              setYearId={setYearId}
              setMakeObject={setMakeObject}
              makeObject={makeObject}
              setModelObject={setModelObject}
              modelObject={modelObject}
              setTrimId={setTrimId}
              trimId={trimId}
              ownership={ownership}
              setOwnership={setOwnership}
              mileage={mileage}
              setMiliage={setMiliage}
              zipCode={zipCode}
              setZipCode={setZipCode}
              active={active}
              setActive={setActive}
              debit={debit}
              setDebit={setDebit}
              callInitialApi={callInitialApi}
              setCallInitialApi={setCallInitialApi}
              vehicles={vehicles}
              setVehicles={setVehicles}
              setTradeVehicleInfo={setTradeVehicleInfo}
              isSellYourCar
              listYears={listYears}
              setListYears={setListYears}
              makeList={makeList}
              setMakeList={setMakeList}
              modelList={modelList}
              setModelList={setModelList}
              trimList={trimList}
              setTrimList={setTrimList}
              vin={vin}
              setVin={setVin}
            />
          )}
          {currentStep === 2 && (
            <VehicleFinanceStep3
              nextStep={nextStep}
              backStep={backStep}
              selectedCondition={selectedCondition}
              setSelectedCondition={setSelectedCondition}
              tradeVehicleInfo={tradeVehicleInfo}
            />
          )}
          {currentStep === 3 && (
            <VehicleFinanceStep4
              nextStep={nextStep}
              backStep={backStep}
              engine={engine}
              setEngine={setEngine}
              transmission={transmission}
              setTransmission={setTransmission}
              color={color}
              setColor={setColor}
              tradeVehicleInfo={tradeVehicleInfo}
              setSelectedOtherColor={setSelectedOtherColor}
              selectedOtherColor={selectedOtherColor}
            />
          )}
          {currentStep === 4 && (
            <VehicleFinanceStep5
              nextStep={nextStep}
              backStep={backStep}
              tradeVehicleInfo={tradeVehicleInfo}
              vehicleType={vehicleType}
              setFinalConfiguration={setFinalConfiguration}
              color={color}
              selectedOtherColor={selectedOtherColor}
            />
          )}
          {currentStep === 5 && (
            <VehicleFinanceStepFinal
              nextStep={nextStep}
              backStep={backStep}
              resetStep={resetStep}
              userObject={userObject}
              setUserObject={setUserObject}
              handleSubmitTrade={handleSubmitTrade}
              finalConfiguration={finalConfiguration}
              setTradeInValue={setTradeInValue}
              selectedCondition={selectedCondition}
              mileage={mileage}
              zipCode={zipCode}
            />
          )}

          {currentStep === 6 && <TradeInValue tradeVehicleInfo={tradeVehicleInfo} tradeInValue={tradeInValue} />}

          {currentStep < 5 && (
            <>
              <div className="mt-8 mb-12 block sm:flex">
                <img src={kelleyImage} alt="" width="90" style={{ height: '56px' }} />
                <div className="ml-3">
                  <p className="text-gray-700 text-sm font-medium">About Kelley Blue Book® Values</p>
                  <p className="text-gray-400 text-xs font-light">
                    For over 90 years, Kelley Blue Book® has been The Trusted Resource for used car values, helping car
                    owners understand what their used car is worth. Kelley Blue Book® Values are the results of massive
                    amounts of data, including actual sales transactions and auction prices, which are then analyzed and
                    adjusted to account for seasonality and market trends. Kelley Blue Book® Values reflect local
                    conditions in over 100 different geographic regions and are undated weekly to give consumers the
                    most up-to-date used car pricing information.
                  </p>
                </div>
              </div>
              <p className="text-black text-xs font-semibold text-center">MotorMarketUSA Autos</p>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

BodyStepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired
}

export default BodyStepper
