import * as PropTypes from 'prop-types'
import React from 'react'
import './style.scss'

function Stepper({ currentStep }) {
  const step = currentStep - 1
  return (
    <div className="h-full search-car-wapper py-5 px-0 xl:px-5 lg:px-5 md:px-5 pb-8 relative z-10 flex-1 -mt-14 grid grid-cols-7">
      <div className="flex flex-col gap-2 text-center">
        <div className="text-lg text-darkPrimary font-semibold ml-2 xl:ml-5 lg:ml-5 md:ml-5">Step 1</div>
        <div className={step >= 0 ? 'border-success border-bottom-10' : undefined} />
      </div>
      <div className="flex flex-col gap-2 text-center">
        <div className="text-lg text-darkPrimary font-semibold  ml-2 xl:ml-5 lg:ml-5 md:ml-5">Step 2</div>
        <div className={step >= 1 ? 'border-success border-bottom-10' : undefined} />
      </div>
      <div className="flex flex-col gap-2 text-center">
        <div className="text-lg text-darkPrimary font-semibold  ml-2 xl:ml-5 lg:ml-5 md:ml-5">Step 3</div>
        <div className={step >= 2 ? 'border-success border-bottom-10' : undefined} />
      </div>
      <div className="flex flex-col gap-2 text-center">
        <div className="text-lg text-darkPrimary font-semibold  ml-2 xl:ml-5 lg:ml-5 md:ml-5">Step 4</div>
        <div className={step >= 3 ? 'border-success border-bottom-10' : undefined} />
      </div>
      <div className="flex flex-col gap-2 text-center">
        <div className="text-lg text-darkPrimary font-semibold  ml-2 xl:ml-5 lg:ml-5 md:ml-5">Step 5</div>
        <div className={step >= 4 ? 'border-success border-bottom-10' : undefined} />
      </div>
      <div className="flex flex-col gap-2 text-center">
        <div className="text-lg text-darkPrimary font-semibold  ml-2 xl:ml-5 lg:ml-5 md:ml-5">Step 6</div>
        <div className={step >= 5 ? 'border-success border-bottom-10' : undefined} />
      </div>
    </div>
  )
}

Stepper.propTypes = {
  currentStep: PropTypes.number.isRequired
}

export default Stepper
