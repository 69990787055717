import { Form, Input, notification } from 'antd'
import React, { useRef, useEffect, useState } from 'react'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useAuthorizationContext } from '../../../AuthorizationProvider'
import { updateSetting700 } from '../../../services/dealer.service'

const CREDIT_TYPES = {
  SOFT_PULL: 'soft',
  HARD_PULL: 'hard'
}

function CreditCredentials() {
  const formRef = useRef()
  const { dealershipAccount, setDealershipAccount } = useAuthorizationContext()
  const settings = dealershipAccount?.dealership_settings || []
  const [showPwdSoftPull, setShowPwdSoftPull] = useState(false)
  const [showPwdHardPull, setShowPwdHardPull] = useState(false)
  const [creditType, setCreditType] = useState(CREDIT_TYPES.SOFT_PULL)
  const [softPullClassName, setSoftPullClassName] = useState('custom-antd-label')
  const [hardPullClassName, setHardPullClassName] = useState('custom-antd-label hidden')
  const usernameSoftPull = settings[CREDIT_TYPES.SOFT_PULL].username
  const usernameHardPull = settings[CREDIT_TYPES.HARD_PULL].username
  const passwordSoftPull = settings[CREDIT_TYPES.SOFT_PULL].password
  const passwordHardPull = settings[CREDIT_TYPES.HARD_PULL].password

  useEffect(() => {
    formRef.current.setFieldsValue({ ...formRef.current.getFieldsValue(), username_soft_pull: usernameSoftPull })
    formRef.current.setFieldsValue({ ...formRef.current.getFieldsValue(), username_hard_pull: usernameHardPull })
    formRef.current.setFieldsValue({ ...formRef.current.getFieldsValue(), password_soft_pull: passwordSoftPull })
    formRef.current.setFieldsValue({ ...formRef.current.getFieldsValue(), password_hard_pull: passwordHardPull })
  }, [])

  const handleTypeChange = (type) => {
    setCreditType(type)
    if (type === CREDIT_TYPES.SOFT_PULL) {
      setSoftPullClassName('custom-antd-label')
      setHardPullClassName('custom-antd-label hidden')
    }
    if (type === CREDIT_TYPES.HARD_PULL) {
      setSoftPullClassName('custom-antd-label hidden')
      setHardPullClassName('custom-antd-label')
    }
  }

  const handleStoreCredit = async () => {
    const values = await formRef.current
      .validateFields()
      .then((response) => response)
      .catch((error) => error)
    if (values.errorFields && values.errorFields.length > 0) return

    updateSetting700(values)
      .then((response) => {
        dealershipAccount.dealership_settings = response.data
        setDealershipAccount(dealershipAccount)
        notification.success({ message: 'Settings updated', placeholder: 'topRight' })
      })
      .catch((err) => {
        console.error(err)
        notification.error({ message: 'Updating settings failed', placeholder: 'topRight' })
      })
  }

  const handleChangeShowPwdSoftPull = () => setShowPwdSoftPull(!showPwdSoftPull)
  const handleChangeShowPwdHardPull = () => setShowPwdHardPull(!showPwdHardPull)

  return (
    <div className="container border-2 w-full rounded-2xl p-6 bg-white flex flex-col justify-center items-center gap-5">
      <div className="text-xl">700Credit settings</div>
      <span className="hidden">{creditType}</span>
      <div className="flex justify-center w-full lg:w-72">
        <div className="flex font-bold justify-around w-full lg:w-72">
          <p
            className={`cursor-pointer border-b-4 pb-2 w-1/2 text-center hover:text-blue-400 ${
              creditType === CREDIT_TYPES.SOFT_PULL && 'text-blue-400 border-blue-400'
            }`}
            onClick={() => handleTypeChange(CREDIT_TYPES.SOFT_PULL)}
          >
            Soft-pull
          </p>
          <p
            className={`cursor-pointer border-b-4 pb-2 w-1/2 text-center hover:text-blue-400 ${
              creditType === CREDIT_TYPES.HARD_PULL && 'text-blue-400  border-blue-400'
            }`}
            onClick={() => handleTypeChange(CREDIT_TYPES.HARD_PULL)}
          >
            Hard-pull
          </p>
        </div>
      </div>
      <div className="w-full lg:w-96">
        <Form layout="vertical" ref={formRef} name="vehicle-ref">
          <div className="lg:px-12">
            <Form.Item name="username_soft_pull" className={softPullClassName}>
              <Input
                defaultValue={usernameSoftPull}
                name="username_soft_pull"
                placeholder="Username"
                className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border"
                type="text"
              />
            </Form.Item>
            <Form.Item name="username_hard_pull" className={hardPullClassName}>
              <Input
                defaultValue={usernameHardPull}
                name="username_hard_pull"
                placeholder="Username"
                className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border"
                type="text"
              />
            </Form.Item>
            <Form.Item name="password_soft_pull" className={softPullClassName}>
              <div className="relative border rounded-xl border-gray">
                <Input
                  defaultValue={passwordSoftPull}
                  name="password_soft_pull"
                  placeholder="Password"
                  className="w-full p-4 box-border rounded-xl border-gray-300 border"
                  type={showPwdSoftPull ? 'text' : 'password'}
                />
                {showPwdSoftPull ? (
                  <AiFillEyeInvisible
                    onClick={handleChangeShowPwdSoftPull}
                    className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                  />
                ) : (
                  <AiFillEye
                    onClick={handleChangeShowPwdSoftPull}
                    className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                  />
                )}
              </div>
            </Form.Item>
            <Form.Item name="password_hard_pull" className={hardPullClassName}>
              <div className="relative border rounded-xl border-gray">
                <Input
                  defaultValue={passwordHardPull}
                  name="password_hard_pull"
                  placeholder="Password"
                  className="w-full p-4 box-border rounded-xl border-gray-300 border"
                  type={showPwdHardPull ? 'text' : 'password'}
                />
                {showPwdHardPull ? (
                  <AiFillEyeInvisible
                    onClick={handleChangeShowPwdHardPull}
                    className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                  />
                ) : (
                  <AiFillEye
                    onClick={handleChangeShowPwdHardPull}
                    className="w-4 h-full cursor-pointer font-semibold absolute top-0 right-2"
                  />
                )}
              </div>
            </Form.Item>
            <div className="mt-6">
              <button
                type="button"
                className="bg-primary hover:bg-activePrimary font-semibold rounded-lg px-2 py-3 outline-none text-lg w-full text-white flex justify-center uppercase"
                onClick={handleStoreCredit}
              >
                Save
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default CreditCredentials
