import queryString from 'query-string'
import { getPublicIp } from '../utils'
import HttpService from './http.service'
import HttpClientService from './httpClient.service'

export function getVehicles(page, limit, dealerId) {
  const params = queryString.stringify({
    page,
    per_page: limit,
    dealer_id: dealerId
  })
  return HttpService.get(`get-vehicles?${params}`).then((result) => {
    const { data } = result
    return data
  })
}

export function dealershipVehicles(params) {
  return HttpClientService.post('dealership/vehicles', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getVehiclesSanitized(page, limit) {
  const params = queryString.stringify({
    page,
    per_page: limit
  })
  return HttpService.get(`get-vehicles-sanitized?${params}`).then((result) => {
    const { data } = result
    return data
  })
}

export function getSearchSuggetion(search) {
  return HttpService.post('suggestions', { keyword: search, limit: 5 }).then((result) => {
    const { data } = result
    return data
  })
}

export function getSearchSuggestionElasticsearch(search) {
  return HttpService.post('suggestions-elasticsearch', { keyword: search, limit: 20 }).then((result) => {
    const { data } = result
    return data
  })
}

export function getSearchSuggestionElasticsearchPerDealer(search, dealerId) {
  return HttpService.post('suggestions-elasticsearch-per-dealer', {
    keyword: search,
    dealer_id: dealerId,
    limit: 20
  }).then((result) => {
    const { data } = result
    return data
  })
}

export function getSearchElasticsearchResult(search) {
  return HttpService.post('search-elasticsearch', { keyword: search, limit: 20 }).then((result) => {
    const { data } = result
    return data
  })
}

export async function getVehiclesInLandingPage(params) {
  const ip = await getPublicIp()
  const header = new Headers({ 'Access-Control-Allow-Origin': '*' })
  const queryParams = queryString.stringify(params)

  return HttpClientService.get(
    `get-vehicles-in-landing-page/${ip}?${queryParams}`,
    { header },
    { mode: 'no-cors' }
  ).then((result) => {
    const { data } = result
    return data
  })
}

export function getSearchResult(search) {
  return HttpService.post('search', { keyword: search }).then((result) => {
    const { data } = result
    return data
  })
}

export function getSearchSorting(sortBy, orderBy, page, limit) {
  return HttpService.post('search-sorting', { sort_by: sortBy, order_by: orderBy, page, per_page: limit }).then(
    (result) => {
      const { data } = result
      return data
    }
  )
}

export function getFilterList() {
  return HttpService.get('get-filter-values').then((result) => {
    const { data } = result
    return data
  })
}

export function getDealerFilterList(dealerId) {
  return HttpService.get(`get-filter-values/${dealerId}`).then((result) => {
    const { data } = result
    return data
  })
}

export function getMakeModelList(make) {
  return HttpService.post('get-make-models', { make }).then((result) => {
    const { data } = result
    return data
  })
}

export function filterValues({
  zipCode,
  zipRadius,
  price,
  year,
  mileage,
  make,
  model,
  trim,
  transmission,
  color,
  vehicleType,
  certifiedVehicles,
  keyword,
  dealerId,
  similarDeal
}) {
  // const arrZipCode = zipCode && zipCode.split(',').map((item) => item.trim())
  const params = {
    zipCode,
    zipRadius,
    price,
    year,
    mileage,
    make,
    model,
    body_type: trim,
    transmission,
    color,
    vehicleType,
    certified_vehicles: certifiedVehicles,
    keyword,
    dealer_id: dealerId,
    similarDeal
  }
  return HttpClientService.post('filter-values', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getByFeature({
  zipCode,
  zipRadius,
  price,
  year,
  mileage,
  make,
  model,
  trim,
  transmission,
  rowsPerPage: limit,
  page,
  color,
  vehicleType,
  certifiedVehicles,
  sortBy,
  orderBy,
  keyword,
  similarDeal,
  showFavorites
}) {
  // const arrZipCode = zipCode && zipCode.split(',').map((item) => item.trim())
  const params = {
    zipCode,
    zipRadius,
    price,
    year,
    mileage,
    make,
    model,
    body_type: trim,
    transmission,
    per_page: limit,
    page,
    color,
    vehicleType,
    certified_vehicles: certifiedVehicles,
    sortBy,
    orderBy,
    keyword,
    similarDeal,
    showFavorites
  }

  return HttpClientService.post('search-by-feature', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getSevenHundredCredit({
  dealerId,
  customerId,
  firstName,
  lastName,
  email,
  mobilePhone,
  address,
  city,
  state,
  zip,
  ssn
}) {
  const params = queryString.stringify({
    dealer_id: dealerId,
    customer_id: customerId,
    first_name: firstName,
    last_name: lastName,
    email,
    mobile: mobilePhone,
    address,
    city,
    state,
    zip,
    ssn
  })
  return HttpService.post('seven-hundred-credit-get-pre-qualified', params).then((result) => {
    const { data } = result
    return data
  })
}
