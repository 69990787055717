import queryString from 'query-string'
import { KBB_VEHICLE_TYPE } from 'src/constants/constants'
import { checkIsEmptyData } from 'src/utils'
import { getPublicIp } from '../utils'
import HttpService from './http.service'
import HttpClientService from './httpClient.service'

export function getVehicles() {
  return HttpService.get('get-vehicles').then((result) => {
    const { data } = result
    return data
  })
}

export function getVehiclesBy({ dealerId, zip, zipRadius, year, make, model }) {
  const params = queryString.stringify({
    dealerId,
    zip,
    zipRadius,
    year,
    make,
    model
  })
  return HttpService.get(`get-vehicles-by?${params}`).then((result) => {
    const { data } = result
    return data
  })
}

const notAvailable = 'Not available'

export function getVehicleDetail(id) {
  return HttpClientService.get(`get-vehicle-detail/${id}`).then((result) => {
    const { data } = result
    const { vehicleDetail } = data.data
    const fullVehicle = {
      ...vehicleDetail,
      miles: checkIsEmptyData(vehicleDetail.miles) ? vehicleDetail.miles || notAvailable : vehicleDetail.miles,
      vin: checkIsEmptyData(vehicleDetail.vin) ? vehicleDetail.vin || notAvailable : vehicleDetail.vin,
      body: checkIsEmptyData(vehicleDetail.body) ? vehicleDetail.body || notAvailable : vehicleDetail.body,
      style: checkIsEmptyData(vehicleDetail.style) ? vehicleDetail.style || notAvailable : vehicleDetail.style,
      engine_name: checkIsEmptyData(vehicleDetail.engine_name)
        ? vehicleDetail.engine_name || notAvailable
        : vehicleDetail.engine_name,
      transmission_name: checkIsEmptyData(vehicleDetail.transmission_name)
        ? vehicleDetail.transmission_name || notAvailable
        : vehicleDetail.transmission_name,
      stock_number: checkIsEmptyData(vehicleDetail.stock_number)
        ? vehicleDetail.stock_number || notAvailable
        : vehicleDetail.stock_number,
      exterior_color: checkIsEmptyData(vehicleDetail.exterior_color)
        ? vehicleDetail.exterior_color || notAvailable
        : vehicleDetail.exterior_color,
      interior_color: checkIsEmptyData(vehicleDetail.interior_color)
        ? vehicleDetail.interior_color || notAvailable
        : vehicleDetail.interior_color,
      year: checkIsEmptyData(vehicleDetail.year) ? vehicleDetail.year || notAvailable : vehicleDetail.year,
      make: checkIsEmptyData(vehicleDetail.make) ? vehicleDetail.make || notAvailable : vehicleDetail.make,
      model: checkIsEmptyData(vehicleDetail.model) ? vehicleDetail.model || notAvailable : vehicleDetail.model
    }
    return { ...data, data: { ...data.data, vehicleDetail: { ...vehicleDetail, vehicle: fullVehicle } } }
  })
}

export function getVehicleDetailAndOptions(id) {
  return HttpClientService.get(`get-vehicle-details-and-options/${id}`).then((result) => {
    const { data } = result
    const { vehicleDetail } = data.data
    const fullVehicle = {
      ...vehicleDetail,
      miles: checkIsEmptyData(vehicleDetail.miles) ? vehicleDetail.miles || notAvailable : vehicleDetail.miles,
      vin: checkIsEmptyData(vehicleDetail.vin) ? vehicleDetail.vin || notAvailable : vehicleDetail.vin,
      body: checkIsEmptyData(vehicleDetail.body) ? vehicleDetail.body || notAvailable : vehicleDetail.body,
      style: checkIsEmptyData(vehicleDetail.style) ? vehicleDetail.style || notAvailable : vehicleDetail.style,
      engine_name: checkIsEmptyData(vehicleDetail.engine_name)
        ? vehicleDetail.engine_name || notAvailable
        : vehicleDetail.engine_name,
      transmission_name: checkIsEmptyData(vehicleDetail.transmission_name)
        ? vehicleDetail.transmission_name || notAvailable
        : vehicleDetail.transmission_name,
      stock_number: checkIsEmptyData(vehicleDetail.stock_number)
        ? vehicleDetail.stock_number || notAvailable
        : vehicleDetail.stock_number,
      exterior_color: checkIsEmptyData(vehicleDetail.exterior_color)
        ? vehicleDetail.exterior_color || notAvailable
        : vehicleDetail.exterior_color,
      interior_color: checkIsEmptyData(vehicleDetail.interior_color)
        ? vehicleDetail.interior_color || notAvailable
        : vehicleDetail.interior_color,
      year: checkIsEmptyData(vehicleDetail.year) ? vehicleDetail.year || notAvailable : vehicleDetail.year,
      make: checkIsEmptyData(vehicleDetail.make) ? vehicleDetail.make || notAvailable : vehicleDetail.make,
      model: checkIsEmptyData(vehicleDetail.model) ? vehicleDetail.model || notAvailable : vehicleDetail.model
    }
    return { ...data, data: { ...data.data, vehicleDetail: { ...vehicleDetail, vehicle: fullVehicle } } }
  })
}

export function getRelatedMakeVehiclesWithYearRange(id, filterDealer) {
  const params = queryString.stringify({
    filter_dealer: filterDealer
  })
  return HttpService.get(`get-related-make-vehicles-with-year-range/${id}?${params}`)
}

export function getMakes() {
  return HttpService.get('get-makes').then((result) => {
    const { data } = result
    return data
  })
}

export function getYearsMakesModels() {
  return HttpService.get('get-years-makes-models').then((result) => {
    const { data } = result
    return data
  })
}

export function getModelsByMake(make) {
  const params = {
    make
  }
  return HttpService.post('models-by-make', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getRelatedPriceVehicles(id, filterDealer) {
  const params = queryString.stringify({
    filter_dealer: filterDealer
  })
  return HttpService.get(`get-related-price-vehicles/${id}?${params}`)
}

export function getSearchSuggestionKBB({ keyword, vehicleType }) {
  const params = {
    keyword,
    type: vehicleType === KBB_VEHICLE_TYPE.AUTOMOTIVE ? 'automotive' : 'powersport'
  }
  return HttpService.post('kbb/search', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getPhotos({ id }) {
  return HttpService.get(`vehicle-photos/${id}`).then((result) => {
    const { data } = result
    return data
  })
}

export async function getZipCodeBaseIpAddress() {
  const ip = await getPublicIp()
  const header = new Headers({ 'Access-Control-Allow-Origin': '*' })
  return HttpService.get(`zip/get-by-ip/${ip}`, { header }, { mode: 'no-cors' }).then((result) => {
    const { data } = result
    return data
  })
}

export function getVehicleByVin(vinCode) {
  const params = queryString.stringify({
    vin: vinCode
  })
  return HttpService.get(`get-vehicle-by-vin?${params}`).then((result) => {
    const { data } = result
    return data
  })
}

export function getVehicleVinInfo(vinCode) {
  return HttpService.get(`get-vehicle-info-by-vin/${vinCode}`).then((result) => {
    const { data } = result
    return data
  })
}

export function partialUpdateVehicle(id, data) {
  return HttpService.put(`partial-update-vehicle/${id}`, JSON.stringify(data), {
    'Content-type': 'application/json',
    Accept: 'application/json'
  }).then((result) => {
    const { data } = result
    return data
  })
}

export function addVehicle(dealerId) {
  const params = {
    dealer_id: dealerId
  }
  return HttpService.post('add-vehicle', params).then((result) => {
    const { data } = result
    return data
  })
}

export function removeImage(vehicleId, fileName) {
  return HttpService.post(
    `vehicle-remove-image/${vehicleId}`,
    { file: fileName.thumbUrl },
    { 'Content-type': 'application/json', Accept: 'application/json' }
  ).then((result) => {
    const { data } = result
    return data
  })
}

export function shareVehicleLinkEmail({ vehicleId, email, recipientEmail, firstName, lastName }) {
  const params = {
    vehicle_id: vehicleId,
    email,
    recipient_email: recipientEmail,
    first_name: firstName,
    last_name: lastName
  }
  return HttpService.post('vehicles/send-share-vehicle-link-email', params).then((result) => {
    const { data } = result
    return data
  })
}

export function shareVehicleLinkEmailCheck({ vehicleId, email, recipientEmail, firstName, lastName }) {
  const params = {
    vehicle_id: vehicleId,
    email,
    recipient_email: recipientEmail,
    first_name: firstName,
    last_name: lastName
  }
  return HttpService.post('vehicles/send-share-vehicle-link-email-check', params).then((result) => {
    const { data } = result
    return data
  })
}

export function shareVehicleLinkPhone({ vehicleId, phone, recipientPhone, firstName, lastName }) {
  const params = {
    vehicle_id: vehicleId,
    phone,
    recipient_phone: recipientPhone,
    first_name: firstName,
    last_name: lastName
  }
  return HttpService.post('vehicles/send-share-vehicle-link-phone', params).then((result) => {
    const { data } = result
    return data
  })
}

export function shareVehicleLinkPhoneCheck({ vehicleId, phone, recipientPhone, firstName, lastName }) {
  const params = {
    vehicle_id: vehicleId,
    phone,
    recipient_phone: recipientPhone,
    first_name: firstName,
    last_name: lastName
  }
  return HttpService.post('vehicles/send-share-vehicle-link-phone-check', params).then((result) => {
    const { data } = result
    return data
  })
}

export function setVehicleOrderImage(order) {
  return HttpService.post('vehicle-order-image', order).then((result) => {
    const { data } = result
    return data
  })
}
