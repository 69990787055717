import { Button, Form, Select, notification, Input } from 'antd'
import 'antd/dist/antd.css'
import React, { useEffect, useState } from 'react'
import {
  PSX_AUTH_TOKEN_IS_ACTIVE_OPTIONS,
  SHOW_NIADA_DEALERS_OPTIONS,
  SHOW_PROMO_VIDEO_OPTIONS
} from 'src/constants/constants'
import {
  updatePsxAuthTokenSettings,
  updateShowNiadaDealersSetting,
  updateShowPromoVideoSetting
} from 'src/services/admin.service'
import { useAuthorizationContext } from '../../../AuthorizationProvider'

const { Option } = Select

const optionsPsxAuthTokenIsActive = PSX_AUTH_TOKEN_IS_ACTIVE_OPTIONS
const optionsNiadaDealers = SHOW_NIADA_DEALERS_OPTIONS
const optionsPromoVideo = SHOW_PROMO_VIDEO_OPTIONS

export function ConfigurableSettings() {
  const { adminAccount, setAdminAccount } = useAuthorizationContext()

  const [psxAuthTokenIsActive, setPsxAuthTokenIsActive] = useState('NO')
  const [psxAuthToken, setPsxAuthToken] = useState('')

  const configurableSettings = adminAccount?.configurable_settings || {}
  const [showNiadaDealers, setShowNiadaDealers] = useState(configurableSettings?.show_niada_dealers || null)
  const [showPromoVideo, setShowPromoVideo] = useState(configurableSettings?.show_promo_video || null)

  useEffect(() => {
    if (adminAccount) {
      const isActive = adminAccount?.psx_auth_token?.is_active || false
      const token = adminAccount?.psx_auth_token?.token || ''
      setPsxAuthTokenIsActive(isActive ? 'YES' : 'NO')
      setPsxAuthToken(token)
    }
  }, [adminAccount])

  const getShowNiadaDealers = () => {
    if (showNiadaDealers === null) {
      return configurableSettings?.show_niada_dealers || optionsNiadaDealers[0].value
    }
    return showNiadaDealers
  }
  const getShowPromoVideo = () => {
    if (showPromoVideo === null) {
      return configurableSettings?.show_promo_video || optionsPromoVideo[0].value
    }
    return showPromoVideo
  }

  const openNotification = (type) => {
    const action = type === 'success' ? notification.success : notification.error
    const message = type === 'success' ? 'Action successful' : 'Action failed'
    action({
      message,
      placement: 'topRight'
    })
  }

  const [formPsxAuthToken] = Form.useForm()
  const [formNiadaDealers] = Form.useForm()
  const [formPromoVideo] = Form.useForm()

  const onPsxAuthTokenIsActiveChange = (value) => {
    setPsxAuthTokenIsActive(value)
    formPsxAuthToken.setFieldsValue({ ...formPsxAuthToken.getFieldValue(), psx_auth_token_is_active: value })
  }

  const onPsxAuthTokenChange = (value) => {
    setPsxAuthToken(value)
    formPsxAuthToken.setFieldsValue({ ...formPsxAuthToken.getFieldValue(), psx_auth_token: value })
  }

  const onShowNiadaDealersChange = (value) => {
    setShowNiadaDealers(value)
    formNiadaDealers.setFieldsValue({ ...formNiadaDealers.getFieldValue(), setting_show_niada_dealers: value })
  }
  const onShowPromoVideoChange = (value) => {
    setShowPromoVideo(value)
    formPromoVideo.setFieldsValue({ ...formPromoVideo.getFieldValue(), setting_show_promo_video: value })
  }

  const handleFormSubmissionPsxAuthToken = () => {
    updatePsxAuthTokenSettings({ isActive: psxAuthTokenIsActive, token: psxAuthToken })
      .then((response) => {
        const data = response.data
        setAdminAccount(data)
        openNotification('success')
      })
      .catch((error) => {
        if (error.response.data.message !== 'undefined') {
          openNotification({ type: 'error', message: error.response.data.message })
        }
      })
  }

  const handleFormSubmissionNiadaDealers = () => {
    updateShowNiadaDealersSetting(showNiadaDealers)
      .then((response) => {
        const data = response.data
        setAdminAccount(data)
        openNotification('success')
      })
      .catch((error) => {
        if (error.response.data.message !== 'undefined') {
          openNotification({ type: 'error', message: error.response.data.message })
        }
      })
  }
  const handleFormSubmissionPromoVideo = () => {
    updateShowPromoVideoSetting(showPromoVideo)
      .then((response) => {
        const data = response.data
        setAdminAccount(data)
        openNotification('success')
      })
      .catch((error) => {
        if (error.response.data.message !== 'undefined') {
          openNotification({ type: 'error', message: error.response.data.message })
        }
      })
  }

  return (
    <div className="container">
      <p className="font-semibold text-xl col-span-2 mb-3">Configurable Settings</p>
      <div className="mx-auto my-10 px-4 rounded-xl border-2 py-4">
        <div>Show Niada Dealers in Inventory</div>
        <div className="ant-table-wrapper mt-5">
          <Form
            form={formNiadaDealers}
            layout="vertical"
            name="vehicle-ref"
            className="mx-4 lg:px-8 items-center justify-center"
            onFinish={handleFormSubmissionNiadaDealers}
          >
            <Select
              name="setting_show_niada_dealers"
              placeholder={optionsNiadaDealers[0].name || 'Select Show Niada Dealers option'}
              onChange={(e) => onShowNiadaDealersChange(e)}
              value={getShowNiadaDealers()}
              className="w-full mt-3"
            >
              {optionsNiadaDealers.map((option) => (
                <Option key={option.name} value={option.value}>
                  {option.name}
                </Option>
              ))}
            </Select>
            <Form.Item className="mt-8 items-center" shouldUpdate>
              {() => (
                <Button type="primary" htmlType="submit" className="h-12 rounded-xl">
                  Update
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="mx-auto my-10 px-4 rounded-xl border-2 py-4">
        <div>Show Promo Video in Claim Process</div>
        <div className="ant-table-wrapper mt-5">
          <Form
            form={formPromoVideo}
            layout="vertical"
            name="vehicle-ref"
            className="mx-4 lg:px-8 items-center justify-center"
            onFinish={handleFormSubmissionPromoVideo}
          >
            <Select
              name="setting_show_promo_video"
              placeholder={optionsPromoVideo[0].name || 'Select Show Promo Video option'}
              onChange={(e) => onShowPromoVideoChange(e)}
              value={getShowPromoVideo()}
              className="w-full mt-3"
            >
              {optionsPromoVideo.map((option) => (
                <Option value={option.value} key={option.name}>
                  {option.name}
                </Option>
              ))}
            </Select>
            <Form.Item className="mt-8 items-center" shouldUpdate>
              {() => (
                <Button type="primary" htmlType="submit" className="h-12 rounded-xl">
                  Update
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="mx-auto my-10 px-4 rounded-xl border-2 py-4">
        <div>PSX Auth Token</div>
        <div className="ant-table-wrapper mt-5">
          <Form
            form={formPsxAuthToken}
            layout="vertical"
            name="vehicle-ref"
            className="mx-4 lg:px-8 items-center justify-center"
            onFinish={handleFormSubmissionPsxAuthToken}
          >
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Is Active</label>
            <Select
              name="psx_auth_token_is_active"
              placeholder={optionsPsxAuthTokenIsActive[0].name || 'Select Psx Auth Token Is Active option'}
              onChange={(e) => onPsxAuthTokenIsActiveChange(e)}
              value={psxAuthTokenIsActive}
              className="w-full mt-3 mb-3"
            >
              {optionsPsxAuthTokenIsActive.map((option) => (
                <Option value={option.value} key={option.name}>
                  {option.name}
                </Option>
              ))}
            </Select>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Token Value</label>
            <Input
              name="psx_auth_token"
              placeholder="Paste in PSX Auth Token here"
              className="w-full mt-3"
              type="text"
              onChange={(e) => onPsxAuthTokenChange(e.target.value)}
              value={psxAuthToken}
            />
            <Form.Item className="mt-8 items-center" shouldUpdate>
              {() => (
                <Button type="primary" htmlType="submit" className="h-12 rounded-xl">
                  Update
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default ConfigurableSettings
