import { notification } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { EzDealz } from 'src/components/DealerDashboard/EzDealz'
import DealerHeaderDashboard from 'src/components/DealerDashboard/HeaderDashboard'
import Header from 'src/components/Header'
import * as AdminServices from 'src/services/admin.service'
import * as CustomerServices from 'src/services/customer.service'
import * as DealerServices from 'src/services/dealer.service'
import * as UserServices from 'src/services/user.service'
import * as VehicleService from 'src/services/vehicle.service'
import { useAuthorizationContext } from '../../AuthorizationProvider'
import ProtectedRoute from '../../ProtectedRoute'
import HeaderDashboard from '../../components/AccountDashboard/HeaderDashboard'
import AdminHeaderDashboard from '../../components/AdminDashboard/HeaderDashboard'
import DealerVehicle from '../../components/DealerDashboard/DealerVehicle'
import Footer from '../../components/Footer'
import ScrollTopButton from '../../components/ScrollTopButton'
import { STORAGE_KEYS, USER_ROLES } from '../../constants/constants'
import AccountDashboard from '../AccountDashboard'
import AdminDashboard from '../AdminDashboard'
import AdminDealers from '../AdminDealers'
import AdminRelatedDealerships from '../AdminRelatedDealerships'
import AdminDealershipPaymentSettings from '../AdminDealershipPaymentSettings'
import AdminPaymentSettings from '../AdminPaymentSettings'
import Buy from '../Buy'
import Contact from '../Contact'
import CreditApplicationDetailSharedByCustomer from '../CreditApplicationDetailSharedByCustomer'
import CustomerFavouriteVehicles from '../CustomerFavouriteVehicles'
import CustomerSavedOffers from '../CustomerSavedOffers'
import DealerDashboard from '../DealerDashboard'
import Dealership from '../Dealership'
import Financing from '../Financing'
import Home from '../Home'
import MyAccount from '../MyAccount'
import SellYourCar from '../SellYourCar'
import SharedLinkHasExpired from '../SharedLinkHasExpired'
import SharingCreditApplicationToCustomer from '../SharingCreditApplicationToCustomer'
import TermsPrivacy from '../TermsPrivacy'
import TradeInVehicleDetail from '../TradeInVehicleDetail'
import ValueYourTradeIn from '../ValueYourTradeIn'
import Vehicle from '../Vehicle'
import { save } from '../../lib/storage'

function Main() {
  const {
    setUserLocation,
    setFavouriteVehicles,
    setFavouriteDealers,
    customerLogin,
    dealerLogin,
    adminLogin,
    userLogin,
    settings,
    customerAccount,
    setCustomerAccount,
    dealershipAccount,
    setDealershipAccount,
    setAdminAccount,
    setUserAccount,
    accountSettings,
    setAccountSettings
  } = useAuthorizationContext()
  const dealerHeaderScrollRef = useRef({})
  const [activeKey, setActiveKey] = useState('1')
  const location = useLocation()

  const openNotification = (type) => {
    const action = type === 'success' ? notification.success : notification.error
    const message = type === 'success' ? 'Logged in successfully' : 'Incorrect user/email or password'
    action({
      message,
      placement: 'topRight'
    })
  }

  const userRole = settings && settings.role ? settings.role : USER_ROLES.GUEST

  useEffect(() => {
    if (location.pathname.includes('/shared-vehicles/')) {
      sessionStorage.setItem(STORAGE_KEYS.IS_SHARED_BY_DEALERSHIP, 'true')
    }
  }, [location.pathname])

  useEffect(() => {
    sessionStorage.removeItem(STORAGE_KEYS.INITIAL_FILTER_ZIP_CODE)
    VehicleService.getZipCodeBaseIpAddress()
      .then((response) => {
        setUserLocation(response.data)
        sessionStorage.setItem(STORAGE_KEYS.INITIAL_FILTER_ZIP_CODE, response.data.zipcode)
      })
      .catch((error) => {
        setUserLocation({})
        openNotification({ message: error.message, type: 'error' })
      })
    if (userLogin) {
      UserServices.getInfo()
        .then((response) => {
          const { data } = response
          if (userRole === USER_ROLES.ADMIN) {
            setAdminAccount(data)
            setDealershipAccount({ employeeInfo: data, dealership: data })
          } else if (userRole === USER_ROLES.DEALERSHIP_ADMIN || userRole === USER_ROLES.DEALERSHIP_USER) {
            setDealershipAccount({ employeeInfo: data, dealership: data })
          } else if (userRole === USER_ROLES.CUSTOMER) {
            setCustomerAccount(data)
          }
          setUserAccount(data)
          setAccountSettings(data)
        })
        .catch((error) => {
          openNotification({ message: error.message, type: 'error' })
        })
    } else if (dealerLogin) {
      DealerServices.getDealerShipDashboardInfo()
        .then((response) => {
          const {
            data: { employeeInfo, dealership, leads }
          } = response
          const totalLeads = leads?.total || 0
          setDealershipAccount({ ...employeeInfo, ...dealership, totalLeads })
          setAccountSettings(dealership)
        })
        .catch((error) => {
          openNotification({ message: error.message, type: 'error' })
        })
    } else if (customerLogin) {
      CustomerServices.getCustomerDashboardInfo()
        .then((response) => {
          const { data } = response
          setCustomerAccount(data)
          setAccountSettings({ ...data })
          const budgetCalculator = data?.customer_budget_calculator ?? {}
          save(STORAGE_KEYS.EZ_DEALZ_BUDGET, JSON.stringify(budgetCalculator))
        })
        .catch((error) => {
          openNotification({ message: error.message, type: 'error' })
        })
      CustomerServices.getFavoriteVehiclesByCustomer()
        .then((response) => {
          setFavouriteVehicles(response)
        })
        .catch((error) => {
          openNotification({ message: error.message, type: 'error' })
        })
      CustomerServices.getFavoriteDealersByCustomer()
        .then((response) => {
          setFavouriteDealers(response)
        })
        .catch((error) => {
          openNotification({ message: error.message, type: 'error' })
        })
    } else if (adminLogin) {
      AdminServices.getAdminDashboardInfo()
        .then((response) => {
          const { data } = response
          setAdminAccount(data)
          setAccountSettings({ ...data })
        })
        .catch((error) => {
          openNotification({ message: error.message, type: 'error' })
        })
    }
  }, [customerLogin, dealerLogin, adminLogin, userLogin])

  let header = null
  let loggedIn = true
  if (customerLogin) {
    header = <HeaderDashboard customerAccount={customerAccount} />
  } else if (dealerLogin) {
    header = (
      <DealerHeaderDashboard
        scrollTo={(index) => dealerHeaderScrollRef?.current?.handleScrollTo(index)}
        dealershipAccount={dealershipAccount}
        setActiveKey={setActiveKey}
        activeKey={activeKey}
      />
    )
  } else if (adminLogin) {
    header = <AdminHeaderDashboard />
  } else if (userLogin && userRole === USER_ROLES.ADMIN) {
    header = <AdminHeaderDashboard />
  } else if (userLogin && (userRole === USER_ROLES.DEALERSHIP_ADMIN || userRole === USER_ROLES.DEALERSHIP_ADMIN)) {
    header = (
      <DealerHeaderDashboard
        scrollTo={(index) => dealerHeaderScrollRef?.current?.handleScrollTo(index)}
        dealershipAccount={dealershipAccount}
        setActiveKey={setActiveKey}
        activeKey={activeKey}
      />
    )
  } else if (userLogin && userRole === USER_ROLES.CUSTOMER) {
    header = <HeaderDashboard customerAccount={customerAccount} />
  } else {
    loggedIn = false
    header = <Header />
  }

  let userSettings = settings && settings.settings
  if (!userSettings && accountSettings) {
    userSettings = accountSettings.settings
  }

  const sharedByDealership = sessionStorage.getItem(STORAGE_KEYS.IS_SHARED_BY_DEALERSHIP) === 'true'

  return (
    <div>
      <div className="w-screen relative">
        {header}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/buy" element={<Buy />} />
          <Route path="/buy/:make" element={<Buy />} />
          <Route path="/buy/:make/:model" element={<Buy />} />
          <Route path="/buy/:make/:model/:year" element={<Buy />} />
          <Route path="/buy/:make/:model/:year/:keyword" element={<Buy />} />
          <Route path="/vehicles/:id" element={<Vehicle />} />
          <Route path="/shared-vehicles/:id" element={<Vehicle />} />
          <Route path="/dealership/:id" element={<Dealership />} />
          <Route path="/c-pop/:index" element={<EzDealz />} />
          <Route path="/sell" element={<SellYourCar />} />
          <Route path="/finance" element={<Financing />} />
          <Route path="/sharing-a-trade-in-to-customer" element={<ValueYourTradeIn />} />
          <Route path="/link-expired" element={<SharedLinkHasExpired />} />
          <Route path="/sharing-a-vehicle-to-dealer" element={<TradeInVehicleDetail />} />
          <Route path="/sharing-credit-application-to-customer" element={<SharingCreditApplicationToCustomer />} />
          <Route path="/sharing-credit-application-to-dealer" element={<CreditApplicationDetailSharedByCustomer />} />
          <Route
            path="/dealer-vehicles/:id"
            element={<DealerVehicle setActiveKey={setActiveKey} activeKey={activeKey} />}
          />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms-privacy" element={<TermsPrivacy />} />

          <Route
            element={
              <ProtectedRoute location="my-account" loginState={loggedIn} settings={userSettings} role={userRole} />
            }
          >
            <Route path="/my-account" element={<MyAccount />} />
          </Route>

          <Route
            element={
              <ProtectedRoute location="my-favourites" loginState={loggedIn} settings={userSettings} role={userRole} />
            }
          >
            <Route path="/my-favourites" element={<CustomerFavouriteVehicles />} />
          </Route>

          <Route
            element={
              <ProtectedRoute location="saved-offers" loginState={loggedIn} settings={userSettings} role={userRole} />
            }
          >
            <Route path="/saved-offers" element={<CustomerSavedOffers />} />
          </Route>

          <Route
            element={
              <ProtectedRoute
                location="customer-dashboard"
                loginState={loggedIn}
                settings={userSettings}
                role={userRole}
              />
            }
          >
            <Route path="/customer-dashboard" element={<AccountDashboard />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                location="dealership-users"
                loginState={loggedIn}
                settings={userSettings}
                role={userRole}
              />
            }
          >
            <Route path="/dealership-users" element={<AdminDealers />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                location="related-dealerships"
                loginState={loggedIn}
                settings={userSettings}
                role={userRole}
              />
            }
          >
            <Route path="/related-dealerships/:id" element={<AdminRelatedDealerships />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                location="dealership-payment-settings"
                loginState={loggedIn}
                settings={userSettings}
                role={userRole}
              />
            }
          >
            <Route path="/dealership-payment-settings" element={<AdminDealershipPaymentSettings />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                location="dealer-dashboard"
                loginState={loggedIn}
                settings={userSettings}
                role={userRole}
              />
            }
          >
            <Route
              path="/dealer-dashboard"
              element={
                <DealerDashboard
                  dealerHeaderScrollRef={dealerHeaderScrollRef}
                  setActiveKey={setActiveKey}
                  activeKey={activeKey}
                />
              }
            />
          </Route>
          <Route
            element={
              <ProtectedRoute location="lead-details" loginState={loggedIn} settings={userSettings} role={userRole} />
            }
          >
            <Route path="/lead-details/:index" element={<EzDealz />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                location="admin-dashboard"
                loginState={loggedIn}
                settings={userSettings}
                role={userRole}
              />
            }
          >
            <Route path="/admin-dashboard" element={<AdminDashboard />} />
          </Route>
          <Route
            element={
              <ProtectedRoute location="admin-dealers" loginState={loggedIn} settings={userSettings} role={userRole} />
            }
          >
            <Route path="/admin-dealers" element={<AdminDealers />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                location="admin-payment-settings"
                loginState={loggedIn}
                settings={userSettings}
                role={userRole}
              />
            }
          >
            <Route path="/admin-payment-settings" element={<AdminPaymentSettings />} />
          </Route>
          <Route
            element={
              <ProtectedRoute location="add-vehicle" loginState={loggedIn} settings={userSettings} role={userRole} />
            }
          >
            <Route
              path="/add-vehicle/:id"
              element={<DealerVehicle setActiveKey={setActiveKey} activeKey={activeKey} createNewVehicle />}
            />
          </Route>
        </Routes>
        {!sharedByDealership ? <Footer /> : <div className="py-5" />}
        <ScrollTopButton />
      </div>
    </div>
  )
}

export default Main
