import { Button, Form, Select } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { CONDITION, STORAGE_KEYS } from 'src/constants/constants'
import { load, save } from 'src/lib/storage'
import { flatten, groupBy } from 'src/utils'
import kelleyImage from '../../../assets/kbb-logo.png'
import * as KBBPowersAutomobileServices from '../../../services/kbb-automobile.service'
import './style.scss'

const { Option } = Select

function EstimatedValue({ setMyWalletView }) {
  const [form] = Form.useForm()
  const [lookups, updateLookups] = useState({
    years: [],
    makes: [],
    models: [],
    trims: []
  })

  const [selectedData, updateSelectedData] = useState({
    year: null,
    make: null,
    model: null,
    trim: null,
    condition: null,
    mileage: null,
    zip: null,
    estimatedValue: null,
    makeName: null,
    modelName: null,
    trimName: null
  })
  const [, forceUpdate] = useState({})
  const [cookies, setCookie, removeCookie] = useCookies()
  const [tradeVehicle, setTradeVehicle] = useState({})
  const previousValue = load(STORAGE_KEYS.EZ_DEALZ_BUDGET) || {}

  const handleFormSubmission = async () => {
    if (
      selectedData.year != null &&
      selectedData.make != null &&
      selectedData.model != null &&
      selectedData.trim != null &&
      selectedData.estimatedValue == null
    ) {
      const vehicle = await KBBPowersAutomobileServices.getVehicles({
        yearId: selectedData.year,
        makeId: selectedData.make,
        modelId: selectedData.model,
        trimId: selectedData.trim
      })
      setTradeVehicle(vehicle.data[0])
      const vehicleId = vehicle.data[0].vehicleId
      const vehicleOptions = await KBBPowersAutomobileServices.getVehicleOptions({ vehicleId })
      const equipments = groupBy(vehicleOptions.data, 'categoryGroup')
      const configurationChanges = []
      const vehicleOptionIds = []
      flatten(Object.values(equipments)).forEach((obj, index) => {
        if (obj.vehicleOptionId !== 'otherColorId') {
          vehicleOptionIds.push(obj.vehicleOptionId)
          configurationChanges.push({
            sequence: index + 1,
            vehicleOptionId: obj.vehicleOptionId,
            action: obj.selected ? 'selected' : 'deselected'
          })
        }
      })
      const defaultConfiguration = await KBBPowersAutomobileServices.postApplyConfiguration({
        vehicleId,
        vehicleOptionIds,
        configurationChanges
      })
      const { finalConfiguration } = defaultConfiguration.data
      const vehicleValue = await KBBPowersAutomobileServices.getFinalVehicleValue(
        finalConfiguration,
        selectedData.mileage,
        selectedData.zip
      )

      let price = 0
      const priceObj =
        vehicleValue.data.prices.find(
          (item) => item.condition?.toLowerCase() === selectedData.condition.toLowerCase()
        ) || {}

      if (priceObj.configuredValue !== undefined) {
        price = priceObj.configuredValue
        updateSelectedData({
          ...selectedData,
          estimatedValue: price,
          makeName: vehicle.data[0].makeName,
          modelName: vehicle.data[0].modelName,
          trimName: vehicle.data[0].trimName
        })
      }
    }
  }

  const estimatedForm = [
    {
      label: 'Year',
      name: 'yearId',
      required: true,
      type: 'select',
      half: true,
      options: lookups.years,
      defaultValue: selectedData.year,
      placeholder: 'Select Year'
    },
    {
      label: 'Make',
      name: 'makeName',
      value: 'makeId',
      preValue: 'year',
      required: true,
      type: 'select',
      half: true,
      options: lookups.makes,
      defaultValue: selectedData.makeName || selectedData.make,
      placeholder: 'Select Make'
    },
    {
      label: 'Model',
      name: 'modelName',
      value: 'modelId',
      required: true,
      type: 'select',
      preValue: 'make',
      options: lookups.models,
      defaultValue: selectedData.modelName || selectedData.model,
      placeholder: 'Select Model'
    },
    {
      label: 'Trim',
      name: 'trimName',
      value: 'trimId',
      required: true,
      type: 'select',
      preValue: 'model',
      options: lookups.trims,
      defaultValue: selectedData.trimName || selectedData.trim,
      placeholder: 'Select Trim'
    },
    {
      label: 'Condition',
      name: 'name',
      required: true,
      type: 'select',
      options: CONDITION,
      defaultValue: selectedData.condition,
      placeholder: 'Select a condition'
    },
    {
      label: 'Mileage',
      name: 'mileage',
      required: true,
      half: true,
      defaultValue: selectedData.mileage,
      type: 'number'
    },
    {
      label: 'Zip',
      name: 'zip',
      required: true,
      half: true,
      defaultValue: selectedData.zip,
      type: 'number'
    }
  ]

  const handleGetYears = () => {
    KBBPowersAutomobileServices.getYears({})
      .then((response) =>
        updateLookups({
          ...lookups,
          years: response.data
        })
      )
      .catch((error) => console.error(error))
  }

  const getMakeList = (yearId) => {
    KBBPowersAutomobileServices.getMakes({ yearId })
      .then((response) =>
        updateLookups({
          ...lookups,
          makes: response.data
        })
      )
      .catch((error) => console.error(error))
  }

  const getModelist = (makeId) => {
    const yearId = selectedData.year
    KBBPowersAutomobileServices.getAutomobileModels({ yearId, makeId })
      .then((response) =>
        updateLookups({
          ...lookups,
          models: response.data
        })
      )
      .catch((error) => console.error(error))
  }

  const getTrimList = (modelId) => {
    const yearId = selectedData.year
    const makeId = selectedData.make
    KBBPowersAutomobileServices.getAutomobileTrims({ yearId, makeId, modelId })
      .then((response) =>
        updateLookups({
          ...lookups,
          trims: response.data
        })
      )
      .catch((error) => console.error(error))
  }

  const handleOptionChange = (label, value) => {
    switch (label) {
      case 'yearId':
        updateSelectedData({
          ...selectedData,
          year: value,
          make: null,
          makeName: null,
          model: null,
          modelName: null,
          trim: null,
          trimName: null,
          condition: null,
          mileage: null,
          zip: null,
          estimatedValue: null
        })
        setCookie('trade_in', { year: value }, { path: '/' })
        getMakeList(value)
        break
      case 'makeName':
        updateSelectedData({
          ...selectedData,
          make: value,
          model: null,
          trim: null,
          condition: null
        })
        getModelist(value)
        break
      case 'modelName':
        updateSelectedData({
          ...selectedData,
          model: value,
          trim: null,
          condition: null
        })
        getTrimList(value)
        break
      case 'trimName':
        updateSelectedData({
          ...selectedData,
          trim: value,
          condition: null
        })
        break
      case 'name':
        updateSelectedData({
          ...selectedData,
          condition: value
        })
        break
      default:
        break
    }
  }

  const handleInputChange = (label, event) => {
    switch (label) {
      case 'mileage':
        updateSelectedData({
          ...selectedData,
          mileage: event.target.value
        })
        break
      case 'zip':
        updateSelectedData({
          ...selectedData,
          zip: event.target.value
        })
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (cookies.trade_in && previousValue.selectedData !== undefined) {
      updateSelectedData(cookies.trade_in)
    }
    forceUpdate({})
    handleGetYears()
  }, [])

  useEffect(() => {
    if (selectedData.estimatedValue) {
      setCookie('trade_in', selectedData, { path: '/' })
      save(
        STORAGE_KEYS.EZ_DEALZ_BUDGET,
        JSON.stringify({ ...previousValue, selectedData, tradeIn: selectedData.estimatedValue })
      )
    }
  }, [selectedData])

  const onClickCancel = () => {
    removeCookie('trade_in')
    setTradeVehicle({})
    updateSelectedData({})
    setMyWalletView(0)
    save(STORAGE_KEYS.EZ_DEALZ_BUDGET, JSON.stringify({ ...previousValue, selectedData, tradeIn: 0 }))
  }

  const renderForm = (form, index) => {
    switch (form.type) {
      case 'select':
        return (
          <Select
            placeholder={form.placeholder || `Select $${form.name}`}
            onChange={(e) => handleOptionChange(form.name, e)}
            allowClear
            showSearch
            optionFilterProp="children"
            disabled={selectedData[form.preValue] === null}
            size="large"
            value={form.defaultValue}
          >
            {form.options.map((option) => {
              return (
                <Option value={option[form.value || form.name]} className="p-2">
                  {option[form.name]}
                </Option>
              )
            })}
          </Select>
        )
      default:
        return (
          <input
            placeholder={form.label}
            name={form.name}
            autoFocus={index === 0}
            className="p-4 py-3 box-border rounded-xl border-gray-300 border focus:outline-none"
            type={form.type}
            onChange={(e) => handleInputChange(form.name, e)}
            defaultValue={form.defaultValue}
          />
        )
    }
  }

  return (
    <div className="px-8 w-full bg-white text-black">
      <div className="flex items-center">
        <img src={kelleyImage} alt="kelleyImage" width="80px" />
        <p className="font-semibold ml-2">Kelley Blue Book® Trade-In Value</p>
      </div>
      <div className="text-lg">
        {tradeVehicle && selectedData.estimatedValue
          ? `Your vehicle ${selectedData.year} ${selectedData.makeName} ${
              selectedData.modelName
            } has an approximate trade-in value of $${selectedData.estimatedValue.toLocaleString()}`
          : null}
      </div>
      <Form form={form} className="mt-4" onFinish={handleFormSubmission}>
        <span className="grid lg:grid-cols-2 grid-cols-1 gap-4">
          {estimatedForm.map((form, index) => (
            <div>
              <p className="pb-2 text-base font-semibold">{form.label}</p>
              <Form.Item
                name={form.name}
                hasFeedback
                dependencies={form?.dependencies}
                rules={[
                  {
                    required: form.required,
                    message: `${form.label} is required`
                  }
                ]}
              >
                {renderForm(form, index)}
              </Form.Item>
            </div>
          ))}
        </span>
        <span className="grid lg:grid-cols-2 grid-cols-1 gap-6 mt-5 mb-6">
          <Button onClick={onClickCancel} className="h-12 rounded-lg">
            Cancel
          </Button>
          <Form.Item className="items-center" shouldUpdate>
            {() => (
              <Button type="primary" htmlType="submit" className="h-12 rounded-lg">
                Get Trade-In Value
              </Button>
            )}
          </Form.Item>
        </span>
      </Form>
    </div>
  )
}

EstimatedValue.propTypes = {
  setMyWalletView: PropTypes.func
}

export default EstimatedValue
