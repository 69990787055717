import { Button, Form, Input, Radio, Space } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { BiChevronLeft } from 'react-icons/bi'
import Select from 'react-select'
import PhoneNumberInput from 'src/components/InputComponent/PhoneNumberInput'
import './style.scss'
import { VALID_EMAIL } from '../../../constants/constants'

const inventoryRadio = [
  {
    label: 'Inventory feed from DMS',
    value: 'dms'
  },
  {
    label: 'Inventory management system',
    value: 'management'
  },
  {
    label: 'FTP drop',
    value: 'ftp'
  },
  {
    label: 'Manual Entry',
    value: 'manual'
  }
]

const getLeadsRadio = [
  {
    label: 'ADF XML',
    value: 'adf_xml'
  },
  {
    label: 'Email',
    value: 'email'
  }
]

const useCrmRadio = [
  {
    label: 'No',
    value: false
  },
  {
    label: 'Yes',
    value: true
  }
]

const useCrmOptions = [
  'PSXDigital',
  'CRM Suite',
  'Hubspot',
  'Trailer Central',
  'Dealer Stack',
  'DXI',
  'Hyper Drive Pro',
  'e-lead',
  'ARI',
  'Microsoft Dynamics',
  'Powersports Tech',
  'Lightspeed EVO',
  'DP 360',
  'Response',
  'Nizex',
  'Higher Gear',
  'TLP - Traffic Log Pro',
  'Auto Loop',
  'VIN Solutions',
  'DealerSocket',
  'IDS',
  'Victory',
  'Vcept',
  'Other'
]

function DealershipDetails({ dealershipDetail, nextStep, backStep, setDealershipDetail }) {
  const [formInventory] = Form.useForm()
  const [formGetLeads] = Form.useForm()

  const [inventory, setInventory] = useState(undefined)
  const [getLeads, setGetLeads] = useState(undefined)
  const [useCrm, setUseCrm] = useState(undefined)
  const [selectedCrm, setSelectedCrm] = useState('')
  const [otherCrmName, setOtherCrmName] = useState('')
  const [crmErrorMessage, setCrmErrorMessage] = useState('')

  const onSubmit = async () => {
    try {
      await Promise.all([formInventory.validateFields(), formGetLeads.validateFields()])
      if (useCrm && !selectedCrm) {
        setCrmErrorMessage('Please select')
        return
      }
      setDealershipDetail({
        inventory,
        getLeads,
        useCrm,
        formInventory: formInventory.getFieldValue(),
        formGetLeads: formGetLeads.getFieldValue(),
        selectedCrm: selectedCrm === 'Other' ? otherCrmName || selectedCrm : selectedCrm
      })
      nextStep()
    } catch (e) {
      console.log(e, 'error')
    }
  }

  const onFormInventoryChange = (e) => {
    formInventory.setFieldsValue({ ...formInventory.getFieldsValue(), [e.target.name]: e.target.value })
  }

  const onGetLeadsFormChange = (e) => {
    formGetLeads.setFieldsValue({ ...formGetLeads.getFieldsValue(), [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (dealershipDetail?.email) {
      formGetLeads.setFieldsValue({ ...formGetLeads.getFieldsValue(), get_leads_email: dealershipDetail.email })
      formInventory.setFieldsValue({ ...formInventory.getFieldsValue(), inventory_email: dealershipDetail.email })
    }
    if (dealershipDetail?.mobile) {
      formInventory.setFieldsValue({ ...formInventory.getFieldsValue(), inventory_phone: dealershipDetail.mobile })
    }
    if (dealershipDetail?.firstName && dealershipDetail?.lastName) {
      const contactName = `${dealershipDetail.firstName} ${dealershipDetail.lastName}`
      formInventory.setFieldsValue({ ...formInventory.getFieldsValue(), inventory_contact_name: contactName })
    }
  }, [dealershipDetail])

  useEffect(() => {
    setCrmErrorMessage('')
  }, [selectedCrm, otherCrmName])

  return (
    <div className="grid grid-cols-4 mt-16 dealership-detail-container">
      <div className="md:col-start-2 md:col-span-3 col-span-12 w-full">
        <div>
          <p className="text-lg">
            How does your dealership syndicate inventory to EX: Autotrader, Car Guru &lsquo;s etc?
          </p>
          <Radio.Group
            className="w-full"
            onChange={(e) => {
              setInventory(e.target.value)
              // Keep in case we need to reset them
              // formInventory.setFieldsValue({
              //   inventory_contact_name: undefined,
              //   inventory_email: undefined,
              //   inventory_phone: undefined
              // })
            }}
          >
            <Space direction="vertical" className="py-8 w-full">
              {inventoryRadio?.map((el, key) => {
                return (
                  <div key={`${key}-${el.value}`} className="w-full">
                    <Radio value={el.value} className="py-1 radio-group">
                      <p className="text-lg w-full">{el.label}</p>
                      {inventory === el.value && (
                        <Form
                          form={formInventory}
                          className="mt-2 flex md:flex-row flex-col md:gap-4"
                          onChange={(e) => onFormInventoryChange(e)}
                        >
                          <Form.Item
                            validateTrigger="onChange"
                            name="inventory_contact_name"
                            validateFirst
                            rules={[
                              {
                                required: true,
                                message: 'Name is required'
                              },
                              { max: 32, message: 'Max allowed characters is 32' },
                              {
                                pattern: /^[A-Za-z]([A-Za-z ])*$/g,
                                message: 'Name consist only of alphabets'
                              }
                            ]}
                          >
                            <p className="text-gray-400 mb-1">Contact name</p>
                            <Input
                              name="inventory_contact_name"
                              placeholder="Please fill"
                              className="w-full p-3 box-border rounded-lg border-gray-300 border focus:outline-none"
                              defaultValue={
                                dealershipDetail?.firstName && dealershipDetail?.lastName
                                  ? `${dealershipDetail.firstName} ${dealershipDetail.lastName}`
                                  : ''
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            name="inventory_email"
                            rules={[
                              {
                                required: true,
                                message: 'Email is required'
                              },
                              {
                                pattern: VALID_EMAIL,
                                message: 'Email is invalid'
                              }
                            ]}
                          >
                            <p className="text-gray-400 mb-1">Email Address</p>
                            <Input
                              name="inventory_email"
                              placeholder="Please fill"
                              className="w-full p-3 box-border rounded-lg border-gray-300 border focus:outline-none"
                              defaultValue={dealershipDetail?.email ? dealershipDetail.email : ''}
                            />
                          </Form.Item>
                          <Form.Item
                            name="inventory_phone"
                            rules={[
                              { required: true, message: 'Phone is required' },
                              {
                                pattern: /[0-9,-]{12}/g,
                                message: 'Phone number is invalid'
                              }
                            ]}
                          >
                            <p className="text-gray-400 mb-1">Phone Number</p>
                            <PhoneNumberInput
                              name="inventory_phone"
                              placeholder="Please fill"
                              className="w-full p-3 box-border rounded-lg border-gray-300 border focus:outline-none"
                              defaultValue={dealershipDetail?.mobile ? dealershipDetail.mobile : ''}
                            />
                          </Form.Item>
                        </Form>
                      )}
                    </Radio>
                  </div>
                )
              })}
            </Space>
          </Radio.Group>
        </div>
        <hr className="my-8" />
        <div>
          <p className="text-lg">How does your dealership get leads today?</p>
          <Radio.Group
            onChange={(e) => {
              setGetLeads(e.target.value)
              // Keep in case we need to reset them
              // formGetLeads.setFieldsValue({ get_leads_email: undefined })
            }}
          >
            <Space direction="vertical" className="py-8">
              {getLeadsRadio?.map((el, key) => {
                return (
                  <Radio key={`${key}-${el.value}`} value={el.value} className="py-1">
                    <p className="text-lg">{el.label}</p>
                    {getLeads === el.value && (
                      <Form form={formGetLeads} onChange={(e) => onGetLeadsFormChange(e)} className="mt-2">
                        <Form.Item
                          name="get_leads_email"
                          rules={[
                            {
                              required: true,
                              message: 'Email is required'
                            },
                            {
                              pattern: VALID_EMAIL,
                              message: 'Email is invalid'
                            }
                          ]}
                        >
                          <p className="text-gray-400 mb-1">What email address would you like to the leads to go to?</p>
                          <Input
                            name="get_leads_email"
                            placeholder="Please fill"
                            className="w-full p-3 box-border rounded-lg border-gray-300 border focus:outline-none"
                            defaultValue={dealershipDetail?.email ? dealershipDetail.email : ''}
                          />
                        </Form.Item>
                      </Form>
                    )}
                  </Radio>
                )
              })}
            </Space>
          </Radio.Group>
        </div>
        <hr className="my-8" />
        <div>
          <p className="text-lg">Do you use CRM?</p>
          <Radio.Group onChange={(e) => setUseCrm(e.target.value)}>
            <Space direction="vertical" className="py-8">
              {useCrmRadio?.map((el, key) => {
                return (
                  <Radio key={`${key}-${el.value}`} value={el.value} className="py-1">
                    <p className="text-lg">{el.label}</p>
                  </Radio>
                )
              })}
            </Space>
          </Radio.Group>
          {!!useCrm && (
            <div className="ml-8">
              <Select
                components={{
                  IndicatorSeparator: () => null
                }}
                className="w-full md:w-1/2"
                onChange={(e) => {
                  setSelectedCrm(e.value)
                }}
                placeholder="Please select"
                options={useCrmOptions.map((e) => ({ label: e, value: e }))}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                    borderRadius: '10px',
                    height: '50px'
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    fontStyle: 'italic'
                  }),
                  menu: (provided) => ({
                    ...provided,
                    marginTop: '20px'
                  })
                }}
              />
              <p className="text-red-500">{crmErrorMessage}</p>
              {selectedCrm === 'Other' && (
                <>
                  <p className="my-3">Enter CRM name</p>
                  <Input
                    placeholder="Please enter CRM name"
                    className="w-full md:w-1/2 p-3 box-border rounded-lg border-gray-300 border focus:outline-none"
                    onChange={(e) => setOtherCrmName(e.target.value)}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="md:col-start-4 col-span-12 flex items-center justify-end mt-12">
        <div className="flex text-blue-500">
          <BiChevronLeft className="text-2xl" />
          <p onClick={backStep} className="font-bold mr-4 cursor-pointer">
            Back
          </p>
        </div>
        <Button
          disabled={!(inventory && getLeads && useCrm !== undefined)}
          type="primary"
          onClick={() => onSubmit()}
          className="bg-blue-600 h-12 w-60 font-bold rounded-xl px-10"
        >
          Next
        </Button>
      </div>
    </div>
  )
}

DealershipDetails.propTypes = {
  dealershipDetail: PropTypes.object,
  nextStep: PropTypes.func,
  backStep: PropTypes.func,
  setDealershipDetail: PropTypes.func
}

export default DealershipDetails
