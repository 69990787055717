import * as PropTypes from 'prop-types'
import React from 'react'

function CreditApplicationDetail({ creditApplicationData, creditApplicationRef }) {
  return (
    <div ref={creditApplicationRef} className="container md:w-full lg:w-5/6 px-5 py-5 flex gap-5 flex-col">
      <div className="text-2xl text-center">Credit application detail</div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {creditApplicationData && creditApplicationData?.applicant_section ? (
          <div className="">
            <div className="group mb-4">
              <h2 className="text-xl">Applicant Information</h2>
              <div className="">
                <div className="flex gap-x-1">
                  <b>First Name:</b>
                  <p>{creditApplicationData?.applicant_section?.first_name}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Middle Name:</b>
                  <p>{creditApplicationData?.applicant_section?.middle_name}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Last Name:</b>
                  <p>{creditApplicationData?.applicant_section?.last_name}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Email:</b>
                  <p>{creditApplicationData?.applicant_section?.email}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Date of Birth:</b>
                  <p>{creditApplicationData?.applicant_section?.date_of_birth}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Social Security Number:</b>
                  <p>{creditApplicationData?.applicant_section?.social_security_number}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Mobile Number:</b>
                  <p>{creditApplicationData?.applicant_section?.mobile_number}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Marital Status:</b>
                  <p>{creditApplicationData?.applicant_section?.marital_status}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Citizenship Status:</b>
                  <p>{creditApplicationData?.applicant_section?.citizenship_status}</p>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {creditApplicationData && creditApplicationData?.address_section ? (
          <div className="">
            <div className="group  mb-4">
              <h2 className="text-xl">Address Information</h2>
              <div className="">
                <div className="flex gap-x-1">
                  <b>Street Address:</b>
                  <p>{creditApplicationData?.address_section?.street_address}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Zip Code:</b>
                  <p>{creditApplicationData?.address_section?.zip_code}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>City:</b>
                  <p>{creditApplicationData?.address_section?.city}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>State:</b>
                  <p>{creditApplicationData?.address_section?.state}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>County:</b>
                  <p>{creditApplicationData?.address_section?.county}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Years At Address:</b>
                  <p>{creditApplicationData?.address_section?.years_at_address}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Months At Address:</b>
                  <p>{creditApplicationData?.address_section?.months_at_address}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Residential Status:</b>
                  <p>{creditApplicationData?.address_section?.residential_status}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Mortgage Lender Name:</b>
                  <p>{creditApplicationData?.address_section?.mortgage_lender_name}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Monthly Mortgage:</b>
                  <p>{creditApplicationData?.address_section?.monthly_mortgage}</p>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {creditApplicationData && creditApplicationData?.job_section ? (
          <div className="">
            <div className="group  mb-4">
              <h2 className="text-xl">Job Information</h2>
              <div className="">
                <div className="flex gap-x-1">
                  <b>Employer Name:</b>
                  <p>{creditApplicationData?.job_section?.employer_name}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Job Status:</b>
                  <p>{creditApplicationData?.job_section?.job_status}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Annual Gross Income:</b>
                  <p>{creditApplicationData?.job_section?.annual_gross_income}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Annual Net Income:</b>
                  <p>{creditApplicationData?.job_section?.annual_net_income}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Work Phone:</b>
                  <p>{creditApplicationData?.job_section?.work_phone}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Job Title:</b>
                  <p>{creditApplicationData?.job_section?.job_title}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Street Address:</b>
                  <p>{creditApplicationData?.job_section?.street_address}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Zip Code:</b>
                  <p>{creditApplicationData?.job_section?.zip_code}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>City:</b>
                  <p>{creditApplicationData?.job_section?.city}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>State:</b>
                  <p>{creditApplicationData?.job_section?.state}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>County:</b>
                  <p>{creditApplicationData?.job_section?.county}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Years At Job:</b>
                  <p>{creditApplicationData?.job_section?.years_at_job}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Months At Job:</b>
                  <p>{creditApplicationData?.job_section?.months_at_job}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Job Type:</b>
                  <p>{creditApplicationData?.job_section?.job_type}</p>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {creditApplicationData && creditApplicationData?.license_section ? (
          <div className="">
            <div className="group  mb-4">
              <h2 className="text-xl">License Information</h2>
              <div className="">
                <div className="flex gap-x-1">
                  <b>License Number:</b>
                  <p>{creditApplicationData?.license_section?.license_number}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>License State:</b>
                  <p>{creditApplicationData?.license_section?.license_state}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>License Issued Date:</b>
                  <p>{creditApplicationData?.license_section?.license_issued_date}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>License Expiry Date:</b>
                  <p>{creditApplicationData?.license_section?.license_expiry_date}</p>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {creditApplicationData && creditApplicationData?.income_section ? (
          <div className="">
            <div className="group  mb-4">
              <h2 className="text-xl">Income Information</h2>
              <div className="">
                <div className="flex gap-x-1">
                  <b>Additional Annual Income:</b>
                  <p>{creditApplicationData?.income_section?.additional_annual_income}</p>
                </div>
                <div className="flex gap-x-1">
                  <b>Additional Income Source:</b>
                  <p>{creditApplicationData?.income_section?.additional_income_source}</p>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

CreditApplicationDetail.propTypes = {
  creditApplicationData: PropTypes.object,
  creditApplicationRef: PropTypes.object
}

export default CreditApplicationDetail
