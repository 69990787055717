import HttpService from './http.service'
import HttpClientService from './httpClient.service'

export const activation = ({ verificationToken }) => {
  const params = {
    verification_token: verificationToken
  }
  return HttpService.post('user-activation', params).then((result) => {
    const { data } = result
    return data
  })
}

export function getInfo() {
  return HttpClientService.get('user-info').then((result) => {
    const { data } = result
    return data
  })
}
