import { Button, Form, Input, Modal, Table, notification } from 'antd'
import moment from 'moment'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import * as DealerService from '../../../services/dealer.service'
import './style.scss'

function InventorySyndication({ dealer, isDealer }) {
  const [form] = Form.useForm()
  const [tableForm] = Form.useForm()
  const [dealerInventoryFeed, setDealerInventoryFeed] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [selectedRowIndex, setSelectedRowIndex] = useState(null)
  const [deleteRowIndex, setDeleteRowIndex] = useState(null)
  const [dealerData, setDealerData] = useState(dealer)

  const inventorySyndicationFileLink = DealerService.getInventorySyndicationFileLink(dealer.dealer_id)

  const openNotification = (type, userAction) => {
    const action = type === 'success' ? notification.success : notification.error
    const message = type === 'success' ? `Successfully ${userAction}` : `Action Failed: ${userAction}`
    action({
      message,
      placement: 'topRight'
    })
  }

  const updateDealer = async (values, newRow) => {
    let payload = {}

    if (isDealer) {
      payload = {
        employeeId: values.employee_id,
        dealerId: values.dealer_id,
        dealerName: values?.name || values.dealer_name,
        firstName: values?.firstName || values.first_name,
        lastName: values?.lastName || values.last_name,
        email: values.email,
        phone: values.phone,
        registered: moment(values.registered).format('YYYY-MM-DD'),
        permissions: values.permissions,
        updateRole: 'dealer'
      }
    } else {
      payload = {
        employeeId: values.employee_id,
        dealerId: values.dealer_id,
        dealerName: values?.name || values.dealer_name,
        firstName: values?.firstName || values.first_name,
        lastName: values?.lastName || values.last_name,
        address: values.address,
        email: values.email,
        phone: values.phone,
        website: values.website,
        city: values.city,
        state: values.state,
        zip: values.zip,
        registered: moment(values.registered).format('YYYY-MM-DD'),
        status: values.status,
        dealershipStatus: values.dealershipStatus,
        engagementTracking: values.engagementTracking ? values.engagementTracking : 'off',
        emailTracking: values.emailTracking ? values.emailTracking : 'off',
        showOtherNearbyDealers: values.showOtherNearbyDealers ? values.showOtherNearbyDealers : 'on',
        smartboard: values.smartboard ? values.smartboard : 'off',
        inventoryFeeds: values.inventoryFeeds,
        updateRole: 'dealership'
      }
    }
    const result = await DealerService.updateDealer(payload)
    if (result.isSuccessful === 'Yes' && newRow) {
      setDealerInventoryFeed([...dealerInventoryFeed, newRow])
      openNotification('success', 'added')
    } else if (result.isSuccessful === 'Yes') {
      openNotification('success', 'updated')
    }
    setDealerData(result.data)
  }

  useEffect(() => {
    const inventoryFeeds = JSON.parse(dealerData.inventoryFeeds)
    const inventoryFeedsKeys = []
    Object.keys(inventoryFeeds).forEach((key, index) =>
      inventoryFeedsKeys.push({
        key: index,
        text: key,
        value: inventoryFeeds[key]
      })
    )
    setDealerInventoryFeed(inventoryFeedsKeys)
  }, [])

  const onCancelConfirmModal = () => {
    setDeleteRowIndex(null)
    setShowConfirmModal(false)
  }

  const onUpdateConfirmModal = async () => {
    if (deleteRowIndex) {
      const payload = {
        dealerId: dealerData.dealer_id,
        index: deleteRowIndex
      }

      const result = await DealerService.deleteDealerInventoryFeed(payload)
      if (result.isSuccessful === 'Yes') {
        const inventoryFeeds = JSON.parse(result.data)
        const inventoryFeedsKeys = []
        Object.keys(inventoryFeeds).forEach((key, index) =>
          inventoryFeedsKeys.push({
            key: index,
            text: key,
            value: inventoryFeeds[key]
          })
        )
        setDealerInventoryFeed(inventoryFeedsKeys)

        dealerData.inventoryFeeds = result.data
        setDealerData(dealerData)

        openNotification('success', 'deleted')
      }
    }

    setShowConfirmModal(false)
  }

  const handleAddRow = (inventoryFeedName) => {
    const inventoryFeeds = JSON.parse(dealerData.inventoryFeeds)
    const formattedInventoryFeedName = inventoryFeedName.toLowerCase().replaceAll(' ', '')
    const flag = dealerInventoryFeed.some(
      (key) => key.text.toLowerCase().replaceAll(' ', '') === formattedInventoryFeedName
    )
    if (!flag) {
      const newRow = {
        key: dealerInventoryFeed.length,
        text: inventoryFeedName,
        value: inventoryFeeds[inventoryFeedName]
      }

      inventoryFeeds[inventoryFeedName] = []
      const result = updateDealer(
        {
          ...dealerData,
          dealer_id: dealerData.dealer_id,
          employee_id: dealerData.employee_id,
          inventoryFeeds: JSON.stringify(inventoryFeeds)
        },
        newRow
      )
      setDealerData(result.data)
    } else {
      openNotification('error', 'Duplicate inventory feed name')
    }
  }
  const handleUpdate = (index) => {
    setSelectedRowIndex(index)
  }

  const handleDelete = (index) => {
    setDeleteRowIndex(index)
    setShowConfirmModal(true)
  }

  const handleUpdateActionElseWhere = async (index, record) => {
    setSelectedRowIndex(index)
    let actionState = 'disabled'
    if (record?.value?.state === 'disabled') {
      actionState = 'enabled'
    }

    const payload = {
      dealerId: dealerData.dealer_id,
      index,
      action: actionState
    }

    const result = await DealerService.updateDealerInventoryFeedElseWhere(payload)
    if (result.isSuccessful === 'Yes') {
      const inventoryFeeds = JSON.parse(result.data)
      const inventoryFeedsKeys = []
      Object.keys(inventoryFeeds).forEach((key, index) =>
        inventoryFeedsKeys.push({
          key: index,
          text: key,
          value: inventoryFeeds[key]
        })
      )
      setDealerInventoryFeed(inventoryFeedsKeys)

      dealerData.inventoryFeeds = result.data
      setDealerData(dealerData)

      openNotification('success', 'updated')
    }
  }

  const handleUpdateActionInSearchX = async (index, record) => {
    setSelectedRowIndex(index)
    let actionState = 'disabled'
    if (record?.value?.state === 'disabled') {
      actionState = 'enabled'
    }

    const payload = {
      dealerId: dealerData.dealer_id,
      index,
      action: actionState
    }

    const result = await DealerService.updateDealerInventoryFeedInSearchX(payload)
    if (result.isSuccessful === 'Yes') {
      const inventoryFeeds = JSON.parse(result.data)
      const inventoryFeedsKeys = []
      Object.keys(inventoryFeeds).forEach((key, index) =>
        inventoryFeedsKeys.push({
          key: index,
          text: key,
          value: inventoryFeeds[key]
        })
      )
      setDealerInventoryFeed(inventoryFeedsKeys)

      dealerData.inventoryFeeds = result.data
      setDealerData(dealerData)

      openNotification('success', 'updated')
    }
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    form.validateFields(['inventoryFeed']).then(() => {
      const inventoryFeedName = form.getFieldValue('inventoryFeed')
      handleAddRow(inventoryFeedName)
      form.resetFields()
      setIsModalOpen(false)
    })
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    form.resetFields()
  }

  const columns = [
    {
      title: 'SYNDICATED TO NAME',
      dataIndex: 'text',
      align: 'center',
      render: (text) => {
        return <span className="text-gray-400">{text}</span>
      }
    },
    {
      title: (
        <span>
          SFTP OR FTP URL <span className="text-red-700">*</span>
        </span>
      ),
      dataIndex: 'url',
      align: 'left',
      render: (text, record, index) =>
        record?.key > 0 && (
          <Form.Item
            initialValue={record?.value !== undefined ? record?.value.url : ''}
            name={`url_${index}`}
            rules={[{ required: index === selectedRowIndex, message: 'Please enter SFTP or FTP URL' }]}
          >
            <Input className="rounded-md border-gray-300 border mt-1 mb-1" placeholder="Enter URL" />
          </Form.Item>
        )
    },
    {
      title: (
        <span>
          PORT <span className="text-red-700">*</span>
        </span>
      ),
      dataIndex: 'port',
      align: 'left',
      render: (text, record, index) =>
        record?.key > 0 && (
          <Form.Item
            initialValue={record?.value !== undefined ? record?.value.port : ''}
            name={`port_${index}`}
            rules={[{ required: index === selectedRowIndex, message: 'Please enter Port' }]}
          >
            <Input className="rounded-md border-gray-300 border mt-1 mb-1" placeholder="Enter Port" />
          </Form.Item>
        )
    },
    {
      title: (
        <span>
          USERNAME <span className="text-red-700">*</span>
        </span>
      ),
      dataIndex: 'username',
      align: 'left',
      render: (text, record, index) =>
        record?.key > 0 && (
          <Form.Item
            initialValue={record?.value !== undefined ? record?.value.username : ''}
            name={`username_${index}`}
            rules={[{ required: index === selectedRowIndex, message: 'Please enter Username' }]}
          >
            <Input className="rounded-md border-gray-300 border mt-1 mb-1" placeholder="Enter Username" />
          </Form.Item>
        )
    },
    {
      title: (
        <span>
          PASSWORD <span className="text-red-700">*</span>
        </span>
      ),
      dataIndex: 'password',
      align: 'left',
      render: (text, record, index) =>
        record?.key > 0 && (
          <Form.Item
            initialValue={record?.value !== undefined ? record?.value.password : ''}
            name={`password_${index}`}
            rules={[{ required: index === selectedRowIndex, message: 'Please enter Password' }]}
          >
            <Input.Password className="rounded-md border-gray-300 border mt-1 mb-1" placeholder="Enter Password" />
          </Form.Item>
        )
    },
    {
      title: (
        <span>
          GOOGLE STORE CODE <span className="text-red-700">*</span>
        </span>
      ),
      dataIndex: 'googleStoreCode',
      align: 'left',
      render: (text, record, index) =>
        record?.key === 1 && (
          <Form.Item
            initialValue={record?.value !== undefined ? record?.value.googleStoreCode : ''}
            name={`googleStoreCode_${index}`}
            rules={[{ required: index === selectedRowIndex, message: 'Please enter Google Store Code' }]}
          >
            <Input className="rounded-md border-gray-300 border mt-1 mb-1" placeholder="Enter Store Code" />
          </Form.Item>
        )
    },
    {
      title: 'GOOGLE PLACES ID',
      dataIndex: 'googlePlacesId',
      align: 'left',
      render: (text, record, index) =>
        record?.key === 1 && (
          <Form.Item
            initialValue={record?.value !== undefined ? record?.value.googlePlacesId : ''}
            name={`googlePlacesId_${index}`}
            rules={[{ message: 'Please enter Google Places ID' }]}
          >
            <Input className="rounded-md border-gray-300 border mt-1 mb-1" placeholder="Enter Places ID" />
          </Form.Item>
        )
    },
    {
      title: 'GOOGLE MAPS URL',
      dataIndex: 'googleMapsUrl',
      align: 'left',
      render: (text, record, index) =>
        record?.key === 1 && (
          <Form.Item
            initialValue={record?.value !== undefined ? record?.value.googleMapsUrl : ''}
            name={`googleMapsUrl_${index}`}
            rules={[{ message: 'Please enter Google Maps URL' }]}
          >
            <Input className="rounded-md border-gray-300 border mt-1 mb-1" placeholder="Enter Google Maps URL" />
          </Form.Item>
        )
    },
    {
      title: 'ACTIONS',
      dataIndex: 'action',
      render: (text, record, index) =>
        record?.key > 0 ? (
          <div key={index} className="flex items-center justify-around">
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                handleUpdate(index)
              }}
            >
              Update
            </Button>
            <Button
              disabled={index < 2}
              htmlType="submit"
              type="danger"
              onClick={() => {
                handleDelete(index)
              }}
            >
              Delete
            </Button>
            <div key={index} className="flex items-center justify-around">
              <Button
                htmlType="button"
                type="default"
                onClick={() => {
                  handleUpdateActionElseWhere(index, record)
                }}
              >
                {record?.value?.state === 'disabled' ? 'Enable' : 'Disable'}
              </Button>
            </div>
          </div>
        ) : (
          <div key={index} className="flex items-center justify-around">
            <Button
              className="w-full"
              htmlType="button"
              type="default"
              onClick={() => {
                handleUpdateActionInSearchX(index, record)
              }}
            >
              {record?.value?.state === 'disabled' ? 'Enable inSearchX' : 'Disable inSearchX'}
            </Button>
          </div>
        )
    }
  ]
  const onSubmit = async () => {
    const values = await tableForm.getFieldsValue()
    const selectedRow = {}
    Object.keys(values)
      .filter((key) => Number(key.split('_')[1]) === selectedRowIndex)
      .forEach((filteredKey) => {
        selectedRow[filteredKey.split('_')[0]] = values[filteredKey]
      })
    const inventoryFeeds = JSON.parse(dealerData.inventoryFeeds)
    const rowToBeSaved = dealerInventoryFeed[selectedRowIndex].text
    inventoryFeeds[rowToBeSaved] = selectedRow
    updateDealer({
      ...dealerData,
      dealer_id: dealerData.dealer_id,
      employee_id: dealerData.employee_id,
      inventoryFeeds: JSON.stringify(inventoryFeeds)
    })
  }

  return (
    <div className="pb-6 bg-white text-black">
      <Form form={tableForm} onFinish={onSubmit}>
        <div id="subTable">
          <Table columns={columns} dataSource={dealerInventoryFeed} pagination={false} />
        </div>
      </Form>
      <div className="flex items-center mt-5">
        <Button type="primary" onClick={showModal}>
          Add another inventory feed
        </Button>
        <Modal title="Add inventory feed" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <Form form={form} onFinish={handleOk}>
            <Form.Item
              name="inventoryFeed"
              rules={[{ required: true, message: 'Please enter your inventory feed name' }]}
              initialValue=""
            >
              <Input className="rounded-md border-gray-300 border mt-3 mb-3" placeholder="Enter Inventory Feed Name" />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          key="delete-confirm-modal"
          centered
          title=""
          visible={showConfirmModal}
          onCancel={onCancelConfirmModal}
          closable={false}
          okText="Close"
          className=""
          width={350}
          destroyOnClose
          footer={[
            <div className="flex items-center justify-around">
              <p>Are you sure?</p>
              <p>
                <div className="flex justify-between gap-4">
                  <Button block onClick={() => onUpdateConfirmModal()}>
                    Yes
                  </Button>
                  <Button block onClick={() => onCancelConfirmModal()}>
                    No
                  </Button>
                </div>
              </p>
            </div>
          ]}
        >
          <div className="pb-2 flex gap-3 lg:gap-5 justify-center rounded-3xl">
            <div className="font-semibold text-md text-center">Delete this record?</div>
          </div>
        </Modal>
      </div>
      <div className="flex items-end mt-5">
        <a href={inventorySyndicationFileLink} className="cursor-pointer text-primary" download>
          Download inventory file
        </a>
      </div>
    </div>
  )
}

InventorySyndication.propTypes = {
  dealer: PropTypes.object,
  isDealer: PropTypes.bool
}

export default InventorySyndication
