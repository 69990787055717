/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
import PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import CardSimilarVehicleInventory from '../CardSimilarVehicleInventory'

function SimilarVehicles({ cars }) {
  const navigate = useNavigate()
  const handleNavigateMyInventory = () => {
    navigate('/dealer-dashboard?scrollTo=my-inventory')
  }

  return (
    <div className="rounded-xl border p-4 mb-5">
      <div className="flex justify-between items-center">
        <p className="text-2xl font-bold text-black mb-2">Similar vehicles in my inventory</p>
        <div className="text-primary cursor-pointer font-bold" onClick={handleNavigateMyInventory}>
          My Inventory&nbsp; &#62;
        </div>
      </div>
      <div className="relative">
        <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-6 mb-12">
          {cars.slice(0, 4).map((car) => (
            <CardSimilarVehicleInventory car={car} key={`best-car-${car._id}`} carId={car._id} />
          ))}
        </div>
      </div>
    </div>
  )
}

SimilarVehicles.propTypes = {
  cars: PropTypes.array
}

export default SimilarVehicles
