import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, notification } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useAuthorizationContext } from '../../../AuthorizationProvider'
import { calculateLoan } from '../../../services/credit.service'
import { setCustomerZipCode } from '../../../services/customer.service'
import * as VehicleService from '../../../services/vehicle.service'
import BoxButtonGroup from '../BoxButtonGroup'
import ZipCodePopup from '../BoxButtonGroup/ZipCodePopup'
import './style.scss'

function CashBox({
  info,
  openBreakdown,
  setOpenBreakdown,
  isCalculateTaxesModalVisible,
  setIsCalculateTaxesModalVisible,
  setInitStep,
  cashdown,
  loans,
  getCalculation
}) {
  const { customerLogin, accountSettings, setAccountSettings } = useAuthorizationContext()
  const [cookie, setCookie, removeCookie] = useCookies()
  const [loginRequired, setLoginRequired] = useState(false)
  const [showCashDownPopupConfirm, setShowCashDownPopupConfirm] = useState(false)
  const [calculationId, setCalculationId] = useState('')
  const [estimatedFinancePayment, setEstimatedFinancePayment] = useState(0)
  const [taxesAndFees, setTaxesAndFees] = useState(0)
  const [salesTaxes, setSalesTaxes] = useState(0)
  const [dealerFees, setDealerFees] = useState(0)
  const [dealerFeesObj, setDealerFeesObj] = useState({})
  const [zipCode, setZipCode] = useState(0)
  const [zipCodeValue, setZipCodeValue] = useState(0)
  const [zipCodeError, setZipCodeError] = useState(false)
  const [showZipCodePopupConfirm, setShowZipCodePopupConfirm] = useState(false)
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [contentHeight, setContentHeight] = useState(0)
  const [resolvedZip, setResolvedZip] = useState(true)
  const [visibleInfo, setIsVisibleInfo] = useState(false)
  const contentRef = useRef(null)

  useEffect(() => {
    setZipCode(accountSettings.zip)
  }, [accountSettings.zip])

  useEffect(() => {
    setContentHeight(contentRef.current.clientHeight)
  }, [visible])

  const showContent = () => {
    setVisible(!visible)
  }

  const [totalPrice, setTotalPrice] = useState(info?.price || 0)
  const vehicleId = info?._id || ''
  const tradeIn = 0

  const openNotification = (type, message) => {
    const action = type === 'success' ? notification.success : notification.error
    action({
      message,
      placement: 'topRight'
    })
  }

  const onChangeOpenBreakdown = () => {
    setOpenBreakdown(!openBreakdown)
  }

  useEffect(() => {
    if (cookie.customer_zip_code && cookie.customer_zip_code > 0 && cookie.customer_zip_code !== zipCode) {
      setZipCode(cookie.customer_zip_code)
      setResolvedZip(true)
    }
  }, [cookie])

  useEffect(() => {
    VehicleService.getZipCodeBaseIpAddress()
      .then((res) => {
        setResolvedZip(res.data.zipCodeResolved)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (!resolvedZip) {
      setIsVisibleInfo(true)
    } else {
      setIsVisibleInfo(false)
    }
  }, [resolvedZip])

  const calculate = () => {
    setLoading(true)
    let userId = ''
    if (customerLogin && accountSettings.zip !== undefined) {
      userId = accountSettings.id
    }

    let zipCodeValue = zipCode
    if (customerLogin && cookie.customer_zip_code) {
      zipCodeValue = cookie.customer_zip_code
    }

    calculateLoan({
      userId,
      vehicleId,
      tradeIn,
      isCash: 1,
      zipCodeValue
    })
      .then((response) => {
        const data = response.data
        setCalculationId(data.calculationId)
        if (!customerLogin) {
          setZipCode(data.zipCode)
        }
        setEstimatedFinancePayment(data.paymentMonthlyInteger)
        setTotalPrice(data.totalPriceInteger)
        setTaxesAndFees(data.totalTaxesAndFeesInteger)
        setSalesTaxes(data.salesTaxesCalculated)
        setDealerFees(data.dealerFeesLabel)
        setDealerFeesObj(data.dealerFeesArray)
        setResolvedZip(data.zipCodeResolved)

        getCalculation(data)
      })
      .catch((error) => {
        openNotification('error', error.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleZipCodeUpdate = () => {
    if (!customerLogin) {
      setLoginRequired(true)
    } else {
      setCustomerZipCode({ zipCode })
        .then((response) => {
          const data = response.data
          setAccountSettings(data)
          calculate()
          openNotification('success', 'Successfully updated')
        })
        .catch((error) => {
          openNotification('error', error.message)
        })
    }
    setShowZipCodePopupConfirm(false)
  }

  const handleChangeZipCode = (event) => {
    const zipCodeValue = event.target.value.replace(/\D/g, '')
    setZipCode(zipCodeValue)
    if (zipCodeValue.length >= 4 && zipCodeValue.length <= 5) {
      setZipCodeValue(zipCodeValue)
      setZipCodeError(false)
      setResolvedZip(true)
    } else {
      setZipCodeError(true)
    }
  }

  useEffect(() => {
    removeCookie('customer_zip_code')

    if (zipCode < 1) {
      calculate()
      setCookie('customer_zip_code', zipCode)
    } else {
      const timer = setTimeout(() => {
        if (
          !showZipCodePopupConfirm &&
          !cookie.customer_zip_code &&
          zipCodeValue.length >= 4 &&
          zipCodeValue.length <= 5
        ) {
          setShowZipCodePopupConfirm(true)
        } else if (
          !showZipCodePopupConfirm &&
          cookie.customer_zip_code &&
          cookie.customer_zip_code !== zipCodeValue &&
          zipCodeValue.length >= 4 &&
          zipCodeValue.length <= 5
        ) {
          setShowZipCodePopupConfirm(true)
        }

        setCookie('customer_zip_code', zipCodeValue)

        calculate()
      }, 1500)

      return () => clearTimeout(timer)
    }
  }, [info, loans, zipCodeValue, showCashDownPopupConfirm])

  return (
    <>
      <div className="pt-8">
        <p className="text-lg text-center font-bold">Est.Total Payment</p>
        <p
          className={`text-4xl text-center font-bold${loading ? 'text-gray-600' : ''}`}
        >{`$${totalPrice.toLocaleString()}`}</p>
        <p className="text-primary text-lg text-center mb-5 cursor-pointer" onClick={onChangeOpenBreakdown}>
          {openBreakdown ? 'Back to Calculate My Payment' : 'See payment Breakdown'}
        </p>
      </div>
      <div className={`${openBreakdown ? 'hidden' : ''}`}>
        <div className="bg-light rounded-lg p-2 mb-10">
          <p className="text-center text-gray">You plan to pay by cash/check or get your own</p>
          <p className="text-center text-gray">financing.</p>
        </div>
        <BoxButtonGroup
          info={info}
          setInitStep={setInitStep}
          setIsCalculateTaxesModalVisible={setIsCalculateTaxesModalVisible}
          isCalculateTaxesModalVisible={isCalculateTaxesModalVisible}
          cashdown={cashdown}
          loans={loans}
          estimatedFinancePayment={estimatedFinancePayment}
          loginRequired={loginRequired}
          setLoginRequired={setLoginRequired}
          showCashDownPopupConfirm={showCashDownPopupConfirm}
          setShowCashDownPopupConfirm={setShowCashDownPopupConfirm}
          calculationId={calculationId}
        />
      </div>
      <div className={`px-2 payment-breakdown pt-5 pb-8 ${openBreakdown ? '' : 'hidden'}`}>
        <div className="text-xl font-bold text-center">Payment Breakdown</div>
        <div className="border-t my-3 border-gray-500 mx-3" />
        <div className="flex justify-between mb-3 text-sm">
          <div>Vehicle Price</div>
          <div>{`$${info?.price?.toLocaleString() || 0}`}</div>
        </div>
        <div className="flex justify-between mb-3 text-sm">
          <div>Zip Code</div>
          <div>
            <input
              maxLength={5}
              name="zipCode"
              placeholder="e.g. 10116"
              className={`ext-right border ${
                zipCodeError ? 'border-red-500' : 'border-lightGray'
              } text-right border text-blue-800 focus:text-blueFv-600 focus:outline-none`}
              onChange={handleChangeZipCode}
              value={zipCode}
              type="text"
            />
          </div>
        </div>
        {visibleInfo && (
          <p className="text-red-800 text-xs">Your zip code could not be resolved, please fill in manually</p>
        )}
        <div className="flex justify-between text-sm" onClick={showContent} role="presentation">
          <div>Taxes & Fees</div>
          <div className="flex gap-1 items-center">
            <span>${taxesAndFees}</span>
            <div className="cursor-pointer text-blue-600 text-lg" onClick={showContent} role="presentation">
              {!visible ? <PlusOutlined /> : <MinusOutlined />}
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-3 text-sm">
          <div
            className="w-full transition-all duration-300 overflow-hidden"
            style={{ maxHeight: !visible ? 0 : `${contentHeight}px` }}
          >
            <div className="pt-2" ref={contentRef}>
              <div className="pt-2" ref={contentRef}>
                <div className="flex justify-between mb-2">
                  <p className="text-sm">Sales Taxes</p>
                  <p className="text-sm">${salesTaxes.toFixed(2).toLocaleString()}</p>
                </div>
                <div className="flex justify-between mb-1">
                  <p className="text-sm">Dealer Fees</p>
                  <p className="text-sm">{dealerFees}</p>
                </div>
                {dealerFeesObj?.fees?.map((item) => {
                  return (
                    <div className="flex justify-between">
                      <p className="text-sm ml-3.5">{item.name}</p>
                      <p className="text-sm">{item.valueCurrency}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="border-t mb-3 border-gray-500 mx-3" />
        <div className="flex justify-between text-sm font-bold mb-3">
          <div>Est. Cash Payment</div>
          <div>{`$${totalPrice.toLocaleString()}`}</div>
        </div>
        <div className="border-t mb-3 border-gray-500 mx-3" />
        <div className="text-sm px-4 w-full pt-4">
          <Button size="large" className="text-blue-300 w-full" onClick={onChangeOpenBreakdown}>
            Close
          </Button>
        </div>
        {(customerLogin || showZipCodePopupConfirm) && (
          <ZipCodePopup
            zipCode={zipCode}
            showZipCodePopupConfirm={showZipCodePopupConfirm}
            setShowZipCodePopupConfirm={setShowZipCodePopupConfirm}
            handleZipCodeUpdate={handleZipCodeUpdate}
          />
        )}
      </div>
    </>
  )
}

CashBox.propTypes = {
  info: PropTypes.object,
  openBreakdown: PropTypes.bool,
  setOpenBreakdown: PropTypes.func,
  isCalculateTaxesModalVisible: PropTypes.bool,
  setIsCalculateTaxesModalVisible: PropTypes.func,
  setInitStep: PropTypes.func,
  cashdown: PropTypes.any,
  loans: PropTypes.number,
  getCalculation: PropTypes.func
}

export default CashBox
