import * as PropTypes from 'prop-types'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { USER_ROLES } from './constants/constants'

const useAuth = (loginState) => {
  const user = { loggedIn: loginState }
  return user && user.loggedIn
}

function ProtectedRoute({ location, loginState, settings, role }) {
  let isAuth = useAuth(loginState)

  settings?.routes?.forEach((route) => {
    if (location === route.name && route.value !== true) {
      isAuth = false
    }
  })

  let redirectRoute = '/'
  if (!isAuth && role === USER_ROLES.ADMIN) {
    redirectRoute = '/admin-dashboard'
  } else if (!isAuth && (role === USER_ROLES.DEALERSHIP_USER || role === USER_ROLES.DEALERSHIP_ADMIN)) {
    redirectRoute = '/dealer-dashboard'
  } else if (!isAuth && role === USER_ROLES.CUSTOMER) {
    redirectRoute = '/customer-dashboard'
  }

  // eslint-disable-next-line no-nested-ternary
  return isAuth ? <Outlet /> : <Navigate to={redirectRoute} />
}

ProtectedRoute.propTypes = {
  location: PropTypes.string.isRequired,
  loginState: PropTypes.bool.isRequired,
  settings: PropTypes.object,
  role: PropTypes.string.isRequired
}

export default ProtectedRoute
