import * as PropTypes from 'prop-types'
import React from 'react'
import CPO from '../../assets/CPO.png'
import VIP from '../../assets/VIP.png'

function StockComponent({ vehicle, className = 'w-12 absolute stock z-10' }) {
  const { new_used_cert_flag: newUsedCertFlag } = vehicle
  if (newUsedCertFlag !== '') {
    return <img src={newUsedCertFlag === 'U' ? VIP : CPO} alt="stock" className={className} />
  }
  return ''
}

StockComponent.propTypes = {
  vehicle: PropTypes.object,
  className: PropTypes.string
}

export default StockComponent
