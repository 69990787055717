import { Modal, Table } from 'antd'
import * as PropTypes from 'prop-types'
import React from 'react'

function CreditApplicationModal({ showModal, setSelectedCreditApp, closeModal, creditApps }) {
  const columns = [
    {
      title: 'Vehicle',
      dataIndex: ['vehicle_name']
    },
    {
      title: 'First name',
      dataIndex: ['applicant_section', 'first_name']
    },
    {
      title: 'Last name',
      dataIndex: ['applicant_section', 'last_name']
    },
    {
      title: 'Mobile number',
      dataIndex: ['applicant_section', 'mobile_number']
    },
    {
      title: 'Email',
      dataIndex: ['applicant_section', 'email']
    },
    {
      title: 'City',
      dataIndex: ['address_section', 'city']
    },
    {
      title: 'State',
      dataIndex: ['address_section', 'state']
    },
    {
      title: 'Address',
      dataIndex: ['address_section', 'address']
    },
    {
      title: 'Zip code',
      dataIndex: ['address_section', 'zip_code']
    }
  ]

  return (
    <Modal
      centered
      visible={showModal}
      title="My Credit Applications"
      onCancel={closeModal}
      className="calendar-modal"
      destroyOnClose
      width="75%"
      footer={[]}
    >
      <Table
        columns={columns}
        dataSource={creditApps}
        onRow={(row) => ({
          onClick: () => setSelectedCreditApp(row)
        })}
      />
    </Modal>
  )
}

CreditApplicationModal.propTypes = {
  showModal: PropTypes.bool,
  setSelectedCreditApp: PropTypes.func,
  closeModal: PropTypes.func,
  creditApps: PropTypes.array
}

export default CreditApplicationModal
