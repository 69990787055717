import React from 'react'
import { Button, Input } from 'antd'

function TableRows({ rowsData, deleteTableRows, handleChange, canManagePaymentSettings }) {
  return rowsData.map((data, index) => {
    const { name, value } = data
    return (
      <tr key={index} className="ant-table-row ant-table-row-level-0">
        <td className="ant-table-cell">
          {canManagePaymentSettings && (
            <Input
              type="text"
              value={name}
              onChange={(event) => handleChange(index, event)}
              name="name"
              className="form-control"
            />
          )}
          {!canManagePaymentSettings && name}
        </td>
        <td className="ant-table-cell">
          {canManagePaymentSettings && (
            <Input
              prefix="$"
              type="number"
              value={value}
              onChange={(event) => handleChange(index, event)}
              name="value"
              className="form-control"
            />
          )}
          {!canManagePaymentSettings && value}
        </td>
        {canManagePaymentSettings && (
          <td className="ant-table-cell">
            <Button className="rounded" type="primary" danger onClick={() => deleteTableRows(index)}>
              Remove Fee
            </Button>
          </td>
        )}
      </tr>
    )
  })
}

export default TableRows
