import { HeartOutlined } from '@ant-design/icons'
import { notification } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { STORAGE_KEYS } from 'src/constants/constants'
import { save } from 'src/lib/storage'
import * as CustomerServices from '../../../services/customer.service'
import { useAuthorizationContext } from '../../../AuthorizationProvider'

function VehicleBookmark({ info, loading }) {
  const [favouriteCars, setFavouriteCars] = useState([])
  const { setFavouriteVehicles } = useAuthorizationContext()

  useEffect(() => {
    CustomerServices.getFavoriteVehiclesByCustomer()
      .then((response) => {
        setFavouriteCars(response)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  const isFavouriteCar = favouriteCars.find((item) => item._id === info._id)
  const navigate = useNavigate()
  const handleRedirectDealership = () => {
    navigate(`/dealership/${info?.dealer_id}`)
  }
  const handleFavouriteCar = () => {
    let newFavouriteCars = JSON.parse(JSON.stringify(favouriteCars))
    if (isFavouriteCar) {
      newFavouriteCars = newFavouriteCars.filter((item) => item._id !== info._id)
    } else if (newFavouriteCars?.length < 10) {
      newFavouriteCars.push(info)
    } else {
      notification.warning({
        message: 'Favorite limit',
        description: "You can't add more than 10 favorite cars."
      })
    }
    if (newFavouriteCars.length > 0) {
      CustomerServices.setFavouriteVehicles(info._id)
        .then(() => {
          setFavouriteCars(newFavouriteCars)
          setFavouriteVehicles(newFavouriteCars)
        })
        .catch((error) => {
          console.error(error)
        })
    } else {
      setFavouriteCars(newFavouriteCars)
    }
    save(STORAGE_KEYS.FAVOURITE_CARS, JSON.stringify(newFavouriteCars))
  }

  const getSalesLink = () => {
    if (info?.dealer_phone && info?.dealer_sales_phone) {
      const address = info.dealer_address
      const phone = info.dealer_phone
      const salesPhone = info.dealer_sales_phone
      return `${address} - <a href="tel:${salesPhone}">${phone}</a>`
    }
    return ''
  }

  return (
    <div className="bg-light p-3 relative mb-6">
      <div className="pr-5">
        <p className="text-2xl font-bold">{loading ? '' : `${info?.year} ${info?.make} ${info?.model}`}</p>
        <p className="text-base">
          {loading || !info?.dealer_city ? '' : `${info?.dealer_city}, ${info?.dealer_state}`}
        </p>
        <p className="text-base cursor-pointer hover:text-primary" onClick={handleRedirectDealership}>
          {loading ? '' : info?.dealer_name}
        </p>
        <p className="text-base">
          {/* eslint-disable-next-line react/no-danger */}
          {loading || !info?.dealer_address ? '' : <div dangerouslySetInnerHTML={{ __html: getSalesLink() }} />}
        </p>
      </div>
      <div
        className={`${
          isFavouriteCar ? 'bg-blue-500' : 'bg-white'
        } absolute right-5 top-5 cursor-pointer border border-black rounded-full flex items-center justify-center h-8 w-8`}
        onClick={handleFavouriteCar}
      >
        <HeartOutlined />
      </div>
    </div>
  )
}

VehicleBookmark.propTypes = {
  info: PropTypes.shape({
    _id: PropTypes.string,
    year: PropTypes.number,
    make: PropTypes.string,
    model: PropTypes.string,
    dealer_id: PropTypes.number,
    dealer_name: PropTypes.string,
    dealer_address: PropTypes.string,
    dealer_city: PropTypes.string,
    dealer_phone: PropTypes.string,
    dealer_sales_phone: PropTypes.string,
    dealer_state: PropTypes.string
  }).isRequired,
  loading: PropTypes.bool.isRequired
}

export default VehicleBookmark
