import React, { useEffect, useState } from 'react'
import { GoCheck } from 'react-icons/go'
import { MdEast, MdFormatQuote, MdWest } from 'react-icons/md'
import { Link } from 'react-router-dom'
import SlickSlider from 'react-slick'
import { useWindowSize } from 'react-use'
import VehicleCard from 'src/components/Buy/vehicleCard'
import { STORAGE_KEYS } from 'src/constants/constants'
import { load } from 'src/lib/storage'
import { formatZipCode } from 'src/utils'
import { useAuthorizationContext } from '../../AuthorizationProvider'
import metaPageImage from '../../assets/new-logo.png'
import HomeSearchBoxCar from '../../components/SearchCar/homeSearchBoxCar'
import SearchBoxCar from '../../components/SearchCar/searchBoxCar'
import SummaryCarCard from '../../components/SummaryCarCard/summaryCarCard'
import metaPages from '../../data/metaPages.json'
import SinglePageMetaDecorator from '../../decorators/SinglePageMetaDecorator'
import { carSummaries } from '../../models/carSampleData'
import * as BuyService from '../../services/buy.service'
import './style.scss'

function Home() {
  const [vehicles, setVehicles] = useState([])
  const favouriteCarsLocal = load(STORAGE_KEYS.FAVOURITE_CARS) || []
  const [favouriteCars, setFavouriteCars] = useState(favouriteCarsLocal)
  const [makeField, setMakeField] = useState([])
  const [modelField, setModelField] = useState([])
  const [yearField, setYearField] = useState({})
  const { width: screenWidth } = useWindowSize()
  const isMobile = screenWidth <= 1024
  const [price, setPrice] = useState({
    min: 0,
    max: 0
  })
  const [zipCodeField, setZipCodeField] = useState('')
  const { userLocation } = useAuthorizationContext()
  const [inPriceRangeVehicles, setInPriceRangeVehicles] = useState([])

  useEffect(() => {
    if (userLocation?.zipcode) {
      setZipCodeField(formatZipCode(userLocation.zipcode))
    }
  }, [userLocation])

  const getVehicles = () => {
    BuyService.getVehiclesInLandingPage({ zipCode: zipCodeField })
      .then((response) => {
        setVehicles(response.data)
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const getInPriceRangeVehicles = () => {
    const params = {
      priceFrom: 16000,
      priceTo: 21000,
      zipCode: zipCodeField
    }
    BuyService.getVehiclesInLandingPage(params)
      .then((response) => {
        setInPriceRangeVehicles(response.data)
      })
      .catch((e) => {
        console.error(e)
      })
  }

  useEffect(() => {
    if (zipCodeField) {
      getVehicles()
      getInPriceRangeVehicles()
    }
  }, [zipCodeField])

  const isHideOnProduction = process.env.REACT_APP_HIDE_ON_PRODUCTION === 'true'

  const slickSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <MdEast size={15} color="gray" />,
    prevArrow: <MdWest size={15} color="gray" />
  }

  return (
    <>
      <SinglePageMetaDecorator
        title={metaPages.home.title}
        description={metaPages.home.description}
        imageUrl={metaPageImage}
        imageAlt={metaPages.home.imageAlt}
      />
      <div className="relative">
        <div className={`max-w-xl z-10 absolute top-search-box ${isMobile ? 'hidden' : 'block'}`}>
          <HomeSearchBoxCar
            makeField={makeField}
            setMakeField={setMakeField}
            modelField={modelField}
            setModelField={setModelField}
            yearField={yearField}
            setYearField={setYearField}
            price={price}
            setPrice={setPrice}
            zipCodeField={zipCodeField}
            setZipCodeField={setZipCodeField}
          />
        </div>
        <div className={`banner desktop banner-background1 ${isMobile ? 'hidden' : 'block'}`}>
          <div className="container baner-alignment buying-made-simple">
            <p className="text-darkPrimary font-extrabold text-7xl">Buying Made Simple</p>
            <p className={`text-darkPrimary font-extrabold text-7xl ${!yearField.value && 'hidden'}`}>
              {yearField.value}
            </p>
            <p className="text-2xl text-black w-2/3 text-left">
              <GoCheck className="with-check" size={25} color="orange" />
              <span>Online Market Place with over</span>
              <span className="font-bold ml-1">2.1 Million Vehicles</span>
            </p>
            <p className="text-2xl text-black w-2/3 text-left">
              <GoCheck className="with-check" size={25} color="orange" />
              <span>Extensive Vehicle</span>
              <span className="font-bold ml-1">Selection and Quality</span>
            </p>
            <p className="text-2xl text-black w-2/3 text-left">
              <GoCheck className="with-check" size={25} color="orange" />
              <span>User-Friendly Interface and</span>
              <span className="font-bold ml-1">Advanced Search Tools</span>
            </p>
            <p className="text-2xl text-black w-2/3 text-left pb-20">
              <GoCheck className="with-check" size={25} color="orange" />
              <span className="font-bold">Buy Online</span>
              <span className="ml-1">or choose from</span>
              <span className="font-bold ml-1">over 15,000+ Locations</span>
            </p>
          </div>
        </div>

        <div className={`banner pb-10 mobile ${isMobile ? 'block' : 'hidden'}`}>
          <div className="mx-5 buying-made-simple">
            <div className="mb-12">
              <p className="text-darkPrimary font-extrabold text-center text-7xl">Buying Made Simple</p>
              <p className={`text-darkPrimary font-extrabold text-5xl ${!yearField.value && 'hidden'}`}>
                {yearField.value}
              </p>
            </div>
            <p className="text-2xl text-black">
              <GoCheck className="with-check" size={25} color="orange" />
              <span className="ml-2">Online Market Place with over</span>
              <span className="font-bold ml-6 block">2.1 Million Vehicles</span>
            </p>
            <p className="text-2xl text-black">
              <GoCheck className="with-check" size={25} color="orange" />
              <span className="ml-2">Extensive Vehicle</span>
              <span className="font-bold ml-6 block">Selection and Quality</span>
            </p>
            <p className="text-2xl text-black">
              <GoCheck className="with-check" size={25} color="orange" />
              <span className="ml-2">User-Friendly Interface and</span>
              <span className="font-bold ml-6 block">Advanced Search Tools</span>
            </p>
            <p className="text-2xl text-black">
              <GoCheck className="with-check" size={25} color="orange" />
              <span className="ml-2">Buy Online</span>
              <span className="ml-1">or choose from</span>
              <span className="font-bold ml-6 block">over 15,000+ Locations</span>
            </p>
          </div>
        </div>
      </div>
      <div className={`my-5 ${isMobile ? 'block' : 'hidden'}`}>
        <SearchBoxCar
          makeField={makeField}
          setMakeField={setMakeField}
          modelField={modelField}
          setModelField={setModelField}
          yearField={yearField}
          setYearField={setYearField}
          price={price}
          setPrice={setPrice}
          zipCodeField={zipCodeField}
          setZipCodeField={setZipCodeField}
        />
      </div>

      <div
        className={`container mx-auto pt-8 pb-10 mt-6 flex grid grid-cols-12 testimonials ${
          isMobile ? 'hidden' : 'block'
        }`}
      >
        <div className="col-span-4 testimonial-title flex items-center justify-end">
          <p className="text-3xl font-bold w-1/2">Real people, life changing results.</p>
        </div>

        <div className="col-span-8 testimonial-contents flex items-center justify-center">
          <SlickSlider className="w-3/4 slider-slick testimonial-slider" {...slickSliderSettings}>
            <div className="testimonial1">
              <MdFormatQuote size={25} className="text-blue-500" />
              <p className="text-base testimonial-body">
                I had a fantastic experience purchasing my car through MotorMarketUSA. The platform made it incredibly
                easy to search for vehicles based on my preferences, and I found the perfect car within minutes. The
                detailed vehicle description and history report provided me with the confidence I needed to make the
                purchase. The whole process was seamless, and I even received financing options through the platform. I
                highly recommend MotorMarketUSA for anyone looking to buy a car hassle-free.
              </p>
              <div className="testimonial-author flex items-center justify-start gap-2 mt-4">
                <span className="font-semibold">Tony H.</span>
              </div>
            </div>
            <div className="testimonial2">
              <MdFormatQuote size={25} className="text-blue-500" />
              <p className="text-base testimonial-body">
                MotorMarketUSA exceeded my expectations when I bought my car from their platform. The extensive
                inventory gave me plenty of options to choose from, and I found a vehicle that suited my needs
                perfectly. The pricing information and valuation tool were incredibly helpful in ensuring I got a fair
                deal. The communication with the seller was smooth, and I appreciated the transparency throughout the
                process. Thanks to MotorMarketUSA, I had a stress-free car buying experience.
              </p>
              <div className="testimonial-author flex items-center justify-start gap-2 mt-4">
                <span className="font-semibold">George R.</span>
              </div>
            </div>
            <div className="testimonial3">
              <MdFormatQuote size={25} className="text-blue-500" />
              <p className="text-base testimonial-body">
                I cannot recommend MotorMarketUSA enough for anyone in the market for a new or used car. I recently
                purchased a car through their platform, and I was impressed by the level of professionalism and
                attention to detail. The platform&apos;s user-friendly interface made it easy to navigate and find the
                exact car I was looking for. The seller was responsive and provided all the necessary information to
                make an informed decision. MotorMarketUSA made the car-buying process straightforward and enjoyable. I
                will definitely be using their services again in the future.
              </p>
              <div className="testimonial-author flex items-center justify-start gap-2 mt-4">
                <span className="font-semibold">Thomas T.</span>
              </div>
            </div>
          </SlickSlider>
        </div>
      </div>

      <div className={`container mx-auto py-8 mobile-testimonials ${isMobile ? 'block' : 'hidden'}`}>
        <div className="h-full flex items-center justify-center">
          <p className="text-3xl text-center font-bold">Real people, life changing results.</p>
        </div>
        <div className="h-full flex items-center justify-center mt-5">
          <SlickSlider className="w-11/12 slider-slick testimonial-slider" {...slickSliderSettings}>
            <div className="testimonial1">
              <MdFormatQuote size={25} className="text-blue-500" />
              <p className="text-base">
                I had a fantastic experience purchasing my car through MotorMarketUSA. The platform made it incredibly
                easy to search for vehicles based on my preferences, and I found the perfect car within minutes. The
                detailed vehicle description and history report provided me with the confidence I needed to make the
                purchase. The whole process was seamless, and I even received financing options through the platform. I
                highly recommend MotorMarketUSA for anyone looking to buy a car hassle-free.
              </p>
              <div className="testimonial-author flex items-center justify-start gap-2 mt-4">
                <span className="font-semibold">Tony H.</span>
              </div>
            </div>
            <div className="testimonial2">
              <MdFormatQuote size={25} className="text-blue-500" />
              <p className="text-base">
                MotorMarketUSA exceeded my expectations when I bought my car from their platform. The extensive
                inventory gave me plenty of options to choose from, and I found a vehicle that suited my needs
                perfectly. The pricing information and valuation tool were incredibly helpful in ensuring I got a fair
                deal. The communication with the seller was smooth, and I appreciated the transparency throughout the
                process. Thanks to MotorMarketUSA, I had a stress-free car buying experience.
              </p>
              <div className="testimonial-author flex items-center justify-start gap-2 mt-4">
                <span className="font-semibold">George R.</span>
              </div>
            </div>
            <div className="testimonial3">
              <MdFormatQuote size={25} className="text-blue-500" />
              <p className="text-base">
                I cannot recommend MotorMarketUSA enough for anyone in the market for a new or used car. I recently
                purchased a car through their platform, and I was impressed by the level of professionalism and
                attention to detail. The platform&apos;s user-friendly interface made it easy to navigate and find the
                exact car I was looking for. The seller was responsive and provided all the necessary information to
                make an informed decision. MotorMarketUSA made the car-buying process straightforward and enjoyable. I
                will definitely be using their services again in the future.
              </p>
              <div className="testimonial-author flex items-center justify-start gap-2 mt-4">
                <span className="font-semibold">Thomas T.</span>
              </div>
            </div>
          </SlickSlider>
        </div>
      </div>

      {!isHideOnProduction && (
        <div className="container mx-auto mb-12">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {carSummaries.map((summary, index) => (
              <SummaryCarCard car={summary} key={`summary-car-${index}`} />
            ))}
          </div>
        </div>
      )}
      {vehicles.length > 0 && (
        <div className="container mx-auto mb-12 mt-5 lg:mt-0" id="best-deals-of-the-week">
          <p className="text-left text-3xl font-bold mb-12">Best Deals of the Week</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6 mb-12">
            {vehicles.map((car, index) => [
              index < 5 && (
                <VehicleCard
                  car={car}
                  key={car._id}
                  favouriteCars={favouriteCars}
                  setFavouriteCars={setFavouriteCars}
                />
              )
            ])}
          </div>
          <div className="flex justify-center">
            <Link to="/buy">
              <button
                type="button"
                className="mt-6 rounded-md border-2 border-primary px-8 py-1 text-primary font-semibold text-base w-full hover:bg-primary hover:text-white hidden"
              >
                See More Deals
              </button>
            </Link>
          </div>
        </div>
      )}

      {inPriceRangeVehicles.length > 0 && (
        <div className="container mx-auto mb-12 mt-5 lg:mt-0" id="between-price-range-vehicles">
          <p className="text-left text-3xl font-bold mb-12">Vehicles Between $16k - $21k</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6 mb-12">
            {inPriceRangeVehicles.map((car, index) => [
              index < 5 && (
                <VehicleCard
                  car={car}
                  key={car._id}
                  favouriteCars={favouriteCars}
                  setFavouriteCars={setFavouriteCars}
                />
              )
            ])}
          </div>
          <div className="flex justify-center">
            <Link to="/buy">
              <button
                type="button"
                className="mt-6 rounded-md border-2 border-primary px-8 py-1 text-primary font-semibold text-base w-full hover:bg-primary hover:text-white hidden"
              >
                See More Deals
              </button>
            </Link>
          </div>
        </div>
      )}
    </>
  )
}

export default Home
