/* eslint-disable react/prop-types */
import { SearchOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, InputNumber, Modal, notification, Select } from 'antd'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import PhoneNumberInput from 'src/components/InputComponent/PhoneNumberInput'
import { STATES, USER_PERMISSIONS, VALID_EMAIL } from 'src/constants/constants'
import { useAuthorizationContext } from '../../../AuthorizationProvider'
import * as DealerService from '../../../services/dealer.service'
import { createDealerAdapter, createDealershipAdapter } from './adapters'
import './style.scss'

const { Option } = Select

export function SearchDealerDashboard({
  filterParentData,
  dealershipAccount,
  isHidden,
  isAdmin,
  dealerId,
  setReloadDealers
}) {
  const { userAccount } = useAuthorizationContext()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [permissions, setPermissions] = useState([])
  const [errorMsg, setErrorMsg] = useState('')
  const [form] = Form.useForm()

  const isUser = undefined !== userAccount?.isUser ? userAccount?.isUser : false

  let canManageDealers = undefined !== dealershipAccount ? dealershipAccount?.permissions?.manage_dealers : true
  if (isAdmin) {
    canManageDealers = true
  }

  if (!canManageDealers) {
    canManageDealers = undefined !== userAccount ? userAccount?.permissions?.manage_dealers : true
  }

  if (!isAdmin && undefined === canManageDealers) {
    canManageDealers = true
  }

  useEffect(() => {
    form.resetFields()
  }, [])

  const customerForm = [
    {
      label: 'Dealership name',
      name: 'dealerName',
      required: true,
      placeholder: 'Add dealership',
      type: 'text'
    },
    {
      label: 'First Name',
      name: 'firstName',
      required: true,
      placeholder: 'Add first name',
      type: 'text'
    },
    {
      label: 'Last Name',
      name: 'lastName',
      required: true,
      placeholder: 'Add last name',
      type: 'text'
    },
    {
      name: 'address',
      label: 'Address',
      required: true,
      placeholder: 'Add address',
      type: 'text'
    },
    {
      name: 'city',
      label: 'City',
      required: true,
      placeholder: 'Add City',
      type: 'text'
    },
    {
      name: 'state',
      label: 'State',
      required: true,
      placeholder: 'Add state',
      type: 'select',
      options: STATES.map((e) => ({ value: e.abbreviation, label: e.name }))
    },
    {
      label: 'Zip',
      name: 'zip',
      required: true,
      placeholder: 'Add zip',
      type: 'number'
    },
    {
      label: 'Email',
      name: 'email',
      required: true,
      placeholder: 'Add email',
      type: 'text'
    },
    {
      label: 'Phone',
      name: 'phone',
      required: false,
      placeholder: 'Add phone',
      type: 'phone'
    },
    {
      label: 'Website',
      name: 'website',
      required: true,
      placeholder: 'Add website',
      type: 'text'
    }
  ]

  if (!isAdmin) {
    customerForm.splice(0, 1)
    customerForm.splice(2, 1)
    customerForm.splice(2, 1)
    customerForm.splice(2, 1)
    customerForm.splice(2, 1)
    customerForm.splice(4, 1)
    customerForm.push({
      label: 'Permissions',
      name: 'permissions',
      rules: [
        {
          required: false,
          transform: (value) => value || undefined,
          type: 'array'
        }
      ],
      type: 'checkbox',
      options: USER_PERMISSIONS
    })
  }

  const handleChangePermissions = (e) => {
    const value = e.target.value
    let newPermissions = permissions
    newPermissions = [...permissions, value]
    if (permissions.includes(value)) {
      newPermissions = permissions.filter((item) => item !== value)
    }
    setPermissions(newPermissions)
    form.setFieldsValue({ ...form.getFieldValue(), [e.target.name]: newPermissions })
  }

  const onFormChange = (values) => {
    if (values.target.name === 'permissions') {
      handleChangePermissions(values)
    } else {
      form.setFieldsValue({ ...form.getFieldValue(), [values.target.name]: values.target.value })
    }
  }

  const handleFormSubmission = async () => {
    await form
      .validateFields()
      .then(() => {
        const formData = form.getFieldsValue()
        formData.permissions = permissions
        formData.dealerId = dealerId
        formData.isUser = isUser
        DealerService.createDealer(isAdmin ? createDealershipAdapter(formData) : createDealerAdapter(formData))
          .then(() => {
            setShowModal(false)
            setReloadDealers(true)
            notification.success({
              message: 'Created Dealer successfully!'
            })
          })
          .catch((err) => {
            console.error(err)
            if (undefined !== err) {
              setErrorMsg(err.response.data.message)
            }
          })
      })
      .catch((e) => console.error(e))
  }

  const handleSearch = (e) => {
    setSearch(e.target.value)
    filterParentData(e.target.value)
  }

  const renderForm = (element, index) => {
    switch (element.type) {
      case 'select':
        return (
          <Select
            placeholder={element.placeholder || `Select ${element.name}`}
            size="large"
            name={element.name}
            mode={element.mode}
            onChange={(value) => {
              form.setFieldsValue({ ...form.getFieldValue(), [element.name]: value })
            }}
          >
            {element.options.map((option) => (
              <Option value={option.value} key={option.value} className="p-2">
                {option.label}
              </Option>
            ))}
          </Select>
        )
      case 'checkbox':
        return (
          <Checkbox.Group name={element.name} size="large">
            {element.options.map((option) => (
              <div className="flex justify-between mb-1.5" key={option.value}>
                <Checkbox value={option.value} className="flex items-center flex-1">
                  {option.label}
                </Checkbox>
              </div>
            ))}
          </Checkbox.Group>
        )
      case 'phone':
        return (
          <PhoneNumberInput
            placeholder={element.label}
            name={element.name}
            mask="(999) 999-9999"
            className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
          />
        )
      case 'zip':
        return (
          <InputNumber
            placeholder={element.label}
            name={element.name}
            className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
          />
        )
      default:
        return (
          <input
            placeholder={element.label}
            name={element.name}
            autoFocus={index === 0}
            className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
            type={element.type}
          />
        )
    }
  }

  const validation = (form) => {
    const patternMessage = {}
    switch (form.name) {
      case 'email':
        patternMessage.pattern = VALID_EMAIL
        patternMessage.message = 'Invalid email format'
        return patternMessage
      case 'phone':
        patternMessage.pattern =
          /(^[0-9]{3}-[0-9]{3}-[0-9]{4}$)|(^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$)|(^\([_]{3}\)\s[_]{3}-[_]{4}$)/
        patternMessage.message = 'Invalid phone number format'
        return patternMessage
      default:
        return patternMessage
    }
  }

  useEffect(() => {
    form.resetFields()
    setPermissions([])
    setErrorMsg('')
  }, [showModal])

  const hideOnProduction = process.env.REACT_APP_HIDE_ON_PRODUCTION === 'true'

  return (
    <div className="grid lg:grid-cols-9 grid-cols-3 gap-3 py-6">
      <div className="search-dashboard flex flex-col col-span-3">
        <div className="title">{`Hello ${dealershipAccount?.first_name || ''}!`}</div>
        <div className="subtitle">Here you have a quick overview on your recent dealer activity</div>
      </div>
      <div className="rounded-xl relative z-10 flex-1 flex justify-end col-span-3">
        <div className={`relative w-full h-full ${isHidden && 'hidden'}`}>
          <input
            onChange={handleSearch}
            className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
            type="text"
            value={search}
            placeholder="Search"
          />
          <SearchOutlined className="text-blue-500 absolute right-3 top-1/3 -translate-y-2/4" />
        </div>
      </div>

      <div className="ml-5 flex items-center gap-3 col-span-3 justify-center">
        {canManageDealers && (
          <Button type="primary" className="h-full w-56 rounded-xl font-semibold" onClick={() => setShowModal(true)}>
            {isAdmin ? 'Add Dealership' : 'Add Dealer user'}
          </Button>
        )}
        {dealerId !== undefined && dealerId > 0 && (
          <Button
            type="primary"
            className="h-full w-56 rounded-xl font-semibold"
            onClick={() => navigate(`/dealership/${dealerId}`)}
          >
            Visit Store
          </Button>
        )}
      </div>

      <Modal
        visible={showModal}
        style={{ top: 20 }}
        bodyStyle={{ paddingLeft: 50 }}
        onCancel={() => setShowModal(false)}
        className="w-full"
        title={<p className="font-bold">{isAdmin ? 'Add Dealership' : 'Add Dealer user'}</p>}
        footer={
          !hideOnProduction && (
            <p className="text-gray-400 text-sm text-center">
              {isAdmin
                ? 'The system will send an invitation to the dealership via email or text to create an Ez-Dealz account.'
                : 'The system will send an invitation to the dealer user via email or text to create an Ez-Dealz account.'}
            </p>
          )
        }
      >
        <Form form={form} layout="vertical" name="vehicle-ref-create" autoComplete="off" onChange={onFormChange}>
          {customerForm.map((form, index) => {
            return (
              <div key={index}>
                {form.name !== 'averageInterest' && <p className="text-base font-semibold">{form.label}</p>}
                <Form.Item
                  name={form.name}
                  hasFeedback
                  dependencies={form?.dependencies}
                  rules={
                    form.rules || [
                      {
                        required: form.required,
                        message: `${form.label} is required`
                      },
                      validation(form)
                    ]
                  }
                  className="custom-antd-label flex"
                >
                  {renderForm(form, index)}
                </Form.Item>
              </div>
            )
          })}

          <p className="text-red-500">{errorMsg}</p>

          <div className="mt-8">
            <Button type="primary" onClick={() => handleFormSubmission()} htmlType="submit" className="h-12 rounded-xl">
              {isAdmin ? 'Add Dealership' : 'Add Dealer'}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}

SearchDealerDashboard.propTypes = {
  filterParentData: PropTypes.func,
  dealershipAccount: PropTypes.object,
  isHidden: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  dealerId: PropTypes.number,
  setReloadDealers: PropTypes.func
}
