import { SearchOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Form,
  InputNumber,
  Modal,
  notification,
  Pagination,
  Select,
  Table,
  Tabs,
  Tooltip
} from 'antd'
import classNames from 'classnames'
import moment from 'moment'
import * as PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  AiOutlineCar,
  AiOutlineDollarCircle,
  AiOutlineKey,
  AiOutlineLoading3Quarters,
  AiOutlineUser,
  AiOutlineUsergroupAdd
} from 'react-icons/ai'
import { TiPencil } from 'react-icons/ti'
import UploadingDealershipLogoComponent from 'src/components/UploadingDealershipLogoComponent'
import { getSingleVehicleUrl } from 'src/utils'
import {
  STATES,
  STORAGE_KEYS,
  USER_PERMISSIONS,
  USER_ROLES,
  VALID_EMAIL,
  VALID_ZIP
} from '../../../constants/constants'
import { load, save } from '../../../lib/storage'
import * as DealerService from '../../../services/dealer.service'
import PhoneNumberInput from '../../InputComponent/PhoneNumberInput'
import InventorySyndication from '../InventorySyndication'
import SftpInventoryCredentials from '../SftpInventoryCredentials'
import { ExpandedRow } from './expandedRow'
import './style.scss'
import { useAuthorizationContext } from '../../../AuthorizationProvider'

const { TabPane } = Tabs

const tabKeys = {
  dealership_info: {
    id: 'dealership_info',
    name: 'Dealership Info'
  },
  add_ons: {
    id: 'add_ons',
    name: 'Add-ons'
  },
  inventory_syndication: {
    id: 'inventory_syndication',
    name: 'Inventory Syndication'
  },
  sftp_inventory_credentials: {
    id: 'sftp_inventory_credentials',
    name: 'SFTP Inventory Credentials'
  }
}

const optionsPermissions = USER_PERMISSIONS

const generatePassword = [
  {
    label: 'Email',
    name: 'email',
    required: true,
    type: 'text'
  }
]

const generatePasswordLogUser = [
  {
    label: 'Email',
    name: 'email',
    required: true,
    type: 'text'
  }
]

const optionsVehiclesStatus = [
  {
    label: 'On',
    value: 'on'
  },
  {
    label: 'Off',
    value: 'off'
  }
]

const optionsDealershipStatus = [
  {
    label: 'Claim',
    value: 'claimed'
  },
  {
    label: 'Un-claim',
    value: 'unclaimed'
  }
]

export function CustomDeals({
  searchText,
  filterParentData,
  showSearch,
  dealerId,
  isAdmin,
  employeeId,
  setLoading,
  loading,
  reloadDealers,
  setReloadDealers
}) {
  const newDataUser = load(STORAGE_KEYS.USER_SETTINGS)
  const [initialData, setInitialData] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [permissions, setPermissions] = useState([])
  const [permissionsLogUser, setPermissionsLogUser] = useState([])
  const [modal, setModal] = useState(false)
  const [modalLogUser, setModalLogUser] = useState(false)
  const [modalGeneratePassword, setModalGeneratePassword] = useState(false)
  const [modalGeneratePassword2, setModalGeneratePassword2] = useState(false)
  const [rowData, setRowData] = useState({})
  const [rowDataLogUser, setRowDataLogUser] = useState({})
  const [sortKey, setSortKey] = useState('all')
  const [keyword, setKeyword] = useState('')
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [total, setTotal] = useState(0)
  const [form] = Form.useForm()
  const [, forceUpdate] = useState({})
  const [bulkActionVehiclesStatus, setBulkActionVehiclesStatus] = useState(undefined)
  const [bulkActionDealershipStatus, setBulkActionDealershipStatus] = useState(undefined)
  const isDealer = undefined !== dealerId && dealerId > 0
  const [tabKey, setTabKey] = useState('dealership_info')
  const [editDealerModalWidth, setEditDealerModalWidth] = useState(640)
  const [triggerUploadDealershipLogo, setTriggerUploadDealershipLogo] = useState()
  const { adminAccount } = useAuthorizationContext()
  const canRelateDealerships = adminAccount?.role === USER_ROLES.ADMIN
  const isAdminDashboard = window.location.pathname === '/admin-dashboard'

  const editDealer = [
    {
      label: 'Dealership name',
      name: 'name',
      required: true,
      type: 'text'
    },
    {
      label: 'First Name',
      name: 'firstName',
      required: true,
      type: 'text'
    },
    {
      label: 'Last Name',
      name: 'lastName',
      required: true,
      type: 'text'
    },
    {
      label: 'Address',
      name: 'address',
      required: true,
      type: 'text'
    },
    {
      label: 'City',
      name: 'city',
      required: true,
      type: 'text'
    },
    {
      label: 'State',
      name: 'state',
      required: true,
      type: 'select',
      options: STATES.map((e) => ({ value: e.abbreviation, label: e.name }))
    },
    {
      label: 'Zip',
      name: 'zip',
      required: true,
      type: 'number'
    },
    {
      label: 'Email',
      name: 'email',
      required: true,
      type: 'text'
    },
    {
      label: 'Phone',
      name: 'phone',
      required: false,
      type: 'phone'
    },
    {
      label: 'Website',
      name: 'website',
      required: true,
      type: 'text'
    },
    {
      label: 'Registered',
      name: 'registered',
      required: false,
      type: 'date'
    },
    {
      label: 'Vehicles status',
      name: 'status',
      required: true,
      type: 'select',
      options: [
        {
          label: 'ON',
          value: 'on'
        },
        {
          label: 'OFF',
          value: 'off'
        }
      ]
    },
    {
      label: 'Dealership status',
      name: 'dealershipStatus',
      required: true,
      type: 'select',
      options: [
        {
          label: 'Claimed',
          value: 'claimed'
        },
        {
          label: 'Unclaimed',
          value: 'unclaimed'
        },
        {
          label: 'Verification',
          value: 'verification'
        }
      ]
    }
  ]

  if (isDealer) {
    editDealer.splice(0, 1)
    editDealer.splice(2, 1)
    editDealer.splice(2, 1)
    editDealer.splice(2, 1)
    editDealer.splice(2, 1)
    editDealer.splice(4, 1)
    editDealer.splice(5, 1)
    editDealer.splice(5, 1)
    editDealer.push({
      label: 'Permissions',
      name: 'permissions',
      rules: [
        {
          required: false,
          transform: (value) => value || undefined,
          type: 'array',
          message: 'Permissions is required',
          disabled: true
        }
      ],
      type: 'checkbox',
      options: USER_PERMISSIONS
    })
  }

  const editDealerAddOns = [
    {
      label: 'Customer Engagement Tracking Features',
      name: 'engagementTracking',
      required: true,
      type: 'select',
      options: [
        {
          label: 'ON',
          value: 'on'
        },
        {
          label: 'OFF',
          value: 'off'
        }
      ]
    },
    {
      label: 'Email Tracking Features',
      name: 'emailTracking',
      required: true,
      type: 'select',
      options: [
        {
          label: 'ON',
          value: 'on'
        },
        {
          label: 'OFF',
          value: 'off'
        }
      ]
    },
    {
      label: 'Show Other Nearby Dealers',
      name: 'showOtherNearbyDealers',
      required: true,
      type: 'select',
      options: [
        {
          label: 'ON',
          value: 'on'
        },
        {
          label: 'OFF',
          value: 'off'
        }
      ]
    },
    {
      label: 'Smartboard Features',
      name: 'smartboard',
      required: true,
      type: 'select',
      options: [
        {
          label: 'ON',
          value: 'on'
        },
        {
          label: 'OFF',
          value: 'off'
        }
      ]
    }
  ]

  const editLogUser = [
    {
      label: 'Dealership name',
      name: 'name',
      required: true,
      type: 'text'
    },
    {
      label: 'First Name',
      name: 'first_name',
      required: true,
      type: 'text'
    },
    {
      label: 'Last Name',
      name: 'last_name',
      required: true,
      type: 'text'
    },
    {
      label: 'Address',
      name: 'address',
      required: true,
      type: 'text'
    },
    {
      label: 'City',
      name: 'city',
      required: true,
      type: 'text'
    },
    {
      label: 'State',
      name: 'state',
      required: true,
      type: 'select',
      options: STATES.map((e) => ({ value: e.abbreviation, label: e.name }))
    },
    {
      label: 'Zip',
      name: 'zip',
      required: true,
      type: 'number'
    },
    {
      label: 'Email',
      name: 'email',
      required: true,
      type: 'text'
    },
    {
      label: 'Phone',
      name: 'phone',
      required: false,
      type: 'phone'
    },
    {
      label: 'Website',
      name: 'website',
      required: true,
      type: 'text'
    },
    {
      label: 'Registered',
      name: 'created_at',
      required: false,
      type: 'date'
    },
    {
      label: 'Vehicles status',
      name: 'status',
      required: true,
      type: 'select',
      options: [
        {
          label: 'ON',
          value: 'on'
        },
        {
          label: 'OFF',
          value: 'off'
        }
      ]
    },
    {
      label: 'Dealership status',
      name: 'dealershipStatus',
      required: true,
      type: 'select',
      options: [
        {
          label: 'Claimed',
          value: 'claimed'
        },
        {
          label: 'Unclaimed',
          value: 'unclaimed'
        }
      ]
    }
  ]

  if (isDealer) {
    editLogUser.splice(0, 1)
    editLogUser.splice(2, 1)
    editLogUser.splice(2, 1)
    editLogUser.splice(2, 1)
    editLogUser.splice(2, 1)
    editLogUser.splice(4, 1)
    editLogUser.splice(5, 1)
    editLogUser.splice(5, 1)
    editLogUser.push({
      label: 'Permissions',
      name: 'permissions',
      rules: [
        {
          required: true,
          transform: (value) => value || undefined,
          type: 'array',
          message: 'Permissions is required'
        }
      ],
      type: 'checkbox',
      options: USER_PERMISSIONS
    })
  }

  const getDealers = useCallback(
    (sortKey) => {
      DealerService.getDealers({
        page,
        rowsPerPage,
        status: sortKey,
        keyword,
        dealerId,
        employeeId,
        includeAdmin: isAdmin
      })
        .then((response) => {
          setInitialData(response.data.data)
          setTotal(response.data.total)
        })
        .catch(() => {
          setInitialData([])
          setPage(1)
        })
    },
    [sortKey, keyword, initialData, isAdmin]
  )

  useEffect(() => {
    if (reloadDealers) {
      getDealers()
      setReloadDealers(false)
    }
  }, [reloadDealers, isAdmin])

  const handleNavigateVehicle = (record) => {
    const {
      location: { origin }
    } = window
    const { frontend_url: vehicleUrl } = record
    const newVehicleUrl = getSingleVehicleUrl(vehicleUrl)
    window.open(`${origin}${newVehicleUrl}`)
  }

  const expandedRowRender = (record) => {
    return <ExpandedRow record={record} isDealer={isDealer} handleNavigateVehicle={handleNavigateVehicle} />
  }

  const openNotification = ({ message, description, type = 'success' }) => {
    const action = type === 'success' ? notification.success : notification.error
    action({
      message,
      description
    })
  }

  useEffect(() => {
    getDealers(sortKey)
  }, [isAdmin])

  useEffect(() => {
    if (Object.keys(rowData)?.length > 0) {
      if (isDealer) {
        form.setFieldsValue({
          firstName: rowData.firstName,
          lastName: rowData.lastName,
          email: rowData.email,
          phone: rowData.phone,
          registered: moment(rowData.registered).format('YYYY-MM-DD'),
          permissions: rowData.permissions
        })
        setPermissions(rowData.permissions)
      } else {
        form.setFieldsValue({
          name: rowData.name,
          firstName: rowData.firstName,
          lastName: rowData.lastName,
          address: rowData.address,
          city: rowData.city,
          state: rowData.state,
          zip: rowData.zip,
          email: rowData.email,
          phone: rowData.phone,
          website: rowData.website,
          registered: moment(rowData.registered).format('YYYY-MM-DD'),
          status: rowData.status,
          dealershipStatus: rowData.dealershipStatus,
          engagementTracking: rowData.engagementTracking ? rowData.engagementTracking : 'off',
          emailTracking: rowData.emailTracking ? rowData.emailTracking : 'off',
          showOtherNearbyDealers: rowData.showOtherNearbyDealers ? rowData.showOtherNearbyDealers : 'on',
          smartboard: rowData.smartboard ? rowData.smartboard : 'off'
        })
      }
    }
  }, [rowData])

  useEffect(() => {
    if (Object.keys(rowDataLogUser)?.length > 0) {
      const newDate = rowDataLogUser.created_at.split(' ')[0]
      if (isDealer) {
        form.setFieldsValue({
          first_name: rowDataLogUser.first_name,
          last_name: rowDataLogUser.last_name,
          email: rowDataLogUser.email,
          phone: rowDataLogUser.phone,
          created_at: newDate,
          permissions: Object.keys(rowDataLogUser.permissions)
        })
      }
    }
  }, [rowDataLogUser])

  const handleNavigateDealer = (record) => {
    const {
      location: { origin }
    } = window
    window.open(`${origin}/dealership/${record.dealer_id}`)
  }

  const handleNavigateDealershipPaymentSettings = (record) => {
    save(STORAGE_KEYS.DEALER_ID, record._id)
    const {
      location: { origin }
    } = window
    window.open(`${origin}/dealership-payment-settings`)
  }

  const handleNavigateDealershipUsers = (record) => {
    save(STORAGE_KEYS.DEALER_ID, record._id)
    const {
      location: { origin }
    } = window
    window.open(`${origin}/dealership-users`)
  }

  const handleNavigateRelatedDealership = (record) => {
    const {
      location: { origin }
    } = window
    window.open(`${origin}/related-dealerships/${record.employee_id}`)
  }

  const updateDealer = useCallback((values, callback) => {
    setLoading(true)
    let payload = {}
    if (isDealer) {
      payload = {
        employeeId: values.employee_id,
        dealerId: values.dealer_id,
        dealerName: values.name,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        registered: moment(values.registered).format('YYYY-MM-DD'),
        permissions: values.permissions,
        updateRole: 'dealer'
      }
    } else {
      payload = {
        employeeId: values.employee_id,
        dealerId: values.dealer_id,
        dealerName: values.name,
        firstName: values.firstName,
        lastName: values.lastName,
        address: values.address,
        email: values.email,
        phone: values.phone,
        website: values.website,
        city: values.city,
        state: values.state,
        zip: values.zip,
        registered: moment(values.registered).format('YYYY-MM-DD'),
        status: values.status,
        dealershipStatus: values.dealershipStatus,
        engagementTracking: values.engagementTracking ? values.engagementTracking : 'off',
        emailTracking: values.emailTracking ? values.emailTracking : 'off',
        showOtherNearbyDealers: values.showOtherNearbyDealers ? values.showOtherNearbyDealers : 'on',
        smartboard: values.smartboard ? values.smartboard : 'off',
        updateRole: 'dealership'
      }
    }

    DealerService.updateDealer(payload)
      .then(() => {
        if (callback) {
          callback()
          setTimeout(() => {
            setModal(false)
            setModalGeneratePassword(false)
            setRowData({})
            openNotification({ message: 'Success' })
            getDealers()
          }, 1000)
        } else {
          setModal(false)
          setModalGeneratePassword(false)
          setRowData({})
          openNotification({ message: 'Success' })
          getDealers()
        }
      })
      .catch((e) => {
        let errorMessage = e.message
        if (e.response) {
          errorMessage = e.response.data.message
        }
        openNotification({ message: errorMessage, type: 'error' })
      })
      .finally(setLoading(false))
  }, [])

  const updateDealerLogUser = useCallback((values) => {
    setLoading(true)
    save(STORAGE_KEYS.USER_SETTINGS, JSON.stringify({ ...newDataUser, ...values }))

    let payload = {}
    if (isDealer) {
      payload = {
        employeeId: values.employee_id,
        dealerId: values.dealer_id,
        dealerName: values.name,
        firstName: values.first_name,
        lastName: values.last_name,
        email: values.email,
        phone: values.phone,
        registered: moment(values.created_at).format('YYYY-MM-DD'),
        permissions: values.permissions,
        updateRole: 'dealer'
      }
    } else {
      payload = {
        employeeId: values.employee_id,
        dealerId: values.dealer_id,
        dealerName: values.name,
        firstName: values.firstName,
        lastName: values.lastName,
        address: values.address,
        email: values.email,
        phone: values.phone,
        website: values.website,
        city: values.city,
        state: values.state,
        zip: values.zip,
        registered: moment(values.registered).format('YYYY-MM-DD'),
        status: values.status,
        dealershipStatus: values.dealershipStatus,
        engagementTracking: values.engagementTracking ? values.engagementTracking : 'off',
        emailTracking: values.emailTracking ? values.emailTracking : 'off',
        showOtherNearbyDealers: values.showOtherNearbyDealers ? values.showOtherNearbyDealers : 'on',
        smartboard: values.smartboard ? values.smartboard : 'off',
        updateRole: 'dealership'
      }
    }

    DealerService.updateDealer(payload)
      .then(() => {
        setRowData({})
        setModalLogUser(false)
        setModalGeneratePassword(false)
        getDealers()
        openNotification({ message: 'Success' })
      })
      .catch((e) => {
        let errorMessage = e.message
        if (e.response) {
          errorMessage = e.response.data.message
        }
        openNotification({ message: errorMessage, type: 'error' })
      })
      .finally(setLoading(false))
  }, [])
  const generatePasswordEmployee = useCallback((values) => {
    setLoading(true)
    const payload = {
      dealerId: values.dealerId,
      employeeId: values.employeeId,
      adminRequest: values.adminRequest,
      email: values.email
    }
    DealerService.generatePasswordEmployee(payload)
      .then(() => {
        setRowData({})
        setModal(false)
        setModalGeneratePassword(false)
        getDealers()
        openNotification({ message: 'Success' })
      })
      .catch((e) => {
        let errorMessage = e.message
        if (e.response) {
          errorMessage = e.response.data.message
        }
        openNotification({ message: errorMessage, type: 'error' })
      })
      .finally(setLoading(false))
  }, [])

  const generatePasswordEmployeeLogUser = useCallback((values) => {
    setLoading(true)
    const payload = {
      dealerId: values.dealerId,
      employeeId: values.employeeId,
      adminRequest: values.adminRequest,
      email: values.email
    }
    DealerService.generatePasswordEmployee(payload)
      .then(() => {
        setRowDataLogUser({})
        setModalLogUser(false)
        setModalGeneratePassword2(false)
        getDealers()
        openNotification({ message: 'Success' })
      })
      .catch((e) => {
        let errorMessage = e.message
        if (e.response) {
          errorMessage = e.response.data.message
        }
        openNotification({ message: errorMessage, type: 'error' })
      })
      .finally(setLoading(false))
  }, [])

  const onChangeTabKey = (key) => {
    setEditDealerModalWidth(key === 'inventory_syndication' ? 1080 : 640)
    setTabKey(key)
  }

  const handleModalOpen = (record) => {
    setRowData(record)
    setModal(true)
    setModalGeneratePassword(false)
    setTabKey('dealership_info')
  }

  const handleModalGeneratePassword = (record) => {
    setRowData(record)
    setModal(false)
    setModalGeneratePassword(true)
  }

  const handleDealershipStatusClassName = (text) => {
    if (text === 'claimed') {
      return 'bg-green-400 text-white'
    }
    if (text === 'unclaimed') {
      return 'bg-red-400 text-white'
    }
    return 'bg-yellow-400 text-white'
  }

  const columns = [
    {
      title: 'DEALERSHIP NAME',
      dataIndex: 'name',
      align: 'left',
      width: '300px',
      render: (text, record, index) => {
        return (
          <div className={classNames({ 'w-96': record.isVerifiedEmail === false || !!record.isCustomPricing })}>
            <div key={index}>{text}</div>
            {record.dealershipStatus === 'unclaimed' && <div className="font-bold text-xs">(Sales leads)</div>}
            {record.isVerifiedEmail === false && <div className="font-bold text-xs">(Email needs to be verified)</div>}
            {!!record.isCustomPricing && (
              <div className="font-bold text-xs w-3/4">
                (This dealership needs to be contacted by PSX Sales for custom pricing)
              </div>
            )}
          </div>
        )
      }
    },
    {
      title: 'FIRST NAME',
      dataIndex: 'firstName',
      render: (text, record, index) => {
        return (
          <div>
            <div key={index}>{text}</div>
            {isDealer && record.isVerified === false && (
              <div className="font-bold text-xs">(Dealer account not verified)</div>
            )}
          </div>
        )
      }
    },
    {
      title: 'LAST NAME',
      dataIndex: 'lastName'
    },
    {
      title: 'SENT LEADS',
      dataIndex: 'leads',
      align: 'center'
    },
    {
      title: 'ADDRESS',
      dataIndex: 'address'
    },
    {
      title: 'CITY',
      dataIndex: 'city'
    },
    {
      title: 'STATE',
      dataIndex: 'state'
    },
    {
      title: 'ZIP',
      dataIndex: 'zip'
    },
    {
      title: 'EMAIL',
      dataIndex: 'email'
    },
    {
      title: 'PHONE',
      dataIndex: 'phone'
    },
    {
      title: 'WEBSITE',
      dataIndex: 'website'
    },
    {
      title: 'REGISTERED',
      dataIndex: 'registeredLabel'
    },
    {
      title: 'VEHICLES STATUS',
      dataIndex: 'status',
      render: (text, record, index) => (
        <div
          key={index}
          className={`text-center  ${
            text === 'on' ? 'bg-blue-100 text-blue-400' : 'bg-gray-100 text-gray-400'
          } justify-content w-14 py-1 align-center rounded-xl`}
        >
          <p className="align-ceter uppercase">{text}</p>
        </div>
      )
    },
    {
      title: 'DEALERSHIP STATUS',
      dataIndex: 'dealershipStatus',
      render: (text, record, index) => (
        <div
          key={index}
          className={`text-center ${handleDealershipStatusClassName(
            text
          )} justify-content w-21 py-1 align-center rounded-xl`}
        >
          <p className="align-center uppercase">{text}</p>
        </div>
      )
    },
    {
      title: 'PERMISSIONS',
      dataIndex: 'permissions',
      render: (text, record, index) => (
        <div key={index} className="text-left">
          {optionsPermissions.map((item) => {
            const classMarker = record.permissions.includes(item.value) ? 'text-green-600' : 'text-red-600'
            return (
              <p key={item.value} className={classMarker}>
                {item.label}
              </p>
            )
          })}
        </div>
      )
    },
    {
      title: 'ACTION',
      width: isDealer ? '150px' : '250px',
      dataIndex: 'action',
      render: (text, record, index) => (
        <div key={index} className="flex items-center justify-around">
          {!isDealer && (
            <Tooltip placement="topLeft" title="Payment settings">
              <Button
                onClick={() => handleNavigateDealershipPaymentSettings(record)}
                icon={<AiOutlineDollarCircle />}
                size="large"
                type="text"
                disabled={record.dealershipStatus !== 'claimed'}
                className="hover:bg-primary hover:text-white flex items-center justify-center rounded-xl"
              />
            </Tooltip>
          )}
          {!isDealer && (
            <Tooltip placement="topLeft" title="Users">
              <Button
                onClick={() => handleNavigateDealershipUsers(record)}
                icon={<AiOutlineUser />}
                size="large"
                type="text"
                disabled={record.dealershipStatus !== 'claimed'}
                className="hover:bg-primary hover:text-white flex items-center justify-center rounded-xl"
              />
            </Tooltip>
          )}
          {!isAdminDashboard && canRelateDealerships && (
            <Tooltip placement="topLeft" title="Related Dealerships">
              <Button
                onClick={() => handleNavigateRelatedDealership(record)}
                icon={<AiOutlineUsergroupAdd />}
                size="large"
                type="text"
                disabled={false}
                className="hover:bg-primary hover:text-white flex items-center justify-center rounded-xl"
              />
            </Tooltip>
          )}
          <Tooltip placement="topLeft" title="Inventory">
            <Button
              onClick={() => handleNavigateDealer(record)}
              icon={<AiOutlineCar />}
              size="large"
              type="text"
              className="hover:bg-primary hover:text-white flex items-center justify-center rounded-xl"
            />
          </Tooltip>
          <Tooltip placement="topLeft" title="Edit">
            <Button
              onClick={() => handleModalOpen(record)}
              icon={<TiPencil />}
              size="large"
              type="text"
              disabled={record.employee_id === '' || (!isAdminDashboard && record.role === USER_ROLES.DEALERSHIP_ADMIN)}
              className="hover:bg-primary hover:text-white flex items-center justify-center rounded-xl"
            />
          </Tooltip>
          <Tooltip placement="topLeft" title="Generate password">
            <Button
              onClick={() => handleModalGeneratePassword(record)}
              icon={<AiOutlineKey />}
              size="large"
              type="text"
              disabled={record.employee_id === '' || (!isAdminDashboard && record.role === USER_ROLES.DEALERSHIP_ADMIN)}
              className="hover:bg-primary hover:text-white flex items-center justify-center rounded-xl"
            />
          </Tooltip>
        </div>
      )
    }
  ]

  if (isDealer) {
    columns.splice(0, 1)
    columns.splice(3, 1)
    columns.splice(3, 1)
    columns.splice(3, 1)
    columns.splice(3, 1)
    columns.splice(5, 1)
    columns.splice(6, 1)
    columns.splice(6, 1)
  } else {
    columns.splice(14, 1)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
      setSelectedRows(selectedRows)
    }
  }
  const handleSortChange = useCallback(
    (page, rowsPerPage, sortKey, statusPrev) => {
      let selectedPage = page
      if (sortKey !== statusPrev) {
        selectedPage = 1
      }
      setSortKey(sortKey)
      DealerService.getDealers({
        page: selectedPage,
        rowsPerPage,
        status: sortKey,
        keyword,
        dealerId,
        employeeId,
        includeAdmin: isAdmin
      })
        .then((response) => {
          setTotal(response.data.total)
          setPage(selectedPage)
          setRowsPerPage(rowsPerPage)
          setInitialData(response.data.data)
        })
        .catch(() => {
          setInitialData([])
          setPage(1)
          setTotal(0)
        })
    },
    [page, rowsPerPage, sortKey, keyword, initialData]
  )

  const handleBulkActionVehicleStatus = (page, rowsPerPage, status) => {
    setBulkActionVehiclesStatus(status)
    if (selectedRows?.length > 0) {
      setLoading(true)
      DealerService.updateDealerStatusBulkAction({ dealers: selectedRows, status })
        .then(() => {
          handleSortChange(page, rowsPerPage, sortKey, sortKey)
          setSelectedRows([])
          setSelectedRowKeys([])
          openNotification({ message: 'Success' })
        })
        .catch((e) => {
          openNotification({ message: e.message, type: 'error' })
        })
        .finally(setLoading(false))
    } else {
      openNotification({ message: 'You need to select at least one dealer. Please try again!', type: 'error' })
    }
  }

  function printClaimResult(records) {
    const myWindow = window.open('', 'PRINT', 'height=400,width=600')
    myWindow.document.write(`<html lang="en"><head><title> ${document.title}</title>`)
    myWindow.document.write('</head><body>')
    myWindow.document.write(`<table border="1">`)
    myWindow.document.write(`<thead>`)
    myWindow.document.write(`<tr>`)
    myWindow.document.write(`<th>DEALER NAME</th>`)
    myWindow.document.write(`<th>EMAIL SENT</th>`)
    myWindow.document.write(`</th>`)
    myWindow.document.write(`</tr>`)
    myWindow.document.write(`</thead>`)
    myWindow.document.write(`<tbody>`)
    records.forEach((item) => {
      myWindow.document.write(`<tr>`)
      myWindow.document.write(`<td>${item.name}</td>`)
      myWindow.document.write(`<td>${item.status}</td>`)
      myWindow.document.write(`</tr>`)
    })
    myWindow.document.write(`</tbody>`)
    myWindow.document.write(`</table>`)
    myWindow.document.write('</body></html>')
    myWindow.focus()
    myWindow.print()
    return true
  }

  const handleBulkActionDealershipStatus = (page, rowsPerPage, status) => {
    setBulkActionDealershipStatus(status)
    if (selectedRows?.length > 0) {
      setLoading(true)

      let action = DealerService.setClaimStatus
      if (status !== 'claimed') {
        action = DealerService.setUnClaimStatus
      }

      action(selectedRowKeys)
        .then((response) => {
          handleSortChange(page, rowsPerPage, sortKey, sortKey)
          setSelectedRows([])
          setSelectedRowKeys([])
          if (status === 'claim') {
            printClaimResult(response.data)
          } else {
            openNotification({ message: 'Success' })
          }
        })
        .catch((e) => {
          openNotification({ message: e.message, type: 'error' })
        })
        .finally(setLoading(false))
    } else {
      openNotification({ message: 'You need to select at least one dealer. Please try again!', type: 'error' })
    }
  }

  const handleChangePage = (page, rowsPerPage) => {
    setPage(page)
    setRowsPerPage(rowsPerPage)
    handleSortChange(page, rowsPerPage, sortKey, sortKey)
  }

  useEffect(() => {
    setPage(1)
  }, [sortKey])

  useEffect(() => {
    setBulkActionVehiclesStatus(undefined)
  }, [selectedRows])

  useEffect(() => {
    setBulkActionDealershipStatus(undefined)
  }, [selectedRows])

  const handleChangePageSize = (page, rowsPerPage) => {
    setRowsPerPage(rowsPerPage)
  }

  useEffect(() => {
    if (searchText !== '') {
      setPage(1)
      setRowsPerPage(10)
      setSortKey('all')
      setKeyword(searchText)
      handleSortChange({ page: 1, rowsPerPage: 10, status: 'all', keyword: searchText })
    }
  }, [])

  useEffect(() => {
    forceUpdate({})
  }, [])
  useEffect(() => {
    if (!modal) {
      form.resetFields()
    }
  }, [modal])

  const handleChangePermissions = (e) => {
    const value = e.target.value
    let newPermissions = permissions
    newPermissions = [...permissions, value]
    if (permissions.includes(value)) {
      newPermissions = permissions.filter((item) => item !== value)
    }
    setPermissions(newPermissions)
    form.setFieldsValue({ ...form.getFieldValue(), [e.target.name]: newPermissions })
  }
  const handleChangePermissionsLogUser = (e) => {
    const value = e.target.value
    let newPermissions = permissionsLogUser
    newPermissions = [...permissionsLogUser, value]
    if (permissionsLogUser.includes(value)) {
      newPermissions = permissionsLogUser.filter((item) => item !== value)
    }
    setPermissionsLogUser(newPermissions)
    form.setFieldsValue({ ...form.getFieldValue(), [e.target.name]: newPermissions })
  }

  const onFormChange = (values) => {
    if (values.target.name === 'permissions') {
      handleChangePermissions(values)
    } else {
      form.setFieldsValue({ ...form.getFieldValue(), [values.target.name]: values.target.value })
    }
  }
  const onFormChange2 = (values) => {
    if (values.target.name === 'permissions') {
      handleChangePermissionsLogUser(values)
    } else {
      form.setFieldsValue({ ...form.getFieldValue(), [values.target.name]: values.target.value })
    }
  }

  const handleFormSubmission = async (value) => {
    const callback = () => setTriggerUploadDealershipLogo(true)
    updateDealer(
      {
        dealer_id: rowData.dealer_id,
        employee_id: rowData.employee_id,
        permissions,
        ...value
      },
      callback
    )
  }

  const handleFormAddOnsSubmission = async (value) => {
    updateDealer({
      dealer_id: rowData.dealer_id,
      employee_id: rowData.employee_id,
      ...value
    })
  }

  const handleFormSubmissionLogUser = async (value) => {
    updateDealerLogUser({
      ...value,
      dealer_id: rowDataLogUser.dealer_id,
      employee_id: rowDataLogUser.id,
      permissions: newDataUser.permissions
    })
  }
  const handleGeneratePasswordFormSubmission = (value) => {
    generatePasswordEmployee({
      dealerId: rowData.dealer_id,
      employeeId: rowData.employee_id ? rowData.employee_id : rowData.id,
      adminRequest: !isDealer,
      ...value
    })
  }

  const handleGeneratePasswordFormSubmissionLogUser = (value) => {
    generatePasswordEmployeeLogUser({
      ...value,
      dealerId: rowDataLogUser.dealer_id,
      employeeId: rowDataLogUser.employee_id ? rowDataLogUser.employee_id : rowDataLogUser.id,
      adminRequest: !isDealer
    })
  }

  const renderForm = (element, index) => {
    switch (element.type) {
      case 'select':
        return (
          <Select
            placeholder={element.placeholder || `Select ${element.name}`}
            size="large"
            name={element.name}
            mode={element.mode}
            onChange={(value) => {
              form.setFieldsValue({ ...form.getFieldValue(), [element.name]: value })
            }}
          >
            {element.options.map((option) => (
              <Select.Option value={option.value} key={option.value} className="p-2">
                {option.label}
              </Select.Option>
            ))}
          </Select>
        )
      case 'checkbox':
        return (
          <Checkbox.Group name={element.name} size="large">
            {element.options.map((option) => (
              <div className="flex justify-between mb-1.5" key={option.value}>
                <Checkbox value={option.value} className="flex items-center flex-1">
                  {option.label}
                </Checkbox>
              </div>
            ))}
          </Checkbox.Group>
        )
      case 'phone':
        return (
          <PhoneNumberInput
            placeholder={element.label}
            name={element.name}
            mask="(999) 999-9999"
            className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
          />
        )
      case 'zip':
        return (
          <InputNumber
            placeholder={element.label}
            name={element.name}
            className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
            type={element.type}
          />
        )
      default:
        return (
          <input
            placeholder={element.label}
            name={element.name}
            defaultValue={(rowData && rowData.email) || ''}
            autoFocus={index === 0}
            className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
            type={element.type}
          />
        )
    }
  }
  const renderFormLogUser = (element, index) => {
    switch (element.type) {
      case 'select':
        return (
          <Select
            placeholder={element.placeholder || `Select ${element.name}`}
            size="large"
            name={element.name}
            mode={element.mode}
            onChange={(value) => {
              form.setFieldsValue({ ...form.getFieldValue(), [element.name]: value })
            }}
          >
            {element.options.map((option) => (
              <Select.Option value={option.value} key={option.value} className="p-2">
                {option.label}
              </Select.Option>
            ))}
          </Select>
        )
      case 'checkbox':
        return (
          <Checkbox.Group disabled name={element?.name} size="large">
            <div name={element?.name}>
              {element?.options?.map((option) => (
                <div className="flex justify-between mb-1.5" key={option?.value}>
                  <Checkbox disabled value={option?.value} name={option?.name} className="flex items-center flex-1">
                    {option?.label}
                  </Checkbox>
                </div>
              ))}
            </div>
          </Checkbox.Group>
        )
      case 'phone':
        return (
          <PhoneNumberInput
            placeholder={element.label}
            name={element.name}
            mask="(999) 999-9999"
            className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
          />
        )
      case 'zip':
        return (
          <InputNumber
            placeholder={element.label}
            name={element.name}
            className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
            type={element.type}
          />
        )
      default:
        return (
          <input
            placeholder={element.label}
            name={element.name}
            defaultValue={(rowDataLogUser && rowDataLogUser.email) || ''}
            autoFocus={index === 0}
            className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
            type={element.type}
          />
        )
    }
  }

  const validation = (form) => {
    const patternMessage = {}
    switch (form.name) {
      case 'email':
        patternMessage.pattern = VALID_EMAIL
        patternMessage.message = 'Invalid email format'
        return patternMessage
      case 'phone':
        patternMessage.pattern = /(^[0-9]{3}-[0-9]{3}-[0-9]{4}$)|(^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$)/
        patternMessage.message = 'Invalid phone number format'
        return patternMessage
      case 'zip':
        patternMessage.pattern = VALID_ZIP
        patternMessage.message = 'Invalid zip code'
        return patternMessage
      default:
        return patternMessage
    }
  }

  const handleSearchDealers = useCallback(() => {
    setLoading(true)
    DealerService.getDealers({
      page,
      rowsPerPage,
      status: sortKey,
      keyword,
      dealerId,
      employeeId,
      includeAdmin: isAdmin
    })
      .then((response) => {
        setInitialData(response.data.data)
        setTotal(response.data.total)
      })
      .catch(() => {
        setInitialData([])
        setPage(1)
      })
      .finally(() => setLoading(false))
  }, [sortKey, keyword, initialData])

  const handleSearchChange = (e) => {
    const value = e.target.value
    filterParentData(value)
    setKeyword(value)
  }

  const pressEnterKey = (event) => {
    if (event.charCode === 13 && !loading) {
      handleSearchDealers()
    }
  }

  const useKey = (item) => {
    if (!isDealer) {
      return item._id
    }
    return item.employee_id
  }

  const handleModalCancel = () => {
    setRowData({})
    setEditDealerModalWidth(640)
    getDealers(sortKey)
  }

  const tableData = useMemo(() => initialData.map((item) => ({ ...item, key: useKey(item) })), [initialData])

  return (
    <>
      {showSearch && (
        <div className="w-full flex items-center justify-center mb-5">
          <div className="relative w-10/12 lg:w-3/12 xl:w-3/12">
            <input
              onChange={handleSearchChange}
              className="placeholder:text-xs placeholder:text-inputColor text-inputColor w-full flex-1 p-2 border border-inputColor bg-white outline-none rounded-l-md focus:border-primary rounded h-full"
              type="text"
              placeholder="Search"
              onKeyPress={pressEnterKey}
            />
            {loading ? (
              <AiOutlineLoading3Quarters className="text-blue-500 absolute right-3 top-1/3 -translate-y-2/4 animate-spin" />
            ) : (
              <SearchOutlined
                onClick={handleSearchDealers}
                className="text-blue-500 absolute right-3 top-1/3 -translate-y-2/4"
              />
            )}
          </div>
        </div>
      )}
      <div className="grid grid-cols-1 xl:grid-cols-8 lg:grid-cols-8 sm:grid-cols-1 mb-5 sm:mb-12 gap-3">
        <br className="lg:hidden xl:hidden" />
        <p className="font-semibold text-xl col-span-2 mb-3">{isDealer ? 'Dealer users' : 'Dealerships'}</p>

        {!isDealer && (
          <div className="grid gap-y-1.5 text-13 xl:mr-5 md:grid-cols-4 grid-cols-3 col-span-4 xl:h-7 lg:h-7 w-90 md:w-100 mb-2">
            <div
              className={`items-center text-13 font-bold flex-1 pb-3 flex justify-center cursor-pointer ${
                sortKey === 'all'
                  ? 'bg-white border-b-8 border-primary rounded-lg'
                  : 'text-gray-300 bg-white border-b-8 border-gray'
              }`}
              onClick={() => handleSortChange(page, rowsPerPage, 'all', sortKey)}
            >
              ALL
            </div>
            <div
              className={`items-center text-13 font-bold flex-1 pb-3 flex justify-center cursor-pointer ${
                sortKey === 'on'
                  ? 'bg-white border-b-8 border-primary rounded-lg'
                  : 'text-gray-300 bg-white border-b-8 border-gray'
              }`}
              onClick={() => handleSortChange(page, rowsPerPage, 'on', sortKey)}
            >
              ON
            </div>
            <div
              className={`items-center text-13 font-bold flex-1 pb-3 flex justify-center cursor-pointer ${
                sortKey === 'off'
                  ? 'bg-white border-b-8 border-primary rounded-lg'
                  : 'text-gray-300 bg-white border-b-8 border-gray'
              }`}
              onClick={() => handleSortChange(page, rowsPerPage, 'off', sortKey)}
            >
              OFF
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-1 ml-0 lg:ml-2 mt-1">
              <div className="flex items-center gap-2 col-span-2">
                <Select
                  className="rounded w-36"
                  onChange={(e) => handleBulkActionVehicleStatus(page, rowsPerPage, e)}
                  placeholder="Vehicles status"
                  value={bulkActionVehiclesStatus}
                  disabled={!selectedRows || selectedRows.length < 1 || loading}
                >
                  {optionsVehiclesStatus.map((item) => (
                    <Select.Option value={item.value} key={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        )}
        {!isDealer && (
          <div className="grid gap-y-1.5 text-13 grid-cols-3 sm:grid-cols-4 xl:grid-cols-6 col-span-4 xl:h-7 lg:h-7 w-90 md:w-100">
            <div
              className={`items-center text-13 font-bold flex-1 pb-3 flex justify-center cursor-pointer ${
                sortKey === 'all_dealerships'
                  ? 'bg-white border-b-8 border-primary rounded-lg'
                  : 'text-gray-300 bg-white border-b-8 border-gray'
              }`}
              onClick={() => handleSortChange(page, rowsPerPage, 'all_dealerships', sortKey)}
            >
              ALL
            </div>
            <div
              className={`items-center text-13 font-bold flex-1 pb-3 flex justify-center cursor-pointer ${
                sortKey === 'only_claimed'
                  ? 'bg-white border-b-8 border-primary rounded-lg'
                  : 'text-gray-300 bg-white border-b-8 border-gray'
              }`}
              onClick={() => handleSortChange(page, rowsPerPage, 'only_claimed', sortKey)}
            >
              CLAIMED
            </div>
            <div
              className={`items-center text-13 font-bold flex-1 pb-3 flex justify-center cursor-pointer ${
                sortKey === 'under_verification'
                  ? 'bg-white border-b-8 border-primary rounded-lg'
                  : 'text-gray-300 bg-white border-b-8 border-gray'
              }`}
              onClick={() => handleSortChange(page, rowsPerPage, 'under_verification', sortKey)}
            >
              VERIFICATION
            </div>
            <div
              className={`items-center text-13 font-bold flex-1 pb-3 flex justify-center cursor-pointer ${
                sortKey === 'only_unclaimed'
                  ? 'bg-white border-b-8 border-primary rounded-lg'
                  : 'text-gray-300 bg-white border-b-8 border-gray'
              }`}
              onClick={() => handleSortChange(page, rowsPerPage, 'only_unclaimed', sortKey)}
            >
              UNCLAIMED
            </div>
            <div className="grid grid-cols-1 ml-1 sm:ml-0 sm:grid-cols-1 sm:mt-0 lg:ml-2">
              <div className="flex items-center col-span-2">
                <Select
                  className="rounded w-42"
                  onChange={(e) => handleBulkActionDealershipStatus(page, rowsPerPage, e)}
                  placeholder="Dealership status"
                  value={bulkActionDealershipStatus}
                  disabled={!selectedRows || selectedRows.length < 1 || loading}
                >
                  {optionsDealershipStatus.map((item) => (
                    <Select.Option value={item.value} key={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        )}
      </div>
      {initialData?.length > 0 && isDealer && (
        <Table
          id="_id"
          scroll={{ x: 768, y: 500 }}
          className="components-table-demo-nested item-center admin-dealers"
          pagination={{ position: ['none', 'none'], pageSize: rowsPerPage }}
          columns={[...columns]}
          dataSource={[...tableData]}
          expandable={{
            expandedRowRender,
            expandable: true
          }}
        />
      )}
      {initialData?.length > 0 && !isDealer && (
        <Table
          id="_id"
          scroll={{ x: 1920, y: 500 }}
          className="components-table-demo-nested item-center admin-dealers"
          pagination={{ position: ['none', 'none'], pageSize: rowsPerPage }}
          columns={[...columns]}
          dataSource={[...tableData]}
          expandable={{
            expandedRowRender,
            expandable: true
          }}
          rowSelection={rowSelection}
        />
      )}

      {Object.keys(rowDataLogUser).length > 0 && (
        <Modal
          visible={modalLogUser}
          style={{ top: 20 }}
          bodyStyle={{ paddingLeft: 50 }}
          onCancel={() => setRowDataLogUser({})}
          className="w-full"
          title={<p className="font-bold">Edit {isDealer ? 'Dealer user' : 'Dealership'}</p>}
          footer={null}
          dataSource={rowDataLogUser}
        >
          <Form
            form={form}
            layout="vertical"
            name="vehicle-ref-edit"
            onFinish={handleFormSubmissionLogUser}
            onChange={onFormChange2}
          >
            {editLogUser.map((form, index) => {
              return (
                <div key={index}>
                  {form.name !== 'averageInterest' && <p className="text-base font-semibold">{form.label}</p>}
                  <Form.Item
                    name={form.name}
                    initialValue={form.value}
                    hasFeedback
                    dependencies={form?.dependencies}
                    rules={
                      form.rules || [
                        {
                          required: form.required,
                          message: `${form.label} is required`
                        },
                        validation(form)
                      ]
                    }
                    className="custom-antd-label flex"
                  >
                    {renderFormLogUser(form, index)}
                  </Form.Item>
                </div>
              )
            })}

            <Form.Item className="flex items-end w-full mt-8 submit" shouldUpdate>
              {() => (
                <Button type="primary" htmlType="submit" className="h-12 rounded-xl">
                  Update
                </Button>
              )}
            </Form.Item>
          </Form>
        </Modal>
      )}

      {/* Edit Dealer user and Dealership */}
      {Object.keys(rowData).length > 0 && (
        <Modal
          visible={modal}
          style={{ top: 20, minWidth: editDealerModalWidth }}
          bodyStyle={{ paddingLeft: 50 }}
          closable
          onCancel={handleModalCancel}
          className="w-full"
          title={<p className="font-bold">Edit {isDealer ? 'Dealer user' : 'Dealership'}</p>}
          footer={null}
          dataSource={rowData}
        >
          {rowData.dealershipStatus !== 'claimed' && (
            <Form
              form={form}
              layout="vertical"
              name="vehicle-ref-edit"
              onFinish={handleFormSubmission}
              onChange={onFormChange}
            >
              {editDealer.map((form, index) => {
                return (
                  <div key={index}>
                    {form.name !== 'averageInterest' && <p className="text-base font-semibold">{form.label}</p>}
                    <Form.Item
                      name={form.name}
                      initialValue={form.value}
                      hasFeedback
                      dependencies={form?.dependencies}
                      rules={
                        form.rules || [
                          {
                            required: form.required,
                            message: `${form.label} is required`
                          },
                          validation(form)
                        ]
                      }
                      className="custom-antd-label flex"
                    >
                      {renderForm(form, index)}
                    </Form.Item>
                  </div>
                )
              })}
              {!isDealer && (
                <div>
                  <div className="text-base font-semibold">Select dealership logo</div>
                  <UploadingDealershipLogoComponent
                    setTriggerUploadDealershipLogo={setTriggerUploadDealershipLogo}
                    triggerUploadDealershipLogo={triggerUploadDealershipLogo}
                    initialImageUrl={rowData.dealerLogo}
                    dealerId={rowData.dealer_id}
                  />
                  <p className="w-1/2 italic text-xs">
                    You can upload any standard image file <b>(JPG, PNG) for up to 2MB file size</b>.
                  </p>
                  <p className="w-1/2 italic text-xs">
                    For optimal display of the logo, we recommend uploading a <b>square image</b>.
                  </p>
                </div>
              )}
              <Form.Item className="flex items-end w-full mt-8 submit" shouldUpdate>
                {() => (
                  <Button type="primary" htmlType="submit" className="h-12 rounded-xl">
                    Update
                  </Button>
                )}
              </Form.Item>
            </Form>
          )}
          {!isDealer && rowData.dealershipStatus === 'claimed' && (
            <Tabs
              className="pricing-tables"
              defaultActiveKey={tabKey}
              activeKey={tabKey}
              tabPosition="top"
              onChange={onChangeTabKey}
              renderTabBar={(props, TabNavList) => <TabNavList {...props} mobile />}
            >
              {Object.keys(tabKeys).map((key) => (
                <TabPane tab={tabKeys[key].name} key={tabKeys[key].id}>
                  {key === 'dealership_info' && (
                    <div className="mt-3.5">
                      <Form
                        form={form}
                        layout="vertical"
                        name="vehicle-ref-edit"
                        onFinish={handleFormSubmission}
                        onChange={onFormChange}
                      >
                        {editDealer.map((form, index) => {
                          return (
                            <div key={index}>
                              {form.name !== 'averageInterest' && (
                                <p className="text-base font-semibold">{form.label}</p>
                              )}
                              <Form.Item
                                name={form.name}
                                initialValue={form.value}
                                hasFeedback
                                dependencies={form?.dependencies}
                                rules={
                                  form.rules || [
                                    {
                                      required: form.required,
                                      message: `${form.label} is required`
                                    },
                                    validation(form)
                                  ]
                                }
                                className="custom-antd-label flex"
                              >
                                {renderForm(form, index)}
                              </Form.Item>
                            </div>
                          )
                        })}
                        <div>
                          <div className="text-base font-semibold">Select dealership logo</div>
                          <UploadingDealershipLogoComponent
                            setTriggerUploadDealershipLogo={setTriggerUploadDealershipLogo}
                            triggerUploadDealershipLogo={triggerUploadDealershipLogo}
                            initialImageUrl={rowData.dealerLogo}
                            dealerId={rowData.dealer_id}
                          />
                          <p className="w-1/2 italic text-xs">
                            You can upload any standard image file <b>(JPG, PNG) for up to 2MB file size</b>.
                          </p>
                          <p className="w-1/2 italic text-xs">
                            For optimal display of the logo, we recommend uploading a <b>square image</b>.
                          </p>
                        </div>

                        <Form.Item className="flex items-end w-full mt-8 submit" shouldUpdate>
                          {() => (
                            <Button type="primary" htmlType="submit" className="h-12 rounded-xl">
                              Update
                            </Button>
                          )}
                        </Form.Item>
                      </Form>
                    </div>
                  )}
                  {key === 'add_ons' && (
                    <div className="mt-3.5">
                      <Form
                        form={form}
                        layout="vertical"
                        name="vehicle-ref-edit"
                        onFinish={handleFormAddOnsSubmission}
                        onChange={onFormChange}
                      >
                        {editDealerAddOns.map((form, index) => {
                          return (
                            <div key={index}>
                              {form.name !== 'averageInterest' && (
                                <p className="text-base font-semibold">{form.label}</p>
                              )}
                              <Form.Item
                                name={form.name}
                                initialValue={form.value}
                                hasFeedback
                                dependencies={form?.dependencies}
                                rules={
                                  form.rules || [
                                    {
                                      required: form.required,
                                      message: `${form.label} is required`
                                    },
                                    validation(form)
                                  ]
                                }
                                className="custom-antd-label flex"
                              >
                                {renderForm(form, index)}
                              </Form.Item>
                            </div>
                          )
                        })}

                        <Form.Item className="flex items-end w-full mt-8 submit" shouldUpdate>
                          {() => (
                            <Button type="primary" htmlType="submit" className="h-12 rounded-xl">
                              Update
                            </Button>
                          )}
                        </Form.Item>
                      </Form>
                    </div>
                  )}
                  {key === 'inventory_syndication' && (
                    <div className="mt-3.5">
                      <InventorySyndication dealer={rowData} isDealer={dealerId > 0} />
                    </div>
                  )}
                  {key === 'sftp_inventory_credentials' && (
                    <div className="mt-3.5">
                      <SftpInventoryCredentials dealer={rowData} />
                    </div>
                  )}
                </TabPane>
              ))}
            </Tabs>
          )}
        </Modal>
      )}

      {/* Generate Password modal */}
      {Object.keys(rowDataLogUser).length > 0 && (
        <Modal
          visible={modalGeneratePassword2}
          title="Generate Password"
          footer={null}
          style={{ top: 20 }}
          onCancel={() => setRowDataLogUser({})}
          className="w-full"
          dataSource={rowDataLogUser}
        >
          <Form
            form={form}
            layout="vertical"
            name="generate-password"
            onFinish={handleGeneratePasswordFormSubmissionLogUser}
            onChange={onFormChange2}
          >
            {generatePasswordLogUser.map((form, index) => {
              return (
                <div key={index}>
                  <Form.Item
                    name={form.name}
                    hasFeedback
                    dependencies={form?.dependencies}
                    rules={[
                      {
                        required: form.required,
                        message: `${form.label} is required`
                      },
                      validation(form)
                    ]}
                    className="custom-antd-label flex"
                  >
                    {form.name !== 'averageInterest' && <p className="text-base font-semibold">{form.label}</p>}
                    {renderFormLogUser(form, index)}
                  </Form.Item>
                </div>
              )
            })}

            <Form.Item className="flex items-end w-full mt-8 submit" shouldUpdate>
              {() => (
                <Button type="primary" htmlType="submit" className="h-12 rounded-xl">
                  Generate Password
                </Button>
              )}
            </Form.Item>
          </Form>
        </Modal>
      )}

      {/* Generate Password modal */}
      {Object.keys(rowData).length > 0 && (
        <Modal
          visible={modalGeneratePassword}
          title="Generate Password"
          footer={null}
          style={{ top: 20 }}
          onCancel={() => setRowData({})}
          className="w-full"
          dataSource={rowData}
        >
          <Form
            form={form}
            layout="vertical"
            name="generate-password"
            onFinish={handleGeneratePasswordFormSubmission}
            onChange={onFormChange}
          >
            {generatePassword.map((form, index) => {
              return (
                <div key={index}>
                  <Form.Item
                    name={form.name}
                    hasFeedback
                    dependencies={form?.dependencies}
                    rules={[
                      {
                        required: form.required,
                        message: `${form.label} is required`
                      },
                      validation(form)
                    ]}
                    className="custom-antd-label flex"
                  >
                    {form.name !== 'averageInterest' && <p className="text-base font-semibold">{form.label}</p>}
                    {renderForm(form, index)}
                  </Form.Item>
                </div>
              )
            })}

            <Form.Item className="flex items-end w-full mt-8 submit" shouldUpdate>
              {() => (
                <Button type="primary" htmlType="submit" className="h-12 rounded-xl">
                  Generate Password
                </Button>
              )}
            </Form.Item>
          </Form>
        </Modal>
      )}

      <div className="flex justify-center mb-8 pagination-margin-top-20">
        <div className="flex items-center">
          <Pagination
            className="flex"
            current={page}
            responsive
            total={initialData.length > 0 ? total : 0}
            pageSize={rowsPerPage}
            onChange={handleChangePage}
            onShowSizeChange={handleChangePageSize}
            pageSizeOptions={[10, 20, 30, 40, 50]}
          />
        </div>
      </div>
    </>
  )
}

CustomDeals.defaultProps = {
  showSearch: true
}

CustomDeals.propTypes = {
  searchText: PropTypes.string,
  filterParentData: PropTypes.func,
  showSearch: PropTypes.bool,
  dealerId: PropTypes.number,
  isAdmin: PropTypes.bool,
  employeeId: PropTypes.string,
  setLoading: PropTypes.func,
  loading: PropTypes.bool,
  reloadDealers: PropTypes.bool,
  setReloadDealers: PropTypes.func
}
