import { Divider } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import userAvator from 'src/assets/icon/userAvator.png'
import { MenuLinkDealerDashboard, USER_ROLES } from 'src/constants/constants'
import { handleClearStorageUserInfo } from 'src/lib/storage'
import { SwitchDealershipModal } from '../SwitchDealershipModal'
import Logo from '../../../assets/new-logo.png'
import './style.scss'

function DealerHeaderDashboard({ scrollTo, dealershipAccount, setActiveKey }) {
  const { handleDealerAccountLogin, setDealershipAccount } = useAuthorizationContext()
  const navigate = useNavigate()
  /*   const scrollInfo = useWindowScroll() */
  const location = useLocation()
  const [showDealershipsList, setShowDealershipsList] = useState(false)
  const [mobileMenu, setMobileMenu] = useState(false)
  const { width: screenWidth } = useWindowSize()
  const isMobile = screenWidth <= 1024

  const dealershipLogo = dealershipAccount?.dealer_logo || ''
  const toggleDealershipsList = dealershipAccount?.toggleDealershipsList || []
  const canEditInventory = undefined !== dealershipAccount ? dealershipAccount?.permissions?.edit_inventory : true

  const handleNavigateMyInventory = () => {
    navigate('/dealer-dashboard?scrollTo=my-inventory')
  }

  const handleClickScroll = (index) => {
    if (index === 6) {
      setActiveKey('2')
      const analyticsElement = document.getElementById('statistic-col')
      analyticsElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const showMobileMenu = () => {
    setMobileMenu(true)
    document.querySelector('body').setAttribute('style', 'overflow: hidden;')
  }

  const closeMobileMenu = () => {
    setMobileMenu(false)
    document.querySelector('body').setAttribute('style', '')
  }

  const handleLogout = () => {
    handleClearStorageUserInfo()
    setDealershipAccount()
    handleDealerAccountLogin(false)
    navigate('/')
  }

  const handleSwitchDealership = () => {
    setShowDealershipsList(true)
  }

  const handleChangeShowDealershipsList = () => setShowDealershipsList(!showDealershipsList)

  const goToDealershipDashboard = () => {
    navigate('/dealer-dashboard')
  }

  const dealerFirstName = dealershipAccount?.first_name || ''
  const dealerName = dealershipAccount?.dealer_name || ''

  const handleClickLogo = () => {
    const role = dealershipAccount?.role || ''
    if (role === USER_ROLES.DEALERSHIP_ADMIN || role === USER_ROLES.DEALERSHIP_USER) {
      navigate('/my-account')
      setMobileMenu(false)
    }
  }

  const isHomePage = window.location.pathname === '/'
  const handleSeeMoreDeals = () => {
    closeMobileMenu()
    const y = document.getElementById('best-deals-of-the-week').getBoundingClientRect().top + window.scrollY
    window.scroll({
      top: y,
      behavior: 'smooth'
    })
  }

  return (
    <>
      <div className="md:sticky md:shadow-lg left-0 top-0 w-full z-50">
        <div className="bg-white">
          <div className="container ml-container mx-auto flex justify-end gap-4 py-1">
            <div
              className={`flex items-center 
          justify-end gap-1 pt-3 ${isMobile ? 'pb-5' : ''}`}
            >
              <div className="flex cursor-pointer" onClick={handleClickLogo}>
                <img
                  className="sm:ml-2 mr-1 w-8 p-1 rounded-full ring-1 ring-gray-300 dark:ring-gray-500"
                  src={userAvator}
                  alt="HL"
                />
              </div>
              <div className="flex items-center justify-end gap-1">
                <p>Hi</p>
                <p className="cursor-pointer hover:text-primary break-all" onClick={goToDealershipDashboard}>
                  {dealerFirstName ?? dealerName}
                </p>
                <Divider type="vertical" />
                <p onClick={handleLogout} className="cursor-pointer hover:text-primary">
                  Logout
                </p>
                {canEditInventory && toggleDealershipsList.length > 1 && <Divider type="vertical" />}
                {canEditInventory && toggleDealershipsList.length > 1 && (
                  <p onClick={handleSwitchDealership} className="cursor-pointer hover:text-primary">
                    Switch Dealership
                  </p>
                )}
                {canEditInventory && toggleDealershipsList.length > 1 && (
                  <SwitchDealershipModal
                    dealerId={dealershipAccount.dealer_id}
                    dealerships={toggleDealershipsList}
                    showModal={showDealershipsList}
                    handleCloseModal={handleChangeShowDealershipsList}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row items-center justify-between bg-white">
          <div className="container ml-container mx-auto flex items-center">
            <div className="w-full flex">
              <Link to="/">
                <div className="flex gap-1">
                  <img
                    className={`dealer-logo ${
                      isMobile ? 'mobile py-2' : ''
                    }   transition-all delay-150 duration-300 ease-in-out`}
                    src={Logo}
                    alt="Logo"
                  />
                  {dealershipLogo.length > 0 && !isMobile && (
                    <img
                      className={`dealer-logo ${
                        isMobile ? 'mobile py-2' : ''
                      }   transition-all delay-150 duration-300 ease-in-out dealership-logo-desktop`}
                      src={dealershipLogo}
                      alt="Dealership Logo"
                    />
                  )}
                </div>
                {dealershipLogo.length > 0 && isMobile && (
                  <div className="flex gap-1">
                    <img
                      className={`dealer-logo ${
                        isMobile ? 'mobile py-2' : ''
                      }   transition-all delay-150 duration-300 ease-in-out dealership-logo-mobile`}
                      src={dealershipLogo}
                      alt="Dealership Logo"
                    />
                  </div>
                )}
              </Link>
            </div>

            <div className="lg:flex flex-row w-full items-center justify-end">
              <ul className="hidden list-none lg:flex flex-row items-end ml-0 lg:ml-10 nav-style">
                {MenuLinkDealerDashboard.map((menu, index) => (
                  <li
                    className={`cursor-pointer menu-item ${location.pathname === menu.link ? 'active' : ''} ${
                      menu.hidden ? 'hidden' : ''
                    }`}
                    key={`menu-item-${index}`}
                    onClick={() => scrollTo(index)}
                  >
                    {index === 1 && (
                      <Link to="/dealer-dashboard">
                        <div className="px-0 py-1" onClick={() => handleNavigateMyInventory}>
                          {menu.name}
                        </div>
                      </Link>
                    )}

                    {index === 6 && (
                      <Link to="/dealer-dashboard">
                        <div className="px-0 py-1" onClick={() => handleClickScroll(index)}>
                          {menu.name}
                        </div>
                      </Link>
                    )}

                    {index !== 1 && index !== 6 && (
                      <Link to={`${menu.link}`}>
                        <div className="px-0 py-1">{menu.name}</div>
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
              <svg
                stroke="currentColor"
                fill="currentColor"
                viewBox="0 0 24 24"
                className="h-6 w-auto lg:hidden cursor-pointer float-right mobile-nav-menu"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
                onClick={showMobileMenu}
              >
                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      {mobileMenu && (
        <div className="w-screen h-screen fixed top-0 left-0 overflow-y-scroll bg-white mobile-menu pb-6">
          <div className="h-full flex flex-col px-4">
            <div className="flex mobile-img flex-row justify-between py-4 items-center border-b">
              <img src={Logo} alt="" className="w-auto object-contain" />
              <button type="button" className="py-1 px-1 outline-none" onClick={closeMobileMenu}>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  className="h-8 w-auto"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
              </button>
            </div>
            <div className="flex flex-col justify-end w-full mx-auto">
              <ul className="flex flex-col list-none gap-6 items-baseline text-xl mb-10 mt-3 mobile">
                {MenuLinkDealerDashboard.map((menu, index) => (
                  <li
                    className={`block w-full text-primary mb-2 ${location.pathname === menu.link ? 'active' : ''} ${
                      menu.hidden ? 'hidden' : ''
                    }`}
                    key={`menu-mobile-item-${index}`}
                    onClick={closeMobileMenu}
                    role="presentation"
                  >
                    {index === 1 && (
                      <Link to="/dealer-dashboard">
                        <div
                          className="px-3 py-2 rounded-xl hover:bg-primary hover:text-white menu-link"
                          onClick={() => handleClickScroll(index)}
                        >
                          {menu.name}
                        </div>
                      </Link>
                    )}

                    {index === 6 && (
                      <Link to="/dealer-dashboard">
                        <div
                          className="px-3 py-2 rounded-xl hover:bg-primary hover:text-white menu-link"
                          onClick={() => handleClickScroll(index)}
                        >
                          {menu.name}
                        </div>
                      </Link>
                    )}

                    {index !== 1 && index !== 6 && (
                      <Link to={menu.link}>
                        <div className="px-3 py-2 rounded-xl hover:bg-primary hover:text-white menu-link">
                          {menu.name}
                        </div>
                      </Link>
                    )}
                  </li>
                ))}
                <li className="block sm:hidden w-full cursor-pointer hover:text-primary" onClick={handleClickLogo}>
                  <div className="flex items-center justify-center sm:justify-end">
                    <img
                      className="sm:ml-2 h-10 w-10 p-1 rounded-full ring-1 ring-gray-300 dark:ring-gray-500"
                      src={userAvator}
                      alt="HL"
                    />
                    <p className="pl-2">{dealershipAccount?.first_name} 111</p>
                  </div>
                </li>
              </ul>
              {isHomePage && (
                <div className="flex justify-center">
                  <button
                    type="button"
                    className="mt-6 rounded-xl border border-primary px-8 py-2 hover:bg-primary hover:text-white outline-none"
                    onClick={handleSeeMoreDeals}
                  >
                    See More Deals
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

DealerHeaderDashboard.propTypes = {
  scrollTo: PropTypes.func,
  dealershipAccount: PropTypes.object,
  setActiveKey: PropTypes.func
}

export default DealerHeaderDashboard
