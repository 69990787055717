/* eslint-disable no-nested-ternary */
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons'
import { Button, Pagination, Popover, Tag } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import { INITIAL_PRICE, STORAGE_KEYS } from 'src/constants/constants'
import { load } from 'src/lib/storage'
import * as DealerService from '../../../services/dealer.service'
import VehicleCard from '../../Buy/vehicleCard'
import FilterBuyCar from '../../FilterBuyCar/FilterBuyCar'
import './style.scss'

const initYear = {
  min: 2009,
  max: 2023
}
const initMileage = {
  min: 0,
  max: 200000
}

const getFilterBody = ({
  zipCode,
  zipCodeMileRange,
  price,
  year,
  mileage,
  make,
  model,
  trim,
  transmission,
  color,
  certifiedVehicles,
  similarDeal,
  sortKey,
  sortType,
  keyword,
  dealerId
}) => {
  const filterBody = {}
  if (zipCode !== '') filterBody.zipCode = zipCode
  if (zipCodeMileRange !== '' && zipCode !== '') filterBody.zipRadius = zipCodeMileRange
  if (price && (price.min !== INITIAL_PRICE.min || price.max !== INITIAL_PRICE.max)) filterBody.price = price
  if (year && (year.min !== initYear.min || year.max !== initYear.max)) filterBody.year = year
  if (mileage && (mileage.min !== initMileage.min || mileage.max !== initMileage.max)) filterBody.mileage = mileage
  if (make && make.length > 0) filterBody.make = make
  if (model && model.length > 0) filterBody.model = model
  if (trim && trim.length > 0) filterBody.trim = trim
  if (color && color.length > 0) filterBody.color = color
  if (transmission && transmission.length > 0) filterBody.transmission = transmission
  if (color && color.length > 0) filterBody.color = color
  filterBody.certifiedVehicles = certifiedVehicles
  if (sortKey && sortKey.length > 0) filterBody.sortBy = sortKey
  if (sortType && sortType.length > 0) filterBody.orderBy = sortType
  if (keyword !== '') filterBody.keyword = keyword
  if (dealerId && dealerId > 0) filterBody.dealerId = dealerId
  if (similarDeal && similarDeal.length > 0) filterBody.similarDeal = similarDeal
  return filterBody
}

function DealershipInventory({ dealerId }) {
  const [sortKey, setSortKey] = useState('all')
  const [keyword, setKeyword] = useState('')
  const [sortType, setSortType] = useState('asc')
  const [buyCars, setBuyCars] = useState([])
  const oldBuyCarRef = useRef([])

  const [zipCode, setZipCode] = useState('')
  const [certified, setCertified] = useState([])
  const [zipCodeDefault, setZipCodeDefault] = useState(false)
  const [price, setPrice] = useState(INITIAL_PRICE)
  const [priceDefault, setPriceDefault] = useState(false)
  const [year, setYear] = useState(initYear)
  const [yearDefault, setYearDefault] = useState(false)
  const [mileage, setMileage] = useState(initMileage)
  const [mileageDefault, setMileageDefault] = useState(false)
  const [make, setMake] = useState([])
  const [model, setModel] = useState([])
  const [trim, setTrim] = useState([])
  const [transmission, setTransmission] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const initialRef = useRef(true)

  const [showOnlyFavoriteVehicles, setShowOnlyFavoriteVehicles] = useState(false)
  const favoriteCarsLocal = load(STORAGE_KEYS.FAVOURITE_CARS) || []
  const [favouriteCars, setFavouriteCars] = useState(favoriteCarsLocal)
  const [vehicleType, setVehicleType] = useState([])
  const [marketPrice, setMarketPrice] = useState([])
  const isMoreFilterRef = useRef(true)
  const [shouldCallFilter, setShouldCallFilter] = useState()
  const [color, setColor] = useState([])
  const [reloadFilters, setReloadFilters] = useState(false)
  const [reloadFiltersMake, setReloadFiltersMake] = useState(false)
  const [reloadFiltersModel, setReloadFiltersModel] = useState(false)

  const { search } = useLocation()
  const query = new URLSearchParams(search).get('query') || ''
  const [similarDeal, setSimilarDeal] = useState([])

  const removeMake = (e, item) => {
    e.preventDefault()
    const tags = make.filter((tag) => tag !== item.item)
    setMake(tags)
    setReloadFilters(true)
  }

  const removeModel = (e, item) => {
    e.preventDefault()
    const tags = model.filter((tag) => tag !== item.item)
    setModel(tags)
    setReloadFilters(true)
  }

  const removeTrim = (e, item) => {
    e.preventDefault()
    const tags = trim.filter((tag) => tag !== item.item)
    setTrim(tags)
    setReloadFilters(true)
  }

  const removeTransmission = (e, item) => {
    e.preventDefault()
    const tags = transmission.filter((tag) => tag !== item.item)
    setTransmission(tags)
    setReloadFilters(true)
  }

  const removeZipCode = (e) => {
    e.preventDefault()
    setZipCode('')
    setZipCodeDefault(false)
  }

  const removePrice = (e) => {
    e.preventDefault()
    setPrice(INITIAL_PRICE)
    setPriceDefault(false)
    setReloadFilters(true)
  }

  const removeYear = (e) => {
    e.preventDefault()
    setYear(initYear)
    setYearDefault(false)
    setReloadFilters(true)
  }

  const removeMileage = (e) => {
    e.preventDefault()
    setMileage(initMileage)
    setMileageDefault(false)
    setReloadFilters(true)
  }

  const removeColor = (e, item) => {
    e.preventDefault()
    const tags = color.filter((tag) => tag !== item.item)
    setColor(tags)
    setReloadFilters(true)
  }

  const removeVehicleType = (e, item) => {
    e.preventDefault()
    const tags = vehicleType.filter((tag) => tag !== item.item)
    setVehicleType(tags)
    setReloadFilters(true)
  }

  const removeSimilarDeal = (e, item) => {
    e.preventDefault()
    const tags = similarDeal.filter((tag) => tag !== item.item)
    setSimilarDeal(tags)
    setReloadFilters(true)
  }

  const handleSortChange = useCallback(
    (value, type, page, rowsPerPage) => {
      isMoreFilterRef.current = false
      setShowOnlyFavoriteVehicles(false)
      setLoading(true)
      setSortKey(value)
      setSortType(type)
      const filterBody = getFilterBody({
        zipCode,
        certified,
        price,
        year,
        mileage,
        make,
        model,
        trim,
        transmission,
        color,
        vehicleType,
        similarDeal,
        certifiedVehicles: certified,
        sortKey: value !== 'all' ? value : '',
        sortType: value !== 'all' ? type : '',
        keyword,
        dealerId
      })
      DealerService.getDealerByFeature({ rowsPerPage, page, dealerId, ...filterBody })
        .then((response) => {
          setBuyCars(response.data.data)
          setTotal(response.data.total)
        })
        .finally(() => {
          setLoading(false)
          initialRef.current = false
        })
        .catch((e) => {
          setBuyCars([])
          setPage(1)
          setTotal(0)
          console.log(e)
        })
    },
    [
      zipCode,
      certified,
      JSON.stringify(price),
      JSON.stringify(year),
      JSON.stringify(mileage),
      JSON.stringify(make),
      JSON.stringify(model),
      JSON.stringify(trim),
      JSON.stringify(transmission),
      JSON.stringify(color),
      JSON.stringify(vehicleType),
      sortKey,
      sortType,
      keyword,
      dealerId,
      JSON.stringify(similarDeal),
      getFilterBody
    ]
  )

  useEffect(() => {
    if (dealerId && initialRef.current) {
      DealerService.getSearchSorting({ page, rowsPerPage, sortBy: 'all', orderBy: 'asc', dealerId })
        .then((response) => {
          setBuyCars(response.data.data)
          setTotal(response.data.total)
        })
        .catch((e) => {
          setBuyCars([])
          setPage(1)
          setTotal(0)
          console.log(e)
        })
        .finally(() => {
          setLoading(false)
          initialRef.current = false
        })
    }
  }, [page, dealerId])

  const handleGetDealerByFeature = useCallback(
    ({ page, rowsPerPage }) => {
      setLoading(true)
      const filterBody = getFilterBody({
        zipCode,
        price,
        year,
        mileage,
        make,
        model,
        trim,
        transmission,
        color,
        vehicleType,
        certifiedVehicles: certified
      })
      if (Object.keys(filterBody).length > 0) {
        DealerService.getDealerByFeature({ rowsPerPage, page, dealerId, ...filterBody })
          .then((response) => {
            setBuyCars(response.data.data)
            setTotal(response.data.total)
          })
          .catch((e) => {
            setBuyCars([])
            setPage(1)
            setTotal(0)
            console.log(e)
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        isMoreFilterRef.current = false
        handleSortChange(sortKey, sortType, page, rowsPerPage)
      }
    },
    [
      zipCode,
      JSON.stringify(price),
      JSON.stringify(year),
      JSON.stringify(mileage),
      JSON.stringify(make),
      JSON.stringify(model),
      JSON.stringify(trim),
      JSON.stringify(transmission),
      JSON.stringify(color),
      JSON.stringify(vehicleType),
      sortKey,
      sortType,
      JSON.stringify(transmission),
      getFilterBody,
      handleSortChange
    ]
  )

  useEffect(() => {
    if (!initialRef.current && isMoreFilterRef.current) {
      handleGetDealerByFeature({ page, rowsPerPage })
    }
  }, [rowsPerPage, dealerId, page, shouldCallFilter, handleGetDealerByFeature])

  useEffect(() => {
    if (!isMoreFilterRef.current) {
      isMoreFilterRef.current = true
      const filterBody = getFilterBody({ zipCode, price, year, mileage, make, model, trim, transmission, color })
      if (Object.keys(filterBody).length > 0) {
        setShouldCallFilter(Math.random())
      }
    }
  }, [zipCode, price, year, mileage, make, model, trim, transmission, vehicleType, marketPrice, color, vehicleType])

  const handleChangePage = (page, pageSize) => {
    setPage(page)
    if (!isMoreFilterRef.current) {
      handleSortChange(sortKey, sortType, page, pageSize)
    }
  }

  const handleChangePageSize = (page, pageSize) => {
    setRowsPerPage(pageSize)
  }

  const goToFirstPage = () => {
    if (page !== 1) setPage(1)
  }

  const goToLastPage = () => {
    if (page !== total) setPage(Math.ceil((buyCars?.length > 0 ? total : 0) / rowsPerPage))
  }

  const setZipCodeRange = (value) => {
    setZipCode(value)
    setZipCodeDefault(true)
  }

  const setPriceRange = (values) => {
    setPrice(values)
    if (values.min !== INITIAL_PRICE.min || values.max !== INITIAL_PRICE.max) {
      setPriceDefault(true)
    } else {
      setPriceDefault(false)
    }
  }

  const setYearRange = (values) => {
    setYear(values)
    if (values.min !== initYear.min || values.max !== initYear.max) {
      setYearDefault(true)
    } else {
      setYearDefault(false)
    }
  }

  const setMileageRange = (values) => {
    setMileage(values)
    if (values.min !== initMileage.min || values.max !== initMileage.max) {
      setMileageDefault(true)
    } else {
      setMileageDefault(false)
    }
  }

  const clearFilter = () => {
    setPrice(INITIAL_PRICE)
    setMake([])
    setModel([])
    setYear(initYear)
    setMileage(initMileage)
    setTrim([])
    setTransmission([])
    setZipCodeDefault(false)
    setPriceDefault(false)
    setYearDefault(false)
    setMileageDefault(false)
    setShowOnlyFavoriteVehicles(false)
    setVehicleType([])
    setMarketPrice([])
    setColor([])
    setReloadFilters(true)
    setReloadFiltersMake(true)
    setReloadFiltersModel(true)
    setSimilarDeal([])
  }

  const mobileFilterBar = (
    <FilterBuyCar
      loading={loading}
      zipCode={zipCode}
      setZipCode={setZipCodeRange}
      certified={certified}
      setCertified={setCertified}
      price={price}
      setPrice={setPriceRange}
      year={year}
      setYear={setYearRange}
      mileage={mileage}
      setMileage={setMileageRange}
      make={make}
      setMake={setMake}
      model={model}
      setModel={setModel}
      trim={trim}
      setTrim={setTrim}
      transmission={transmission}
      setTransmission={setTransmission}
      clearFilter={clearFilter}
      className="sm:hidden"
      isInitial={initialRef.current}
      showOnlyFavoriteVehicles={showOnlyFavoriteVehicles}
      setShowOnlyFavoriteVehicles={setShowOnlyFavoriteVehicles}
      favouriteCars={favouriteCars}
      oldBuyCarRef={oldBuyCarRef}
      buyCars={buyCars}
      setBuyCars={setBuyCars}
      vehicleType={vehicleType}
      setVehicleType={setVehicleType}
      marketPrice={marketPrice}
      setMarketPrice={setMarketPrice}
      color={color}
      setColor={setColor}
      dealerId={dealerId}
      keyword={keyword}
      setKeyword={setKeyword}
      reloadFilters={reloadFilters}
      setReloadFilters={setReloadFilters}
      reloadFiltersMake={reloadFiltersMake}
      setReloadFiltersMake={setReloadFiltersMake}
      reloadFiltersModel={reloadFiltersModel}
      setReloadFiltersModel={setReloadFiltersModel}
      similarDeal={similarDeal}
      setSimilarDeal={setSimilarDeal}
      query={query}
    />
  )

  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6 mb-8">
        <FilterBuyCar
          loading={loading}
          zipCode={zipCode}
          setZipCode={setZipCodeRange}
          certified={certified}
          setCertified={setCertified}
          price={price}
          setPrice={setPriceRange}
          year={year}
          setYear={setYearRange}
          mileage={mileage}
          setMileage={setMileageRange}
          make={make}
          setMake={setMake}
          model={model}
          setModel={setModel}
          trim={trim}
          setTrim={setTrim}
          transmission={transmission}
          setTransmission={setTransmission}
          clearFilter={clearFilter}
          className="hidden sm:block"
          isInitial={initialRef.current}
          showOnlyFavoriteVehicles={showOnlyFavoriteVehicles}
          setShowOnlyFavoriteVehicles={setShowOnlyFavoriteVehicles}
          favouriteCars={favouriteCars}
          oldBuyCarRef={oldBuyCarRef}
          buyCars={buyCars}
          setBuyCars={setBuyCars}
          vehicleType={vehicleType}
          setVehicleType={setVehicleType}
          marketPrice={marketPrice}
          setMarketPrice={setMarketPrice}
          color={color}
          setColor={setColor}
          dealerId={dealerId}
          keyword={keyword}
          setKeyword={setKeyword}
          reloadFilters={reloadFilters}
          setReloadFilters={setReloadFilters}
          reloadFiltersMake={reloadFiltersMake}
          setReloadFiltersMake={setReloadFiltersMake}
          reloadFiltersModel={reloadFiltersModel}
          setReloadFiltersModel={setReloadFiltersModel}
          similarDeal={similarDeal}
          setSimilarDeal={setSimilarDeal}
          query={query}
        />
        <div className="md:col-span-2 lg:col-span-4">
          <div className="flex bg-gray-100 px-2 py-2 sm:p-0 items-center mb-5 rounded relative z-30">
            <Popover
              placement="bottomLeft"
              content={mobileFilterBar}
              trigger="click"
              style={{ width: 400 }}
              openClassName="mobile-filter-car"
            >
              <svg
                viewBox="0 0 24 24"
                className="h-8 w-auto cursor-pointer sm:hidden mr-3"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path color="#444445" d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
              </svg>
            </Popover>
          </div>
          <div className="flex flex-wrap mb-5">
            {zipCodeDefault && (
              <Tag closable onClose={removeZipCode}>
                {`ZIP CODE: ${zipCode}`}
              </Tag>
            )}
            {priceDefault && (
              <Tag closable onClose={removePrice}>
                {`PRICE: $${price.min.toLocaleString('en-US')} TO $${price.max.toLocaleString('en-US')}`}
              </Tag>
            )}
            {make.map((item, index) => (
              <Tag closable key={`make-tag-${index}`} onClose={(e) => removeMake(e, { item })}>
                {item}
              </Tag>
            ))}
            {model.map((item, index) => (
              <Tag closable key={`model-tag-${index}`} onClose={(e) => removeModel(e, { item })}>
                {item}
              </Tag>
            ))}
            {yearDefault && (
              <Tag closable onClose={removeYear}>
                {`YEAR: ${year.min} TO ${year.max}`}
              </Tag>
            )}
            {mileageDefault && (
              <Tag closable onClose={removeMileage}>
                {`MILEAGE: ${mileage.min} TO ${mileage.max}`}
              </Tag>
            )}
            {trim.map((item, index) => (
              <Tag closable key={`trim-tag-${index}`} onClose={(e) => removeTrim(e, { item })}>
                {item}
              </Tag>
            ))}
            {transmission.map((item, index) => (
              <Tag closable key={`transmission-tag-${index}`} onClose={(e) => removeTransmission(e, { item })}>
                {item}
              </Tag>
            ))}
            {color.map((item, index) => (
              <Tag closable key={`color-tag-${index}`} onClose={(e) => removeColor(e, { item })}>
                {item}
              </Tag>
            ))}
            {vehicleType.map((item, index) => (
              <Tag closable key={`vehicle-type-tag-${index}`} onClose={(e) => removeVehicleType(e, { item })}>
                {item}
              </Tag>
            ))}
            {similarDeal.map((item, index) => (
              <Tag closable key={`similar-deal-tag-${index}`} onClose={(e) => removeSimilarDeal(e, { item })}>
                {item}
              </Tag>
            ))}
          </div>
          <div className="flex justify-between flex-col lg:flex-row gap-3 dealer-filter-container">
            <Button onClick={clearFilter} className="rounded" danger>
              Clear search
            </Button>
            <div className="flex justify-center sm:justify-end items-center mb-5">
              <p className="mr-3" style={{ minWidth: 'max-content' }}>
                Sort by:
              </p>
              <div className="flex w-80 md:w-96 bg-gray-300 rounded-2xl">
                <div
                  className={`flex-1 flex justify-center cursor-pointer py-1 ${
                    sortKey === 'all' ? 'bg-white border border-primary rounded-2xl' : ''
                  }`}
                  onClick={() => handleSortChange('all', 'asc', page, rowsPerPage)}
                  role="presentation"
                >
                  All
                </div>
                <div
                  className={`flex-1 flex justify-center items-center cursor-pointer py-1 ${
                    sortKey === 'year' ? 'bg-white border border-primary rounded-2xl' : ''
                  }`}
                  onClick={
                    sortKey === 'year' && sortType !== 'asc'
                      ? () => handleSortChange('year', 'asc', page, rowsPerPage)
                      : sortKey === 'year' && sortType === 'asc'
                      ? () => handleSortChange('year', 'desc', page, rowsPerPage)
                      : () => handleSortChange('year', 'asc', page, rowsPerPage)
                  }
                  role="presentation"
                >
                  <div className="mr-2 md:mr-4">Year</div>
                  {sortKey === 'year' && sortType !== 'asc' ? (
                    <FaChevronDown />
                  ) : sortKey === 'year' && sortType === 'asc' ? (
                    <FaChevronUp />
                  ) : (
                    <FaChevronUp />
                  )}
                </div>
                <div
                  className={`flex-1 flex justify-center items-center cursor-pointer py-1 ${
                    sortKey === 'brand' ? 'bg-white border border-primary rounded-2xl' : ''
                  }`}
                  onClick={
                    sortKey === 'brand' && sortType !== 'asc'
                      ? () => handleSortChange('brand', 'asc', page, rowsPerPage)
                      : sortKey === 'brand' && sortType === 'asc'
                      ? () => handleSortChange('brand', 'desc', page, rowsPerPage)
                      : () => handleSortChange('brand', 'asc', page, rowsPerPage)
                  }
                  role="presentation"
                >
                  <div className="mr-2 md:mr-4">Brand</div>
                  {sortKey === 'brand' && sortType !== 'asc' ? (
                    <FaChevronDown />
                  ) : sortKey === 'brand' && sortType === 'asc' ? (
                    <FaChevronUp />
                  ) : (
                    <FaChevronUp />
                  )}
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <div className="loader mx-auto my-44" />
          ) : (
            <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-6 mb-12">
              {buyCars?.map((car) => (
                <VehicleCard
                  car={car}
                  key={car._id}
                  favouriteCars={favouriteCars}
                  setFavouriteCars={setFavouriteCars}
                />
              ))}
            </div>
          )}
          <div className="flex justify-center sm:justify-end mb-8">
            <div className="flex items-center">
              <button
                type="button"
                className={`custom-pagination custom-first ${page === 1 ? 'disabled' : ''}`}
                onClick={goToFirstPage}
              >
                <DoubleLeftOutlined />
              </button>
              <Pagination
                className="flex"
                current={page}
                responsive
                total={buyCars?.length > 0 ? total : 0}
                pageSize={rowsPerPage}
                onChange={handleChangePage}
                onShowSizeChange={handleChangePageSize}
                pageSizeOptions={[10, 20, 50, 100]}
              />
              <button
                type="button"
                className={`custom-pagination custom-last ${
                  page === Math.ceil((buyCars?.length > 0 ? total : 0) / rowsPerPage) ? 'disabled' : ''
                }`}
                onClick={goToLastPage}
              >
                <DoubleRightOutlined />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

DealershipInventory.propTypes = {
  dealerId: PropTypes.number.isRequired
}

export default DealershipInventory
