import { Button, Checkbox, DatePicker, Form, Input, Modal, notification, Radio, Spin } from 'antd'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from '@reach/combobox'
import '@reach/combobox/styles.css'
import { BiChevronLeft } from 'react-icons/bi'
import Select from 'react-select'
import PhoneNumberInput from 'src/components/InputComponent/PhoneNumberInput'
import { STATES, VALID_EMAIL } from 'src/constants/constants'
import './style.scss'
import Cards from 'react-credit-cards-2'
import { LoadingOutlined } from '@ant-design/icons'
import { IoMdShareAlt } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import moment from 'moment/moment'
import LogoMMUSA from '../../../assets/new-login-logo.png'
import LogoNiada from '../../../assets/logo_niada.png'
import LogoNiadaAlabama from '../../../assets/logo_niada_alabama.png'
import LogoNiadaAlaska from '../../../assets/logo_niada_alaska.png'
import LogoNiadaArizona from '../../../assets/logo_niada_arizona.png'
import LogoNiadaArkansas from '../../../assets/logo_niada_arkansas.png'
import LogoNiadaCalifornia from '../../../assets/logo_niada_california.png'
import LogoNiadaColorado from '../../../assets/logo_niada_colorado.png'
import LogoNiadaCarolinas from '../../../assets/logo_niada_carolinas.png'
import LogoNiadaFlorida from '../../../assets/logo_niada_florida.png'
import LogoNiadaGeorgia from '../../../assets/logo_niada_georgia.png'
import LogoNiadaHawaii from '../../../assets/logo_niada_hawaii.png'
import LogoNiadaIdaho from '../../../assets/logo_niada_idaho.png'
import LogoNiadaIllinois from '../../../assets/logo_niada_illinois.png'
import LogoNiadaIndiana from '../../../assets/logo_niada_indiana.png'
import LogoNiadaIowa from '../../../assets/logo_niada_iowa.png'
import LogoNiadaKansas from '../../../assets/logo_niada_kansas.png'
import LogoNiadaMassachusetts from '../../../assets/logo_niada_massachusetts.png'
import LogoNiadaMichigan from '../../../assets/logo_niada_michigan.png'
import LogoNiadaMidatlantic from '../../../assets/logo_niada_midatlantic.png'
import LogoNiadaMississippi from '../../../assets/logo_niada_mississippi.png'
import LogoNiadaMissouri from '../../../assets/logo_niada_missouri.png'
import LogoNiadaMontana from '../../../assets/logo_niada_montana.png'
import LogoNiadaNebraska from '../../../assets/logo_niada_nebraska.png'
import LogoNiadaNevada from '../../../assets/logo_niada_nevada.png'
import LogoNiadaNewHampshire from '../../../assets/logo_niada_new_hampshire.png'
import LogoNiadaNewJersey from '../../../assets/logo_niada_new_jersey.png'
import LogoNiadaNewYork from '../../../assets/logo_niada_new_york.png'
import LogoNiadaNorthland from '../../../assets/logo_niada_northland.png'
import LogoNiadaOhio from '../../../assets/logo_niada_ohio.png'
import LogoNiadaOregon from '../../../assets/logo_niada_oregon.png'
import LogoNiadaSouthDakota from '../../../assets/logo_niada_south_dakota.png'
import LogoNiadaTennessee from '../../../assets/logo_niada_tennessee.png'
import LogoNiadaTexas from '../../../assets/logo_niada_texas.png'
import LogoNiadaUtah from '../../../assets/logo_niada_utah.png'
import LogoNiadaVirginia from '../../../assets/logo_niada_virginia.png'
import LogoNiadaWashington from '../../../assets/logo_niada_washington.png'
import LogoNiadaWisconsin from '../../../assets/logo_niada_wisconsin.png'
import LogoNiadaWyoming from '../../../assets/logo_niada_wyoming.png'
import * as DealerService from '../../../services/dealer.service'
import BankRoutingNumberPopup from '../../../components/Vehicle/BoxButtonGroup/BankRoutingNumberPopup'
import * as ClaimServices from '../../../services/claim.service'
import { getPublicIp } from '../../../utils'
import * as AuthServices from '../../../services/auth.service'

const achDisclaimer =
  'I understand that this authorization will remain in effect until I cancel it in writing, and I agree to notify MOTOix (creators of MotorMarketUSA) in writing of any changes in my account information or termination of this authorization at least 15 days prior to the next billing date. If the above noted payment dates fall on a weekend or holiday, I understand that the payments may be executed on the next business day. For ACH debits to my checking/savings account, I understand that because these are electronic transactions, these funds may be withdrawn from my account as soon as the above noted periodic transactions dates. In this case of an ACH Transaction being rejected for Non-Sufficient Funds (NSF) I understand that MOTOix (creators of MotorMarketUSA) may at its discretion attempt to process the charge again within 30 days and agree to an additional $25.00 charge for each attempt returned NSF which will be initiated as a separate transaction from the authorized recurring payment. I acknowledge that the origination of ACH transactions to my account must comply with the provisions of U.S. law. I certify that I am an authorized user of this bank account and will not dispute these scheduled transactions with my bank; so long as the transactions correspond to the terms indicated in this authorization form.'

const creditCardDisclaimer = (
  <div>
    <div>
      You authorize regularly scheduled charges to your Credit Card account. You will be charged the amount indicated
      below each billing period. A separate convenience fee of 3% will be added to your invoice amount when you choose
      to pay via a credit card instead of the primary payment method. A receipt for each payment will be provided to you
      and the charge will appear on your credit card statement. You agree that no prior notification will be provided
      unless the date or amount changes, in which case you will receive notice from us at least 10-days prior to the
      payment being collected.
    </div>
    <br />
    <div>
      I understand that this authorization will remain in effect until I cancel it in writing, and I agree to notify
      MOTOix (creators of MotorMarketUSA) in writing of any changes in my account information or termination of this
      authorization at least 15 days prior to the next billing date. If the above noted payment dates fall on a weekend
      or holiday, I understand that the payments may be executed on the next business day. I certify that I am an
      authorized user of this credit card account and will not dispute these scheduled transactions; so long as the
      transactions correspond to the terms indicated in this authorization form.
    </div>
  </div>
)

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

function LoadingIcon() {
  return <Spin indicator={antIcon} />
}

function VerifyYourAccountOwnership({
  nextStep,
  selectedDealership,
  backStep,
  dealershipDetail,
  setDealershipDetail,
  setSignUpData,
  setDealerId,
  getClaimBusinessPayloadMailAdapter,
  getClaimBusinessPayloadAdapter,
  setPaymentInfo
}) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_PLATFORM_KEY ?? '',
    libraries: ['places']
  })

  const navigate = useNavigate()

  const selectedPrice = 'month to Month'
  const totalMonthly = 99

  const memberNumber = dealershipDetail.memberNumber ?? ''

  // New York is default
  const dealershipLat = dealershipDetail.lat ?? '-73.935242'
  const dealershipLng = dealershipDetail.lng ?? '40.730610'

  const googleMapsCenter = useMemo(() => ({ lat: dealershipLat, lng: dealershipLng }), [])
  const [googleMapsCenterSelected, setGoogleMapsCenterSelected] = useState(googleMapsCenter)
  const [memberPrice, setMemberPrice] = useState(null)

  const [form] = Form.useForm()
  const [emailForm] = Form.useForm()
  const { dealerName } = selectedDealership

  const [emailErrorMessage, setEmailErrorMessage] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('ACH')
  const [bankResolving, setBankResolving] = useState(false)
  const [bankRoutingNumber, setBankRoutingNumber] = useState('')
  const [showBankRoutingNumberPopup, setShowBankRoutingNumberPopup] = useState(false)
  const [cardNumber, setSetCardNumber] = useState('')
  const [cardHolder, setCardHolder] = useState('')
  const [expiration, setExpiration] = useState('')
  const [cvv, setCvv] = useState('')
  const [cardFocus, setCardFocus] = useState('')
  const [checkedBillMyDealership, setCheckedBillMyDealership] = useState(false)
  const [billMyDealershipEmail, setBillMyDealershipEmail] = useState('')
  const [billMyDealershipPhone, setBillMyDealershipPhone] = useState('')
  const [billMyDealershipContactName, setBillMyDealershipContactName] = useState('')

  const handleCheckboxBillMyDealership = () => {
    setCheckedBillMyDealership(!checkedBillMyDealership)
  }

  const handleBillMyDealershipEmail = (e) => {
    setBillMyDealershipEmail(e.target.value)
  }

  const handleBillMyDealershipPhone = (e) => {
    setBillMyDealershipPhone(e.target.value)
  }

  const handleBillMyDealershipContactName = (e) => {
    setBillMyDealershipContactName(e.target.value)
  }

  const [modal, setModal] = useState(false)

  const [isEnabledEditAddress, setIsEnabledEditAddress] = useState(true)

  const openNotification = ({ message, description, type = 'success' }) => {
    const action = type === 'success' ? notification.success : notification.error
    action({
      message,
      description
    })
  }

  useEffect(() => {
    if (memberNumber !== undefined || memberNumber !== '') {
      DealerService.memberPrice(memberNumber).then((response) => {
        setMemberPrice(response.data)
      })
    }
  }, [memberNumber])

  useEffect(() => {
    if (bankRoutingNumber === '') {
      form.setFieldsValue({ bankRoutingNumber: '' })
    }
  }, [bankRoutingNumber])

  const dealershipFormFields = [
    {
      name: 'firstName',
      rules: [
        { required: true, message: 'First name is required' },
        { max: 32, message: 'Max allowed characters is 32' },
        { pattern: /^[A-Za-z]([A-Za-z ])*$/g, message: 'First name can only consist of letters' }
      ],
      label: 'Your first name',
      placeholder: 'Add first name',
      className: 'md:col-span-6 col-span-12'
    },
    {
      name: 'lastName',
      rules: [
        { required: true, message: 'Last name is required' },
        { max: 32, message: 'Max allowed characters is 32' },
        { pattern: /^[A-Za-z]([A-Za-z ])*$/g, message: 'Last name can only consist of letters' }
      ],
      label: 'Your last name',
      placeholder: 'Add last name',
      className: 'md:col-span-6 col-span-12'
    },
    {
      name: 'email',
      rules: [
        { required: true, message: 'Email is required' },
        {
          pattern: VALID_EMAIL,
          message: 'Email is invalid'
        }
      ],
      label: 'Email Address',
      placeholder: 'Add email',
      className: 'md:col-span-6 md:col-start-1 col-span-12'
    },
    {
      name: 'phone',
      rules: [{ pattern: /(___-___-____)|([0-9,-,\\),\\(,\s])/g, message: 'Phone number is invalid' }],
      label: 'Dealership Phone',
      placeholder: 'Add mobile phone',
      className: 'md:col-span-6 col-span-12',
      type: 'phone'
    },
    {
      name: 'mobile',
      rules: [
        { required: true, message: 'Mobile number is required' },
        { pattern: /(___-___-____)|([0-9,-,\\),\\(,\s])/g, message: 'Phone number is invalid' }
      ],
      label: 'Mobile Phone',
      placeholder: 'Add mobile phone',
      className: 'md:col-span-6 md:col-start-1 col-span-12',
      type: 'phone'
    }
  ]

  const onEditAddress = async () => {
    if (isEnabledEditAddress) {
      form
        .validateFields(['physicalAddress', 'city', 'state', 'zip'])
        .then(() => {
          setIsEnabledEditAddress(false)
        })
        .catch(() => {})
    } else {
      setIsEnabledEditAddress(true)
    }
  }

  const handleChangePaymentMethod = (e) => {
    setPaymentMethod(e.target.value)
  }

  const onChangeResolvingBank = async (value) => {
    setBankRoutingNumber(value)
    if (value.length > 8 && value.length < 10) {
      setBankResolving(true)
      setShowBankRoutingNumberPopup(false)
      try {
        const bankName = await DealerService.bankRoutingNumberResolve(value)
        if (bankName) form.setFieldsValue({ bankName })
      } catch (e) {
        setShowBankRoutingNumberPopup(true)
        setBankResolving(false)
        console.error(e)
      } finally {
        setBankResolving(false)
      }
    }
  }

  const onFormChange = (values) => {
    if (values.target.value === '___-___-____' && values.target.name === 'phone') {
      form.setFieldsValue({ ...form.getFieldsValue(), phone: '' })
    } else if (values.target.name === 'hasEmailAddress') {
      setEmailErrorMessage('')
      form.setFieldsValue({ ...form.getFieldsValue(), [values.target.name]: values.target.checked })
    } else if (values.target.name === 'email') {
      setEmailErrorMessage('')
      form.setFieldsValue({ ...form.getFieldsValue(), [values.target.name]: values.target.value })
    } else {
      form.setFieldsValue({ ...form.getFieldsValue(), [values.target.name]: values.target.value })
    }
  }

  const fieldRenderer = useCallback((field) => {
    switch (field.type) {
      case 'select':
        return (
          <Select
            components={{
              IndicatorSeparator: () => null
            }}
            placeholder={field.placeholder}
            className="w-full"
            onChange={field.onSelectField}
            options={field.options.filter((item) => !item.hidden)}
            styles={{
              control: (provided) => ({
                ...provided,
                width: '100%',
                borderRadius: '10px',
                height: '50px'
              }),
              placeholder: (provided) => ({
                ...provided,
                fontStyle: 'italic'
              }),
              menu: (provided) => ({
                ...provided
              })
            }}
          />
        )
      case 'phone':
        return (
          <PhoneNumberInput
            placeholder={field.placeholder}
            className="w-full p-3 box-border rounded-lg border-gray-300 border focus:outline-none"
            value={form.getFieldValue(field.name)}
          />
        )
      default:
        return (
          <Input
            name={field.name}
            placeholder={field.placeholder}
            className="w-full p-3 box-border rounded-lg border-gray-300 border focus:outline-none"
            type={field.type || 'text'}
          />
        )
    }
  }, [])

  const onSubmitForm = async () => {
    await form
      .validateFields()
      .then(async () => {
        const values = form.getFieldsValue()
        values.role = 'Dealer'

        try {
          const paymentDetails = getClaimBusinessPayloadMailAdapter({
            paymentDetails: values,
            selectedPrice,
            totalMonthly
          })

          setDealershipDetail({ ...dealershipDetail, ...values })
          setPaymentInfo({ ...paymentDetails, ...values })

          const businessDetails = getClaimBusinessPayloadAdapter()
          const payload = { ...paymentDetails, ...businessDetails }
          payload.ip = await getPublicIp()
          payload.role = 'Dealer'
          payload.term = selectedPrice
          payload.price = totalMonthly
          payload.price_amount = totalMonthly
          payload.contacts.address = values.physicalAddress
          payload.contacts.city = values.city
          payload.contacts.state = values.state.value || values.state
          payload.contacts.zip = values.zip
          payload.contacts.phone = values.phone
          payload.contacts.email = values.email
          payload.contacts.mobile = values.mobile
          payload.billMyDealershipEmail = values.bill_my_dealership_email
          payload.billMyDealershipPhone = values.bill_my_dealership_phone
          payload.billMyDealershipContactName = values.bill_my_dealership_contact_name
          payload.average_vehicles_stock = '31-100'
          payload.crm = ''
          payload.dealership_get_leads_email = values.email
          payload.inventory_contact_name = `${values.firstName} ${values.lastName}`
          payload.inventory_email = values.email
          payload.inventory_phone = values.phone
          payload.inventory = 'ftp'
          payload.bank_name = values?.bankName
          payload.account_nr = values?.bankAccountNumber
          payload.routing_nr = values?.bankRoutingNumber
          payload.is_not_dealership_email = true
          payload.cc_nr = values?.cardNumber
          payload.cc_nh = values?.cardHolder
          payload.cc_exp = values?.expiration
          payload.cc_cvv = values?.cvv
          payload.cc_zip = values?.billingZipCode
          payload.is_niada_member = true
          payload.is_niada_claim_process = true

          await AuthServices.dealershipSignup(payload).then((response) => {
            setSignUpData(response.data)
            setDealerId(response.data.dealer_id)
            openNotification({
              message: 'Dealership sign up successful',
              description: 'Click link in email to verify your business'
            })
            navigate(`/dealership/${response.data.dealer_id}`)
          })
        } catch (error) {
          openNotification({ message: error.message, type: 'error' })
          console.error(error)
        }
      })
      .catch(() => {})
  }

  useEffect(() => {
    if (dealershipDetail && Object.keys(dealershipDetail).length > 0) {
      form.setFieldsValue(dealershipDetail)
      form.setFieldsValue({ zip: String(dealershipDetail.zip) })
      const stateValue = STATES.find((e) => e.name === dealershipDetail.state)
      if (stateValue) form.setFieldsValue({ state: { label: stateValue.name, value: stateValue.abbreviation } })
    }
  }, [dealershipDetail])

  const handleSendMail = (type, email) => {
    const values = form.getFieldsValue()
    const payload = getClaimBusinessPayloadMailAdapter({ paymentDetails: values, selectedPrice, totalMonthly })
    payload.first_name = values.firstName
    payload.last_name = values.lastName
    return ClaimServices.employeeSendMailAgreement({ ...payload, email }, type)
  }

  const getNiadaImage = (marker) => {
    let image = LogoNiada
    if (marker === 'logo_niada_alabama') {
      image = LogoNiadaAlabama
    }
    if (marker === 'logo_niada_alaska') {
      image = LogoNiadaAlaska
    }
    if (marker === 'logo_niada_arizona') {
      image = LogoNiadaArizona
    }
    if (marker === 'logo_niada_arkansas') {
      image = LogoNiadaArkansas
    }
    if (marker === 'logo_niada_california') {
      image = LogoNiadaCalifornia
    }
    if (marker === 'logo_niada_colorado') {
      image = LogoNiadaColorado
    }
    if (marker === 'logo_niada_carolinas') {
      image = LogoNiadaCarolinas
    }
    if (marker === 'logo_niada_florida') {
      image = LogoNiadaFlorida
    }
    if (marker === 'logo_niada_georgia') {
      image = LogoNiadaGeorgia
    }
    if (marker === 'logo_niada_hawaii') {
      image = LogoNiadaHawaii
    }
    if (marker === 'logo_niada_idaho') {
      image = LogoNiadaIdaho
    }
    if (marker === 'logo_niada_illinois') {
      image = LogoNiadaIllinois
    }
    if (marker === 'logo_niada_indiana') {
      image = LogoNiadaIndiana
    }
    if (marker === 'logo_niada_kansas') {
      image = LogoNiadaKansas
    }
    if (marker === 'logo_niada_iowa') {
      image = LogoNiadaIowa
    }
    if (marker === 'logo_niada_massachusetts') {
      image = LogoNiadaMassachusetts
    }
    if (marker === 'logo_niada_michigan') {
      image = LogoNiadaMichigan
    }
    if (marker === 'logo_niada_midatlantic') {
      image = LogoNiadaMidatlantic
    }
    if (marker === 'logo_niada_mississippi') {
      image = LogoNiadaMississippi
    }
    if (marker === 'logo_niada_missouri') {
      image = LogoNiadaMissouri
    }
    if (marker === 'logo_niada_montana') {
      image = LogoNiadaMontana
    }
    if (marker === 'logo_niada_nebraska') {
      image = LogoNiadaNebraska
    }
    if (marker === 'logo_niada_nevada') {
      image = LogoNiadaNevada
    }
    if (marker === 'logo_niada_new_hampshire') {
      image = LogoNiadaNewHampshire
    }
    if (marker === 'logo_niada_new_jersey') {
      image = LogoNiadaNewJersey
    }
    if (marker === 'logo_niada_new_york') {
      image = LogoNiadaNewYork
    }
    if (marker === 'logo_niada_northland') {
      image = LogoNiadaNorthland
    }
    if (marker === 'logo_niada_ohio') {
      image = LogoNiadaOhio
    }
    if (marker === 'logo_niada_oregon') {
      image = LogoNiadaOregon
    }
    if (marker === 'logo_niada_south_dakota') {
      image = LogoNiadaSouthDakota
    }
    if (marker === 'logo_niada_tennessee') {
      image = LogoNiadaTennessee
    }
    if (marker === 'logo_niada_texas') {
      image = LogoNiadaTexas
    }
    if (marker === 'logo_niada_utah') {
      image = LogoNiadaUtah
    }
    if (marker === 'logo_niada_virginia') {
      image = LogoNiadaVirginia
    }
    if (marker === 'logo_niada_washington') {
      image = LogoNiadaWashington
    }
    if (marker === 'logo_niada_wisconsin') {
      image = LogoNiadaWisconsin
    }
    if (marker === 'logo_niada_wyoming') {
      image = LogoNiadaWyoming
    }

    return image
  }

  const handleSendEmail = async () => {
    await form
      .validateFields()
      .then(async () => {
        await emailForm.validateFields(['email']).then(() => {
          const emailValue = emailForm.getFieldValue(['email'])
          if (emailValue) {
            handleSendMail('disclaimer', emailValue)
              .then(() => {
                openNotification({ message: 'Send disclaimer successful' })
              })
              .catch((error) => {
                console.error(error)
                openNotification({ message: 'Send disclaimer failed', type: 'error' })
              })
          }
        })
      })
      .catch(() => {})
  }

  const handleSendEmailInvoiceMe = async () => {
    await form
      .validateFields()
      .then(async () => {
        await form
          .validateFields([
            'email',
            'bill_my_dealership_email',
            'bill_my_dealership_phone',
            'bill_my_dealership_contact_name'
          ])
          .then(() => {
            const email = form.getFieldValue(['email'])
            const emailValue = form.getFieldValue(['bill_my_dealership_email'])
            const phoneValue = form.getFieldValue(['bill_my_dealership_phone'])
            const contactNameValue = form.getFieldValue(['bill_my_dealership_contact_name'])
            if (email && emailValue && phoneValue && contactNameValue) {
              handleSendMail('invoiceme', email)
                .then(() => {
                  openNotification({ message: 'Send Invoice Me successful' })
                })
                .catch((error) => {
                  console.error(error)
                  openNotification({ message: 'Send Invoice Me failed', type: 'error' })
                })
            }
          })
      })
      .catch(() => {})
  }

  // eslint-disable-next-line react/prop-types,react/no-unstable-nested-components
  function PlacesAutocomplete({ setGoogleMapsCenterSelected }) {
    const {
      ready,
      value,
      setValue,
      suggestions: { status, data },
      clearSuggestions
    } = usePlacesAutocomplete()

    const handleSelect = async (address) => {
      setValue(address, false)
      clearSuggestions()

      const results = await getGeocode({ address })
      form.setFieldsValue({ physicalAddress: results[0].formatted_address })
      results[0].address_components.forEach((obj) => {
        if (obj.types[0] === 'postal_code') {
          form.setFieldsValue({ zip: obj.long_name })
        }
        if (obj.types[0] === 'locality') {
          form.setFieldsValue({ city: obj.long_name })
        }
        if (obj.types[0] === 'administrative_area_level_1') {
          const stateValue = STATES.find((e) => e.name === obj.long_name)
          if (stateValue)
            form.setFieldsValue({
              state: {
                label: stateValue.name,
                value: stateValue.abbreviation
              }
            })
        }
      })
      const { lat, lng } = await getLatLng(results[0])
      setGoogleMapsCenterSelected({ lat, lng })
    }

    return (
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          className={classNames({ 'address-input-border': isEnabledEditAddress }, 'text-base w-full')}
          placeholder="Search an address"
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === 'OK' &&
              // eslint-disable-next-line camelcase
              data.map(({ place_id, description }) => (
                // eslint-disable-next-line camelcase
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    )
  }

  return (
    <div className="grid grid-cols-4 mt-24 verify-your-account-container">
      <Form
        form={form}
        className="md:col-start-6 md:col-span-6 col-span-12"
        name="add-your-dealership-form-ref"
        onChange={onFormChange}
        layout="vertical"
      >
        <div className="dealer-info-container grid grid-cols-12 gap-x-5">
          {dealershipFormFields?.map((field, key) => {
            return (
              <div className={field.className} key={`dealer-info-form-${field.label}`}>
                {field.label && field.type !== 'checkbox' && (
                  <p className="pb-2 text-gray-500 text-base">{field.label}:</p>
                )}
                {field.type === 'checkbox' ? (
                  <div className="flex items-center mb-4">
                    <Form.Item
                      name={field.name}
                      className="flex flex-col mb-0 mt-1"
                      rules={field.rules}
                      validateTrigger="onChange"
                      key={`dealership-form-field-${key}`}
                    >
                      <Input
                        name={field.name}
                        placeholder={field.placeholder}
                        className="w-full p-3 box-border rounded-lg border-gray-300 border focus:outline-none mb-0"
                        type="checkbox"
                      />
                    </Form.Item>
                    <p className="ml-2">{field.label}</p>
                  </div>
                ) : (
                  <Form.Item
                    name={field.name}
                    className="flex flex-col"
                    rules={field.rules}
                    validateTrigger="onChange"
                    key={`dealership-form-field-${key}`}
                  >
                    {fieldRenderer(field)}
                  </Form.Item>
                )}
                {!!emailErrorMessage && field.name === 'email' && (
                  <p className="md:col-start-2 col-span-4 text-red-500">{emailErrorMessage}</p>
                )}
              </div>
            )
          })}
        </div>
        <div className="dealer-info-container grid grid-cols-12 gap-x-5 mt-3">
          <div className="md:col-span-6 col-span-12">
            <p className="mb-6 text-base">Dealership address</p>
            <div className="font-bold text-base mb-2">{dealerName}</div>

            <p className="pt-2 text-gray-500 text-base text-left">Dealer Address:</p>
            <Form.Item
              name="physicalAddress"
              rules={[
                { required: true, message: 'Address is required' },
                { max: 100, message: 'Max allowed characters is 100' }
              ]}
              validateTrigger="onChange"
            >
              <input
                className={classNames({ 'address-input-border': isEnabledEditAddress }, 'text-base w-full')}
                bordered={false}
                name="physicalAddress"
                disabled={!isEnabledEditAddress}
                required
              />
            </Form.Item>
            <p className="pt-2 text-gray-500 text-base text-left">Dealer City:</p>
            <Form.Item
              name="city"
              rules={[
                { required: true, message: 'City is required' },
                { max: 20, message: 'Max allowed characters is 20' },
                { pattern: /^[^0-9]+$/g, message: 'City can only consist of letters' }
              ]}
              validateTrigger="onChange"
            >
              <input
                bordered={false}
                className={classNames({ 'address-input-border': isEnabledEditAddress }, 'text-base w-full')}
                name="city"
                disabled={!isEnabledEditAddress}
                required
              />
            </Form.Item>
            <p className="pt-2 text-gray-500 text-base text-left">Dealer Zip:</p>
            <Form.Item
              name="zip"
              rules={[
                { required: isEnabledEditAddress, message: 'Zip is required' },
                { min: 4, max: 5 },
                { pattern: /[0-9]/g, message: 'Only number is accepted!' }
              ]}
              validateTrigger="onChange"
            >
              <input
                className={classNames({ 'address-input-border': isEnabledEditAddress }, 'text-base w-full')}
                bordered={false}
                name="zip"
                disabled={!isEnabledEditAddress}
              />
            </Form.Item>
            <p className="pt-2 text-gray-500 text-base text-left">Dealer State:</p>
            <Form.Item
              name="state"
              className="flex flex-col mr-2 address-input-border"
              rules={[{ required: true, message: 'State is required' }]}
              validateTrigger="onChange"
            >
              <Select
                options={STATES.map((e) => ({ value: e.abbreviation, label: e.name }))}
                name="state"
                required
                className="text-base w-full"
                isDisabled={!isEnabledEditAddress}
                components={{
                  IndicatorSeparator: () => null
                }}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                    border: 'none',
                    height: '10px',
                    padding: 0
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: 0
                  })
                }}
              />
            </Form.Item>
            {isLoaded && (
              <div className="text-center md:text-left mt-3 mb-3">
                <div className="places-container">
                  <PlacesAutocomplete setGoogleMapsCenterSelected={setGoogleMapsCenterSelected} />
                </div>
                <GoogleMap zoom={10} center={googleMapsCenterSelected} mapContainerClassName="google-map-container">
                  {googleMapsCenterSelected && <Marker position={googleMapsCenterSelected} />}
                </GoogleMap>
              </div>
            )}
          </div>
          <div className="md:col-span-6 col-span-12">
            {memberPrice && (
              <div>
                <div className="grid grid-cols-3 ">
                  <div className="grid grid-cols-1 gap-x-5">
                    <div className="flex items-center">
                      <img src={LogoMMUSA} alt="Logo MMUSA" />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-x-5 pl-7">
                    <div className="flex items-center text-gray-600">Retail Price:</div>
                  </div>
                  <div className="grid grid-cols-1 gap-x-5">
                    <div className="flex items-center font-semibold pl-7">{`$${memberPrice.retail_price}/mo.`}</div>
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-3">
                  <div className="grid grid-cols-1">
                    <div className="flex items-center">
                      <img src={LogoNiada} alt="Logo Niada" />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-x-5 pl-7">
                    <div className="flex items-center text-gray-600">Discount:</div>
                  </div>
                  <div className="grid grid-cols-1 gap-x-5">
                    <div className="flex items-center font-bold pl-7">{`$${memberPrice.discount_niada}/mo.`}</div>
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-3">
                  <div className="grid grid-cols-1">
                    <div className="flex items-center">
                      <img src={getNiadaImage(memberPrice.logo_dues)} alt="Logo Dues" />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-x-5 pl-7">
                    <span className="text-gray-600">Discount:</span>
                    <span className="font-bold">{`$${memberPrice.discount_association}`}</span>
                    <span className="font-bold fontSize24">+</span>
                    <span className="text-gray-600">Dues Credit:</span>
                    <span className="font-bold">{`$${memberPrice.dues_credit}`}</span>
                  </div>
                  <div className="grid grid-cols-1 gap-x-5">
                    <div className="flex items-center font-bold pl-7">{`$${memberPrice.const_one_zero_one}/mo.`}</div>
                  </div>
                </div>
                <div className="grid grid-cols-2 mt-3">
                  <div className="grid grid-cols-1">
                    <div className="flex items-center font-extrabold">Member Price:</div>
                  </div>
                  <div className="grid grid-cols-1 gap-x-5">
                    <div className="flex justify-end">
                      <div
                        key="pricing-for-month"
                        className="w-40 lg:w-36 h-28 cursor-pointer bg-blue-50 rounded-lg flex justify-center items-center px-2 border-primary border-2"
                      >
                        <div className="flex flex-col gap-2 text-center">
                          <div className="text-base font-bold">Per-Month</div>
                          <div className="text-primary-color text-lg font-bold">{`$${memberPrice.member_price}`}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="text-left w-full pl-4 mt-3">
              <Radio.Group
                className="text-xl flex justify-center lg:justify-start gap-12 mb-6"
                onChange={handleChangePaymentMethod}
                value={paymentMethod}
              >
                <Radio value="ACH">ACH</Radio>
                <Radio value="Credit card">Credit card</Radio>
              </Radio.Group>
              {paymentMethod === 'ACH' ? (
                <>
                  <div className="flex gap-5">
                    <Form.Item
                      className="flex-1 bank-routing-search"
                      name="bankRoutingNumber"
                      label="Bank Routing Number:"
                      rules={[
                        { required: true, message: 'Bank Routing Number is required' },
                        { pattern: /[0-9]{9}/g, message: 'Bank Routing Number is invalid' },
                        { max: 9, message: 'Max allowed digits is 9' }
                      ]}
                    >
                      <Input
                        suffix={bankResolving ? <LoadingIcon /> : undefined}
                        name="bankRoutingNumber"
                        placeholder="Please fill"
                        className="w-full p-3 box-border rounded-xl border-gray-300 border"
                        type="number"
                        onChange={(e) => onChangeResolvingBank(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      className="flex-1"
                      name="bankAccountNumber"
                      label="Bank Account Number:"
                      rules={[{ required: true, message: 'Bank Account Number is required' }]}
                    >
                      <Input
                        name="bankAccountNumber"
                        placeholder="Please fill"
                        className="w-full p-3 box-border rounded-xl border-gray-300 border"
                        type="number"
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    name="bankName"
                    label="Bank Name:"
                    rules={[{ required: true, message: 'Bank Name is required' }]}
                  >
                    <Input
                      name="bankName"
                      placeholder="Please fill"
                      className="w-full p-3 box-border rounded-xl border-gray-300 border"
                      type="text"
                    />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    name="cardNumber"
                    label="Card Number:"
                    rules={[
                      { required: true, message: 'Card Number is required' },
                      { pattern: /[0-9]{15,19}/g, message: 'Card Number is invalid' },
                      { min: 15, message: 'Minimum digits 15' },
                      { max: 19, message: 'Maximum digits 19' }
                    ]}
                  >
                    <Input
                      name="cardNumber"
                      placeholder="Please fill"
                      className="w-full p-3 box-border rounded-xl border-gray-300 border"
                      type="number"
                      value={cardNumber}
                      onChange={(e) => setSetCardNumber(e.target.value)}
                      onFocus={() => setCardFocus('number')}
                    />
                  </Form.Item>
                  <Form.Item
                    name="cardHolder"
                    label="Card Holder Name:"
                    rules={[{ required: true, message: 'Card Holder Name is required' }]}
                  >
                    <Input
                      name="cardHolder"
                      placeholder="Please fill"
                      className="w-full p-3 box-border rounded-xl border-gray-300 border"
                      type="text"
                      value={cardHolder}
                      onChange={(e) => setCardHolder(e.target.value)}
                      onFocus={() => setCardFocus('name')}
                    />
                  </Form.Item>
                  <div className="grid grid-cols-11 gap-4">
                    <Form.Item
                      className="col-span-4"
                      name="expiration"
                      label="Expiration:"
                      rules={[{ required: true, message: 'Expiration is required' }]}
                    >
                      <DatePicker
                        disabledDate={(current) => {
                          const customDate = moment().format('YYYY-MM-DD')
                          return current && current < moment(customDate, 'YYYY-MM-DD')
                        }}
                        format="MM/YYYY"
                        picker="month"
                        placeholder="MM | YY"
                        className="w-full p-3 box-border rounded-xl border-gray-300 border"
                        value={expiration}
                        onChange={(date) => {
                          const dateString = new Date(date).toLocaleString(undefined, {
                            month: '2-digit',
                            year: '2-digit'
                          })
                          setExpiration(dateString)
                        }}
                        onFocus={() => setCardFocus('expiry')}
                      />
                    </Form.Item>
                    <Form.Item
                      className="flex-1 col-span-3"
                      name="cvv"
                      label="CVV:"
                      rules={[
                        { required: true, message: 'CVV is required' },
                        { pattern: /[0-9]{3,4}/g, message: 'CVV is invalid' },
                        { min: 3, message: 'Minimum digits 3' },
                        { max: 4, message: 'Maximum digits 4' }
                      ]}
                    >
                      <Input
                        name="cvv"
                        placeholder="123"
                        className="w-full p-3 box-border rounded-xl border-gray-300 border"
                        type="text"
                        value={cvv}
                        onChange={(e) => setCvv(e.target.value)}
                        onFocus={() => setCardFocus('cvc')}
                      />
                    </Form.Item>
                    <Form.Item
                      className="col-span-4"
                      name="billingZipCode"
                      label="Billing zip code:"
                      rules={[
                        { required: true, message: 'Billing zip code is required' },
                        { min: 4, max: 5 },
                        { pattern: /[0-9]/g, message: 'Only number is accepted!' }
                      ]}
                    >
                      <Input
                        name="billingZipCode"
                        placeholder="Please fill"
                        className="w-full p-3 box-border rounded-xl border-gray-300 border"
                        type="number"
                      />
                    </Form.Item>
                  </div>
                  <div className="mt-5">
                    <Cards number={cardNumber} name={cardHolder} expiry={expiration} cvc={cvv} focused={cardFocus} />
                  </div>
                </>
              )}
              <Checkbox
                className="mr-2 mt-2"
                onChange={handleCheckboxBillMyDealership}
                value={checkedBillMyDealership}
              />
              Bill My Dealership
              <div className="flex gap-5">
                <Form.Item
                  hidden={!checkedBillMyDealership}
                  label="Contact Name:"
                  name="bill_my_dealership_contact_name"
                  className="flex-1"
                  rules={[
                    {
                      required: checkedBillMyDealership,
                      message: 'Contact Name is required'
                    }
                  ]}
                >
                  <Input
                    defaultValue={billMyDealershipContactName}
                    onChnage={handleBillMyDealershipContactName}
                    hidden={!checkedBillMyDealership}
                    name="bill_my_dealership_contact_name"
                    placeholder="Please fill"
                    className="w-full p-3 box-border rounded-xl border-gray-300 border"
                    type="text"
                  />
                </Form.Item>
                <Form.Item
                  className="flex-1"
                  hidden={!checkedBillMyDealership}
                  name="bill_my_dealership_phone"
                  label="Phone Number:"
                  rules={[
                    { required: checkedBillMyDealership, message: 'Phone Number is required' },
                    {
                      pattern: /(___-___-____)|([0-9,-,\\),\\(,\s])/g,
                      message: 'Phone Number is invalid'
                    }
                  ]}
                >
                  <PhoneNumberInput
                    name="bill_my_dealership_phone"
                    defaultValue={billMyDealershipPhone}
                    onChnage={handleBillMyDealershipPhone}
                    hidden={!checkedBillMyDealership}
                    placeholder="Please fill"
                    className="w-full p-3 box-border rounded-xl border-gray-300 border"
                  />
                </Form.Item>
              </div>
              <Form.Item
                hidden={!checkedBillMyDealership}
                label="Email Address:"
                name="bill_my_dealership_email"
                className="mb-0 sm-max:flex-1 mt-2"
                rules={[
                  {
                    required: checkedBillMyDealership,
                    message: 'Email Address is required'
                  },
                  {
                    pattern: VALID_EMAIL,
                    message: 'Invalid email'
                  }
                ]}
              >
                <Input
                  defaultValue={billMyDealershipEmail}
                  onChnage={handleBillMyDealershipEmail}
                  hidden={!checkedBillMyDealership}
                  name="bill_my_dealership_email"
                  placeholder="Please fill"
                  className="w-full p-3 box-border rounded-xl border-gray-300 border"
                  type="text"
                  style={{ minWidth: '240px' }}
                />
              </Form.Item>
              <Button
                hidden={!checkedBillMyDealership}
                className="hidden sm:flex px-6 py-5 h-12 rounded-lg justify-center items-center gap-2 mt-5 text-base font-semibold text-white bg-blue-700"
                onClick={handleSendEmailInvoiceMe}
              >
                Send <IoMdShareAlt className="text-xl" />
              </Button>
              <Button
                hidden={!checkedBillMyDealership}
                className="hidden sm-max:flex px-6 py-5 h-12 rounded-lg justify-center items-center gap-2 mt-5 text-base font-semibold text-white bg-blue-700"
                onClick={handleSendEmailInvoiceMe}
              >
                <IoMdShareAlt className="text-3xl" />
              </Button>
            </div>
          </div>
        </div>

        <div className="dealer-info-container grid grid-cols-12 gap-x-5 mt-3">
          <div className="md:col-span-12 col-span-12">
            <p className="mb-6 font-bold text-base">Disclaimer:</p>
            <div className="text-[15px]">{paymentMethod === 'ACH' ? achDisclaimer : creditCardDisclaimer}</div>

            <Form
              form={emailForm}
              layout="vertical"
              onChange={onFormChange}
              name="email-form-ref"
              spellCheck={false}
              className="mt-3"
            >
              <div className="flex flex-col lg:flex-row gap-3 lg:gap-5 border-b pb-7 lg:items-center">
                <div className="text-secondary-color text-base font-semibold">Send disclaimer to my email:</div>
                <div className="flex gap-4 w-full lg:w-auto lg:items-center">
                  <Form.Item
                    name="email"
                    className="mb-0 sm-max:flex-1"
                    rules={[
                      {
                        pattern:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Invalid email'
                      }
                    ]}
                  >
                    <Input
                      name="email"
                      placeholder="Email address"
                      className="w-full p-3 box-border rounded-xl border-gray-300 border"
                      type="text"
                      style={{ minWidth: '240px' }}
                    />
                  </Form.Item>
                  <Button
                    className="hidden sm:flex px-6 py-5 h-12 rounded-lg justify-center items-center gap-2 text-base font-semibold text-white bg-blue-700"
                    onClick={handleSendEmail}
                  >
                    Send <IoMdShareAlt className="text-xl" />
                  </Button>
                  <Button
                    className="hidden sm-max:flex px-6 py-5 h-12 rounded-lg justify-center items-center gap-2 text-base font-semibold text-white bg-blue-700"
                    onClick={handleSendEmail}
                  >
                    <IoMdShareAlt className="text-3xl" />
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>

        {/* Text mode */}
        {!isEnabledEditAddress && (
          <div>
            <div className="md:col-start-2 md:col-span-3 text-md mt-4 mb-0 md:mb-4 col-span-12 text-center md:text-left">
              <div className="mb-4">Dealership address</div>
              <div className="font-bold">{dealerName}</div>
              {form.getFieldValue('physicalAddress') === '' ? setIsEnabledEditAddress(true) : null}
              <div>{form.getFieldValue('physicalAddress')}</div>
              <div>{`${form.getFieldValue('city')}`}</div>
              <div>{`${form.getFieldValue('state')?.value} ${form.getFieldValue('zip')}`}</div>
            </div>
          </div>
        )}
        <div className="text-center md:text-left">
          <Button className="hidden w-20 font-bold rounded-xl px-5" onClick={onEditAddress}>
            {isEnabledEditAddress ? 'Save' : 'Edit'}
          </Button>
        </div>
        <BankRoutingNumberPopup
          show={showBankRoutingNumberPopup}
          setShow={setShowBankRoutingNumberPopup}
          setResetNumber={setBankRoutingNumber}
          closable={false}
          maskClosable={false}
        />
      </Form>

      <div className="md:col-start-4 col-span-12 flex sm-max:justify-between justify-end mt-3">
        <Button
          className="px-6 py-5 h-12 border-0 flex justify-center items-center gap-2 text-base font-semibold text-blue-700"
          onClick={backStep}
        >
          <BiChevronLeft className="text-xl" /> Back
        </Button>
        <Button type="primary" onClick={() => onSubmitForm()} className="h-12 w-60 font-bold rounded-xl px-10">
          CLAIM
        </Button>
      </div>
      <Modal
        centered
        visible={modal}
        onCancel={() => setModal(false)}
        className="verify-account-modal-alert"
        width={650}
        footer={null}
      >
        <div className="md:m-14 m-8 flex flex-col justify-center items-center">
          <p className="text-lg text-center">
            MotorMarketUSA Support will need to verify with your dealership your account ownership
          </p>
          <div className="mt-5 w-36">
            <Button
              className="w-full h-10 font-bold"
              type="primary"
              onClick={() => {
                setModal(false)
                nextStep()
              }}
            >
              Ok
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

VerifyYourAccountOwnership.propTypes = {
  nextStep: PropTypes.func,
  backStep: PropTypes.func,
  selectedDealership: PropTypes.object.isRequired,
  setDealershipDetail: PropTypes.func,
  dealershipDetail: PropTypes.object,
  setSignUpData: PropTypes.func,
  setDealerId: PropTypes.func,
  getClaimBusinessPayloadMailAdapter: PropTypes.any,
  getClaimBusinessPayloadAdapter: PropTypes.func,
  setPaymentInfo: PropTypes.func
}

export default VerifyYourAccountOwnership
