import { disableReactDevTools } from '@fvilers/disable-react-devtools'
import 'antd/dist/antd.min.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
import App from './App'
import { AuthorizationProvider } from './AuthorizationProvider'
import { EzDealzWalletProvider } from './EzDealzWalletProvider'
import { HelmetHeadProvider } from './HelmetProvider'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { VehicleInformationProvider } from './VehicleProvider'

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools()
}

const rootElement = document.getElementById('root')

const googleClientId =
  process.env.REACT_APP_GOOGLE_CLIENT_ID !== undefined && process.env.REACT_APP_GOOGLE_CLIENT_ID !== ''
    ? process.env.REACT_APP_GOOGLE_CLIENT_ID
    : ''

const APP =
  googleClientId !== '' ? (
    <GoogleOAuthProvider clientId={googleClientId}>
      <React.StrictMode>
        <Router>
          <AuthorizationProvider>
            <EzDealzWalletProvider>
              <VehicleInformationProvider>
                <HelmetHeadProvider>
                  <App />
                </HelmetHeadProvider>
              </VehicleInformationProvider>
            </EzDealzWalletProvider>
          </AuthorizationProvider>
        </Router>
      </React.StrictMode>
    </GoogleOAuthProvider>
  ) : (
    <React.StrictMode>
      <Router>
        <AuthorizationProvider>
          <EzDealzWalletProvider>
            <VehicleInformationProvider>
              <HelmetHeadProvider>
                <App />
              </HelmetHeadProvider>
            </VehicleInformationProvider>
          </EzDealzWalletProvider>
        </AuthorizationProvider>
      </Router>
    </React.StrictMode>
  )

// if (rootElement.hasChildNodes()) {
//   ReactDOM.hydrate(APP, rootElement)
// } else {
//   ReactDOM.render(APP, rootElement)
// }

ReactDOM.render(APP, rootElement)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
