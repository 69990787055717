import React, { useState } from 'react'
import BodyStepper from 'src/components/Financing/BodyStepper'
import Stepper from 'src/components/Financing/Stepper'
import Slider from '../../components/Slider/Slider'
import './style.scss'
import SinglePageMetaDecorator from '../../decorators/SinglePageMetaDecorator'
import metaPages from '../../data/metaPages.json'
import metaPageImage from '../../assets/new-logo.png'

function Financing() {
  const [currentStep, setCurrentStep] = useState(1)

  return (
    <>
      <SinglePageMetaDecorator
        title={metaPages.financing.title}
        description={metaPages.financing.description}
        imageUrl={metaPageImage}
        imageAlt={metaPages.financing.imageAlt}
      />
      <Slider className="web-slider" hiddenControls>
        <div className="banner banner-background-qualified h-80 lg:h-100">
          <div className="container baner-alignment">
            <p className="text-darkPrimary font-bold lg:text-7xl text-4xl uppercase">Get Pre-Qualified</p>
            <p className="lg:text-2xl text-sm text-white">Get an estimate on your loan amount and monthly payment</p>
          </div>
        </div>
        <div className="banner banner-background2 h-80 lg:h-100">
          <div className="container baner-alignment">
            <p className="text-darkPrimary font-extrabold lg:text-7xl text-4xl">DEPENDABLE</p>
            <p className="lg:text-2xl text-sm text-black w-1/2">
              Buy with CONFIDENCE and peace of mind! All vehicles CERTIFIED by NIADA and come with 36 month bumper to
              bumper warranty PLUS Vehicle History and Inspection Reports.
            </p>
          </div>
        </div>
        <div className="banner banner-background3 h-80 lg:h-100">
          <div className="container baner-alignment">
            <p className="text-darkPrimary font-extrabold lg:text-7xl text-4xl md:w-1/2 leading-none">
              Delivered to your driveway
            </p>
            <p className="lg:text-2xl text-sm text-black md:w-1/2 leading-none lg:mt-10 mt-5">
              We deliver to Your Driveway Within 72 Hours of Purchase
            </p>
          </div>
        </div>
      </Slider>
      {currentStep < 7 && (
        <div className="mb-5 container mx-auto h-24 mt-14">
          <Stepper currentStep={currentStep} />
        </div>
      )}
      <div className="container mx-auto mb-12">
        <BodyStepper currentStep={currentStep} setCurrentStep={setCurrentStep} />
      </div>
    </>
  )
}

export default Financing
