import { Button, Form, Input, Modal, notification, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import * as DealerService from 'src/services/dealer.service'
import * as PropTypes from 'prop-types'
import { useAuthorizationContext } from '../../../AuthorizationProvider'

const tableColumns = [
  {
    title: 'Rate 36',
    dataIndex: 'rate_year_36',
    key: 'rate_year_36',
    render: (value) => <div className="text-center">{`-${value}`}</div>
  },
  {
    title: 'Rate 48',
    dataIndex: 'rate_year_48',
    key: 'rate_year_48',
    render: (value) => <div className="text-center">{`-${value}`}</div>
  },
  {
    title: 'Rate 60',
    dataIndex: 'rate_year_60',
    key: 'rate_year_60',
    render: (value) => <div className="text-center">{`-${value}`}</div>
  },
  {
    title: 'Rate 72',
    dataIndex: 'rate_year_72',
    key: 'rate_year_72',
    render: (value) => <div className="text-center">{`-${value}`}</div>
  }
]

const formFields = [
  {
    label: 'Rate 36',
    name: 'rate_year_36',
    required: true,
    type: 'number',
    prefix: '-'
  },
  {
    label: 'Rate 48',
    name: 'rate_year_48',
    required: true,
    type: 'number',
    prefix: '-'
  },
  {
    label: 'Rate 60',
    name: 'rate_year_60',
    required: true,
    type: 'number',
    prefix: '-'
  },
  {
    label: 'Rate 72',
    name: 'rate_year_72',
    required: true,
    type: 'number',
    prefix: '-'
  }
]

function CreditScoreRateYearTable({ dealerId }) {
  const [showModal, setShowModal] = useState(false)
  const [form] = Form.useForm()
  const [selectedRow, setSelectedRow] = useState({})
  const [dataList, setDataList] = useState([])
  const { dealershipAccount } = useAuthorizationContext()
  const canManagePaymentSettings = undefined !== dealershipAccount ? dealershipAccount?.permissions?.manage_payment_settings : true

  const handleChangeShowModal = () => setShowModal(!showModal)

  const handleFormSubmission = () => {
    const parameters = form.getFieldsValue()
    parameters.dealer_id = dealerId
    DealerService.updateCreditScoreRateYearsRecord(parameters)
      .then((response) => {
        setDataList(response.data)
        notification.success({
          message: 'Success!',
          description: 'Updated rate years successful.'
        })
      })
      .catch((error) => {
        console.error(error)
        notification.error({
          message: 'Error!',
          description: 'Cannot complete this action. Please try again!'
        })
      })
      .finally(() => handleChangeShowModal())
  }

  const onFormChange = (e) => {
    if (e.charCode) form.setFieldsValue({ ...form.getFieldValue(), [e.target.name]: e.target.value })
  }

  const onSelectRow = (record) => {
    form.setFieldsValue(record)
    setSelectedRow(record)
  }

  useEffect(() => {
    DealerService.getCreditScoreRateYears(dealerId)
      .then((response) => {
        const data = response.data
        setDataList(data)
      })
      .catch((error) => console.error(error))
  }, [])

  return (
    <div className="container">
      <div className="mx-auto my-10 px-4 rounded-xl border-2 py-4">
        <div>Credit Score Rate Years</div>
        <Table
          columns={tableColumns}
          className="mt-5 payment-settings-table "
          dataSource={dataList}
          scroll={{ x: 'max-content' }}
          pagination={{ position: ['none', 'none'] }}
          onRow={(record) => {
            return {
              onClick: () => {
                if (canManagePaymentSettings) {
                  onSelectRow(record)
                  handleChangeShowModal()
                }
              }
            }
          }}
        />
      </div>
      <Modal
        visible={showModal}
        title="Edit Credit Score Rate Years"
        footer={null}
        style={{ top: 20 }}
        onCancel={handleChangeShowModal}
        className="w-full"
      >
        <Form form={form} layout="vertical" name="vehicle-ref" onFinish={handleFormSubmission} onChange={onFormChange}>
          {formFields.map((item, index) => {
            return (
              <div key={item.name}>
                <p className="text-base font-semibold">{item.label}</p>
                <Form.Item
                  name={item.name}
                  rules={[
                    {
                      required: item.required,
                      message: `${item.label} is required`
                    },
                    {
                      pattern: /^\d*\.?\d*$/,
                      message: `${item.label} is invalid format`
                    }
                  ]}
                  className="custom-antd-label flex"
                >
                  <Input
                    placehodler={item.value}
                    name={item.name}
                    autoFocus={index === 1}
                    className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
                    prefix={item.prefix}
                    disabled={selectedRow.type === 'default'}
                  />
                </Form.Item>
              </div>
            )
          })}

          <Form.Item className="flex items-end w-full mt-5 submit" shouldUpdate>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                className="w-48 px-6 py-5 h-12 rounded-lg flex justify-center items-center gap-2 text-base font-semibold text-white bg-blue-700"
              >
                Save
              </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

CreditScoreRateYearTable.propTypes = {
  dealerId: PropTypes.number
}

export default CreditScoreRateYearTable
