import { CheckOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

const colors = [
  {
    name: 'Beige',
    color: '#d0b48d'
  },
  {
    name: 'Gray',
    color: '#a1a1a1'
  },
  {
    name: 'White',
    color: '#ffffff'
  },
  {
    name: 'Blue',
    color: '#0a5da3'
  },
  {
    name: 'Black',
    color: '#000000'
  },
  {
    name: 'Red',
    color: '#e95240'
  }
]
const color2 = [
  {
    name: 'Other',
    color: '#ffffff'
  }
]
function VehicleFinanceStep4({
  backStep,
  nextStep,
  engine,
  setEngine,
  transmission,
  setTransmission,
  color,
  setColor,
  tradeVehicleInfo,
  setSelectedOtherColor,
  selectedOtherColor
}) {
  const { year, make, model, yearId, makeName, modelName, standardEngine, standardTransmission } = tradeVehicleInfo
  const [errorMessage, setErrorMessage] = useState(true)
  const handleNexStep = () => {
    nextStep()
  }
  const handleBackStep = () => {
    backStep()
  }

  const yearDisplay = year || yearId || ''
  const makeDisplay = make || makeName || ''
  const modelDisplay = model || modelName || ''

  useEffect(() => {
    setEngine(standardEngine)
    setTransmission(standardTransmission)
  }, [standardEngine, standardTransmission])

  const handleChangeColor = (item) => {
    setColor(item.name)
    setErrorMessage(true)
    setSelectedOtherColor(false)
  }

  const handleOtherColor = () => {
    setColor('')
    setSelectedOtherColor(true)
  }

  const handleChangeOtherColor = (e) => {
    const value = e.target.value
    const testValue = /^[A-Za-z\s]*$/.test(value)
    setErrorMessage(testValue)
    setColor(value)
  }

  return (
    <div className="w-full px-2 mt-8 step-4">
      <p className="text-2xl text-black font-bold">Tell Us More About Your Car</p>
      <p className="text-gray-400 text-base mb-3">
        {`Please answer thoroughly, these options will affect your ${yearDisplay} ${makeDisplay} ${modelDisplay}'s value`}
      </p>
      <div className="px-2">
        <div className="mb-5">
          <p className="text-black text-sm mb-2">Engine</p>
          <input
            className="w-full border border-black rounded py-2 px-3"
            type="text"
            value={engine}
            maxLength={25}
            disabled
            onChange={(e) => setEngine(e.target.value)}
          />
        </div>
        <div className="mb-5">
          <p className="text-black text-sm mb-2">Transmission</p>
          <input
            className="w-full border border-black rounded py-2 px-3"
            type="text"
            value={transmission}
            maxLength={15}
            disabled
            onChange={(e) => setTransmission(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <p className="text-black text-sm mb-2">Color</p>
          <div className="flex overflow-y-hidden">
            {colors.map((item) => (
              <div className="flex flex-col items-center" key={`color-${item.name}`}>
                <button
                  type="button"
                  className={`w-16 h-16 mr-3 rounded cursor-pointer flex justify-center items-center ${color === item.name && !selectedOtherColor ? 'border-2 border-primary' : ''
                    }`}
                  style={{ backgroundColor: item.color }}
                  onClick={() => handleChangeColor(item)}
                >
                  {color === item.name && !selectedOtherColor && (
                    <div className="flex justify-center items-center w-5 h-5 bg-primary rounded-full text-white">
                      <CheckOutlined />
                    </div>
                  )}
                </button>
                <p className="text-gray-600 mt-1">{item.name}</p>
              </div>
            ))}
            {color2.map((item) => (
              <div className="flex flex-col items-center" key={`color-${item.name}`}>
                <button
                  type="button"
                  className={`w-16 h-16 mr-3 rounded cursor-pointer flex justify-center items-center	border-black${color === item.name ? 'border-2 border-primary' : ''
                    }`}
                  style={{ backgroundColor: item.color }}
                  onClick={() => handleOtherColor(item)}
                >
                  {selectedOtherColor && (
                    <div className="flex justify-center items-center w-5 h-5 bg-primary rounded-full text-white">
                      <CheckOutlined />
                    </div>
                  )}
                </button>
                <p className="mt-1 rounded-sm mb-1 px-2">{item.name}</p>
                {selectedOtherColor && (
                  <input
                    className="w-34 lg:w-24 border border-slate-400"
                    placeholder="Specify color"
                    onChange={handleChangeOtherColor}
                    type="text"
                    value={color}
                    label="Specify color"
                  />
                )}

                {!errorMessage && selectedOtherColor ? <p className="text-red-600">Please use only letters</p> : ''}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-around mt-12 gap-2">
        <button type="button" className="bg-gray-500 rounded text-white px-16 py-2" onClick={handleBackStep}>
          Back
        </button>
        <button
          disabled={!color}
          type="button"
          className={`bg-primary rounded text-white px-16 py-2 hover:bg-activePrimary ${!color ? 'cursor-not-allowed opacity-50 pointer-events-none' : ''
            }`}
          onClick={handleNexStep}
        >
          Next
        </button>
      </div>
    </div>
  )
}

VehicleFinanceStep4.propTypes = {
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  engine: PropTypes.string,
  setEngine: PropTypes.func,
  transmission: PropTypes.string,
  setTransmission: PropTypes.func,
  color: PropTypes.string,
  setColor: PropTypes.func,
  tradeVehicleInfo: PropTypes.object,
  setSelectedOtherColor: PropTypes.func,
  selectedOtherColor: PropTypes.bool
}

export default VehicleFinanceStep4
