import { ExclamationCircleOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import React from 'react'
import { useAuthorizationContext } from 'src/AuthorizationProvider'

function VehicleDetail({ vehicleDetail }) {
  const { vehicle } = vehicleDetail
  const { accountSettings } = useAuthorizationContext()

  return (
    <>
      {vehicle.comparison && (
        <div className="flex flex-col mb-5">
          <div className="flex justify-between">
            <p className="text-sm px-3 py-0.5 bg-light rounded-t-lg">Price Comparison</p>
            <ExclamationCircleOutlined />
          </div>
          <div className="bg-light px-3 w-full rounded-lg rounded-tl-none">
            <div className="flex justify-center relative mb-3 pt-3">
              <div className="bg-primary rounded-full px-3 py-0.5">
                <p className="text-sm text-white">${vehicle?.price}</p>
              </div>
              <div className="absolute -bottom-1 left-1/2 -translate-x-2/4 w-2 h-2 bg-primary rotate-45" />
            </div>
            <div className="relative h-[5px] bg-gray-300 w-full">
              <div
                style={{
                  width: `${(5000 * 100) / (vehicle.comparisonData.high.price - vehicle.comparisonData.low.price)}%`,
                  left: `${
                    ((vehicle.price - vehicle.comparisonData.low.price) * 100) /
                    (vehicle.comparisonData.high.price - vehicle.comparisonData.low.price)
                  }%`,
                  transform: 'translateX(-50%)'
                }}
                className="absolute top-0 bg-primary h-[5px]"
              />
              <div className="absolute bg-gray-300 left-0 -bottom-[5px] w-0.5 h-2" />
              <div className="absolute bg-gray-300 right-0 -bottom-[5px] w-0.5 h-2" />
            </div>
            <div className="flex justify-between">
              <p className="text-gray-400 text-sm mt-0.5">Low</p>
              <p className="text-gray-400 text-sm mt-0.5">High</p>
            </div>
            <div className="flex justify-between">
              <p className="text-black text-xs mt-0.5">${vehicle.comparisonData.low.price.toLocaleString()}</p>
              <p className="text-black text-xs mt-0.5">${vehicle.comparisonData.middle.price.toLocaleString()}</p>
              <p className="text-black text-xs mt-0.5">${vehicle.comparisonData.high.price.toLocaleString()}</p>
            </div>
            <div className="flex justify-between pb-3">
              <p className="text-gray-400 text-xs mt-0.5">
                {vehicle.comparisonData.low.distance.toLocaleString()} miles
              </p>
              <p className="text-gray-400 text-xs mt-0.5">
                {vehicle.comparisonData.middle.distance.toLocaleString()} miles
              </p>
              <p className="text-gray-400 text-xs mt-0.5">
                {vehicle.comparisonData.high.distance.toLocaleString()} miles
              </p>
            </div>
          </div>
        </div>
      )}
      <table className="w-full">
        <tbody>
          <tr>
            <td className="border-b border-lightGray py-2 w-2/5 text-base font-medium">BODY</td>
            <td className="border-b border-lightGray py-2 w-3/5 text-base">{vehicle?.body}</td>
          </tr>
          <tr>
            <td className="border-b border-lightGray py-2 w-2/5 text-base font-medium">STYLE</td>
            <td className="border-b border-lightGray py-2 w-3/5 text-base">{vehicle?.style}</td>
          </tr>
          <tr>
            <td className="border-b border-lightGray py-2 w-2/5 text-base font-medium">MILEAGE</td>
            <td className="border-b border-lightGray py-2 w-3/5 text-base">
              <p className="flex items-center">{vehicle?.miles}</p>
            </td>
          </tr>
          <tr>
            <td className="border-b border-lightGray py-2 w-2/5 text-base font-medium">FUEL TYPE</td>
            <td className="border-b border-lightGray py-2 w-3/5 text-base">{vehicle?.fuel || 'Not Available'}</td>
          </tr>
          <tr>
            <td className="border-b border-lightGray py-2 w-2/5 text-base font-medium">ENGINE</td>
            <td className="border-b border-lightGray py-2 w-3/5 text-base">{vehicle?.engine_name}</td>
          </tr>
          <tr>
            <td className="border-b border-lightGray py-2 w-2/5 text-base font-medium">TRANSMISSION</td>
            <td className="border-b border-lightGray py-2 w-3/5 text-base">{vehicle?.transmission_name}</td>
          </tr>
          <tr>
            <td className="border-b border-lightGray py-2 w-2/5 text-base font-medium">EXT.COLOR</td>
            <td className="border-b border-lightGray py-2 w-3/5 text-base">{vehicle?.exterior_color}</td>
          </tr>
          <tr>
            <td className="border-b border-lightGray py-2 w-2/5 text-base font-medium">INT.COLOR</td>
            <td className="border-b border-lightGray py-2 w-3/5 text-base">{vehicle?.interior_color}</td>
          </tr>
          <tr>
            <td className="border-b border-lightGray py-2 w-2/5 text-base font-medium">VIN</td>
            <td className="border-b border-lightGray py-2 w-3/5 text-base">{vehicle?.vin}</td>
          </tr>
          <tr>
            <td className="border-b border-lightGray py-2 w-2/5 text-base font-medium">STOCK #</td>
            <td className="border-b border-lightGray py-2 w-3/5 text-base">{vehicle?.stock_number}</td>
          </tr>

          {Object.keys(accountSettings).length > 0 && accountSettings.role !== 'customer' && (
            <tr>
              <td className="border-b border-lightGray py-2 w-2/5 text-base font-medium">Dealer ID</td>
              <td className="border-b border-lightGray py-2 w-3/5 text-base">{vehicle?.dealer_id}</td>
            </tr>
          )}

          <tr>
            <td className="border-b border-lightGray py-2 w-2/5 text-base font-medium">DEALER NAME</td>
            <td className="border-b border-lightGray py-2 w-3/5 text-base">{vehicle?.dealer_name}</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

VehicleDetail.propTypes = {
  vehicleDetail: PropTypes.shape({
    vehicle: PropTypes.shape({
      fuel: PropTypes.string,
      price: PropTypes.object,
      comparison: PropTypes.bool,
      comparisonData: PropTypes.shape({
        high: PropTypes.shape({
          price: PropTypes.number,
          distance: PropTypes.number
        }),
        low: PropTypes.shape({
          price: PropTypes.number,
          distance: PropTypes.number
        }),
        middle: PropTypes.shape({
          price: PropTypes.number,
          distance: PropTypes.number
        })
      }),
      body: PropTypes.string,
      style: PropTypes.string,
      miles: PropTypes.string,
      engine_name: PropTypes.string,
      transmission_name: PropTypes.string,
      exterior_color: PropTypes.string,
      interior_color: PropTypes.string,
      vin: PropTypes.string,
      stock_number: PropTypes.string,
      fullType: PropTypes.string,
      dealer_id: PropTypes.number,
      dealer_name: PropTypes.string
    })
  })
}

export default VehicleDetail
