import PropTypes from 'prop-types'
import React from 'react'
import OwlCarousel from 'react-owl-carousel3'
import './style.scss'

function Slider({ className, children, hiddenControls, ...rest }) {
  const options = {
    loop: true,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: false,
    responsive: {
      0: {
        items: 1
      }
    }
  }

  const handleNext = () => {
    if (window.innerWidth < 992) {
      document.querySelector('.mobile-slider .owl-carousel .owl-next').click()
    } else {
      document.querySelector('.web-slider .owl-carousel .owl-next').click()
    }
  }

  const handlePrev = () => {
    if (window.innerWidth < 992) {
      document.querySelector('.mobile-slider .owl-carousel .owl-prev').click()
    } else {
      document.querySelector('.web-slider .owl-carousel .owl-prev').click()
    }
  }

  return (
    <div className={`relative owl-carousel-container ${className}`}>
      <OwlCarousel className="owl-theme default-slider" {...options} {...rest}>
        {children}
      </OwlCarousel>
      {!hiddenControls && (
        <>
          <button type="button" className="btn-preview cursor-pointer" onClick={handlePrev}>
            ‹
          </button>
          <button type="button" className="btn-next cursor-pointer" onClick={handleNext}>
            ›
          </button>
        </>
      )}
    </div>
  )
}

Slider.defaultProps = {
  className: '',
  children: undefined
}

Slider.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  hiddenControls: PropTypes.bool
}

export default Slider
