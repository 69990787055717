import { Chart as ChartJS, Filler } from 'chart.js'
import React, { useState, useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import { useAuthorizationContext } from 'src/AuthorizationProvider'
import { getDealershipVehicleVisits } from 'src/services/dealer.service'

ChartJS.register(Filler)

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      display: false
    },
    title: {
      display: false,
      text: 'Visitor Statistic Chart'
    }
  }
}

const dateRangeOptions = [
  {
    value: 1,
    label: '1 month'
  },
  {
    value: 2,
    label: '2 months'
  },
  {
    value: 3,
    label: '3 months'
  },
  {
    value: 4,
    label: '4 months'
  }
]

export function VisitorStatistic() {
  const { dealershipAccount } = useAuthorizationContext()
  const dealerId = dealershipAccount?._id ?? 0
  const [visits, setVisits] = useState([])
  const [dateRange, setDateRange] = useState(dateRangeOptions[3].value)
  const [allLabels, setAllLabels] = useState(['Jan', 'Feb', 'Mar', 'Apr'])
  const [labels, setLabels] = useState(allLabels)

  const getVisits = () => {
    getDealershipVehicleVisits(dealerId)
        .then((response) => {
          const data = response.data
          setAllLabels(data.labels)
          setLabels(data.labels)
          setVisits(data.visits)
        })
        .catch((error) => {
          console.error(error.message)
        })
  }

  useEffect(() => {
    getVisits()
  }, [dealerId])

  const handleChangeDateRange = (event) => {
    const value = event.target.value
    setLabels(allLabels.slice(0, value))
    setDateRange(value)
  }
  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: visits,
        borderColor: '#89a1c7',
        borderWidth: 2,
        backgroundColor: '#e7effa',
        tension: 0.2,
        fill: true
      }
    ]
  }

  return (
    <div className="rounded-xl border-2 p-4 flex flex-col justify-between mt-5">
      <div className="flex justify-between">
        <div className="text-xl font-semibold">Visitors</div>
          <div className="w-36">
            <select
              className="w-full border border-black rounded py-2 px-3"
              onChange={handleChangeDateRange}
              value={dateRange}
            >
              <option value="">Select Month</option>
              {dateRangeOptions.map((item) => (
                <option value={item.value} key={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
      </div>
      <Line options={options} data={data} />
    </div>
  )
}
