import { Button, Modal } from 'antd'
import * as PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { BsPrinterFill } from 'react-icons/bs'
import { useReactToPrint } from 'react-to-print'

function VehicleFinalDealModal({ calculation, offer, selectedTrade, showModal, handleCloseModal }) {
  const finalDealRef = useRef()
  const handlePrintFinalDeal = useReactToPrint({
    content: () => finalDealRef.current
  })

  const vehiclePrice = () => {
    return calculation.vehiclePrice.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })
  }

  const msrpPrice = () => {
    return calculation.msrp.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })
  }

  const taxesAndFeesPrice = () => {
    return calculation.totalTaxesAndFees.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })
  }

  return (
    <Modal
      centered
      visible={showModal}
      title="Final Deal Details"
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      okText="Close"
      className="calendar-modal"
      destroyOnClose
      width={650}
      footer={[
        <div className="flex justify-end">
          <Button
            className="flex gap-2 justify-center items-center font-semibold text-md rounded-lg p-4"
            icon={<BsPrinterFill className="text-md" />}
            type="primary"
            onClick={handlePrintFinalDeal}
          >
            Print
          </Button>
        </div>
      ]}
    >
      <div ref={finalDealRef} className="items-center py-4 ml-8 mr-8">
        <div className="border-b border-gray-500 px-4 py-3">
          <div className="flex justify-between">
            <p className="text-lg text-black">Your Price</p>
            <p className="text-lg text-black">{vehiclePrice()}</p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-gray-400">MSRP</p>
            <p className="text-sm text-gray-400">{msrpPrice()}</p>
          </div>
        </div>

        <div className="border-b border-gray-500 px-4 py-3 relative">
          <div>
            <div className="flex justify-between">
              <p className="text-lg text-black">Taxes & Fees</p>
              <p className="text-lg text-black">{taxesAndFeesPrice()}</p>
            </div>
            <div className="flex justify-between">
              <p className="text-sm text-gray-400">Zip Code</p>
              <p className="text-sm text-gray-400">{calculation.zipCode}</p>
            </div>
            <div className="flex justify-between">
              <p className="text-sm text-gray-400">Sales Taxes</p>
              <p className="text-sm text-gray-400">
                {calculation.salesTaxesCalculated.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0
                })}
              </p>
            </div>
          </div>

          <div className="w-full transition-all duration-300">
            <div className="pt-2">
              <div className="flex justify-between">
                <p className="text-sm text-gray-400">Total Tees</p>
                <p className="text-sm text-gray-400">{calculation.dealerFeesLabel}</p>
              </div>
              {calculation.dealerFeesArray?.fees?.map((item) => {
                return (
                  <div className="flex justify-between">
                    <p className="text-sm text-gray-400">{item.name}</p>
                    <p className="text-sm text-gray-400">{item.valueCurrency}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className="border-b border-gray-500 px-4 py-3">
          <div className="flex justify-between cursor-pointer" role="presentation">
            <p className="text-lg text-black">Total Price</p>
            <p className="text-lg text-black">
              {calculation.totalPriceInteger.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
              })}
            </p>
          </div>
          <div className="w-full transition-all duration-300 overflow-hidden">
            <div className="pt-2">
              <div className="flex justify-between">
                <p className="text-sm text-gray-400">Term</p>
                <p className="text-sm text-gray-400">{calculation.term}</p>
              </div>
              <div className="flex justify-between">
                <p className="text-sm text-gray-400">Payment</p>
                <p className="text-sm text-gray-400">
                  {calculation.paymentMonthlyInteger.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0
                  })}
                </p>
              </div>
              <div className="flex justify-between">
                <p className="text-sm text-gray-400">Interest Rate</p>
                <p className="text-sm text-gray-400">{calculation.apr}%</p>
              </div>
              <div className="flex justify-between">
                <p className="text-sm text-gray-400">Trade in equity</p>
                <p className="text-sm text-gray-400">
                  {selectedTrade?.vehicle?.estimatedvalue
                    ? selectedTrade.vehicle.estimatedvalue.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0
                      })
                    : 0}
                </p>
              </div>
              <div className="flex justify-between">
                <p className="text-sm text-gray-400">Down Payment</p>
                <p className="text-sm text-gray-400">
                  {calculation.cashDown.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0
                  })}
                </p>
              </div>
              <div className="flex justify-between">
                <p className="text-sm text-gray-400">Amount Financed</p>
                <p className="text-sm text-gray-400">{calculation.amountFinancedLabel}</p>
              </div>
            </div>
          </div>
        </div>

        {offer?.finish_your_deal_comments && (
          <div className="border-b border-gray-500 px-4 py-1 mb-3">
            <div className="flex justify-between">
              <p className="text-lg text-black">Comment(s)</p>
            </div>
            <p className="text-sm px-4 py-3 text-gray-400 leading-4">{offer?.finish_your_deal_comments}</p>
          </div>
        )}

        <p className="text-sm px-4 py-1 text-gray-400 leading-4">
          *Estimated payment based on vehicle price of {vehiclePrice()}, includes {taxesAndFeesPrice()} in fees and
          taxes. Final transaction may include additional fees/charges and texes. This is not an offer or sales contract
          and is contingent upon dealer&apos;s acceptance of these terms. See dealer for terms of purchase.
        </p>
      </div>
    </Modal>
  )
}

VehicleFinalDealModal.propTypes = {
  calculation: PropTypes.object,
  offer: PropTypes.object,
  selectedTrade: PropTypes.object,
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func
}

export default VehicleFinalDealModal
