import * as PropTypes from 'prop-types'
import React from 'react'
import InputMask from 'react-input-mask'

function PhoneNumberInput({ value, onChange, mask = '999-999-9999', ...rest }) {
  return <InputMask {...rest} mask={mask} value={value} onChange={onChange} />
}

PhoneNumberInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  mask: PropTypes.string
}

export default PhoneNumberInput
