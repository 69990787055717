import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { generateSearchURL } from 'src/utils'
import * as BuyService from '../../services/buy.service'

function SearchCar({
  heading = true,
  buy = false,
  bgColor = true,
  keyword,
  setKeyword,
  dealerId,
  setMake,
  make,
  setModel,
  model,
  year,
  setYear,
  setYearDefault,
  shouldChangeUrlRef,
  setShowKeywordTag
}) {
  const [searchedCars, setSearchedCars] = useState([])
  const [query, setQuery] = useState(keyword)
  const [showSearch, setShowSearch] = useState(false)
  const ref = useRef(null)

  const navigate = useNavigate()

  const searchQuery = (query) => {
    BuyService.getSearchSuggestionElasticsearch(query)
      .then((response) => {
        setSearchedCars(response.data)
        setShowSearch(true)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (!keyword) {
      setQuery('')
    }
  }, [keyword])

  const debounceQueryChange = useMemo(() => {
    return debounce(searchQuery, 500)
  }, [])

  useEffect(() => {
    return () => {
      // if unmount => cancel to avoid call api in stack queue(async func)
      debounceQueryChange.cancel()
    }
  }, [])

  const change = (e) => {
    setQuery(e.target.value)

    if (e.target.value) {
      debounceQueryChange(e.target.value)
    } else {
      setSearchedCars([])
      setKeyword('')
      if (dealerId) {
        navigate(`/${`dealership/${dealerId}`}`)
      } else {
        shouldChangeUrlRef.current = false
        const newSearchURL = generateSearchURL({
          make,
          model,
          year
        })
        navigate(newSearchURL)
      }
    }
  }

  const search = () => {
    if (dealerId) {
      navigate(`/${`dealership/${dealerId}?query=${query}`}`)
    } else {
      shouldChangeUrlRef.current = false
      let searchQuery = query
      const searchId = Math.random().toString()
      searchQuery += `&searchID=${searchId}`
      if (query) {
        setMake([])
        setModel([])
        setYear({
          min: 2009,
          max: 2023
        })
        setYearDefault(false)
        navigate(`/buy/${query}?query=${query}`)
      } else {
        const newSearchURL = generateSearchURL({
          keyword: query,
          make,
          model,
          year
        })
        navigate(`${newSearchURL}?query=${searchQuery}`)
      }
    }
  }

  const selectValue = (car) => {
    setMake([car._source.vehicle_make])
    setModel([car._source.vehicle_model])
    setYear({ min: car._source.vehicle_year, max: car._source.vehicle_year })
    setYearDefault(true)
    setShowSearch(false)
    const newQuery = `${car._source.vehicle_year} ${car._source.vehicle_make} ${car._source.vehicle_model}`
    setQuery(`${car._source.vehicle_year} ${car._source.vehicle_make} ${car._source.vehicle_model}`)
    setKeyword(newQuery)
    setShowKeywordTag(false)
    if (dealerId) {
      navigate(`/${`dealership/${dealerId}?query=${newQuery}`}`)
    } else {
      shouldChangeUrlRef.current = false
      const searchId = Math.random().toString()
      const searchQuery = `${newQuery}&searchID=${searchId}`
      const newSearchURL = generateSearchURL({
        keyword: newQuery,
        make,
        model,
        year: car._source.vehicle_year
      })
      navigate(`${newSearchURL}?query=${searchQuery}`)
    }
  }

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setSearchedCars([])
    }
  }

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        search()
        setKeyword(query)
        setShowSearch(false)
        setShowKeywordTag(true)
      }
    }

    document.addEventListener('keydown', keyDownHandler)
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [ref, query,setShowKeywordTag])

  return (
    <div className={`${bgColor && 'bg-gray-200'} ${!buy && 'lg:-mt-16 mb-20 p-4'} rounded-xl relative z-10 flex-1`}>
      {heading && <p className="text-black font-bold text-xl mb-3 ml-3 pt-1">Find Your Car</p>}
      <div className="flex">
        <div className="relative w-full" ref={ref}>
          <input
            onChange={change}
            maxLength={255}
            className={`placeholder:text-xs placeholder:text-inputColor text-deepGrey w-full flex-1 p-2 border border-inputColor bg-inputBgColor outline-none rounded-l-md focus:border-primary ${buy ? 'rounded-lg md:rounded-none md:rounded-l-md' : ''
              }`}
            type="text"
            value={query === 'null' ? '' : query}
            placeholder="Search by Year, Make, Model"
          />
          {showSearch && query && searchedCars?.length > 0 && (
            <div className="h-full">
              <ul className="absolute overflow-y-auto bg-white w-full right-0 left-0 border border-gray-300 rounded-md">
                {searchedCars?.map((car, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      selectValue(car)
                    }}
                    className="hover:bg-gray-50 list-none text-sm px-2 py-3 cursor-pointer"
                    role="presentation"
                  >
                    {`${car._source.vehicle_year} ${car._source.vehicle_make} ${car._source.vehicle_model}`}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <button
          type="button"
          onClick={search}
          className={`bg-primary hover:bg-activePrimary text-white outline-none rounded-r-md flex items-center justify-center w-40 md:w-52 ${buy ? 'hidden md:block' : ''
            }`}
        >
          Search
        </button>
      </div>
    </div>
  )
}

SearchCar.defaultProps = {
  heading: true,
  buy: false,
  bgColor: true,
  dealerId: ''
}

SearchCar.propTypes = {
  heading: PropTypes.bool,
  buy: PropTypes.bool,
  bgColor: PropTypes.bool,
  keyword: PropTypes.string,
  setKeyword: PropTypes.func,
  dealerId: PropTypes.string,
  setMake: PropTypes.func,
  setModel: PropTypes.func,
  year: PropTypes.object,
  setYear: PropTypes.func,
  make: PropTypes.array,
  model: PropTypes.array,
  setYearDefault: PropTypes.func,
  shouldChangeUrlRef: PropTypes.object,
  setShowKeywordTag: PropTypes.func
}

export default SearchCar
