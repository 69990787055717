import { Form, Input, InputNumber, Radio, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { CREDIT_SCORE, DEFAULT_OPTIONS, STORAGE_KEYS, TERM_LENGTH } from 'src/constants/constants'
import { load, save } from 'src/lib/storage'
import './style.scss'

const { Option } = Select

const creditTearmForm = [
  {
    label: 'Tell us your credit score',
    name: 'creditScore',
    required: true,
    type: 'select',
    options: CREDIT_SCORE,
    placeholder: 'Choose credit range'
  },
  {
    label: 'How long would you like your loan to be?',
    name: 'termLength',
    required: true,
    type: 'select',
    options: TERM_LENGTH,
    placeholder: 'Choose term length (months)'
  },
  {
    label: 'Do you want a custom rate?',
    name: 'customRate',
    required: true,
    type: 'radio',
    options: DEFAULT_OPTIONS
  },
  {
    label: 'Average interest rates',
    name: 'averageInterest',
    type: 'info',
    dependencies: ['customRate'],
    options: [
      {
        label: 'New Car Loan',
        name: 'newCarLoan'
      },
      {
        label: 'Used Car Loan',
        name: 'usedCarLoan'
      }
    ]
  }
]

function CreditTerm() {
  const previousValue = load(STORAGE_KEYS.EZ_DEALZ_BUDGET) || {}
  const [form] = Form.useForm()
  const [, forceUpdate] = useState({})
  const [carLoan, setCarLoan] = useState({
    newCarLoan: previousValue?.newCarLoan || 0,
    usedCarLoan: previousValue?.usedCarLoan || 0
  })
  const [customRate, setCustomRate] = useState(previousValue?.customRate || 'yes')

  useEffect(() => {
    form.setFieldsValue(previousValue)
    forceUpdate({})
  }, [])

  const handleFormSubmission = () => {
    const value = form.getFieldsValue()
    save(STORAGE_KEYS.EZ_DEALZ_BUDGET, JSON.stringify({ ...previousValue, ...value }))
  }

  const handleCarLoanChange = (name, value) => {
    previousValue[name] = value
    save(STORAGE_KEYS.EZ_DEALZ_BUDGET, JSON.stringify({ ...previousValue }))
    setCarLoan({ ...carLoan, [name]: value })
  }

  const renderForm = (form, index) => {
    switch (form.type) {
      case 'info':
        if (customRate === 'yes') {
          return (
            <Input
              placeholder={form.label}
              name={form.name}
              autoFocus={index === 0}
              className="w-full p-3 box-border rounded-lg border-gray-300 border focus:outline-none"
              type={form.type}
            />
          )
        }
        return (
          <>
            <p className="pb-2 text-base font-semibold">{form.label}</p>
            <div className="w-full flex justify-between">
              {form.options.map((option, index) => (
                <div key={index} className="flex items-center justify-between gap-2 mx-2">
                  <p className="w-full">{option.label}</p>
                  <InputNumber
                    value={carLoan[option.name]}
                    onChange={(value) => handleCarLoanChange(option.name, value)}
                    min="0"
                    type="tel"
                    maxLength={2}
                    onKeyDown={(e) => {
                      if (e.keyCode === 189) {
                        e.preventDefault()
                        return false
                      }
                    }}
                  />
                  <p>%</p>
                </div>
              ))}
            </div>
          </>
        )
      case 'radio':
        return (
          <Radio.Group onChange={(e) => setCustomRate(e.target.value)} value={customRate}>
            {form.options.map((option) => (
              <Radio value={option.value}>{option.name}</Radio>
            ))}
          </Radio.Group>
        )
      case 'select':
        return (
          <Select
            placeholder={form.placeholder || `Select $${form.name}`}
            onChange={(value) =>
              save(STORAGE_KEYS.EZ_DEALZ_BUDGET, JSON.stringify({ ...previousValue, [form.name]: value }))
            }
            allowClear
            showSearch
            size="large"
            value={previousValue[form.name]}
            className="w-full"
          >
            {form.options.map((option) => (
              <Option value={option.name} className="p-2">
                {option.name}
              </Option>
            ))}
          </Select>
        )

      default:
        return (
          <Input
            placeholder={form.label}
            name={form.name}
            autoFocus={index === 0}
            className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
            type={form.type}
          />
        )
    }
  }

  return (
    <div className="p-8 py-6 w-full bg-white text-black">
      <p className="font-bold text-xl">Credit & Term</p>
      <p className="text-base mt-2">
        Select your estimated credit range and term length you’d like to use to finance your vehicle. This will be used
        as you shop to help you select the vehicles that fit your budget. As you get offers, these rates will change to
        reflect the most accurate interest rate and term from financial institutions that have approved you.
      </p>
      <Form
        form={form}
        layout="vertical"
        name="vehicle-ref"
        className="mt-4 w-10/12 items-center justify-center"
        onChange={handleFormSubmission}
      >
        {creditTearmForm.map((form, index) => {
          return (
            <div key={index}>
              {form.name !== 'averageInterest' && <p className="py-3 text-base font-semibold">{form.label}</p>}
              <Form.Item
                name={form.name}
                hasFeedback={form.type !== 'info'}
                dependencies={form?.dependencies}
                rules={[
                  {
                    required: form.required,
                    message: `${form.label} is required`
                  }
                ]}
                className="custom-antd-label"
              >
                {renderForm(form, index)}
              </Form.Item>
            </div>
          )
        })}
      </Form>
    </div>
  )
}

CreditTerm.propTypes = {}

export default CreditTerm
