import PropTypes from 'prop-types'
import React from 'react'

function ConditionCard({ info, handleSelect, selectedCondition }) {
  const selectCondition = (type) => {
    handleSelect(type)
  }

  return (
    <div className="bg-white rounded shadow py-3 px-8 flex flex-col justify-between items-center mb-5">
      <p className="text-black text-base mb-5">{info.title}</p>
      <p className="text-gray-400 text-xs text-center mb-5">{info.detail}</p>
      <button
        type="button"
        className={`rounded px-6 py-1 ${
          selectedCondition === info.type ? 'bg-lightGreen text-white border-0' : 'border border-primary text-primary'
        }`}
        onClick={() => selectCondition(info.type)}
      >
        {selectedCondition === info.type ? 'Selected' : 'Select'}
      </button>
    </div>
  )
}

ConditionCard.propTypes = {
  info: PropTypes.shape({
    title: PropTypes.string,
    detail: PropTypes.string,
    type: PropTypes.string,
    selected: PropTypes.bool
  }).isRequired,
  handleSelect: PropTypes.func.isRequired,
  selectedCondition: PropTypes.string
}

export default ConditionCard
