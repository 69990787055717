import { PlusOutlined } from '@ant-design/icons'
import { Button, Modal, Table } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import * as CustomerServices from 'src/services/customer.service'



function MyTradeInModal({ customerLogin, modalVisible, handleCloseModal, handleAddTradeIn }) {
  const [listTrades, setListTrades] = useState([])

  const columns = [
    {
      title: 'MAKER',
      dataIndex: ['vehicle', 'make']
    },
    {
      title: 'MODEL',
      dataIndex: ['vehicle', 'model']
    },
    {
      title: 'YEAR',
      dataIndex: ['vehicle', 'year']
    },
    {
      title: 'SOURCE',
      dataIndex: ['source']
    },
    {
      render: (record) => (
        <Button
          type="primary"
          onClick={() => handleAddTradeIn(record)}
          className="flex items-center"
          icon={<PlusOutlined />}
        >
          Add
        </Button>
      )
    }
  ]

  const getTradeIns = async () => {
    if (customerLogin && listTrades.length === 0) {
      try {
        const { data } = await CustomerServices.getCustomerTrades()
        setListTrades(data)
      } catch (error) {
        console.error(error)
      }
    }
  }
  useEffect(() => {
    getTradeIns()
  }, [customerLogin])
  

  return (
    <Modal
      title="My Trade Ins"
      visible={modalVisible}
      onCancel={handleCloseModal}
      footer={null}
    >
      <div>
        <Table
          className="my-trade-ins-table text-center"
          scroll={{ x: 300 }}
          columns={columns}
          dataSource={listTrades}
        />
      </div>
    </Modal>
  )
}

MyTradeInModal.propTypes = {
  customerLogin: PropTypes.bool,
  modalVisible: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleAddTradeIn: PropTypes.func
}

export default MyTradeInModal
