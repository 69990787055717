import React, { useState, useEffect } from 'react'
import { Form, Input, notification } from 'antd'
import UploadingCustomerLogoComponent from 'src/components/UploadingCustomerLogoComponent'
import PhoneNumberInput from '../../components/InputComponent/PhoneNumberInput'
import { useAuthorizationContext } from '../../AuthorizationProvider'
import { accountUpdate as dealershipAccountUpdate } from '../../services/dealer.service'
import { accountUpdate as customerAccountUpdate } from '../../services/customer.service'
import { USER_ROLES, VALID_EMAIL } from '../../constants/constants'
import SinglePageMetaDecorator from '../../decorators/SinglePageMetaDecorator'
import metaPages from '../../data/metaPages.json'
import metaPageImage from '../../assets/new-logo.png'

function MyAccount() {
  const { customerAccount, setCustomerAccount, dealershipAccount, setDealershipAccount } = useAuthorizationContext()
  const [userAccount, setUserAccount] = useState({})
  const [isCustomer, setIsCustomer] = useState(false)
  const [idProperty, setIdProperty] = useState('employee_id')
  const [triggerUploadCustomerLogo, setTriggerUploadCustomerLogo] = useState(true)

  const [form] = Form.useForm()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [zip, setZip] = useState('')

  useEffect(() => {
    const userAccount = customerAccount?.id !== undefined ? customerAccount : dealershipAccount
    const isCustomer = userAccount.role === USER_ROLES.CUSTOMER
    const phoneProperty = isCustomer ? 'phone' : 'phone_user'
    setUserAccount(userAccount)
    setIsCustomer(isCustomer)
    setIdProperty(isCustomer ? 'id' : 'employee_id')
    setFirstName(userAccount.first_name || '')
    setLastName(userAccount.last_name || '')
    setPhone(userAccount[phoneProperty] || '')
    if (isCustomer) {
      setEmail(userAccount.email || '')
      setZip(userAccount.zip || '')
    }
  }, [customerAccount, dealershipAccount, userAccount, isCustomer, idProperty, firstName, lastName, phone, email, zip])

  useEffect(() => {
    const params = form.getFieldValue()
    if (params.length === undefined) {
      form.setFieldsValue({ ...form.getFieldValue(), first_name: firstName })
      form.setFieldsValue({ ...form.getFieldValue(), last_name: lastName })
      form.setFieldsValue({ ...form.getFieldValue(), phone })
      if (isCustomer) {
        form.setFieldsValue({ ...form.getFieldValue(), email })
        form.setFieldsValue({ ...form.getFieldValue(), zip })
      }
    }
  }, [form, userAccount])

  const onFormChange = (values) => {
    if (values.target.name === 'first_name') {
      setFirstName(values.target.value)
    }
    if (values.target.name === 'last_name') {
      setLastName(values.target.value)
    }
    if (values.target.name === 'phone') {
      setPhone(values.target.value)
    }
    if (values.target.name === 'email') {
      setEmail(values.target.value)
    }
    if (values.target.name === 'zip') {
      setZip(values.target.value)
    }
    form.setFieldsValue({ ...form.getFieldValue(), [values.target.name]: values.target.value })
  }

  const refreshPage = () => {
    window.location.reload(false)
  }

  const handleFormSubmission = async () => {
    const parameters = await form.getFieldValue()
    parameters.user_id = userAccount[idProperty]
    parameters.first_name = parameters.first_name !== undefined ? parameters.first_name : firstName
    parameters.last_name = parameters.last_name !== undefined ? parameters.last_name : lastName
    parameters.phone = parameters.phone !== undefined ? parameters.phone : phone
    if (isCustomer) {
      parameters.email = parameters.email !== undefined ? parameters.email : email
      parameters.zip = parameters.zip !== undefined ? parameters.zip : zip
    }

    const action = isCustomer ? customerAccountUpdate : dealershipAccountUpdate

    action(parameters)
      .then(() => {
        userAccount.first_name = firstName
        userAccount.last_name = lastName
        userAccount.phone = phone
        if (isCustomer) {
          userAccount.email = email
          userAccount.zip = zip
          setCustomerAccount(userAccount)
        } else {
          setDealershipAccount(userAccount)
        }
        notification.success({
          message: 'Success!',
          description: 'My Account information updated.'
        })
      })
      .catch((error) => {
        const errMessage = error?.response?.data?.message || ''
        let message = 'My Account information update failed.'
        if (errMessage === 'EmailExists') {
          message = 'Email address exist, please try another one.'
        }
        notification.error({
          message: 'Error!',
          description: message
        })
        console.error(error)
      })
      .finally(() => {
        setTimeout(() => {
          refreshPage()
        }, 500)
      })
  }

  return (
    <div className="container mx-auto">
      <SinglePageMetaDecorator
        title={metaPages.myAccount.title}
        description={metaPages.myAccount.description}
        imageUrl={metaPageImage}
        imageAlt={metaPages.myAccount.imageAlt}
      />
      <div className="grid lg:grid-cols-9 grid-cols-3 gap-3 py-6">
        <div className="search-dashboard flex flex-col col-span-3">
          <div className="title">{`Hello ${firstName}!`}</div>
          <div className="subtitle">Here you can manage your account information</div>
        </div>
      </div>
      <div className="p-8 py-6 w-full bg-white text-black mb-4 box-border mt-3 rounded-xl border-gray-300 border">
        <p className="font-bold text-xl">Personal Information</p>
        <Form
          layout="vertical"
          name="my-account-ref"
          className="mt-4 w-12/12 items-center justify-center"
          form={form}
          onChange={onFormChange}
          onFinish={handleFormSubmission}
        >
          {isCustomer && (
            <div key="avatar">
              <p className="py-3 text-base font-semibold">Avatar</p>
              <UploadingCustomerLogoComponent
                setTriggerUploadCustomerLogo={setTriggerUploadCustomerLogo}
                triggerUploadCustomerLogo={triggerUploadCustomerLogo}
                customerAccount={customerAccount}
                setCustomerAccount={setCustomerAccount}
                initialImageUrl={userAccount.avatar ?? ''}
              />
            </div>
          )}
          <div key="first_name">
            <p className="py-3 text-base font-semibold">First Name</p>
            <Form.Item
              name="first_name"
              rules={[{ required: true, message: 'First Name is required' }]}
              className="custom-antd-label"
            >
              <Input
                placeholder="Enter First Name"
                type="text"
                defaultValue={firstName}
                name="first_name"
                className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
              />
            </Form.Item>
          </div>
          <div key="last_name">
            <p className="py-3 text-base font-semibold">Last Name</p>
            <Form.Item
              name="last_name"
              rules={[{ required: true, message: 'Last Name is required' }]}
              className="custom-antd-label"
            >
              <Input
                placeholder="Enter Last Name"
                type="text"
                defaultValue={lastName}
                name="last_name"
                className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
              />
            </Form.Item>
          </div>
          <div key="phone">
            <p className="py-3 text-base font-semibold">Phone</p>
            <Form.Item
              name="phone"
              rules={[{ required: true, message: 'Phone is required' }]}
              className="custom-antd-label"
            >
              <PhoneNumberInput
                mask="(999) 999-9999"
                placeholder="Enter Phone"
                defaultValue={phone}
                name="phone"
                className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
              />
            </Form.Item>
          </div>
          {isCustomer && (
            <div key="email">
              <p className="py-3 text-base font-semibold">Email</p>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Email is required' },
                  {
                    pattern: VALID_EMAIL,
                    message: 'Email is invalid'
                  }
                ]}
                className="custom-antd-label"
              >
                <input
                  name="email"
                  defaultValue={email}
                  placeholder="Enter Email"
                  className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
                  type="text"
                />
              </Form.Item>
            </div>
          )}
          {isCustomer && (
            <div key="zip">
              <p className="py-3 text-base font-semibold">Zip Code</p>
              <Form.Item
                name="zip"
                rules={[
                  { required: true, message: 'Zip Code is required' },
                  {
                    pattern: /^[ 0-9]{4,5}\s*$/,
                    message: 'Zip Code is invalid'
                  }
                ]}
                className="custom-antd-label"
              >
                <input
                  name="zip"
                  defaultValue={zip}
                  placeholder="Enter Zip Code"
                  className="w-full p-4 box-border mt-3 rounded-xl border-gray-300 border focus:outline-none"
                  type="text"
                />
              </Form.Item>
            </div>
          )}
          <Form.Item className="flex items-end w-full mt-8 submit" shouldUpdate>
            {() => (
              <button
                type="submit"
                className="bg-primary hover:bg-activePrimary font-semibold rounded-lg px-8 p-0 outline-none text-lg w-fit text-white flex justify-center"
              >
                Save
              </button>
            )}
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default MyAccount
